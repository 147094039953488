import React from 'react';
import RoutesHistoryFilter
    from "../../../../../../../components/transport/subpages/drivers/driver/routesHistory/RoutesHistoryFilter";
import RouteHistoryCard
    from "../../../../../../../components/transport/subpages/drivers/driver/routesHistory/RouteHistoryCard";
import {Container} from "../../../../../../../components/shared";

const RoutesHistory = () => {
    return (
        <div>
            <RoutesHistoryFilter />

            <Container>
                <div className={'grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-[30px] mt-[30px]'}>
                    <RouteHistoryCard/>
                    <RouteHistoryCard/>
                    <RouteHistoryCard/>
                    <RouteHistoryCard/>
                </div>
            </Container>
        </div>
    );
};

export default RoutesHistory;