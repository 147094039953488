import React, {useEffect, useRef, useState} from 'react';
import logo from "../../assets/images/logo.png";
import {Link, NavLink, useLocation} from "react-router-dom";
import {Container} from "../shared";
import {
    ApplicationIcon,
    BookIcon,
    ChatIcon,
    CheckInHistoryIcon,
    LogoutIcon,
    NoteIcon,
    OpenedEnvelopeIcon,
    ProfileIcon,
    SettingsIcon,
    StatementIcon,
    StudentsIcon,
    TransportIcon,
    YellowStudentsIcon
} from "../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/actions/auth";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {roles} from "../../common/roles";
import {useNotifications} from "../../hooks/socket/useNotifications";
import moment from "moment";
import {setNewNotificationsCount} from "../../store/actions/notifications";
import {useMediaQuery} from "react-responsive";

const parseContent = (content) => {
    const regex = /<Link to="(.*?)">(.*?)<\/Link>/g

    const parts = []
    let lastIndex = 0

    content.replace(regex, (match, path, linkText, offset) => {
        if (offset > lastIndex) {
            parts.push(content.slice(lastIndex, offset))
        }

        parts.push(<Link key={offset} to={path} className={'hover:text-[#FCC10F] transition'}>{linkText}</Link>)
        lastIndex = offset + match.length
        return match
    })

    if (lastIndex < content.length) {
        parts.push(content.slice(lastIndex))
    }

    return parts
}

const MainHeader = () => {
    const {authData} = useSelector((state) => state.auth);
    const {socket} = useSelector((state) => state.socket);
    const {newNotificationsCount, notifications} = useSelector((state) => state.notifications)
    const {newMessagesCount} = useSelector((state) => state.chats)

    const {
        getNotifications,
        markNotificationAsRead,
        markAllNotificationsAsRead,
    } = useNotifications(socket);

    const [showMenu, setShowMenu] = useState(false);
    const [showNotifications, setShowNotifications] = useState(false)
    const [activeNotificationTab, setActiveNotificationTab] = useState('all')
    const [localNotifications, setLocalNotifications] = useState(notifications || [])
    const [showApplicationSubpages, setShowApplicationSubpages] = useState(false)
    const [showEduFormatsSubpages, setShowEduFormatsSubpages] = useState(false)
    const [showTransportSubpages, setShowTransportSubpages] = useState(false)
    const [isHovered, setIsHovered] = useState(false)

    const userRole = authData?.role

    const dispatch = useDispatch()
    const ref = useRef(null)
    const notRef = useRef(null)
    const applicationSubpagesRef = useRef(null)
    const eduFormatsSubpagesRef = useRef(null)
    const transportSubpagesRef = useRef(null)

    const location = useLocation()

    const isChatsPageOpen = location.pathname.startsWith('/chats')

    useOutsideClick(ref, () => setShowMenu(false))
    useOutsideClick(notRef, () => setShowNotifications(false))
    useOutsideClick(applicationSubpagesRef, () => setShowApplicationSubpages(false))
    useOutsideClick(eduFormatsSubpagesRef, () => setShowEduFormatsSubpages(false))
    useOutsideClick(transportSubpagesRef, () => setShowTransportSubpages(false))

    const isPhone = useMediaQuery({maxWidth: 640})

    const handleLogout = () => {
        dispatch(logout())
    }

    const handleReadNot = async (notId) => {
        setLocalNotifications(prevState => prevState.map(not =>
            not._id === notId ? {...not, isRead: true} : not
        ))

        markNotificationAsRead(notId).then(() => {
            dispatch(setNewNotificationsCount(newNotificationsCount - 1))
        })
    }

    const handleReadAllNots = async () => {
        try {
            await markAllNotificationsAsRead()

            setLocalNotifications(prevState =>
                prevState.map(not => ({...not, isRead: true}))
            )

            dispatch(setNewNotificationsCount(0))

        } catch (error) {
            console.error('Failed to mark all notifications as read:', error)
        }
    }

    useEffect(() => {
        if (showNotifications) {
            getNotifications('all')
        }
    }, [showNotifications])

    useEffect(() => {
        setLocalNotifications(notifications)
    }, [notifications])

    const filteredNotifications =
        activeNotificationTab === 'new'
            ? localNotifications?.filter((not) => !not.isRead)
            : localNotifications

    useEffect(() => {
        setShowTransportSubpages(false)
        setShowApplicationSubpages(false)
        setShowEduFormatsSubpages(false)
    }, [location])

    return (
        <div
            className={`fixed ${isPhone && 'max-h-[70px]'} top-0 right-0 left-0 z-[10000] py-1 w-full bg-white lg:border-b lg:border-b-[#FCC10F]`}>
            <Container>
                <div className={'flex justify-between items-center'}>
                    <div>
                        <Link to={'/'}>
                            <img src={logo} className={'w-[30px] lg:w-10'} alt={'logo'}/>
                        </Link>
                    </div>

                    <div className={'flex items-center gap-2 lg:gap-[30px]'}>
                        {(userRole === 'admin' || userRole === 'manager' || userRole === 'divisionManager') &&
                            <Link to={'/checkin-history'} className={'fill-icon'}><CheckInHistoryIcon/></Link>
                        }
                        {roles[authData?.role]?.some(role => role.allowViewTransportPage) &&
                            <div className={'relative flex justify-center'}>
                                <div className={'fill-icon cursor-pointer'}>
                                    <div onClick={() => setShowTransportSubpages(true)}
                                         className={'relative group flex items-center gap-[10px]'}>
                                        <TransportIcon color={showTransportSubpages ? '#FCC10F' : '#000'}/>
                                        <span
                                            className={`absolute z-[999] w-[104px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Տրանսպորտ</span>
                                    </div>
                                </div>
                                {showTransportSubpages &&
                                    <div
                                        ref={transportSubpagesRef}
                                        className={'absolute w-[275px] max-h-[335px] bg-white top-full border border-black border-opacity-50 rounded-[5px] py-4 mt-2.5'}>
                                        <div className={'flex flex-col gap-2'}>
                                            <NavLink to={`/transport/trips`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Երթադարան</NavLink>
                                            <NavLink to={`/transport/routes?page=1&size=9&`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Երթուղիներ</NavLink>
                                            <NavLink to={`/transport/stops?page=1&size=9&`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Կանգառներ</NavLink>
                                            <NavLink to={'/vehicles?page=1&size=9&'}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Մեքենաներ</NavLink>
                                            <NavLink to={`/transport/drivers?page=1&size=9&`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Վարորդներ</NavLink>
                                            <NavLink to={`/transport/trips/analytics`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Վերլուծություն</NavLink>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        <div className={'relative flex justify-center p-2 fill-icon'}>
                            {(userRole === 'manager' || userRole === 'divisionManager' || userRole === 'admin') && (
                                <div
                                    className={'cursor-pointer'}
                                >
                                    <div className={'relative group flex items-center gap-[10px]'}>
                                        <div
                                            className={showApplicationSubpages ? 'pointer-events-none' : 'pointer-events-auto'}
                                            onClick={() => setShowApplicationSubpages(!showApplicationSubpages)}
                                        >
                                            <ApplicationIcon color={showApplicationSubpages ? '#FCC10F' : '#000'}/>
                                        </div>
                                        <span
                                            className={`absolute z-[999] w-[125px] left-full top-full text-[12px] border rounded-[3px] p-2.5 hidden bg-white custom-shadow pointer-events-none group-hover:block transition`}>Ընդունելություն</span>
                                    </div>
                                </div>
                            )}
                            {showApplicationSubpages &&
                                <div
                                    ref={applicationSubpagesRef}
                                    className={'absolute w-[275px] max-h-[230px] bg-white top-full border border-black border-opacity-50 rounded-[5px] py-4'}>
                                    <div className={'flex flex-col gap-2'}>
                                        {(userRole !== 'volunteer' || userRole !== 'driver' || userRole !== 'student') &&
                                            <NavLink to={`/intros?page=1&size=9&`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Ներածական
                                                շաբաթ</NavLink>
                                        }
                                        {(userRole === 'manager' || userRole === 'admin' || userRole === 'divisionManager' || userRole === 'mdt') &&
                                            <>
                                                <NavLink to={`/requests?page=1&size=10&archive=false&`}
                                                         className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Հայտադարան</NavLink>
                                                <NavLink to={`/forms?page=1&size=10&`}
                                                         className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Հայտեր</NavLink>
                                            </>
                                        }
                                        {(userRole !== 'volunteer' || userRole !== 'driver' || userRole !== 'student') &&
                                            <NavLink to={'/communities?sortOption=alphabetical'}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Համայնքներ</NavLink>}
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={'cursor-pointer'}>
                            <div
                                className={'relative group flex items-center gap-[10px]'}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                <div
                                    className={showEduFormatsSubpages ? 'pointer-events-none' : 'pointer-events-auto'}>
                                    {(userRole !== 'driver' && userRole !== 'student') &&
                                        <NavLink
                                            to={'/students?page=1&size=10&'}
                                            className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:text-[#FCC10F] transition`}
                                        >
                                            {isHovered ? <YellowStudentsIcon/> : <StudentsIcon/>}
                                        </NavLink>
                                    }
                                </div>
                                <span
                                    className={`absolute z-[999] w-[102px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}
                                >
                                    Ուսանողներ
                                </span>
                            </div>
                        </div>

                        <div className={'relative flex justify-center p-2 fill-icon'}>
                            <div className={'cursor-pointer'}>
                                <div className={'relative group flex items-center gap-[10px]'}>
                                    <div
                                        className={showEduFormatsSubpages ? 'pointer-events-none' : 'pointer-events-auto'}
                                        onClick={() => setShowEduFormatsSubpages(!showEduFormatsSubpages)}>
                                        <BookIcon color={showEduFormatsSubpages ? '#FCC10F' : '#000'}/>
                                    </div>
                                    <span
                                        className={`absolute z-[999] w-[190px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ուսումնական ձևաչափեր</span>
                                </div>
                            </div>
                            {showEduFormatsSubpages &&
                                <div
                                    ref={eduFormatsSubpagesRef}
                                    className={'absolute w-[275px] max-h-[330px] bg-white top-full border border-black border-opacity-50 rounded-[5px] py-4'}>
                                    <div className={'flex flex-col gap-2'}>
                                        {(userRole !== 'volunteer' || userRole !== 'transportCoordinator' || userRole !== 'student' || userRole !== 'driver') &&
                                            <NavLink to={`/groups?page=1&size=9&`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>Խմբեր</NavLink>
                                        }
                                        {(userRole !== 'volunteer' || userRole !== 'driver' || userRole !== 'student') &&
                                            <NavLink to={'/programs'}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>ՍՄԱՐԹ
                                                հիմունքներ</NavLink>}
                                        {(userRole !== 'volunteer' || userRole !== 'driver') &&
                                            <NavLink to={`/projects?sort=newestFirst`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>ՍՄԱՐԹ
                                                նախագծեր</NavLink>
                                        }
                                        {(userRole !== 'volunteer' || userRole !== 'driver' || userRole !== 'student') &&
                                            <NavLink to={`/connects?page=1&size=9&`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>ՍՄԱՐԹամեջ</NavLink>
                                        }
                                        {(userRole !== 'volunteer' || userRole !== 'driver' || userRole !== 'student') &&
                                            <NavLink to={`/trips?page=1&size=9&`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>ՍՄԱՐԹ
                                                այցեր</NavLink>
                                        }
                                        {(userRole !== 'volunteer' || userRole !== 'driver' || userRole !== 'student') &&
                                            <NavLink to={`/workshops?page=1&size=9&`}
                                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} hover:bg-gray-100 transition px-[30px] py-2.5 cursor-pointer`}>ՍՄԱՐԹ
                                                աշխատարան</NavLink>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        <div>
                            {(userRole !== 'volunteer' || userRole !== 'driver' || userRole !== 'student') &&
                                <NavLink to={'/statements?page=1&size=9&'}
                                         className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} fill-icon transition`}
                                >
                                    <p className={'relative group flex items-center gap-[10px]'}>
                                        <StatementIcon/>
                                        <span
                                            className={`absolute z-[999] w-[139px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Հայտարարություն</span>
                                    </p>
                                </NavLink>
                            }
                        </div>
                        <NavLink to={'/chats'} className={`cursor-pointer`}>
                            <p className={'relative group flex items-center gap-[10px] fill-icon'}>
                                <ChatIcon color={isChatsPageOpen ? '#FCC10F' : '#000000'}/>
                                <span
                                    className={`absolute z-[999] w-[93px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Նամակներ</span>
                                {newMessagesCount > 0 &&
                                    <span
                                        className={'absolute top-2 -right-2 w-[15px] h-[15px] flex items-center justify-center text-[12px] text-white bg-[#FF0707] rounded-full px-1'}
                                    >
                                        {newMessagesCount}
                                    </span>
                                }
                            </p>
                        </NavLink>
                        <div
                            className={`relative`}
                        >
                            <div
                                onClick={() => setShowNotifications(!showNotifications)}
                                className={`relative cursor-pointer ${showNotifications ? 'pointer-events-none' : 'pointer-events-auto'}`}
                            >
                                <div className={'fill-icon'}>
                                    <p className={'relative group flex items-center gap-[10px]'}>
                                        <NoteIcon/>
                                        <span
                                            className={`absolute z-[999] w-[93px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ծանուցում</span>
                                    </p>
                                </div>
                                {newNotificationsCount > 0 &&
                                    <div
                                        className={`absolute top-2 -right-2 ${newNotificationsCount > 99 ? 'w-[18px] h-[18px]' : 'w-[15px] h-[15px]'} flex items-center justify-center text-[12px] text-white bg-[#FF0707] rounded-full px-1`}
                                    >
                                        {newNotificationsCount > 99 ?
                                            <p className={'text-[10px]'}>99+</p> : newNotificationsCount}
                                    </div>
                                }
                            </div>
                            {showNotifications &&
                                <div
                                    ref={notRef}
                                    className={'absolute right-0 z-[9990] w-[359px] max-h-[593px] mt-[15px] bg-white border border-black border-opacity-50 shadow-sm rounded-[5px]'}>
                                    <div className={'w-full flex items-center justify-between py-5 px-2.5'}>
                                        <h2 className={'bold'}>Ծանուցումներ</h2>
                                        <div
                                            className={'relative group flex items-center gap-[10px] fill-icon cursor-pointer'}>
                                            <div onClick={handleReadAllNots}>
                                                <OpenedEnvelopeIcon/>
                                            </div>
                                            <span
                                                className={`absolute w-[225px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Նշել բոլորը որպես կարդացած</span>
                                        </div>
                                    </div>
                                    <div className={'flex gap-5 px-2.5 pb-5'}>
                                        <button
                                            onClick={() => setActiveNotificationTab('all')}
                                            className={`w-[100px] h-[30px] border rounded-[5px] cursor-pointer ${
                                                activeNotificationTab === 'all' ? 'bg-[#FCC10F] text-white border-[#FCC10F]' : 'text-black text-opacity-70 border-black border-opacity-70'
                                            }`}
                                        >
                                            Բոլորը
                                        </button>
                                        <button
                                            onClick={() => setActiveNotificationTab('new')}
                                            className={`w-[156px] h-[30px] border text-[12px] rounded-[5px] cursor-pointer ${
                                                activeNotificationTab === 'new' ? 'bg-[#FCC10F] text-white border-[#FCC10F]' : 'text-black text-opacity-70 border-black border-opacity-70'
                                            }`}
                                        >
                                            Չկարդացածները
                                        </button>
                                    </div>
                                    <div
                                        className={'w-full max-h-[470px] flex flex-col gap-2.5 overflow-y-auto pb-5'}>
                                        {filteredNotifications?.length > 0
                                            ? filteredNotifications.map(not => {
                                                const formattedDate = moment(not.createdAt).fromNow();
                                                return (
                                                    <div
                                                        key={not._id}
                                                        onClick={() => handleReadNot(not._id)}
                                                        className={`flex gap-2.5 px-2.5 ${!not.isRead && 'bg-black bg-opacity-5 cursor-pointer'}`}
                                                    >
                                                        <div className="w-10 h-10 overflow-hidden rounded-full">
                                                            <img
                                                                src={not.image}
                                                                alt={'image'}
                                                                className={`w-full h-full object-contain`}
                                                            />
                                                        </div>
                                                        <div className={'w-full flex items-center justify-between'}>
                                                            <div
                                                                className={'max-w-[264px] flex flex-col gap-2 text-[12px]'}>
                                                                <p>{parseContent(not.content)}</p>
                                                                <span
                                                                    className={!not.isRead && 'text-[#FCC10F]'}>{formattedDate}</span>
                                                            </div>
                                                            {!not.isRead && (
                                                                <div
                                                                    className={'w-[15px] h-[15px] bg-[#FCC10F] rounded-full'}></div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                            : <p className={'text-center'}>Ծանուցումներ չկան</p>
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={`relative`}>
                            <img
                                src={authData.profileImage}
                                alt={'testingImage'}
                                className={`w-[30px] h-[30px] lg:w-10 lg:h-10 rounded-full ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'} select-none object-cover`}
                                onClick={() => setShowMenu(!showMenu)}
                            />

                            {showMenu && (
                                <div ref={ref}
                                     className={`absolute z-50 w-[236px] max-h-[140px] top-full flex flex-col gap-2 bg-white right-0 mt-[10px] py-2 border border-black rounded-[5px]`}>
                                    <>
                                        <Link to={'/profile'}>
                                            <p
                                                className={'flex items-center gap-5 px-5 transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                                <ProfileIcon/>
                                                <span>Օգտաէջ</span>
                                            </p>
                                        </Link>
                                        {(authData.role === 'manager' || authData.role === 'divisionManager') &&
                                            <Link to={`/settings/members`}>
                                                <p
                                                    className={'flex items-center gap-5 px-5 transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                                    <SettingsIcon width={20} height={20} color={"#000"}/>
                                                    <span>Կարգավորումներ</span>
                                                </p>
                                            </Link>
                                        }
                                        <Link to={'/'}
                                              className={'flex items-center gap-[10px] px-5 transition hover:bg-gray-200 py-1 cursor-pointer'}
                                              onClick={handleLogout}>
                                            <LogoutIcon/>
                                            <span>Ելք</span>
                                        </Link>
                                    </>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default MainHeader;