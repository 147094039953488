import React from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children, top, left, alignment = 'left' }) => {
    const style = {
        position: 'absolute',
        top: `${top}px`,
        zIndex: 9999,
    };

    if (alignment === 'right') {
        style.right = `${left}px`;
    } else {
        style.left = `${left}px`;
    }

    return ReactDOM.createPortal(
        <div style={style}>
            {children}
        </div>,
        document.body
    )
}

export default Portal
