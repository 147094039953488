import React, {useEffect, useState} from 'react';
import AddButton from "../../../../components/shared/buttons/AddButton";
import MemberItem from "../../../../components/settings/members/MemberItem";
import CreateMemberModal from "../../../../components/settings/members/CreateMemberModal";
import SettingsNavbar from "../../../../components/settings/SettingsNavbar";
import Pagination from "../../../../components/shared/Pagination";
import {useSelector} from "react-redux";
import {getMembers} from "../../../../store/actions/profile";
import {useFilters} from "../../../../hooks/useFilters";

const initialFilters = {page: 1}

const Members = () => {
    const {members, loading} = useSelector(state => state.profile)

    const {filters, changePage} = useFilters(initialFilters, getMembers, 'settings/members', false)

    const [memberModal, setMemberModal] = useState(null)

    const handleEditMember = (member) => {
        setMemberModal(member)
    }

    useEffect(() => {
        document.body.style.overflow = memberModal ? 'hidden' : 'auto'
    }, [memberModal])

    return (
        <div>
            {memberModal &&
                <CreateMemberModal close={() => setMemberModal(null)} memberData={memberModal}/>
            }

            <div className={'flex items-center justify-between'}>
                <AddButton text={"Նոր օգտաէջ"} onClick={() => setMemberModal('create')}/>
                <SettingsNavbar/>
            </div>

            <div>
                {
                    loading
                        ?
                        <p className={'text-center mt-[30px]'}>Բեռնվում է...</p>
                        :
                        members?.data?.length > 0
                            ?
                            <>
                                <div>
                                    {members?.data?.map((member, index) => <MemberItem handleEditMember={() => handleEditMember(member)} {...member} key={index}/>)}
                                </div>
                                <Pagination count={members?.pagesCount} page={filters?.page || 1} onChange={changePage}/>
                            </>
                            :
                            <p className={'text-center mt-[30px]'}>Օգտաէջեր չկան</p>}
            </div>
        </div>
    );
};

export default Members;