import React, {useEffect, useRef, useState} from 'react';
import {ActionMenuIcon, TrashIcon} from "../../../assets/icons";
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {changeMemberStatus, deleteMember} from "../../../store/actions/profile";
import {useDispatch} from "react-redux";
import CreateMemberModal from "./CreateMemberModal";
import {ConfirmationModal} from "../../shared";

const MemberItem = ({_id, profileImage, fullName, position, email, division, role, status, workingSchedule, handleEditMember}) => {
    const [showMenu, setShowMenu] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [statusToChange, setStatusToChange] = useState(null)
    const [memberToDelete, setMemberToDelete] = useState(null)

    const dispatch = useDispatch()
    const ref = useRef(null)

    useOutsideClick(ref, () => setShowMenu(false))

    const changeStatus = () => {
        setStatusToChange(true)
    }

    const handleChangeStatus = () => {
        dispatch(changeMemberStatus(_id))

        setShowMenu(false)
        setStatusToChange(null)
    }

    const openEditModal = () => {
        setShowMenu(false)
        setEditMode(true)
    }

    const handleDeleteMember = () => {
        dispatch(deleteMember(_id))

        setShowMenu(false)
    }

    useEffect(() => {
        document.body.style.overflow = memberToDelete || editMode ? 'hidden' : 'auto'
    }, [memberToDelete, editMode])

    return (
        <>
            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(null)}
                    warningText={status === 'active' ? 'Վստա՞հ եք, որ ցանկանում եք ապաակտիվացնել այս օգտաէջը։' : 'Վստա՞հ եք, որ ցանկանում եք ակտիվացնել այս օգտաէջը։'}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }
            {memberToDelete &&
                <ConfirmationModal
                    close={() => setMemberToDelete(null)}
                    icon={<TrashIcon width={50} height={50}/>}
                    warningText={'Վստա՞հ եք, որ ցանկանում եք ջնջել այս օգտաէջը։'}
                    confirm={handleDeleteMember}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }
            {editMode && (
                <CreateMemberModal
                    close={() => setEditMode(false)}
                    memberData={{
                        _id,
                        fullName,
                        profileImage,
                        email,
                        position,
                        division,
                        role,
                        workingSchedule
                    }}
                />
            )}

            <div className={'relative border border-black border-opacity-70 px-5 rounded-[5px] mt-[30px]'}>
                <div className={'relative grid grid-cols-4'}>
                    <div className={'flex items-center gap-[60px]'}>
                        <img src={profileImage} alt={"memberProfileImg"}
                             className={'flex items-center w-[30px] h-[30px] rounded-full object-cover'}/>

                        <h2>{fullName}</h2>
                    </div>

                    <div className={'flex flex-col'}>
                        <span className={'text-[#FCC10F]'}>Էլ. փոստ՝</span>
                        <span title={email} className={'line-clamp-1'}>{email}</span>
                    </div>

                    <div className={'flex flex-col'}>
                        <span className={'text-[#FCC10F]'}>Պաշտոն՝</span>
                        <span title={position} className={'line-clamp-1'}>{position}</span>
                    </div>

                    <div className={'flex items-center'}>
                        <div className={'flex items-center gap-3'}>
                            <span className={'text-[#FCC10F]'}>Դիվիժն՝</span>
                            <span title={division?.name} className={'line-clamp-1'}>{division?.name}</span>
                        </div>
                    </div>

                    <button
                        className={`absolute -right-3 top-2 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                        onClick={() => setShowMenu(!showMenu)}>
                        <ActionMenuIcon/>
                    </button>
                </div>

                {showMenu && (
                    <div ref={ref}
                         className={`absolute z-50 w-[218px] top-full flex flex-col gap-5 bg-white -right-[2px] py-5 border border-black rounded-[5px]`}>
                        <>
                            <p
                                onClick={handleEditMember}
                                className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Խմբագրել
                            </p>
                            <p
                                onClick={changeStatus}
                                className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                {status === 'active' ? 'Ապաակտիվացնել' : 'Ակտիվացնել'}
                            </p>
                            <p
                                onClick={() => setMemberToDelete(true)}
                                className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Հեռացնել
                            </p>
                        </>
                    </div>
                )}
            </div>
        </>
    );
};

export default MemberItem;