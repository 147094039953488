import {
    CHANGE_PROGRAM_STATUS,
    CREATE_PROGRAM,
    CREATE_PROGRAM_PROJECT,
    DELETE_PROGRAM,
    DELETE_PROGRAM_PROJECT,
    EDIT_NOTE,
    EDIT_PROGRAM,
    EDIT_PROGRAM_PROJECT,
    GET_ALL_PROJECTS,
    GET_PROGRAM,
    GET_PROGRAM_GROUPS,
    GET_PROGRAM_PROJECTS,
    GET_PROGRAMS, LIKE_PROJECT,
    SET_LOADING
} from "../constants/actionTypes";
import * as programsApi from "../../api/programs";
import program from "../../pages/main/programs/Program";

export const createProgram = (payload) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.createProgram(payload)

        if (data.success) {
            dispatch({type: CREATE_PROGRAM, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const createProgramProject = (programId, payload, page) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.createProgramProject(programId, payload)

        if (data.success) {
            dispatch({type: CREATE_PROGRAM_PROJECT, payload: data})
            getProgramProjects(programId, page)
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        // alert(error.response?.data.message)
    }
}


export const getPrograms = () => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.getPrograms()

        if (data.success) {
            dispatch({type: GET_PROGRAMS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response?.data.message)
    }
}

export const getProgramProjects = (id, page) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.getProgramProjects(id, page)

        if (data.success) {
            dispatch({type: GET_PROGRAM_PROJECTS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response?.data.message)
    }
}

export const getProgram = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.getProgram(id)

        if (data.success) {
            dispatch({type: GET_PROGRAM, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const getProgramGroups = (id, page) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.getProgramGroups(id, page)

        if (data.success) {
            dispatch({type: GET_PROGRAM_GROUPS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const getAllProjects = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.getAllProjects(queryParams)

        if (data.success) {
            dispatch({type: GET_ALL_PROJECTS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const changeProgramStatus = (id, status) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.changeProgramStatus(id, status)

        if (data.success) {
            dispatch({type: CHANGE_PROGRAM_STATUS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const editProgram = (id, payload) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.editProgram(id, payload)

        if (data.success) {
            dispatch({type: EDIT_PROGRAM, payload: data})
            dispatch(getProgram(id))
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const editProgramProject = (id, payload, programId, page, pageType, size) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.editProgramProject(id, payload)

        if (data.success) {
            dispatch({type: EDIT_PROGRAM_PROJECT, payload: data})
            if (pageType !== 'allProjects') {
                dispatch(getProgramProjects(programId, page))
            } else {
                dispatch(getAllProjects(`?page=${page}&size=${size}&`))
            }
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const likeProject = (id, programId, page, type, size) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.likeProject(id)

        if (data.success) {
            dispatch({type: LIKE_PROJECT, payload: data})
            type === 'program' ? dispatch(getProgramProjects(programId, page)) : dispatch(getAllProjects(`?page=${page}&size=${size}&`))
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const deleteProgram = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.deleteProgram(id)

        if (data.success) {
            dispatch({type: DELETE_PROGRAM, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response.data.message)
    }
}

export const deleteProgramProject = (projectId, programId, page, size, pageType) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await programsApi.deleteProgramProject(projectId)

        if (data.success) {
            dispatch({type: DELETE_PROGRAM_PROJECT, payload: data})
            if (pageType !== 'allProjects') {
                dispatch(getProgramProjects(programId, page))
            } else {
                dispatch(getAllProjects(`?page=${page}&size=${size}&`))
            }
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        alert(error.response?.data.message)
    }
}
