import React from 'react';
import {creator, genius, thinker, whizzKid, wizard} from "../../../../assets/images/SMART Degrees";
import {HoodieIcon, LocationIcon, PinIcon, TuitionIcon} from "../../../../assets/icons";
import {useMediaQuery} from "react-responsive";

const ProfileHeader = ({student, setSubpage, subpage}) => {
    const isPhone = useMediaQuery({maxWidth: 640})

    const rewardIcons = [
        {icon: <PinIcon width={isPhone ? 15 : 30} height={isPhone ? 15 : 30}/>, id: 'pins', text: 'Փիներ'},
        {icon: <HoodieIcon width={isPhone ? 15 : 30} height={isPhone ? 15 : 30}/>, id: 'hoodie', text: 'Հուդի'},
        {icon: <LocationIcon width={isPhone ? 15 : 30} height={isPhone ? 15 : 30}/>, id: 'smartVisits', text: 'Սմարթ այցեր'},
        {icon: <TuitionIcon width={isPhone ? 15 : 30} height={isPhone ? 15 : 30}/>, id: 'scholarship', text: 'Կրթաթոշակ'}
    ]

    return (
        <div className={`${isPhone && 'fixed top-10 px-[15px]'} w-full bg-white lg:top-24`}>
            <div className="flex items-center lg:items-start lg:gap-[100px] mt-4 lg:mt-0">
                <img src={student?.profileImage} alt="profileImage"
                     className="max-w-[75px] max-h-[75px] lg:max-w-[200px] lg:max-h-[200px] rounded-full object-cover"/>
                <div className={'flex flex-col'}>
                    <div className="flex items-center">
                        <div className="flex lg:gap-[30px]">
                            <h2 className="max-w-[228px] lg:max-w-full text-center w-fit text-[20px] lg:text-[50px] text-[#FCC10F] font-bold">
                                {student?.fullName}
                            </h2>
                            {/*{student?.currentSmartDegree === "thinker" &&*/}
                            <img
                                src={thinker}
                                alt="thinker"
                                className="w-5 h-5 lg:w-10 lg:h-10 select-none"
                            />
                            {/*}*/}
                            {student?.currentSmartDegree === "creator" &&
                                <img
                                    src={creator}
                                    alt="creator"
                                    className="w-5 h-5 lg:w-10 lg:h-10 select-none"
                                />}
                            {student?.currentSmartDegree === "whizzKid" &&
                                <img
                                    src={whizzKid}
                                    alt="whizzKid"
                                    className="w-5 h-5 lg:w-10 lg:h-10 select-none"
                                />}
                            {student?.currentSmartDegree === "wizard" &&
                                <img
                                    src={wizard}
                                    alt="wizard"
                                    className="w-5 h-5 lg:w-10 lg:h-10 select-none"
                                />}
                            {student?.currentSmartDegree === "genius" &&
                                <img
                                    src={genius}
                                    alt="genius"
                                    className="w-5 h-5 lg:w-10 lg:h-10 select-none"
                                />}
                        </div>
                    </div>
                    <div className="max-w-[300px] lg:max-w-full flex items-center gap-2 mt-2.5 pl-9 lg:pl-0 lg:justify-end">
                        <div className="flex items-center gap-2 lg:gap-5 p-2 lg:p-5 rounded-[5px] shadow-md flex-wrap">
                            {rewardIcons.map((iconData, index) => {
                                const isAwarded = student?.smartAwards?.includes(iconData.id);
                                return (
                                    <div key={index}
                                         className="relative group flex flex-col items-center cursor-pointer select-none">
                                        {React.cloneElement(iconData.icon, {color: isAwarded ? '#FCC10F' : undefined})}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex items-center gap-2 lg:gap-5 p-2 lg:p-5 rounded-[5px] shadow-md select-none flex-wrap">
                            {[
                                {src: thinker, label: 'Մտածող', degree: 'thinker'},
                                {src: creator, label: 'Ստեղծող', degree: 'creator'},
                                {src: whizzKid, label: 'Գիտակ', degree: 'whizzKid'},
                                {src: wizard, label: 'Հրաշագործ', degree: 'wizard'},
                                {src: genius, label: 'Տաղանդ', degree: 'genius'}
                            ].map(({src, label, degree}) => (
                                <div key={degree} className="relative group">
                                    <img
                                        src={src}
                                        alt={degree}
                                        className={`w-[15px] h-[15px] lg:w-[30px] lg:h-[30px] select-none ${student?.smartDegrees.includes(degree) ? '' : 'grayscale'}`}
                                    />
                                    <span
                                        className="absolute z-10 w-fit left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white shadow-md pointer-events-none group-hover:opacity-100 transition">
                                    {label}
                                </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className={'flex justify-between lg:justify-center lg:gap-[100px] mt-5 px-1'}>
                <p onClick={() => setSubpage('programs')}
                   className={`text-[14px] lg:text-[20px] ${subpage === 'programs' && 'border-b border-[#FCC10F] pb-2.5'}`}>ՍՄԱՐԹ
                    հիմունքներ</p>
                <p onClick={() => setSubpage('info')}
                   className={`text-[14px] lg:text-[20px] ${subpage === 'info' && 'border-b border-[#FCC10F] pb-2.5'}`}>Անձնական
                    տվյալներ</p>
            </div>
        </div>
    );
};

export default ProfileHeader;