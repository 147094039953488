import React, {useEffect, useState} from 'react';
import {CloseIcon, FilterIcon} from "../../assets/icons";
import MobileDropdown from "../shared/dropdowns/MobileDropdown";
import {ageGroupDataHybrid, groupStatusData} from "../../common/dropdownOptions";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";
import {Container} from "../shared";
import {useDispatch, useSelector} from "react-redux";
import {getScheduleCodes, getStatementProps} from "../../store/actions/common";
import Calendar from "../shared/calendar/Calendar";
import {useMediaQuery} from "react-responsive";

const StatementsMobileFilter = ({
                                    filters,
                                    discardFilters,
                                    submitFilters,
                                    changeSelectedFilters,
                                    clearAllFilters,
                                    removeFilters,
                                    setFilters,
                                    handleChange
                                }) => {
    const {statementProps} = useSelector(state => state.common)

    const combinedAudience = [
        ...(statementProps?.audience?.ageGroups || []),
        ...(statementProps?.audience?.genders || []),
        ...(statementProps?.audience?.groups || []),
        ...(statementProps?.audience?.programs || []),
        ...(statementProps?.audience?.scheduleCodes || []),
        ...(statementProps?.audience?.statusInGroup || []),
        ...(statementProps?.audience?.studentGlobalStatus || []),
    ]

    const isPhone = useMediaQuery({maxWidth: 640})

    const [showFilters, setShowFilters] = useState(false)

    const dispatch = useDispatch()

    const selectedFilters = (filters?.name || filters?.status || filters?.program || filters?.ageGroup || filters?.schedule)

    const handleChangeFromDate = (date) => {
        setFilters({
            ...filters,
            fromDate: date
        })
    }

    const handleChangeToDate = (date) => {
        setFilters({
            ...filters,
            toDate: date
        })
    }

    useEffect(() => {
        dispatch(getScheduleCodes())
        dispatch(getStatementProps())
    }, [])

    return (
        <Container className={'pt-0 pb-8'}>
            <div className={'fixed left-[75%] z-[99999] flex justify-end'}>
                <div className={'relative'}>
                    <div
                        onClick={() => setShowFilters(!showFilters)}
                        className={`relative flex items-center gap-2.5 bg-white`}
                    >
                        <span className={`${showFilters && 'text-[#FCC10F]'} text-[14px]`}>Ֆիլտր</span>
                        <FilterIcon color={showFilters && '#FCC10F'}/>
                    </div>

                    {showFilters &&
                        <div
                            className={'absolute top-full right-[5%] z-[9999] w-[345px] min-h-[424px] mt-2.5 p-[30px] bg-white border border-black rounded-[3px]'}>
                            <h2 className={'text-[14px] text-[#FCC10F] mb-5'}>Ֆիլտր</h2>

                            <div className={'max-h-[250px] overflow-y-auto'}>
                                <div className={'text-[14px] border-b border-b-black border-opacity-50 pb-[15px]'}>
                                    <input
                                        name={'title'}
                                        className={'outline-none custom-placeholder'}
                                        autoComplete={'off'}
                                        value={filters?.title || ''}
                                        onChange={handleChange}
                                        placeholder={"Նախագծի անուն"}
                                        // onKeyDown={handleEnter}
                                    />
                                </div>

                                <MobileDropdown
                                    data={statementProps?.themes}
                                    name={'theme'}
                                    placeholder={'Թեմա'}
                                    className={'border-b border-black border-opacity-50 py-[15px]'}
                                    onChange={changeSelectedFilters}
                                    selected={filters?.theme}
                                    removeFilters={removeFilters}
                                />

                                <MobileDropdown
                                    data={combinedAudience}
                                    placeholder={'Թիրախային խումբ'}
                                    className={'border-b border-black border-opacity-50 py-[15px]'}
                                    name={'audience'}
                                    onChange={changeSelectedFilters}
                                    selected={filters?.audience}
                                    removeFilters={removeFilters}
                                />

                                <div className={'text-[14px] border-b border-b-black border-opacity-50 py-[15px]'}>
                                    <Calendar displayFormat={'DD.MM.YYYY'}
                                              returnFormat={'YYYY-MM-DD'}
                                              placeholder={'Սկիզբ'}
                                              className={'py-[1px]'}
                                              paddingClassName={'px-0'}
                                              optionClassName={'px-2'}
                                              previewClassName={'px-0'}
                                              gapClassName={'gap-1'}
                                              type={'mobile'}
                                              textSize={'text-[12px]'}
                                              showShortMonths={true}
                                              calendarIconWidth={17}
                                              calendarIconHeight={17}
                                              setDate={handleChangeFromDate}
                                              value={filters?.fromDate}
                                    />
                                </div>

                                <div className={'text-[14px] py-[15px]'}>
                                    <Calendar displayFormat={'DD.MM.YYYY'}
                                              returnFormat={'YYYY-MM-DD'}
                                              placeholder={'Ավարտ'}
                                              className={'py-[1px]'}
                                              paddingClassName={'px-0'}
                                              previewClassName={'px-0'}
                                              type={'mobile'}
                                              optionClassName={'px-2'}
                                              gapClassName={'gap-1'}
                                              textSize={'text-[12px]'}
                                              showShortMonths={true}
                                              calendarIconWidth={17}
                                              calendarIconHeight={17}
                                              setDate={handleChangeToDate}
                                              value={filters?.toDate}
                                    />
                                </div>
                            </div>

                            <div className={'flex items-center justify-center'}>
                                {selectedFilters?.length > 0 &&
                                    <div
                                        onClick={clearAllFilters}
                                        className={'w-fit flex items-center gap-3 px-1 bg-[#D9D9D940] rounded-[3px] text-[14px] mt-5'}>
                                        <span>Ջնջել բոլորը</span>
                                        <CloseIcon width={17} height={17}/>
                                    </div>
                                }
                            </div>

                            <div
                                className={`flex gap-3 items-center justify-end ${selectedFilters?.length > 0 ? 'mt-5' : 'mt-[60px]'}`}>
                                <ButtonOutlined
                                    onClick={discardFilters}
                                    className={'w-[105px] h-[27px] text-[14px]'}
                                    text={'Չեղարկել'}
                                    isPhone={isPhone}
                                />
                                <ButtonFilled
                                    onClick={() => submitFilters()}
                                    className={'w-[105px] h-[27px] text-[14px]'}
                                    isPhone={isPhone}
                                    text={'Կիրառել'}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Container>
    );
};

export default StatementsMobileFilter;