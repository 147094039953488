import React, { useRef } from 'react';
import { CameraIcon } from "../../assets/icons";

const ProfileInfo = ({ profileImage, fullName, position, uploadedImage, uploadImage }) => {
    const imageUploadRef = useRef(null)

    const imageSrc = uploadedImage ? URL.createObjectURL(uploadedImage) : profileImage

    return (
        <div className={'flex flex-col items-center justify-center h-fit'}>
            <div className={'w-fit relative group'}>
                <img
                    src={imageSrc}
                    className={'w-[200px] h-[200px] rounded-full object-cover aspect-square select-none pointer-events-none'}
                    alt="profile-image"
                />
                <input
                    type={"file"}
                    accept={'image/*'}
                    className={'hidden'}
                    id={"upload_image"}
                    ref={imageUploadRef}
                    onChange={uploadImage}
                />

                <button
                    title={'Վերբեռնել նկար'}
                    className={'absolute right-0 fill-icon-white bottom-3 shadow-md p-2 rounded-full group-hover:opacity-100 opacity-0 transition z-50 bg-[#fff] border border-[#FCC10F] hover:bg-[#FCC10F]'}
                    onClick={() => imageUploadRef.current.click()}
                >
                    <CameraIcon color={'#FCC10F'} />
                </button>
            </div>
            <div className={'flex flex-col items-center justify-center mt-[30px] gap-5'}>
                <h2 className={'text-[#FCC10F]'}>{fullName}</h2>
                <p className={'text-center'}>{position}</p>
            </div>
        </div>
    );
};

export default ProfileInfo