import API from "./index";

export const createProgram = (data) => API.post(`/programs`, data)
export const getPrograms = () => API.get(`/programs`)
export const getProgram = (id) => API.get(`/programs/${id}`)
export const getProgramGroups = (id, page) => API.get(`/programs/${id}/groups?page=${page}`)
export const editProgram = (id, data) => API.patch(`programs/${id}`, data)
export const changeProgramStatus = (id, status) => API.patch(`/programs/${id}/status`, {status})
export const deleteProgram = (id) => API.delete(`/programs/${id}`)

export const createProgramProject = (programId, data) => API.post(`/programs/${programId}/projects`, data)
export const editProgramProject = (id, data) => API.patch(`/programs/projects/${id}`, data)
export const likeProject = (id) => API.patch(`/programs/projects/${id}/like`)
export const getProgramProjects = (id, page) => API.get(`/programs/${id}/projects?page=${page}`)
export const deleteProgramProject = (projectId) => API.delete(`/programs/projects/${projectId}`)

export const getAllProjects = (queryParams) => API.get(`/programs/all-projects${queryParams}`)