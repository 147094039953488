import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getForms} from "../../../store/actions/forms";
import {Container} from "../../../components/shared";
import FormItem from "../../../components/forms/FormItem";
import Pagination from "../../../components/shared/Pagination";
import PageSizeDropdown from "../../../components/shared/dropdowns/PageSizeDropdown";
import CreateNewFormButton from "../../../components/forms/CreateNewFormButton";
import {useFilters} from "../../../hooks/useFilters";
import {roles} from "../../../common/roles";
import {useMediaQuery} from "react-responsive";
import {DropdownArrow} from "../../../assets/icons";

const initialFilters = {page: 1, size: 10}

const Forms = () => {
    const {forms, loading} = useSelector(state => state.forms)
    const [customPage, setCustomPage] = useState('')
    const {authData} = useSelector(state => state.auth)

    const [showScrollToTop, setShowScrollToTop] = useState(false)

    const {
        filters,
        changePage,
        handleChangeSize,
        setFilters,
        submitFilters
    } = useFilters(initialFilters, getForms, 'forms')

    const isPhone = useMediaQuery({maxWidth: 640})

    const changeCustomPage = (e) => {
        const disallowedCharSet = '-+.'
        const value = e.target.value

        if (e.key === 'Enter') {
            setFilters({...filters, page: customPage <= forms?.pagesCount ? customPage : forms?.pagesCount})
        } else {
            const lastChat = value[value.length - 1]

            !disallowedCharSet.includes(lastChat) && setCustomPage(value)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 170) {
                setShowScrollToTop(true)
            } else {
                setShowScrollToTop(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    return (
        <>
            <Container>
                <h2 className={'text-center text-[20px] lg:text-[50px] text-[#FCC10F] font-bold mt-5 lg:mt-[14px]'}>Ստեղծել
                    հայտ</h2>

                <div className={'pt-5 lg:pt-[30px]'}>
                    {roles[authData?.role]?.some(role => role.showCreateFormButton) && !isPhone &&
                        <CreateNewFormButton/>
                    }

                    {loading && !forms ?
                        <p className={'text-center'}>Բեռնվում է․․․</p>
                        :
                        forms?.data.length > 0
                            ?
                            <div className={'lg:py-[30px]'}>
                                {!isPhone &&
                                    <div className={'grid grid-cols-[1fr,120px] px-[30px] mb-[30px]'}>
                                        <div className={'grid grid-cols-2'}>
                                            <div className={'bold'}>Հայտի անուն</div>
                                            <div className={'bold'}>Հայտի կարգավիճակ</div>
                                        </div>
                                    </div>
                                }
                                <div className={'grid grid-cols-1 gap-2.5 lg:gap-[30px]'}>
                                    {forms?.data.map(form => <FormItem key={form._id} {...form} />)}
                                </div>

                                {showScrollToTop && isPhone &&
                                    <button
                                        className={'fixed z-[9999] bottom-14 left-[90%] bg-white border border-black border-opacity-70 rounded-full rotate-180 p-[3px]'}
                                        onClick={scrollToTop}
                                    >
                                        <DropdownArrow />
                                    </button>
                                }
                            </div>
                            :
                            <p className={'text-center'}>Հայտեր չկան
                            </p>
                    }
                </div>
                {forms && forms?.data.length > 0 && (
                    <div className={'flex items-center justify-center mt-[20px] mb-[100px]'}>
                        <Pagination count={forms?.pagesCount} onChange={changePage}
                                    page={parseInt(filters?.page || 1)} size={"small"}/>

                        <div className={'flex items-center justify-center gap-2.5'}>
                            <span className={isPhone && 'text-[14px]'}>էջ</span>
                            <input type="number" onKeyDown={changeCustomPage}
                                   onChange={changeCustomPage}
                                   value={customPage}
                                   max={forms?.pagesCount}
                                   className={'max-w-[53px] outline-none px-2 py-0.5 lg:py-[3px] text-center border border-black rounded-[5px] text-[14px] lg:text-[16px]'}/>
                        </div>
                        <div className={'ml-2.5 lg:ml-[30px]'}>
                            <PageSizeDropdown onChange={handleChangeSize} value={filters?.size}/>
                        </div>
                    </div>
                )}
            </Container>
        </>
    );
};

export default Forms;