import React from 'react';
import head from '../../../assets/images/Head.png';
import heart from '../../../assets/images/Heart.png';
import hand from '../../../assets/images/Hand.png';
import {CompletedIcon, DroppedIcon, ParticipatedIcon} from "../../../assets/icons";
import {Link} from "react-router-dom";

const icons = {head, hand, heart};

const SmartBlock = ({selected, title, icon}) => {

    return (
        <div className={'pt-[15px] px-[25px] pb-[100px]'}>
            <h2 className={'flex items-center justify-center gap-5 text-[#FCC10F] text-center select-none'}>{icon} {title}</h2>

            {selected?.length > 0 && (
                <div className="flex flex-wrap justify-between gap-[30px] mt-30">
                    {selected.map(item => {
                        return (
                            <div key={item._id} className={'max-w-[235px] flex items-center justify-between gap-5'}>
                                <div className={'flex flex-col gap-5'}>
                                    {item.statusType === 'current'
                                        ?
                                        item.userCanNavigateToGroup
                                            ?
                                            <Link to={`/connects/${item._id}`}
                                                  className={'hover:text-[#FCC10F] transition cursor-pointer'}>{item.name}</Link>
                                            :
                                            <p>{item.name}</p>
                                        :
                                        item.userCanNavigateToGroup
                                            ?
                                            <Link to={`/connects/${item.group?._id}`}
                                                  className={'hover:text-[#FCC10F] transition cursor-pointer'}>{item.group?.name}</Link>
                                            :
                                            <p>{item.group?.name}</p>
                                    }
                                </div>
                                <div className={'flex justify-center items-center gap-5'}>
                                    {item.status === 'completed' && <CompletedIcon/>}
                                    {item.status === 'participated' && <ParticipatedIcon/>}
                                    {item.status === 'dropped' && <DroppedIcon/>}
                                    {/*<span className={'uppercase'}>{item.grade}</span>*/}
                                </div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
};

export default SmartBlock;
