export const checkCreatedFormValidity = (form) => {
    if(!form.name || !form.description) return false

    for(const field in form.body) {
        if(
            !form.body.length ||
            (
                !form.body[field].type ||
                !form.body[field].category
            )
        ) {
            if(form.body[field].type !== 'successMessage') {
                return false
            }
        }
    }

    return true
}