import React from 'react';
import {Link} from "react-router-dom";
import {SettingsIcon} from "../../assets/icons";

const TransportHeader = () => {
    return (
        <div className={'flex justify-between items-center mt-[14px]'}>
            <div></div>
            <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Տրանսպորտ</h2>
            <Link to={'transport/settings'}><SettingsIcon /></Link>
        </div>
    );
};

export default TransportHeader;