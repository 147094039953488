import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useParams} from "react-router-dom";
import Attendance from "./attendance/Attendance";
import {Container} from "../../../../components/shared";
import GroupHeader from "../../../../components/groups/group/GroupHeader";
import GroupInfo from "../../../../components/groups/group/GroupInfo";
import GroupNavbar from "../../../../components/groups/group/GroupNavbar";
import {getGroup} from "../../../../store/actions/groups";
import GroupNotes from "./notes/GroupNotes";
import CreateNoteModal from "../../../../components/students/modals/CreateNoteModal";
import GroupSettings from "./settings/GroupSettings";

const Group = () => {
    const {group, loading} = useSelector(state => state.groups)

    const [showDroppedStudents, setShowDroppedStudents] = useState(() => {
        const savedState = localStorage.getItem('showDroppedStudents');
        return savedState !== null ? JSON.parse(savedState) : false;
    })

    const [openSelectStudentModal, setOpenSelectStudentModal] = useState(false)
    const [selectedStudent, setSelectedStudent] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const changeShowDroppedStudents = () => {
        const newState = !showDroppedStudents;
        setShowDroppedStudents(newState);
        localStorage.setItem('showDroppedStudents', JSON.stringify(newState))
    }

    useEffect(() => {
        dispatch(getGroup(id))
    }, [id])

    return (
        <>
            {selectedStudent && (
                <CreateNoteModal
                    studentId={selectedStudent?._id}
                    close={() => setSelectedStudent(null)}
                    groupId={id}
                    open={selectedStudent}
                    type={'student'}
                />
            )}

            <Container>
                <GroupHeader {...group}/>
                <GroupInfo {...group}/>
                <GroupNavbar
                    {...group}
                    showDroppedStudents={showDroppedStudents}
                    changeShowDroppedStudents={changeShowDroppedStudents}
                    setSelectedStudent={setSelectedStudent}
                    selectedStudent={selectedStudent}
                    setOpenSelectStudentModal={setOpenSelectStudentModal}
                    openSelectStudentModal={openSelectStudentModal}
                />

                <Routes>
                    <Route path='attendance' element={<Attendance {...group} showDroppedStudents={showDroppedStudents}/>}/>
                    <Route path='exercises' element={<p></p>}/>
                    <Route path='performance' element={<p></p>}/>
                    <Route path='notes' element={<GroupNotes id={id}/>}/>
                    <Route path='settings' element={<GroupSettings group={group} />}/>
                </Routes>
            </Container>
        </>
    );
};

export default Group;