import React, { useEffect, useState } from 'react';
import { Container } from "../../../../../components/shared";
import DropdownInputCheckbox from "../../../../../components/shared/dropdowns/DropdownInputCheckbox";
import { AsteriskIcon } from "../../../../../assets/icons";
import Calendar from "../../../../../components/shared/calendar/Calendar";
import { ButtonFilled, ButtonOutlined } from "../../../../../components/shared/buttons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { checkGroupDetails } from "../../../../../utils/groups/checkGroupDetails";
import { changeGroupSettings, getConnect } from "../../../../../store/actions/groups";

const ConnectSettings = () => {
    const { programs } = useSelector(state => state.common);
    const { group } = useSelector(state => state.groups);

    const [data, setData] = useState({
        name: '',
        program: '',
        startDate: '',
        endDate: '',
        lessonDuration: '',
        startTime: '',
        endTime: ''
    });
    const [validationErrors, setValidationErrors] = useState({});
    const [nameBeforeEdit, setNameBeforeEdit] = useState('');

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleStartChangeName = () => {
        setNameBeforeEdit(data.name);
    };

    const handleChangeName = (e) => {
        setData({ ...data, name: e.target.value });
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (data.name) {
                setNameBeforeEdit('');
            } else {
                setData({ ...data, name: nameBeforeEdit });
            }
        }
    };

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const handleChangeTime = (e) => {
        const { name, value } = e.target;
        const timeValue = value.replace(/\D/g, '').slice(0, 4);
        let formattedTime = '';

        if (timeValue.length >= 2) {
            formattedTime = `${timeValue.slice(0, 2)}:${timeValue.slice(2)}`;
        }

        if (formattedTime.length === 5) {
            const [hours, minutes] = formattedTime.split(':').map(Number);
            if (hours > 23 || minutes > 59) {
                return;
            }
        }

        setData({
            ...data,
            [name]: formattedTime
        });
    };

    const handleKeyDown = (e) => {
        const { name, value } = e.target;

        if (e.key === 'Enter') {
            // handleSubmit()
        } else if (e.key === 'Backspace' && value.length === 3 && value.charAt(2) === ":") {
            e.preventDefault();
            setData({
                ...data,
                [name]: value.slice(0, 1)
            });
        }
    };

    const handleSelectProgram = (_, program) => {
        setData({ ...data, program });
    };

    const changeStartDate = (e) => {
        setData({ ...data, date: e });
    };

    const handleDiscard = () => {
        navigate('/connects?page=1&size=10&');
    };

    const handleSubmit = () => {
        const errors = {};

        if (!data.name) {
            errors.name = "Այս դաշտը պարտադիր է"
        }

        if (!data.lessonDuration) {
            errors.lessonDuration = "Այս դաշտը պարտադիր է";
        }

        if (!data.startTime) {
            errors.startTime = "Այս դաշտը պարտադիր է";
        }

        if (Object.keys(errors).length === 0) {
            const payload = {
                name: data.name,
                program: data.program?._id,
                lessonDuration: data.lessonDuration,
                date: data.startDate,
                startTime: data.startTime,
                endTime: data.endTime
            };

            dispatch(changeGroupSettings(id, payload, () => navigate(`/connects/${group._id}`)));
        } else {
            setValidationErrors(errors);
        }
    };

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ""
        }));
    };

    useEffect(() => {
        if (group) {
            setData({
                name: group.name,
                program: group.program,
                startDate: group.startDate,
                endDate: group.endDate,
                lessonDuration: group.lessonDuration,
                startTime: group.schedule[0]?.startTime || '',
                endTime: group.schedule[0]?.endTime || ''
            });
        }
    }, [group]);

    useEffect(() => {
        if (!group) {
            dispatch(getConnect(id));
        }
    }, [id, dispatch, group]);

    return (
        <div>
            <Container>
                <div onFocus={() => handleFocus('name')} className={`flex justify-center ${validationErrors.name ? 'mb-5' : 'mb-[50px]'}`}>
                    {nameBeforeEdit ?
                        <input
                            name="name"
                            className={'w-full text-center text-[50px] text-[#FCC10F] font-bold outline-none'}
                            autoComplete={'off'}
                            autoFocus
                            onFocus={e => e.target.select()}
                            value={data?.name}
                            onChange={handleChangeName}
                            onKeyDown={handleEnter}
                        />
                        :
                        <h2 onClick={handleStartChangeName}
                            className={'text-center text-[50px] text-[#FCC10F] font-bold break-all'}>{data?.name}</h2>
                    }
                </div>
                {validationErrors?.name && <p className={"text-red-500 text-center mb-3"}>{validationErrors.name}</p>}
                <div className={'grid lg:grid-cols-2 grid-cols-1 gap-[30px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կցել ծրագիր</h2>
                            <div>
                                <DropdownInputCheckbox
                                    data={programs}
                                    previewClassName={'px-[30px] rounded-[5px] py-[7px]'}
                                    optionClassName={'px-6'}
                                    name={'program'}
                                    placeholder={'Ընտրել ծրագիր'}
                                    onChange={handleSelectProgram}
                                    selected={[data?.program]}
                                    hideCheckbox
                                    onFocus={() => handleFocus('program')}
                                />
                            </div>
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Դասերի տևողությունը (րոպե) <AsteriskIcon/></h2>
                            <input
                                type={"number"}
                                value={data?.lessonDuration}
                                name={'lessonDuration'}
                                placeholder={'Օրինակ՝ 120'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('lessonDuration')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.lessonDuration &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.lessonDuration}</p>}
                        </div>
                    </div>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>ՍՄԱՐԹամեջի օր <AsteriskIcon/></h2>
                            <div className={'relative'}>
                                <div
                                    className={`absolute w-full bg-white border border-black border-opacity-70 py-[6px] rounded-[5px]`}>
                                    <Calendar displayFormat={'DD.MM.YYYY'}
                                              returnFormat={'YYYY-MM-DD'}
                                              value={group?.date}
                                              setDate={changeStartDate}
                                              placeholder={'օր/ամիս/տարի'}
                                              className={'py-[1px]'}
                                              optionClassName={'px-[30px]'}
                                              onFocus={() => handleFocus('startDate')}
                                              disableClick={true}
                                    />
                                </div>
                                {validationErrors.startDate &&
                                    <p className={"text-red-500 mt-2"}>{validationErrors.startDate}</p>}
                            </div>
                        </div>

                        <div className={'flex items-center gap-[13px] mt-[30px]'}>
                            <div className={'relative flex flex-col gap-2.5'}>
                                <p className={'flex gap-1 mb-[10px]'}>Դասի սկիզբ <AsteriskIcon/></p>
                                <input
                                    type={"text"}
                                    name={'startTime'}
                                    placeholder={'Օրինակ՝ 14։00'}
                                    className={'w-[300px] h-10 border border-black border-opacity-70 rounded-[5px] px-[30px] py-2.5 outline-none'}
                                    onChange={handleChangeTime}
                                    onKeyDown={handleKeyDown}
                                    value={data?.startTime}
                                    onFocus={() => handleFocus('startTime')}
                                />
                                {validationErrors.startTime &&
                                    <p className={"absolute top-full text-red-500 mt-2"}>{validationErrors.startTime}</p>}
                            </div>

                            <div className={'flex flex-col gap-2.5'}>
                                <p className={'flex gap-1 mb-[10px]'}>Դասի ավարտ <AsteriskIcon/></p>
                                <input
                                    type={"text"}
                                    name={'endTime'}
                                    placeholder={'Օրինակ՝ 16։00'}
                                    className={'w-[300px] h-10 border border-black border-opacity-70 rounded-[5px] px-[30px] py-2.5 outline-none'}
                                    onChange={handleChangeTime}
                                    onKeyDown={handleKeyDown}
                                    value={data?.endTime}
                                    onFocus={() => handleFocus('endTime')}
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'flex items-center justify-end'}>
                        <div>
                            <div className={'w-fit flex gap-[30px] items-center mt-[100px]'}>
                                <ButtonOutlined
                                    onClick={handleDiscard}
                                    text={'Չեղարկել'}
                                    className={'w-[176px]'}
                                />
                                <ButtonFilled
                                    onClick={handleSubmit}
                                    text={'Պահպանել'}
                                    className={'w-[176px]'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ConnectSettings;
