export const checkEditRequestFormData = (data) => {
    const errors = [];

    for (const key in data) {
        switch (key) {
            case 'firstName':
            case 'lastName':
            case 'middleName':
                if (!data[key]) {
                    errors.push(key);
                }
                break;
            case 'email':
                if (!data[key]) {
                    errors.push(key);
                } else {
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (!emailRegex.test(data[key])) {
                        errors.push(key);
                    }
                }
                break;
            case 'phoneNumbers':
                for(let i = 0; i < data.length; i++) {
                    if (!data[key][i] || data[key].length !== 13) {
                        errors.push(`phoneNumbers_${i}`);
                    }
                }
                break;
            default:
                break;
        }
    }

    console.log(errors);
    return errors;
};
