import React, {useEffect, useRef, useState} from 'react';
import {
    CrossedPinnedIcon,
    MenuIcon,
    PinnedIcon,
    ReactionIcon,
    ReplyIcon,
    SeenMessageIcon,
    SentMessageIcon,
    TrashIcon,
    UploadIcon,
    YellowReactionIcon,
} from "../../../../assets/icons";
import EmojiPicker from "emoji-picker-react";
import OpenedMediaModal from "../../modals/OpenedMediaModal";
import {ConfirmationModal} from "../../../shared";
import Portal from "../../../shared/Portal";
import moment from "moment";
import coloredPdf from '../../../../assets/images/ColoredPdf.png'
import {useDispatch, useSelector} from "react-redux";
import {downloadFileFromSrc} from "../../../../utils/fileDownloads/downloadFile";
import {reactToMessage, unpinAllMessages} from "../../../../socket/chats"
import ShowSeenUsersModal from "../../modals/ShowSeenUsersModal";
import {useMediaQuery} from "react-responsive";
import {AudioVisualizer} from "react-audio-visualize";
import VoiceMessagePlayer from "../items/VoiceMessagePlayer";
import {useClick} from "../../../../hooks/useClick";

const Message = ({
                     isSender,
                     isLast,
                     showPinnedMessages,
                     handleDeleteMessage,
                     messageObj,
                     pinUnpinMessage,
                     handleReplyToMessage,
                     scrollableDivRef,
                     userCanSendMessage,
                     setShowPinnedMessages,
                     setPinnedMessages,
                     pinnedMessagesCount,
                     setReplyToMessage,
                     setReplyingTo,
                     chatType,
                     setIsEditingMessage,
                     setEditMessage,
                     closed,
                     menuCLick,
                     openMenuMessageId,
                     setOpenMenuMessageId,
                     handleGoToMessage,
                     isMediaOpen,
                     setIsMediaOpen
                 }) => {
    const {authData} = useSelector(state => state.auth)
    const {socket} = useSelector(state => state.socket)
    const {chatRoom, reactions, messages} = useSelector(state => state.chats)
    const [unpinMessages, setUnpinMessages] = useState(null)

    const [showMenu, setShowMenu] = useState(null)
    const [selectedReaction, setSelectedReaction] = useState(null)
    const [isHoveringReaction, setIsHoveringReaction] = useState(false)
    const [openMedia, setOpenMedia] = useState(null)

    const [messageToDelete, setMessageToDelete] = useState(null)
    const [menuPosition, setMenuPosition] = useState({top: 0, left: 0})
    const [hoveringPdf, setHoveringPdf] = useState(null)
    const [isPinned, setIsPinned] = useState(messageObj.pinned)
    const [hoveredUserId, setHoveredUserId] = useState(null)
    const [longPressTimer, setLongPressTimer] = useState(null)
    const [tooltipPosition, setTooltipPosition] = useState({top: 0, left: 0})
    const [showSeenUsers, setShowSeenUsers] = useState(null)
    const [emojiPickerPosition, setEmojiPickerPosition] = useState({top: 0, left: 0})

    const sentTime = moment(messageObj.createdAt).format('HH:mm')

    const dispatch = useDispatch()

    const hasPdfFiles = messageObj.files?.some(file => file.extname.toLowerCase() === '.pdf')
    const hasVideoFiles = messageObj.files?.some(file => ['.mp4', '.mkv', '.webm', '.avi', '.mov', '.wmv', '.flv'].includes(file.extname.toLowerCase()))
    const hasImageFiles = messageObj.files?.some(file => ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.jfif', '.svg'].includes(file.extname.toLowerCase()))

    const ref = useRef(null)
    const menuButtonRef = useRef(null)

    useClick(ref, () => setShowMenu(null), [])

    const isPhone = useMediaQuery({maxWidth: 640})

    const onReactionClick = (event) => {
        setSelectedReaction(prevReaction => prevReaction === event.emoji ? null : event.emoji)
        reactToMessage(socket, messageObj._id, event.unified)
    }

    const handleMenuClick = () => {
        const rect = menuButtonRef.current.getBoundingClientRect()
        const scrollY = window.scrollY
        const scrollX = window.scrollX
        const offset = 8
        const menuHeight = 200

        const spaceBelow = window.innerHeight - rect.bottom
        const spaceAbove = rect.top

        let topPosition = rect.bottom + scrollY + offset
        if (spaceBelow < menuHeight && spaceAbove > menuHeight) {
            topPosition = rect.top + scrollY - menuHeight - offset - rect.height
        }

        setMenuPosition({
            top: topPosition,
            left: rect.left + scrollX,
        })

        setShowMenu(true)
        setOpenMenuMessageId(messageObj._id)
    }


    const handleMouseEnter = (userId, event) => {
        const rect = event.target.getBoundingClientRect()
        setTooltipPosition({
            top: rect.bottom + window.scrollY + 10,
            left: rect.right + window.scrollX,
        })
        setHoveredUserId(userId)
    }

    const onReactionIconMouseEnter = (event) => {
        const rect = event.currentTarget.getBoundingClientRect()
        setEmojiPickerPosition({
            top: rect.top + window.scrollY - 50,
            left: messageObj.message.length < 30 ? rect.left + window.scrollX - 200 : rect.left + window.scrollX - 290,
        })
        setIsHoveringReaction(true)
    }

    const onReactionIconMouseLeave = () => {
        setIsHoveringReaction(false)
    }

    const handleMouseLeave = () => {
        setHoveredUserId(null)
    }

    const handleUnpinAllMessages = () => {
        unpinAllMessages(socket, chatRoom).then(() => {
            setPinnedMessages(null)
            setShowPinnedMessages(null)
            setIsPinned(null)
        })
    }

    const handleEditMessage = () => {
        setIsEditingMessage(true)
        setEditMessage(messageObj)
        setReplyToMessage(null)
        setReplyingTo(null)
    }

    const handleOpenMedia = (src) => {
        setOpenMedia(src)
        setIsMediaOpen(true)
    }

    const renderFile = (file, index) => {
        const extname = file.extname.toLowerCase()

        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp', '.jfif', '.svg']
        const videoExtensions = ['.mp4', '.mkv', '.webm', '.avi', '.mov', '.wmv', '.flv']

        if (imageExtensions.includes(extname)) {
            return (
                <img
                    src={file.src}
                    alt='image'
                    className='max-w-[57px] max-h-[76px] lg:max-w-[100px] lg:max-h-[100px] object-cover rounded-[5px] cursor-pointer'
                    onClick={() => handleOpenMedia(file.src)}
                />
            )
        } else if (videoExtensions.includes(extname)) {
            return (
                <video
                    src={file.src}
                    onClick={() => handleOpenMedia(file.src)}
                    className='w-[77px] lg:w-[150px] max-h-[47px] lg:max-h-[100px] object-cover rounded-[5px] cursor-pointer'
                />
            )
        } else if (extname === '.pdf') {
            return (
                <div
                    className='flex items-center gap-2.5 cursor-pointer'
                    onMouseEnter={() => setHoveringPdf(index)}
                    onMouseLeave={() => setHoveringPdf(null)}
                >
                    {hoveringPdf === index
                        ?
                        <button
                            onClick={() => downloadFileFromSrc(file.src)}
                            className={'cursor-pointer'}
                        >
                            <UploadIcon/>
                        </button>
                        :
                        <img src={coloredPdf} alt={'pdf'}/>
                    }
                    <span className={'text-[14px] lg:text-[16px]'}>{file.name}</span>
                </div>
            )
        }
    }

    const handlePinUnpinMessage = () => {
        setIsPinned(!isPinned)
        pinUnpinMessage(messageObj._id)
    }

    const handleDeleteClick = () => {
        handleDeleteMessage(messageObj._id)
    }

    const messageRef = useRef(null)

    const handleLongPress = () => {
        if (messageRef.current) {
            const rect = messageRef.current.getBoundingClientRect()
            setMenuPosition({
                top: rect.bottom + window.scrollY,
                left: rect.left + window.scrollX,
            })
        }
        setShowMenu(true)
        setOpenMenuMessageId(messageObj._id)
    }

    const handleTouchStart = () => {
        setLongPressTimer(setTimeout(handleLongPress, 500))
    };

    const handleTouchEnd = () => {
        if (longPressTimer) {
            clearTimeout(longPressTimer);
        }
    }

    const handleReplyMessage = () => {
        handleReplyToMessage(messageObj)
        setShowMenu(null)
        setIsEditingMessage(null)
    }

    const handleCloseMediaModal = () => {
        setOpenMedia(null)
        setIsMediaOpen(null)
    }

    useEffect(() => {
        const handleScroll = () => {
            setShowMenu(null);
            setIsHoveringReaction(false);
            setOpenMenuMessageId(null)
        };

        const scrollableDiv = scrollableDivRef.current;
        scrollableDiv?.addEventListener('scroll', handleScroll);

        return () => scrollableDiv?.removeEventListener('scroll', handleScroll);
    }, [scrollableDivRef])

    useEffect(() => {
        const reaction = reactions?.reactions?.find(([code, {isMyReaction}]) => isMyReaction);
        if (reaction) {
            setSelectedReaction(String.fromCodePoint(`0x${reaction[0]}`));
        }
    }, [reactions])

    useEffect(() => {
        const reaction = reactions?.reactions?.find(([code, {isMyReaction}]) => isMyReaction);
        if (reaction) {
            messageObj.reactions = messageObj.reactions || [];
            const reactionIndex = messageObj.reactions.findIndex(r => r.code === reaction[0]);
            if (reactionIndex !== -1) {
                messageObj.reactions[reactionIndex] = {...messageObj.reactions[reactionIndex], isMyReaction: true};
            } else {
                messageObj.reactions.push({code: reaction[0], isMyReaction: true});
            }
        }
    }, [selectedReaction])

    useEffect(() => {
        document.body.style.overflow = openMedia ? 'hidden' : 'auto'
    }, [openMedia])

    return (
        <div>
            {showSeenUsers &&
                <ShowSeenUsersModal
                    data={messageObj.seenBy}
                    close={() => setShowSeenUsers(null)}
                />
            }
            {messageToDelete &&
                <ConfirmationModal
                    close={() => setMessageToDelete(null)}
                    warningText={'Վստա՞հ եք, որ ցանկանում եք ջնջել այս նամակը։'}
                    icon={<TrashIcon width={50} height={50}/>}
                    confirm={handleDeleteClick}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {unpinMessages &&
                <ConfirmationModal
                    close={() => setUnpinMessages(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք հանել ${pinnedMessagesCount} նամակի ամրացումը։`}
                    icon={<CrossedPinnedIcon width={50} height={50} color={'#FCC10F'}/>}
                    confirm={handleUnpinAllMessages}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {openMedia &&
                <OpenedMediaModal
                    {...messageObj}
                    message={messageObj.message}
                    id={messageObj._id}
                    isSender={isSender}
                    close={handleCloseMediaModal}
                />
            }

            <div
                ref={messageRef}
                onTouchStart={isPhone ? handleTouchStart : null}
                onTouchEnd={isPhone ? handleTouchEnd : null}
                className={`${openMenuMessageId && openMenuMessageId === messageObj._id ? 'fixed' : 'relative'} flex gap-4 my-2 message ${!isPhone && 'mx-[30px]'} ${isSender && 'justify-end'} ${messageObj.type === 'system' && 'justify-center'} ${isPhone && 'px-2'} message ${openMenuMessageId === messageObj._id ? 'active-message' : ''}`}
            >
                {!isSender && messageObj.type !== 'system' &&
                    <img
                        src={messageObj.author?.profileImage}
                        alt={'profileImage'}
                        className={'w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] object-cover rounded-full pointer-events-none select-none mt-4'}
                    />
                }
                <div className={'flex flex-col'}>
                    {messageObj.repliesTo &&
                        <div
                            onClick={() => handleGoToMessage(messageObj?.repliesTo?._id)}
                            className={'w-full max-w-[285px] lg:max-w-[542px] bg-gray-200 rounded-t-[5px] rounded-l-[5px] px-5 py-2.5'}>
                            <div className={'flex flex-col lg:gap-2.5'}>
                                <div className={'flex items-center gap-2.5'}>
                                    <ReplyIcon/>
                                    <span
                                        className={'text-[14px] text-black text-opacity-80 bold'}>{messageObj?.repliesTo.author?.fullName}</span>
                                </div>
                                <div className={'text-[14px]'}>{messageObj?.repliesTo?.message}</div>
                            </div>
                        </div>
                    }
                    <div className={'relative flex items-center justify-end gap-2.5'}>
                        {messageObj.type === 'system'
                            ?
                            <p className={`${isPhone && 'text-center px-[30px]'} text-[12px] text-black text-opacity-80`}>{messageObj.message}</p>
                            :
                            <>
                                {isSender && !isPhone && !messageObj.deleted &&
                                    <button
                                        ref={menuButtonRef}
                                        onClick={handleMenuClick}
                                        className={`${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                                    >
                                        <MenuIcon/>
                                    </button>
                                }
                                <div ref={ref}
                                     className={`relative ${messageObj.files?.length && hasVideoFiles ? 'max-w-[645px]' : hasImageFiles ? 'max-w-[590px]' : 'max-w-[542px]'} ${isPhone && 'max-w-[285px]'} ${hasVideoFiles ? 'bg-0' : 'bg-white'} rounded-[5px] ${messageObj.repliesTo && 'rounded-tr-none'} mt-4`}>
                                    <div className={'relative py-2.5 text-[14px]'}>
                                        {!isSender &&
                                            <div className={'w-full relative'}>
                                                <h3 className={'w-fit bold px-5 pb-2.5 text-[14px] text-black text-opacity-80'}>{messageObj.author?.fullName}</h3>
                                                <div className={'absolute right-0 bottom-[82%] mr-2.5'}>
                                                    <div className={'flex items-center'}>
                                                        {messageObj.reactions && messageObj.reactions?.length > 0 &&
                                                            <div
                                                                className='absolute right-5 transform -translate-x-1/2'>
                                                                {messageObj.reactions.map((reaction, index) => {
                                                                    return (
                                                                        reaction[1]?.isMyReaction && (
                                                                            <span key={index} className='text-sm'>
                                                                                {String.fromCodePoint(parseInt(reaction[0], 16))}
                                                                            </span>
                                                                        )
                                                                    );
                                                                })}
                                                            </div>
                                                        }
                                                        <div
                                                            onMouseEnter={onReactionIconMouseEnter}
                                                            onMouseLeave={onReactionIconMouseLeave}
                                                            className={'group'}>
                                                            {isHoveringReaction ?
                                                                <div className={'relative'}>
                                                                    <div className={'flex flex-col'}>
                                                                        <div
                                                                            className={'absolute -top-1.5 bg-white w-[5px] h-[5px] -ml-[6px] rounded-full'}></div>
                                                                        <div
                                                                            className={'absolute -top-0.5 bg-white w-1 h-1 -ml-[3px] rounded-full'}></div>
                                                                        <div
                                                                            className={'absolute top-0.5 bg-white w-[3px] h-[3px] rounded-full'}></div>
                                                                    </div>
                                                                    <div
                                                                        className={'bg-white rounded-full p-1 cursor-pointer'}>
                                                                        <YellowReactionIcon/>
                                                                    </div>
                                                                </div> :
                                                                <div
                                                                    className={'bg-gray-100 rounded-full p-1 cursor-pointer'}>
                                                                    <ReactionIcon/>
                                                                </div>
                                                            }
                                                            {isHoveringReaction &&
                                                                <Portal top={emojiPickerPosition.top}
                                                                        left={emojiPickerPosition.left}>
                                                                    <EmojiPicker
                                                                        reactionsDefaultOpen={true}
                                                                        reactions={["2764-fe0f", "1f604", "1f44d", "2705", "1f44c", "1f914"]}
                                                                        onEmojiClick={onReactionClick}
                                                                        searchDisabled={true}
                                                                    />
                                                                </Portal>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        {isPinned &&
                                            <div className={'absolute -right-2 -top-2'}><PinnedIcon color={'#FCC10F'}/>
                                            </div>
                                        }
                                        {messageObj.voice && (
                                            <VoiceMessagePlayer voiceUrl={messageObj.voice} messageId={messageObj._id}/>
                                        )}
                                        <div className={`flex items-center`}>
                                            <div className={'flex flex-col'}>
                                                <div
                                                    className={`flex ${isSender && hasVideoFiles ? 'justify-end' : 'justify-start'} ${hasPdfFiles ? 'flex-col gap-2.5' : 'flex-wrap'} gap-1 ${hasVideoFiles && 'gap-y-2.5'} ${hasPdfFiles && messageObj.message && 'border-b py-2'} ${isSender ? 'mx-2.5' : 'px-5'}`}>
                                                    {messageObj.files?.map((file, index) => (
                                                        <div key={index} className={``}>
                                                            {renderFile(file, index)}
                                                        </div>
                                                    ))}
                                                </div>
                                                <div
                                                    className={`flex items-center gap-2.5 ${isSender ? 'px-2.5' : 'px-5'}`}>
                                                    {messageObj.deleted &&
                                                        <TrashIcon width={17} height={17} color={'#000'}/>
                                                    }
                                                    <span
                                                        className={`text-[14px] ${messageObj.deleted && 'text-black text-opacity-70'}`}>{messageObj.message}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!isSender && !isPhone && !messageObj.deleted &&
                                    <button
                                        ref={menuButtonRef}
                                        onClick={handleMenuClick}
                                        className={`${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                                    >
                                        <MenuIcon/>
                                    </button>
                                }
                            </>
                        }

                        {showMenu &&
                            <Portal top={menuPosition.top} left={menuPosition.left} alignment="center">
                                <div
                                    ref={ref}
                                    className={`z-50 w-[199px] flex flex-col gap-4 bg-white py-[17px] lg:py-6 border border-black border-opacity-50 rounded-[5px] ${isPhone && !isSender ? 'ml-[55px]' : isPhone && 'ml-[160px] -mt-6'}`}
                                >
                                    <>
                                        {isSender && authData?.role !== 'student' &&
                                            <p
                                                onClick={handleEditMessage}
                                                className={'px-2.5 lg:px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer text-[14px] lg:text-[16px]'}
                                            >
                                                Խմբագրել
                                            </p>
                                        }
                                        <p
                                            onClick={handleReplyMessage}
                                            className={'px-2.5 lg:px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer text-[14px] lg:text-[16px]'}
                                        >
                                            Պատասխանել
                                        </p>
                                        {authData?.role !== 'student' &&
                                            <p
                                                onClick={handlePinUnpinMessage}
                                                className={'px-2.5 lg:px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer text-[14px] lg:text-[16px]'}
                                            >
                                                {isPinned ? 'Ապաամրացնել' : 'Ամրացնել'}
                                            </p>
                                        }
                                        {isSender && authData.role !== 'student' &&
                                            <p
                                                onClick={() => setMessageToDelete(true)}
                                                className={'px-2.5 lg:px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer text-[14px] lg:text-[16px]'}
                                            >
                                                Ջնջել
                                            </p>
                                        }
                                    </>
                                </div>
                            </Portal>
                        }
                    </div>
                    {messageObj.type !== 'system' &&
                        <div className={`text-[12px] ${isSender && 'flex justify-end'} pt-2.5`}>{sentTime}</div>
                    }
                    {isSender && chatType === 'group' && messageObj.seenBy.length
                        ?
                        <div className={'flex gap-1 items-center justify-end mt-2.5'}>
                            {messageObj.seenBy.slice(0, 3).map(user => {
                                const formattedDate = moment(user.date).format('HH:mm')
                                return (
                                    <div key={user.user._id} className={'flex items-center gap-1'}>
                                        <div
                                            className={'relative group flex items-center gap-[10px]'}
                                            onMouseEnter={(event) => handleMouseEnter(user.user._id, event)}
                                            onMouseLeave={handleMouseLeave}
                                        >
                                            <img
                                                src={user.user?.profileImage}
                                                alt={'userImage'}
                                                className={'w-[13px] h-[13px] lg:w-[17px] lg:h-[17px] object-cover rounded-full'}
                                            />
                                            {hoveredUserId === user.user._id && (
                                                <Portal top={tooltipPosition.top} left={tooltipPosition.left}>
                                                    <p className={`flex gap-1 text-[12px] border rounded-[3px] p-2.5 bg-white custom-shadow`}>
                                                        <span>{user.user.fullName}</span>
                                                        <span>{formattedDate}</span>
                                                    </p>
                                                </Portal>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                            {messageObj.seenBy.length > 3 &&
                                <div className={'relative group flex items-center gap-[10px]'}>
                                    <span
                                        onClick={() => setShowSeenUsers(true)}
                                        className={'text-[12px] text-black text-opacity-80 cursor-pointer'}>
                                        +{messageObj.seenBy.length - 3}
                                    </span>
                                    <div
                                        className={'absolute z-[999] w-[293px] right-full bottom-full border rounded-[3px] p-2.5 hidden flex-col gap-2.5 bg-white shadow-md pointer-events-none group-hover:flex transition'}>
                                        {messageObj.seenBy.slice(3).map(user => {
                                            const formattedDate = moment(user.date).format('HH:mm')
                                            return (
                                                <div key={user.user._id}>
                                                    <p className={`flex gap-1 text-[12px]`}>
                                                        <span>{user.user.fullName}</span>
                                                        <span>{formattedDate}</span>
                                                    </p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        isSender && chatType === 'group' &&
                        <div className={'flex justify-end'}><SentMessageIcon width={isPhone && 13}
                                                                             height={isPhone && 13}/></div>
                    }
                    {isSender && chatType === 'dm' &&
                        <div className={'flex justify-end pt-2.5'}>
                            {messageObj.seenBy.length
                                ?
                                <SeenMessageIcon width={isPhone && 13} height={isPhone && 13}/>
                                :
                                <SentMessageIcon width={isPhone && 13} height={isPhone && 13}/>
                            }
                        </div>
                    }
                </div>
            </div>
            {isLast && showPinnedMessages &&
                <div onClick={() => setUnpinMessages(true)}
                     className={'flex items-center justify-center gap-2.5 lg:gap-5 pb-[30px] cursor-pointer'}>
                    <CrossedPinnedIcon/>
                    <p className={'text-[#FCC10F] text-[14px] lg:text-[16px]'}>Հանել բոլոր ամրացված նամակները</p>
                </div>
            }
            {isLast && closed
                ?
                <div
                    className={`w-full ${isPhone && 'text-center px-[30px]'} flex items-center justify-center gap-5 text-[12px] pb-2.5 text-black text-opacity-80 cursor-pointer`}>
                    <p>Այս խումբն արդեն փակ է, և դուք չեք կարող ստանալ և գրել նամակներ։</p>
                </div>
                :
                isLast && !closed && !userCanSendMessage &&
                <div
                    className={`w-full ${isPhone && 'text-center px-[30px]'} flex items-center justify-center gap-5 text-[12px] pb-2.5 text-black text-opacity-80 cursor-pointer`}>
                    <p>Դուք այլևս այս խմբի մասնակից չեք և չեք կարող ստանալ և գրել նամակներ։</p>
                </div>
            }
        </div>
    )
}

export default Message