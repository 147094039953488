import React, {useRef, useState} from 'react';
import {Container} from "../shared";
import {Link} from "react-router-dom";
import logo from "../../assets/images/logo.png";
import {LogoutIcon, ProfileIcon} from "../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../../store/actions/auth";
import {useOutsideClick} from "../../hooks/useOutsideClick";

const CheckinHeader = () => {
    const {authData} = useSelector(state => state.auth)

    const [showMenu, setShowMenu] = useState(null)

    const dispatch = useDispatch()
    const ref = useRef(null)

    useOutsideClick(ref, () => setShowMenu(null))

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <div
            className={`fixed w-[390px] lg:w-full top-0 right-0 left-0 z-[999999999999] py-1 bg-white lg:border-b lg:border-b-[#FCC10F]`}>
            <Container>
                <div className={'flex justify-between items-center'}>
                    <div>
                        <Link to={'/'}>
                            <img src={logo} className={'w-[30px] lg:w-10'} alt={'logo'}/>
                        </Link>
                    </div>

                    <div className={`relative flex items-center`}>
                        <img
                            src={authData.profileImage}
                            alt={'testingImage'}
                            className={`w-[30px] h-[30px] lg:w-10 lg:h-10 rounded-full ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'} select-none object-cover`}
                            onClick={() => setShowMenu(!showMenu)}
                        />

                        {showMenu &&
                            <div ref={ref}
                                 className={`absolute z-50 w-[236px] max-h-[100px] top-full flex flex-col gap-2 bg-white right-0 mt-[10px] py-2 border border-black rounded-[5px]`}>
                                <>
                                    <Link to={'/'}
                                          className={'flex items-center gap-5 px-5 transition hover:bg-gray-200 py-1 cursor-pointer'}
                                          onClick={handleLogout}
                                    >
                                        <LogoutIcon/>
                                        <span className={'text-[14px] lg:text-[16px]'}>Ելք</span>
                                    </Link>
                                </>
                            </div>
                        }
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default CheckinHeader