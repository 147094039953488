import {Programs} from "./index"
import SMARTBlock from "./SMARTBlock";
import {SMARTConnectsIcon, SMARTVisitsIcon, SMARTWorkshopsIcon} from "../../../assets/icons";
import PersonalInfo from "./PersonalInfo";


const ProfileData = ({
                         community,
                         phoneNumbers,
                         email,
                         smartDegrees,
                         gender,
                         changeCommunityField,
                         changeTransportStopName,
                         changePhoneNumber,
                         changeClothingSize,
                         studentGroups,
                         birthday,
                         transportStop,
                         changeField,
                         smartConnects,
                         smartTrips,
                         smartWorkshops,
                         editMode,
                         clothingSize,
                         changeBirthdayDate,
                         ageGroup,
                         addAward,
                         awards,
                     }) => {
    return (
        <div className={'border border-l-0'}>
            <PersonalInfo
                editMode={editMode}
                community={community}
                phoneNumbers={phoneNumbers}
                email={email}
                gender={gender}
                birthday={birthday}
                transportStop={transportStop}
                changeField={changeField}
                changeCommunityField={changeCommunityField}
                changePhoneNumber={changePhoneNumber}
                changeClothingSize={changeClothingSize}
                changeBirthdayDate={changeBirthdayDate}
                changeTransportStopName={changeTransportStopName}
                clothingSize={clothingSize}
                ageGroup={ageGroup}
                addAward={addAward}
                awards={awards}
                smartDegrees={smartDegrees}
            />

            {studentGroups && <Programs studentGroups={studentGroups}/>}

            <div className={'flex flex-col'}>
                <SMARTBlock
                    selected={smartConnects}
                    title={'ՍՄԱՐԹամեջեր'}
                    icon={<SMARTConnectsIcon/>}
                />
                <div className={'border-t'}>
                    <SMARTBlock
                        selected={smartTrips}
                        title={'ՍՄԱՐԹ այցեր'}
                        icon={<SMARTVisitsIcon/>}
                    />
                </div>
                <div className={'border-t'}>
                    <SMARTBlock
                        selected={smartWorkshops}
                        title={'ՍՄԱՐԹ աշխատարան'}
                        icon={<SMARTWorkshopsIcon/>}
                    />
                </div>
            </div>
        </div>
    );
};


export default ProfileData;