import React, {useEffect, useState} from 'react'
import {Route, Routes, useLocation, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {getFormNames} from "../../store/actions/forms";
import {getCommunityNames, getMemberRoles, getMembersNamesList, getProgramNames} from "../../store/actions/common";
import {routes} from "../../common/routes";
import {io} from "socket.io-client";
import {setSocket} from "../../store/actions/socket";
import {useChatEvents} from "../../hooks/socket/useChatEvents";
import {getNewMessagesCount} from "../../socket/chats";
import {
    getNewNotificationsCount
} from "../../socket/notifications";

const Main = () => {
    const {authData} = useSelector(state => state.auth)
    const {socket} = useSelector(state => state.socket)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useChatEvents(socket)

    useEffect(() => {
        // const newSocket = io(`http://10.100.7.26:5000?token=${authData.token}`)
        const newSocket = io(`https://api.smartcitizen.am?token=${authData.token}`)
        // const newSocket = io(`http://localhost:5000?token=${authData.token}`)
        // const newSocket = io(`http://10.100.21.188:5000?token=${authData.token}`)

        dispatch(setSocket(newSocket))

        getNewMessagesCount(newSocket, dispatch)
        getNewNotificationsCount(newSocket, dispatch)

        return () => {
            newSocket?.emit('leave_room', `user_${authData.id}`)
            newSocket?.disconnect()
        }
    }, [authData])

    useEffect(() => {
        dispatch(getFormNames())
        dispatch(getCommunityNames())
        dispatch(getProgramNames())
        dispatch(getMemberRoles())

        if (!authData) {
            navigate("/")
        }
    }, [])

    return (
        <Routes>
            {routes[authData?.role]?.map(route => <Route key={route.path} path={route.path} element={route.element}/>)}
        </Routes>
    )
};

export default Main;