import React, {useEffect, useState} from 'react'
import '../../../../index.css'
import {PhoneInput} from "../../../shared";
import Calendar from "../../../shared/calendar/Calendar";
import {Dropdown} from "../../../shared/dropdowns";

const disallowedCharset = '1234567890'

const Answer = ({type, placeholder, options, focused}) => {
    const [answer, setAnswer] = useState('')
    const [selectedOption, setSelectedOption] = useState(null)

    const handleSelectOption = (e) => {
        setSelectedOption(e.target.value)
    }

    useEffect(() => {
        setAnswer('')
    }, [type]);

    switch (type) {
        case 'dropdown':
            return options && (
                <div className={`${!focused ? 'w-full' : 'max-w-[1054px]'} border border-black rounded-[5px] mt-2.5`}>
                    <Dropdown value={selectedOption || placeholder}
                              data={options}
                              placeholder={placeholder}
                              onChange={handleSelectOption} />
                </div>
            )
        case 'shortAnswer':
        case 'staticText':
        case 'text':
            return (
                <div className={`${!focused ? 'w-full' : 'max-w-[1054px]'} px-7 border border-black py-0.5 border-opacity-70 mt-2.5 rounded-[5px]`}>
                    <input type="text" className={`w-full outline-none`} placeholder={placeholder}/>
                </div>
            )
        case 'calendar':
            return (
                <div className={`${!focused ? 'w-full' : 'max-w-[1054px]'} border border-black border-opacity-70 mt-2.5 rounded-[5px] px-[2px]`}>
                    <Calendar value={answer} displayFormat={'DD/MM/YYYY'} returnFormat={'YYYY-MM-DD'} placeholder={placeholder} setDate={setAnswer} className={'py-[3px]'}/>
                </div>
            )
        case 'phoneNumber':
            return (
                <div className={`${!focused ? 'w-full' : 'max-w-[1054px]'} border border-black border-opacity-70 mt-2.5 rounded-[5px]`}>
                    <PhoneInput answer={''} onChange={x => x} className={'py-[3px]'}/>
                </div>
            )
        default:
            break;
    }
}

export default Answer