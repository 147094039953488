import React, {useEffect} from 'react';
import {Container} from "../../../../../shared";
import DropdownInputCheckbox from "../../../../../shared/dropdowns/DropdownInputCheckbox";
import {driverLicenseData} from "../../../../../../common/dropdownOptions";
import SelectedFilters from "../../../../../shared/SelectedFilters";
import {ButtonFilled, ButtonOutlined} from "../../../../../shared/buttons";
import {useDispatch, useSelector} from "react-redux";
import {getVehicleFilters} from "../../../../../../store/actions/common";
import Calendar from "../../../../../shared/calendar/Calendar";

const RoutesHistoryFilter = ({handleChange, changeSelectedFilters, filters, discardFilters, submitFilters}) => {
    const {vehicleFilters} = useSelector(state => state.common)

    const dispatch = useDispatch()

    const handleEnter = (e) => {
        e.key === 'Enter' && submitFilters()
    }

    useEffect(() => {
        dispatch(getVehicleFilters())
    }, [])

    return (
        <div className={'custom-shadow mt-5'}>
            <Container>
                <div className={'relative mt-[14px]'}>
                    <div className={'absolute w-[620px] border border-black border-opacity-70 rounded-[5px] py-[9px]'}>
                        <Calendar
                            placeholder={'Սկիզբ'}
                            pr
                            optionClassName={'px-[30px]'}
                        />
                    </div>
                    <div
                        className={'absolute right-0 w-[620px] border border-black border-opacity-70 rounded-[5px] py-[9px]'}>
                        <Calendar
                            placeholder={'Ավարտ'}
                            optionClassName={'px-[30px]'}
                        />
                    </div>
                </div>


                <div className={'flex justify-between gap-3 pt-[85px] pb-[17px] pr-1'}>
                    <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters}/>

                    <div className={'flex gap-3 items-center'}>
                        <ButtonOutlined
                            onClick={discardFilters}
                            text={'Չեղարկել'}
                        />
                        <ButtonFilled
                            onClick={() => submitFilters()}
                            text={'Կիրառել'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default RoutesHistoryFilter;