import React, { useEffect, useRef, useState } from 'react';
import { ArmenianFlag } from "../../assets/icons";
import { useMediaQuery } from "react-responsive";

const charset = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
const acceptedCodes = ['77', '44', '99', '96', '33', '43', '41', '55', '95', '93', '98', '97', '91', '66', '11', '94'];

const PhoneInput = ({ answer, onChange, onTouch, name, className }) => {
    const initialCode = answer?.slice(4, 6) || '';
    const initialNumber = answer?.slice(6, 12) || '';

    const [code, setCode] = useState(initialCode);
    const [number, setNumber] = useState(initialNumber);
    const [isInvalidCode, setIsInvalidCode] = useState(false);
    const [value, setValue] = useState(`+374${code}${number}`);

    const codeRef = useRef(null);
    const numbersRef = useRef(null);
    const containerRef = useRef(null);

    const isPhone = useMediaQuery({ maxWidth: 640 });

    const handleFocus = (e) => {
        if (containerRef.current.contains(e.target)) {
            const rect = codeRef.current.getBoundingClientRect();
            const x = e.clientX - rect.left;

            if (x < codeRef.current.offsetWidth) {
                codeRef.current.focus();
            } else {
                numbersRef.current.focus();
            }
        }

        onTouch && onTouch();
    };

    const changeCode = (e) => {
        const input = e.target.value;
        const lastChar = input[input.length - 1];
        const lastCharValid = lastChar ? charset.includes(lastChar) : true;
        const newCode = input.length <= 2 && lastCharValid ? input : code;

        if (newCode.length === 2 && acceptedCodes.includes(newCode)) {
            numbersRef.current.focus();
        }

        setCode(newCode);
    };

    const changeNumber = (e) => {
        const input = e.target.value;
        const lastChar = input[input.length - 1];
        const lastCharValid = lastChar ? charset.includes(lastChar) : true;
        const newNumber = input.length <= 6 && lastCharValid ? input : number;

        setNumber(newNumber);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Backspace') {
            const selectionStart = numbersRef.current.selectionStart;

            if (selectionStart === 0) {
                e.preventDefault();
                codeRef.current.focus();
                codeRef.current.setSelectionRange(codeRef.current.value.length, codeRef.current.value.length);
            }
        }
    };

    useEffect(() => {
        if (code.length === 2 && acceptedCodes.includes(code)) {
            setIsInvalidCode(false);
        } else {
            setIsInvalidCode(true);
        }

        setValue(`+374${code}${number}`);
    }, [code, number]);

    useEffect(() => {
        setValue(`+374${code}${number}`);
    }, [code, number]);

    useEffect(() => {
        onChange(value, name);
    }, [value]);

    return (
        <div ref={containerRef} className={`flex items-center px-5 lg:px-7 ${className || 'py-[5px]'}`} onClick={handleFocus}>
            <div className={'w-5 h-5'}>
                <ArmenianFlag width={isPhone ? 17 : 20} height={isPhone ? 17 : 20} />
            </div>
            <span className={`mx-2.5 text-[14px] text-black ${isPhone ? 'text-opacity-70' : 'text-opacity-100'} lg:text-[16px] lg:mx-[15px]`}>+374</span>
            <span className={`text-black ${isPhone ? 'text-opacity-70' : 'text-opacity-100'}`}>
                (<input type={"text"} ref={codeRef} onChange={changeCode} value={code}
                        className={`w-6 outline-none text-center text-black ${isInvalidCode && 'text-[#FF0707] caret-black'}`}
                        placeholder={'__'}/>)
            </span>
            <input type={"text"} ref={numbersRef} onChange={changeNumber} value={number} onKeyDown={handleKeyDown}
                   className={'w-20 ml-1 outline-none'}
                   maxLength={6} placeholder={'______'}/>
        </div>
    );
};

export default PhoneInput;
