import React, {useState} from 'react';
import {CopyIcon, EditIcon, ExternalFormIcon, InnerFormIcon, ListTaskIcon, TrashIcon} from "../../assets/icons";
import ButtonHoverText from "../shared/buttons/ButtonHoverText";
import {useDispatch, useSelector} from "react-redux";
import {changeRequestFormStatus, deleteRequestForm} from "../../store/actions/forms";
import FormStatusDropdown from "../shared/dropdowns/FormStatusDropdown";
import {Link, useNavigate} from "react-router-dom";
import externalForm from "../../assets/images/ExternalForm.png";
import innerForm from "../../assets/images/InnerForm.png"
import {roles} from "../../common/roles";
import {useMediaQuery} from "react-responsive";

const statusOptions = [
    {id: 1, value: 'public', name: 'Հրապարակել'},
    {id: 2, value: 'draft', name: 'Սևագիր'},
    {id: 3, value: 'closed', name: 'Փակել հայտը'},
]

const displayNames = {
    public: 'Հրապարակված',
    draft: 'Սևագիր',
    closed: 'Փակված',
}

const FormItem = ({name, status, _id, type}) => {
    const {authData} = useSelector(state => state.auth)
    const [copied, setCopied] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isPhone = useMediaQuery({maxWidth: 640})

    const handleCopyLink = () => {
        const externalLink = `${window.location.origin}/forms/view/${_id}`;
        navigator.clipboard.writeText(externalLink)
            .then(() => {
                setCopied(true)
                setTimeout(() => setCopied(false), 1000)
            })
    };

    const redirectToRequests = () => {
        navigate(`/requests?size=10&page=1&archive=false&formN=${_id}`)
    }

    const handleSelectStatus = (e) => {
        dispatch(changeRequestFormStatus(_id, e.target.value))
    }

    const handleDeleteForm = (e) => {
        dispatch(deleteRequestForm(_id))
    }

    return (
        <div
            className={`${isPhone ? 'flex items-center justify-between' : 'grid grid-cols-[1fr,1fr,120px]'} px-5 lg:px-[30px] border border-black border-opacity-30 rounded-[5px]`}>
            <div className={`flex gap-5 items-center ${!isPhone && 'line-clamp-1'} py-1 lg:py-1.5`}>
                {type === 'request'
                    ?
                    <img src={externalForm} className={isPhone && 'w-[17px] h-[17px] object-contain'}
                         alt={'external_form'}/>
                    :
                    <img src={innerForm} className={isPhone && 'w-[17px] h-[17px] object-contain'} alt={'inner_form'}/>
                }
                <Link to={`/forms/view/${_id}`}
                      className={`${isPhone && 'max-w-[205px] text-[14px]'} lg:truncate hover:text-[#FCC10F] transition`}>{name}</Link>
            </div>
            {!isPhone &&
                <div>
                    <FormStatusDropdown value={displayNames[status]} selectedKey={status} data={statusOptions}
                                        onChange={handleSelectStatus}/>
                </div>
            }
            <div className={'flex items-center gap-[30px] justify-end py-1.5'}>
                {(status === 'closed' || status === 'public')
                    ?
                    <>
                        {!isPhone &&
                            <ButtonHoverText text={'Բացել լրացված հայտերը'} icon={<ListTaskIcon/>}
                                             onClick={redirectToRequests}/>
                        }
                        {status !== 'closed' &&
                            <ButtonHoverText text={copied ? 'Պատճենված է' : 'Պատճենել հղումը'} icon={<CopyIcon width={isPhone && 17} height={isPhone && 17}/>}
                                             onClick={handleCopyLink}/>}
                    </>
                    :
                    !isPhone &&
                    <ButtonHoverText text={'Խմբագրել'} icon={<EditIcon/>} onClick={() => navigate(`/forms/${_id}`)}/>
                }

                {status === 'draft' && !isPhone && <ButtonHoverText text={'Ջնջել'} icon={<TrashIcon/>} onClick={handleDeleteForm}/>}
            </div>
        </div>
    )
}

export default FormItem;