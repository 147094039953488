import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {convertToQueryParams, parseQueryParams} from "../utils/convertQueryFilters";
import {useLocation, useNavigate} from "react-router-dom"
import {
    ageData,
    ageGroupData,
    genderOptions,
    generateScheduleDropdownData, stopsCount,
    studentStatusData
} from "../common/dropdownOptions";

const scheduleData = generateScheduleDropdownData()

export const useFilters = (initialFilters, applyFilters, mainPath, hasArchiveMode = false) => {
    const {communities, programs, regions, stopNames, routeFilters} = useSelector(state => state.common)
    const {formNames} = useSelector(state => state.forms)

    const [filters, setFilters] = useState(null)
    const [firstRequestGone, setFirstRequestGone] = useState(false)
    const [prevPageNumber, setPrevPageNumber] = useState(filters?.page)

    const allFetched = communities && formNames && programs

    const [customPage, setCustomPage] = useState('')

    const navigate = useNavigate()
    const location = useLocation()

    const dispatch = useDispatch()

    const setParsedFilters = (parsed) => {
        setFilters(parsed)
    }

    const handleChange = (e) => {
        setFilters({...filters, [e.target.name]: e.target.value})
    }

    const changePage = (_, page) => {
        setFilters({...filters, page})
    }

    const handleChangeSize = (e) => {
        setFilters({...filters, page: 1, size: e.target.value})
    }

    const changeCustomPage = (e) => {
        if (e.key === 'Enter') {
            setFilters({...filters, page: parseInt(customPage) > 0 ? parseInt(customPage) : 1})
        }
    }

    const changeSelectedFilters = (name, selectedItem) => {
        let f = filters

        const notMultipleSelectNames = ['fromDate', 'toDate', 'sortOption']

        if (!f[name]) f[name] = []

        let checked = false

        if(!notMultipleSelectNames.includes(name)) {
            checked = f[name]?.find(item => item?._id === selectedItem?._id)
        }

        if (name === 'gender' || name === 'requestStatus' || name === 'sortOption' || name === 'finishedProgramsCount') {
            f = {
                ...f,
                [name]: checked
                    ? f[name].filter(item => item._id !== selectedItem._id)
                    : [selectedItem]
            }
        } else if(name === 'fromDate' || name === 'toDate') {
            f = {
                ...f,
                [name]: null
            }
        } else if (name === 'audience') {
            f = {
                ...f,
                [selectedItem.type]: selectedItem._id,
            }
            f.audience.push(selectedItem)
        } else {
            f = {
                ...f,
                [name]: checked
                    ? f[name].filter(item => item._id !== selectedItem._id)
                    : [...f[name], selectedItem]
            }
        }

        f.page = 1

        setFilters(f)
    }

    const removeSelectedFilters = (name) => {
        let f = filters

        const notMultipleSelectNames = ['fromDate', 'toDate', 'sortOption']

        if (name === 'audience') {
            f = {
                ...f,
                [name]: [],
            }
        } else if (notMultipleSelectNames.includes(name)) {
            f = {
                ...f,
                [name]: null,
            }
        } else {
            f = {
                ...f,
                [name]: [],
            }
        }

        f.page = 1

        setFilters(f)
    }

    const clearAllFilters = () => {
        let f = { ...filters }

        const notMultipleSelectNames = ['fromDate', 'toDate', 'sortOption']

        for (const key in f) {
            if (f.hasOwnProperty(key)) {
                if (key === 'audience') {
                    f[key] = []
                } else if (notMultipleSelectNames.includes(key)) {
                    f[key] = null
                } else if (typeof f[key] === 'string') {
                    f[key] = ''
                } else {
                    f[key] = []
                }
            }
        }

        f.page = 1

        setFilters(f)
    }

    const toggleArchiveMode = () => {
        setFilters({...initialFilters, archive: !filters.archive})
    }

    const discardFilters = () => {
        const resetFilters = {}
        for (const key in initialFilters) {
            if (Array.isArray(initialFilters[key])) {
                resetFilters[key] = []
            } else if (typeof initialFilters[key] === 'object') {
                resetFilters[key] = null
            } else {
                resetFilters[key] = 0
            }
        }
        setFilters(resetFilters)
    }

    const submitFilters = () => {
        const f = filters

        if (f && f?.page === prevPageNumber) {
            f.page = 1
            setPrevPageNumber(1)
        }

        const queryParams = convertToQueryParams(f)

        queryParams && allFetched && navigate(`/${mainPath}?${queryParams}`)
    }

    useEffect(() => {
        const parsedParams = parseQueryParams(window.location)
        setParsedFilters(Object.keys(parsedParams).length === 0 ? initialFilters : parsedParams)

        dispatch(applyFilters(location.search))
        setFirstRequestGone(true)
    }, [])

    useEffect(() => {
        if (filters?.page === prevPageNumber) {
            setFilters({...filters, page: 1})
        } else {
            setPrevPageNumber(filters?.page)
        }

        submitFilters()

        window.scrollTo({top: 0})
    }, [filters?.page, filters?.size]);

    useEffect(() => {
        if (firstRequestGone) {
            submitFilters()
        }
    }, [filters?.archive])

    useEffect(() => {
        if (communities && formNames && programs) {
            const finishedProgramsCountData = programs?.map((item, i) => ({_id: i + 1, name: `${i + 1} ծրագիր`}))

            const parsedParams = parseQueryParams(window.location)

            let parsedFilters = {}

            for (const key in parsedParams) {
                if (typeof parsedParams[key] === 'object') {
                    if (key === 'community') {
                        const selectedFilters = parsedParams[key].map(id => communities?.find(item => item._id === id)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'formN') {
                        const selectedFilters = parsedParams[key].map(id => formNames?.find(item => item._id === id)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'status' || key === 'requestStatus') {
                        const selectedFilters = parsedParams[key].map(id => studentStatusData.find(item => item._id === id)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'age') {
                        const selectedFilters = parsedParams[key].map(age => ageData.find(item => item.name === age)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'schedule') {
                        const selectedFilters = parsedParams[key].map(interval => scheduleData.find(item => item._id === interval)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'program') {
                        const selectedFilters = parsedParams[key].map(selected => programs?.find(item => item._id === selected)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'ageGroup') {
                        const selectedFilters = parsedParams[key].map(selectedItem => ageGroupData.find(item => item._id === selectedItem)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'finishedPrograms') {
                        const selectedFilters = parsedParams[key].map(selectedItem => programs?.find(item => item._id === selectedItem)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'finishedProgramsCount') {
                        const selectedFilters = parsedParams[key].map(selectedItem => finishedProgramsCountData?.find(item => item._id === parseInt(selectedItem))).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'gender') {
                        const selectedFilters = parsedParams[key].map(selectedItem => genderOptions?.find(item => item._id === selectedItem)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'region') {
                        const selectedFilters = parsedParams[key].map(selectedItem => regions?.find(item => item._id === selectedItem)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'routeStart') {
                        const selectedFilters = parsedParams[key].map(selectedItem => stopNames?.find(item => item._id === selectedItem)).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'routeDuration') {
                        const selectedFilters = parsedParams[key].map(selectedItem => routeFilters?.routeDurations?.find(item => item._id === parseInt(selectedItem))).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'distance') {
                        const selectedFilters = parsedParams[key].map(selectedItem => routeFilters?.routeDistances?.find(item => item._id === parseInt(selectedItem))).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    } else if (key === 'stopsCount') {
                        const selectedFilters = parsedParams[key].map(selectedItem => stopsCount?.find(item => item._id === parseInt(selectedItem))).filter(item => item)

                        parsedFilters = {...parsedFilters, [key]: selectedFilters}
                    }
                } else {
                    parsedFilters[key] = parsedParams[key]
                }
            }

            setFilters(parsedFilters)
        }
    }, [communities, formNames, programs])

    useEffect(() => {
        if (firstRequestGone && hasArchiveMode) {
            const status = window.location.href.split('archive=')[1]?.split('&')[0] === 'true'
            setFilters({...filters, archive: status || false})
        }
    }, [window.location.href])

    useEffect(() => {
        if (firstRequestGone && location.search) {
            dispatch(applyFilters(location.search))
        }
    }, [location.search])

    return {
        filters,
        setFilters,
        changePage,
        changeCustomPage,
        customPage,
        setCustomPage,
        handleChangeSize,
        handleChange,
        changeSelectedFilters,
        removeSelectedFilters,
        clearAllFilters,
        submitFilters,
        discardFilters,
        toggleArchiveMode,
    }
}