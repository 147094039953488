import CryptoJS from "crypto-js";

const MESSAGE_ENCRYPTION_SECRET = 'c68da3bda02afe0479565c8dc68da3bda02afe0479565c8dc68da3bda02afe'

export const encryptMessage = async (obj) => {
    // Helper function to encrypt a file
    const encryptFile = async (file, secret) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const byteArray = new Uint8Array(e.target.result);
                const wordArray = CryptoJS.lib.WordArray.create(byteArray);
                const encrypted = CryptoJS.AES.encrypt(wordArray, secret).toString();
                resolve(encrypted);
            };
            reader.onerror = function (e) {
                reject(e);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const processObject = async (obj) => {
        if (Array.isArray(obj)) {
            return await Promise.all(obj.map(processObject));
        } else if (obj && typeof obj === 'object') {
            const processedObj = { ...obj };
            for (const key in processedObj) {
                if (processedObj[key] instanceof File) {
                    processedObj[key] = await encryptFile(processedObj[key], MESSAGE_ENCRYPTION_SECRET);
                } else {
                    processedObj[key] = await processObject(processedObj[key]);
                }
            }
            return processedObj;
        }
        return obj;
    };

    const processedObj = await processObject(obj);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(processedObj), MESSAGE_ENCRYPTION_SECRET).toString();
    return encrypted;
};

export const decryptMessage = async (encryptedData) => {
    // Helper function to decrypt a file
    const decryptFile = (encryptedFile, secret) => {
        const decrypted = CryptoJS.AES.decrypt(encryptedFile, secret);
        const byteArray = decrypted.toString(CryptoJS.enc.Latin1)
            .split('')
            .map(char => char.charCodeAt(0));

        // Create a Blob from the decrypted binary data
        return new Blob([new Uint8Array(byteArray)]);
    };

    const processObject = async (obj) => {
        if (Array.isArray(obj)) {
            return await Promise.all(obj.map(processObject));
        } else if (obj && typeof obj === 'object') {
            const processedObj = { ...obj };
            for (const key in processedObj) {
                if (typeof processedObj[key] === 'string' && processedObj[key].startsWith('U2FsdGVkX1')) { // Check if it is an encrypted string
                    processedObj[key] = decryptFile(processedObj[key], MESSAGE_ENCRYPTION_SECRET);
                } else {
                    processedObj[key] = await processObject(processedObj[key]);
                }
            }
            return processedObj;
        }
        return obj;
    };

    const decrypted = CryptoJS.AES.decrypt(encryptedData, MESSAGE_ENCRYPTION_SECRET);
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8)
    const decryptedObj = JSON.parse(decryptedString);

    if (typeof decryptedObj === 'object' && decryptedObj !== null) {
        const processedObj = await processObject(decryptedObj);
        return processedObj
    } else {
        return decryptedObj;
    }
}