import React, { useEffect, useState } from 'react';
import StudentItem from "./StudentItem";
import EditStudentPlusNote from "../../modals/EditStudentPlusNote";

const StudentsList = ({ studentsList, lineHovered, hoverLine, activeStudents, showDropped, handleRemoveStudent }) => {
    const [editStudentModalData, setEditStudentModalData] = useState(null)
    const [tempStatus, setTempStatus] = useState('')
    const [currentStudentId, setCurrentStudentId] = useState(null)

    const openEditStudentModal = (student) => {
        setEditStudentModalData(student)
        setCurrentStudentId(student?.student?._id)
    }

    useEffect(() => {
        if (editStudentModalData) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [editStudentModalData])

    return (
        <>
            {editStudentModalData &&
                <EditStudentPlusNote
                    student={editStudentModalData?.student}
                    type={editStudentModalData?.type}
                    setTempStatus={setTempStatus}
                    close={() => {
                        openEditStudentModal(null)
                        setCurrentStudentId(null)
                    }}
                />}

            <div className={'sticky w-full max-w-[362px] bg-white left-0 z-[999]'}>
                <div className={'border border-l-0'}>
                    <div className={'w-full flex items-center pl-5 pr-[80px] h-20'}>
                        <p className={'truncate bold'}>Անուն Ազգանուն Հայրանուն</p>
                    </div>

                    <div className={'relative w-full'}>
                        {studentsList?.map((studentData, index) => {
                            const show = showDropped ? true : activeStudents.includes(studentData.student?._id);

                            if (show) {
                                return (
                                    <StudentItem
                                        key={studentData.student._id}
                                        openEditStudentModal={openEditStudentModal}
                                        {...studentData}
                                        index={index}
                                        totalStudents={studentsList.length}
                                        lineHovered={lineHovered}
                                        hoverLine={hoverLine}
                                        tempStatus={studentData.student._id === currentStudentId ? tempStatus : ''}
                                        handleRemoveStudent={handleRemoveStudent}
                                    />
                                );
                            }
                            return null
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudentsList