import React, {useEffect, useRef, useState} from 'react';
import {
    ActiveStudentsIcon,
    DriverYellowIcon,
    MenuIcon,
    StatusIcon,
    StopIcon,
    TransportIcon
} from "../../../../../assets/icons";
import {useDispatch} from "react-redux";
import {changeTripStatus} from "../../../../../store/actions/transport";
import {ConfirmationModal} from "../../../../shared";
import ButtonHoverText from "../../../../shared/buttons/ButtonHoverText";
import {Link} from "react-router-dom";
import EditPassengersModal from "../modals/EditPassengersModal";
import {useOutsideClick} from "../../../../../hooks/useOutsideClick";

const TripRouteAndVehicleItem = ({
                                     _id,
                                     route,
                                     passengersSum,
                                     vehicle,
                                     active: initialActive,
                                     refreshData,
                                     routeToRemove,
                                     setRouteToRemove,
                                     handleRemoveRoute,
                                     handleRemoveVehicle,
                                     vehicleToRemove,
                                     setVehicleToRemove,
                                     passengers,
                                 }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [editPassengersModal, setEditPassengersModal] = useState(null);
    const [statusToChange, setStatusToChange] = useState(false);
    const [active, setActive] = useState(initialActive);
    const [showPassengerList, setShowPassengerList] = useState(false);
    const [listPosition, setListPosition] = useState('5px');
    const passengerListRef = useRef(null);

    const ref = useRef();
    const dispatch = useDispatch();

    useOutsideClick(ref, () => setShowMenu(null));

    const removeRoute = () => {
        setRouteToRemove(_id);
        setShowMenu(null);
    };

    const removeVehicle = () => {
        setVehicleToRemove(_id);
        setShowMenu(null);
    };

    const changeStatus = () => {
        setStatusToChange(true);
        setShowMenu(false);
    };

    const handleChangeStatus = () => {
        dispatch(changeTripStatus(_id, !active));
        setActive(!active);
        setShowMenu(false);
        setStatusToChange(false);
    };


    const handleMouseEnter = () => {
        setListPosition('5px');
        setShowPassengerList(true);
    };

    const handleMouseLeave = () => {
        setShowPassengerList(false);
    };

    useEffect(() => {
        editPassengersModal ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
    }, [editPassengersModal]);

    useEffect(() => {
        if (showPassengerList && passengerListRef.current) {
            const rect = passengerListRef.current.getBoundingClientRect();
            if (rect.bottom > window.innerHeight) {
                setListPosition(`-${rect.height}px`);
            } else {
                setListPosition('20px');
            }
        }
    }, [showPassengerList]);

    return (
        <>
            {editPassengersModal &&
                <EditPassengersModal
                    close={() => setEditPassengersModal(null)}
                    id={_id}
                    refreshData={refreshData}
                />
            }

            {vehicleToRemove &&
                <ConfirmationModal
                    close={() => setVehicleToRemove(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք հեռացնել այս մեքենան։`}
                    confirm={handleRemoveVehicle}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {routeToRemove &&
                <ConfirmationModal
                    close={() => setRouteToRemove(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք հեռացնել այս երթուղին։`}
                    confirm={handleRemoveRoute}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(false)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ${active ? 'ապաակտիվացնել' : 'ակտիվացնել'} այս երթուղին։`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'flex items-center gap-5'}>
                <div className={'relative w-full h-[140px] custom-shadow rounded-[5px] pt-5 pb-[25px] px-[30px]'}>
                    <div
                        className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px] cursor-pointer`}></div>

                    <div className={'flex justify-between'}>
                        <div></div>
                        <div className={'flex gap-5'}>
                            {active === true
                                ?
                                <ButtonHoverText icon={<StatusIcon color={'#20BA07'}/>} text={'Ակտիվ'}/>
                                :
                                <ButtonHoverText icon={<StatusIcon color={'#00000099'}/>} text={'Ապաակտիվ'}/>
                            }
                            <span className={'max-w-[567px] text-center'}>{route?.name}</span>
                        </div>
                        <div onClick={() => setShowMenu(!showMenu)} className={'cursor-pointer'}>
                            <MenuIcon/>
                        </div>
                    </div>
                    {showMenu && (
                        <div ref={ref}
                             className={`absolute z-[999] w-[203px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                            <p
                                onClick={() => setEditPassengersModal(true)}
                                className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Խմբագրել
                            </p>
                            <p
                                onClick={changeStatus}
                                className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                {active ? 'Ապաակտիվացնել' : 'Ակտիվացնել'}
                            </p>
                            <p
                                onClick={removeRoute}
                                className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Հեռացնել երթուղին
                            </p>
                            <p
                                onClick={removeVehicle}
                                className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Հեռացնել մեքենան
                            </p>
                        </div>
                    )}

                    <div className={'w-full grid grid-cols-3 gap-[280px] mt-[30px] px-10'}>
                        <div className={'flex'}>
                            <div
                                className={'relative group flex items-center gap-2.5'}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <ActiveStudentsIcon color={'#FCC10F'}/>
                                <span className={'select-none'}>{passengersSum}</span>
                                <div
                                    ref={passengerListRef}
                                    className={'w-[350px] absolute z-[9999] top-5 left-[70%] max-h-[400px] overflow-y-auto hidden group-hover:flex flex-col gap-5 p-2.5 transition bg-white custom-shadow rounded-[3px]'}
                                    style={{ left: '70%', top: listPosition }}
                                >
                                    {passengers?.map(passenger =>
                                        passenger.type === 'student'
                                            ?
                                            <Link
                                                to={`/students/${passenger._id}?mode=view&subpage=profile`}
                                                key={passenger._id}
                                                className={'w-fit hover:text-[#FCC10F] transition'}
                                            >
                                                {passenger?.fullName}
                                            </Link>
                                            :
                                            <p
                                                key={passenger._id}
                                                className={'w-fit cursor-default'}
                                            >
                                                {passenger?.fullName}
                                            </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={'flex items-center gap-5'}>
                            <DriverYellowIcon/>
                            <span>Վարորդ</span>
                        </div>
                        <div className={'flex items-center gap-5'}>
                            <TransportIcon color={'#FCC10F'}/>
                            <span>{vehicle.mark}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TripRouteAndVehicleItem