import React, {useEffect} from 'react';
import {Container} from "../../../components/shared";
import {Route, Routes} from "react-router-dom";
import Members from "./subpages/Members";
import Divisions from "./subpages/Divisions";
import {useDispatch, useSelector} from "react-redux";
import {getDivisions} from "../../../store/actions/profile";
import {getMemberRoles} from "../../../store/actions/common";

const Settings = () => {
    const {authData} = useSelector(state => state.auth)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getDivisions())
        dispatch(getMemberRoles())
    }, [])

    return (
        <div>
            <Container>
                <Routes>
                    <Route path='members' element={<Members />}/>
                    <Route path='notifications' element={<p></p>}/>
                    {authData.role === 'manager' && <Route path='divisions' element={<Divisions />}/>}
                </Routes>
            </Container>
        </div>
    );
};

export default Settings;