import React, {useEffect, useState} from 'react';
import {CloseIcon} from "../../../assets/icons";
import {useDispatch} from "react-redux";
import {createStudentNote, editStudentNote} from "../../../store/actions/students";

const CreateNoteModal = ({open, close, studentId, groupId, editNote, page}) => {
    const [value, setValue] = useState(editNote?.note || '')
    const dispatch = useDispatch()

    let type = ''
    if (groupId) type = 'group'

    const handleCreateNote = (isPublic) => {
        const noteData = {
            group: groupId,
            note: value,
            isPublic,
        }

        if (editNote) {
            dispatch(editStudentNote(editNote?._id, { note: value, isPublic }, groupId, type, page))
            close()
        } else {
            dispatch(createStudentNote(studentId, noteData, groupId, type, page))
            close()
        }
        setValue('')
    }

    const handleChange = (e) => {
        setValue(e.target.value)
    }

    useEffect(() => {
        if (editNote?.note) {
            setValue(editNote?.note)
        } else {
            setValue('')
        }
    }, [editNote]);

    return (
        <>
            <div
                className={`${open ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} fixed z-[9999] w-screen h-screen top-0 left-0 flex items-center justify-center`}>
                <div className={`${open ? 'backdrop-blur pointer-events-auto' : 'backdrop-blur-none pointer-events-none'} transition fixed bg-black bg-opacity-5 w-full h-full top-0 left-0 z-[9990]`}></div>
                <div
                    className={`${open ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}  transition grid grid-rows-[64px,1fr] absolute z-[9999] w-full h-[calc(100vh-200px)] max-h-[830px] max-w-[842px] pb-[50px] overflow-hidden border border-black rounded-[10px] bg-white`}>
                    <div className={'flex items-center justify-end h-16 px-[30px]'}>
                        <button className={'bg-black bg-opacity-5 rounded-full p-1.5'}
                                onClick={close}>
                            <CloseIcon width={20} height={20}/>
                        </button>
                    </div>

                    <div className={'w-full h-full grid grid-rows-[1fr,56px] px-4'}>
                        <textarea
                            placeholder={'Գրել նոթագրություն'}
                            className={'w-full h-full max-w-[642px] mx-auto border outline-none rounded-[5px] resize-none p-[30px] shadow-md'}
                            value={value}
                            onChange={handleChange}
                        />

                        <div className={'flex justify-center gap-[30px] mt-4'}>
                            <button
                                onClick={() => handleCreateNote(false)}
                                className={'w-[176px] h-10 flex items-center justify-center border border-[#FCC10F] text-[#FCC10F] rounded-[5px]'}>
                                <span>Սևագիր</span>
                            </button>
                            <button
                                onClick={() => handleCreateNote(true)}
                                className={'w-[176px] h-10 flex items-center justify-center bg-[#FCC10F] rounded-[5px] text-white'}>
                                <span>Հրապարակել</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default CreateNoteModal;
