import React from 'react';
import {NavLink} from "react-router-dom";

const ProgramNavbar = ({_id}) => {
    return (
        <div className={'flex flex-col gap-[30px] mt-[60px]'}>
            <div className={'flex justify-between'}>
                <NavLink to={`groups`}
                         className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>
                    Խմբեր
                </NavLink>
                <NavLink to={`content`}
                         className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>Ծրագրի
                    բովանդակություն</NavLink>
                <NavLink to={`plan`}
                         className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>Դասապլաններ</NavLink>
                <NavLink to={`exercises`}
                         className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>Առաջադրանքներ</NavLink>
                <NavLink to={`projects`}
                         className={({isActive}) => `${isActive ? 'text-[#FCC10F]' : 'text-black'}`}>ՍՄԱՐԹ
                    նախագծեր</NavLink>
            </div>
        </div>
    );
};

export default ProgramNavbar;