import React from 'react';
import {CloseIcon} from "../../../assets/icons";
import moment from "moment"
import 'moment/locale/hy-am';
import {useMediaQuery} from "react-responsive"

const formatDate = (date) => {
    const now = moment();
    const inputDate = moment(date);
    const isToday = now.isSame(inputDate, 'day');
    const isPastWeek = now.clone().subtract(1, 'week').isBefore(inputDate, 'day')

    if (isToday) {
        return inputDate.format('HH:mm');
    } else if (isPastWeek) {
        return inputDate.locale('hy').format('dddd, HH:mm')
    } else {
        return inputDate.format('DD.MM.YYYY, HH:mm');
    }
}

const ShowSeenUsersModal = ({data, close}) => {
    const isPhone = useMediaQuery({maxWidth: 640})

    return (
        <div className={'fixed z-[999999] w-screen h-screen top-0 left-0 flex justify-center mt-[100px]'}>
            <div className="fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]"></div>
            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[335px] lg:max-w-[790px] max-h-[445px] lg:max-h-[605px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-5 lg:p-[28px]'}>
                    <h2 className={'text-[14px] lg:text-[16px] text-center'}>Նամակը տեսել են</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-[2px] lg:p-1.5'} onClick={close}>
                        <CloseIcon width={isPhone ? 13 : 20} height={isPhone ? 13 : 20} />
                    </button>
                </div>

                <div className={'max-h-[400px] overflow-y-auto flex flex-col gap-5 mb-5 lg:mb-[50px]'}>
                    {data.map(user => {
                        const formattedDate = formatDate(user.date);
                        return (
                            <div key={user._id} className={'flex items-center gap-2.5 lg:gap-[30px] px-5 lg:px-[50px]'}>
                                <img
                                    src={user.user?.profileImage}
                                    alt={'userImage'}
                                    className={'w-[30px] h-[30px] lg:w-[50px] lg:h-[50px] object-cover rounded-full'}
                                />
                                <div className={'flex flex-col gap-1'}>
                                    <h2 className={'text-[12px] lg:text-[16px] bold'}>{user.user?.fullName}</h2>
                                    <p className={'text-[12px]'}>{formattedDate}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ShowSeenUsersModal;