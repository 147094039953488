import React, {useEffect} from 'react';
import {ButtonFilled, ButtonOutlined} from "../../../../shared/buttons";
import SelectedFilters from "../../../../shared/SelectedFilters";
import Calendar from "../../../../shared/calendar/Calendar";
import {DropdownInputCheckbox} from "../../../../shared/dropdowns";
import {Container} from "../../../../shared";
import {useDispatch, useSelector} from "react-redux";
import {getRouteNames} from "../../../../../store/actions/common";

const TripAnalyticsFilter = ({
                                 filters,
                                 setFilters,
                                 changeSelectedFilters,
                                 discardFilters,
                                 submitFilters,
                                 handleChange,
                                 placeholder,
                                 type
                             }) => {
    const {communities, routeNames} = useSelector(state => state.common)

    const dispatch = useDispatch()

    const handleChangeFromDate = (date) => {
        setFilters({
            ...filters,
            fromDate: date
        })
    }

    const handleChangeToDate = (date) => {
        setFilters({
            ...filters,
            toDate: date
        })
    }

    useEffect(() => {
        dispatch(getRouteNames())
    }, [])

    return (
        <div>
            <div className={'custom-shadow'}>
                <Container>
                    <div className={'relative flex flex-wrap gap-2.5 mt-[17px]'}>
                        <div className={'relative w-[205px]'}>
                            <DropdownInputCheckbox data={communities} name={'community'} placeholder={'Համայնք'}
                                                   onChange={changeSelectedFilters}
                                                   previewClassName={'rounded-[5px] py-[7px]'}
                                                   selected={filters?.community}/>
                        </div>

                        <div className={'relative w-[205px]'}>
                            <DropdownInputCheckbox data={routeNames} name={'route'} showCheckbox={true}
                                                   previewClassName={'rounded-[5px] py-[7px]'}
                                                   placeholder={'Երթուղի'}
                                                   onChange={changeSelectedFilters}
                                                   selected={filters?.route}
                            />
                        </div>

                        <div className={'relative w-[205px]'}>
                            <DropdownInputCheckbox data={''} name={'weekday-time'} showCheckbox={true}
                                                   previewClassName={'rounded-[5px] py-[7px]'}
                                                   placeholder={'Շաբաթվա օր/ժամ'}
                                                   onChange={changeSelectedFilters}
                                                   selected={''}
                            />
                        </div>

                        <div className={'relative w-[205px]'}>
                            <DropdownInputCheckbox data={''} name={'driver'} showCheckbox={true}
                                                   placeholder={'Վարորդ'}
                                                   previewClassName={'rounded-[5px] py-[7px]'}
                                                   onChange={changeSelectedFilters}
                                                   selected={filters?.driver}
                            />
                        </div>

                        <div className={'relative z-[9990] w-[205px] h-10'}>
                            <div
                                className={'absolute w-full border border-black border-opacity-70 py-[6px] rounded-[5px] bg-white'}>
                                <Calendar displayFormat={'DD.MM.YYYY'}
                                          returnFormat={'YYYY-MM-DD'}
                                          placeholder={'Սկիզբ'}
                                          className={'py-[1px]'}
                                          previewClassName={'px-2.5'}
                                          gapClassName={'gap-0'}
                                          paddingClassName={'px-2.5'}
                                          daySizeClassName={'w-5 h-5'}
                                          textSize={'text-[12px]'}
                                          showShortMonths={true}
                                          shortMonthsWidth={'w-[205px]'}
                                          arrowSize={10}
                                          setDate={handleChangeFromDate}
                                          value={filters?.fromDate}
                                />
                            </div>
                        </div>

                        <div className={'relative z-[9990] w-[205px] h-10'}>
                            <div
                                className={'absolute w-full border border-black border-opacity-70 py-[6px] rounded-[5px] bg-white'}>
                                <Calendar displayFormat={'DD.MM.YYYY'}
                                          returnFormat={'YYYY-MM-DD'}
                                          placeholder={'Ավարտ'}
                                          className={'py-[1px]'}
                                          previewClassName={'px-2.5'}
                                          gapClassName={'gap-0'}
                                          paddingClassName={'px-2.5'}
                                          daySizeClassName={'w-5 h-5'}
                                          textSize={'text-[12px]'}
                                          showShortMonths={true}
                                          shortMonthsWidth={'w-[205px]'}
                                          arrowSize={10}
                                          setDate={handleChangeToDate}
                                          value={filters?.toDate}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={'flex justify-between gap-3 pt-[30px] pb-[17px] mr-1'}>
                        <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters}/>

                        <div className={'flex gap-3 items-center'}>
                            <ButtonOutlined
                                onClick={discardFilters}
                                text={'Չեղարկել'}
                            />
                            <ButtonFilled
                                onClick={() => submitFilters()}
                                text={'Կիրառել'}
                            />
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default TripAnalyticsFilter;