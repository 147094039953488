import {useEffect} from "react";

export const useOutsideClick = (refElement, cb) => {
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (!refElement?.current?.contains(e.target)) {
                cb()
            }
        }

        document.addEventListener('mousedown', handleOutsideClick)

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [refElement, cb])

    return null
};
