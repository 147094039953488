import React, {useEffect, useRef, useState} from 'react';
import {gsap} from 'gsap';
import {CheckedIcon, DropdownArrow} from "../../../assets/icons";

const Dropdown = ({data, name, value, onChange, placeholder, onTouch, returnObject, className, previewClassName, optionClassName, icon}) => {
    const [open, setOpen] = useState(false)
    const optionsRef = useRef(null)
    const selectedRef = useRef(null)

    const toggleOpen = () => {
        setOpen(!open);
    };

    if (!data || !onChange) {
        throw new Error('Missing props in DropdownInputCheckbox component (data, value, onChange)');
    }

    const handleSelect = (selected) => {
        returnObject ? onChange({target: {value: selected, name}}) : onChange({target: {value: selected.value, name}})
        toggleOpen()
    }

    useEffect(() => {
        const optionsElement = optionsRef.current;
        const selectedElement = selectedRef.current;

        if (open) {
            gsap.fromTo(
                optionsElement,
                {opacity: 0, height: 0, display: 'block'},
                {opacity: 1, height: 'auto', duration: 0.3}
            )
            gsap.fromTo(
                selectedElement,
                {borderBottomColor: 'none', marginBottom: 0},
                {borderBottomColor: "black", marginBottom: 8, duration: 0.3}
            );
        } else {
            gsap.to(optionsElement, {opacity: 0, height: 0, duration: 0.2, display: 'none'})
            gsap.to(selectedElement, {borderBottomColor: 'none', marginBottom: 0, duration: 0.2})
        }
    }, [open])

    return (
        <div className={`w-full overflow-hidden bg-white rounded-[3px] ${open ? 'pb-2' : className} ${className}`}
             onClick={() => onTouch && !open && onTouch()}>
            <div onClick={toggleOpen} ref={selectedRef}
                 className={`flex items-center justify-between transition cursor-pointer ${previewClassName} px-5 lg:px-7 py-[3px] ${open && 'border-b border-black'}`}>
                {icon}
                <p className={'text-[14px] lg:text-[16px]'}>{value || <span className={'text-black text-opacity-70 lg:text-opacity-100'}>{placeholder}</span>}</p>
                <button><DropdownArrow/></button>
            </div>
            <div ref={optionsRef}
                 className={`${open ? 'h-auto' : 'h-0'} max-h-[171px] overflow-auto transition`}>
                <div>
                    {data.map((option, i) => (
                        <div key={option.id || i} onClick={() => handleSelect(option)}
                             className={`flex justify-between items-center px-7 py-2 line-clamp-1 cursor-pointer transition hover:bg-gray-100`}
                        >
                            <p className={'text-[14px] lg:text-[16px]'}>{option.value}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Dropdown;
