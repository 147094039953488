import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {Container} from "../shared";
import {Link, NavLink, useLocation} from "react-router-dom";
import {
    ChatIcon,
    LogoutIcon,
    MobileMenuIcon,
    NoteIcon,
    OpenedEnvelopeIcon,
    ProfileIcon,
    QRIcon,
    StatementIcon
} from "../../assets/icons";
import {logout} from "../../store/actions/auth";
import logo from '../../assets/images/logo.png'
import {useMediaQuery} from "react-responsive";
import moment from "moment/moment";
import {setNewNotificationsCount} from "../../store/actions/notifications";
import {useNotifications} from "../../hooks/socket/useNotifications"
import QRCode from "../students/modals/QRCode";
import {getNewNotificationsCount} from "../../socket/notifications";

const parseContent = (content) => {
    const regex = /<Link to="(.*?)">(.*?)<\/Link>/g

    const parts = []
    let lastIndex = 0

    content.replace(regex, (match, path, linkText, offset) => {
        if (offset > lastIndex) {
            parts.push(content.slice(lastIndex, offset))
        }

        parts.push(<Link key={offset} to={path} className={'hover:text-[#FCC10F] transition'}>{linkText}</Link>)
        lastIndex = offset + match.length
        return match
    })

    if (lastIndex < content.length) {
        parts.push(content.slice(lastIndex))
    }

    return parts
}

const StudentProfileHeader = () => {
    const {socket} = useSelector((state) => state.socket)
    const {authData} = useSelector(state => state.auth)
    const {newNotificationsCount, notifications} = useSelector((state) => state.notifications)
    const {newMessagesCount} = useSelector((state) => state.chats)

    const [showMenu, setShowMenu] = useState(null)
    const [activeNotificationTab, setActiveNotificationTab] = useState('all')
    const [localNotifications, setLocalNotifications] = useState(notifications || [])
    const [showNotifications, setShowNotifications] = useState(false)
    const [openQRCode, setOpenQRCode] = useState(null)

    const filteredNotifications =
        activeNotificationTab === 'new'
            ? localNotifications?.filter((not) => !not.isRead)
            : localNotifications

    const {
        getNotifications,
        markNotificationAsRead,
        markAllNotificationsAsRead,
    } = useNotifications(socket)

    const isPhone = useMediaQuery({maxWidth: 640})
    const dispatch = useDispatch()
    const ref = useRef(null)
    const notRef = useRef(null)
    const qrRef = useRef(null)

    useOutsideClick(ref, () => setShowMenu(null))
    useOutsideClick(notRef, () => setShowNotifications(null))
    useOutsideClick(qrRef, () => setOpenQRCode(null))

    const location = useLocation()

    const isChatsPageOpen = location.pathname.startsWith('/chats')

    const handleLogout = () => {
        dispatch(logout())
    }

    const handleReadNot = async (notId) => {
        setLocalNotifications(prevState => prevState.map(not =>
            not._id === notId ? {...not, isRead: true} : not
        ))

        try {
            await markNotificationAsRead(notId)
        } catch (error) {
            console.error('Failed to mark notification as read:', error)
        }
    }

    const handleReadAllNots = async () => {
        try {
            await markAllNotificationsAsRead()

            setLocalNotifications(prevState =>
                prevState.map(not => ({...not, isRead: true}))
            )

            setNewNotificationsCount(0)
        } catch (error) {
            console.error('Failed to mark all notifications as read:', error)
        }
    }

    useEffect(() => {
        if (showNotifications) {
            getNewNotificationsCount(socket, dispatch)
            getNotifications('all')
        }
    }, [showNotifications])

    useEffect(() => {
        setLocalNotifications(notifications)
    }, [notifications])

    return (
        <>
            {openQRCode &&
                <QRCode
                    close={() => setOpenQRCode(null)}
                />
            }
            <div
                className={`fixed w-[390px] ${isPhone && 'max-h-[100px]'} lg:w-full top-0 right-0 left-0 z-[999999999999] py-1 bg-white lg:border-b lg:border-b-[#FCC10F]`}>
                <Container className={isPhone && 'py-0 pt-1'}>
                    <div className={'flex justify-between items-center'}>
                        <div>
                            <Link to={'/'}>
                                <img src={logo} className={'w-[30px] lg:w-10'} alt={'logo'}/>
                            </Link>
                        </div>

                        <div className={'flex items-center gap-5'}>
                            <div ref={qrRef} onClick={() => setOpenQRCode(true)}>
                                <QRIcon width={18} height={18} color={'#000'}/>
                            </div>
                            <NavLink to={'/statements?page=1&size=9&'}
                                     className={({isActive}) => `${isActive ? 'text-[#fcc10f]' : ''} fill-icon transition`}
                            >
                                <p className={'relative group flex items-center gap-[10px]'}>
                                    <StatementIcon/>
                                </p>
                            </NavLink>
                            <NavLink to={'/chats'} className={'cursor-pointer'}>
                                <p className={'relative group flex items-center gap-[10px] fill-icon'}>
                                    <ChatIcon color={isChatsPageOpen ? '#FCC10F' : '#000000'}/>
                                    <span
                                        className={'absolute z-[999] w-[93px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition'}>Նամակներ</span>
                                    {newMessagesCount > 0 &&
                                        <span
                                            className={'absolute top-2 -right-2 w-[15px] h-[15px] flex items-center justify-center text-[12px] text-white bg-[#FF0707] rounded-full px-1'}
                                        >
                                        {newMessagesCount}
                                    </span>
                                    }
                                </p>
                            </NavLink>
                            <div className={'relative'}>
                                <div
                                    onClick={() => setShowNotifications(!showNotifications)}
                                    className={`relative cursor-pointer ${showNotifications ? 'pointer-events-none' : 'pointer-events-auto'}`}
                                >
                                    <div className={'fill-icon'}>
                                        <p className={'relative group flex items-center gap-[10px]'}>
                                            <NoteIcon/>
                                        </p>
                                    </div>
                                    {newNotificationsCount > 0 &&
                                        <div
                                            className={'absolute top-2 -right-2 w-[15px] h-[15px] flex items-center justify-center text-[12px] text-white bg-[#FF0707] rounded-full px-1'}
                                        >
                                            {newNotificationsCount}
                                        </div>
                                    }
                                </div>
                                {showNotifications &&
                                    <div
                                        ref={notRef}
                                        className={`absolute z-[9990] w-[345px] lg:w-[359px] max-h-[593px] mt-[15px] bg-white border border-black border-opacity-50 shadow-sm rounded-[5px] ${
                                            isPhone
                                                ? "-left-20 transform -translate-x-1/2 top-full"
                                                : "lg:right-0"
                                        }`}
                                    >
                                        <div className={'w-full flex items-center justify-between py-5 px-2.5'}>
                                            <h2 className={'bold'}>Ծանուցումներ</h2>
                                            <div
                                                className={'relative group flex items-center gap-[10px] fill-icon cursor-pointer'}>
                                                <div onClick={handleReadAllNots}>
                                                    <OpenedEnvelopeIcon/>
                                                </div>
                                                <span
                                                    className={'absolute w-[225px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition'}>
                                                Նշել բոլորը որպես կարդացած
                                            </span>
                                            </div>
                                        </div>
                                        <div className={'flex gap-5 px-2.5 pb-5'}>
                                            <button
                                                onClick={() => setActiveNotificationTab('all')}
                                                className={`w-[100px] h-[30px] border rounded-[5px] cursor-pointer ${
                                                    activeNotificationTab === 'all' ? 'bg-[#FCC10F] text-white border-[#FCC10F]' : 'text-black text-opacity-70 border-black border-opacity-70'
                                                }`}
                                            >
                                                Բոլորը
                                            </button>
                                            <button
                                                onClick={() => setActiveNotificationTab('new')}
                                                className={`w-[156px] h-[30px] border text-[12px] rounded-[5px] cursor-pointer ${
                                                    activeNotificationTab === 'new' ? 'bg-[#FCC10F] text-white border-[#FCC10F]' : 'text-black text-opacity-70 border-black border-opacity-70'
                                                }`}
                                            >
                                                Չկարդացածները
                                            </button>
                                        </div>
                                        <div
                                            className={'w-full max-h-[470px] flex flex-col gap-2.5 overflow-y-auto pb-5'}>
                                            {filteredNotifications?.length > 0
                                                ? filteredNotifications.map(not => {
                                                    const formattedDate = moment(not.createdAt).fromNow();
                                                    return (
                                                        <div
                                                            key={not._id}
                                                            onClick={() => handleReadNot(not._id)}
                                                            className={`flex gap-2.5 px-2.5 ${!not.isRead && 'bg-black bg-opacity-5 cursor-pointer'}`}
                                                        >
                                                            <img src={not.image} alt={'image'}
                                                                 className={`max-w-10 w-full h-10 object-cover ${(not.type !== 'newGroup' && not.type !== 'groupStatus') && 'rounded-full'}`}/>
                                                            <div className={'w-full flex items-center justify-between'}>
                                                                <div
                                                                    className={'max-w-[264px] flex flex-col gap-2 text-[12px]'}>
                                                                    <p>{parseContent(not.content)}</p>
                                                                    <span
                                                                        className={!not.isRead && 'text-[#FCC10F]'}>{formattedDate}</span>
                                                                </div>
                                                                {!not.isRead && <div
                                                                    className={'w-[15px] h-[15px] bg-[#FCC10F] rounded-full'}></div>}
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                : <p className={'text-center text-[14px] lg:text-[16px]'}>Ծանուցումներ
                                                    չկան</p>
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                            <MobileMenuIcon />
                            <div className={'relative'}>
                                <img
                                    src={authData.profileImage}
                                    alt={'testingImage'}
                                    className={`w-[30px] h-[30px] lg:w-10 lg:h-10 rounded-full ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'} select-none object-cover`}
                                    onClick={() => setShowMenu(!showMenu)}
                                />

                                {showMenu &&
                                    <div ref={ref}
                                         className={'absolute z-50 w-[236px] max-h-[100px] top-full flex flex-col gap-2 bg-white right-0 mt-[10px] py-2 border border-black rounded-[5px]'}>
                                        <>
                                            <Link to={'/profile'}>
                                                <p
                                                    className={'flex items-center gap-5 px-5 transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                                    <ProfileIcon width={isPhone && 17} height={isPhone && 17}/>
                                                    <span className={'text-[14px] lg:text-[16px]'}>Օգտաէջ</span>
                                                </p>
                                            </Link>
                                            <Link to={'/'}
                                                  className={'flex items-center gap-5 px-5 transition hover:bg-gray-200 py-1 cursor-pointer'}
                                                  onClick={handleLogout}
                                            >
                                                <LogoutIcon width={isPhone && 17} height={isPhone && 17}/>
                                                <span className={'text-[14px] lg:text-[16px]'}>Ելք</span>
                                            </Link>
                                        </>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default StudentProfileHeader;