import React from 'react';
import {
    CloseIcon,
    GroupInstructorIcon,
    ParticipantsIcon,
    RangeIcon,
    ReturnLeftIcon,
    ScheduleIcon
} from "../../../../assets/icons";
import {daysOfWeek} from "../../../../common/dropdownOptions";
import CircularProgress from "./CircularProgress";

const OpenCurrentGroup = ({close, currentGroup}) => {
    const schedule = currentGroup.scheduleCodes?.map((scheduleCode) => {
        const splitted = scheduleCode.split('_');
        const weekday = daysOfWeek[splitted[0]]
        const timeRange = splitted[1].split('-')
        const startTime = timeRange[0];
        const endTime = timeRange[1];

        return `${weekday}. ${startTime}-${endTime}`
    })

    return (
        <div className={'fixed z-[999999] w-screen h-screen top-0 left-0 flex justify-center mt-12'}>
            <div className="fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]"></div>
            <div
                onClick={close}
                className="absolute z-[99999] flex items-center gap-2.5 lg:gap-[30px] pt-2.5 lg:pt-5 pl-5 cursor-pointer"
            >
                <ReturnLeftIcon color="#4C4C4C" width={17} height={17}/>
                <p className="text text-black text-opacity-70 text-[14px] lg:text-[16px]">Վերադառնալ նախորդ էջ</p>
                <div
                    onClick={close}
                    className={'w-[17px] h-[17px] flex items-center justify-center bg-white border border-black border-opacity-70 rounded-full ml-[100px]'}
                >
                    <CloseIcon width={13} height={13}/>
                </div>
            </div>
            <div
                className={'absolute flex flex-col w-full max-w-[345px] min-h-[44px] mt-[50px] z-[9999] custom-shadow rounded-[10px] bg-white'}>
                <div className={'max-w-[345px] min-h-[44px] custom-shadow rounded-[10px]'}>
                    <h2 className={'text-center text-[#FCC10F] pt-5'}>{currentGroup?.name}</h2>
                    <div>
                        <div className={'pl-5'}>
                            <div className={'flex items-center justify-between pr-5'}>
                                <div className={'max-w-[220px]'}>
                                    <div className={'flex items-center gap-2.5 mt-5'}>
                                        <GroupInstructorIcon/>
                                        <div className={'text-[14px]'}>
                                            {currentGroup.instructors?.map(instructor => (
                                                <div key={instructor._id}>
                                                    {instructor.fullName}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={'flex items-center gap-2.5 mt-5'}>
                                        <ParticipantsIcon/>
                                        <p className={'text-[14px]'}>{currentGroup?.studentsCount}</p>
                                    </div>
                                </div>
                                <div className={''}><CircularProgress percentage={currentGroup?.attendancePercentage}/>
                                </div>
                            </div>
                            <div className={'flex items-center gap-2.5 mt-5 pb-5'}>
                                <ScheduleIcon/>
                                <div className={'text-[14px]'}>
                                    {schedule?.length > 0
                                        ?
                                        schedule.length > 2
                                            ?
                                            <div className={"relative group"}>
                                                <span className={'cursor-pointer'}>Գրաֆիկ</span>
                                                <div
                                                    className={"w-[228px] absolute z-10 hidden group-hover:grid grid-cols-3 gap-y-2.5 bg-white border shadow-md rounded-[3px] p-2 left-0 mt-2"}>
                                                    {schedule.map((item, index) => (
                                                        <p key={index} className={'text-[12px]'}>{item}</p>
                                                    ))}
                                                </div>
                                            </div>
                                            :
                                            <div className={'grid grid-cols-2 gap-2.5'}>
                                                {schedule.map((item, index) =>
                                                    <p className={'w-full'} key={index}>{item}</p>
                                                )}
                                            </div>
                                        :
                                        <p>Առկա չէ</p>
                                    }
                                </div>
                            </div>
                            <div className={'flex items-center gap-2.5 mb-5'}>
                                <RangeIcon/>
                                <p className={'text-[14px]'}>{currentGroup?.studentsCount}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div
                className={'absolute top-[38%] flex flex-col w-full max-w-[345px] min-h-[207px] lg:mt-[50px] z-[9999] custom-shadow rounded-[10px] bg-white'}>
                <div className={'flex items-center justify-center gap-2.5 pt-5'}>
                    <ParticipantsIcon/>
                    <h2 className={'text-[#FCC10F]'}>Մասնակիցներ</h2>
                </div>
                <div
                    className={'w-full flex flex-col gap-2.5 px-[15px] mt-[13px] pb-5 max-h-[300px] overflow-y-auto'}
                >
                    {currentGroup?.students?.studentsList.map((student, i) => {
                        return (
                            <p className={'flex items-center gap-2.5 text-[14px]'} key={i}>
                                <span>{i + 1}</span>
                                <span>{student?.student?.fullName}</span>
                            </p>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default OpenCurrentGroup;