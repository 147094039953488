import React, {useEffect, useRef, useState} from 'react';
import {AsteriskIcon, CheckedIcon, CloseIcon, CopyIcon, InfoIcon, MoveIcon, TrashIcon} from "../../assets/icons";
import ButtonHoverText from "../shared/buttons/ButtonHoverText";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import FormFieldOptions from "../shared/dropdowns/FormFieldOptions";
import AnswerPreview from "./form/widgets/AnswerPreview";
import HoverInfo from "../shared/HoverInfo";
import successMessageImg from "../../assets/images/forms/form-submitted.png";
import {v4 as uuidv4} from "uuid";
import {CSS} from "@dnd-kit/utilities";
import {useSortable} from "@dnd-kit/sortable";

const categoryOptions = [
    {id: 1, name: 'Անուն', value: "firstName"},
    {id: 2, name: 'Ազգանուն', value: "lastName"},
    {id: 3, name: 'Հայրանուն', value: "middleName"},
    {id: 4, name: 'Սեռ', value: "gender"},
    {id: 5, name: 'Ծննդյան տվյալ', value: "birthday"},
    {id: 6, name: 'Համայնք', value: "community"},
    {id: 7, name: 'Անձնագիր', value: "birthCertificate"},
    {id: 8, name: 'Հեռախոս', value: "phoneNumber"},
    {id: 9, name: 'Ծնող', value: "parentFullName"},
    {id: 10, name: 'Հայտը հաստատող նամակ', value: "successMessage"},
]

const addOption = (index) => {
    return {
        id: uuidv4(),
        value: `Տարբերակ ${index}`
    }
}

const FormField = ({
                       id,
                       name,
                       placeholder,
                       category,
                       options,
                       type,
                       mandatory,
                       info,
                       focused,
                       showCategory,
                       fieldTypes,
                       deleteField,
                       changeField,
                       duplicateField,
                       handleFocus,
                       index
                   }) => {
    const [data, setData] = useState({id, name, placeholder, category, options: options || null, type, focused, mandatory, info})
    const [editElement, setEditElement] = useState(null)
    const editElementRef = useRef(null)


    const selectedType = fieldTypes.find(item => item.value === data.type)
    const selectedCategory = categoryOptions.find(item => item.value === data.category)

    const handleEditElement = (name) => setEditElement(name)

    const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({id})

    useOutsideClick(editElementRef, () => setEditElement(null))
    // useOutsideClick(setNodeRef, () => handleFocus(null))

    const style = {
        transform: CSS.Translate.toString(transform),
        transition,
    }

    const handleChange = (e) => {
        setData({...data, [e.target.name]: e.target.value})
    }

    const toggleMandatory = () => {
        setData({...data, mandatory: !data.mandatory})
    }

    const toggleInfo = () => {
        setData({...data, info: data.info === null ? true : null})
    }

    const handleEnter = (e) => {
        e.key === 'Enter' && setEditElement(null)
    }

    const handleAddOption = (index) => {
        setData({...data, options: [...data.options, addOption(index)]})
    }

    const handleChangeOption = (e) => {
        const modifiedOptions = data.options.map(item => {
            if (item.id === e.target.dataset.id) {
                item.value = e.target.value
            }

            return item
        })
        setData({...data, options: modifiedOptions})
    }

    const handleDeleteOption = (id) => {
        if (data.options.length > 1) {
            setData({...data, options: data.options.filter(item => item.id !== id)})
        }
    }

    const handleDuplicate = () => {
        duplicateField(id, index)
    }

    useEffect(() => {
        if (data.type === 'dropdown') {
            const newOptions = data.options ? [...data.options, addOption(data.options.length)] : [addOption(1)]

            setData({...data, options: options || newOptions})
        } else {
            setData({...data, options: null})
        }
    }, [data.type])

    useEffect(() => {
        changeField(data)
    }, [data])

    return (
        <div
            ref={setNodeRef}
            {...attributes}
            {...listeners}
            style={style}
            onClick={() => handleFocus(id)}
            className={`p-[30px] mb-[30px] rounded-[3px] ${focused && 'custom-shadow'} bg-white`}
        >
            {focused && <>
                <div className={'flex justify-between items-start'}>
                    <input ref={editElementRef} type="text"
                           className={'w-full outline-none py-1 px-2 rounded-md'}
                           onFocus={e => e.target.select()}
                           name={'name'} value={data.name} onKeyDown={handleEnter}
                           onChange={handleChange}/>
                    <div className={'flex gap-[30px]'}>
                        <FormFieldOptions data={fieldTypes} value={selectedType?.name} name={'type'}
                                          onChange={handleChange}/>

                        {showCategory && (
                            <FormFieldOptions data={categoryOptions}
                                              value={selectedCategory?.name || 'Կապել'}
                                              name={'category'}
                                              isCategory={true}
                                              onChange={handleChange}/>
                        )}

                        <div className={'flex gap-[30px]'}>
                            <ButtonHoverText text={'Պատճենել տարբերակը'} icon={<CopyIcon/>}
                                             onClick={handleDuplicate}/>
                            <ButtonHoverText text={'Ջնջել'} icon={<TrashIcon/>} onClick={() => deleteField(id)}/>
                        </div>
                    </div>
                </div>
                <div className={'my-[30px]'}>
                    <input ref={editElementRef}
                           type="text"
                           className={'max-w-[500x] outline-none py-1 px-2 rounded-md'}
                           onFocus={e => e.target.select()}
                           name={'placeholder'}
                           value={data.placeholder}
                           onKeyDown={handleEnter}
                           onChange={handleChange}
                    />
                </div>
                {data.type === 'dropdown' && (
                    <div className={'flex flex-col gap-[15px] mb-5'}>
                        {data.options?.map((option, i) => {
                            const isLast = i === data.options?.length - 1

                            return (
                                <div key={option.id} className={'w-[300px]'}>
                                    <div onClick={() => handleEditElement(option.id)}
                                         className={`w-full grid grid-cols-2 gap-5`}
                                    >
                                        <input ref={editElementRef}
                                               type="text"
                                               className={'w-full outline-none py-1 px-2 rounded-md'}
                                               onFocus={e => e.target.select()}
                                               data-id={option.id}
                                               value={option.value}
                                               onKeyDown={handleEnter}
                                               onChange={handleChangeOption}
                                        />
                                        <button onClick={() => handleDeleteOption(option.id)}>
                                            <CloseIcon width={20} height={20}/>
                                        </button>
                                    </div>
                                    {isLast && (
                                        <div onClick={() => handleAddOption(data.options?.length + 1)}
                                             className={`mt-[30px] px-2 opacity-50 cursor-pointer`}>
                                            Տարբերակ {data.options?.length + 1}
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
                <div className={'flex gap-[50px] h-9 w-full'}>
                    <div onClick={toggleMandatory}
                         className={'flex items-center gap-[30px] cursor-pointer select-none'}>
                        <p className={'flex items-center gap-[10px]'}>
                            <AsteriskIcon/>
                            <span className={'text-[16px]'}>Պարտադիր դաշտ</span>
                        </p>
                        <div
                            className={'flex items-center justify-center gap- w-5 h-5 border border-black rounded-[5px]'}>
                            {data.mandatory && <CheckedIcon/>}
                        </div>
                    </div>
                    <div className={'flex items-center gap-[30px] w-[70%]'}>
                        <div className={'flex items-center gap-[30px] cursor-pointer'} onClick={toggleInfo}>
                            <p className={'flex items-center gap-[10px]'}>
                                <InfoIcon/>
                                <span className={'text-[16px] select-none'}>Տեղեկատվություն</span>
                            </p>
                            <div
                                className={'flex items-center justify-center gap- w-5 h-5 border border-black rounded-[5px]'}>
                                {data.info && <CheckedIcon/>}
                            </div>
                        </div>
                        {data.info &&
                            <input type="text"
                                   value={typeof data.info === 'boolean' ? '' : data.info}
                                   autoFocus
                                   name={'info'}
                                   onChange={handleChange}
                                   className={'border border-black opacity-70 bg-black bg-opacity-5 outline-none py-1 px-2.5 w-full rounded-[3px]'}/>}
                    </div>
                </div>
            </>}

            <div className={`${focused && 'border-t border-black border-opacity-20 mt-[30px] pt-[30px]'} `}>
                <h2 className={'flex items-center gap-2 py-1 select-none'}>
                    <span>{data.name}</span>
                    {data.mandatory && <AsteriskIcon/>}
                    {data.info && <HoverInfo info={data.info}/>}
                </h2>

                {data.type !== 'successMessage' ?
                    <AnswerPreview
                        options={data.options}
                        type={data.type}
                        focused={focused}
                        placeholder={data.placeholder}
                    />
                    :
                    <div className={'w-[300px] flex flex-col items-center mt-0 mx-auto border pt-5 px-2'}>
                        <h2 className={'text-[#FCC10F]'}>{data.name}</h2>
                        <p className={'block max-w-full text-sm mt-2.5'}>{data.placeholder}</p>
                        <img src={successMessageImg} className={'w-[130px] mt-2.5'} alt="message"/>
                    </div>
                }
            </div>
        </div>
    );
};

export default FormField;