import React, {useState, useRef, useEffect} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {EffectCoverflow, Navigation, Pagination} from "swiper/modules";
import {
    CloseIcon,
    ReplyIcon,
    TrashIcon,
    UploadIcon,
    ZoomInIcon,
    ZoomOutIcon,
    SliderControlArrow
} from '../../../assets/icons';
import {Container} from '../../shared';
import 'swiper/swiper-bundle.css';
import {formatDate} from "../../../utils/formatSentMessageDate";
import './style/style.css';
import ShareFilesModal from "./ShareFilesModal";
import {downloadFileFromSrc} from "../../../utils/fileDownloads/downloadFile";
import {deleteMessage} from "../../../socket/chats";
import {useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";

const videoExtensions = ['.mp4', '.mkv', '.webm', '.avi', '.mov', '.wmv', '.flv'];

const OpenedMediaModal = ({close, _id, files, message, id, author, createdAt, isSender}) => {
    const [scale, setScale] = useState(1);
    const [openShareFilesModal, setOpenShareFilesModal] = useState(null);
    const [activeFileId, setActiveFileId] = useState(null);
    const [activeFilePath, setActiveFilePath] = useState(null);
    const {socket} = useSelector(state => state.socket);

    const {authData} = useSelector((state) => state.auth)

    const messageDate = formatDate(createdAt) || '';

    const isVideo = (file) => videoExtensions.some(ext => file.src.toLowerCase().endsWith(ext));
    const videoFiles = files.filter(isVideo);
    const imageFiles = files.filter(file => !isVideo(file));

    const swiperRef = useRef(null);

    const isPhone = useMediaQuery({maxWidth: 640});

    const handleZoomIn = () => {
        setScale(prevScale => Math.min(prevScale + 0.1, 1.5));
    };

    const handleZoomOut = () => {
        setScale(prevScale => Math.max(prevScale - 0.1, 0.5));
    };

    const handleDownloadMedia = (mediaPath) => {
        downloadFileFromSrc(mediaPath);
    };

    const handleDeleteMessage = (messageId) => {
        deleteMessage(socket, messageId).then(() => {
            close();
        });
    };

    const imageActions = [
        ...(authData.role !== 'student' ? [
            {
                id: 1,
                icon: <ReplyIcon width={isPhone && 11} height={isPhone && 11}/>,
                onClick: () => setOpenShareFilesModal(true)
            }
        ] : []),
        {
            id: 2,
            icon: <UploadIcon width={isPhone && 11} height={isPhone && 11}/>,
            onClick: () => handleDownloadMedia(activeFilePath)
        },
        ...(!isPhone ? [
            {id: 3, icon: <ZoomOutIcon/>, onClick: handleZoomOut},
            {id: 4, icon: <ZoomInIcon/>, onClick: handleZoomIn},
        ] : []),
        ...(isSender ? [
            {
                id: 5,
                icon: <TrashIcon width={isPhone ? 11 : 16} height={isPhone ? 11 : 16} color={'#000'}/>,
                onClick: () => handleDeleteMessage(id)
            }
        ] : []),
        {id: 6, icon: <CloseIcon width={isPhone ? 11 : 16} height={isPhone ? 11 : 16}/>, onClick: close}
    ];

    useEffect(() => {
        if (files.length === 1) {
            const singleFile = imageFiles[0] || videoFiles[0];
            setActiveFileId(singleFile?._id);
            setActiveFilePath(singleFile?.src);
        } else if (swiperRef.current) {
            const middleIndex = Math.floor(files.length / 2);
            swiperRef.current.slideToLoop(middleIndex, 0);

            const initialFile = imageFiles[middleIndex] || videoFiles[middleIndex];
            setActiveFileId(initialFile?._id);
            setActiveFilePath(initialFile?.src);
        }
    }, [files]);

    const handleSlideChange = (swiper) => {
        const activeIndex = swiper.realIndex;
        const activeFile = imageFiles[activeIndex] || videoFiles[activeIndex];
        setActiveFileId(activeFile?._id);
        setActiveFilePath(activeFile?.src);
    }


    return (
        <>
            {openShareFilesModal &&
                <ShareFilesModal
                    close={() => setOpenShareFilesModal(null)}
                    closeMediaModal={close}
                    messageId={_id}
                    mediaId={activeFileId}
                />
            }
            <div className="fixed z-[99999] w-screen h-screen top-0 left-0 mt-[30px] flex justify-center">
                <div className="fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]"></div>
                <div className="w-full flex flex-col absolute z-[99999999]">
                    <div
                        className={`w-full flex justify-between px-4 md:px-[320px] ${authData.role === 'student' ? 'mt-10' : 'mt-14'} lg:mt-0`}>
                        <div className="flex items-center gap-5">
                            <img src={author?.profileImage} alt="avatar"
                                 className="w-[40px] h-[40px] md:w-[50px] md:h-[50px] object-cover rounded-full"/>
                            <div className="flex flex-col gap-2.5 text-[12px]">
                                <h2 className="bold">{author?.fullName}</h2>
                                <h2 className="text-black text-opacity-70">{messageDate}</h2>
                            </div>
                        </div>
                        <div className="flex items-center gap-5 pb-2.5">
                            {imageActions?.map((item) => (
                                <div
                                    onClick={item.onClick}
                                    key={item.id}
                                    className="w-[17px] h-[17px] lg:w-[30px] lg:h-[30px] flex justify-center items-center border border-black bg-white rounded-full lg:p-[7px] cursor-pointer"
                                >
                                    {item.icon}
                                </div>
                            ))}
                        </div>
                    </div>

                    <Container className={isPhone && 'px-0'}>
                        {imageFiles.length > 3
                            ?
                            <div className={''}>
                                <div
                                    className={`relative flex justify-center md:px-14 ${openShareFilesModal ? 'blur-sm' : ''} ${isPhone && imageFiles.length <= 3 && 'px-[60px] rounded-[5px]'}`}>
                                    <Swiper
                                        grabCursor={true}
                                        loop={true}
                                        centeredSlides={true}
                                        slidesPerView={isPhone && imageFiles.length <= 3 ? 1 : 'auto'}
                                        spaceBetween={0}
                                        {...(isPhone && imageFiles.length <= 3 ? {} : {
                                            effect: 'coverflow',
                                            coverflowEffect: {
                                                rotate: 0,
                                                stretch: 80,
                                                depth: 250,
                                                modifier: 2,
                                                slideShadows: false,
                                            },
                                        })}
                                        onSwiper={(swiper) => {
                                            swiperRef.current = swiper;
                                            const middleIndex = Math.floor(imageFiles.length / 2);
                                            swiper.slideToLoop(middleIndex, 0);
                                            setActiveFileId(imageFiles[middleIndex]?._id)
                                            setActiveFilePath(imageFiles[middleIndex]?.src)
                                        }}
                                        onSlideChange={handleSlideChange}
                                        pagination={{el: '.swiper-pagination-slider', clickable: true}}
                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                            clickable: true,
                                        }}
                                        modules={[EffectCoverflow, Pagination, Navigation]}
                                    >
                                        {imageFiles.map((media, index) => (
                                            <SwiperSlide
                                                key={index}
                                                style={{
                                                    width: isPhone ? 'auto' : '521px',
                                                    height: isPhone ? 'auto' : '619px',
                                                }}
                                            >
                                                <img
                                                    src={media.src}
                                                    alt="image"
                                                    className={`w-full h-full object-cover rounded-[5px] transform transition-transform duration-300 select-none`}
                                                    style={{
                                                        transform: `scale(${scale})`,
                                                        maxWidth: isPhone ? '255px' : '100%',
                                                        maxHeight: isPhone ? '339px' : '100%',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    {!isPhone &&
                                        <div className="slider-controller-images-larger">
                                            <div
                                                className={`swiper-button-prev slider-arrow-larger rotate-180 bg-white bg-opacity-70 border border-black rounded-full absolute left-0 top-1/2 transform -translate-y-1/2`}>
                                                <SliderControlArrow color={'#000'}/>
                                            </div>
                                            <div
                                                className={`swiper-button-next slider-arrow-larger bg-white bg-opacity-70 border border-black rounded-full absolute right-0 top-1/2 transform -translate-y-1/2`}>
                                                <SliderControlArrow color={'#000'}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="swiper-pagination-slider flex justify-center gap-5 mt-5"></div>
                            </div>
                            :
                            (imageFiles.length === 2 || imageFiles.length === 3) && isPhone
                                ?
                                <div className={''}>
                                    <div
                                        className={`relative flex justify-center md:px-14 ${openShareFilesModal ? 'blur-sm' : ''} ${isPhone && imageFiles.length <= 3 && 'px-[60px] rounded-[5px]'}`}>
                                        <Swiper
                                            grabCursor={true}
                                            loop={true}
                                            centeredSlides={true}
                                            slidesPerView={'auto'}
                                            spaceBetween={100}
                                            onSlideChange={handleSlideChange}
                                            pagination={{el: '.swiper-pagination-slider', clickable: true}}
                                            navigation={{
                                                nextEl: '.swiper-button-next',
                                                prevEl: '.swiper-button-prev',
                                                clickable: true,
                                            }}
                                            modules={[Pagination, Navigation]}
                                        >
                                            {imageFiles.map((media, index) => (
                                                <SwiperSlide
                                                    key={index}
                                                    style={{
                                                        width: isPhone ? 'auto' : '521px',
                                                        height: isPhone ? 'auto' : '619px',
                                                    }}
                                                >
                                                    <img
                                                        src={media.src}
                                                        alt="image"
                                                        className={`w-full h-full object-cover rounded-[5px] transform transition-transform duration-300 select-none`}
                                                        style={{
                                                            transform: `scale(${scale})`,
                                                            maxWidth: isPhone ? '255px' : '100%',
                                                            maxHeight: isPhone ? '339px' : '100%',
                                                            objectFit: 'contain',
                                                        }}
                                                    />
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        {!isPhone &&
                                            <div className="slider-controller-images-larger">
                                                <div
                                                    className={`swiper-button-prev slider-arrow-larger rotate-180 bg-white bg-opacity-70 border border-black rounded-full absolute left-0 top-1/2 transform -translate-y-1/2`}>
                                                    <SliderControlArrow color={'#000'}/>
                                                </div>
                                                <div
                                                    className={`swiper-button-next slider-arrow-larger bg-white bg-opacity-70 border border-black rounded-full absolute right-0 top-1/2 transform -translate-y-1/2`}>
                                                    <SliderControlArrow color={'#000'}/>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="swiper-pagination-slider flex justify-center gap-5 mt-5"></div>
                                </div>
                                :
                                <div className={`flex gap-5 ${openShareFilesModal ? 'blur-sm' : ''}`}>
                                    {imageFiles.map((media, index) =>
                                        <div key={index} className={'w-[255px] lg:w-[541px] lg:h-[719px]'}>
                                            <img
                                                src={media.src}
                                                alt="image"
                                                className={`w-full h-full object-cover rounded-[5px] transform transition-transform duration-300 select-none`}
                                                style={{
                                                    transform: `scale(${scale})`,
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                        }

                        {videoFiles.length > 1
                            ?
                            <div>
                                <div
                                    className={`relative flex justify-center px-4 md:px-14 mt-5 ${openShareFilesModal ? 'blur-sm' : ''}`}>
                                    <Swiper
                                        grabCursor={true}
                                        loop={true}
                                        centeredSlides={true}
                                        slidesPerView={'auto'}
                                        spaceBetween={0}
                                        onSlideChange={handleSlideChange}
                                        pagination={{el: '.swiper-pagination-slider', clickable: true}}
                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                            clickable: true,
                                        }}
                                        modules={[Pagination, Navigation]}
                                    >
                                        {videoFiles.map((media, index) => (
                                            <SwiperSlide
                                                key={index}
                                                className="w-full h-auto"
                                            >
                                                <video
                                                    controls
                                                    src={media.src}
                                                    className="w-full h-full rounded-[5px] object-contain"
                                                />
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    {!isPhone &&
                                        <div className="slider-controller-images-larger">
                                            <div
                                                className={`swiper-button-prev slider-arrow-larger rotate-180 bg-white bg-opacity-70 border border-black rounded-full absolute left-0 top-1/2 transform -translate-y-1/2`}>
                                                <SliderControlArrow color={'#000'}/>
                                            </div>
                                            <div
                                                className={`swiper-button-next slider-arrow-larger bg-white bg-opacity-70 border border-black rounded-full absolute right-0 top-1/2 transform -translate-y-1/2`}>
                                                <SliderControlArrow color={'#000'}/>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="swiper-pagination-slider flex justify-center gap-5 mt-5"></div>
                            </div>
                            :
                            videoFiles.length === 1 &&
                            <div className={`flex gap-5 mt-5 ${openShareFilesModal ? 'blur-sm' : ''}`}>
                                {videoFiles.map((media, index) =>
                                    <div key={index}
                                         className={`w-full ${isPhone && 'max-h-[400px] px-[15px]'} lg:w-[541px] lg:h-[719px]`}>
                                        <video
                                            controls
                                            src={media.src}
                                            className="w-full h-full object-contain rounded-[5px]"
                                        />
                                    </div>
                                )}
                            </div>
                        }

                        <p className={'text-[12px] lg:text-[16px] text-center mt-5 select-none'}>{message}</p>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default OpenedMediaModal