import {
    SET_CHAT,
    SET_CHATS,
    SET_CHAT_ROOM,
    CHAT_MESSAGE,
    USER_PINNED_UNPINNED_MESSAGE,
    USER_DELETED_MESSAGE,
    SET_REPLYING_MESSAGE,
    SET_MEDIA_OF_CHAT,
    USER_HAS_SEEN_MESSAGE,
    SET_SEARCHED_CHATS,
    SET_REACTIONS,
    UNPIN_ALL_MESSAGES,
    SET_SELECTED_CHAT_ID,
    SET_PREPARED_CHAT,
    UPDATE_EDITED_MESSAGE,
    LOAD_MORE_MESSAGES, GO_TO_MESSAGE_FETCHED_FROM_SERVER, SET_SEARCH_CHATS_QUERY_ID, GET_NEW_MESSAGES_COUNT
} from "../constants/actionTypes"

const initialState = {
    chatRoom: null,
    chats: null,
    searchedChats: null,
    chat: null,
    messages: null,
    mayFetchEarlierMessages: null,
    mayFetchNextMessages: null,
    media: null,
    chatId: null,
    reactions: null,
    newMessagesCount: 0,
    replyingMessage: null,
    chatsSearchQueryId: null,
}

const chatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NEW_MESSAGES_COUNT:
            return {...state, newMessagesCount: action.payload.newMessagesCount}
        case SET_CHAT_ROOM:
            return {...state, chatRoom: action.payload}
        case SET_CHATS:
            return {...state, chats: action.payload.chats}
        case SET_CHAT:
            return {
                ...state,
                chats: action.payload.chats,
                chat: action.payload.chat
                    ?
                    action.payload.chat.chat
                    :
                    state.chat,
                messages: action.payload.chat
                    ?
                    action.payload.chat.messages.messages
                    :
                    state.messages,
                mayFetchEarlierMessages: action.payload.chat.messages.mayFetchEarlierMessages,
            }
        case SET_PREPARED_CHAT:
            return {
                ...state,
                chat: action.payload.chat,
                messages: [],
            }
        case UPDATE_EDITED_MESSAGE: {
            const {chatId, message} = action.payload

            if (state.chat && state.chat._id === chatId) {
                return {
                    ...state,
                    messages: state.messages.map(msg =>
                        msg._id === message._id
                            ? {...msg, ...message}
                            : msg
                    ),
                    chats: state.chats.map(chat => {
                        if (chat._id === chatId) {
                            chat.lastMessage = message
                        }
                        return chat;
                    })
                }
            }

            return state
        }
        case SET_MEDIA_OF_CHAT:
            return {...state, media: action.payload.media}
        case SET_SELECTED_CHAT_ID:
            return {...state, chatId: action.payload}
        case SET_SEARCHED_CHATS:
            if (action.payload.chatsSearchQueryId === state.chatsSearchQueryId) {
                return {...state, searchedChats: action.payload.chats}
            }
            return state
        case SET_SEARCH_CHATS_QUERY_ID:
            return {...state, chatsSearchQueryId: action.payload.chatsSearchQueryId}
        case SET_REACTIONS:
            return {...state, reactions: action.payload.reactions}
        case SET_REPLYING_MESSAGE:
            return {
                ...state,
                replyingMessage: action.payload,
            };
        case CHAT_MESSAGE:
            if (state.chats && state.chat) {
                if (action.payload.chatId === state.chat._id) {
                    const messageExists = state.messages?.some(
                        (msg) => msg._id === action.payload.message._id
                    )

                    if (!messageExists) {
                        return {
                            ...state,
                            messages: state.messages
                                ? [...state.messages, action.payload.message]
                                : [action.payload.message],
                            chats: state.chats.map((chat) => {
                                if (chat._id === action.payload.chatId) {
                                    chat.lastMessage = action.payload.messageMinimized
                                    chat.updatedAt = action.payload.messageMinimized.createdAt
                                }
                                return chat
                            }),
                        }
                    } else {
                        return state
                    }
                } else {
                    return {
                        ...state,
                        newMessagesCount: state.newMessagesCount + 1,
                        chats: state.chats.map((chat) => {
                            if (chat._id === action.payload.chatId) {
                                chat.lastMessage = action.payload.messageMinimized
                                chat.updatedAt = action.payload.messageMinimized.createdAt

                                if (action.payload.message.author._id !== action.payload.userId) {
                                    chat.newMessagesCount = chat.newMessagesCount + 1
                                }
                            }
                            return chat
                        }),
                    }
                }
            } else {
                if (action.payload.message.author._id !== action.payload.userId) {
                    return {
                        ...state,
                        newMessagesCount: state.newMessagesCount + 1,
                    }
                }
            }
            break
        case USER_PINNED_UNPINNED_MESSAGE:
            if (state.chats && state.chat) {
                if (action.payload.chatId === state.chat._id) {
                    const messagesModified = state.messages.map(message => message._id === action.payload.messageId ? action.payload.message : message)

                    return {
                        ...state,
                        messages: [...messagesModified, action.payload.messageMinimized]
                    }
                }
            }
            break
        case UNPIN_ALL_MESSAGES:
            if (state.chat) {
                const updatedMessages = state.messages.map(message => ({
                    ...message,
                    pinned: false
                }))

                return {
                    ...state,
                    messages: updatedMessages
                }
            }
            return state
        case USER_HAS_SEEN_MESSAGE:
            if (state.chats && state.chat) {
                if (action.payload.chatId === state.chat._id) {
                    const updatedMessages = state.messages.map(message => {
                        const userIdExists = message.seenBy.some(seen => seen.user && seen.user._id === action.payload.userId);

                        if (!userIdExists) {
                            return {
                                ...message,
                                seenBy: [...message.seenBy, {user: action.payload.seenBy.user}]
                            };
                        }
                        return message;
                    });

                    return {
                        ...state,
                        messages: updatedMessages,
                        chats: state.chats.map(chat => {
                            if (chat._id === action.payload.chatId) {
                                const lastMessageSeenBy = chat.lastMessage.seenBy || [];
                                const userIdExistsInLastMessage = lastMessageSeenBy.some(seen => seen.user && seen.user._id === action.payload.userId);

                                if (!userIdExistsInLastMessage) {
                                    chat.lastMessage.seenBy = [...new Set([...lastMessageSeenBy, {user: action.payload.seenBy.user}])];
                                }
                            }
                            return chat;
                        })
                    };
                }
            }
            break
        case USER_DELETED_MESSAGE:
            const messagesModified = state.messages.map(message => {
                if (message._id === action.payload.messageId || action.payload.deletedReferredMessages.includes(message._id)) {
                    message.message = action.payload.messageContent
                    return null;
                } else if (message.repliesTo?._id === action.payload.messageId) {
                    message.repliesTo = null
                }

                return message
            }).filter(message => message)

            const updatedReplyingMessage = state.replyingMessage?._id === action.payload.messageId ? null : state.replyingMessage

            return {
                ...state,
                messages: messagesModified,
                replyingMessage: updatedReplyingMessage,
            }
        case LOAD_MORE_MESSAGES:
            const {type} = action.payload
            return {
                ...state,
                messages: type === 'earlier' ? [...action.payload.messages, ...state.messages] : [...state.messages, ...action.payload.messages],
                mayFetchEarlierMessages: action.payload.mayFetchEarlierMessages,
                mayFetchNextMessages: type === 'next' && action.payload.mayFetchNextMessages,
            }
        case GO_TO_MESSAGE_FETCHED_FROM_SERVER:
            return {
                ...state,
                messages: action.payload.messages,
                mayFetchEarlierMessages: action.payload.mayFetchEarlierMessages,
                mayFetchNextMessages: action.payload.mayFetchNextMessages,
            }
        default:
            return state;
    }
}

export default chatsReducer