import React, { useState } from 'react';
import { AsteriskIcon, CloseIcon } from "../../../../assets/icons";
import { ButtonFilled, ButtonOutlined } from "../../../shared/buttons";
import Calendar from "../../../shared/calendar/Calendar";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { changeLessonDate } from "../../../../store/actions/groups";
import { AutoResizableTextarea } from "../../../shared";

const ReplaceLessonModal = ({ close, targetLessonDate: { targetLessonDate, lessonIndex, levelIndex } }) => {
    const [date, setDate] = useState(null);
    const [note, setNote] = useState('');
    const [time, setTime] = useState('');
    const [errors, setErrors] = useState({});

    const { id } = useParams();
    const dispatch = useDispatch();

    const handleChangeNote = (e) => {
        setNote(e.target.value);
    };

    const handleChangeTime = (e) => {
        const timeValue = e.target.value;
        let value = timeValue.replace(/\D/g, '').slice(0, 4);
        let hours = timeValue.slice(0, 2).replace(/\D/g, '');
        let minutes = timeValue.slice(2).replace(/\D/g, '');

        if (value.length >= 2 && hours <= 24 && minutes <= 59) {
            value = `${value.slice(0, 2)}:${value.slice(2)}`;
        }

        if ((hours > 24) || (minutes > 59 || (minutes >= 6 && minutes <= 9))) return;

        setTime(value);
    };

    const handleKeyDown = (e) => {
        const { value } = e.target;

        if (e.key === 'Backspace' && value.length === 3 && value.charAt(2) === ":") {
            e.preventDefault();
            setTime(value.slice(0, 1));
        }
    };

    const validateFields = () => {
        const newErrors = {};
        if (!date) newErrors.date = "Այս դաշտը պարտադիր է";
        if (!time) newErrors.time = "Այս դաշտը պարտադիր է";
        if (!note) newErrors.note = "Այս դաշտը պարտադիր է";
        return newErrors;
    };

    const handleSubmit = () => {
        const validationErrors = validateFields();

        if (Object.keys(validationErrors).length === 0) {
            close();
            dispatch(changeLessonDate(id, targetLessonDate, date, lessonIndex, levelIndex, note, time));
        } else {
            setErrors(validationErrors);
        }
    };

    const clearError = (field) => {
        setErrors((prevErrors) => ({ ...prevErrors, [field]: null }));
    };

    return (
        <div className={'fixed z-[99999] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div onClick={close}
                 className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[690px] min-h-[400px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-[28px]'}>
                    <h2 className={'text-center'}>Տեղափոխել դասը</h2>

                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>
                <div className={'flex flex-col px-[70px]'}>
                    <div className={'relative flex justify-between'}>
                        <div className={'relative'}>
                            <h2 className={'flex gap-[5px] mb-2.5'}>Տեղափոխվող ամսաթիվ <AsteriskIcon/></h2>
                            <div
                                className={'absolute w-[259px] border border-black border-opacity-70 z-[99999] rounded-[3px] overflow-hidden'}>
                                <Calendar
                                    displayFormat={'DD/MM/YYYY'}
                                    returnFormat={'YYYY-MM-DD'}
                                    placeholder={'օր/ամիս/տարի'}
                                    className={'py-[1px]'}
                                    paddingClassName={'px-0'}
                                    previewClassName={'py-[3px] px-[30px]'}
                                    optionClassName={'px-2'}
                                    gapClassName={'gap-1'}
                                    textSize={'text-[12px]'}
                                    showShortMonths={true}
                                    setDate={(value) => { setDate(value); clearError('date'); }}
                                    value={date}
                                />
                            </div>
                            {errors.date && <p className={'absolute top-[80%] z-[9999] text-red-500 text-sm'}>{errors.date}</p>}
                        </div>
                        <div className={'relative'}>
                            <h2 className={'flex gap-[5px] mb-2.5'}>Տեղափոխվող ժամ <AsteriskIcon/></h2>
                            <div className={''}>
                                <input
                                    type={"text"}
                                    name={'startTime'}
                                    className={`w-[259px] h-[30px] border border-black border-opacity-70 outline-none rounded-[3px] px-5 py-1`}
                                    placeholder={'Օրինակ՝ 16։00'}
                                    onChange={handleChangeTime}
                                    onKeyDown={handleKeyDown}
                                    onFocus={() => clearError('time')}
                                    value={time}
                                />
                                {errors.time && <p className={'text-red-500 text-sm'}>{errors.time}</p>}
                            </div>
                        </div>
                    </div>

                    <div
                        onFocus={() => clearError('note')}
                        className={'relative flex flex-col gap-[10px] mt-[30px]'}>
                        <h2 className={'flex gap-[5px]'}>Տեղափոխման պատճառը <AsteriskIcon/></h2>
                        <AutoResizableTextarea
                            placeholder={'Նոթագրություն'}
                            value={note}
                            onChange={handleChangeNote}
                            autoFocus
                        />
                    </div>
                    {errors.note && <p className={'text-red-500 text-sm'}>{errors.note}</p>}
                </div>
                <div className={'flex justify-center py-[50px] gap-[30px]'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                    <ButtonFilled onClick={handleSubmit} text={'Պահպանել'} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    );
};

export default ReplaceLessonModal;
