import {AUTH_ERROR, CHANGE_PROFILE_SETTINGS, CHECK_AUTH, LOGOUT, SET_LOADING, SIGN_IN} from '../constants/actionTypes'

const initialState = {
    authData: null,
    user: null,
    error: null,
    loading: false
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIGN_IN:
            return {...state, authData: action.payload.user, error: null}
        case CHECK_AUTH:
            return {...state, authData: action.payload.user || 'unauthorized'};
        case CHANGE_PROFILE_SETTINGS:
            return {...state, authData: action.payload.member}
        case LOGOUT:
            return {...state, authData: 'unauthorized'}
        case AUTH_ERROR:
            return {...state, authData: 'unauthorized', error: action.payload.message}
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
};

export default authReducer;