import React, { useEffect, useRef, useState } from 'react';
import {
    ActiveStudentsIcon, CheckedIcon,
    DivideStudentsIcon,
    DriverIcon, DriverYellowIcon,
    EditIcon,
    MenuIcon,
    StopIcon, TransportIcon
} from "../../../../../assets/icons";
import { useDispatch } from "react-redux";
import { ConfirmationModal } from "../../../../shared";
import { Link } from "react-router-dom";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import EditPassengersModal from "../modals/EditPassengersModal";

const TripVehicleItem = ({
                             _id,
                             stops,
                             vehicle,
                             passengersSum,
                             active,
                             selected,
                             handleSelectTrips,
                             vehicleToRemove,
                             setVehicleToRemove,
                             handleRemoveVehicle,
                             passengers,
                             refreshData
                         }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [editPassengersModal, setEditPassengersModal] = useState(null);
    const [showPassengerList, setShowPassengerList] = useState(false);
    const [listPosition, setListPosition] = useState('20px');
    const passengerListRef = useRef(null);

    const checked = selected?.find(selected => selected?._id === _id);

    const ref = useRef();
    useOutsideClick(ref, () => setShowMenu(null));

    const removeVehicle = () => {
        setVehicleToRemove(_id);
        setShowMenu(null);
    };

    useEffect(() => {
        editPassengersModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto';
    }, [editPassengersModal]);

    useEffect(() => {
        if (showPassengerList && passengerListRef.current) {
            const rect = passengerListRef.current.getBoundingClientRect();
            if (rect.bottom > window.innerHeight) {
                setListPosition(`-${rect.height}px`);
            } else {
                setListPosition('20px');
            }
        }
    }, [showPassengerList]);

    const handleMouseEnter = () => {
        setListPosition('20px')
        setShowPassengerList(true);
    };

    const handleMouseLeave = () => {
        setShowPassengerList(false);
    };

    return (
        <>
            {editPassengersModal &&
                <EditPassengersModal
                    close={() => setEditPassengersModal(null)}
                    id={_id}
                    refreshData={refreshData}
                />
            }

            {vehicleToRemove &&
                <ConfirmationModal
                    close={() => setVehicleToRemove(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք հեռացնել այս մեքենան։`}
                    confirm={handleRemoveVehicle}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'flex items-center gap-5'}>
                <div
                    onClick={() => handleSelectTrips(_id)}
                    className={'w-[30px] h-[30px] flex items-center justify-center cursor-pointer border border-black border-opacity-70 rounded-[5px]'}
                >
                    {checked && <CheckedIcon />}
                </div>
                <div className={'relative w-full h-[140px] custom-shadow rounded-[5px] pt-[30px] pb-[25px] px-[30px]'}>
                    <div
                        className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px] cursor-pointer`}></div>

                    <div className={'flex justify-between'}>
                        <div></div>
                        <div className={'flex items-center gap-2.5'}>
                            <StopIcon colour={'#FCC10F'} />
                            <span>{stops[0]?.transportStop.name}</span>
                        </div>
                        <div onClick={() => setShowMenu(!showMenu)} className={'cursor-pointer'}>
                            <MenuIcon />
                        </div>
                    </div>
                    {showMenu && (
                        <div ref={ref}
                             className={`absolute z-[9990] w-[203px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                            <p
                                onClick={() => setEditPassengersModal(true)}
                                className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Խմբագրել
                            </p>
                            <p
                                onClick={removeVehicle}
                                className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Հեռացնել մեքենան
                            </p>
                        </div>
                    )}

                    <div className={'w-full grid grid-cols-3 gap-[250px] mt-[44px] px-10'}>
                        <div className={'flex'}>
                            <div
                                className={'relative group flex items-center gap-2.5'}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <ActiveStudentsIcon color={'#FCC10F'} />
                                <span className={'select-none'}>{passengersSum}</span>
                                <div
                                    ref={passengerListRef}
                                    className={`w-[350px] absolute z-[9999] max-h-[400px] overflow-y-auto flex flex-col gap-5 p-2.5 transition bg-white custom-shadow rounded-[3px] ${showPassengerList ? 'flex' : 'hidden'}`}
                                    style={{ left: '70%', top: listPosition }}
                                >
                                    {passengers?.map(passenger =>
                                        passenger.type === 'student'
                                            ?
                                            <Link
                                                to={`/students/${passenger._id}?mode=view&subpage=profile`}
                                                key={passenger._id}
                                                className={'w-fit hover:text-[#FCC10F] transition'}
                                            >
                                                {passenger?.fullName}
                                            </Link>
                                            :
                                            <p
                                                key={passenger._id}
                                                className={'w-fit cursor-default'}
                                            >
                                                {passenger?.fullName}
                                            </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={'flex items-center gap-5'}>
                            <DriverYellowIcon />
                            <span>Վարորդ</span>
                        </div>
                        <div className={'flex items-center gap-5'}>
                            <TransportIcon color={'#FCC10F'} />
                            <span>{vehicle?.mark}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TripVehicleItem;
