import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getConnect, getWorkshop} from "../../../../../store/actions/groups";
import AddStudentModal from "../../../../../components/groups/group/modals/AddStudent";
import {Container} from "../../../../../components/shared";
import EventHeader from "../../../../../components/smartEvents/event/EventHeader";
import EventInfo from "../../../../../components/smartEvents/event/EventInfo";
import {PlusButton} from "../../../../../components/shared/buttons";
import ButtonHoverText from "../../../../../components/shared/buttons/ButtonHoverText";
import SwitchButton from "../../../../../components/shared/SwitchButton";
import Attendance from "../../../groups/group/attendance/Attendance";
import {roles} from "../../../../../common/roles";

const Connect = () => {
    const {group, loading} = useSelector(state => state.groups)
    const {authData} = useSelector(state => state.auth)

    const [openAddStudentModal, setOpenAddStudentModal] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const [showDroppedStudents, setShowDroppedStudents] = useState(() => {
        const savedState = localStorage.getItem('showDroppedStudents');
        return savedState !== null ? JSON.parse(savedState) : false;
    })

    const changeShowDroppedStudents = () => {
        const newState = !showDroppedStudents;
        setShowDroppedStudents(newState);
        localStorage.setItem('showDroppedStudents', JSON.stringify(newState));
    }

    useEffect(() => {
        dispatch(getConnect(id))
    }, [id])

    useEffect(() => {
        openAddStudentModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [openAddStudentModal])

    return (
        <div>
            {openAddStudentModal &&
                <AddStudentModal
                    close={() => setOpenAddStudentModal(false)}
                    type={'connect'}
                />
            }

            <Container>
                <div className={'fixed top-[7%] max-w-[1280px] w-full z-[9999] bg-white'}>
                    <EventHeader {...group} />
                    <EventInfo {...group} type={'connect'}/>
                </div>

                <div className={`flex items-center justify-between ${group?.name?.length <= 30 ? 'mt-[230px]' : group?.name?.length > 50 ? 'mt-[160px]' : 'mt-[110px]'}`}>
                    {roles[authData?.role]?.some(role => role.allowAddStudentInGroup)
                        ?
                        <PlusButton
                            onClick={() => setOpenAddStudentModal(true)}
                            className={'p-2.5'}
                            width={10}
                            height={10}
                            styleClassName={'hover:bg-white transition border border-[#FCC10F]'}
                            hoverPlusColor={'#FCC10F'}
                        />
                        :
                        <div></div>
                    }

                    <ButtonHoverText
                        icon={<SwitchButton checked={showDroppedStudents} handleChange={changeShowDroppedStudents} />}
                        text={'Ցույց տալ դուրս մնացած մասնակիցներին'}
                    />
                </div>

                <Attendance {...group} showDroppedStudents={showDroppedStudents} type={group?.type}/>
            </Container>
        </div>
    );
};

export default Connect;