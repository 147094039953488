import React, {useState} from 'react';
import VideoPreviewModal from "./modals/VideoPreviewModal";
import ImageModal from "./modals/ImageModal";
import {useMediaQuery} from "react-responsive";
import ButtonHoverText from "../../../shared/buttons/ButtonHoverText";
import {CloseIcon, LikeIcon, PdfColoredIcon, ReturnLeftIcon} from "../../../../assets/icons";
import {Viewer, Worker} from "@react-pdf-viewer/core";
import {likeProject} from "../../../../store/actions/programs";
import {useDispatch} from "react-redux";

const ProjectItemMobile = ({
                               _id,
                               description,
                               file,
                               media,
                               title,
                               group,
                               page,
                               size,
                               type,
                               programId,
                               pageType,
                               likes,
                               userHasLiked,
                           }) => {
    const isImage = media.endsWith('.jpg') || media.endsWith('.jpeg') || media.endsWith('.png') || media.endsWith('.gif') || media.endsWith(".webp")

    const [videoPreview, setVideoPreview] = useState(null)
    const [showPdfViewer, setShowPdfViewer] = useState(false)
    const [showImageModal, setShowImageModal] = useState(false)
    const [modalImage, setModalImage] = useState(null)

    const dispatch = useDispatch()

    const isPhone = useMediaQuery({maxWidth: 640})

    const openImageModal = (imageSrc) => {
        setModalImage(imageSrc)
        setShowImageModal(true)
    }

    const openPdfViewer = () => {
        setShowPdfViewer(true);
    }

    const closeImageModal = () => {
        setShowImageModal(false)
    }

    const handleLikeProject = () => {
        dispatch(likeProject(_id, programId, page, type, size, pageType))
    }

    return (
        <>
            {videoPreview && (
                <VideoPreviewModal
                    video={media}
                    close={() => setVideoPreview(null)}
                />
            )}

            {showImageModal && (
                <ImageModal
                    close={closeImageModal}
                    imageSrc={modalImage}
                />
            )}

            {showPdfViewer && (
                <>
                    <div className="pdf-overlay bg-black bg-opacity-20"></div>
                    <div className="pdf-viewer-modal overflow-hidden">
                        <div className={'flex items-center justify-between'}>
                            <div
                                onClick={() => setShowPdfViewer(null)}
                                className={'flex items-center gap-[30px] py-[10px] cursor-pointer'}>
                                <ReturnLeftIcon/>
                                <div className={'flex items-center gap-[10px]'}>
                                    <PdfColoredIcon/>
                                    <span>{title}</span>
                                </div>
                            </div>
                            <div
                                onClick={() => setShowPdfViewer(null)}
                                className={'w-[30px] h-[30px] border border-black bg-white rounded-full p-[5px] shadow-md cursor-pointer'}>
                                <CloseIcon width={20} height={20}/>
                            </div>
                        </div>
                        <div className="pdf-viewer-container">
                            <div className="pdf-viewer-content">
                                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                    <Viewer fileUrl={file}/>
                                </Worker>
                            </div>
                        </div>
                    </div>
                </>
            )}

            <div className={'w-[345px] max-h-[483px] custom-shadow bg-white rounded-[5px]'}>
                <p className={'text-center text-[#FCC10F]'}>{title}</p>

                <div className={isPhone && 'flex items-center justify-center mt-[30px] mb-5'}>
                    {isImage ?
                        <img
                            src={media}
                            alt={'image'}
                            className={'w-full h-full max-w-[154px] max-h-[83px] object-cover cursor-pointer'}
                            onClick={() => openImageModal(media)}
                        />
                        :
                        <div className="video-wrapper" onClick={() => setVideoPreview(true)}>
                            <video src={media} className={'w-full h-full max-w-[154px] max-h-[83px]'}></video>
                        </div>
                    }
                </div>

                <div className={'flex flex-col gap-1 px-5'}>
                    <p className={'text-[14px] font-bold'}>{group?.name}</p>
                    <p className={'max-h-[240px] break-all text-[14px]'}>{description}</p>
                </div>

                <div className={'flex items-center justify-between'}>
                    <div className={'px-5 mt-2.5 mb-5'}>
                        {file && (
                            <ButtonHoverText
                                icon={<PdfColoredIcon/>}
                                text={'Բացել ֆայլը'}
                                onClick={openPdfViewer}
                                className={'mr-5'}
                            />
                        )}
                    </div>

                    <div className={'right-0 bottom-0 mb-5 mr-5 flex items-center gap-2.5'}>
                        <span className={'text-[14px] lg:text-[16px]'}>{likes}</span>
                        <div
                            onClick={handleLikeProject}
                            className={'fill-icon cursor-pointer'}>
                            <p className={'relative group flex items-center gap-[10px]'}>
                                <LikeIcon color={userHasLiked && '#FCC10F'} width={17} height={17}/>
                                <span
                                    className={`absolute z-[999] w-[80px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Հավանել</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ProjectItemMobile;