import {
    CREATE_STATEMENT, DELETE_STATEMENT, DUPLICATE_STATEMENT,
    EDIT_STATEMENT, FETCH_STATEMENT,
    GET_STATEMENT,
    GET_STATEMENTS,
    SELECT_STATEMENT,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    statements: {
        data: [],
    },
    statement: null,
    loading: false,
}

const statementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_STATEMENT:
            return {
                ...state,
                statements: {
                    ...state.statements,
                    data: [action.payload.statement, ...state.statements.data]
                }
            }
        case GET_STATEMENTS:
            return {
                ...state,
                statements: {data: action.payload.data, pagesCount: action.payload.pagesCount},
            }
        case GET_STATEMENT:
            return { ...state, statement: action.payload.statement}
        case SELECT_STATEMENT:
            return {...state, statement: action.payload.statement}
        case DUPLICATE_STATEMENT:
            return {
                ...state,
                statements: {
                    ...state.statements,
                    data: [action.payload.statement, ...state.statements.data]
                }
            }
        case EDIT_STATEMENT:
            const updatedStatement = action.payload.statement
            const updatedStatements = state.statements.data.map(statement => {
                if (statement._id === updatedStatement._id) {
                    return { ...statement, ...updatedStatement}
                }
                return statement
            })

            return {
                ...state,
                statements: {
                    ...state.statements,
                    data: updatedStatements
                }
            }
        case FETCH_STATEMENT:
            return {...state, statement: action.payload.statement }
        case DELETE_STATEMENT:
            const filteredStatements = state.statements.data.filter(statement => statement._id !== action.payload.id)
            return {
                ...state,
                statements: {
                    ...state.statements,
                    data: filteredStatements
                }
            }
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}
export default statementsReducer