import React, {useEffect, useState} from 'react';
import {Container} from "../../../../../components/shared";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {ReturnLeftIcon, RoutesIcon, StopIcon, TransportIcon, YellowRouteIcon} from "../../../../../assets/icons";
import {DropdownInputCheckbox} from "../../../../../components/shared/dropdowns";
import {useDispatch, useSelector} from "react-redux";
import {
    addRouteToTrip, checkRoutesMatch,
    getTripsAtTime,
    getTripWeekday,
    getTripWeeks, mergeTrip, mergeTrips,
    removeTripRoute,
    removeTripVehicle,
    selectTrips
} from "../../../../../store/actions/transport";
import TripItem from "../../../../../components/transport/subpages/trips/items/TripItem";
import TripVehicleItem from "../../../../../components/transport/subpages/trips/items/TripVehicleItem";
import TripRouteAndVehicleItem from "../../../../../components/transport/subpages/trips/items/TripRouteAndVehicleItem";
import TripRouteItem from "../../../../../components/transport/subpages/trips/items/TripRouteItem";
import moment from "moment";
import AddRoute from "../../../../../components/transport/subpages/trips/modals/AddRoute";
import AddVehicle from "../../../../../components/transport/subpages/trips/modals/AddVehicle";

const weekNames = [
    {name: 'Երկուշաբթի', index: 0},
    {name: 'Երեքշաբթի', index: 1},
    {name: 'Չորեքշաբթի', index: 2},
    {name: 'Հինգշաբթի', index: 3},
    {name: 'Ուրբաթ', index: 4},
    {name: 'Շաբաթ', index: 5},
    {name: 'Կիրակի', index: 6}
];

const TransportTrips = () => {
    const {weeks, weekday, trips, selected} = useSelector(state => state.transport)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const selectedTripsId = selected?.map(trip => trip._id)
    const joinedIds = selectedTripsId?.join(',')

    const initialParams = new URLSearchParams(location.search)
    const initialWeek = initialParams.get('week') || null
    const initialWeekday = initialParams.get('weekday') || null
    const initialType = initialParams.get('type') || null
    const initialTime = initialParams.get('time') || null

    const [selectedWeek, setSelectedWeek] = useState(initialWeek)
    const [selectedWeekday, setSelectedWeekday] = useState(initialWeekday)
    const [selectedType, setSelectedType] = useState(initialType)
    const [selectedTime, setSelectedTime] = useState(initialTime)
    const [modifiedWeeks, setModifiedWeeks] = useState(null)

    const [addRoute, setAddRoute] = useState(null)
    const [addVehicle, setAddVehicle] = useState(null)
    const [routeToRemove, setRouteToRemove] = useState(null)
    const [vehicleToRemove, setVehicleToRemove] = useState(null)
    const [isHovered, setIsHovered] = useState(false)
    const [error, setError] = useState(null)

    const handleRemoveRoute = () => {
        dispatch(removeTripRoute(routeToRemove))
            .then(() => {
                refreshData()
                setRouteToRemove(null)
            })
    }

    const handleRemoveVehicle = () => {
        dispatch(removeTripVehicle(vehicleToRemove))
            .then(() => {
                refreshData()
                setVehicleToRemove(null)
            })
    }

    const handleWeekChange = (_, selectedWeek) => {
        setSelectedWeek(selectedWeek)
        const params = new URLSearchParams(location.search)
        params.set('week', selectedWeek._id)
        navigate({search: params.toString()})
    }

    const handleWeekdayClick = (index) => {
        setSelectedWeekday(index.toString())
        const params = new URLSearchParams(location.search)
        params.set('weekday', index.toString())
        navigate({search: params.toString()})
    }

    const handleTypeClick = (type) => {
        setSelectedType(type)
        const params = new URLSearchParams(location.search)
        params.set('type', type)
        navigate({search: params.toString()})
    }

    const handleTimeClick = (time) => {
        setSelectedTime(time)
        const params = new URLSearchParams(location.search)
        params.set('time', time)
        navigate({search: params.toString()})
    }

    const handleSelectTrips = (id) => {
        dispatch(selectTrips(id))
        setError('')
    }

    const handleMergeTrips = () => {
        dispatch(mergeTrips(joinedIds, setError))
            .then(() => {
                refreshData()
            })
            .catch(error => {
                setError(error.message)
            })
    }

    const renderTripComponent = (trip) => {
        switch (trip.type) {
            case 'withoutRouteAndVehicle':
                return <TripItem
                    key={trip.id}
                    {...trip}
                    handleSelectTrips={handleSelectTrips}
                    selected={selected}
                    refreshData={refreshData}
                />
            case 'withoutRouteWithVehicle':
                return <TripVehicleItem
                    key={trip.id}
                    {...trip}
                    handleSelectTrips={handleSelectTrips}
                    selected={selected}
                    vehicleToRemove={vehicleToRemove}
                    setVehicleToRemove={setVehicleToRemove}
                    handleRemoveVehicle={handleRemoveVehicle}
                    refreshData={refreshData}
                />
            case 'withoutVehicleWithRoute':
                return <TripRouteItem
                    key={trip.id}
                    {...trip}
                    handleSelectTrips={handleSelectTrips}
                    routeToRemove={routeToRemove}
                    setRouteToRemove={setRouteToRemove}
                    handleRemoveRoute={handleRemoveRoute}
                    refreshData={refreshData}
                    selected={selected}
                />
            case 'withVehicleAndRoute':
                return <TripRouteAndVehicleItem
                    key={trip.id}
                    {...trip}
                    handleSelectTrips={handleSelectTrips}
                    routeToRemove={routeToRemove}
                    setRouteToRemove={setRouteToRemove}
                    handleRemoveRoute={handleRemoveRoute}
                    vehicleToRemove={vehicleToRemove}
                    setVehicleToRemove={setVehicleToRemove}
                    handleRemoveVehicle={handleRemoveVehicle}
                    refreshData={refreshData}
                />
            default:
                return null
        }
    }

    const isAddingRouteAllowed = () => {
        return selected?.some(trip => trip.type === 'withoutRouteWithVehicle')
    }

    const isAddingVehicleAllowed = () => {
        return selected?.some(trip => trip.type === 'withoutVehicleWithRoute')
    }

    const isAddingVehicleAndRouteAllowed = () => {
        return selected?.some(trip => trip.type === 'withoutRouteAndVehicle')
    }

    const handleAddVehicleClick = () => {
        if (isAddingVehicleAllowed() || isAddingVehicleAndRouteAllowed()) {
            setAddVehicle(true)
        } else {
            alert('Հնարավոր չէ կցել մեքենա, քանի որ այն արդեն առկա է։')
        }
    };

    const handleAddRouteClick = () => {
        if (isAddingRouteAllowed() || isAddingVehicleAndRouteAllowed()) {
            dispatch(checkRoutesMatch(joinedIds, setAddRoute, setError))
                .then(() => {
                    // refreshData()
                })
                .catch(error => {
                    setError(error.message)
                })
        } else {
            alert('Հնարավոր չէ կցել երթուղի, քանի որ այն արդեն առկա է։')
        }
    }

    const refreshData = () => {
        const params = new URLSearchParams(location.search)
        const weekId = params.get('week')
        const weekdayIndex = params.get('weekday')
        const type = params.get('type')
        const time = params.get('time')

        if (weekId && weekdayIndex !== null) {
            dispatch(getTripWeekday(weekId, weekdayIndex))
        }

        if (weekId && weekdayIndex !== null && type && time) {
            dispatch(getTripsAtTime(weekId, weekdayIndex, type, time))
        }
    }

    useEffect(() => {
        if (weeks) {
            const formattedWeeks = weeks.map(week => {
                const dates = week.name.split(' - ')
                return {
                    ...week,
                    name: `${moment(dates[0], 'YYYY-MM-DD').format('DD.MM.YYYY')} ━ ${moment(dates[1], 'YYYY-MM-DD').format('DD.MM.YYYY')}`
                }
            })

            setModifiedWeeks(formattedWeeks)

            const defaultWeek = formattedWeeks?.find(week => week.selected)

            if (defaultWeek && !selectedWeek) {
                setSelectedWeek(defaultWeek)
                const params = new URLSearchParams(location.search)
                params.set('week', defaultWeek._id)
                navigate({search: params.toString()})
            }
        }
    }, [weeks, selectedWeek, navigate, location.search])

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const weekId = params.get('week')

        const currentWeek = modifiedWeeks?.find(week => week._id === weekId)

        if (currentWeek && selectedWeek !== currentWeek) {
            setSelectedWeek(currentWeek)
        }

    }, [location.search, weeks])

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const weekId = params.get('week')
        const weekdayIndex = params.get('weekday')
        const type = params.get('type')
        const time = params.get('time')

        if (weekId && weekdayIndex !== null) {
            dispatch(getTripWeekday(weekId, weekdayIndex))
        }

        if (weekId && weekdayIndex !== null && type && time) {
            dispatch(getTripsAtTime(weekId, weekdayIndex, type, time))
        }

        dispatch(getTripWeeks())
    }, [location.search])

    useEffect(() => {
        if (error) {
            setError(error)
        }
    }, [error])

    return (
        <div>
            {addRoute &&
                <AddRoute
                    close={() => setAddRoute(null)}
                    joinedIds={joinedIds}
                    refreshData={refreshData}
                    error={error}
                    setError={setError}
                />
            }
            {addVehicle &&
                <AddVehicle
                    close={() => setAddVehicle(null)}
                    joinedIds={joinedIds}
                    refreshData={refreshData}
                    error={error}
                    setError={setError}
                />
            }
            <Container>
                <Link to={'/transport'} className={'flex items-center gap-[30px] group w-fit cursor-pointer fill-icon'}>
                    <ReturnLeftIcon/>
                    <p className={'transition group-hover:text-[#FCC10F]'}>Տրանսպորտ</p>
                </Link>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Երթադարան</h2>

                <div className={'flex flex-col gap-[30px]'}>
                    <div>
                        <div className={'w-full max-w-[355px] float-end'}>
                            <DropdownInputCheckbox
                                previewClassName={'py-[11.5px] px-5 border-opacity-70 rounded-[5px]'}
                                optionClassName={'px-5'}
                                data={modifiedWeeks}
                                onChange={handleWeekChange}
                                disableInput
                                hideCheckbox
                                selected={selectedWeek ? [selectedWeek] : []}
                            />
                        </div>
                    </div>

                    <div className={'flex gap-[15px]'}>
                        {weekNames.map((weekObj, index) => (
                            <div
                                key={weekObj.name}
                                onClick={() => handleWeekdayClick(weekObj.index)}
                                className={`w-[170px] h-10 flex items-center justify-center rounded-[5px] py-2.5 cursor-pointer
                ${selectedWeekday === weekObj.index.toString() ? 'border border-[#FCC10F] text-[#FCC10F]' : 'border border-black'}`}
                            >
                                <span>{weekObj.name}</span>
                            </div>
                        ))}
                    </div>

                    <div className={'flex gap-[45px]'}>
                        <div className={'w-[170px] px-[60px] flex flex-col gap-[60px]'}>
                            <div></div>
                            {selectedType === 'arrival' && weekday?.hours?.arrival?.map((hour, index) => (
                                <div
                                    key={index}
                                    className={`max-w-[45px] cursor-pointer border-b-[3px] ${selectedTime === hour ? 'border-[#FCC10F]' : 'border-white'}`}
                                    onClick={() => handleTimeClick(hour)}
                                >
                                    {hour}
                                </div>
                            ))}
                            {selectedType === 'departure' && weekday?.hours?.departure?.map((hour, index) => (
                                <div
                                    key={index}
                                    className={`cursor-pointer border-b-[3px] border-[#FCC10F] ${selectedTime === hour ? 'border-opacity-100' : 'border-opacity-0'}`}
                                    onClick={() => handleTimeClick(hour)}
                                >
                                    {hour}
                                </div>
                            ))}
                        </div>
                        <div className={'relative flex flex-col w-full'}>
                            <div className={'flex flex-col gap-[30px] w-full h-fit'}>
                                <div className={' max-w-[845px] flex justify-between'}>
                                    <div onClick={() => handleTypeClick('arrival')}
                                         className={`cursor-pointer border-b-[3px] ${selectedType === 'arrival' ? 'border-[#FCC10F]' : 'border-white'}`}>Ժամանում
                                    </div>
                                    <div className={'border border-black border-opacity-70 max-h-5'}></div>
                                    <div onClick={() => handleTypeClick('departure')}
                                         className={`cursor-pointer border-b-[3px] ${selectedType === 'departure' ? 'border-[#FCC10F]' : 'border-white'}`}>Մեկնում
                                    </div>
                                </div>
                                <div className={'flex flex-col gap-5'}>
                                    {selectedTime && trips?.map(trip => renderTripComponent(trip))}
                                </div>
                            </div>
                            {error && error.message}
                        </div>
                    </div>
                </div>
                {selectedType &&
                    <div className={'w-full flex gap-[30px] justify-end mt-[30px]'}>
                        <div
                            onClick={handleMergeTrips}
                            className={'group w-[267px] h-10 flex items-center justify-center gap-[13px] py-2.5 border border-black hover:border-[#FCC10F] hover:text-[#FCC10F] rounded-[5px] cursor-pointer fill-icon transition'}>
                            <span>Միավորել կանգառը</span>
                            <StopIcon color={'#000'}/>
                        </div>
                        <div
                            onClick={handleAddVehicleClick}
                            className={'group w-[276px] h-10 flex items-center justify-center gap-[13px] py-2.5 border border-black hover:border-[#FCC10F] hover:text-[#FCC10F] rounded-[5px] cursor-pointer fill-icon transition'}>
                            <span>Կցել մեքենա</span>
                            <TransportIcon color={'#000'}/>
                        </div>
                        <div
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            onClick={handleAddRouteClick}
                            className={'group w-[276px] h-10 flex items-center justify-center gap-[13px] py-2.5 border border-black hover:border-[#FCC10F] hover:text-[#FCC10F] rounded-[5px] cursor-pointer fill-icon transition'}
                        >
                            <span>Կցել երթուղի</span>
                            {isHovered ? <YellowRouteIcon width={20} height={20} color={'#000'}/> :
                                <RoutesIcon width={20} height={20} color={'#000'}/>}
                        </div>
                    </div>
                }
            </Container>
        </div>
    );
};

export default TransportTrips;
