export const parseQueryParams = (urlString) => {
    const url = new URL(urlString);

    const params = new URLSearchParams(url.search);

    const parsedParams = {}

    for (const [key, value] of params.entries()) {
        if (value) {
            switch (key) {
                case 'community':
                case 'status':
                case 'requestStatus':
                case 'formN':
                case 'schedule':
                case 'program':
                case 'ageGroup':
                case 'clothingSize':
                case 'finishedPrograms':
                case 'finishedProgramsCount':
                case 'region':
                case 'routeStart':
                case 'routeDuration':
                case 'distance':
                case 'stopsCount':
                case 'gender':
                    parsedParams[key] = value.split(',').map(String)
                    break
                case 'age':
                    parsedParams[key] = value.split(',').map(Number)
                    break
                case 'page':
                case 'size':
                    parsedParams[key] = parseInt(value)
                    break
                case 'archive':
                    parsedParams[key] = value !== 'false'
                    break
                default:
                    parsedParams[key] = value
            }
        }
    }

    return parsedParams
}

export const convertToQueryParams = (filters) => {
    let url = ''
    if (filters && !filters?.page) {
        filters.page = 1
        filters.size = 10
    }

    for (const filtersKey in filters) {
        if (filters[filtersKey] || filtersKey === 'archive') {
            if (typeof filters[filtersKey] !== 'object') {
                url += `${filtersKey}=${filters[filtersKey]}&`
            } else {
                let values = []
                filters[filtersKey].forEach(valueItem => {
                    if (filtersKey === 'age') {
                        values.push(valueItem.name)
                    } else {
                        values.push(valueItem._id)
                    }
                })

               url += `${filtersKey}=${values.join(',')}&`
            }
        }
    }

    return url
}