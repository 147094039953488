import {
    GET_COMMUNITY_NAMES,
    GET_MEMBER_ROLES,
    GET_MEMBERS_NAMES_LIST,
    GET_PROGRAM_NAMES,
    GET_REGIONS,
    GET_ROUTE_NAMES,
    GET_SCHEDULE_CODES,
    GET_STATEMENT_PROPS,
    GET_TRANSPORT_ROUTE_FILTERS,
    GET_TRANSPORT_STOP_NAMES,
    GET_VEHICLE_FILTERS,
    GET_VEHICLE_MARKS,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    communities: null,
    programs: null,
    members: null,
    educationalEstablishments: null,
    roles: null,
    stopNames: null,
    routeNames: null,
    regions: null,
    routeFilters: null,
    vehicleFilters: null,
    vehicleMarks: null,
    scheduleCodes: null,
    statementProps: null,
    loading: false,
}

const commonsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMMUNITY_NAMES:
            return { ...state, communities: action.payload.communities}
        case GET_PROGRAM_NAMES:
            return {...state, programs: action.payload.programs}
        case GET_ROUTE_NAMES:
            return {...state, routeNames: action.payload.routeNames}
        case GET_MEMBERS_NAMES_LIST:
            return {...state, members: action.payload.members}
        case GET_MEMBER_ROLES:
            return {...state, roles: action.payload.roles}
        case GET_TRANSPORT_STOP_NAMES:
            return {...state, stopNames: action.payload.transportStops}
        case GET_REGIONS:
            return {...state, regions: action.payload.regions}
        case GET_TRANSPORT_ROUTE_FILTERS:
            return {...state, routeFilters: action.payload.filters}
        case GET_VEHICLE_FILTERS:
            return {...state, vehicleFilters: action.payload.filters}
        case GET_VEHICLE_MARKS:
            return {...state, vehicleMarks: action.payload.vehicleMarks}
        case GET_SCHEDULE_CODES:
            return {...state, scheduleCodes: action.payload.scheduleCodes}
        case GET_STATEMENT_PROPS:
            return {...state, statementProps: action.payload.data}
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
}

export default commonsReducer