import {
    CHANGE_STUDENT_PROFILE_IMAGE,
    CHANGE_STUDENT_STATUS, CREATE_GROUP_STUDENT_NOTE,
    CREATE_NOTE,
    CREATE_STUDENT_FILE,
    DELETE_GROUP_NOTE,
    DELETE_NOTE,
    DELETE_STUDENT_FILE,
    EDIT_NOTE,
    EDIT_STUDENT,
    GET_NOTES,
    GET_STUDENT,
    GET_STUDENT_FILES,
    GET_STUDENTS, GET_STUDENTS_FILTERS, RESET_PASSWORD,
    SELECT_STUDENTS,
    SET_LOADING
} from "../constants/actionTypes";
import * as studentsApi from "../../api/students";
import {getGroupNotes} from "./groups";
import {downloadQrCodes} from "../../api/students";
import {downloadFileFromSrc} from "../../utils/fileDownloads/downloadFile";

export const getStudents = (filters) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.getStudents(filters)

        dispatch({type: GET_STUDENTS, payload: data})
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const editStudent = (id, edited, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.editStudent(id, edited)

        dispatch({type: EDIT_STUDENT, payload: data})

        window.location.href = '/students?page=1&size=10&'
        // navigate('/students?page=1&size=10&')
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error)
        error.response.data.message && alert(error.response.data.message)
    }
};

export const selectStudent = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        dispatch({type: SELECT_STUDENTS, payload: {id}})
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeStudentStatus = (id, status) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.changeStudentStatus(id, status)

        dispatch({type: CHANGE_STUDENT_STATUS, payload: data})
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}


export const changeStudentProfileImage = (id, imageData) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.changeStudentProfileImage(id, imageData)

        dispatch({type: CHANGE_STUDENT_PROFILE_IMAGE, payload: data})
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getStudent = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.getStudent(id)

        dispatch({type: GET_STUDENT, payload: data})
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error)
        error.response.data.message && alert(error.response.data.message)
    }
};

export const getStudentNotes = (studentId, page) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.getNotes(studentId, page)

        dispatch({type: GET_NOTES, payload: data})
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error)
        error.response.data.message && alert(error.response.data.message)
    }
};

export const createStudentNote = (studentId, noteData, groupId, type, page) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.createNote(studentId, noteData)

        dispatch({type: CREATE_NOTE, payload: data})

        type === 'group' && dispatch(getGroupNotes(groupId, page))
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const editStudentNote = (noteId, noteData, groupId, type, page) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})
        const {data} = await studentsApi.editNote(noteId, noteData)

        dispatch({type: EDIT_NOTE, payload: data})

        type === 'group' && dispatch(getGroupNotes(groupId, page))

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const deleteStudentNote = (noteId) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.deleteNote(noteId)

        dispatch({type: DELETE_NOTE, payload: data})

        dispatch({type: SET_LOADING})

    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const deleteGroupStudentNote = (noteId) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.deleteNote(noteId)

        dispatch({type: DELETE_GROUP_NOTE, payload: data})
        dispatch({type: SET_LOADING})

    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getStudentFiles = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})
        const {data} = await studentsApi.getStudentFiles(id)

        dispatch({type: GET_STUDENT_FILES, payload: data})
        dispatch({type: SET_LOADING})

    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const createStudentFile = (id, formData) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.createStudentFile(id, formData)

        dispatch({type: CREATE_STUDENT_FILE, payload: data})
        dispatch({type: SET_LOADING})

    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const deleteStudentFile = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.deleteStudentFile(id)

        dispatch({type: DELETE_STUDENT_FILE, payload: data})
        dispatch({type: SET_LOADING})

    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getStudentsFilters = () => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.getStudentsFilters()

        dispatch({type: GET_STUDENTS_FILTERS, payload: data})
        dispatch({type: SET_LOADING})

    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const resetPassword = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await studentsApi.resetPassword(id)

        dispatch({type: RESET_PASSWORD, payload: data})
        dispatch({type: SET_LOADING})

    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const downloadStudentQrCodes = (ids) => async (dispatch) => {
    try {
        await studentsApi.downloadQrCodes(ids)

        const {data} = await studentsApi.downloadQrCodes(ids)

        window.open(data.filePath, '_blank')
    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}