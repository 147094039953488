import {setNewNotificationsCount} from "../../store/actions/notifications";
import {decryptMessage, encryptMessage} from "../encryption";

export const getNewNotificationsCount = async (socket, dispatch) => {
    const cb = async (response) => {
        response = await decryptMessage(response)
        dispatch(setNewNotificationsCount(response.count))
    }

    if (socket) {
        const obj = {
            type: 'get_new_notifications_count',
        }

        const encryptedMessage = await encryptMessage(obj)

        socket.emit('msg', encryptedMessage, cb)
    }
}