import React from 'react';
import {useSelector} from "react-redux";
import {Container} from "../shared";
import DropdownInputCheckbox from "../shared/dropdowns/DropdownInputCheckbox";
import SelectedFilters from "../shared/SelectedFilters";
import {ageData, requestStatusData, studentStatusData} from "../../common/dropdownOptions";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";

const RequestsFilters = ({
                             show,
                             filters,
                             setFilters,
                             selectedFilters,
                             setSelectedFilters,
                             handleChange,
                             discardFilters,
                             submitFilters,
                             changeSelectedFilters
                         }) => {
    const {communities} = useSelector(state => state.common)
    const {formNames} = useSelector(state => state.forms)

    if (!show) return

    const handleEnter = (e) => {
        e.key === 'Enter' && submitFilters()
    }

    return (
        <div className={'custom-shadow mt-5'}>
            <Container>
                <div className={'relative flex flex-wrap gap-3 mt-4 pl-[53px]'}>
                    <div className={'w-[165px]'}>
                        <input type={"text"}
                               className={'w-full border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                               name={'firstName'}
                               value={filters?.firstName || ''}
                               onKeyDown={handleEnter}
                               onChange={handleChange}
                               placeholder={"Անուն"}/>
                    </div>
                    <div className={'w-[165px]'}>
                        <input type={"text"}
                               className={'w-full border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                               name={'lastName'}
                               value={filters?.lastName || ''}
                               onChange={handleChange}
                               onKeyDown={handleEnter}
                               placeholder={"Ազգանուն"}/>
                    </div>
                    <div className={'w-[165px]'}>
                        <input type={"text"}
                               className={'w-full border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                               name={'middleName'}
                               value={filters?.middleName || ''}
                               onChange={handleChange}
                               onKeyDown={handleEnter}
                               placeholder={"Հայրանուն"}/>
                    </div>

                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={ageData} name={'age'} placeholder={'Տարիք'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               selected={filters?.age}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={communities} name={'community'} placeholder={'Համայնք'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               selected={filters?.community}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={requestStatusData} name={'status'}
                                               placeholder={'Կարգավիճակ'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'} selected={filters?.status}
                                               hideCheckbox/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={formNames} name={'formN'} placeholder={'Հայտի համար'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'} selected={filters?.formN}/>
                    </div>
                </div>
                <div className={'flex justify-between pl-[53px] mt-[30px] mb-4'}>
                    <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters} />

                    <div className={'flex gap-3 items-center'}>
                        <ButtonOutlined
                            onClick={discardFilters}
                            text={'Չեղարկել'}
                        />
                        <ButtonFilled
                            onClick={() => submitFilters()}
                            className={'shadow-md w-[165px]'}
                            text={'Կիրառել'}
                        />
                    </div>
                </div>
            </Container>
        </div>);
};

export default RequestsFilters;