import React, {useEffect, useRef, useState} from 'react';
import Message from "./message/Message";
import ChatMediaViewer from "./ChatMediaViewer";
import {useDispatch, useSelector} from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import {ArrowIcon, ReturnLeftIcon} from "../../../assets/icons"
import {goToMessage, loadMoreMessages} from "../../../socket/chats";
import {useMediaQuery} from "react-responsive";

const formatDateInArmenian = (date) => {
    const today = new Date()
    const messageDate = new Date(date)

    const isSameDay = today.toDateString() === messageDate.toDateString()

    if (isSameDay) return 'Այսօր'

    const yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    const isYesterday = yesterday.toDateString() === messageDate.toDateString()

    if (isYesterday) return 'Երեկ'

    const diffTime = today - messageDate
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

    return `${diffDays} օր առաջ`
}

const ChatBody = ({
                      openChatMediaViewer,
                      setOpenChatMediaViewer,
                      replyToMessage,
                      setReplyToMessage,
                      setReplyingTo,
                      openMedia,
                      setOpenMedia,
                      typingAreaHeight,
                      handlePinUnpinMessage,
                      showPinnedMessages,
                      setShowPinnedMessages,
                      handleReplyToMessage,
                      handleDeleteMessage,
                      userCanSendMessage,
                      setPinnedMessages,
                      pinnedMessagesCount,
                      chatType,
                      chatImage,
                      chatName,
                      setIsEditingMessage,
                      setEditMessage,
                      isEditingMessage,
                      setShowMenu,
                      openMenuMessageId,
                      setOpenMenuMessageId,
                      handleSelectMedia,
                      setIsMediaOpen,
                      isMediaOpen,
                      chat,
                      chats,
                  }) => {
    const {messages, mayFetchEarlierMessages, mayFetchNextMessages} = useSelector(state => state.chats)
    const {authData} = useSelector(state => state.auth)
    const {socket} = useSelector(state => state.socket)
    const [messagesLoading, setMessagesLoading] = useState(false)

    const dispatch = useDispatch()

    const isPhone = useMediaQuery({maxWidth: 640})

    const [replyToMessageHeight, setReplyToMessageHeight] = useState(0)
    const [editMessageHeight, setEditMessageHeight] = useState(0)
    const [showScrollToBottom, setShowScrollToBottom] = useState(false)
    const scrollableDivRef = useRef(null)

    const handleLoadEarlier = () => {
        loadMoreMessages(socket, 'earlier', chat._id, messages[0]._id, dispatch, setMessagesLoading)
    }

    const handleScrollToBottom = () => {
        if (scrollableDivRef.current) {
            scrollableDivRef.current.scrollTo({top: 0, behavior: 'smooth'})
        }
    }

    const handleScroll = (e) => {
        if (scrollableDivRef.current?.scrollTop > -2000 && mayFetchNextMessages && !messagesLoading) {
            setMessagesLoading(true)
            loadMoreMessages(socket, 'next', chat._id, messages[messages.length - 1]._id, dispatch, setMessagesLoading)
        }
    }

    const handleGoToMessage = (messageId) => {
        if (messages.some(message => message._id === messageId)) {
            const messageBlock = document.getElementById(messageId)
            messageBlock.scrollIntoView({block: 'start'})
        } else {
            const scrollToMessageBlock = () => {
                const messageBlock = document.getElementById(messageId)
                messageBlock?.scrollIntoView({block: 'start'})
            }

            goToMessage(socket, chat._id, messageId, dispatch, scrollToMessageBlock)
        }
    }

    const calculateHeight = () => {
        return (replyToMessage || isEditingMessage)
            ? `calc(100vh - ${200 + typingAreaHeight + editMessageHeight + replyToMessageHeight}px)`
            : `calc(100vh - ${250 + typingAreaHeight + editMessageHeight + replyToMessageHeight}px)`
    }

    useEffect(() => {
        if (replyToMessage) {
            const replyElement = document.getElementById('reply-to-message')
            if (replyElement) {
                setReplyToMessageHeight(replyElement.offsetHeight)
            }
        } else {
            setReplyToMessageHeight(0)
        }
    }, [replyToMessage])

    useEffect(() => {
        if (isEditingMessage) {
            const editElement = document.getElementById('edit-message')
            if (isEditingMessage) {
                setEditMessageHeight(editElement.offsetHeight)
            }
        } else {
            setEditMessageHeight(0)
        }
    }, [isEditingMessage])

    useEffect(() => {
        const scrollableDiv = scrollableDivRef.current

        const handleScroll = () => {
            if (scrollableDiv) {
                const isAtBottom = scrollableDiv.scrollTop === 0
                setShowScrollToBottom(!isAtBottom)
            }
        }

        scrollableDiv?.addEventListener('scroll', handleScroll)

        return () => {
            scrollableDiv?.removeEventListener('scroll', handleScroll)
        }
    }, [scrollableDivRef.current])

    useEffect(() => {
        const scrollableDiv = scrollableDivRef.current

        if (scrollableDiv) {
            scrollableDiv.scrollTop = scrollableDiv.scrollHeight
        }
    }, [chat, scrollableDivRef])

    const displayedMessages = showPinnedMessages ? messages.filter(message => message.pinned) : messages

    const renderMessagesWithDates = () => {
        let lastDate = null

        return displayedMessages?.map((message, index) => {
            const messageDate = new Date(message.createdAt).toDateString()
            const showDate = messageDate !== lastDate

            lastDate = messageDate

            const isSender = authData?.id === message.author?._id
            const isLast = index === displayedMessages?.length - 1

            return (
                <div key={message._id} id={message._id}>
                    {showDate && (
                        <div className="w-full text-[12px] text-center lg:text-sm py-2">
                            {formatDateInArmenian(message.createdAt)}
                        </div>
                    )}
                    <Message
                        {...message}
                        messageObj={message}
                        isSender={isSender}
                        setReplyToMessage={setReplyToMessage}
                        setReplyingTo={setReplyingTo}
                        pinUnpinMessage={handlePinUnpinMessage}
                        handleReplyToMessage={handleReplyToMessage}
                        handleDeleteMessage={handleDeleteMessage}
                        isLast={isLast}
                        scrollableDivRef={scrollableDivRef}
                        showPinnedMessages={showPinnedMessages}
                        chatType={chatType}
                        userCanSendMessage={userCanSendMessage}
                        openMedia={openMedia}
                        setOpenMedia={setOpenMedia}
                        setShowPinnedMessages={setShowPinnedMessages}
                        setPinnedMessages={setPinnedMessages}
                        pinnedMessagesCount={pinnedMessagesCount}
                        chats={chats}
                        chat={chat}
                        setEditMessage={setEditMessage}
                        setIsEditingMessage={setIsEditingMessage}
                        setOpenMenuMessageId={setOpenMenuMessageId}
                        openMenuMessageId={openMenuMessageId}
                        setShowMenu={setShowMenu}
                        handleGoToMessage={handleGoToMessage}
                        handleSelectMedia={handleSelectMedia}
                        setIsMediaOpen={setIsMediaOpen}
                        isMediaOpen={isMediaOpen}
                    />
                </div>
            )
        })
    }

    return (
        <div className={`${openChatMediaViewer ? 'flex flex-row-reverse' : ''}`}>
            {openChatMediaViewer && (
                <ChatMediaViewer
                    setOpenChatMediaViewer={setOpenChatMediaViewer}
                    name={chatName}
                    image={chatImage}
                />
            )}

            <div className="relative w-full overflow-y-auto bg-black bg-opacity-5"
                 style={{height: calculateHeight()}}>
                {showPinnedMessages && (
                    <div
                        onClick={() => setShowPinnedMessages(null)}
                        className="absolute z-[99999] flex items-center gap-2.5 lg:gap-[30px] pt-2.5 lg:pt-5 pl-5 cursor-pointer"
                    >
                        <ReturnLeftIcon color="#4C4C4C" width={isPhone && 17} height={isPhone && 17}/>
                        <p className="text text-black text-opacity-70 text-[14px] lg:text-[16px]">Վերադառնալ
                            նամակներին</p>
                    </div>
                )}
                <div>
                    {displayedMessages?.length === 0
                        ? <div className='w-full flex items-center justify-center font-medium text-lg'>Նամակներ դեռ
                            չկան</div>
                        : <div className="relative flex flex-col justify-end" id='chat'
                               style={{height: calculateHeight()}}>
                            <div
                                id={'scrollableDiv'}
                                ref={scrollableDivRef}
                                style={{
                                    overflowY: "auto",
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                }}
                            >
                                <InfiniteScroll
                                    onScroll={handleScroll}
                                    dataLength={displayedMessages?.length || 0}
                                    next={handleLoadEarlier}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                    inverse={true}
                                    hasMore={mayFetchEarlierMessages}
                                    loader={''}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {renderMessagesWithDates()}
                                </InfiniteScroll>

                                {showScrollToBottom && !isMediaOpen &&
                                    <button
                                        className="fixed right-[19%] z-[9999] mb-2.5 w-5 h-5 flex items-center justify-center bg-white text-black rounded-full shadow-md"
                                        onClick={handleScrollToBottom}
                                    >
                                        <ArrowIcon/>
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};


export default ChatBody;