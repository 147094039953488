import React, {useEffect, useState} from 'react';
import {FillFormRequest, Login} from "./pages";
import {Navigate, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import DefaultHeader from "./components/header/DefaultHeader";
import {Main} from "./pages/main";
import MainHeader from "./components/header/MainHeader"
import {checkAuth} from "./store/actions/auth";
import StudentProfileHeader from "./components/header/StudentProfileHeader";
import {useMediaQuery} from "react-responsive";
import CheckinHeader from "./components/header/CheckinHeader";

const App = () => {
    const {authData, loading} = useSelector(state => state.auth)
    const [isPublicPage, setIsPublicPage] = useState(false)
    const [path, setPath] = useState(null)

    const dispatch = useDispatch()

    const isPhone = useMediaQuery({maxWidth: 640})

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (location.pathname.includes('/forms/view/')) {
            setIsPublicPage(true)
        } else {
            setIsPublicPage(false)
        }

        window.scrollTo(0, 0)
    }, [location])

    useEffect(() => {
        setPath(`${location.pathname}${location.search}`)
        dispatch(checkAuth())
    }, [])

    useEffect(() => {
        if (authData && authData !== 'unauthorized') {
            navigate(path)
        }
    }, [authData])

    if (loading && !authData) {
        return (
            <div className={'flex items-center justify-center w-screen h-screen fixed z-[999999] backdrop-blur-lg'}>
                Loading...
            </div>
        )
    }

    return (
        <>
            {(authData && authData !== 'unauthorized')
                ?
                isPublicPage
                    ?
                    <DefaultHeader/>
                    :
                    authData.role === 'student'
                        ?
                        <StudentProfileHeader/>
                        :
                        authData.role === 'checkin'
                            ?
                            <CheckinHeader/>
                            :
                            <MainHeader/>
                :
                <DefaultHeader/>
            }

            <div className={`pt-10 lg:pt-20`}>
                <Routes>
                    {(authData && authData !== 'unauthorized')
                        ?
                        <>
                            <Route path='/*' element={<Main/>}/>
                        </>
                        :
                        authData === 'unauthorized'
                        &&
                        <>
                            <Route path='/' element={<Login/>}/>
                            <Route path="*" element={<Navigate to="/" replace={true}/>}/>
                        </>
                    }

                    <Route path='/forms/view/:id' element={<FillFormRequest/>}/>
                </Routes>
            </div>
        </>
    );
};

export default App;
