import {
    CHANGE_REQUEST_FORM_ACCESS, CREATE_FORM, DELETE_REQUEST_FORM,
    GET_FORM_NAMES, GET_FORM_REQUIREMENTS,
    GET_REQUEST_FORM, GET_REQUEST_FORMS, SAVE_REQUEST_FORM,
    SET_LOADING, SUBMIT_FORM
} from '../constants/actionTypes'
import * as formsApi from '../../api/forms'

export const createForm = (type) => async (dispatch) => {
    try {
        dispatch({ type: CREATE_FORM, payload: {type}})
    } catch (error) {
        console.log(error.message);
    }
}
export const saveRequestForm = (newForm) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await formsApi.saveRequestForm(newForm)

        dispatch({ type: SAVE_REQUEST_FORM, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getForms = (filters) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await formsApi.getForms(filters)

        dispatch({ type: GET_REQUEST_FORMS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getFormNames = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await formsApi.getFormNames()

        dispatch({ type: GET_FORM_NAMES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getRequestForm = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await formsApi.getRequestForm(id)

        dispatch({ type: GET_REQUEST_FORM, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
        dispatch({ type: SET_LOADING })
    }
}
export const changeRequestFormStatus = (id, status) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await formsApi.changeRequestFormStatus(id, status)

        dispatch({ type: CHANGE_REQUEST_FORM_ACCESS, payload: data })
        dispatch({ type: SET_LOADING })

    } catch (error) {
        console.log(error.message);
    }
}

export const submitForm = (id, formData) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await formsApi.submitForm(id, formData)

        dispatch({ type: SUBMIT_FORM, payload: data })
        dispatch({ type: SET_LOADING })

    } catch (error) {
        console.log(error.message);
    }
}
export const deleteRequestForm = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await formsApi.deleteRequestForm(id)

        dispatch({ type: DELETE_REQUEST_FORM, payload: data })
        dispatch({ type: SET_LOADING })

    } catch (error) {
        console.log(error.message);
    }
}
export const getFormRequirements = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await formsApi.getFormRequirements(id)

        dispatch({ type: GET_FORM_REQUIREMENTS, payload: data })
        dispatch({ type: SET_LOADING })

    } catch (error) {
        console.log(error.message);
    }
}
