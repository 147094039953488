import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import { SliderControlArrow } from "../../assets/icons";
import { Container } from "../shared";
import 'swiper/swiper-bundle.css';
import {useMediaQuery} from "react-responsive";
import './style/style.css';

const StatementImages = ({ images }) => {
    const swiperRef = useRef(null)

    const isPhone = useMediaQuery({ maxWidth: 640 })

    useEffect(() => {
        if (swiperRef.current && images.length > 0) {
            const middleIndex = Math.floor(images.length / 2)
            swiperRef.current.slideToLoop(middleIndex, 0)
        }
    }, [images])

    return (
        <div>
            {images?.length === 1 &&
                <Container className="flex justify-center">
                    <img src={images[0]} alt="poster"
                         className={'w-[405px] h-[221px] rounded-[10px] object-cover select-none'}/>
                </Container>
            }
            {(images?.length === 2 || images?.length === 3) && !isPhone &&
                <Container className={`flex ${images?.length === 2 ? 'justify-center gap-8' : 'justify-between'}`}>
                    {images?.map((image, index) => <img key={index} src={image} alt="poster"
                                                       className={'w-[405px] h-[221px] rounded-[10px] object-cover select-none'}/>)
                    }
                </Container>
            }
            {images?.length >= 2 && isPhone &&
                <Swiper
                    grabCursor={true}
                    loop={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    spaceBetween={300}
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                        swiper.slideToLoop(1, 0)
                    }}
                    pagination={{el: '.swiper-pagination-slider', clickable: true}}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                        clickable: true,
                    }}
                    modules={[Pagination, Navigation]}
                >
                    {images?.map((image, imgIndex) => (
                        <SwiperSlide
                            key={imgIndex}
                            style={{
                                width: isPhone ? 'auto' : '521px',
                                height: isPhone ? 'auto' : '619px',
                            }}
                        >
                            <img
                                src={image}
                                alt="image"
                                className={`w-full h-full object-cover rounded-[5px] transform transition-transform duration-300 select-none`}
                                style={{
                                    // transform: `scale(${scale})`,
                                    maxWidth: isPhone ? '355px' : '100%',
                                    maxHeight: isPhone ? '160px' : '100%',
                                    objectFit: 'contain',
                                }}
                            />
                        </SwiperSlide>
                    ))}
                    <div className="swiper-pagination-slider flex justify-center gap-5 mt-5"></div>
                </Swiper>
            }
            {images?.length === 4 &&
                <Container className="">
                    <div className="relative mt-5">
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            loop={true}
                            centeredSlides={true}
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 150,
                                modifier: 1.5,
                                slideShadows: false,
                            }}
                            onSwiper={(swiper) => {
                                swiperRef.current = swiper;
                                swiper.slideToLoop(1, 0)
                            }}
                            pagination={{ el: '.swiper-pagination-slider', clickable: true }}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                                clickable: true,
                            }}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                        >
                            {images?.map((image, imgIndex) => (
                                <SwiperSlide key={imgIndex} style={{ width: '405px', height: '221px' }}>
                                    <div className='relative'>
                                        <img
                                            src={image}
                                            alt='poster'
                                            className='w-full h-full object-cover rounded-[10px] select-none'
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="slider-controller-images">
                            <div
                                className={`swiper-button-prev slider-arrow rotate-180 bg-black bg-opacity-70 rounded-full absolute left-0 top-1/2 transform -translate-y-1/2`}>
                                <SliderControlArrow />
                            </div>
                            <div
                                className={`swiper-button-next slider-arrow bg-black bg-opacity-70 rounded-full absolute right-0 top-1/2 transform -translate-y-1/2`}>
                                <SliderControlArrow />
                            </div>
                        </div>
                    </div>
                    <div className="swiper-pagination-slider flex justify-center gap-5 mt-5"></div>
                </Container>
            }
            {images?.length > 4 &&
                <Container>
                    <div className='relative flex justify-center mt-5'>
                        <Swiper
                            effect={'coverflow'}
                            grabCursor={true}
                            loop={true}
                            centeredSlides={true}
                            slidesPerView={'auto'}
                            spaceBetween={0}
                            coverflowEffect={{
                                rotate: 0,
                                stretch: 0,
                                depth: 150,
                                modifier: 1.5,
                                slideShadows: false,
                            }}
                            onSwiper={(swiper) => {
                                swiperRef.current = swiper;
                                const middleIndex = Math.floor(images.length / 2);
                                swiper.slideToLoop(middleIndex, 0);
                            }}
                            pagination={{ el: '.swiper-pagination-slider', clickable: true }}
                            navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                                clickable: true,
                            }}
                            modules={[EffectCoverflow, Pagination, Navigation]}
                        >
                            {images?.map((image, imgIndex) => (
                                <SwiperSlide key={imgIndex} style={{ width: '405px', height: '221px' }}>
                                    <div className='relative'>
                                        <img
                                            src={image}
                                            alt='poster'
                                            className='w-full h-full object-cover rounded-[10px] select-none'
                                        />
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className="slider-controller-images">
                            <div
                                className={`swiper-button-prev slider-arrow rotate-180 bg-black bg-opacity-70 rounded-full absolute left-0 top-1/2 transform -translate-y-1/2`}>
                                <SliderControlArrow />
                            </div>
                            <div
                                className={`swiper-button-next slider-arrow bg-black bg-opacity-70 rounded-full absolute right-0 top-1/2 transform -translate-y-1/2`}>
                                <SliderControlArrow />
                            </div>
                        </div>
                    </div>
                    <div className="swiper-pagination-slider flex justify-center gap-5 mt-5"></div>
                </Container>
            }
        </div>
    );
};

export default StatementImages;
