import React from 'react';
import closedFormImg from "../../../assets/images/forms/ClosedFormPageImg.png"
const ClosedForm = () => {
    return (
        <div className={'flex flex-col justify-center items-center gap-5'}>
            <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Շնորհակալություն</h2>
            <div className={'flex items-center gap-4'}>
                <img src={closedFormImg} alt={"innerForm"} className={'w-fit h-fit'}/>
                <p className={'text-[28px]'}>Հայտն արդեն փակ է</p>
            </div>
        </div>
    );
};

export default ClosedForm;