import {
    GET_COMMUNITY_NAMES,
    GET_MEMBER_ROLES,
    GET_MEMBERS_NAMES_LIST,
    GET_PROGRAM_NAMES,
    GET_REGIONS, GET_ROUTE_NAMES,
    GET_SCHEDULE_CODES, GET_STATEMENT_PROPS,
    GET_TRANSPORT_ROUTE_FILTERS,
    GET_TRANSPORT_STOP_NAMES,
    GET_VEHICLE_FILTERS, GET_VEHICLE_MARKS,
    SET_LOADING
} from "../constants/actionTypes";
import * as api from "../../api/common";

export const getCommunityNames = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getCommunityNames()

        dispatch({ type: GET_COMMUNITY_NAMES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getProgramNames = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getProgramNames()

        dispatch({ type: GET_PROGRAM_NAMES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message)
    }
}

export const getMemberRoles = () => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await api.getMemberRoles()

        if (data.success) {
            dispatch({type: GET_MEMBER_ROLES, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        // alert(error.response?.data.message)
    }
}

export const getMembersNamesList = (roles) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getMembersNamesList(roles)

        dispatch({ type: GET_MEMBERS_NAMES_LIST, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getTransportStopsNames = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getTransportStopsNames()

        dispatch({ type: GET_TRANSPORT_STOP_NAMES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getRegions = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getRegions()

        dispatch({ type: GET_REGIONS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getTransportRouteFilters = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getTransportRouteFilters()

        dispatch({ type: GET_TRANSPORT_ROUTE_FILTERS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getScheduleCodes = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getScheduleCodes()

        dispatch({ type: GET_SCHEDULE_CODES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getVehicleFilters = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getVehicleFilters()

        dispatch({ type: GET_VEHICLE_FILTERS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getVehicleMarks = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getVehicleMarks()

        dispatch({ type: GET_VEHICLE_MARKS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getRouteNames = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getRouteNames()

        dispatch({ type: GET_ROUTE_NAMES, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const getStatementProps = () => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getStatementProps()

        dispatch({ type: GET_STATEMENT_PROPS, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}