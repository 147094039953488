import React, {useState} from 'react';
import {AsteriskIcon, CloseIcon} from "../../../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../../../shared/buttons";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {removeStudentFromGroup} from "../../../../store/actions/groups";
import {AutoResizableTextarea} from "../../../shared";

const RemoveStudentModal = ({close, studentId, students}) => {
    const [note, setNote] = useState('')


    const student = students?.studentsList.find(student => student.student._id === studentId)

    const {id} = useParams()
    const dispatch = useDispatch()

    const handleChangeNote = (e) => {
        setNote(e.target.value)
    }

    const handleSubmit = () => {
        if (note) {
            close()
            dispatch(removeStudentFromGroup(id, studentId, note))
        }
    }

    return (
        <div className={'fixed z-[99999] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div onClick={close}
                 className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[690px] max-h-[400px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-[28px]'}>
                    <h2 className={'text-center'}>Հեռացնել ուսանող` {student.student.fullName}</h2>

                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'}
                            onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>
                <div className={'flex flex-col px-[30px]'}>
                    <h2 className={'flex gap-[5px] mb-2.5'}>Հեռացման պառճառը <AsteriskIcon/></h2>
                    <AutoResizableTextarea value={note} onChange={handleChangeNote} placeholder={'Նոթագրություն'} autoFocus />
                </div>
                <div className={'flex justify-center py-[50px] gap-[30px]'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                    <ButtonFilled onClick={handleSubmit} text={'Պահպանել'} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    );
};

export default RemoveStudentModal