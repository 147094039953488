import React, {useEffect, useState} from 'react';
import {Container} from "../../../../../components/shared";
import {Link, useNavigate} from "react-router-dom";
import {ReturnLeftIcon} from "../../../../../assets/icons";
import DriversFilter from "../../../../../components/transport/subpages/drivers/DriversFilter";
import {useSelector} from "react-redux";
import {useFilters} from "../../../../../hooks/useFilters";
import {getDrivers} from "../../../../../store/actions/transport";
import AddButton from "../../../../../components/shared/buttons/AddButton";
import StopCard from "../../../../../components/transport/subpages/stops/StopCard";
import Pagination from "../../../../../components/shared/Pagination";
import CreateDriverModal from "../../../../../components/transport/subpages/drivers/modals/CreateDriverModal";
import DriverCard from "../../../../../components/transport/subpages/drivers/DriverCard";
import {roles} from "../../../../../common/roles";

const initialFilters = {
    page: 1,
    size: 9
}

const Drivers = () => {
    const {drivers, loading} = useSelector(state => state.transport)
    const {authData} = useSelector(state => state.auth)

    const [createDriverModal, setCreateDriverModal] = useState(null)
    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        changePage,
        submitFilters,
        setFilters
    } = useFilters(initialFilters, getDrivers, 'transport/drivers')

    const navigate = useNavigate()

    useEffect(() => {
        document.body.style.overflow = createDriverModal ? 'hidden' : 'auto'
    }, [createDriverModal])

    return (
        <div>
            {createDriverModal &&
                <CreateDriverModal
                    close={() => setCreateDriverModal(null)}
                />
            }

            <Container>
                <Link to={'/transport'} className={'flex items-center gap-[30px] group w-fit cursor-pointer fill-icon'}>
                    <ReturnLeftIcon/>
                    <p className={'transition group-hover:text-[#FCC10F]'}>Տրանսպորտ</p>
                </Link>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Վարորդներ</h2>
            </Container>
            <DriversFilter
                filters={filters}
                setFilters={setFilters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
            />

            <Container>
                {roles[authData?.role]?.some(role => role.allowAllActionsInTransport) &&
                    <AddButton
                        text={'Նոր վարորդ'}
                        onClick={() => setCreateDriverModal(true)}
                        className={'hover:bg-white transition border border-[#FCC10F] hover:text-[#FCC10F]'}
                    />
                }
                {loading
                    ?
                    <p className={'text-center'}>Բեռնվում է․․․</p>
                    :
                    drivers?.data?.length > 0
                        ?
                        <>
                            <div className={'grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-[30px] mt-[30px]'}>
                                {drivers?.data?.map((driver, index) =>
                                    <DriverCard
                                        {...driver}
                                        key={index}
                                    />)}
                            </div>
                            <Pagination count={drivers?.pagesCount} page={filters?.page || 1} onChange={changePage}/>
                        </>
                        :
                        <p className={'text-center'}>Վարորդներ չկան</p>
                }
            </Container>
        </div>
    );
};

export default Drivers;