import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {checkGroupDetails} from "../../../../../utils/groups/checkGroupDetails";
import {createRoute, editRoute, selectRoute} from "../../../../../store/actions/transport";
import {getRegions, getTransportStopsNames} from "../../../../../store/actions/common";
import {Container} from "../../../../../components/shared";
import {AsteriskIcon} from "../../../../../assets/icons";
import DropdownInputCheckbox from "../../../../../components/shared/dropdowns/DropdownInputCheckbox";
import {ButtonFilled, ButtonOutlined} from "../../../../../components/shared/buttons";

const EditRoute = () => {
    const { stopNames, regions } = useSelector(state => state.common);
    const { route } = useSelector(state => state.transport);

    const [data, setData] = useState(route || {});
    const [validationErrors, setValidationErrors] = useState({});
    const [nameBeforeEdit, setNameBeforeEdit] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const initialStopState = (index) => (data.transportStops && data.transportStops[index]) ? data.transportStops[index].transportStop : '';
    const initialDurationState = (index) => (data.transportStops && data.transportStops[index]) ? data.transportStops[index].durationToEndPoint || '' : '';

    const [selectedStop1, setSelectedStop1] = useState(initialStopState(0));
    const [selectedStop2, setSelectedStop2] = useState(initialStopState(1));
    const [selectedStop3, setSelectedStop3] = useState(initialStopState(2));
    const [selectedStop4, setSelectedStop4] = useState(initialStopState(3));

    const [selectedDuration1, setSelectedDuration1] = useState(initialDurationState(0));
    const [selectedDuration2, setSelectedDuration2] = useState(initialDurationState(1));
    const [selectedDuration3, setSelectedDuration3] = useState(initialDurationState(2));
    const [selectedDuration4, setSelectedDuration4] = useState(initialDurationState(3));

    const handleStartChangeName = () => {
        setNameBeforeEdit(data.name);
    };

    const handleChangeName = (e) => {
        setData({ ...data, name: e.target.value });
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (data.name) {
                setNameBeforeEdit('');
            } else {
                setData({ ...data, name: nameBeforeEdit });
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const numericFields = ['durationToEndPoint', 'distance', 'routeDuration'];
        const isNumeric = numericFields.includes(name);

        setData(prevData => ({
            ...prevData,
            [name]: isNumeric ? parseFloat(value) : value
        }));

        switch (name) {
            case 'stopDurationToEndPoint1':
                setSelectedDuration1(value);
                break;
            case 'stopDurationToEndPoint2':
                setSelectedDuration2(value);
                break;
            case 'stopDurationToEndPoint3':
                setSelectedDuration3(value);
                break;
            case 'stopDurationToEndPoint4':
                setSelectedDuration4(value);
                break;
            default:
                break;
        }
    };

    const handleSelectOption = (field, selectedOption) => {
        switch (field) {
            case 'stop1':
                setSelectedStop1(selectedOption);
                break;
            case 'stop2':
                setSelectedStop2(selectedOption);
                break;
            case 'stop3':
                setSelectedStop3(selectedOption);
                break;
            case 'stop4':
                setSelectedStop4(selectedOption);
                break;
            default:
                break;
        }
        setData(prevData => ({
            ...prevData,
            [field]: selectedOption
        }));
    };

    const handleDiscard = () => {
        navigate('/transport/routes?page=1&size=9&');
    };

    const handleSubmit = () => {
        const payload = { ...data };

        payload.routeStart = payload.routeStart?._id;
        payload.routeEnd = payload.routeEnd?._id;
        payload.region = payload.region?._id;
        payload.stop1 = selectedStop1?._id;
        payload.stop2 = selectedStop2?._id;
        payload.stop3 = selectedStop3?._id;
        payload.stop4 = selectedStop4?._id;
        payload.stopDurationToEndPoint1 = parseInt(selectedDuration1);
        payload.stopDurationToEndPoint2 = parseInt(selectedDuration2);
        payload.stopDurationToEndPoint3 = parseInt(selectedDuration3);
        payload.stopDurationToEndPoint4 = parseInt(selectedDuration4);

        const errors = checkGroupDetails(payload, data);

        if (Object.keys(errors).length === 0) {
            dispatch(editRoute(route._id, payload));
            navigate('/transport/routes?page=1&size=9&');
        } else {
            setValidationErrors(errors);
        }
    };

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ""
        }));
    };

    useEffect(() => {
        dispatch(getTransportStopsNames());
        dispatch(getRegions());
    }, [dispatch]);

    return (
        <div>
            <Container>
                <div className={'flex justify-center mb-[50px]'}>
                    {nameBeforeEdit ?
                        <input
                            name="name"
                            className={'w-full text-center text-[50px] text-[#FCC10F] font-bold outline-none'}
                            autoComplete={'off'}
                            autoFocus
                            onFocus={e => e.target.select()}
                            value={data.name}
                            onChange={handleChangeName}
                            onKeyDown={handleEnter}
                        />
                        :
                        <h2 onClick={handleStartChangeName}
                            className={'text-center text-[50px] text-[#FCC10F] font-bold break-all'}>{data?.name}</h2>
                    }
                </div>
                <div className={'grid lg:grid-cols-2 grid-cols-1 gap-[30px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Տարածաշրջան <AsteriskIcon/></h2>
                            <DropdownInputCheckbox data={regions} name={'region'}
                                                   onChange={handleSelectOption}
                                                   previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                                   optionClassName={'px-[30px]'}
                                                   hideCheckbox
                                                   selected={[data?.region]}
                                                   disableInput
                                                   onFocus={() => handleFocus('region')}
                            />
                            {validationErrors.region &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.region}</p>}
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Երթուղու սկիզբ <AsteriskIcon/></h2>
                            <DropdownInputCheckbox data={stopNames} name={'routeStart'}
                                                   onChange={handleSelectOption}
                                                   previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                                   optionClassName={'px-[30px]'}
                                                   selected={[data?.routeStart]}
                                                   hideCheckbox
                                                   disableInput
                                                   onFocus={() => handleFocus('routeStart')}
                            />
                            {validationErrors.routeStart &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.routeStart}</p>}
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Վերջնակետից հեռավորությունը (րոպե) <AsteriskIcon/>
                            </h2>
                            <input
                                type={"number"}
                                value={data?.durationToEndPoint}
                                name={'durationToEndPoint'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('durationToEndPoint')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.durationToEndPoint &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.durationToEndPoint}</p>}
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կանգառ 1 </h2>
                            <DropdownInputCheckbox
                                data={stopNames}
                                name={'stop1'}
                                onChange={handleSelectOption}
                                previewClassName={'rounded-[5px] py-[7px] px-[30px] h-10'}
                                optionClassName={'px-[30px]'}
                                selected={[selectedStop1]}
                                hideCheckbox
                                disableInput
                                onFocus={() => handleFocus('stop1')}
                            />
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կանգառ 1-ի հեռավորությունը վերջնակետից
                                հեռավորությունը (րոպե)</h2>
                            <input
                                type={"number"}
                                value={selectedDuration1}
                                name={'stopDurationToEndPoint1'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('stopDurationToEndPoint1')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կանգառ 2 </h2>
                            <DropdownInputCheckbox
                                data={stopNames}
                                name={'stop2'}
                                onChange={handleSelectOption}
                                previewClassName={'rounded-[5px] py-[7px] px-[30px] h-10'}
                                optionClassName={'px-[30px]'}
                                selected={[selectedStop2]}
                                hideCheckbox
                                disableInput
                                onFocus={() => handleFocus('stop2')}
                            />
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կանգառ 2-ի հեռավորությունը վերջնակետից
                                հեռավորությունը (րոպե)</h2>
                            <input
                                type={"number"}
                                value={selectedDuration2}
                                name={'stopDurationToEndPoint2'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('stopDurationToEndPoint2')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                        </div>
                    </div>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կանգառ 3 </h2>
                            <DropdownInputCheckbox
                                data={stopNames}
                                name={'stop3'}
                                onChange={handleSelectOption}
                                previewClassName={'rounded-[5px] py-[7px] px-[30px] h-10'}
                                optionClassName={'px-[30px]'}
                                selected={[selectedStop3]}
                                hideCheckbox
                                disableInput
                                onFocus={() => handleFocus('stop3')}
                            />
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կանգառ 3-ի հեռավորությունը վերջնակետից
                                հեռավորությունը (րոպե)</h2>
                            <input
                                type={"number"}
                                value={selectedDuration3}
                                name={'stopDurationToEndPoint3'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('stopDurationToEndPoint3')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կանգառ 4 </h2>
                            <DropdownInputCheckbox
                                data={stopNames}
                                name={'stop4'}
                                onChange={handleSelectOption}
                                previewClassName={'rounded-[5px] py-[7px] px-[30px] h-10'}
                                optionClassName={'px-[30px]'}
                                selected={[selectedStop4]}
                                hideCheckbox
                                disableInput
                                onFocus={() => handleFocus('stop4')}
                            />
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կանգառ 4-ի հեռավորությունը վերջնակետից
                                հեռավորությունը (րոպե)</h2>
                            <input
                                type={"number"}
                                value={selectedDuration4}
                                name={'stopDurationToEndPoint4'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('stopDurationToEndPoint4')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Երթուղու ավարտ <AsteriskIcon/></h2>
                            <DropdownInputCheckbox data={stopNames} name={'routeEnd'}
                                                   onChange={handleSelectOption}
                                                   previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                                   optionClassName={'px-[30px]'}
                                                   selected={[data?.routeEnd]}
                                                   hideCheckbox
                                                   disableInput
                                                   onFocus={() => handleFocus('routeEnd')}
                            />
                            {validationErrors.routeEnd &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.routeEnd}</p>}
                        </div>

                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>Երթուղու երկարություն (կմ) <AsteriskIcon/></h2>
                            <input
                                type={"number"}
                                value={data?.distance}
                                name={'distance'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('distance')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.distance &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.distance}</p>}
                        </div>

                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>Երթուղու տևողություն (րոպե) <AsteriskIcon/></h2>
                            <input
                                type={"number"}
                                value={data?.routeDuration}
                                name={'routeDuration'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('routeDuration')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.routeDuration &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.routeDuration}</p>}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'flex items-center justify-end'}>
                        <div>
                            <div className={'w-fit flex gap-[30px] items-center mt-[100px]'}>
                                <ButtonOutlined
                                    onClick={handleDiscard}
                                    text={'Չեղարկել'}
                                    className={'w-[176px]'}
                                />
                                <ButtonFilled
                                    onClick={handleSubmit}
                                    text={'Պահպանել'}
                                    className={'w-[176px]'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default EditRoute;