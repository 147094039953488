import {useEffect} from "react"
import {decryptMessage, encryptMessage} from "../../socket/encryption";
import {useDispatch} from "react-redux";
import {
    openChat,
    receiveInChatMessage,
    setChats, setReactions,
    userDeletedMessage, userHasSeenMessage,
    userPinnedUnpinnedMessage
} from "../../store/actions/chats";
import {useSelector} from 'react-redux'

export const useChatEvents = (socket) => {
    const {authData} = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const cb = async (response) => {
        response = await decryptMessage(response)

        dispatch(setChats(response.chats))
    }

    const updateChatsList = async () => {
        if (socket) {
            const obj = {
                type: 'get_chats',
            }
            const encryptedMessage = await encryptMessage(obj)
            socket.emit('msg', encryptedMessage, cb)
        }
    }

    useEffect(() => {
        if (socket) {
            socket.on('msg', async payload => {
                payload = await decryptMessage(payload)

                switch (payload.type) {
                    case "user_pinned_chat":
                        dispatch(setChats(payload.data.chats))
                        break
                    case "user_get_chat": // for cleaning new messages of the chat in chats list
                        updateChatsList()
                        break
                    case "chat_message": // for cleaning new messages of the chat in chats list
                        const {message, chatId, messageMinimized} = payload.data

                        dispatch(receiveInChatMessage(message, chatId, messageMinimized, authData.id))

                        updateChatsList()
                        break
                    case "user_deleted_message": // for cleaning new messages of the chat in chats list
                        dispatch(userDeletedMessage(payload.data))
                        updateChatsList()
                        break
                    case "user_pinned_unpinned_message":
                        dispatch(userPinnedUnpinnedMessage(payload.data))
                        updateChatsList()
                        break
                    case "user_has_been_added_to_chat":
                        updateChatsList()
                        break
                    case "user_has_seen_message":
                        dispatch(userHasSeenMessage(payload.data))
                        break
                    case "chat_has_been_deleted":
                        updateChatsList()
                        break
                    case "chat_status_changed":
                        console.log(payload.data)
                        break
                    case "user_has_been_deleted_from_chat":
                        console.log(payload.data)
                        break
                    case "user_status_change_in_chat":
                        console.log(payload.data)
                        break
                    case "notification":
                        console.log(payload.data)
                        break
                    case "user_has_read_notification":
                        console.log(payload.data)
                        break
                    case "user_unpinned_all_messages":
                        console.log(payload.data)
                        break
                    case "user_reacted_to_message":
                        dispatch(setReactions(payload.data))
                        break
                    case "prepare_chat":
                        console.log(payload.data)
                        break
                    case "voice_message":
                        console.log(payload.data)
                        break
                    default:
                        break
                }
            })
        }
    }, [socket])
}