import React from 'react';
import {Link} from "react-router-dom";
import {SettingsIcon} from "../../../assets/icons";
import {roles} from "../../../common/roles";
import {useSelector} from "react-redux";

const EventHeader = ({image, name}) => {
    const {authData} = useSelector(state => state.auth)

    return (
        <div className={`flex items-center justify-between pt-3 px-1`}>
            <div><img src={image} alt={'groupImage'} className={'max-w-[50px] max-h-[50px]'}/></div>
            <h2 className={`${name?.length > 30 ? 'w-full text-[28px]' : 'max-w-[800px] text-[50px]'} text-[#FCC10F] font-bold text-center`}>{name}</h2>
            {roles[authData?.role]?.some(role => role.allowAddStudentInGroup)
                ?
                <Link to={'settings'}><SettingsIcon/></Link>
                :
                <div></div>
            }
        </div>
    );
};

export default EventHeader;