import React from 'react';
import {CalendarIcon, CheckInIcon} from "../../assets/icons";
import moment from "moment";
import checkinsHistory from "./CheckinsHistory";

const CheckinHistoryItem = ({student, group, checkin, checkout}) => {
    const formattedCheckinDate = moment(checkin).format('DD.MM.YYYY')
    const formattedCheckinTime = moment(checkin).format('HH:mm')
    const formattedCheckoutTime = moment(checkout).format('HH:mm')

    return (
        <div className={'flex justify-between w-full max-h-[50px] custom-shadow rounded-[10px] py-[15px] px-[30px]'}>
            <div className={'flex items-center gap-[49px]'}>
                <div className={'max-w-[30px] max-h-[30px]'}>
                    <img src={student?.profileImage} alt={'profile-image'}
                         className={'w-[30px] h-[30px] rounded-full object-cover'}/>
                </div>
                <h2 className={'text-[#FCC10F]'}>{student?.fullName}</h2>
            </div>

            <div className={'grid grid-cols-5 gap-[79px] max-w-[600px] justify-between items-center'}>
                <div className={'col-span-2'}>
                    {group?.name
                        ?
                        <p className={'w-[250px] line-clamp-1'} title={group?.name}>{group?.name}</p>
                        :
                        <div className={'w-[108px] border-b-[3px] border-[#FF0707]'}></div>
                    }
                </div>
                <div className={'flex items-center gap-2.5'}>
                    <CalendarIcon/>
                    <p>{formattedCheckinDate}</p>
                </div>
                {checkin
                    ?
                    <div className={'flex items-center gap-2.5'}>
                        <CheckInIcon/>
                        <p>{formattedCheckinTime}</p>
                    </div>
                    :
                    <div className={'w-[100px]'}></div>
                }
                {checkout
                    ?
                    <div className={'flex items-center gap-2.5'}>
                        <div className={'rotate-180'}>
                            <CheckInIcon color={'#FF0707'}/>
                        </div>
                        <p>{formattedCheckoutTime}</p>
                    </div>
                    :
                    <div className={'w-[67px]'}></div>
                }
            </div>
        </div>
    );
};

export default CheckinHistoryItem;
