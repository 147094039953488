import React, {useEffect, useRef, useState} from 'react';
import {DropdownArrow, ReturnLeftIcon, SettingsIcon} from "../../../../../../assets/icons";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Container} from "../../../../../../components/shared";
import {parseQueryParams} from "../../../../../../utils/convertQueryFilters";
import {useDispatch, useSelector} from "react-redux";
import {useOutsideClick} from "../../../../../../hooks/useOutsideClick";
import Files from "../../../../students/Files";
import DriverProfile from "./DriverProfile";
import {getDriver} from "../../../../../../store/actions/transport";
import DriverEdit from "./DriverEdit";
import RoutesHistory from "./routesHistory/RoutesHistory";
import {roles} from "../../../../../../common/roles";

const subpagesTitles = {
    profile: 'Վարորդի տվյալներ',
    routesHistory: 'Երթերի պատմություն',
    files: 'Փաստաթղթեր'
}

const Driver = () => {
    const {authData} = useSelector(state => state.auth)
    const {driver} = useSelector(state => state.transport)

    const [showSubpages, setShowSubpages] = useState(false)
    const [subpage, setSubpage] = useState('')
    const [editMode, setEditMode] = useState(false)

    const {id} = useParams()
    const navigate = useNavigate()
    const location = useLocation()

    const dispatch = useDispatch()

    const optionsRef = useRef(null)

    useOutsideClick(optionsRef, () => setShowSubpages(false))

    const handleClick = (type) => {
        setShowSubpages(false)

        const {mode} = parseQueryParams(window.location)

        navigate(`?mode=${mode}&subpage=${type}`)
    }

    const enterEditMode = () => {
        roles[authData?.role]?.some(role => role.allowAllActionsInTransport) ? setEditMode(true) : setEditMode(false)
    }

    const handleDriverUpdated = () => {
        dispatch(getDriver(id))
    }

    useEffect(() => {
        const {subpage} = parseQueryParams(window.location)
        setSubpage(subpage)
    }, [location])

    useEffect(() => {
        const parsedParams = parseQueryParams(window.location.href)
        const {mode, subpage} = parsedParams

        const modeEnums = ['view', 'edit']
        const subpageEnums = ['profile', 'routesHistory', 'files']

        if (!mode || !subpage || !modeEnums.includes(mode) || !subpageEnums.includes(subpage)) {
            navigate('?mode=view&subpage=profile')
        } else {
            if (!mode || !subpage) {
                navigate('?mode=view&subpage=profile')
            } else {
                if (mode === 'view') {
                    setEditMode(false)
                } else if (mode === 'edit') {
                    roles[authData?.role]?.some(role => role.allowAllActionsInTransport) ? enterEditMode() : navigate('?mode=view&subpage=profile')
                } else {
                    navigate('?mode=view&subpage=profile')
                }
            }
        }
    }, [window.location.href])

    useEffect(() => {
        dispatch(getDriver(id))
    }, [])

    const handleSettingsClick = () => {
        enterEditMode();
        navigate(`?mode=edit&subpage=${subpage}`);
    };

    return (
        <div>
            <Container>
                {!editMode &&
                    <>
                        <div className={'relative flex items-center justify-between'}>
                            <div>
                                <p
                                    onClick={() => navigate('/transport/drivers?page=1&size=9')}
                                    className={'group w-fit flex items-center gap-[30px] cursor-pointer fill-icon'}>
                                    <ReturnLeftIcon/>
                                    <span
                                        className={'transition group-hover:text-[#FCC10F]'}>Վերադառնալ վարորդների ցանկ</span>
                                </p>
                            </div>
                            {roles[authData?.role]?.some(role => role.allowAllActionsInTransport) &&
                                <div
                                    className={`flex items-center gap-3 cursor-pointer select-none ${showSubpages ? 'pointer-events-none' : 'pointer-events-auto'}`}
                                    onClick={() => setShowSubpages(!showSubpages)}>
                                    <p>{subpagesTitles[subpage]}</p>
                                    <div className={`${showSubpages && 'rotate-180'} transition`}><DropdownArrow
                                        width={20}
                                        height={20}/>
                                    </div>
                                </div>
                            }

                            {showSubpages && (
                                <div ref={optionsRef}
                                     className={`absolute z-50 w-[162px] flex flex-col bg-white gap-3 right-0 top-full py-[10px] mt-[6px] border border-black rounded-[5px] `}>
                                    {
                                        <>
                                            {Object.entries(subpagesTitles).map(([key, name]) => key !== subpage && (
                                                    <p
                                                        key={key}
                                                        onClick={() => handleClick(key)}
                                                        className={'pl-[10px] pr-[17px] mt-1 transition hover:bg-gray-200 py-1 cursor-pointer select-none'}>
                                                        {name}
                                                    </p>
                                                )
                                            )}
                                        </>
                                    }
                                </div>
                            )}
                        </div>

                        <div
                            className={'flex items-center justify-center gap-[30px] text-[28px] text-[#FCC10F] text-center font-medium'}>
                            <div className={'w-full flex items-center justify-between'}>
                                <div></div>
                                <div className={'font-medium'}>{driver?.fullName}</div>
                                <div onClick={handleSettingsClick} className={'cursor-pointer'}><SettingsIcon/></div>
                            </div>
                        </div>
                    </>
                }
            </Container>
            {
                (driver && subpage === 'routesHistory' && <RoutesHistory/>)
                ||
                (driver && subpage === 'profile' &&
                    !editMode && <DriverProfile driver={driver}/>)
                ||
                (driver && subpage === 'profile' &&
                    editMode && <DriverEdit driver={driver} onDriverUpdated={handleDriverUpdated}/>)
                ||
                (driver && subpage === 'files' &&
                    <Container>
                        <Files type={'driver'} {...driver}/>
                    </Container>
                )
            }
        </div>
    );
};

export default Driver;
