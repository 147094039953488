import React from 'react';
import {Pagination} from "@mui/material";


const PaginationComponent = ({page, onChange, count, size}) => {
    return count > 1 && (
        <div className={'flex justify-center items-center lg:p-4'}>
            <Pagination count={count || 1} onChange={onChange} page={page} size={size} />
        </div>
    )
};

export default PaginationComponent;