import React, {useEffect, useState} from 'react';
import GroupsFilter from "../../../components/groups/GroupsFilter";
import {useFilters} from "../../../hooks/useFilters";
import {useDispatch, useSelector} from "react-redux";
import {DropdownArrow, PlusIcon, ScrollToTopIcon} from "../../../assets/icons";
import {Link, useLocation, useParams} from "react-router-dom";
import {Container} from "../../../components/shared";
import GroupCard from "../../../components/groups/GroupCard";
import {deleteGroup, getGroups} from "../../../store/actions/groups";
import Pagination from "../../../components/shared/Pagination";
import PageSizeDropdown from "../../../components/shared/dropdowns/PageSizeDropdown";
import {roles} from "../../../common/roles";
import {useMediaQuery} from "react-responsive";
import GroupsFilterMobile from "../../../components/groups/GroupsFilterMobile";

const initialFilters = {
    name: '', page: 1, size: 9
}

const Groups = () => {
    const {groups, loading} = useSelector(state => state.groups)
    const {authData} = useSelector(state => state.auth)

    const [groupToDelete, setGroupToDelete] = useState(null)
    const [showScrollToTop, setShowScrollToTop] = useState(false)

    const {
        filters,
        handleChange,
        changeSelectedFilters,
        removeSelectedFilters,
        discardFilters,
        changePage,
        clearAllFilters,
        changeCustomPage,
        setCustomPage,
        handleChangeSize,
        submitFilters
    } = useFilters(initialFilters, getGroups, 'groups')

    const dispatch = useDispatch()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)

    const isPhone = useMediaQuery({maxWidth: 640})
    const page = parseInt(queryParams.get('page'))
    const size = parseInt(queryParams.get('size'))

    const handleDeleteGroup = () => {
        dispatch(deleteGroup(groupToDelete, `?page=${page}&size=${size}&`))
        setGroupToDelete(null)
    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 670) {
                setShowScrollToTop(true)
            } else {
                setShowScrollToTop(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const content = (
        <div>
            <div>
                {roles[authData?.role]?.some(role => role.allowCreateNewGroup)
                    &&
                    <Link
                        to={'/groups/create'}
                        className={'w-[208px] h-[50px] flex items-center justify-center gap-[30px] text-white rounded-[5px] bg-[#FCC10F] mt-[14px]'}>
                        <PlusIcon/>
                        <span className={'bold'}>Նոր խումբ</span>
                    </Link>
                }
                {loading
                    ?
                    <p className={'text-center my-4'}>Բեռնվում է․․․</p>
                    :
                    groups?.data.length ?
                        <>
                            <div
                                className={'relative grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items-center gap-5 lg:gap-[30px] mt-5 lg:mt-[30px]'}>
                                {groups?.data.map((group, index) => {
                                    const isLast = groups?.data.length === index + 1

                                    return (
                                        <div key={group._id}>
                                            <GroupCard
                                                {...group}
                                                handleDeleteGroup={handleDeleteGroup}
                                                groupToDelete={groupToDelete}
                                                setGroupToDelete={setGroupToDelete}
                                                // isManager={isManager}
                                            />

                                            {isLast && !isPhone &&
                                                <button
                                                    className={'absolute -right-16 bottom-2'}
                                                    onClick={scrollToTop}>
                                                    <ScrollToTopIcon/>
                                                </button>
                                            }
                                        </div>
                                    )
                                })}
                            </div>

                            {authData.role !== 'student' &&
                                <div className={'flex items-center justify-center mt-[40px]'}>
                                    {filters?.page &&
                                        <Pagination count={groups?.pagesCount} onChange={changePage}
                                                    page={parseInt(filters?.page)}/>}
                                    <div className={'flex items-center justify-center gap-2.5'}>
                                        <span>Էջ</span>
                                        <input type="number" onKeyDown={changeCustomPage}
                                               onChange={e => setCustomPage(e.target.value)}
                                               className={'max-w-[50px] outline-none px-2 py-0.5 text-center border border-black rounded-[5px]'}/>
                                    </div>
                                    <div className={'ml-[30px]'}>
                                        <PageSizeDropdown onChange={handleChangeSize} value={filters?.size}/>
                                    </div>
                                </div>
                            }
                        </>
                        :
                        !loading && <p className={'text-center mt-5'}>Խմբեր չկան</p>
                }
            </div>

            {showScrollToTop && isPhone &&
                <button
                    className={'fixed z-[9999] bottom-4 left-[80%] bg-white border border-black border-opacity-70 rounded-full rotate-180 p-[7px]'}
                    onClick={scrollToTop}
                >
                    <DropdownArrow />
                </button>
            }
        </div>
    )

    return (
        <div>
            <div>
                <h2 className={'mt-5 text-center text-[20px] lg:text-[50px] text-[#FCC10F] font-bold'}>Խմբեր</h2>
            </div>

            {isPhone
                ?
                <GroupsFilterMobile
                    filters={filters}
                    discardFilters={discardFilters}
                    submitFilters={submitFilters}
                    handleChange={handleChange}
                    changeSelectedFilters={changeSelectedFilters}
                    removeFilters={removeSelectedFilters}
                    clearAllFilters={clearAllFilters}
                />
                :
                <GroupsFilter
                    filters={filters}
                    discardFilters={discardFilters}
                    submitFilters={submitFilters}
                    handleChange={handleChange}
                    changeSelectedFilters={changeSelectedFilters}
                />
            }
            {isPhone ? content : <Container>{content}</Container>}
        </div>
    )
};

export default Groups;