import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {checkGroupDetails} from "../../../../utils/groups/checkGroupDetails";
import {createVehicle} from "../../../../store/actions/transport";
import {Container} from "../../../../components/shared";
import {AsteriskIcon} from "../../../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../../../../components/shared/buttons";

const initialState = {
    model: 'Մեքենայի մոդելի անունը',
    mark: null,
    plate: '',
    color: '',
    seatingCapacity: '',
}

const CreateVehicle = () => {
    const [data, setData] = useState(initialState)
    const [validationErrors, setValidationErrors] = useState({})
    const [nameBeforeEdit, setNameBeforeEdit] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleStartChangeName = () => {
        setNameBeforeEdit(data.model)
    }

    const handleChangeName = (e) => {
        setData({...data, model: e.target.value})
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (data.model) {
                setNameBeforeEdit('')
            } else {
                setData({...data, model: nameBeforeEdit})
            }
        }
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleDiscard = () => {
        navigate('/vehicles?page=1&size=9&')
    }

    const handleSubmit = () => {
        const payload = {
            ...data,
        }

        // Regular expression for Armenian car plate format
        const plateRegex = /^(?:\d{2}[A-Z]{2}\d{3}|\d{3}[A-Z]{2}\d{2})$/;
        const errors = checkGroupDetails(payload, data);

        if (!plateRegex.test(data.plate)) {
            errors.plate = 'Ձևաչափը սխալ է։ Կարող է լինել օրինակ՝ 00AA000 կամ 000AA00';
        }

        if (Object.keys(errors).length === 0) {
            dispatch(createVehicle(payload, navigate))
        } else {
            setValidationErrors(errors)
        }
    }

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ""
        }))
    }

    return (
        <div>
            <Container>
                <div className={'flex justify-center mb-[50px]'}>
                    {nameBeforeEdit ?
                        <input
                            name="name"
                            className={'w-full text-center text-[50px] text-[#FCC10F] font-bold outline-none'}
                            autoComplete={'off'}
                            autoFocus
                            onFocus={e => e.target.select()}
                            value={data.model}
                            onChange={handleChangeName}
                            onKeyDown={handleEnter}
                        />
                        :
                        <h2 onClick={handleStartChangeName}
                            className={'text-center text-[50px] text-[#FCC10F] font-bold break-all'}>{data.model}</h2>
                    }
                </div>
                <div className={'grid lg:grid-cols-2 grid-cols-1 gap-[30px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Մեքենայի մակնիշ <AsteriskIcon/></h2>
                            <input
                                type={"text"}
                                value={data.mark}
                                name={'mark'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('mark')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.mark &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.mark}</p>}
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Մեքենայի գույն <AsteriskIcon/></h2>
                            <input
                                type={"text"}
                                value={data.color}
                                name={'color'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('color')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.color &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.color}</p>}
                        </div>
                    </div>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>Ուղևորների քանակ <AsteriskIcon/>
                            </h2>
                            <input
                                type={"number"}
                                value={data.seatingCapacity}
                                name={'seatingCapacity'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('seatingCapacity')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.seatingCapacity &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.seatingCapacity}</p>}
                        </div>

                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>Պետհամարանիշ <AsteriskIcon/></h2>
                            <input
                                type={"text"}
                                value={data.plate}
                                name={'plate'}
                                onChange={handleChange}
                                placeholder={'00AA000 կամ 000AA00'}
                                onFocus={() => handleFocus('plate')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.plate &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.plate}</p>}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'flex items-center justify-end'}>
                        <div>
                            <div className={'w-fit flex gap-[30px] items-center mt-[100px]'}>
                                <ButtonOutlined
                                    onClick={handleDiscard}
                                    text={'Չեղարկել'}
                                    className={'w-[176px]'}
                                />
                                <ButtonFilled
                                    onClick={handleSubmit}
                                    text={'Պահպանել'}
                                    className={'w-[176px]'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CreateVehicle;
