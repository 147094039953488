import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyAgSjdmUH39hW5jmNX4Vvp46io1uQS0azE",
    authDomain: "coaf-lms.firebaseapp.com",
    projectId: "coaf-lms",
    storageBucket: "coaf-lms.appspot.com",
    messagingSenderId: "630560875417",
    appId: "1:630560875417:web:645aaf76e65233493ba908",
    measurementId: "G-D0G07FLLQW"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const analytics = getAnalytics(app);
const provider = new GoogleAuthProvider();

export {auth,provider};