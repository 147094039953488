import {CompletedIcon, DroppedIcon, ParticipatedIcon} from "../../../../assets/icons";
import React from "react";
import {useMediaQuery} from "react-responsive";

export const SmartBlock = ({event}) => {
    const isPhone = useMediaQuery({maxWidth: 640})

    return (
        <div className={'flex flex-col gap-2.5 mt-2.5'}>
            {event?.map(item => (
                <div key={item?._id} className={'flex items-center justify-between'}>
                    <div className={'flex flex-col gap-5'}>
                        {item.statusType === 'current'
                            ?
                            <p className={'max-w-[160px] lg:max-w-[210px] text-[14px] lg:text-[16px]'}>{item.name}</p>
                            :
                            <p className={'max-w-[160px] lg:max-w-[210px] text-[14px] lg:text-[16px]'}>{item.group?.name}</p>
                        }

                    </div>
                    <div className={'flex justify-center items-center gap-5'}>
                        {item.status === 'completed' &&
                            <p className={'flex items-center gap-2.5'}>
                                <span className={'text-[14px] text-[#20BA07] lg:text-[16px]'}>Ավարտած</span>
                                <CompletedIcon width={isPhone ? 17 : 20} height={isPhone ? 17 : 20}/>
                            </p>
                        }
                        {item.status === 'participated' &&
                            <p className={'flex items-center gap-2.5'}>
                                <span className={'text-[14px] lg:text-[16px]'}>Մասնակից</span>
                                <ParticipatedIcon width={isPhone ? 17 : 20} height={isPhone ? 17 : 20}/>
                            </p>
                        }
                        {item.status === 'dropped' &&
                            <p className={'flex items-center gap-2.5'}>
                                <span className={'text-[14px] text-[#FF0707] lg:text-[16px]'}>Չավարտած</span>
                                <DroppedIcon width={isPhone ? 17 : 20} height={isPhone ? 17 : 20}/>
                            </p>
                        }
                    </div>
                </div>
            ))}
        </div>
    )
}