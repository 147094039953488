import React, {useEffect, useState} from 'react';
import {PlusIcon, TrashIcon} from "../../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {deleteStudentNote, getStudentNotes} from "../../../store/actions/students";
import NoteItem from "../../../components/students/NoteItem";
import Pagination from "../../../components/shared/Pagination";
import CreateNoteModal from "../../../components/students/modals/CreateNoteModal";
import {ConfirmationModal} from "../../../components/shared";

const Notes = ({_id}) => {
    const {authData: {role}} = useSelector((state) => state.auth);

    const {notes} = useSelector(state => state.students)
    const [page, setPage] = useState(1)
    const [createNoteModal, setCreateNoteModal] = useState(false)
    const [deleteNoteModal, setDeleteNoteModal] = useState(null)
    const [editNote, setEditNote] = useState(null)

    const dispatch = useDispatch()

    const handleChangePage = (_, p) => {
        window.scrollTo({top: 0})
        setPage(p)
    }

    const closeNoteModal = () => {
        setCreateNoteModal(false)
        setEditNote(null)
    }

    const handleEditNote = (note) => {
        setEditNote(note)
    }

    const handleDeleteNote = (id) => {
        setDeleteNoteModal(id)
    }

    const confirmDeleteNote = () => {
        dispatch(deleteStudentNote(deleteNoteModal))
        setDeleteNoteModal(null)
    }

    useEffect(() => {
        dispatch(getStudentNotes(_id, page))
    }, [page]);

    useEffect(() => {
        if (createNoteModal || deleteNoteModal) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [createNoteModal, deleteNoteModal]);

    return (
        <>
            <CreateNoteModal open={createNoteModal || editNote} close={closeNoteModal} studentId={_id}
                             editNote={editNote}/>
            {deleteNoteModal &&
                <ConfirmationModal icon={<TrashIcon width={50} height={50}/>} infoText={'Վստա՞հ եք, որ ցանկանում եք ջնջել այս նոթագրությունը։'}
                                   confirmText={'Այո'} cancelText={'Ոչ'} confirm={confirmDeleteNote} close={() => setDeleteNoteModal(null)}/>}
            <div>
                {(role !== 'instructor') && (
                    <div>
                        <button
                            onClick={() => setCreateNoteModal(true)}
                            className={'w-[208px] h-[50px] flex items-center justify-center gap-[30px] text-white rounded-[5px] bg-[#FCC10F] mt-7'}>
                            <PlusIcon/>
                            <span className={'bold'}>Նոր նոթ</span>
                        </button>
                    </div>
                )}

                <div>
                    {notes?.data?.map(note => <NoteItem key={note._id} note={note} handleEditNote={handleEditNote}
                                                        handleDeleteNote={handleDeleteNote}
                                                        setDeleteNoteModal={setDeleteNoteModal}/>)}
                </div>

                <Pagination count={notes?.pagesCount} page={page} onChange={handleChangePage}/>
            </div>
        </>
    );
};

export default Notes;