import React from 'react';

const DisplayPhoneNumber = ({phoneNumber, className}) => {
    const customPhoneNumber = `${phoneNumber?.substring(0, 4)} ${phoneNumber?.substring(4, 6)} ${phoneNumber?.substring(6, phoneNumber?.length)}`

    return (
        <span className={`break-keep ${className}`}>{customPhoneNumber}</span>
    );

};

export default DisplayPhoneNumber;