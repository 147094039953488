import React, {useEffect, useRef, useState} from 'react';

const ButtonHoverText = ({icon, text, onClick, className}) => {
    const [isNearToBorder, setIsNearToBorder] = useState(true)
    const iconRef = useRef(null)
    const textRef = useRef(null)

    useEffect(() => {
        window.addEventListener('resize', () => {
            const windowWidth = window?.innerWidth

            const iconRect = textRef?.current?.getBoundingClientRect()
            const posX = iconRect?.x

            const textRect = textRef.current?.getBoundingClientRect()
            const textBlockWidth = textRect?.width

            if(windowWidth > posX + textBlockWidth) {
                setIsNearToBorder(false)
            } else {
                setIsNearToBorder(true)
            }
        })
    }, [])

    return (
        <button className={`relative group ${className}`} onClick={onClick}>
            <span ref={iconRef}>{icon}</span>
            <span ref={textRef} className={`absolute ${isNearToBorder ? 'right-0' : 'left-full'} -bottom-7 rounded-sm text-xs text-nowrap opacity-0 bg-white pointer-events-none group-hover:opacity-100 transition`}>{text}</span>
        </button>
    )
}

export default ButtonHoverText