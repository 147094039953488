import React, {useState, useEffect, useRef} from 'react';
import {PlusButton} from "../../shared/buttons";
import Search from "./Search";
import {SearchIcon} from "../../../assets/icons";
import ChatItem from "./ChatItem";
import {useDispatch, useSelector} from "react-redux";
import {searchChats} from "../../../socket/chats";
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {useMediaQuery} from "react-responsive";

const ChatsList = ({loading, onChatSelect}) => {
    const {socket} = useSelector(state => state.socket)
    const {chats, searchedChats} = useSelector(state => state.chats)
    const {authData} = useSelector(state => state.auth)

    const [openSearch, setOpenSearch] = useState(null)
    const [searchValue, setSearchValue] = useState("")
    const [openMobileSearch, setOpenMobileSearch] = useState(null)

    const dispatch = useDispatch()

    const ref = useRef(null)
    const searchRef = useRef(null)

    const isPhone = useMediaQuery({maxWidth: 640})

    useOutsideClick(ref, () => setSearchValue(''))
    useOutsideClick(searchRef, () => setOpenMobileSearch(null))

    useEffect(() => {
        if (searchValue) {
            searchChats({socket, dispatch, searchValue, includeExistingChats: true, includeGroups: authData.role !== 'student'})
        }
    }, [searchValue])

    return (
        <div className={`lg:border lg:border-[#D0CFCF]`}>
            <div className={'relative'}>
                <div
                    className={`relative max-w-[445px] flex flex-wrap items-center h-[64px] lg:h-[94px] justify-between py-[28px] lg:px-[30px] lg:border-b lg:border-[#D0CFCF] ${openMobileSearch && 'opacity-0'}`}>
                    <h2 className={'text-[20px] font-bold lg:font-normal lg:text-[28px] text-[#FCC10F]'}>Նամակագրություն</h2>
                    {!isPhone && authData.role !== 'student' &&
                        <PlusButton
                            onClick={() => setOpenSearch(true)}
                            className={`p-[5.1px]`}
                            width={8}
                            height={8}
                            styleClassName={`hover:bg-white transition border border-[#FCC10F]`}
                            hoverPlusColor={'#FCC10F'}
                        />
                    }
                    {isPhone &&
                        <button className={'cursor-pointer'} onClick={() => setOpenMobileSearch(true)}><SearchIcon
                            isPhone={isPhone}/></button>
                    }
                    {openSearch && <Search setOpenSearch={setOpenSearch} onChatSelect={onChatSelect}
                                           className={'absolute z-[99999] top-[70%] right-[27px] rounded-[5px]'}/>}
                </div>
                {openMobileSearch &&
                    <div
                        ref={searchRef}
                        className={`w-full absolute bottom-0 h-[30px] flex justify-between bg-white border border-black border-opacity-70 rounded-[5px] py-1 px-[30px] ${searchValue && 'rounded-b-[0px]'}`}>
                        <input
                            type={'text'}
                            className={'w-full outline-none'}
                            value={searchValue}
                            autoFocus
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                        <div className={'flex items-center gap-5'}>
                            <button><SearchIcon/></button>
                        </div>
                    </div>
                }
            </div>
            <div className={`relative lg:p-[30px] lg:border-b lg:border-[#D0CFCF] lg:mt-0`}>
                {!isPhone && (
                    <div className="relative">
                        <div
                            className={`h-[30px] flex justify-between bg-white border border-black border-opacity-70 rounded-[5px] py-1 px-[30px] ${searchValue && 'rounded-b-[0px]'}`}
                        >
                            <input
                                type={'text'}
                                className={'w-full pr-[30px] outline-none'}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <div className={'flex items-center gap-5'}>
                                <button><SearchIcon/></button>
                            </div>
                        </div>

                        <div className={'absolute w-full z-[999999] bg-white'}>
                            {searchValue && searchedChats?.length > 0 && (
                                <div
                                    ref={ref}
                                    className="w-full max-h-[330px] flex flex-col gap-5 overflow-y-auto bg-white border border-black border-t-0 rounded-b-[5px] py-5 lg:py-[30px]"
                                >
                                    {searchedChats?.map(chat => (
                                        <div
                                            key={chat._id}
                                            onClick={() => onChatSelect(chat._id)}
                                            className="flex items-center gap-5 px-5 lg:px-[30px] hover:bg-gray-100 transition cursor-pointer"
                                        >
                                            <img
                                                alt="chat_image"
                                                src={chat.image}
                                                className={`w-5 h-5 lg:w-[30px] lg:h-[30px] ${chat.type !== 'group' && 'rounded-full object-cover'} object-contain`}
                                            />
                                            <p className="text-[14px] lg:text-[16px]">{chat.name}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className={'relative h-[calc(100vh-267px)] overflow-y-auto mt-5 lg:mt-0'}>
                {loading && <p>Loading</p>}

                {chats?.map(chat => (
                    <ChatItem
                        {...chat}
                        key={chat._id}
                        socket={socket}
                        chats={chats}
                        onChatSelect={onChatSelect}
                    />
                ))}
            </div>
        </div>
    )
}

export default ChatsList