import React, {useEffect, useRef, useState} from 'react';
import {DropdownArrow} from "../../../assets/icons";
import {gsap} from "gsap";
import {lightGreen} from "@mui/material/colors";

const DropdownInput = ({data, placeholder, onChange, name, value, onTouch, className, optionClassName, disableZoom}) => {
    const [innerData, setInnerData] = useState(data)
    const [searchValue, setSearchValue] = useState('')
    const [open, setOpen] = useState(false)
    const [focused, setFocused] = useState(false)

    const selectedRef = useRef(null);
    const optionsRef = useRef(null);

    const toggleOpen = () => {
        setOpen(!open)

        setTimeout(() => setSearchValue(''), 200)
    }

    const handleClick = (value) => {
        onChange({target: {value, name}})
        toggleOpen()
        setSearchValue('')
    }

    const toggleFocus = () => {
        setFocused(!focused)
    }

    const handleChangeSearch = (e) => {
        setSearchValue(e.target.value)

        if (!open) setOpen(true)
    }

    useEffect(() => {
        const optionsElement = optionsRef.current;

        if (open) {
            gsap.fromTo(
                optionsElement,
                {opacity: 0, height: 0, display: 'block'},
                {opacity: 1, height: 'auto', duration: 0.3}
            )
        } else {
            gsap.to(optionsElement, {opacity: 0, height: 0, duration: 0.2, display: 'none'})
        }
    }, [open])

    useEffect(() => {
        // searchValue.length > 0 ? setInnerData(data.filter(item => String(item.name || item.value).toLowerCase().includes(searchValue.toLowerCase()))) : setInnerData(data)
        searchValue.length > 0 ? setInnerData(data.filter(item => String(item.name || item.value).toLowerCase().slice(0, searchValue.toLowerCase().length) === searchValue.toLowerCase())) : setInnerData(data)
    }, [searchValue])

    useEffect(() => {
        setInnerData(data)
    }, [data])

    return (
        <>
            {open && <div onClick={toggleOpen} className={'fixed z-40 w-full h-full top-0 left-0'}></div>}

            <div className={`relative ${open && 'z-50'}`} onClick={() => onTouch && onTouch()}>
                <div
                    className={`flex justify-between px-5 lg:px-7 rounded-md text-[14px] lg:text-[16px] ${open && 'border-b border-b-black rounded-b-none'}`}
                    ref={selectedRef}
                    onClick={toggleOpen}>
                    <input value={focused ? searchValue : value}
                           onFocus={toggleFocus}
                           onBlur={toggleFocus}
                           placeholder={placeholder}
                           onChange={handleChangeSearch}
                           className={`w-full transition outline-none ${className || 'py-[7px]'}`}
                    />
                    <button className={`${open && 'rotate-180'} transition`}><DropdownArrow/></button>
                </div>

                <div
                    style={{background: '#fff'}}
                    ref={optionsRef}
                    className={`${open ? 'h-auto' : 'h-0'} w-full transition overflow-auto`}>
                    <div className={`w-full max-h-[171px] transition py-2`}>
                        <div>
                            {innerData?.length > 0
                                ?
                                innerData.map((option, i) => {
                                    return (
                                        <div key={option._id || i}
                                             onClick={() => handleClick(option.value || option._id)}
                                             className={`flex justify-between items-center px-5 lg:px-[30px] py-2.5 cursor-pointer hover:bg-gray-100`}>
                                            <div
                                                className={'flex items-center justify-between text-[14px] lg:text-[16px] line-clamp-1 overflow-hidden'}>
                                                <p>{option.name || option.value}</p>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div
                                    className={'flex justify-between items-center px-1.5 py-2.5 rounded-md cursor-default'}>
                                    <p>Ոչինչ չի գտնվել</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DropdownInput