import React, {useEffect, useRef, useState} from 'react';
import {
    ActionMenuIcon,
    ActiveStudentsIcon,
    AllStudentsCountIcon,
    GroupsCountIcon,
    StatusIcon,
    TrashIcon
} from "../../assets/icons";
import {Link} from "react-router-dom";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {useDispatch, useSelector} from "react-redux";
import {changeProgramStatus} from "../../store/actions/programs";
import {ConfirmationModal} from "../shared";
import ButtonHoverText from "../shared/buttons/ButtonHoverText";
import {roles} from "../../common/roles";

const ProgramCard = ({
                         _id,
                         name,
                         image,
                         activeStudentsCount,
                         allStudentsCount,
                         groupsCount,
                         active: initialActive,
                         programToDelete,
                         setProgramToDelete,
                         handleDeleteProgram
                     }) => {
    const {authData} = useSelector(state => state.auth)

    const [showMenu, setShowMenu] = useState(false)
    const [statusToChange, setStatusToChange] = useState(false)
    const [active, setActive] = useState(initialActive)

    const dispatch = useDispatch()
    const ref = useRef(null)

    useOutsideClick(ref, () => setShowMenu(false))

    const deleteProgram = () => {
        setProgramToDelete(_id)
    }

    const changeStatus = () => {
        setStatusToChange(true)
    }

    const handleChangeStatus = () => {
        dispatch(changeProgramStatus(_id, !active))
        setActive(!active)
        setShowMenu(false)
        setStatusToChange(false)
    };

    useEffect(() => {
        setActive(initialActive)
    }, [initialActive])

    useEffect(() => {
        document.body.style.overflow = (statusToChange || programToDelete) ? 'hidden' : 'auto'
    }, [statusToChange, programToDelete])

    return (
        <>
            {programToDelete &&
                <ConfirmationModal
                    close={() => setProgramToDelete(null)}
                    warningText={'Վստա՞հ եք, որ ցանկանում եք ջնջել այս ծրագիրը։'}
                    icon={<TrashIcon width={50} height={50}/>}
                    confirm={handleDeleteProgram}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(false)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ${active ? 'ապաակտիվացնել' : 'ակտիվացնել'} այս ծրագիրը։`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'relative max-w-[296px] h-[314px] py-[30px] custom-shadow rounded-[10px]'}>
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px]`}></div>

                <div className={'w-full'}>
                    <div className={'flex justify-center'}>
                        <Link to={`/programs/${_id}/groups`}>
                            <h2 className={'text-[#FCC10F] text-center'}>{name}</h2>
                        </Link>

                        {(roles[authData?.role]?.some(role => role.allowChangeProgramStatus) || roles[authData?.role]?.some(role => role.allowDeleteProgram))
                            &&
                            <button
                                className={`absolute z-50 right-3 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                <ActionMenuIcon/>
                            </button>
                        }
                    </div>

                    {showMenu && (
                        <div ref={ref}
                             className={`absolute z-50 w-[203px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                            <>
                                <p
                                    onClick={changeStatus}
                                    className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                    {active ? 'Ապաակտիվացնել' : 'Ակտիվացնել'}
                                </p>
                                <p
                                    onClick={deleteProgram}
                                    className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                    Ջնջել
                                </p>
                            </>
                        </div>
                    )}
                </div>

                <div className={'w-full h-full max-h-[152px] flex justify-center my-[30px] px-10'}>
                    <Link to={`/programs/${_id}/groups`}> <img src={image} alt={'program_image'}
                                                               className={'max-h-[149px] w-full'}/></Link>
                </div>

                <div className={'flex items-center justify-between px-10'}>
                    <p className={'relative group flex items-center gap-[10px]'}>
                        <ActiveStudentsIcon/>
                        <span>{activeStudentsCount}</span>
                        <span
                            className={`absolute z-[999] w-[160px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ակտիվ ուսանողներ</span>
                    </p>
                    <p className={'relative group flex items-center gap-[10px]'}>
                        <AllStudentsCountIcon/>
                        <span>{allStudentsCount}</span>
                        <span
                            className={`absolute z-[999] w-[220px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ընդհանուր ուսանողների թիվ</span>
                    </p>
                    <p className={'relative group flex items-center gap-[10px]'}>
                        <GroupsCountIcon/>
                        <span>{groupsCount}</span>
                        <span
                            className={`absolute z-[999] w-[130px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Խմբերի քանակ</span>
                    </p>
                    {active === true
                        ?
                        <ButtonHoverText icon={<StatusIcon color={'#20BA07'}/>} text={'Ակտիվ'}/>
                        :
                        <ButtonHoverText icon={<StatusIcon color={'#00000099'}/>} text={'Ապաակտիվ'}/>
                    }
                </div>
            </div>
        </>
    );
};

export default ProgramCard;
