import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {formatDate} from "../../../utils/formatSentMessageDate";
import moment from "moment";
import 'moment/locale/hy-am';
import {PinnedIcon, TrashIcon} from "../../../assets/icons";
import Portal from "../../shared/Portal";
import {usePinUnpinChat} from "../../../hooks/socket/useChats";
import defaultGroupImage from '../../../assets/images/group-chat.png'
import {deleteChat} from "../../../socket/chats";
import {ConfirmationModal} from "../../shared";
import {useMediaQuery} from "react-responsive";

const ChatItem = ({
                      _id,
                      closed,
                      image,
                      lastMessage,
                      name,
                      type,
                      newMessagesCount,
                      pinned,
                      socket,
                      chats,
                      onChatSelect
                  }) => {
    const [showMenu, setShowMenu] = useState(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [groupToDelete, setGroupToDelete] = useState(null)
    const [pinnedIconPos, setPinnedIconPos] = useState({top: 0, left: 0})

    const {pinUnpinChat} = usePinUnpinChat(socket)

    const lastMessageDate = lastMessage && formatDate(lastMessage?.createdAt) || ''

    const {id} = useParams()

    const ref = useRef(null)
    const navigate = useNavigate()

    const isPhone = useMediaQuery({maxWidth: 640})

    useOutsideClick(ref, () => setShowMenu(null))

    const handleContextMenu = (e) => {
        e.preventDefault();
        setShowMenu(e.currentTarget)
    }

    const handlePinUnpinClick = () => {
        pinUnpinChat(_id)
        setShowMenu(null)
    }

    const handleDeleteChat = () => {
        deleteChat(socket, _id).then(() => {
            navigate(`/chats`)
            setShowMenu(null)
        })
    }

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);

    useEffect(() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect()

            setPinnedIconPos({
                top: rect.top - 10,
                left: rect.right - 20,
            });
        }
    }, [windowWidth, ref.current, chats])

    return (
        <>
            {groupToDelete &&
                <ConfirmationModal
                    close={() => setGroupToDelete(null)}
                    warningText={'Վստա՞հ եք, որ ցանկանում եք ջնջել այս խմբի նամակագրությունը։'}
                    icon={<TrashIcon width={50} height={50}/>}
                    confirm={handleDeleteChat}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div
                ref={ref}
                onContextMenu={handleContextMenu}
                onClick={() => onChatSelect(_id)}
                className={`relative w-full flex gap-2.5 lg:gap-10 items-center border border-[#D0CFCF] py-1 lg:border-0 lg:border-b lg:py-3 cursor-pointer ${showMenu && 'bg-black bg-opacity-5'} ${id === _id && ' bg-[#FCC10F] rounded-[5px]'}`}
            >
                {closed && <div className={`absolute inset-0 bg-[#D0CFCF] bg-opacity-20 z-[9990]`}/>}

                <img
                    alt={'chat_image'}
                    src={image || defaultGroupImage}
                    className={`w-[30px] h-[30px] ${type !== 'group' ?  'object-cover rounded-full' : 'object-contain'}  ml-2.5 lg:ml-[30px]`}
                />

                <div className={'w-full flex flex-col gap-2.5 lg:gap-0 text-[12px]'}>
                    <div className={'flex items-center justify-between pr-2.5'}>
                        <h2 className={'text-black text-opacity-70'}>{name}</h2>
                        <p className={'text-black text-opacity-70'}>{lastMessageDate}</p>
                    </div>
                    <div className={'flex justify-between'}>
                        <p className={`flex gap-1 text-black text-opacity-50 line-clamp-1 pr-[50px] ${newMessagesCount > 0 && 'bold'}`}
                        >
                            {type === 'group' && lastMessage?.author &&
                                <span className={`max-w-[130px] lg:max-w-full bold text-black ${newMessagesCount === 0 && 'text-opacity-50'} line-clamp-1`}>
                                    {lastMessage?.author}`
                                </span>
                            }
                            <span className={'line-clamp-1'}>{lastMessage?.message}</span>
                        </p>
                        {newMessagesCount > 0 &&
                            <div
                                className={'w-[18px] h-[18px] aspect-square flex items-center justify-center bg-[#FF0707] mr-4 text-white rounded-full px-[6px] py-[1px]'}
                            >
                                {newMessagesCount}
                            </div>
                        }
                    </div>
                </div>
                {pinned &&
                    <Portal top={pinnedIconPos.top} left={pinnedIconPos.left} alignment="left">
                        <PinnedIcon height={isPhone && 17} width={isPhone && 17}/>
                    </Portal>
                }
                {showMenu &&
                    <div
                        className={'fixed z-50 w-[229px] flex flex-col gap-4 bg-white py-6 border border-black rounded-[5px] transform -translate-x-1/2'}
                        style={{
                            left: showMenu.getBoundingClientRect().left + showMenu.getBoundingClientRect().width / 2,
                            top: showMenu.getBoundingClientRect().bottom,
                        }}
                    >
                        <>
                            <p
                                className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}
                                onClick={handlePinUnpinClick}
                            >
                                {pinned ? 'Ապաամրացնել' : 'Ամրացնել'}
                            </p>
                            <p
                                onClick={() => setGroupToDelete(true)}
                                className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}
                            >
                                Ջնջել
                            </p>
                        </>
                    </div>
                }
            </div>
        </>
    )
}

export default ChatItem