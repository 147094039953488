import React, {useState} from 'react';
import {AsteriskIcon, CloseIcon} from "../../../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../../../shared/buttons";
import Calendar from "../../../shared/calendar/Calendar";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {AutoResizableTextarea} from "../../../shared"
import {addStudentNote, changeStudentStatus} from "../../../../store/actions/groups";
import {logDOM} from "@testing-library/react";

const EditStudentPlusNoteModal = ({student, close, type, setTempStatus}) => {
    const [date, setDate] = useState(null)
    const [note, setNote] = useState('')
    const [error, setError] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const handleChangeNote = (e) => {
        setNote(e.target.value)
    }

    const handleSubmit = () => {
        if (date && note) {
            setTempStatus(type)

            if (type !== 'completed') {
                dispatch(changeStudentStatus(id, student._id, type, date, note, close, setError))
            } else {
                dispatch(addStudentNote(id, student._id, {date, note}, close, setError))
            }
        }

        if (type === 'completed') {
            setTempStatus(type)

            dispatch(changeStudentStatus(id, student._id, type, note, close, setError))
            close()
        }

        if (type === 'restored') {
            setTempStatus(null)
        }
    }

    return (
        <div className={'fixed z-[99999] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div onClick={close}
                 className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[690px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-[28px]'}>
                    <h2 className={'text-center'}>{student.fullName}</h2>

                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'}
                            onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>
                <div className={'flex flex-col px-[30px]'}>
                    {type !== 'completed' &&
                        <div className={'relative'}>
                            <h2 className={'flex gap-[5px] mb-2.5'}>Ամսաթիվ <AsteriskIcon/></h2>
                            <div
                                className={'absolute w-full border border-black border-opacity-70 z-[999] rounded-[3px] overflow-hidden'}>
                                <Calendar placeholder={'օր/ամիս/տարի'} className={'py-[3px]'} setDate={setDate}
                                          value={date}
                                          displayFormat={'DD/MM/YYYY'} returnFormat={'YYYY-MM-DD'}/>
                            </div>
                        </div>
                    }

                    <div className={`relative flex flex-col gap-[10px] ${type !== 'completed' && 'mt-[60px]'}`}>
                        <h2 className={'flex gap-[5px]'}>
                            Նոթագրություն
                            {type !== 'completed' && <AsteriskIcon/>}
                        </h2>
                        <AutoResizableTextarea value={note} onChange={handleChangeNote} placeholder={'Նոթագրություն'}/>
                    </div>
                </div>
                <div className={'flex justify-center py-[50px] gap-[30px]'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                    <ButtonFilled onClick={handleSubmit} text={'Պահպանել'} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    );
};

export default EditStudentPlusNoteModal