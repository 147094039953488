import React, {useState} from 'react';
import {EditIcon, TrashIcon, TrashModeIcon} from "../../assets/icons";
import moment from "moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {roles} from "../../common/roles";

const NoteItem = ({note, handleEditNote, showStudent, setDeleteNoteModal, type}) => {
    const {authData} = useSelector(state => state.auth);
    const formattedDate = moment(note.date || note.createdAt).format('DD.MM.YYYY')
    const formattedTime = moment(note.createdAt).format("HH:mm")

    return (
        <div
            className={'relative group p-[30px] mt-[30px] mb-[50px] bg-white shadow-md rounded-[10px]'}
        >
            {!note?.isPublic && <div className={'mb-2'}><TrashModeIcon/></div>}
            <div className={`flex items-center mb-5`}>
                {showStudent &&
                    <div className={'flex items-center justify-between w-full'}>
                        <Link to={`/students/${note.student._id}`}
                              className={`hover:text-[#FCC10F] transition bold`}>{note.student.fullName}</Link>
                        <div className={'w-[90px] flex items-start justify-end gap-[30px]'}>
                            {note.userCanEditNote &&
                                <button onClick={() => handleEditNote(note)}>
                                    <EditIcon width={30} height={30}/>
                                </button>
                            }
                            {note.userCanDeleteNote &&
                                <button onClick={() => setDeleteNoteModal(note._id)}>
                                    <TrashIcon width={30} height={30}/>
                                </button>
                            }
                        </div>
                    </div>
                }
            </div>
            {!note.isPublic &&
                <h2 className={"absolute group-hover:opacity-100 opacity-0 right-0 bottom-0 mb-1 mr-[25px] text-[12px] transition"}>Սևագիր</h2>
            }
            <div className={'flex justify-between gap-[30px]'}>
                <div className={`${!note.isPublic && 'opacity-70'} text-justify`}>
                    <p>{note.note}</p>
                </div>
                {!showStudent &&
                    <div className={'w-[90px] flex items-start justify-end gap-[30px]'}>
                        {note.userCanEditNote &&
                            <button onClick={() => handleEditNote(note)}>
                                <EditIcon width={30} height={30}/>
                            </button>
                        }
                        {note.userCanDeleteNote &&
                            <button onClick={() => setDeleteNoteModal(note._id)}>
                                <TrashIcon width={30} height={30}/>
                            </button>
                        }
                    </div>
                }
            </div>
            <div className={'flex gap-[30px] mt-[30px]'}>
                <p className={'bold opacity-70'}>{note.author.fullName}</p>
                <p>{note.author?.position}</p>
                <p>{formattedDate}</p>
                <p>{formattedTime}</p>

                {
                    note.group
                    &&
                    note.userCanNavigateToGroup ? (
                        <Link to={`/groups/${note.group._id}/attendance`}
                              className={'text-[#FCC10F]'}>{note.group?.name}</Link>
                    ) : (
                        <p>{note.group?.name}</p>
                    )
                }
            </div>
        </div>
    );
};

export default NoteItem;
