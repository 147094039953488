import React from 'react';
import {
    ActiveStudentsIcon,
    AlarmIcon,
    CalendarIcon,
    DriverYellowIcon, TransportIcon,
    YellowRouteIcon
} from "../../../../../assets/icons";
import Calendar from "../../../../shared/calendar/Calendar";

const TripAnalyticItem = () => {
    return (
        <div>
            <div className={'flex items-center gap-5'}>
                <div
                    // onClick={() => handleSelectTrips(_id)}
                    className={'w-[30px] h-[30px] flex items-center justify-center cursor-pointer border border-black border-opacity-70 rounded-[5px]'}
                >
                    {/*{checked && <CheckedIcon/>}*/}
                </div>
                <div className={'relative w-full min-h-[133px] custom-shadow rounded-[5px] p-[30px]'}>
                    <div
                        className={`hidden absolute w-full h-full z-40 backdrop-blur-[1px] cursor-pointer`}></div>

                    <div className={'flex justify-center'}>
                        <div className={'flex gap-5'}>
                            <YellowRouteIcon />
                            <span className={''}>Մարգահովիտ գյուղամեջ- Վանաձորի պատկերասրահ- Փամբակ-Եղեգնուտի վարսավիրանոց- ՍՄԱՐԹ</span>
                        </div>
                    </div>

                    <div className={'w-full flex justify-between items-center mt-[30px]'}>
                        <div className={'flex items-center gap-5'}>
                            <AlarmIcon />
                            <span>16:00</span>
                        </div>
                        <div className={'flex items-center gap-5'}>
                            <CalendarIcon color={'#FCC10F'} />
                            <span>27.06.2024</span>
                        </div>
                        <div className={'flex items-center gap-5'}>
                            <ActiveStudentsIcon color={'#FCC10F'}/>
                            <span>16</span>
                        </div>
                        <div className={'flex items-center gap-5'}>
                            <DriverYellowIcon/>
                            <span>Վարորդ</span>
                        </div>
                        <div className={'flex items-center gap-5'}>
                            <TransportIcon color={'#FCC10F'}/>
                            <span>Ford, Transit, սպիտակ</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TripAnalyticItem;