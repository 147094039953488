import React from 'react';
import {Link} from "react-router-dom";

const TransportSectionCard = ({name, icon, path}) => {
    return (
        <Link to={path}
              className={'w-[187px] h-[178px] flex justify-center py-[30px] border-[3px] border-black rounded-[20px] cursor-pointer'}>
            <div className={'flex flex-col gap-[30px]'}>
                <span className={'flex justify-center'}>{icon}</span>
                <span>{name}</span>
            </div>
        </Link>
    )
}

export default TransportSectionCard