export const downloadFileFromSrc = async (filePath, downloadName) => {
    try {
        await fetch(`https://api.smartcitizen.am/d?f=${filePath}`, {
            method: 'GET',
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            const urlParts = filePath.split('/')
            const filename = urlParts[urlParts.length - 1]

            const extname = filePath.split('.')[filePath.split('.').length - 1]

            return response.blob().then(blob => ({ blob, downloadName: downloadName ? `${downloadName}.${extname}` : filename }))
        })
            .then(({ blob, downloadName }) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = downloadName
                document.body.appendChild(a)
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)
            })
    } catch (error) {
        console.error('There was an error downloading the file:', error)
    }
}

export const downloadStudentsQrCodesFile = async (ids) => {
    try {
        await fetch(`https://api.smartcitizen.am/students/qr?students=${ids}`, {
            method: 'GET',
            credentials: 'include'
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }

            const filename = 'qr-codes.pdf'

            return response.blob().then(blob => ({ blob, downloadName: filename  }))
        })
            .then(({ blob, downloadName }) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = downloadName
                document.body.appendChild(a)
                a.click()
                a.remove()
                window.URL.revokeObjectURL(url)
            })
    } catch (error) {
        console.error('There was an error downloading the file:', error)
    }
}