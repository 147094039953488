import React, {useEffect, useRef, useState} from 'react';
import {ActionMenuIcon, AlarmIcon, PhoneIcon, RouteIcon, StatusIcon, TransportIcon} from "../../../../assets/icons";
import ButtonHoverText from "../../../shared/buttons/ButtonHoverText";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {useOutsideClick} from "../../../../hooks/useOutsideClick";
import {changeDriverStatus} from "../../../../store/actions/transport";
import {ConfirmationModal, DisplayPhoneNumber} from "../../../shared";
import {roles} from "../../../../common/roles";

const DriverCard = ({
                        _id,
                        active: initialActive,
                        fullName,
                        profileImage,
                        workingSchedule,
                        vehicles,
                        phoneNumbers,
                    }) => {
    const {authData} = useSelector(state => state.auth)

    const [showMenu, setShowMenu] = useState(false)
    const [statusToChange, setStatusToChange] = useState(false)
    const [active, setActive] = useState(initialActive)

    const ref = useRef()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useOutsideClick(ref, () => setShowMenu(false))

    const changeStatus = () => {
        setStatusToChange(true)
    }

    const handleChangeStatus = () => {
        dispatch(changeDriverStatus(_id))
        setActive(!active)
        setShowMenu(false)
        setStatusToChange(false)
    }

    const handleSelectStop = () => {
        // const selectedStopData = { _id,
        //     active: initialActive,
        //     community,
        //     coordinates,
        //     distance,
        //     duration,
        //     name,
        //     place,
        // }
        //
        // dispatch(selectTransportStop(selectedStopData))
        // navigate(`/transport/stops/edit`)
    }

    useEffect(() => {
        setActive(initialActive)
    }, [initialActive])

    useEffect(() => {
        document.body.style.overflow = statusToChange ? 'hidden' : 'auto'
    }, [statusToChange])

    return (
        <>
            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(false)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ${active ? 'ապաակտիվացնել' : 'ակտիվացնել'} այս վարորդին։`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'relative w-[405px] h-[326px] custom-shadow rounded-2.5'}>
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px]`}></div>


                <div className={'flex justify-center'}>
                    <div className={'relative flex items-center pt-[30px] gap-2.5 cursor-pointer'}>
                        <div className={'text-[12px]'}>
                            {active === true
                                ?
                                <ButtonHoverText icon={<StatusIcon color={'#20BA07'}/>} text={'Ակտիվ'}/>
                                :
                                <ButtonHoverText icon={<StatusIcon color={'#00000099'}/>} text={'Ապաակտիվ'}/>
                            }
                        </div>
                        <Link to={`/transport/drivers/${_id}?mode=view&subpage=profile`}
                              className={'text-[#FCC10F]'}>{fullName}</Link>
                    </div>
                    {roles[authData?.role]?.some(role => role.allowAllActionsInTransport) &&
                        <button
                            className={`absolute z-50 right-[30px] top-8 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                            onClick={() => setShowMenu(!showMenu)}
                        >
                            <ActionMenuIcon width={30} height={30}/>
                        </button>
                    }
                </div>

                {showMenu && (
                    <div ref={ref}
                         className={`absolute z-50 w-[199px] top-[62px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                        <p
                            onClick={() => navigate(`/transport/drivers/${_id}?mode=edit&subpage=profile`)}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Խմբագրել
                        </p>
                        <p
                            onClick={changeStatus}
                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            {active ? 'Ապաակտիվացնել' : 'Ակտիվացնել'}
                        </p>
                    </div>
                )}
                <div>
                    <Link to={`/transport/drivers/${_id}`}
                          className={'w-full flex justify-center pt-5 pb-[30px]'}>
                        <img alt={'profileImage'} src={profileImage}
                             className={'max-w-[122px] w-full max-h-[124px] object-cover rounded-full pointer-events-none select-none'}/>
                    </Link>
                    <div className={'flex justify-between items-center'}>
                        <div className={'flex flex-col pl-[30px] gap-[30px]'}>
                            <div className={'flex items-center gap-5'}>
                                <PhoneIcon color={'#000'}/>
                                <DisplayPhoneNumber phoneNumber={phoneNumbers[0]}/>
                            </div>
                            <div className={'flex items-center gap-5'}>
                                <TransportIcon color={'#000'}/>
                                {vehicles[0].mark}
                            </div>
                        </div>
                        <div className={'flex flex-col pr-[30px] gap-[30px]'}>
                            <div className={'max-h-[70px] flex items-start flex-col gap-[30px]'}>
                                <div className={"relative group"}>
                                    <div className={'flex items-center gap-5 select-none'}>
                                        <AlarmIcon color={'#000'}/>
                                        <span>Գրաֆիկ</span>
                                    </div>
                                    {workingSchedule.length >= 1 &&
                                        <div
                                            className={"w-[228px] absolute z-10 hidden group-hover:grid grid-cols-3 gap-y-2.5 bg-white border shadow-md rounded-[3px] p-2 left-0 mt-2"}>
                                            {workingSchedule?.map((item, index) => (
                                                <p key={index} className={'text-[12px]'}>
                                                    {item.dayIndex} {item.startTime} - {item.endTime}
                                                </p>
                                            ))}
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={'flex items-center gap-5 select-none'}>
                                <RouteIcon/>
                                <span>Երթեր</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DriverCard;
