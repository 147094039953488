import React, { useEffect, useRef, useState } from 'react';
import { ActiveStudentsIcon, CheckedIcon, DivideStudentsIcon, EditIcon, StopIcon } from "../../../../../assets/icons";
import DividePassengersModal from "../modals/DividePassengersModal";
import { Link } from "react-router-dom";
import EditPassengersModal from "../modals/EditPassengersModal";

const TripItem = ({ _id, stops, handleSelectTrips, selected, passengers, order, passengersSum, refreshData }) => {
    const [studentToDivide, setStudentToDivide] = useState(null);
    const [editPassengersModal, setEditPassengersModal] = useState(null);
    const [showPassengerList, setShowPassengerList] = useState(false);
    const [listPosition, setListPosition] = useState('5px');
    const passengerListRef = useRef(null);

    const checked = selected?.find(selected => selected?._id === _id);

    useEffect(() => {
        editPassengersModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto';
    }, [editPassengersModal]);

    useEffect(() => {
        if (showPassengerList && passengerListRef.current) {
            const rect = passengerListRef.current.getBoundingClientRect();
            if (rect.bottom > window.innerHeight) {
                setListPosition(`-${rect.height}px`);
            } else {
                setListPosition('20px');
            }
        }
    }, [showPassengerList]);

    const handleMouseEnter = () => {
        setListPosition('5px')
        setShowPassengerList(true)
    };

    const handleMouseLeave = () => {
        setShowPassengerList(false)
    }

    return (
        <>
            {editPassengersModal &&
                <EditPassengersModal
                    close={() => setEditPassengersModal(null)}
                    id={_id}
                    refreshData={refreshData}
                />
            }

            {studentToDivide &&
                <DividePassengersModal
                    id={_id}
                    close={() => setStudentToDivide(null)}
                    refreshData={refreshData}
                />
            }

            {stops?.map((stop, index) =>
                <div key={index} className={'flex items-center gap-5'}>
                    <div
                        onClick={() => handleSelectTrips(_id)}
                        className={'w-[30px] h-[30px] flex items-center justify-center cursor-pointer border border-black border-opacity-70 rounded-[5px]'}
                    >
                        {checked && <CheckedIcon />}
                    </div>
                    <div className={'w-full h-[60px] custom-shadow rounded-[5px] py-[15px] px-[30px]'}>
                        <div className={'w-full grid grid-cols-4 items-center'}>
                            <div className={'col-span-2 flex items-center gap-2.5'}>
                                <StopIcon colour={'#FCC10F'} />
                                <span>{stop?.transportStop?.name}</span>
                            </div>
                            <div className={'flex'}>
                                <div
                                    className={'relative group flex items-center gap-2.5'}
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <ActiveStudentsIcon color={'#FCC10F'} />
                                    <span className={'select-none'}>{passengersSum}</span>
                                    <div
                                        ref={passengerListRef}
                                        className={`w-[350px] absolute z-[9999] max-h-[400px] overflow-y-auto flex flex-col gap-5 p-2.5 transition bg-white custom-shadow rounded-[3px] ${showPassengerList ? 'flex' : 'hidden'}`}
                                        style={{ left: '70%', top: listPosition }}
                                    >
                                        {passengers?.map(passenger =>
                                            passenger.type === 'student'
                                                ?
                                                <Link
                                                    to={`/students/${passenger._id}?mode=view&subpage=profile`}
                                                    key={passenger._id}
                                                    className={'w-fit hover:text-[#FCC10F] transition'}
                                                >
                                                    {passenger?.fullName}
                                                </Link>
                                                :
                                                <p
                                                    key={passenger._id}
                                                    className={'w-fit cursor-default'}
                                                >
                                                    {passenger?.fullName}
                                                </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={'flex items-center justify-end gap-52'}>
                                {passengers?.length > 7 &&
                                    <div className={'relative w-fit group fill-icon cursor-pointer'}>
                                        <div onClick={() => setStudentToDivide(true)}>
                                            <DivideStudentsIcon />
                                        </div>
                                        <span
                                            className={`absolute w-[199px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Բաժանել մասնակիցներին</span>
                                    </div>
                                }
                                <div className={'relative w-fit group cursor-pointer fill-icon'}>
                                    <div onClick={() => setEditPassengersModal(true)}>
                                        <EditIcon width={15} height={15} color={'#000'} />
                                    </div>
                                    <span
                                        className={`absolute w-fit left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Խմբագրել</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TripItem;
