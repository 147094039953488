export const checkMissingMandatory = (field) => {
    let isInvalid = false

    if(!field.mandatory || !field.touched) {
        return isInvalid
    }

    switch (field.type) {
        case 'date':
            if(!field.answer) {
                isInvalid = true

                return isInvalid
            }
            break
        case 'phoneNumber':
            if(!field.answer || field.answer.length !== 12) {
                isInvalid = true

                return isInvalid
            }
            break
        default:
            isInvalid = ((field.mandatory && field.touched && (field.answer && field.answer?.trim() === '')) || (field.mandatory && field.touched && !field.answer))
            return isInvalid
    }

    return isInvalid
}