import React from 'react';
import {CheckedIcon, SearchIcon} from "../../../../../assets/icons";

const Search = ({trip, searchOptionsRef, searchStates, handleChange, totalPassengersCount, seatingCapacity, handleSelect, warningMessage, selectedStudents}) => {
    return (
        <div>
            <div className={'flex flex-col px-[30px] gap-5 mb-5'}>
                {trip?.stops.map((stop, stopIndex) =>
                    <div key={stop._id} className={'relative flex flex-col gap-[10px]'} ref={searchOptionsRef}>
                        <h2 className={'flex gap-[5px]'}>Ավելացնել
                            ուսանող/հյուր {stop.transportStop.name} կանգառում</h2>
                        <div
                            className={'relative flex justify-between bg-white w-full border border-black border-opacity-70 rounded-[3px] py-[3px] px-[30px]'}>
                            <input
                                type={'text'}
                                value={searchStates[stopIndex]?.value || ''}
                                onChange={e => handleChange(e.target.value, stopIndex, e)}
                                onKeyPress={e => {
                                    if (e.key === 'Enter') {
                                        e.preventDefault()
                                        handleChange(e.target.value, stopIndex, e);
                                    }
                                }}
                                className={'w-full pr-[30px] outline-none'}
                            />
                            <div className={'flex items-center gap-5'}>
                                <button>
                                    <SearchIcon/>
                                </button>
                            </div>
                        </div>
                        {stopIndex === 0 && totalPassengersCount === seatingCapacity &&
                            <p className="absolute top-full text-red-500 text-[12px]">Մեքենայում նստատեղերի թիվը
                                լրացել է</p>
                        }
                        {searchStates[stopIndex]?.students && (
                            <div
                                className={'absolute z-[9999] w-full max-h-[200px] overflow-y-auto py-2.5 border border-black border-t-0 top-full bg-white rounded-b-[3px]'}>
                                {searchStates[stopIndex].students.length > 0 ?
                                    <div className={'flex flex-col gap-5'}>
                                        {searchStates[stopIndex].students.map(student => (
                                            <div key={student._id} onClick={() => handleSelect(student, stopIndex)}
                                                 className={'flex items-center gap-5 px-[30px] py-[5px] hover:bg-gray-100 transition cursor-pointer'}>
                                                <div className={'w-full flex items-center justify-between'}>
                                                    <span>{student.fullName}</span>
                                                    <div
                                                        className={'w-5 h-5 flex items-center justify-center cursor-pointer border border-black border-opacity-70 rounded-[5px]'}>
                                                        {selectedStudents.some(s => s._id === student._id) &&
                                                            <CheckedIcon/>}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    :
                                    <p className={'mx-[30px]'}>Ուսանողներ չկան</p>
                                }
                            </div>
                        )}
                    </div>
                )}
                {warningMessage && (
                    <p className="text-red-500 text-sm text-[12px]">{warningMessage}</p>
                )}
            </div>
        </div>
    );
};

export default Search;