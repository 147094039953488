import API from "./index";

export const createDivision = (data) => API.post(`/divisions`, data)
export const getDivisions = () => API.get(`/divisions`)
export const changeDivisionStatus = (id) => API.patch(`/divisions/${id}/status`)
export const editDivision = (id, data) => API.patch(`/divisions/${id}`, data)

export const createMember = (data) => API.post('/members', data)
export const getMembers = (queryParams) => API.get(`/members${queryParams}`)
export const changeMemberStatus = (id) => API.patch(`members/${id}/status`)
export const editProfile = (id, data) => API.patch(`/members/${id}`, data)
export const deleteMember = (id) => API.delete(`/members/${id}`)

