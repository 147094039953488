import {
    CHANGE_COMMUNITY_STATUS,
    CREATE_COMMUNITY, DELETE_COMMUNITY, DELETE_PROGRAM,
    GET_COMMUNITIES,
    SET_LOADING
} from '../constants/actionTypes'
import statement from "../../pages/main/statements/statement/Statement";

const initialState = {
    communities: [],
    loading: false,
}

const communitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_COMMUNITY:
            const updatedCommunity = action.payload
            const updatedCommunities = state.communities.map(community => {
                if (community._id === updatedCommunity._id) {
                    return {...community, ...updatedCommunity}
                }
                return community
            })
            return {
                ...state,
                communities: updatedCommunities,
            }
        case CHANGE_COMMUNITY_STATUS:
            const updatedStatusCommunity = action.payload;
            const updatedStatusCommunities = state.communities.map(community => {
                if (community._id === updatedStatusCommunity._id) {
                    return { ...community, active: updatedStatusCommunity?.active };
                }
                return community
            });
            return {
                ...state,
                communities: updatedStatusCommunities,
            }
        case GET_COMMUNITIES:
            return {
                ...state,
                communities: action.payload.communities,
            }
        case DELETE_COMMUNITY:
            return {
                ...state,
                communities: state.communities.filter(item => item._id !== action.payload.id)
            }
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}
export default communitiesReducer