import {
    CREATE_STATEMENT, DELETE_STATEMENT, DUPLICATE_STATEMENT, EDIT_STATEMENT, FETCH_STATEMENT,
    GET_STATEMENT,
    GET_STATEMENTS,
    SELECT_ROUTE,
    SELECT_STATEMENT,
    SET_LOADING
} from "../constants/actionTypes";
import * as statementsApi from "../../api/statements";

export const createStatement = (payload, queryParams) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING });

        const { data } = await statementsApi.createStatement(payload)

        if (data.success) {
            dispatch({ type: CREATE_STATEMENT, payload: data })

            dispatch(getStatements('?page=1&size=9&'))
        }

        dispatch({ type: SET_LOADING });
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const getStatements = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await statementsApi.getStatements(queryParams)

        if (data.success) {
            dispatch({type: GET_STATEMENTS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const getStatement = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await statementsApi.getStatement(id)

        if (data.success) {
            dispatch({type: GET_STATEMENT, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const editStatement = (id, payload) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await statementsApi.editStatement(id, payload)

        if (data.success) {
            dispatch({type: EDIT_STATEMENT, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const selectStatement = (statement) => async (dispatch) => {
    try {
        dispatch({type: SELECT_STATEMENT, payload: {statement}})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const fetchStatement = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await statementsApi.getStatement(id)

        if (data.success) {
            dispatch({type: FETCH_STATEMENT, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const duplicateStatement = (id, queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await statementsApi.duplicateStatement(id)

        if (data.success) {
            dispatch({type: DUPLICATE_STATEMENT, payload: data})

            dispatch(getStatements(queryParams))
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const deleteStatement = (id, queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await statementsApi.deleteStatement(id)

        if (data.success) {
            dispatch({type: DELETE_STATEMENT, payload: data})

            dispatch(getStatements(queryParams))
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

