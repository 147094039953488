import React from 'react';
import {DriverLicenseIcon, PassportIcon, PhoneIcon, TransportIcon} from "../../../../../assets/icons";

const DriverWorkData = ({passport, driverLicense, phoneModel, vehicles}) => {
    return (
        <div className={'w-[773px] h-[560px] flex flex-col gap-[50px] custom-shadow rounded-[10px] p-[50px]'}>
            <div className={'flex items-center gap-[30px]'}>
                <div
                    className={'w-[60px] h-[60px] flex items-center justify-center border-[2px] border-[#FCC10F] rounded-full'}>
                    <PassportIcon/>
                </div>
                <span>{passport}</span>
            </div>
            <div className={'flex items-center gap-[30px]'}>
                <div
                    className={'w-[60px] h-[60px] flex items-center justify-center border-[2px] border-[#FCC10F] rounded-full'}>
                    <DriverLicenseIcon />
                </div>
                <div className={'flex gap-[30px]'}>
                    {driverLicense?.map((driverLicense, index) =>
                        <p key={index} className={'uppercase'}>
                            {driverLicense}
                        </p>
                    )}
                </div>
            </div>
            <div className={'flex items-center gap-[30px]'}>
                <div
                    className={'w-[60px] h-[60px] flex items-center justify-center border-[2px] border-[#FCC10F] rounded-full'}>
                    <PhoneIcon width={40} height={40}/>
                </div>
                <span>{phoneModel}</span>
            </div>
            <div className={'flex items-center gap-[30px]'}>
                <div
                    className={'w-[60px] h-[60px] flex items-center justify-center border-[2px] border-[#FCC10F] rounded-full'}>
                    <TransportIcon width={40} height={40}/>
                </div>
                <div className={'flex gap-[30px]'}>
                    {vehicles?.map((vehicle, index) =>
                        <p key={index}>{vehicle.name}</p>)}
                </div>
            </div>
        </div>
    );
};

export default DriverWorkData;