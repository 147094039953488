import React, {useState} from 'react';
import {AsteriskIcon, CloseIcon} from "../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";
import {useDispatch} from "react-redux";
import {createCommunity} from "../../store/actions/communities";

const initialState = {
    name: ''
}

const CreateCommunityModal = ({close, sortOptions}) => {
    const [data, setData] = useState(initialState)
    const [error, setError] = useState('')

    const dispatch = useDispatch()

    const handleInputChange = (name, value) => {
        setData(prevState => ({
            ...prevState,
            [name]: value
        }))
        setError('')
    }

    const handleCreateCommunity = () => {
        if (!data.name) {
            setError('Այս դաշտը պարտադիր է')
            return
        }

        dispatch(createCommunity(data, sortOptions))

        close()
    }

    return (
        <div className={'fixed z-[9990] mt-[30px] w-screen h-screen left-0 flex justify-center'}>
            <div className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute w-full max-w-[689px] h-[289px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-5'}>
                    <h2 className={'text-center mt-5'}>Ավելացնել նոր համայնք</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>

                <div className={'flex flex-col gap-2.5 px-[70px]'}>
                    <p className={'flex gap-1'}>Նոր համայնքի անունը <AsteriskIcon/></p>
                    <div className={'relative'}>
                        <input
                            name={'name'}
                            value={data.name}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                            className={'w-[548px] h-[30px] border border-black border-opacity-70 rounded-[3px] px-[30px] py-1 outline-none'}
                        />
                        {error && <p className="absolute text-red-500 mt-1">{error}</p>}
                    </div>
                </div>

                <div className={'flex justify-center gap-[30px] mt-[60px]'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                    <ButtonFilled onClick={handleCreateCommunity} text={'Հաստատել'} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    )
}

export default CreateCommunityModal