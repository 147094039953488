import React, {useEffect, useRef, useState} from 'react';
import {
    ActiveStudentsIcon,
    CheckedIcon,
    MenuIcon,
} from "../../../../../assets/icons";
import {useOutsideClick} from "../../../../../hooks/useOutsideClick";
import EditPassengersModal from "../modals/EditPassengersModal";
import {Link} from "react-router-dom";
import ConfirmationModal from "../../../../shared/ConfirmationModal";

const TripRouteItem = ({
                           _id,
                           route,
                           passengersSum,
                           passengers,
                           routeToRemove,
                           setRouteToRemove,
                           handleSelectTrips,
                           handleRemoveRoute,
                           selected,
                           refreshData,
                       }) => {
    const [showMenu, setShowMenu] = useState(false);
    const [editPassengersModal, setEditPassengersModal] = useState(null);
    const [showPassengerList, setShowPassengerList] = useState(false);
    const [listPosition, setListPosition] = useState('5px');
    const passengerListRef = useRef(null);

    const ref = useRef();
    useOutsideClick(ref, () => setShowMenu(null));

    const checked = selected?.find((selected) => selected?._id === _id);

    const removeRoute = () => {
        setRouteToRemove(_id);
        setShowMenu(null);
    };

    useEffect(() => {
        editPassengersModal ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
    }, [editPassengersModal]);

    useEffect(() => {
        if (showPassengerList && passengerListRef.current) {
            const rect = passengerListRef.current.getBoundingClientRect();
            if (rect.bottom > window.innerHeight) {
                setListPosition(`-${rect.height}px`);
            } else {
                setListPosition('20px');
            }
        }
    }, [showPassengerList]);

    const handleMouseEnter = () => {
        setListPosition('5px');
        setShowPassengerList(true);
    };

    const handleMouseLeave = () => {
        setShowPassengerList(false);
    };

    return (
        <>
            {editPassengersModal && (
                <EditPassengersModal
                    close={() => setEditPassengersModal(null)}
                    id={_id}
                    refreshData={refreshData}
                />
            )}

            {routeToRemove && (
                <ConfirmationModal
                    close={() => setRouteToRemove(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք հեռացնել այս երթուղին։`}
                    confirm={handleRemoveRoute}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            )}

            <div className={'flex items-center gap-5'}>
                <div
                    onClick={() => handleSelectTrips(_id)}
                    className={'w-[30px] h-[30px] flex items-center justify-center cursor-pointer border border-black border-opacity-70 rounded-[5px]'}
                >
                    {checked && <CheckedIcon/>}
                </div>
                <div className={'relative w-full min-h-[140px] custom-shadow rounded-[5px] pt-5 pb-[25px] px-[30px]'}>
                    <div
                        className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px] cursor-pointer`}></div>

                    <div className={'flex justify-between'}>
                        <div></div>
                        <div className={'flex gap-5'}>
                            <span className={'max-w-[567px] text-center'}>{route?.name}</span>
                        </div>
                        <div onClick={() => setShowMenu(!showMenu)} className={'cursor-pointer'}>
                            <MenuIcon/>
                        </div>
                    </div>
                    {showMenu && (
                        <div ref={ref}
                             className={`absolute z-[999] w-[203px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                            <p onClick={() => setEditPassengersModal(true)}
                               className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Խմբագրել
                            </p>
                            <p onClick={removeRoute}
                               className={'px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Հեռացնել երթուղին
                            </p>
                        </div>
                    )}

                    <div className={'w-full flex items-center mt-[30px] px-10'}>
                        <div className={'flex'}>
                            <div className={'relative group flex items-center gap-2.5'} onMouseEnter={handleMouseEnter}
                                 onMouseLeave={handleMouseLeave}>
                                <ActiveStudentsIcon color={'#FCC10F'}/>
                                <span className={'select-none'}>{passengersSum}</span>
                                <div ref={passengerListRef}
                                     className={`w-[350px] absolute z-[9999] max-h-[400px] overflow-y-auto flex flex-col gap-5 p-2.5 transition bg-white custom-shadow rounded-[3px] ${showPassengerList ? 'flex' : 'hidden'}`}
                                     style={{left: '70%', top: listPosition}}>
                                    {passengers?.map((passenger) =>
                                        passenger.type === 'student' ?
                                            <Link to={`/students/${passenger._id}?mode=view&subpage=profile`}
                                                  key={passenger._id}
                                                  className={'w-fit hover:text-[#FCC10F] transition'}>
                                                {passenger?.fullName}
                                            </Link>
                                            :
                                            <p key={passenger._id} className={'w-fit cursor-default'}>
                                                {passenger?.fullName}
                                            </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TripRouteItem;
