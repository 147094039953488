import React from 'react';
import {creator, genius, thinker, whizzKid, wizard} from "../../assets/images/SMART Degrees";
import {CheckInIcon} from "../../assets/icons";
import moment from "moment";

const CheckInCard = ({data, error}) => {
    const formattedCheckInDate = moment(data?.checkin).format('HH:mm')

    return (
        <div className={`max-w-[296px] min-h-[250px] custom-shadow rounded-[10px] ${(data?.status === 'no_lesson' || data?.status === 'student_is_not_in_group') && 'border-[3px] border-[#FF0707]'}`}>
            <div className={'flex gap-2.5 px-[50px]'}>
                <h2 className={'text-[#FCC10F] text-center pt-4'}>{data?.student?.fullName}</h2>
                <>
                    {data?.student?.currentSmartDegree === "thinker" &&
                        <img
                            src={thinker}
                            alt={'thinker'}
                            className={`w-[30px] h-[30px] select-none pt-2.5`}
                        />}
                    {data?.student?.currentSmartDegree === "creator" &&
                        <img
                            src={creator}
                            alt={'creator'}
                            className={`w-[30px] h-[30px] select-none pt-2.5`}
                        />}
                    {data?.student?.currentSmartDegree === "whizzKid" &&
                        <img
                            src={whizzKid}
                            alt={'whizzKid'}
                            className={`w-[30px] h-[30px] select-none pt-2.5`}
                        />}
                    {data?.student?.currentSmartDegree === "wizard" &&
                        <img
                            src={wizard}
                            alt={'wizard'}
                            className={`w-[30px] h-[30px] select-none pt-2.5`}
                        />}
                    {data?.student?.currentSmartDegree === "genius" &&
                        <img
                            src={genius}
                            alt={'genius'}
                            className={`w-[30px] h-[30px] select-none pt-2.5`}
                        />}
                </>
            </div>

            {data?.message && <p className={'text-[#FF0707] text-center mt-5'}>{data?.message}</p>}

            <div className={'flex items-center justify-center mt-5'}>
                <div className={'flex flex-col items-center'}>
                    <p className={'text-center'}>{data?.group?.name}</p>
                    <div className={`max-w-[70px] max-h-[70px] ${!data?.message && 'mt-2'}`}>
                        <img src={data?.student?.profileImage} alt={'profile_image'}
                             className={'w-full h-full rounded-full object-cover'}/>
                    </div>

                    <div className={'flex items-center gap-2.5 py-5'}>
                        {!data?.checkout
                            ?
                            <CheckInIcon />
                            :
                            <div className={'rotate-180'}><CheckInIcon color={'#FF0707'}/></div>
                        }

                        <div>{formattedCheckInDate}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckInCard;