import React from 'react';
import {Link, useNavigate} from "react-router-dom";
import {CheckedIcon, CloseIcon, GeoIcon, PhoneIcon, StopIcon, WriteNoteIcon} from "../../../../../assets/icons";

const PassengerItem = ({
                           trip,
                           editNoteIndex,
                           handleEditNote,
                           handleRemovePassenger,
                           handleToggleActive,
                           handleUpdateNote,
                           setEditNoteIndex,
                           setUpdatedNote,
                           updatedNote
                       }) => {
    const navigate = useNavigate()

    return (
        <div>
            <div className={'flex flex-col gap-[30px]'}>
                {trip?.stops.map((stop, stopIndex) =>
                    <div className={'flex flex-col gap-5'} key={stop._id}>
                        <h2 className={'flex items-center justify-center gap-5'}>
                            <StopIcon colour={'#FCC10F'}/>
                            <span>{stop.transportStop.name}</span>
                        </h2>

                        {stop.passengers.map((passenger, passengerIndex) =>
                            <div key={passenger._id} className={'relative flex items-center gap-5 px-[30px]'}>
                                {passenger.type === 'student'
                                    ?
                                    <div
                                        className={'w-5 h-5 flex items-center justify-center cursor-pointer border border-black border-opacity-70 rounded-[5px]'}
                                        onClick={() => handleToggleActive(passenger._id, stopIndex)}>
                                        {passenger.active && <CheckedIcon width={15} height={15}/>}
                                    </div>
                                    :
                                    <div onClick={() => handleRemovePassenger(passenger._id, stopIndex)}
                                         className={'w-5 h-5 flex items-center justify-center border border-black border-opacity-70 rounded-[5px] cursor-pointer'}>
                                        <CloseIcon width={15} height={15}/>
                                    </div>
                                }
                                <div
                                    className={`w-full h-[30px] grid grid-cols-4 rounded-[3px] custom-shadow-sm px-[30px] py-1 ${passenger.fromReplaced && 'bg-gray-200'}`}>
                                    <>
                                        <div className={'flex gap-5 col-span-2'}>
                                            <span>{passengerIndex + 1}</span>
                                            <div className="flex">
                                                <div className="relative group">
                                                    <div className={'relative inline-block'}>
                                                        {passenger.type === 'student'
                                                            ?
                                                            <Link
                                                                to={`/students/${passenger.passenger._id}?mode=view&subpage=profile`}
                                                                className={'hover:text-[#FCC10F] transition cursor-pointer w-full'}>{passenger.passenger?.fullName}</Link>
                                                            :
                                                            <span
                                                                className={'w-full'}>{passenger.passenger?.fullName}</span>
                                                        }
                                                        {passenger.type === 'student' &&
                                                            <div
                                                                className="absolute left-1/2 transform -translate-x-1/2 mb-2 bottom-full w-[239px] shadow-md custom-shadow transition z-[9999] rounded-[3px] bg-white pl-5 pr-[30px] py-[7px] group-hover:block hidden">
                                                                <div className="flex gap-[30px]">
                                                                    <div
                                                                        className="max-w-[30px] max-h-[30px] my-[15px]">
                                                                        <img src={passenger.passenger.profileImage}
                                                                             className="w-full h-full object-cover rounded-full"
                                                                             alt="profile-image"/>
                                                                    </div>
                                                                    <div
                                                                        className="flex flex-col gap-[5px] text-sm w-full">
                                                                        <div
                                                                            className="flex items-center gap-[15px]">
                                                                            <GeoIcon width={11} height={11}/>
                                                                            <span
                                                                                className={'text-[12px]'}>{passenger.passenger.community?.name}</span>
                                                                        </div>
                                                                        <div
                                                                            className="flex items-center gap-[15px]">
                                                                            <PhoneIcon width={11} height={11}/>
                                                                            <span
                                                                                className={'text-[12px] line-clamp-1'}>{passenger.passenger.phoneNumbers?.length && passenger.passenger.phoneNumbers[0]}</span>
                                                                        </div>
                                                                        <div
                                                                            className="flex items-center gap-[15px]">
                                                                            <PhoneIcon width={11} height={11}/>
                                                                            <span
                                                                                className={'text-[12px]'}>{passenger.passenger.phoneNumbers?.length && passenger.passenger.phoneNumbers[1]}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="relative flex items-center justify-center">
                                                                    <div
                                                                        className="w-2 h-2 bg-white absolute top-[calc(100%+1.1px)] transform rotate-[45deg]"></div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {passenger.group ?
                                            <div className={'text-[#FCC10F] cursor-pointer w-[300px]'}
                                                 onClick={() => navigate(`/groups/${passenger.group?._id}/attendance`)}>{passenger.group?.name}</div>
                                            :
                                            <div></div>
                                        }
                                        <div className={'flex justify-end'}>
                                            <div className={'flex group cursor-pointer fill-icon'}
                                                 onClick={() => handleEditNote(passenger._id, stopIndex)}>
                                                <WriteNoteIcon/>
                                                {(passenger.note || editNoteIndex === passenger._id) && (
                                                    <div
                                                        className="hidden group-hover:block absolute bottom-[calc(100%)] right-0 mr-[7px] z-[9999] pointer-events-auto transition bg-white py-[5px] px-2.5 custom-shadow rounded-[3px]">
                                                        <div
                                                            className="max-w-[200px] w-fit flex justify-center break-all">
                                                            {editNoteIndex === passenger._id
                                                                ?
                                                                <textarea
                                                                    className={'outline-none resize-none'}
                                                                    value={updatedNote}
                                                                    onChange={(e) => setUpdatedNote(e.target.value)}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter') {
                                                                            handleUpdateNote(passenger._id, stopIndex);
                                                                        }
                                                                    }}
                                                                    onBlur={() => setEditNoteIndex(-1)}
                                                                />
                                                                :
                                                                passenger.note
                                                            }
                                                        </div>
                                                        <div
                                                            className="relative flex items-center justify-end mr-[50px]">
                                                            <div
                                                                className="w-2 h-2 bg-white absolute top-[calc(100%)] transform rotate-[45deg]"></div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PassengerItem;