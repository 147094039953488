import React, {useEffect} from 'react';
import {Container} from "../shared";
import DropdownInputCheckbox from "../shared/dropdowns/DropdownInputCheckbox";
import {
    ageGroupData,
    clothingSizeData,
    gradeData,
    smartAwardsData,
    smartDegreesData,
    studentStatusData
} from "../../common/dropdownOptions";
import {useDispatch, useSelector} from "react-redux";
import SelectedFilters from "../shared/SelectedFilters";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";
import {getScheduleCodes} from "../../store/actions/common";
import {getStudentsFilters} from "../../store/actions/students";

export const genderFilters = [
    {_id: 'male', name: 'Արական'},
    {_id: 'female', name: 'Իգական'},
]

const StudentsFilters = ({filters, handleChange, changeSelectedFilters, submitFilters, discardFilters}) => {
    const {communities, programs, scheduleCodes} = useSelector(state => state.common)
    const {formNames} = useSelector(state => state.forms)
    const {studentFilters} = useSelector(state => state.students)

    const dispatch = useDispatch()

    const finishedProgramsCountData = programs?.map((item, i) => ({_id: i + 1, name: `${i + 1} ծրագիր`}))

    const handleEnter = (e) => {
        e.key === 'Enter' && submitFilters()
    }

    useEffect(() => {
        dispatch(getScheduleCodes())
        dispatch(getStudentsFilters())
    }, [])

    return (
        <div className={'custom-shadow mt-5'}>
            <Container>
                <div className={'flex flex-wrap gap-y-[27px] gap-x-3 mt-[14px] pl-[53px]'}>
                    <div className={'relative w-[165px]'}>
                        <div>
                            <input type={"text"}
                                   className={'w-full border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                                   name={'firstName'}
                                   value={filters?.firstName || ''}
                                   onKeyDown={handleEnter}
                                   onChange={handleChange}
                                   placeholder={"Անուն"}/>
                        </div>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <input type={"text"}
                               className={'w-full border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                               name={'lastName'}
                               value={filters?.lastName || ''}
                               onKeyDown={handleEnter}
                               onChange={handleChange}
                               placeholder={"Ազգանուն"}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <input type={"text"}
                               className={'w-full border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                               name={'middleName'}
                               value={filters?.middleName || ''}
                               onKeyDown={handleEnter}
                               onChange={handleChange}
                               placeholder={"Հայրանուն"}
                        />
                    </div>

                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={genderFilters} name={'gender'} placeholder={'Սեռ'}
                                               onChange={changeSelectedFilters}
                                               hideCheckbox={true}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               selected={filters?.gender}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={studentFilters?.ageFilters} name={'age'} placeholder={'Տարիք'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] p-[7px]'} selected={filters?.age}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={communities} name={'community'} placeholder={'Համայնք'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               selected={filters?.community}/>
                    </div>
                    <div className={'w-[165px]'}>
                        <input type={"text"}
                               className={'w-full border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                               name={'educationalEstablishment'}
                               value={filters?.educationalEstablishment || ''}
                               onKeyDown={handleEnter}
                               onChange={handleChange}
                               placeholder={"Դպրոց"}
                        />
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={gradeData} name={'grade'} placeholder={'Դասարան'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               selected={filters?.grade}/>
                    </div>
                    <div className={'w-[165px]'}>
                        <input type={"text"}
                               className={'w-full border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                               name={'group'}
                               value={filters?.group || ''}
                               onKeyDown={handleEnter}
                               onChange={handleChange}
                               placeholder={"Խումբ"}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={scheduleCodes} name={'schedule'} placeholder={'Գրաֆիկ'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               selected={filters?.schedule}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={studentStatusData} name={'status'} showCheckbox={false}
                                               placeholder={'Կարգավիճակ'}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               onChange={changeSelectedFilters} selected={filters?.status}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={ageGroupData} name={'ageGroup'} showCheckbox={false}
                                               placeholder={'Տարիքային խումբ'} hideCheckbox
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               onChange={changeSelectedFilters} selected={filters?.ageGroup}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={formNames} name={'formN'} placeholder={'Շաբաթ'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'} selected={filters?.formN}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={programs} name={'finishedPrograms'}
                                               placeholder={'Ավարտած ծրագիր'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               selected={filters?.finishedPrograms}/>
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={finishedProgramsCountData} name={'finishedProgramsCount'}
                                               placeholder={'Ավարտած ծրագրերի քանակ'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               hideCheckbox
                                               selected={filters?.finishedProgramsCount}/>
                    </div>
                    <div className={'w-[165px]'}>
                        <input type={"text"}
                               className={'w-full border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                               name={'email'}
                               value={filters?.email || ''}
                               onKeyDown={handleEnter}
                               onChange={handleChange}
                               placeholder={"Էլ․ փոստ"}
                        />
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={smartDegreesData} name={'degree'}
                                               placeholder={'Կոչումներ'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               selected={filters?.degree}
                        />
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={smartAwardsData} name={'awards'}
                                               placeholder={'Մրցանակ'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               selected={filters?.awards}
                        />
                    </div>
                    <div className={'relative w-[165px]'}>
                        <DropdownInputCheckbox data={clothingSizeData} name={'clothingSize'}
                                               placeholder={'Հագուստ'}
                                               onChange={changeSelectedFilters}
                                               optionClassName={'uppercase'}
                                               previewClassName={'py-[7px] rounded-[5px]'}
                                               previewUppercase={true}
                                               selected={filters?.clothingSize}
                        />
                    </div>
                </div>
                <div className={'flex justify-between pl-[53px] mt-[30px] mb-[14px]'}>
                    <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters}/>

                    <div className={'flex gap-3 items-center'}>
                        <ButtonOutlined
                            onClick={discardFilters}
                            text={'Չեղարկել'}
                        />
                        <ButtonFilled
                            onClick={() => submitFilters()}
                            text={'Կիրառել'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default StudentsFilters;