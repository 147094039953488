import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Container} from "../../../shared";
import DropdownInputCheckbox from "../../../shared/dropdowns/DropdownInputCheckbox";
import SelectedFilters from "../../../shared/SelectedFilters";
import {ButtonFilled, ButtonOutlined} from "../../../shared/buttons";
import {driverLicenseData} from "../../../../common/dropdownOptions";
import {getVehicles} from "../../../../store/actions/transport";
import {getVehicleFilters} from "../../../../store/actions/common";

const DriversFilter = ({handleChange, changeSelectedFilters, filters, discardFilters, submitFilters}) => {
    const {vehicleFilters} = useSelector(state => state.common)

    const dispatch = useDispatch()

    const handleEnter = (e) => {
        e.key === 'Enter' && submitFilters()
    }

    useEffect(() => {
        dispatch(getVehicleFilters())
    }, [])

    return (
        <div className={'custom-shadow mt-5'}>
            <Container>
                <div className={'flex flex-wrap gap-[26px] mt-[17px]'}>
                    <div className={'relative'}>
                        <div>
                            <input type={"text"}
                                   className={'w-[300px] border border-black outline-none rounded-[5px] py-[7px] pl-[30px]'}
                                   name={'name'}
                                   value={filters?.name || ''}
                                   onChange={handleChange}
                                   placeholder={"Վարորդի ԱԱՀ"}
                                   onKeyDown={handleEnter}
                            />
                        </div>
                    </div>

                    <div className={'relative w-[300px]'}>
                        <DropdownInputCheckbox data={driverLicenseData} name={'driverLicense'} placeholder={'Վարորդական կարգ'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                               optionClassName={'px-[30px]'}
                                               selected={filters?.driverLicense}/>
                    </div>

                    <div className={'relative w-[300px]'}>
                        <DropdownInputCheckbox data={''} name={'schedule'} placeholder={'Գրաֆիկ'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                               optionClassName={'px-[30px]'}
                                               selected={filters?.schedule}/>
                    </div>

                    <div className={'relative w-[300px]'}>
                        <DropdownInputCheckbox data={vehicleFilters?.markFilters} name={'mark'} placeholder={'Մեքենա'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                               optionClassName={'px-[30px]'}
                                               selected={filters?.mark}/>
                    </div>
                </div>

                <div className={'flex justify-between gap-3 pt-[30px] pb-[17px] pr-1'}>
                    <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters}/>

                    <div className={'flex gap-3 items-center'}>
                        <ButtonOutlined
                            onClick={discardFilters}
                            text={'Չեղարկել'}
                        />
                        <ButtonFilled
                            onClick={() => submitFilters()}
                            text={'Կիրառել'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default DriversFilter;