import React, {useEffect, useRef, useState} from 'react';
import {Container} from "../../../components/shared";
import {useDispatch, useSelector} from "react-redux";
import {createDriver} from "../../../store/actions/transport";
import {AsteriskIcon, CameraIcon, CloseIcon, FileUploadIcon} from "../../../assets/icons";
import {DropdownInputCheckbox} from "../../../components/shared/dropdowns";
import {ButtonFilled, ButtonOutlined} from "../../../components/shared/buttons";
import {getStatementProps} from "../../../store/actions/common";
import {createStatement} from "../../../store/actions/statements";
import {useNavigate} from "react-router-dom";

const initialState = {
    title: 'Հայտարարության վերնագիրը',
    theme: null,
    audience: [],
    link: '',
    linkName: '',
    media: [],
    body: '',
}

const CreateStatement = () => {
    const {statementProps} = useSelector(state => state.common)

    const [data, setData] = useState(initialState)
    const [uploadedImages, setUploadedImages] = useState([])
    const [validationErrors, setValidationErrors] = useState({})
    const [nameBeforeEdit, setNameBeforeEdit] = useState('')
    const [selectedTheme, setSelectedTheme] = useState(null)
    const [selectedAudience, setSelectedAudience] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const imageUploadRef = useRef(null)

    const combinedAudience = [
        ...(statementProps?.audience?.ageGroups || []),
        ...(statementProps?.audience?.genders || []),
        ...(statementProps?.audience?.groups || []),
        ...(statementProps?.audience?.programs || []),
        ...(statementProps?.audience?.scheduleCodes || []),
        ...(statementProps?.audience?.statusInGroup || []),
        ...(statementProps?.audience?.studentGlobalStatus || []),
    ]

    const handleChangeName = (e) => {
        setData({...data, title: e.target.value})
    }

    const handleStartChangeName = () => {
        setNameBeforeEdit(data.title)
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (data.name) {
                setNameBeforeEdit('')
            } else {
                setData({...data, name: nameBeforeEdit})
            }
        }
    }

    const handleImageUpload = (e) => {
        const selectedImages = Array.from(e.target.files)
        if (selectedImages.length) {
            setUploadedImages(prevImages => [...prevImages, ...selectedImages])
            setValidationErrors(prevErrors => ({
                ...prevErrors,
                image: ''
            }))
            e.target.value = null
        }
    }

    const handleRemoveImage = (index) => {
        setUploadedImages(prevImages => prevImages.filter((_, i) => i !== index))
    }

    const handleSelectTheme = (_, theme) => {
        setSelectedTheme(theme)
    }

    const handleSelectAudience = (_, audience) => {
        setSelectedAudience(prevAudience => {
            if (prevAudience.includes(audience)) {
                return prevAudience.filter(a => a !== audience)
            } else {
                return [...prevAudience, audience]
            }
        })
    }

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ''
        }));
    }

    const handleCreateStatement = (isPublic) => {
        const requiredFields = {
            theme: selectedTheme,
            audience: selectedAudience,
            body: data.body,
        }

        const newErrors = Object.keys(requiredFields).reduce((errors, field) => {
            if (!requiredFields[field] || (field === 'audience' && selectedAudience.length === 0)) {
                errors[field] = "Այս դաշտը պարտադիր է"
            }
            return errors;
        }, {})

        if (uploadedImages.length === 0) {
            newErrors.image = "Այս դաշտը պարտադիր է"
        }

        if (Object.keys(newErrors).length === 0) {
            const formData = new FormData()

            uploadedImages.forEach((image) => {
                formData.append('image', image)
            })

            formData.append('title', data.title)
            formData.append('theme', selectedTheme?._id)
            formData.append('audience', JSON.stringify(selectedAudience))
            data.link.length && formData.append('link', data.link)
            data.linkName.length && formData.append('linkName', data.linkName)
            formData.append('body', data.body)
            formData.append('published', isPublic)

            dispatch(createStatement(formData))

            navigate(`/statements?page=1&size=9&`)
        } else {
            setValidationErrors(newErrors);
        }
    }

    useEffect(() => {
        dispatch(getStatementProps())
    }, [])

    return (
        <div>
            <Container>
                <div className={'flex justify-center mb-2.5 mt-[14px]'}>
                    {nameBeforeEdit ?
                        <input
                            name="name"
                            className={'w-full text-center text-[28px] text-[#FCC10F] font-bold outline-none'}
                            autoComplete={'off'}
                            autoFocus
                            onFocus={e => e.target.select()}
                            value={data.title}
                            onChange={handleChangeName}
                            onKeyDown={handleEnter}
                        />
                        :
                        <h2 onClick={handleStartChangeName}
                            className={'text-center text-[28px] text-[#FCC10F] font-bold break-all'}>{data.title}</h2>
                    }
                </div>

                <p className={'flex gap-[15px] text-[#FF0707]'}>
                    <AsteriskIcon/>
                    <span>դաշտերը պարտադիր լրացման են</span>
                </p>

                <div className={'flex gap-[128px]'}>
                    <div className={'flex flex-col mt-[60px]'}>
                        <div onClick={() => imageUploadRef.current.click()}
                             className={`w-[200px] h-[200px] flex items-center justify-center cursor-pointer`}>
                            <input type={"file"} accept={'image/*'} className={'hidden'} id={"upload_image"}
                                   ref={imageUploadRef} onChange={handleImageUpload} multiple/>
                            <div
                                className={`w-full h-full flex items-center justify-center rounded-full`}>
                                <FileUploadIcon/>
                            </div>
                        </div>
                        <p className={'flex gap-1 justify-center my-[30px]'}>
                            <span>Վերբեռնել նկար</span>
                            <AsteriskIcon/>
                        </p>
                        {uploadedImages.length > 0 && (
                            <div className={'flex flex-col gap-[30px]'}>
                                {uploadedImages.map((image, index) => (
                                    <div key={index}
                                         className={"flex items-center justify-center bg-[#D9D9D940] rounded-[3px] text-black text-opacity-70 break-all"}>
                                        <div className={'w-full flex justify-between items-center px-1'}>
                                            <span>{image.name}</span>
                                            <span onClick={() => handleRemoveImage(index)} className="cursor-pointer">
                                                <CloseIcon width={10} height={10}/>
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {validationErrors?.image &&
                            <p className={'text-red-500 text-[14px]'}>{validationErrors.image}</p>}
                    </div>

                    <div className={'flex flex-col gap-[30px] mt-[30px]'}>
                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>Թեմա <AsteriskIcon/></p>
                            <div className={'w-[952px]'}>
                                <DropdownInputCheckbox
                                    data={statementProps?.themes}
                                    previewClassName={'py-[7px] rounded-[5px] px-[30px]'}
                                    optionClassName={'px-[30px]'}
                                    onChange={handleSelectTheme}
                                    name={'theme'}
                                    onFocus={() => handleFocus('theme')}
                                    selected={[selectedTheme]}
                                    hideCheckbox
                                />
                            </div>
                            {validationErrors?.theme &&
                                <p className={'text-red-500 text-[14px]'}>{validationErrors.theme}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>Թիրախային խումբ <AsteriskIcon/></p>
                            <div className={'w-[952px]'}>
                                <DropdownInputCheckbox
                                    data={combinedAudience}
                                    showSelected
                                    previewClassName={'py-[7px] rounded-[5px] px-[30px]'}
                                    optionClassName={'px-[30px]'}
                                    onChange={handleSelectAudience}
                                    name={'audience'}
                                    onFocus={() => handleFocus('audience')}
                                    selected={selectedAudience}
                                />
                            </div>
                            {validationErrors?.audience &&
                                <p className={'text-red-500 text-[14px]'}>{validationErrors.audience}</p>}
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>Ավելացնել հղում</p>
                            <div className={'w-[952px]'}>
                                <input
                                    className={'w-[952px] h-10 border border-black rounded-[5px] outline-none px-[30px]'}
                                    value={data.link}
                                    name={'link'}
                                    onChange={(e) => setData({...data, link: e.target.value})}
                                />
                            </div>
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>Հղման անվանումը</p>
                            <div className={'w-[952px]'}>
                                <input
                                    className={'w-[952px] h-10 border border-black rounded-[5px] outline-none px-[30px]'}
                                    value={data.linkName}
                                    name={'linkName'}
                                    placeholder={'օրինակ՝ ավելին, ընդունելության հայտ և այլն'}
                                    onChange={(e) => setData({...data, linkName: e.target.value})}
                                />
                            </div>
                        </div>

                        <div className={'flex flex-col gap-2.5'}>
                            <p className={'flex gap-1'}>Հայտարարության տեքստ <AsteriskIcon/></p>
                            <div className={'w-[952px]'}>
                                <textarea
                                    className={'w-full h-[417px] border border-black rounded-[5px] outline-none p-[30px] resize-none'}
                                    value={data.body}
                                    name={'body'}
                                    onFocus={() => handleFocus('body')}
                                    onChange={(e) => setData({...data, body: e.target.value})}
                                />
                            </div>
                            {validationErrors?.body &&
                                <p className={'text-red-500 text-[14px]'}>{validationErrors.body}</p>}
                        </div>

                        <div className={'flex items-center justify-end'}>
                            <div className={'w-fit flex gap-[30px] items-center mb-5'}>
                                <ButtonOutlined
                                    onClick={() => handleCreateStatement(false)}
                                    text={'Սևագիր'}
                                    className={'w-[176px]'}
                                />
                                <ButtonFilled
                                    onClick={() => handleCreateStatement(true)}
                                    text={'Պահպանել'}
                                    className={'w-[176px]'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default CreateStatement
