import React, {useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import {useOutsideClick} from '../../hooks/useOutsideClick';
import {ConfirmationModal} from '../shared';
import {CalendarIcon, FileIcon, MenuIcon, OpenedEyeIcon, SliderControlArrow, WriteNoteIcon} from '../../assets/icons';
import {Navigation} from 'swiper/modules';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {deleteStatement, duplicateStatement, selectStatement} from '../../store/actions/statements';
import {roles} from "../../common/roles";

const StatementCard = ({
                           _id,
                           title,
                           media,
                           theme,
                           link,
                           body,
                           published,
                           viewedBy,
                           author,
                           audience,
                           createdAt,
                           index
                       }) => {
    const {authData} = useSelector(state => state.auth)

    const [showMenu, setShowMenu] = useState(false)
    const [statementToDelete, setStatementToDelete] = useState(null)
    const [showAudienceList, setShowAudienceList] = useState(false)
    const [listPosition, setListPosition] = useState('5px')
    const audienceListRef = useRef(null)

    const modifiedDate = moment(createdAt).format('DD.MM.YYYY')

    const isStudent = authData.role === 'student'

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const ref = useRef(null)

    useOutsideClick(ref, () => setShowMenu(false))

    const queryParams = new URLSearchParams(location.search)
    const page = queryParams.get('page')
    const size = queryParams.get('size')

    const convertAudienceToArray = (audience) => {
        const audienceArray = []

        audience?.ageGroup && audienceArray.push({label: 'Տարիքային խումբ', value: audience.ageGroup})

        audience?.community?.length && audience.community.forEach(community => {
            audienceArray.push({label: 'Համայնք', value: community.name})
        })

        audience?.groups?.length && audience.groups?.forEach(group => {
            audienceArray.push({label: 'Խումբ', value: group.name})
        })

        audience?.program?.length && audience.program.forEach(program => {
            audienceArray.push({label: 'Ծրագիր', value: program.name})
        })

        audience?.schedule?.length && audience.schedule.forEach(schedule => {
            audienceArray.push({label: 'Գրաֆիկ', value: schedule})
        })

        audience?.gender && audienceArray.push({label: 'Սեռ', value: audience.gender || audience.gender.name})

        audience?.statusInGroup?.length && audience.statusInGroup.forEach(status => {
            audienceArray.push({label: 'Կարգավիճակ (խմբում)', value: status})
        })

        audience?.globalStatus && audienceArray.push({
            label: 'Կարգավիճակ (գլոբալ)',
            value: audience.globalStatus || audience.globalStatus.name
        })

        return audienceArray
    }

    const audienceArray = convertAudienceToArray(audience)

    const handleDuplicateStatement = () => {
        dispatch(duplicateStatement(_id, `?page=${page}&size=${size}&`))
    }

    const handleDeleteStatement = () => {
        dispatch(deleteStatement(_id, `?page=${page}&size=${size}&`))
    }

    const formatValue = (value) => {
        if (typeof value === 'object') {
            if (value?.name) {
                return value.name
            }
            return JSON.stringify(value, null, 2)
        }
        return value
    }

    const handleMouseEnter = () => {
        setListPosition('5px')
        setShowAudienceList(true)
    }

    const handleMouseLeave = () => {
        setShowAudienceList(false)
    }

    const handleSelectStatement = () => {
        const selectedStatementData = {
            _id,
            title,
            theme,
            media,
            body,
            link,
            published,
            viewedBy,
            author,
            audience,
            createdAt,
        }

        dispatch(selectStatement(selectedStatementData))
        navigate(`/statements/${_id}/edit`)
    }

    useEffect(() => {
        if (showAudienceList && audienceListRef.current) {
            const rect = audienceListRef.current.getBoundingClientRect()
            if (rect.bottom > window.innerHeight) {
                setListPosition(`-${rect.height}px`)
            } else {
                setListPosition('5px')
            }
        }
    }, [showAudienceList])

    return (
        <div>
            {statementToDelete && (
                <ConfirmationModal
                    close={() => setStatementToDelete(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ջնջել այս հայտարարությունը։`}
                    confirm={handleDeleteStatement}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            )}
            <div className="relative w-[345px] lg:w-[405px] max-h-[539px] lg:max-h-[585px] rounded-[5px] custom-shadow">
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px]`}></div>
                <div className="relative">
                    <Swiper
                        modules={[Navigation]}
                        navigation={{
                            nextEl: `.swiper-button-next-${index}`,
                            prevEl: `.swiper-button-prev-${index}`,
                        }}
                        loop
                        speed={600}
                    >
                        {media?.map((image, imgIndex) => (
                            <SwiperSlide key={image}>
                                <img
                                    src={image}
                                    alt="poster"
                                    className="w-[405px] h-[203px] object-cover rounded-t-[5px] filter select-none"
                                />
                                <div
                                    className="absolute inset-0 bg-black opacity-30 rounded-t-[5px] hover:opacity-0 transition"></div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {media?.length > 1 && (
                        <div className="slider-controller">
                            <div
                                className={`swiper-button-prev-${index} swiper-button-prev slider-arrow rotate-180 bg-black bg-opacity-70 rounded-full`}>
                                <SliderControlArrow/>
                            </div>
                            <div
                                className={`swiper-button-next-${index} swiper-button-next slider-arrow bg-black bg-opacity-70 rounded-full`}>
                                <SliderControlArrow/>
                            </div>
                        </div>
                    )}
                </div>

                {!published
                    ? <div className={'pt-2.5 pl-2.5'}><WriteNoteIcon/></div>
                    : <div className={'mt-5 lg:mt-[30px]'}></div>
                }

                {roles[authData?.role]?.some(role => role.statementActionsMenu) &&
                    <div
                        onClick={() => setShowMenu(!showMenu)}
                        className="absolute z-[999] top-0 right-0 p-4 text-white cursor-pointer"
                    >
                        <MenuIcon color={'#fff'}/>
                    </div>
                }

                {showMenu && (
                    <div
                        ref={ref}
                        className={`absolute z-[9999] w-[199px] h-[195px] ${roles[authData?.role]?.some(role => role.allowEditPublishedStatement) || published && 'h-[90px]'} top-[60px] flex flex-col gap-4 bg-white right-5 pt-[30px] border border-black rounded-[5px]`}
                    >
                        {roles[authData?.role]?.some(role => role.allowEditPublishedStatement) && published
                            &&
                            <p
                                onClick={handleSelectStatement}
                                className="px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer">
                                Խմբագրել
                            </p>
                        }
                        {!published
                            &&
                            <p
                                onClick={handleSelectStatement}
                                className="px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer">
                                Խմբագրել
                            </p>
                        }
                        <p
                            onClick={handleDuplicateStatement}
                            className="px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer"
                        >
                            Կրկնօրինակել
                        </p>
                        {!published
                            &&
                            <p
                                onClick={() => setStatementToDelete(true)}
                                className="px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer"
                            >
                                Ջնջել
                            </p>
                        }
                        {roles[authData?.role]?.some(role => role.allowEditPublishedStatement) && published
                            &&
                            <p
                                onClick={() => setStatementToDelete(true)}
                                className="px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer"
                            >
                                Ջնջել
                            </p>
                        }
                    </div>
                )}

                <div className={`flex flex-col gap-3 lg:gap-5`}>
                    <div
                        onClick={() => navigate(`/statements/${_id}`)}
                        className={'flex flex-col gap-2.5 lg:gap-5'}
                    >
                        <h2 className={'font-bold text-center px-2.5 hover:text-[#FCC10F] transition cursor-pointer'}>{title}</h2>
                        <p className={'max-h-[179px] lg:max-h-[200px] overflow-hidden px-2.5 lg:px-5 text-justify text-[13px] lg:text-[15px]'}>{body}</p>
                    </div>

                    <div className={'flex items-center justify-between px-5 pb-[30px]'}>
                        <div className={'flex flex-col gap-2.5 lg:gap-5'}>
                            <div className={'flex items-center gap-2.5'}>
                                <img alt={'profile-image'} src={author?.profileImage}
                                     className={'w-[15px] h-[15px] object-cover select-none rounded-full'}/>
                                <div className={'group relative'}>
                                    <Link to={''}
                                          className={'text-[12px] text-black text-opacity-70 hover:text-[#FCC10F] transition'}>{author?.fullName}</Link>
                                    <span
                                        className={'group-hover:block absolute z-[9999] left-full w-fit h-10 p-2.5 hidden text-[12px] custom-shadow bg-white rounded-[3px]'}>{author.position}</span>
                                </div>
                            </div>
                            <div className={'group relative flex items-center gap-2.5 cursor-pointer'}
                                 onMouseEnter={handleMouseEnter}
                                 onMouseLeave={handleMouseLeave}
                            >
                                <FileIcon/>
                                <p className={'text-[12px] text-black text-opacity-70'}>Թիրախային խումբ</p>
                                <div
                                    ref={audienceListRef}
                                    className={`absolute z-[99999] top-0 left-full mt-5 w-[148px] ${showAudienceList ? 'flex' : 'hidden'} flex-col gap-5 p-2.5 custom-shadow bg-white rounded-[3px]`}
                                    style={{top: listPosition}}
                                >
                                    {audienceArray.map((item, index) => {
                                        return (
                                            <p key={index} className={'text-[12px]'}>{formatValue(item.value)}</p>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={'flex flex-col gap-2.5 lg:gap-5'}>
                            <div className={'flex items-center gap-2.5'}>
                                <CalendarIcon width={15} height={15}/>
                                <p className={'text-[12px] text-black text-opacity-70'}>{modifiedDate}</p>
                            </div>
                            <div className={'flex items-center gap-2.5'}>
                                <OpenedEyeIcon width={15} height={15}/>
                                <p className={'text-[12px] text-black text-opacity-70'}>{viewedBy ? viewedBy : 0}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatementCard
