import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Container} from "../shared";
import {DropdownInputCheckbox} from "../shared/dropdowns";
import {groupStatusData} from "../../common/dropdownOptions";
import Calendar from "../shared/calendar/Calendar";
import SelectedFilters from "../shared/SelectedFilters";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";
import {getStatementProps} from "../../store/actions/common";

const StatementsFilter = ({
                              filters,
                              setFilters,
                              changeSelectedFilters,
                              discardFilters,
                              submitFilters,
                              handleChange,
                              type
                          }) => {
    const { statementProps } = useSelector(state => state.common)

    const combinedAudience = [
        ...(statementProps?.audience?.ageGroups || []),
        ...(statementProps?.audience?.genders || []),
        ...(statementProps?.audience?.groups || []),
        ...(statementProps?.audience?.programs || []),
        ...(statementProps?.audience?.scheduleCodes || []),
        ...(statementProps?.audience?.statusInGroup || []),
        ...(statementProps?.audience?.studentGlobalStatus || []),
    ]

    const dispatch = useDispatch()

    const handleEnter = (e) => {
        e.key === 'Enter' && submitFilters()
    }

    const handleChangeFromDate = (date) => {
        setFilters({
            ...filters,
            fromDate: date
        })
    }

    const handleChangeToDate = (date) => {
        setFilters({
            ...filters,
            toDate: date
        })
    }

    useEffect(() => {
        dispatch(getStatementProps())
    }, [])

    return (
        <div className={'custom-shadow'}>
            <Container>
                <div className={'relative flex flex-wrap gap-[7px] mt-[17px]'}>
                    <div className={'relative'}>
                        <div>
                            <input type={"text"}
                                   className={'w-[250px] border border-black outline-none rounded-[5px] py-[7px] pl-[30px]'}
                                   name={'title'}
                                   value={filters?.title || ''}
                                   onChange={handleChange}
                                   placeholder={'Անուն'}
                                   onKeyDown={handleEnter}
                            />
                        </div>
                    </div>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={statementProps?.themes} name={'theme'} showCheckbox={true}
                                               previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                               optionClassName={'px-[30px]'}
                                               placeholder={'Թեմա'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.theme}
                        />
                    </div>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={combinedAudience} name={'audience'} showCheckbox={true}
                                               placeholder={'Թիրախային խումբ'}
                                               previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                               optionClassName={'px-[30px]'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.audience}
                        />
                    </div>

                    <div className={'relative z-[9990] w-[250px] h-10'}>
                        <div
                            className={'absolute w-full border border-black border-opacity-70 py-[6px] rounded-[5px] bg-white'}>
                            <Calendar displayFormat={'DD.MM.YYYY'}
                                      returnFormat={'YYYY-MM-DD'}
                                      placeholder={'Սկիզբ'}
                                      className={'py-[1px]'}
                                      paddingClassName={'px-0'}
                                      optionClassName={'px-2'}
                                      gapClassName={'gap-1'}
                                      textSize={'text-[12px]'}
                                      showShortMonths={true}
                                      setDate={handleChangeFromDate}
                                      value={filters?.fromDate}
                            />
                        </div>
                    </div>

                    <div className={'relative z-[9990] w-[250px] h-10'}>
                        <div
                            className={'absolute w-full border border-black border-opacity-70 py-[6px] rounded-[5px] bg-white'}>
                            <Calendar displayFormat={'DD.MM.YYYY'}
                                      returnFormat={'YYYY-MM-DD'}
                                      placeholder={'Ավարտ'}
                                      className={'py-[1px]'}
                                      paddingClassName={'px-0'}
                                      optionClassName={'px-2'}
                                      gapClassName={'gap-1'}
                                      textSize={'text-[12px]'}
                                      showShortMonths={true}
                                      setDate={handleChangeToDate}
                                      value={filters?.toDate}
                            />
                        </div>
                    </div>
                </div>

                <div className={'flex justify-between gap-3 pt-[30px] pb-[17px] mr-1'}>
                    <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters}/>

                    <div className={'flex gap-3 items-center'}>
                        <ButtonOutlined
                            onClick={discardFilters}
                            text={'Չեղարկել'}
                        />
                        <ButtonFilled
                            onClick={() => submitFilters()}
                            text={'Կիրառել'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default StatementsFilter;