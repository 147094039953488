import React, { useEffect, useState } from 'react';
import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import MessageTypingArea from "./MessageTypingArea";
import { useConnectToChat } from "../../../hooks/socket/useChats";
import { useDispatch, useSelector } from "react-redux";
import {pinUnpinMessage, deleteMessage, markMessagesAsSeen, getNewMessagesCount} from "../../../socket/chats";
import { setReplyingMessage } from "../../../store/actions/chats";
import {useMediaQuery} from "react-responsive";

const Chat = () => {
    const { socket } = useSelector(state => state.socket)
    const { chatRoom, chat, messages, replyingMessage, chats } = useSelector(state => state.chats)
    const {authData} = useSelector((state) => state.auth)

    console.log(chat)
    const [openChatMediaViewer, setOpenChatMediaViewer] = useState(null)
    const [replyToMessage, setReplyToMessage] = useState(null)
    const [replyingTo, setReplyingTo] = useState(replyingMessage)
    const [isMediaOpen, setIsMediaOpen] = useState(null)
    const [typingAreaHeight, setTypingAreaHeight] = useState(20)
    const [pinnedMessages, setPinnedMessages] = useState([])
    const [showPinnedMessages, setShowPinnedMessages] = useState(null)
    const [editMessage, setEditMessage] = useState(null)
    const [isEditingMessage, setIsEditingMessage] = useState(null)
    const [showMenu, setShowMenu] = useState(false)
    const [openMenuMessageId, setOpenMenuMessageId] = useState(null)

    const { connectChatRoom } = useConnectToChat(socket)
    const dispatch = useDispatch()
    const isPhone = useMediaQuery({maxWidth: 640})

    const handleMenuClick = (messageId) => {
        setOpenMenuMessageId(prevId => (prevId === messageId ? null : messageId))
    }

    useEffect(() => {
        if (chat) {
            connectChatRoom(chat._id, chatRoom)
        }
    }, [chat, connectChatRoom, chatRoom])

    const handlePinUnpinMessage = (messageId) => {
        const messageIndex = messages.findIndex(message => message._id === messageId)
        if (messageIndex !== -1) {
            const updatedMessages = [...messages]
            updatedMessages[messageIndex].pinned = !updatedMessages[messageIndex].pinned

            setPinnedMessages(updatedMessages.filter(message => message.pinned))

            pinUnpinMessage(socket, messageId).catch(() => {
                updatedMessages[messageIndex].pinned = !updatedMessages[messageIndex].pinned
                setPinnedMessages(updatedMessages.filter(message => message.pinned))
            })
        }
    }

    const handleReplyToMessage = (messageObj) => {
        setReplyToMessage(true)
        setReplyingTo(messageObj)
        setOpenMenuMessageId(null)
        dispatch(setReplyingMessage(messageObj))
    }

    const handleDeleteMessage = (messageId) => {
        deleteMessage(socket, messageId).then(() => {
            if (replyingTo?._id === messageId) {
                setReplyToMessage(null)
                setReplyingTo(null)
                dispatch(setReplyingMessage(null))
            }
        })
    }

    useEffect(() => {
        setPinnedMessages(messages?.filter(message => message.pinned))
    }, [messages])

    useEffect(() => {
        if(chat) {
            markMessagesAsSeen(socket, chat._id, dispatch)
        }
    }, [socket, chat, messages])

    return (
        <div
            className={`relative w-full h-full grid ${authData.role === 'student' ? 'grid-rows-[70px,1fr]' : 'grid-rows-[88px,1fr]'} lg:grid-rows-[94px,1fr] ${openMenuMessageId && isPhone ? 'blur-background' : ''}`}
        >
            <ChatHeader
                {...chat}
                pinnedMessages={pinnedMessages}
                setOpenChatMediaViewer={setOpenChatMediaViewer}
                setShowPinnedMessages={setShowPinnedMessages}
                openChatMediaViewer={openChatMediaViewer}
            />
            <ChatBody
                openChatMediaViewer={openChatMediaViewer}
                setOpenChatMediaViewer={setOpenChatMediaViewer}
                replyToMessage={replyToMessage}
                setReplyToMessage={setReplyToMessage}
                setReplyingTo={setReplyingTo}
                handleReplyToMessage={handleReplyToMessage}
                typingAreaHeight={typingAreaHeight}
                handlePinUnpinMessage={handlePinUnpinMessage}
                handleDeleteMessage={handleDeleteMessage}
                showPinnedMessages={showPinnedMessages}
                setShowPinnedMessages={setShowPinnedMessages}
                setPinnedMessages={setPinnedMessages}
                setIsEditingMessage={setIsEditingMessage}
                chatType={chat?.type}
                userCanSendMessage={chat?.userCanSendMessage}
                chatName={chat?.name}
                chatImage={chat?.image}
                pinnedMessagesCount={pinnedMessages?.length}
                chats={chats}
                chat={chat}
                setEditMessage={setEditMessage}
                isEditingMessage={isEditingMessage}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
                openMenuMessageId={openMenuMessageId}
                handleMenuClick={handleMenuClick}
                setOpenMenuMessageId={setOpenMenuMessageId}
                isMediaOpen={isMediaOpen}
                setIsMediaOpen={setIsMediaOpen}
            />
            <MessageTypingArea
                id={chat?._id}
                userCanSendMessage={chat?.userCanSendMessage}
                replyToMessage={replyToMessage}
                replyingTo={replyingTo}
                setReplyToMessage={setReplyToMessage}
                setReplyingTo={setReplyingTo}
                onHeightChange={setTypingAreaHeight}
                typingAreaHeight={typingAreaHeight}
                isEditingMessage={isEditingMessage}
                setEditMessage={setEditMessage}
                setIsEditingMessage={setIsEditingMessage}
                openChatMediaViewer={openChatMediaViewer}
                editMessage={editMessage}
                isMediaOpen={isMediaOpen}
            />
        </div>
    );
};

export default Chat;
