import {decryptMessage, encryptMessage} from "../encryption";
import {
    setChatRoom,
    setMediaOfChat,
    setPreparedChat,
    setSearchedChats
} from "../../store/actions/chats";
import {
    GET_NEW_MESSAGES_COUNT,
    GO_TO_MESSAGE_FETCHED_FROM_SERVER,
    LOAD_MORE_MESSAGES
} from "../../store/constants/actionTypes";
import {v4 as uuidv4} from 'uuid'

export const getNewMessagesCount = async (socket, dispatch) => {
    const cb = async (result) => {
        result = await decryptMessage(result)

        dispatch({type: GET_NEW_MESSAGES_COUNT, payload: result})
    }

    if (socket) {
        const obj = {
            type: 'new_messages_count'
        }

        const encryptedMessage = await encryptMessage(obj)

        socket.emit('msg', encryptedMessage, cb)
    }
}

export const prepareNewChat = async (socket, receiver, navigate, dispatch) => {
    const cb = async (result) => {
        result = await decryptMessage(result)

        dispatch(setPreparedChat(result.chat))
        dispatch(setChatRoom(result.chatId))
        navigate(`/chats/${result.chatId}`)
    }

    if (socket) {
        const obj = {
            type: 'prepare_chat',
            data: {
                receiver
            }
        }

        const encryptedMessage = await encryptMessage(obj)

        socket.emit('msg', encryptedMessage, cb)
    }
}

export const sendMessage = async (socket, message, chatId, repliesTo) => {
    if (socket) {
        const obj = {
            type: 'send_message',
            data: {
                chatId,
                message,
                repliesTo
            }
        }

        const encryptedMessage = await encryptMessage(obj)

        socket.emit('msg', encryptedMessage)
    }
}

export const deleteMessage = async (socket, messageId) => {
    const cb = async (response) => {
        const decryptedMessage = await decryptMessage(response)

        console.log(decryptedMessage)
    }

    if (socket) {
        const obj = {
            type: 'delete_message',
            data: {
                messageId
            }
        }

        const encryptedMessage = await encryptMessage(obj)

        socket.emit('msg', encryptedMessage, cb)
    }
}

export const pinUnpinMessage = async (socket, messageId) => {
    const cb = async (response) => {
        const decryptedMessage = await decryptMessage(response)

        console.log(decryptedMessage)
    }

    if (socket) {
        const obj = {
            type: 'pin_unpin_message',
            data: {
                messageId
            }
        }

        const encryptedMessage = await encryptMessage(obj)

        socket.emit('msg', encryptedMessage, cb)
    }
}

export const unpinAllMessages = async (socket, chatId) => {
    const cb = async (response) => {
        const decryptedMessage = await decryptMessage(response)

        console.log(decryptedMessage)
    }

    if (socket) {
        const obj = {
            type: 'unpin_all_messages',
            data: {
                chatId
            }
        }

        const encryptedMessage = await encryptMessage(obj)

        socket.emit('msg', encryptedMessage, cb)
    }
}

export const getMediaOfChat = async (socket, chatId, filesType, dispatch) => {
    try {
        const cb = async (response) => {
            const decryptedMessage = await decryptMessage(response)
            dispatch(setMediaOfChat(decryptedMessage.media))
        }

        if (socket) {
            const obj = {
                type: 'get_media_of_chat',
                data: {
                    chatId,
                    filesType
                }
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e)
    }
}

export const markMessagesAsSeen = async (socket, chatId, dispatch) => {
    try {
        const cb = async (response) => {
            response = await decryptMessage(response)

            getNewMessagesCount(socket, dispatch)
        }

        if (socket) {
            const obj = {
                type: 'mark_messages_as_seen',
                data: {
                    chatId
                }
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e)

    }
}

export const goToMessage = async (socket, chatId, messageId, dispatch, scrollToMessageBlock) => {
    try {
        const cb = async (response) => {
            response = await decryptMessage(response)

            dispatch({type: GO_TO_MESSAGE_FETCHED_FROM_SERVER, payload: response})

            setTimeout(() => {
                scrollToMessageBlock()
            }, 0.2)
        }

        if (socket) {
            const obj = {
                type: 'go_to_message',
                data: {
                    chatId,
                    messageId
                }
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e)
    }
}

export const loadMoreMessages = async (socket, type, chatId, messageId, dispatch, setMessagesLoading) => {
    try {
        const cb = async (response) => {
            response = await decryptMessage(response)

            dispatch({type: LOAD_MORE_MESSAGES, payload: {...response, type}})

            setMessagesLoading && setMessagesLoading(false)
        }

        if (socket) {
            const obj = {
                type: type === 'earlier' ? 'load_earlier_messages' : 'load_next_messages',
                data: {
                    chatId,
                    messageId
                }
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e)
    }
}

export const searchChats = async ({
                                      socket,
                                      searchValue,
                                      includeExistingChats,
                                      includeGroups,
                                      includeNewUsers,
                                      dispatch
                                  }) => {
    try {
        const chatsSearchQueryId = uuidv4()

        dispatch({type: 'SET_SEARCH_CHATS_QUERY_ID', payload: {chatsSearchQueryId}})

        const cb = async (response) => {
            response = await decryptMessage(response)

            dispatch(setSearchedChats({...response, chatsSearchQueryId}))
        }

        if (socket) {
            const obj = {
                type: 'search_chats',
                data: {
                    pattern: searchValue,
                    includeExistingChats,
                    includeGroups,
                    includeNewUsers,
                }
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e)
    }
}

export const forwardMessage = async (socket, messageId, mediaId, receivers) => {
    try {
        const cb = async (response) => {
            const decryptedMessage = await decryptMessage(response)

            console.log(decryptedMessage)
        }

        if (socket) {
            const obj = {
                type: 'forward_message',
                data: {
                    type: mediaId ? 'singleMedia' : 'message',
                    messageId,
                    mediaId,
                    receivers
                }
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e)
    }
}

export const createGroupChat = async (socket, name, users) => {
    try {
        const cb = async (response) => {
            const decryptedMessage = await decryptMessage(response)

            console.log(decryptedMessage)
        }

        if (socket) {
            const obj = {
                type: 'create_group_chat',
                data: {
                    name: name,
                    users
                }
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e)
    }
}

export const reactToMessage = async (socket, messageId, emojiCode) => {
    try {
        const cb = async (response) => {
            const decryptedMessage = await decryptMessage(response)

            console.log(decryptedMessage)
        }

        if (socket) {
            const obj = {
                type: 'react_to_message',
                data: {
                    messageId: messageId,
                    reaction: emojiCode
                }
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e)
    }
}

export const deleteChat = async (socket, chatId) => {
    try {
        const cb = async (response) => {
            const decryptedMessage = await decryptMessage(response)

            console.log(decryptedMessage)
        }

        if (socket) {
            const obj = {
                type: 'delete_chat',
                data: {
                    chatId
                }
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e)
    }
}

export const editSelectedMessage = async (socket, messageId, content, dispatch) => {
    try {
        const cb = async (response) => {
            const decryptedMessage = await decryptMessage(response)

            dispatch({
                type: 'UPDATE_EDITED_MESSAGE',
                payload: {
                    chatId: decryptedMessage.data.chatId,
                    message: decryptedMessage.data.message,
                },
            })
        }

        if (socket) {
            const obj = {
                type: 'edit_message',
                data: {
                    messageId,
                    editedMessageContent: content,
                },
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg', encryptedMessage, cb)
        }
    } catch (e) {
        console.log(e);
    }
}

export const sendFileAsVoiceMessage = async (socket, chatId, repliesTo, name, file) => {
    try {
        const cb = async (response) => {
            const decryptedMessage = await decryptMessage(response)

            console.log(decryptedMessage)
        }

        if (socket) {
            const obj = {
                type: 'voice_message',
                data: {
                    chatId,
                    repliesTo,
                    fileData: {
                        name,
                        id: uuidv4()
                    }
                },
            }

            const encryptedMessage = await encryptMessage(obj)

            socket.emit('msg_file', {data: encryptedMessage, file: file}, cb)
        }
    } catch (e) {
        console.log(e);
    }
}