import React, {useEffect, useRef, useState} from 'react';
import {AsteriskIcon, CameraIcon} from "../../../../../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {editDriver} from "../../../../../../store/actions/transport";
import Calendar from "../../../../../../components/shared/calendar/Calendar";
import {Container, PhoneInput} from "../../../../../../components/shared";
import DropdownInputCheckbox from "../../../../../../components/shared/dropdowns/DropdownInputCheckbox";
import {driverLicenseData} from "../../../../../../common/dropdownOptions";
import {ButtonFilled, ButtonOutlined} from "../../../../../../components/shared/buttons";
import {getVehicleMarks} from "../../../../../../store/actions/common";
import {useNavigate} from "react-router-dom";

const DriverEdit = ({ onDriverUpdated }) => {
    const { driver } = useSelector(state => state.transport)
    const {vehicleMarks} = useSelector(state => state.common)

    const [data, setData] = useState(driver)
    const [selectedImage, setSelectedImage] = useState(null)
    const [validationErrors, setValidationErrors] = useState({})

    const imageUploadRef = useRef(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleImageUpload = (e) => {
        const file = e.target.files[0]

        if (file) {
            setSelectedImage(file)
        } else {
            console.log('No image selected');
        }
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const changeBirthday = (e) => {
        setData({ ...data, birthday: e })
    }

    const changePhoneNumber = (value, index) => {
        const updatedPhoneNumbers = [...data?.phoneNumbers]

        if (value.trim() !== '') {
            updatedPhoneNumbers[index] = value
        } else {
            updatedPhoneNumbers[index] = undefined
        }

        const filteredPhoneNumbers = updatedPhoneNumbers.filter(number => number)

        setData({
            ...data,
            phoneNumbers: filteredPhoneNumbers
        })
    }

    const handleAddDriverLicense = (_, value) => {
        const isSelected = data.driverLicense?.includes(value._id)

        if (isSelected) {
            const updatedLicenses = data.driverLicense?.filter(license => license !== value._id)
            setData({
                ...data,
                driverLicense: updatedLicenses,
            })
        } else {
            setData({
                ...data,
                driverLicense: [...data.driverLicense, value._id],
            })
        }
    }

    const handleSelectOption = (field, selectedOptions) => {
        if (field === 'vehicle1') {
            setData(prevData => ({
                ...prevData,
                vehicles: [
                    selectedOptions,
                    prevData.vehicles[1]
                ].filter(Boolean)
            }));
        } else if (field === 'vehicle2') {
            setData(prevData => ({
                ...prevData,
                vehicles: [
                    prevData.vehicles[0],
                    selectedOptions
                ].filter(Boolean)
            }));
        }
    }

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ''
        }));
    }

    const handleSubmit = () => {
        // const requiredFields = {
        //     image: selectedImage,
        //     fullName: data.fullName,
        //     birthday: data.birthday,
        //     registrationAddress: data.registrationAddress,
        //     address: data.address,
        //     passport: data.passport,
        //     email: data.email,
        //     phoneModel: data.phoneModel,
        //     driverLicense: data.driverLicense.length ? data.driverLicense : null,
        //     vehicles: data.vehicles[0] ? data.vehicles : null,
        // }
        //
        // const newErrors = Object.keys(requiredFields).reduce((errors, field) => {
        //     if (!requiredFields[field]) {
        //         errors[field] = "Այս դաշտը պարտադիր է";
        //     }
        //     return errors;
        // }, {})

        const formattedVehicles = data.vehicles.map(vehicle => vehicle._id)

        // if (Object.keys(newErrors).length === 0) {
            const formData = new FormData()
            formData.append('image', selectedImage)
            formData.append('fullName', data.fullName)
            formData.append('birthday', data.birthday)
            formData.append('registrationAddress', data.registrationAddress)
            formData.append('address', data.address)
            formData.append('email', data.email)
            formData.append('passport', data.passport)
            formData.append('phoneModel', data.phoneModel)
            formData.append('driverLicense', JSON.stringify(data?.driverLicense))
            formData.append('phoneNumbers', JSON.stringify(data.phoneNumbers))
            formData.append('vehicles', JSON.stringify(formattedVehicles))

            dispatch(editDriver(driver._id, formData)).then(() => {
                onDriverUpdated();
                navigate(`/transport/drivers/${driver._id}?mode=view&subpage=profile`);
            })

        // } else {
        //     setValidationErrors(newErrors)
        // }
    }

    useEffect(() => {
        if (driver) {
            setData(driver)
        }
    }, [driver])

    useEffect(() => {
        dispatch(getVehicleMarks())
    }, [])

    return (
        <Container>
            <div className={'grid grid-cols-[328px,624px] mt-[14px]'}>
                <div className={'w-fit h-fit relative group'}>
                    <div>
                        <img
                            src={selectedImage ? URL.createObjectURL(selectedImage) : (data?.profileImage || '')}
                            className={'object-cover rounded-full aspect-square w-[200px] h-[200px] select-none pointer-events-none'}
                            alt="profile"
                        />
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        style={{display: 'none'}}
                        ref={imageUploadRef}
                    />
                    <button
                        title={'Վերբեռնել նկար'}
                        className={'absolute right-0 fill-icon-white bottom-3 shadow-md p-2 rounded-full group-hover:opacity-100 opacity-0 transition z-50 bg-[#fff] border border-[#FCC10F] hover:bg-[#FCC10F]'}
                        onClick={() => imageUploadRef.current.click()}
                    >
                        <CameraIcon color={'#FCC10F'}/>
                    </button>
                </div>
                <div className={'flex flex-col gap-5'}>
                    <div className={'flex flex-col gap-2.5'}>
                        <p className={'flex gap-1'}>
                            <span>Անուն, ազգանուն, հայրանուն</span>
                            <AsteriskIcon/>
                        </p>
                        <input
                            type={'text'}
                            name={'fullName'}
                            value={data?.fullName}
                            onChange={handleChange}
                            onFocus={() => handleFocus('fullName')}
                            className={'w-full h-10 border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                        />
                        {validationErrors.fullName && <p className={'text-red-500'}>{validationErrors.fullName}</p>}
                    </div>
                    <div className={'relative flex flex-col gap-2.5'}>
                        <p className={'flex gap-1'}>
                            <span>Ծննդյան օր, ամիս, տարի </span>
                            <AsteriskIcon/>
                        </p>
                        <div
                            onFocus={() => handleFocus('birthday')}
                            className={'absolute w-full top-full border border-black py-[7px] rounded-[3px] mt-2.5 z-[9990]'}>
                            <Calendar value={data?.birthday} displayFormat={'DD.MM.YYYY'}
                                      returnFormat={'YYYY-MM-DD'} setDate={changeBirthday}
                                      optionClassName={'px-[30px]'}
                                      onChange={handleChange}/>
                            {validationErrors.birthday &&
                                <p className={'absolute top-full text-red-500 pt-2.5'}>{validationErrors.birthday}</p>}
                        </div>
                    </div>

                    <div className={`flex flex-col gap-2.5 ${validationErrors.birthday ? 'mt-[65px]' : 'mt-[50px]'}`}>
                        <p className={'flex gap-1'}>
                            <span>Գրանցման հասցե </span>
                            <AsteriskIcon/>
                        </p>
                        <input
                            type={"text"}
                            name={"registrationAddress"}
                            value={data?.registrationAddress}
                            onChange={handleChange}
                            onFocus={() => handleFocus('registrationAddress')}
                            className={'w-full h-10 border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                        />
                        {validationErrors.registrationAddress &&
                            <p className={'text-red-500'}>{validationErrors.registrationAddress}</p>}
                    </div>

                    <div className={'flex flex-col gap-2.5'}>
                        <p className={'flex gap-1'}>
                            <span>Բնակության հասցե </span>
                            <AsteriskIcon/>
                        </p>
                        <input
                            type={"text"}
                            name={"address"}
                            value={data?.address}
                            onChange={handleChange}
                            onFocus={() => handleFocus('address')}
                            className={'w-full h-10 border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                        />
                        {validationErrors.address && <p className={'text-red-500'}>{validationErrors.address}</p>}
                    </div>

                    <div className={'flex flex-col gap-2.5 '}>
                        <p className={'flex gap-1'}>
                            <span>Անձնագիր </span>
                            <AsteriskIcon/>
                        </p>
                        <input
                            type={"text"}
                            name={"passport"}
                            value={data?.passport}
                            onChange={handleChange}
                            onFocus={() => handleFocus('passport')}
                            className={'w-full h-10 border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                        />
                        {validationErrors.passport && <p className={'text-red-500'}>{validationErrors.passport}</p>}
                    </div>

                    <div className={'relative flex flex-col gap-5'}>
                        {data?.phoneNumbers?.map((phoneNumber, index) => (
                            <div key={index} className={'relative'}>
                                <label htmlFor={'phoneNumber'}
                                       className={'flex items-center gap-1.5'}><span>Հեռախոսահամար {index + 1}</span>
                                    <AsteriskIcon/>
                                </label>
                                <div
                                    onFocus={() => handleFocus('phoneNumber')}
                                    className={'border border-black rounded-[5px] py-[2px] mt-2.5'}>
                                    <PhoneInput answer={phoneNumber}
                                                onChange={changePhoneNumber}
                                                name={index}/>
                                </div>
                                {validationErrors.phoneNumbers &&
                                    <p className={'text-red-500 mt-2.5'}>{validationErrors.phoneNumbers}</p>}
                            </div>
                        ))}
                    </div>

                    <div className={'flex flex-col gap-2.5'}>
                        <p className={'flex gap-1'}>
                            <span>Էլեկտրոնային փոստ </span>
                            <AsteriskIcon/>
                        </p>
                        <input
                            type={"email"}
                            name={'email'}
                            autoComplete={'off'}
                            value={data?.email}
                            onChange={handleChange}
                            onFocus={() => handleFocus('email')}
                            className={'w-full h-10 border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                        />
                        {validationErrors.email && <p className={'text-red-500'}>{validationErrors.email}</p>}
                    </div>

                    <div className={'flex flex-col gap-2.5'}>
                        <p className={'flex gap-1'}>
                            <span>Վարորդական վկայական </span>
                            <AsteriskIcon/>
                        </p>
                        <DropdownInputCheckbox
                            data={driverLicenseData}
                            name={'driverLicense'}
                            onChange={handleAddDriverLicense}
                            previewClassName={'rounded-[3px] py-[4px] px-[30px]'}
                            optionClassName={'px-[30px]'}
                            showSelected
                            type={'driverLicense'}
                            onFocus={() => handleFocus('driverLicense')}
                            selected={driverLicenseData?.filter(option => data?.driverLicense?.includes(option._id))}
                        />
                        {validationErrors.driverLicense &&
                            <p className={'text-red-500'}>{validationErrors.driverLicense}</p>}
                    </div>

                    <div className={'flex flex-col gap-2.5'}>
                        <p className={'flex gap-1'}>
                            <span>SMART հեռախոսի մոդել </span>
                            <AsteriskIcon/>
                        </p>
                        <input
                            type={"text"}
                            name={"phoneModel"}
                            value={data?.phoneModel}
                            onChange={handleChange}
                            onFocus={() => handleFocus('phoneModel')}
                            className={'w-full h-10 border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                        />
                        {validationErrors.phoneModel &&
                            <p className={'text-red-500'}>{validationErrors.phoneModel}</p>}
                    </div>

                    <div className={'flex flex-col gap-2.5'}>
                        <p className={'flex gap-1'}>
                            <span>Մեքենայի մակնիշ 1 </span>
                            <AsteriskIcon/>
                        </p>
                        <DropdownInputCheckbox
                            data={vehicleMarks}
                            name={'vehicle1'}
                            onChange={handleSelectOption}
                            previewClassName={'rounded-[3px] py-[7px] px-[30px]'}
                            optionClassName={'px-[30px]'}
                            selected={data?.vehicles[0] ? [data?.vehicles[0]] : []}
                            onFocus={() => handleFocus('vehicles')}
                        />
                        {validationErrors.vehicles && <p className={'text-red-500'}>{validationErrors.vehicles}</p>}
                    </div>

                    <div className={'flex flex-col gap-2.5'}>
                        <p className={'flex gap-1'}>
                            <span>Մեքենայի մակնիշ 2 </span>
                        </p>
                        <DropdownInputCheckbox
                            data={vehicleMarks}
                            name={'vehicle2'}
                            onChange={handleSelectOption}
                            previewClassName={'rounded-[3px] py-[7px] px-[30px]'}
                            optionClassName={'px-[30px]'}
                            selected={data?.vehicles[1] ? [data?.vehicles[1]] : []}
                        />
                    </div>

                    <div className={'flex items-center justify-end gap-[30px] pb-5 pt-[30px]'}>
                        <ButtonFilled onClick={handleSubmit} text={'Պահպանել'}
                                      className={'w-[176px] shadow-md'}/>
                        <ButtonOutlined
                            onClick={() => navigate(`/transport/drivers/${driver._id}?mode=view&subpage=profile`)}
                            text={'Չեղարկել'} className={'w-[176px] shadow-md'}/>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default DriverEdit;
