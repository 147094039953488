import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Route, Routes, useParams} from "react-router-dom";
import {getProgram} from "../../../store/actions/programs";
import {Container} from "../../../components/shared";
import ProgramHeader from "../../../components/programs/program/ProgramHeader";
import ProgramNavbar from "../../../components/programs/program/ProgramNavbar";
import Groups from "./subpages/Groups";
import Projects from "./subpages/Projects";
import CreateProgramModal from "../../../components/programs/CreateProgramModal";

const Program = () => {
    const {program, loading} = useSelector(state => state.programs)

    const [editProgramModal, setEditProgramModal] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const handleEditProgram = (program) => {
        setEditProgramModal(program)
    }

    useEffect(() => {
        dispatch(getProgram(id))
    }, [id])

    return (
        <div>
            {editProgramModal &&
                <CreateProgramModal close={() => setEditProgramModal(null)} programData={editProgramModal}/>
            }

            <Container>
                <ProgramHeader {...program} handleEditProgram={() => handleEditProgram(program)} programData={program}/>
                <ProgramNavbar {...program}/>

                <Routes>
                    <Route path='groups' element={<Groups {...program}/>}/>
                    <Route path='content' element={<p></p>}/>
                    <Route path='plan' element={<p></p>}/>
                    <Route path='exercises' element={<p></p>}/>
                    <Route path='projects' element={<Projects />}/>
                </Routes>
            </Container>
        </div>
    );
};

export default Program;