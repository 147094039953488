import React, { useEffect, useRef, useState, useMemo, useCallback, lazy } from 'react';
import { FixedSizeList as List } from 'react-window';
import StudentsList from "../../../../../components/groups/group/attendance/students/StudentsList";
import Levels from "../../../../../components/groups/group/attendance/Levels";
import MarkAttendanceModal from "../../../../../components/groups/group/modals/MarkAttendance";
import { useDispatch } from "react-redux";
import { markLessonAttendance, restoreCanceledLesson } from "../../../../../store/actions/groups";
import ReplaceLessonModal from "../../../../../components/groups/group/modals/ReplaceLesson";
import CancelLesson from "../../../../../components/groups/group/modals/CancelLesson";
import { ConfirmationModal } from "../../../../../components/shared";
import RemoveStudentModal from "../../../../../components/groups/group/modals/RemoveStudent";
import { debounce } from 'lodash'

const Attendance = ({ students, attendance, showDroppedStudents, _id, type }) => {
    const [markAttendance, setMarkAttendance] = useState(null);
    const [replaceLessonModal, setReplaceLessonModal] = useState(false);
    const [cancelLessonModal, setCancelLessonModal] = useState(false);
    const [restoreCanceledLessonModal, setRestoreCanceledLessonModal] = useState(false);
    const [removeStudentModal, setRemoveStudentModal] = useState(false);
    const [lineHovered, setLineHovered] = useState(null);
    const [isScrolling, setIsScrolling] = useState(false);
    const attendanceRef = useRef(null);

    const dispatch = useDispatch();

    const activeStudents = useMemo(() => {
        return students?.studentsList
            .filter(studentData => studentData.status !== 'dropped')
            .map(studentData => studentData.student?._id)
            .filter(student => student)
    }, [students])

    const handleScroll = useCallback(debounce(() => {
        setIsScrolling(true)
        setTimeout(() => {
            setIsScrolling(false)
        }, 1000)
    }, 100), [])

    useEffect(() => {
        const modals = [markAttendance, replaceLessonModal, cancelLessonModal, restoreCanceledLessonModal, removeStudentModal]
        document.body.style.overflow = modals.some(Boolean) ? 'hidden' : 'auto'
    }, [markAttendance, replaceLessonModal, cancelLessonModal, restoreCanceledLessonModal, removeStudentModal])

    const handleMarkAttendance = (dayAttendance, levelIndex, lessonIndex) => {
        setMarkAttendance({ dayAttendance, levelIndex, lessonIndex })
    }

    const handleOpenReplaceLessonModal = (targetLessonDate, lessonIndex, levelIndex) => {
        setReplaceLessonModal({ targetLessonDate, lessonIndex, levelIndex })
    }

    const handleOpenCancelLessonModal = (targetLessonDate, lessonIndex, levelIndex) => {
        setCancelLessonModal({ targetLessonDate, lessonIndex, levelIndex })
    }

    const handleOpenRestoreCanceledLessonModal = (targetLesson, lessonIndex, levelIndex) => {
        setRestoreCanceledLessonModal({ targetLesson, lessonIndex, levelIndex })
    }

    const handleRestoreCanceledLesson = () => {
        dispatch(restoreCanceledLesson(_id, restoreCanceledLessonModal.targetLesson, restoreCanceledLessonModal.levelIndex, restoreCanceledLessonModal.lessonIndex))
        setRestoreCanceledLessonModal(false)
    }

    const handleRemoveStudent = (studentId) => {
        setRemoveStudentModal(studentId)
    }

    const handleSubmitMarkAttendance = (lessonDate, data, levelIndex, lessonIndex) => {
        dispatch(markLessonAttendance(_id, lessonDate, data, levelIndex, lessonIndex))
        setMarkAttendance(null)
    }

    return (
        <>
            {markAttendance && (
                <MarkAttendanceModal
                    students={students}
                    data={markAttendance}
                    close={() => setMarkAttendance(null)}
                    submit={handleSubmitMarkAttendance}
                />
            )}
            {replaceLessonModal && (
                <ReplaceLessonModal
                    close={() => setReplaceLessonModal(null)}
                    targetLessonDate={replaceLessonModal}
                />
            )}
            {cancelLessonModal && (
                <CancelLesson
                    close={() => setCancelLessonModal(null)}
                    targetLessonDate={cancelLessonModal}
                />
            )}
            {restoreCanceledLessonModal && (
                <ConfirmationModal
                    close={() => setRestoreCanceledLessonModal(null)}
                    warningText="Վստա՞հ եք, որ ցանկանում եք վերականգնել չեղարկումը։"
                    confirmText="Վերականգնել"
                    cancelText="Չեղարկել"
                    confirm={handleRestoreCanceledLesson}
                />
            )}
            {removeStudentModal && (
                <RemoveStudentModal
                    close={() => setRemoveStudentModal(null)}
                    studentId={removeStudentModal}
                    students={students}
                />
            )}

            <div
                onScroll={handleScroll}
                ref={attendanceRef}
                className="relative max-w-full flex pb-2.5 border rounded-[3px] mt-[30px]"
                id="group-attendance"
            >
                <StudentsList
                    studentsList={students?.studentsList}
                    lineHovered={lineHovered}
                    hoverLine={setLineHovered}
                    activeStudents={activeStudents}
                    showDropped={showDroppedStudents}
                    handleRemoveStudent={handleRemoveStudent}
                />
                <Levels
                    students={students}
                    levels={attendance?.levels}
                    total={attendance?.total}
                    markAttendance={handleMarkAttendance}
                    openReplaceLessonModal={handleOpenReplaceLessonModal}
                    openCancelLessonModal={handleOpenCancelLessonModal}
                    openRestoreCanceledLessonModal={handleOpenRestoreCanceledLessonModal}
                    lineHovered={lineHovered}
                    hoverLine={setLineHovered}
                    activeStudents={activeStudents}
                    showDropped={showDroppedStudents}
                    isParentScrolling={isScrolling}
                    attendanceRef={attendanceRef}
                    type={type}
                />
            </div>
        </>
    );
};

export default Attendance;
