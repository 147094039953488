import React, {useEffect, useRef, useState} from 'react';
import {Container} from "../../../components/shared";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createForm, getRequestForm, saveRequestForm} from "../../../store/actions/forms";
import FormField from "../../../components/forms/FormField"

import {gsap} from 'gsap'
import {arrayMove, SortableContext,} from "@dnd-kit/sortable";
import {DndContext, MouseSensor, PointerSensor, pointerWithin, TouchSensor, useSensor, useSensors} from "@dnd-kit/core";
import {checkCreatedFormValidity} from "../../../utils/forms/checkCreatedFormValidity";
import {PlusButton} from "../../../components/shared/buttons";
import {
    CHANGE_EDIT_ELEMENT,
    CHANGE_FIELD,
    CHANGE_FOCUS,
    CHANGE_POSITION,
    CREATE_FIELD,
    DELETE_FIELD,
    DUPLICATE_FIELD
} from "../../../store/constants/actionTypes";
import {parseQueryParams} from "../../../utils/convertQueryFilters";

const fieldTypes = [
    {id: 1, name: 'Տեքստ', value: "text"},
    {id: 2, name: 'Մեկ ընտրությամբ հարց', value: "dropdown"},
    {id: 3, name: 'Բազմակի ընտրությամբ հարց', value: "dropdown"},
    {id: 4, name: 'Օրացույց', value: "calendar"},
    {id: 5, name: 'Հեռախոսահամար', value: "phoneNumber"},
    {id: 6, name: 'Մեկ ընտրությամբ աղյուսակ', value: "dropdown"},
    {id: 7, name: 'Բազմակի ընտրությամբ աղյուսակ', value: "dropdown"},
    {id: 8, name: 'Վերբեռնել ֆայլ', value: "dropdown"},
    {id: 9, name: 'Գնահատում', value: "dropdown"},
    {id: 10, name: 'Հայտը հաստատող նամակ', value: "successMessage"},
]

const Form = () => {
    const [open, setOpen] = useState(false)
    const {form} = useSelector(state => state.forms)
    const [data, setData] = useState(null)

    const [editElement, setEditElement] = useState(null)
    const editElementRef = useRef(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const createFieldRef = useRef(null)

    const navigate = useNavigate()
    const getFieldPosition = id => data.body.findIndex(field => field.id === id)

    const handleDragEnd = event => {
        const {active, over} = event

        if (!over || event.id === over.id) return

        const originalPosition = getFieldPosition(active.id)
        const newPosition = getFieldPosition(over.id)

        dispatch({type: CHANGE_POSITION, payload: {body: arrayMove(data.body, originalPosition, newPosition)}})
    }

    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 0.001
            }
        }),
        useSensor(TouchSensor),
    )

    const checkAndEdit = () => {
        if (data) {
            if (data[editElement?.name].trim() !== '') {
                setEditElement(null)
            } else {
                dispatch({type: CHANGE_EDIT_ELEMENT, payload: {name: editElement.name, value: editElement.value}})
                setEditElement(null)
            }
        }
    }

    const handleOpenFieldTypes = () => {
        setOpen(!open)
    }

    const handleDeleteField = (id) => {
        dispatch({type: DELETE_FIELD, payload: {fieldId: id}})
    }

    const handleCreateNewField = (fieldType) => {
        dispatch({type: CREATE_FIELD, payload: {fieldType}})
        setOpen(false)
    }

    const handleFocus = (id) => {
        dispatch({type: CHANGE_FOCUS, payload: {id}})
    }

    const handleChangeEditElement = (e) => {
        dispatch({type: CHANGE_EDIT_ELEMENT, payload: {name: e.target.name, value: e.target.value}})
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            checkAndEdit()
        }
    }

    const handleEditElement = (name) => {
        setEditElement({name, value: data[name]})
        editElementRef?.current?.select()
    }

    const changeField = (field) => {
        dispatch({type: CHANGE_FIELD, payload: {field}})
    }

    const duplicateField = (id, index) => {
        dispatch({type: DUPLICATE_FIELD, payload: {id, index}})
    }

    const handleSaveForm = () => {
        const isValid = checkCreatedFormValidity(data)

        if (isValid) {
            const payload = {
                ...data,
                body: data.body.map(item => ({...item, answer: '', focused: false}))
            }

            dispatch(saveRequestForm(payload))
            navigate('/forms')
        }
    }

    useEffect(() => {
        // Either create a new one or get from server
        if (id && id.length === 24) {
            !form && dispatch(getRequestForm(id))
        } else {
            const {type} = parseQueryParams(window.location.href)

            if (!type) navigate('/forms/create?type=request')

            !form && dispatch(createForm(type))
        }
    }, [id, window.location.href, form])

    useEffect(() => {
        if (form) {
            setData(form)
        }
    }, [form])

    useEffect(() => {
        const optionsElement = createFieldRef.current

        if (open) {
            gsap.fromTo(
                optionsElement,
                {opacity: 0, height: 0, display: 'block'},
                {opacity: 1, height: 'auto', duration: 0.3}
            )
        } else {
            gsap.to(optionsElement, {opacity: 0, height: 0, duration: 0.2, display: 'none'})
        }
    }, [open]);

    return (
        <div>
            <div className={'relative mt-7 shadow-md'}>
                <div className={`flex justify-center`}>
                    {editElement?.name === 'name'
                        ?
                        <textarea ref={editElementRef}
                                  className={'w-[900px] leading-[27px] bold text-[28px] text-[#FCC10F] text-center outline-none resize-none'}
                                  name={'name'}
                                  value={form.name} onKeyDown={handleEnter}
                                  onChange={handleChangeEditElement}
                                  onFocus={(e) => e.target.select()}
                        />
                        :
                        <h2 className={'max-w-[900px] break-all text-center bold text-[28px] text-[#FCC10F] select-none'}
                            onClick={() => handleEditElement('name')}>{data?.name}</h2>
                    }
                </div>
                {editElement &&
                    <div className={'mt-[30px] border-t border-black border-opacity-30'}></div>
                }
                <Container>
                    {editElement?.name === 'description'
                        ?
                        <textarea ref={editElementRef}
                                  className={'w-[1280px] text-[28px] leading-[34.13px] pt-[10px] outline-none resize-none'}
                                  autoFocus
                                  name={'description'}
                                  value={form.description}
                                  onKeyDown={handleEnter}
                                  onChange={handleChangeEditElement}
                                  onFocus={(e) => e.target.select()}
                        />
                        :
                        <h2 className={'w-full break-all text-[28px] leading-[34.13px] pb-[14px] mt-[34px] select-none'}
                            onClick={() => handleEditElement('description')}>{data?.description}</h2>
                    }
                </Container>
            </div>
            <Container>
                <div className={'pb-[100px]'}>
                    <div className={'mt-[30px]'}>
                        <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={pointerWithin}>

                            <div>
                                {data?.body.length > 0 &&
                                    <SortableContext items={data?.body}>
                                        <div>{data?.body.map((field, i) => {
                                            return <FormField key={field.id}
                                                              showCategory={form?.type === 'request'}
                                                              handleFocus={handleFocus}
                                                              duplicateField={duplicateField}
                                                              index={i}
                                                              changeField={changeField}
                                                              deleteField={handleDeleteField}
                                                              fieldTypes={fieldTypes} {...field}
                                            />
                                        })}
                                        </div>
                                    </SortableContext>
                                }
                            </div>
                        </DndContext>
                        <div className={'relative flex flex-col items-center mb-[53px]'}>
                            <PlusButton onClick={handleOpenFieldTypes}/>

                            <div
                                ref={createFieldRef}
                                className={`w-[400px] ${open ? 'h-auto' : 'h-0'}  top-10 custom-shadow flex flex-col gap-5 mt-[30px] py-[25px] border border-black rounded-[5px] overflow-hidden`}
                            >
                                {fieldTypes.map(item => (
                                    <p
                                        key={item.id}
                                        className={'px-[30px] py-[5px] hover:bg-gray-100 cursor-pointer transition'}
                                        onClick={() => handleCreateNewField(item.value)}
                                    >
                                        {item.name}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={handleSaveForm}
                        className={'w-[176px] h-10 float-end text-white bg-[#FCC10F] rounded-[5px]'}>
                        Պահպանել
                    </button>
                </div>
            </Container>
        </div>
    );
};

export default Form