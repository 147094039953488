import React, { useEffect, useRef, useState } from 'react';
import { DropdownArrow } from "../../../../assets/icons";
import SelectWorkSchedule from "./SelectWorkSchedule";
import { daysOfWeek } from "../../../../common/dropdownOptions";
import { useOutsideClick } from "../../../../hooks/useOutsideClick";

const Schedule = ({ schedule = [], onChange }) => {
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);

    const optionsRef = useRef(null);
    const selectedRef = useRef(null);

    useOutsideClick(optionsRef, () => handleAddSchedule());

    const filteredSchedule = schedule.filter((_, index) => index < 4);

    const handleAddSchedule = () => {
        const modifiedSchedule = filteredSchedule.reduce((acc, curr) => {
            const day = daysOfWeek[curr.dayIndex];
            const timeRange = `${curr.startTime}-${curr.endTime}`;
            const existingEntry = acc.find(entry => entry.timeRange === timeRange);

            if (existingEntry) {
                existingEntry.days.push(day);
            } else {
                acc.push({ timeRange, days: [day] });
            }

            return acc;
        }, []).map(({ days, timeRange }) => `${days.join(', ')} ${timeRange}`).join(', ');

        return modifiedSchedule;
    }

    const toggleOpen = () => {
        setOpen(!open);
        setFocused(true);
    }

    const addToSchedule = (newScheduleDay) => {
        onChange([...schedule, ...newScheduleDay]);
        setOpen(false);
    }

    const removeScheduleDay = (dayIndex) => {
        onChange(schedule.filter(day => day.dayIndex !== dayIndex));
    }

    return (
        <div tabIndex={0} className={`w-full overflow-hidden bg-white rounded-[3px]`}>
            <div onClick={toggleOpen} ref={selectedRef}
                 className={`flex items-center justify-between h-[30px] px-[30px] border border-black border-opacity-70 rounded-[3px] transition cursor-pointer ${open && 'border-b-0 rounded-b-none'}`}>
                <span>{handleAddSchedule()}</span>
                <span>
                    {schedule.slice(4).length > 0 &&
                        <div className={'group'}>
                            <div className={'cursor-pointer ml-5'}>
                                + {schedule.slice(4).length}
                            </div>
                            <div className={'absolute w-screen right-0 flex justify-end'}>
                                <div
                                    className={'absolute grid grid-cols-2 justify-end gap-2 opacity-0 bg-white p-1 border border-black border-opacity-70 group-hover:opacity-100 top-full pointer-events-none rounded-[3px] text-[12px] transition'}>
                                    {schedule.slice(4).map((item, index) => (
                                        <div key={index}>
                                            {daysOfWeek[item.dayIndex]} {item.startTime}-{item.endTime}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </span>
                <button className={`${open && 'rotate-180 transition'}`}><DropdownArrow/></button>
            </div>

            <div ref={optionsRef}
                 className={`${open ? 'h-auto' : 'h-0'} max-h-[241px] overflow-auto transition bg-white`}>
                {open &&
                    <div className={'h-full border border-black border-opacity-70 rounded-b-[3px] px-[25px] py-5'}>
                        <SelectWorkSchedule schedule={schedule} addToSchedule={addToSchedule}
                                            removeScheduleDay={removeScheduleDay} onFocus={() => setFocused(true)} />
                    </div>
                }
            </div>
        </div>
    );
};

export default Schedule;
