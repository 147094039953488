import {
    CHAT_MESSAGE,
    SET_CHAT,
    SET_CHAT_ROOM,
    SET_CHATS,
    SET_MEDIA_OF_CHAT, SET_PREPARED_CHAT,
    SET_REACTIONS,
    SET_REPLYING_MESSAGE,
    SET_SEARCHED_CHATS,
    SET_SELECTED_CHAT_ID,
    UNPIN_ALL_MESSAGES,
    USER_DELETED_MESSAGE,
    USER_HAS_SEEN_MESSAGE,
    USER_PINNED_UNPINNED_MESSAGE
} from "../constants/actionTypes";
import {unpinAllMessages} from "../../socket/chats";
import {useSelector} from "react-redux";

export const setChatRoom = (room) => async (dispatch) => {
    try {
        dispatch({ type: SET_CHAT_ROOM, payload: room })
    } catch (error) {
        console.log(error.message)
    }
}

export const setPreparedChat = (chat) => async (dispatch) => {
    try {
        dispatch({ type: SET_PREPARED_CHAT, payload: {chat} })
    } catch (error) {
        console.log(error.message)
    }
}

export const setChats = (chats) => async (dispatch) => {
    try {
        dispatch({ type: SET_CHATS, payload: {chats} })
    } catch (error) {
        console.log(error.message)
    }
}

export const setMediaOfChat = (media) => async (dispatch) => {
    try {
        dispatch({ type: SET_MEDIA_OF_CHAT, payload: {media} })
    } catch (error) {
        console.log(error.message)
    }
}

export const setSearchedChats = (payload) => async (dispatch) => {
    try {
        dispatch({ type: SET_SEARCHED_CHATS, payload })
    } catch (error) {
        console.log(error.message)
    }
}

export const setReactions = (reactions) => async (dispatch) => {
    try {
        dispatch({ type: SET_REACTIONS, payload: {reactions} })
        console.log(reactions)
    } catch (error) {
        console.log(error.message)
    }
}

export const openChat = (chats, chat) => async (dispatch) => {
    try {
        dispatch({ type: SET_CHAT, payload: {chats, chat} })
    } catch (error) {
        console.log(error.message)
    }
}

export const setReplyingMessage = (message) => async (dispatch) => {
    try {
        dispatch({ type: SET_REPLYING_MESSAGE, payload: message })
    } catch (error) {
        console.log(error.message)
    }
}

export const receiveInChatMessage = (message, chatId, messageMinimized) => async (dispatch) => {
    try {
        dispatch({ type: CHAT_MESSAGE, payload: {message, chatId, messageMinimized} })
    } catch (error) {
        console.log(error.message)
    }
}

export const userPinnedUnpinnedMessage = (pinnedMessageData) => async (dispatch) => {
    try {
        dispatch({ type: USER_PINNED_UNPINNED_MESSAGE, payload: pinnedMessageData })
    } catch (error) {
        console.log(error.message)
    }
}

export const userHasSeenMessage = (chatId) => async (dispatch) => {
    try {
        dispatch({ type: USER_HAS_SEEN_MESSAGE, payload: chatId })
    } catch (error) {
        console.log(error.message)
    }
}

export const userDeletedMessage = (deletedMessageData) => async (dispatch) => {
    try {
        dispatch({ type: USER_DELETED_MESSAGE, payload: deletedMessageData })
    } catch (error) {
        console.log(error.message)
    }
}