import React, {useEffect, useState} from 'react';
import {
    CompletedIcon,
    DroppedIcon,
    GroupInstructorIcon,
    ParticipantsIcon,
    ParticipatedIcon,
    ProgramIcon,
    ScheduleIcon,
    SMARTConnectsIcon,
    SMARTVisitsIcon,
    SMARTWorkshopsIcon
} from "../../../../assets/icons";
import head from "../../../../assets/images/Head.png";
import hand from "../../../../assets/images/Hand.png";
import heart from "../../../../assets/images/Heart.png";
import CircularProgress from "./CircularProgress";
import {useDispatch} from "react-redux";
import OpenCurrentGroup from "./OpenCurrentGroup";
import {daysOfWeek} from "../../../../common/dropdownOptions";
import {SmartBlock} from "./SmartBlock";
import {useMediaQuery} from "react-responsive";

const icons = {head, hand, heart}

const StudentProgramsInfo = ({studentGroups = {}, smartConnects, smartTrips, smartWorkshops, currentGroups}) => {
    const [openCurrentGroup, setOpenCurrentGroup] = useState(null);

    const studentGroupsArr = Object.entries(studentGroups);
    const empty = !studentGroups.head?.length && !studentGroups.hand?.length && !studentGroups.heart?.length;
    const dispatch = useDispatch()

    const isPhone = useMediaQuery({maxWidth: 640})

    useEffect(() => {
        document.body.style.overflow = (openCurrentGroup) ? 'hidden' : 'auto'
    }, [openCurrentGroup])

    return (
        <div>
            {openCurrentGroup &&
                <OpenCurrentGroup
                    currentGroup={openCurrentGroup}
                    close={() => setOpenCurrentGroup(null)}
                />
            }

            <div className={'grid grid-cols-1 lg:grid-cols-3 gap-5 mt-5'}>
                {currentGroups?.map(group => {
                    const schedule = group.scheduleCodes?.map((scheduleCode) => {
                        const splitted = scheduleCode.split('_');
                        const weekday = daysOfWeek[splitted[0]];
                        const timeRange = splitted[1].split('-');
                        const startTime = timeRange[0];
                        const endTime = timeRange[1];

                        return `${weekday}. ${startTime}-${endTime}`;
                    });

                    return (
                        <div key={group._id} onClick={() => setOpenCurrentGroup(group)}
                             className={'max-w-[345px] min-h-[211px] lg:max-w-[500px] custom-shadow rounded-[10px]'}>
                            <h2 className={'text-center text-[#FCC10F] py-5 lg:text-[18px]'}>Ընթացիկ ծրագիր</h2>
                            <div>
                                <div className={'pl-5'}>
                                    <div className={'flex items-center justify-between pr-5'}>
                                        <div className={'max-w-[220px]'}>
                                            <div className={'flex items-center gap-2.5'}>
                                                <div className={'w-5 h-5 lg:w-10 lg:h-10'}>
                                                    <img src={group?.program?.image} alt={'program-image'}
                                                         className={'w-full h-full object-contain'}/>
                                                </div>
                                                <p className={'text-[14px] lg:text-[16px]'}>
                                                    {group.name}
                                                </p>
                                            </div>
                                            <div className={'flex items-center gap-2.5 mt-5'}>
                                                <GroupInstructorIcon width={!isPhone && 30} height={!isPhone && 30}/>
                                                <div className={'text-[14px] lg:text-[16px]'}>
                                                    {group.instructors?.map(instructor => (
                                                        <div key={instructor._id}>
                                                            {instructor.fullName}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className={'flex items-center gap-2.5 mt-5'}>
                                                <ParticipantsIcon width={!isPhone && 30} height={!isPhone && 30}/>
                                                <p className={'text-[14px] lg:text-[16px]'}>{group?.studentsCount}</p>
                                            </div>
                                        </div>
                                        <div className={''}><CircularProgress percentage={group?.attendancePercentage}/>
                                        </div>
                                    </div>
                                    <div className={'flex items-center gap-2.5 mt-2.5 pb-5'}>
                                        <ScheduleIcon width={!isPhone && 30} height={!isPhone && 30}/>
                                        <div className={'text-[14px] lg:text-[16px]'}>
                                            {schedule?.length > 0
                                                ?
                                                schedule.length > 2
                                                    ?
                                                    <div className={"relative group"}>
                                                        <span className={'cursor-pointer'}>Գրաֆիկ</span>
                                                        <div
                                                            className={"w-[228px] absolute z-10 hidden group-hover:grid grid-cols-3 gap-y-2.5 bg-white border shadow-md rounded-[3px] p-2 left-0 mt-2"}>
                                                            {schedule.map((item, index) => (
                                                                <p key={index} className={'text-[12px]'}>{item}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={'grid grid-cols-2 gap-2.5'}>
                                                        {schedule.map((item, index) => <p className={'w-full'}
                                                                                          key={index}>{item}</p>)}
                                                    </div>
                                                :
                                                <p>Առկա չէ</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            {!isPhone &&
                <h2 className={'flex items-center justify-center gap-5 text-[20px] text-[#FCC10F] pt-5'}><ProgramIcon
                    width={40} height={40}/> Ծրագրեր</h2>}

            <div className={'bg-white custom-shadow rounded-[10px] max-w-[345px] min-h-[211px] lg:max-w-[600px] lg:mx-[350px] mt-5 mb-5 p-5 pb-0'}>
                {isPhone && <h2 className={'flex items-center justify-center gap-2.5 text-[14px] text-[#FCC10F]'}>
                    <ProgramIcon/> Ծրագրեր</h2>}
                {empty ? <h2 className={'text-center'}></h2> :
                    <div className={'grid grid-cols-1'}>
                        {studentGroupsArr.map(([key, groups], index) => (
                            <div key={key}
                                 className={`py-5 ${index === studentGroupsArr.length - 1 ? '' : 'border-b'}`}>
                                <div className={'flex justify-center pointer-events-none select-none'}>
                                    <img src={icons[key]} alt="category" className={'lg:w-[50px] lg:h-[50px]'}/>
                                </div>
                                <div className={'flex flex-col gap-2.5 mt-2.5'}>
                                    {groups.length > 0 && groups.map(item => (
                                        item.statusType !== 'current' && (
                                            <div key={item._id}
                                                 className={'w-full flex items-center justify-between gap-5'}>
                                                {item.statusType !== 'current' &&
                                                    <p className={'hover:text-[#FCC10F] transition text-[14px] lg:text-[18px]'}>
                                                        {item.group?.name}
                                                    </p>
                                                }
                                                <div className={'flex justify-center items-center gap-2.5'}>
                                                    {item.status === 'completed' &&
                                                        <p className={'flex items-center gap-2.5'}>
                                                            <span
                                                                className={'text-[14px] text-[#20BA07] lg:text-[18px]'}>Ավարտած</span>
                                                            <CompletedIcon width={isPhone ? 17 : 30} height={isPhone ? 17 : 30}/>
                                                        </p>
                                                    }
                                                    {item.status === 'participated' &&
                                                        <p className={'flex items-center gap-2.5'}>
                                                            <span className={'text-[14px] lg:text-[18px]'}>Մասնակից</span>
                                                            <ParticipatedIcon width={isPhone ? 17 : 30} height={isPhone ? 17 : 30}/>
                                                        </p>
                                                    }
                                                    {item.status === 'dropped' &&
                                                        <p className={'flex items-center gap-2.5'}>
                                                            <span
                                                                className={'text-[14px] text-[#FF0707] lg:text-[18px]'}>Չավարտած</span>
                                                            <DroppedIcon width={isPhone ? 17 : 30} height={isPhone ? 17 : 30}/>
                                                        </p>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div className={'grid grid-cols-1 lg:grid-cols-3 lg:gap-5'}>
                <div className={'bg-white custom-shadow rounded-[10px] max-w-[345px] min-h-[70px] lg:max-w-[500px] mb-5 p-5'}>
                    <div className={'flex items-center justify-center gap-2.5'}>
                        <SMARTConnectsIcon width={!isPhone && 50} height={!isPhone && 50}/>
                        <h2 className={'text-[#FCC10F] text-[14px] lg:text-[20px]'}>ՍՄԱՐԹամեջեր</h2>
                    </div>
                    <SmartBlock event={smartConnects}/>
                </div>
                <div className={'bg-white custom-shadow rounded-[10px] max-w-[345px] min-h-[70px] lg:max-w-[500px] mb-5 p-5'}>
                    <div className={'flex items-center justify-center gap-2.5'}>
                        <SMARTVisitsIcon width={!isPhone && 40} height={!isPhone && 40}/>
                        <h2 className={'text-[#FCC10F] text-[14px] lg:text-[20px]'}>ՍՄԱՐԹ այցեր</h2>
                    </div>
                    <SmartBlock event={smartTrips}/>
                </div>
                <div className={'bg-white custom-shadow rounded-[10px] max-w-[345px] min-h-[70px] lg:max-w-[500px] mb-5 p-5'}>
                    <div className={'flex items-center justify-center gap-2.5'}>
                        <SMARTWorkshopsIcon width={!isPhone && 40} height={!isPhone && 40}/>
                        <h2 className={'text-[#FCC10F] text-[14px] lg:text-[20px]'}>ՍՄԱՐԹ արհեստանոցներ</h2>
                    </div>
                    <SmartBlock event={smartWorkshops}/>
                </div>
            </div>
        </div>
    );
};

export default StudentProgramsInfo;
