import {
    CREATE_STUDENT_FROM_REQUEST, DELETE_FOREVER,
    DELETE_REQUEST, EDIT_REQUEST,
    GET_REQUESTS, RECOVER_REQUEST, SELECT_REQUESTS, SET_LOADING,
} from '../constants/actionTypes'
import * as requestsApi from '../../api/requests'

export const getRequests = (filters,) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await requestsApi.getRequests(filters)

        dispatch({type: GET_REQUESTS, payload: data})
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
    }
};

export const archiveRequest = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await requestsApi.archiveRequest(id)

        dispatch({ type: DELETE_REQUEST, payload: data })
        dispatch({ type: SET_LOADING })

    } catch (error) {
        console.log(error.message);
    }
}
export const editRequest = (id, editedData, close, setIsEmailTaken) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await requestsApi.editRequest(id, editedData)

        dispatch({ type: EDIT_REQUEST, payload: data })

        close()
        dispatch({ type: SET_LOADING })
    } catch (error) {
        if(error.response.data.error === 'email_is_taken') {
            setIsEmailTaken(true)
        }
        dispatch({ type: SET_LOADING })

        console.log(error.message);
    }
}
export const selectRequests = (request) => async (dispatch) => {
    try {
        dispatch({ type: SELECT_REQUESTS, payload: {request} })

    } catch (error) {
        console.log(error.message);
    }
}

export const createStudentFromRequest = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await requestsApi.createStudentFromRequest(id)

        dispatch({ type: CREATE_STUDENT_FROM_REQUEST, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}

export const recoverRequest = (id) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await requestsApi.recoverRequest(id)

        dispatch({ type: RECOVER_REQUEST, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message);
    }
}