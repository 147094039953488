import React, {useState} from 'react';
import {Container} from "../../../../../components/shared";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {CsvIcon, PdfIcon, ReturnLeftIcon, XlsxIcon} from "../../../../../assets/icons";
import TripAnalyticsFilter from "../../../../../components/transport/subpages/trips/analytics/TripAnalyticsFilter";
import {useFilters} from "../../../../../hooks/useFilters";
import {getConnects} from "../../../../../store/actions/groups";
import TripAnalyticItem from "../../../../../components/transport/subpages/trips/analytics/TripAnalyticItem";
import ButtonHoverText from "../../../../../components/shared/buttons/ButtonHoverText";
import {CSVLink} from "react-csv";
import generatePDF from "react-to-pdf";
import {downloadXlsx} from "../../../../../utils/fileDownloads/xlsx/studentsXlsx";

const initialFilters = {
    name: '', page: 1, size: 9
}

const TripAnalytics = () => {
    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        changePage,
        submitFilters,
        changeCustomPage,
        setCustomPage,
        handleChangeSize,
        setFilters
    } = useFilters(initialFilters, getConnects, 'transport/trips/analytics')

    const location = useLocation()
    const navigate = useNavigate()

    const initialParams = new URLSearchParams(location.search)
    const initialType = initialParams.get('type') || null
    const [selectedType, setSelectedType] = useState(initialType)

    const handleTypeClick = (type) => {
        setSelectedType(type)
        const params = new URLSearchParams(location.search)
        params.set('type', type)
        navigate({search: params.toString()})
    }

    const downloadPdf = () => {
        // generatePDF(pdfRef, {filename: 'page.pdf'})
    }

    const handleDownloadXlsx = () => {
        // downloadXlsx(xlsxColumns, (selected.length ? selected : students.data))
    }

    return (
        <div>
            <Container>
                <Link to={'/transport'} className={'flex items-center gap-[30px] group w-fit cursor-pointer fill-icon'}>
                    <ReturnLeftIcon/>
                    <p className={'transition group-hover:text-[#FCC10F]'}>Տրանսպորտ</p>
                </Link>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Երթադարան վերլուծական</h2>
            </Container>

            <TripAnalyticsFilter
                filters={filters}
                setFilters={setFilters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
            />

            <Container>
                <div className={'flex justify-center gap-[30px] w-full h-fit mt-[14px] mb-[30px]'}>
                    <div className={' max-w-[845px] flex justify-between'}>
                        <div onClick={() => handleTypeClick('arrival')}
                             className={`cursor-pointer border-b-[3px] ${selectedType === 'arrival' ? 'border-[#FCC10F]' : 'border-white'}`}>Ժամանում
                        </div>
                        <div className={'border border-black border-opacity-70 max-h-5 mx-[333px]'}></div>
                        <div onClick={() => handleTypeClick('departure')}
                             className={`cursor-pointer border-b-[3px] ${selectedType === 'departure' ? 'border-[#FCC10F]' : 'border-white'}`}>Մեկնում
                        </div>
                    </div>
                </div>

                <div className={'flex flex-col gap-5'}>
                    <TripAnalyticItem/>
                    <TripAnalyticItem/>
                    <TripAnalyticItem/>
                </div>

                <div
                    className={`flex gap-[10px] mt-[50px] ml-[60px]`}>
                    <ButtonHoverText text={'Արտահանել որպես PDF'} className={'fill-icon'}
                                     icon={<PdfIcon/>} onClick={downloadPdf}/>
                    <ButtonHoverText text={'Արտահանել որպես XLSX'} className={'fill-icon'}
                                     icon={<XlsxIcon/>} onClick={handleDownloadXlsx}/>
                        <CSVLink className="download-csv-button" filename="my-file.csv"
                                 data={''}
                        >
                            <ButtonHoverText text={'Արտահանել որպես CSV'} className={'fill-icon'}
                                             icon={<CsvIcon/>} onClick={a => a}/>
                        </CSVLink>
                </div>
            </Container>
        </div>
    );
};

export default TripAnalytics;