import React, {useEffect, useRef, useState} from 'react';
import {ActiveStudentsIcon, CloseIcon, NewGroupIcon, PeopleIcon, SearchIcon} from "../../../assets/icons";
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {createGroupChat, prepareChat, prepareNewChat, searchChats} from "../../../socket/chats";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

const Search = ({setOpenSearch, className, onChatSelect}) => {
    const {socket} = useSelector(state => state.socket)
    const {searchedChats} = useSelector(state => state.chats)

    const [searchValue, setSearchValue] = useState("")
    const [name, setName] = useState("")
    const [groupToCreate, setGroupToCreate] = useState(null)
    const [selectedChats, setSelectedChats] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef(null)
    const textAreaRef = useRef(null)

    useOutsideClick(ref, () => setOpenSearch(null))

    useEffect(() => {
        if (searchValue.trim()) {
            searchChats({socket, searchValue, includeExistingChats: false, includeNewUsers: true, dispatch})
        }
    }, [searchValue, socket, groupToCreate])

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto'
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
        }
    }, [name])

    const handleChatSelect = (chat) => {
        setSelectedChats(prevSelectedChats => {
            const isSelected = prevSelectedChats.some(selectedChat => selectedChat._id === chat._id)

            if (isSelected) {
                return prevSelectedChats.filter(selectedChat => selectedChat._id !== chat._id)
            } else {
                return [...prevSelectedChats, chat]
            }
        })

        if (!groupToCreate) {
            onChatSelect(chat._id)
        }
    }

    const handleSelectUser = (chat) => {
        if(chat.chatExists) {
            navigate(`/chats/${chat._id}`)
        } else {
            prepareNewChat(socket, chat, navigate, dispatch).then(() => {
                setSearchValue('')
            })
        }
    }

    const handleRemoveChat = (chat) => {
        setSelectedChats(prevSelectedChats => prevSelectedChats.filter(selectedChat => selectedChat._id !== chat._id))
    }

    const handleCreateGroup = () => {
        createGroupChat(socket, name, selectedChats)
            .then(() => {
                setOpenSearch(null)
            })
            .catch(e => {
                console.log(e)
            })
    }

    return (
        <div ref={ref} className={`w-[387px] bg-white border border-black border-opacity-60 ${className}`}>
            <div className={'flex items-center justify-between mt-5 px-5'}>
                {groupToCreate
                    ?
                    <button
                        className={'text-[#FCC10F] cursor-pointer'}
                        onClick={() => setGroupToCreate(null)}
                    >
                        Չեղարկել
                    </button>
                    :
                    <div></div>
                }
                <h2 className={'text-center'}>{groupToCreate ? 'Նոր խումբ' : 'Նոր նամակ'}</h2>
                {groupToCreate
                    ?
                    <button
                        disabled={selectedChats.length < 2 && true}
                        className={`${selectedChats.length >= 2 ? 'text-[#FCC10F]' : 'text-black text-opacity-50'} cursor-pointer`}
                        onClick={handleCreateGroup}
                    >
                        Պահպանել
                    </button>
                    :
                    <div></div>
                }
            </div>
            <div className={`${!groupToCreate && 'flex items-center justify-center'} py-5`}>
                <div className={`flex flex-col gap-5`}>
                    {groupToCreate &&
                        <div className={'px-5 border-b border-black border-opacity-60'}>
                            <textarea
                                ref={textAreaRef}
                                value={name}
                                onChange={e => setName(e.target.value)}
                                autoFocus
                                placeholder={'Խմբի անունը'}
                                className={'w-full max-h-[100px] max-w-[344px] break-all outline-none custom-placeholder resize-none'}
                            />
                        </div>
                    }
                    <div className={`${groupToCreate && 'px-5 border-b border-black border-opacity-60 pb-5'}`}>
                        <div
                            className={`h-[30px] flex justify-between bg-white border border-black border-opacity-70 rounded-[5px] py-0.5 px-[30px] ${searchValue && 'rounded-b-[0px]'}`}>
                            <input
                                ref={textAreaRef}
                                className={'w-full pr-[30px] outline-none resize-none'}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />

                            <div className={'flex items-center gap-5'}>
                                <button><SearchIcon/></button>
                            </div>
                        </div>
                        {selectedChats.length > 0 &&
                            <div className={'flex gap-[27px] pt-5 max-w-[372px] overflow-x-auto'}>
                                {selectedChats.map(chat => (
                                    <div key={chat._id} className={'max-w-[66px]'}>
                                    <div className={'relative w-full flex items-center justify-center'}>
                                            <img
                                                alt={'chat_image'}
                                                src={chat?.image}
                                                className={`w-10 h-10 ${chat?.type !== 'group' && 'rounded-full'} object-cover`}
                                            />
                                            <button
                                                onClick={() => handleRemoveChat(chat)}
                                                className={'absolute bg-white top-0 right-1 w-3 h-3 flex items-center justify-center border border-black border-opacity-80 rounded-full'}
                                            >
                                                <CloseIcon/>
                                            </button>
                                        </div>
                                        <p className={'text-center max-w-[66px] text-[12px]'}>{chat?.name}</p>
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    <div className={'flex flex-col gap-5 max-h-[430px] overflow-y-auto'}>
                        {searchValue && searchedChats?.map(chat => (
                            <div
                                key={chat._id}
                                onClick={groupToCreate ? () => handleChatSelect(chat) : () => handleSelectUser(chat)}
                                className={`flex items-center justify-between hover:bg-gray-100 transition cursor-pointer ${groupToCreate && 'px-5'}`}>
                                <div className={'flex items-center gap-5'}
                                >
                                    <img
                                        alt={'chat_image'}
                                        src={chat?.image}
                                        className={`${groupToCreate ? 'w-5 h-5' : 'w-[30px] h-[30px]'} ${chat?.type !== 'group' && 'rounded-full'} object-cover`}
                                    />
                                    <p>{chat.name}</p>
                                </div>

                                {groupToCreate &&
                                    <div
                                        className={'w-4 h-4 flex items-center justify-center border border-black border-opacity-80 rounded-full'}>
                                        {selectedChats.find(selectedChat => selectedChat._id === chat._id) &&
                                            <div className={'w-2 h-2 bg-[#FCC10F] rounded-full'}></div>}
                                    </div>
                                }
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {!searchValue && !groupToCreate &&
                <div onClick={() => setGroupToCreate(true)}
                     className={'border border-black border-opacity-60 border-l-0 border-r-0 bg-white mb-4 cursor-pointer'}>
                    <p className={'flex items-center gap-5 p-5'}>
                        <NewGroupIcon/>
                        <span>Ստեղծել նոր խումբ</span>
                    </p>
                </div>
            }
        </div>
    )
}

export default Search