import React, {useState} from 'react';
import {CheckedIcon, CloseIcon} from "../../../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../../../shared/buttons";
import moment from "moment";

const MarkAttendanceModal = ({ students, data: { dayAttendance, levelIndex, lessonIndex }, submit, close }) => {
    const [attendanceData, setAttendanceData] = useState(dayAttendance[1].students);
    const [note, setNote] = useState('')
    const [allChecked, setAllChecked] = useState(false)

    const modifiedDay = moment(dayAttendance[0]).format('DD.MM.YYYY')

    const studentsAvailable = students.studentsList.map(studentData => {
        const canMarkAttendance = studentData.status === 'current' || studentData.status === 'restored'

        if (canMarkAttendance) {
            return studentData.student._id;
        }
    }).filter(student => student);

    const toggleCheck = (studentId) => {
        setAttendanceData({
            ...attendanceData,
            [studentId]: {
                ...attendanceData[studentId],
                present: !attendanceData[studentId].present
            }
        });
    }

    const handleChangeNote = (e) => {
        setAttendanceData({
            ...attendanceData,
            [e.target.name]: {
                ...attendanceData[e.target.name],
                note: e.target.value
            }
        });
    }

    const handleNoteChange = (e) => {
        setNote(e.target.value)
    }

    const checkAll = () => {
        const updatedAttendanceData = {};
        studentsAvailable.forEach(studentId => {
            updatedAttendanceData[studentId] = {
                ...attendanceData[studentId],
                present: !allChecked
            };
        });
        setAttendanceData({
            ...attendanceData,
            ...updatedAttendanceData
        });
        setAllChecked(!allChecked);
    }

    const handleSubmit = () => {
        const payload = {
            attendanceData: {},
            note: note
        };

        for (const studentId in attendanceData) {
            if (attendanceData[studentId]) {
                const dayData = attendanceData[studentId];

                if (dayData.present === null) {
                    dayData.present = false;
                }

                payload.attendanceData[studentId] = dayData;
            }
        }

        submit(dayAttendance[0], payload, levelIndex, lessonIndex);
    }

    return (
        <div className={'fixed z-[99999] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] z-[9999] w-full max-h-[calc(100vh-200px)] max-w-[690px] overflow-hidden border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-[30px]'}>
                    <h2 className={'text-center'}>Ներկայություն` <span>{modifiedDay}</span></h2>

                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'}
                            onClick={close}>
                        <CloseIcon width={20} height={20} />
                    </button>
                </div>

                <div className={'w-full pl-[25px] pr-[35px] mb-2.5'}>
                    <h2>Նոթագրություն</h2>
                    <input
                        type={"text"}
                        name={'note'}
                        autoComplete={"off"}
                        autoFocus
                        value={note}
                        onChange={handleNoteChange}
                        className={'w-full h-10 px-[30px] py-2.5 border border-black border-opacity-70 rounded-[3px] outline-none mt-2.5'}
                    />
                </div>

                <div className={'grid gap-4 pl-[30px] mr-[30px] overflow-auto'}>
                    <div className={'grid grid-cols-[250px,20px,250px] place-items-center gap-10'}>
                        <div className={'mt-[50px]'}>Նշել բոլորը</div>
                        <div className={'flex flex-col justify-center items-center gap-5'}>
                            <span>Ներկա</span>
                            <button
                                className={'flex items-center justify-center w-5 h-5 border border-black rounded-[3px]'}
                                onClick={checkAll}>
                                {allChecked && <CheckedIcon />}
                            </button>
                        </div>
                    </div>
                    {students?.studentsList.map(studentData => {
                        const present = attendanceData[studentData.student._id]?.present;
                        const checked = present === true
                            ? true
                            : present === false ? false : present === null && null;

                        if (studentsAvailable.includes(studentData.student._id)) {
                            return (
                                <div key={studentData._id} className={'h-10 grid grid-cols-[250px,20px,250px] gap-10'}>
                                    <div className={'flex items-center truncate'}
                                         title={studentData.student.fullName}>{studentData.student.fullName}</div>
                                    <div className={'flex items-center'}>
                                        <button onClick={() => toggleCheck(studentData.student._id)}
                                                className={'flex items-center justify-center w-5 h-5 border border-black rounded-[3px]'}>
                                            {checked && <CheckedIcon />}
                                        </button>
                                    </div>
                                    <div>
                                        <textarea
                                            value={attendanceData[studentData.student._id]?.note || ''}
                                            onChange={handleChangeNote}
                                            className={'max-w-[250px] h-10 border border-black outline-none rounded-[3px] p-1 resize-none'}
                                            name={studentData.student._id} />
                                    </div>
                                </div>
                            );
                        }
                    })}
                </div>
                <div className={'flex justify-end gap-[30px] py-[30px] px-11'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'} />
                    <ButtonFilled onClick={handleSubmit} text={'Պահպանել'} className={'w-[176px]'} />
                </div>
            </div>
        </div>
    );
};

export default MarkAttendanceModal;
