import React, { useState } from 'react';
import { Scanner } from "@yudiel/react-qr-scanner";
import { Container } from "../../../components/shared";
import { useDispatch, useSelector } from "react-redux";
import { checkIn } from "../../../store/actions/checkIn";
import CheckInCard from "../../../components/checkins/CheckInCard";
import Pagination from "../../../components/shared/Pagination";

const CheckInOut = () => {
    const { checkins } = useSelector(state => state.checkin)
    const dispatch = useDispatch()

    const [currentPage, setCurrentPage] = useState(1)
    const [error, setError] = useState(null)

    const wrongCheckInData = error?.checkinData

    const checkinsPerPage = 12
    const totalCheckins = checkins.length
    const totalPages = Math.ceil(totalCheckins / checkinsPerPage)

    const startIndex = (currentPage - 1) * checkinsPerPage
    const endIndex = startIndex + checkinsPerPage
    const currentCheckins = checkins.slice(startIndex, endIndex)

    const handleScanQR = (result) => {
        dispatch(checkIn(result[0].rawValue, setError))
    }

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage)
    }

    return (
        <Container>
            <div
                className={'w-full h-[294px] flex items-center justify-between custom-shadow rounded-[10px] px-[109px]'}
            >
                <h2 className={'text-[28px]'}>Սքանավորի՛ր QR կոդն այստեղ</h2>
                <div className="w-[254px] h-[254px] rounded-[20px] overflow-hidden border-0 custom-qr-scanner">
                    <Scanner onScan={(result) => handleScanQR(result)} />
                </div>
            </div>

            <div className={'flex flex-wrap mt-5 gap-x-8 gap-y-5'}>
                {currentCheckins.map((checkin, index) => (
                    <CheckInCard data={checkin || wrongCheckInData} key={`${checkin?._id}-${index}`} error={error?.success}/>
                ))}
            </div>

            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
            />
        </Container>
    )
}

export default CheckInOut