import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {CheckedIcon, ClosedEyeIcon, GoogleIcon, KeyIcon, OpenedEyeIcon, UserIcon} from '../../assets/icons';
import {auth, provider} from '../../firebaseConfig'
import {googleSignIn, signIn} from "../../store/actions/auth";
import {signInWithPopup} from 'firebase/auth'
import {useMediaQuery} from "react-responsive";

const initialFormData = {
    email: '',
    password: '',
    rememberMe: false,
    error: null,
}

const LoginForm = () => {
    const {error} = useSelector(state => state.auth)
    const [formData, setFormData] = useState(initialFormData)
    const [showPassword, setShowPassword] = useState(false)
    const dispatch = useDispatch()

    const loginRef = useRef(null)
    const passwordRef = useRef(null)

    const isPhone = useMediaQuery({ maxWidth: 640 })

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = () => {
        const normalizedEmail = formData.email.toLowerCase().trim()
        if (normalizedEmail.includes('@gmail.com') || normalizedEmail.includes('@coaf.org')) {
            dispatch(signIn({...formData, email: normalizedEmail}))
            setFormData(initialFormData)
        } else {
            setFormData({...formData, error: 'Մուտքանվան սխալ ձևաչափ, username@gmail.com'})
        }
    }

    const firebaseLogin = () => {
        signInWithPopup(auth, provider)
            .then((data) => {
                dispatch(googleSignIn(data._tokenResponse.idToken))
            })
            .catch((error) => {
                console.log("Caught error Popup closed")
            })
    }

    const toggleRememberMe = () => {
        setFormData({...formData, rememberMe: !formData.rememberMe})
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className={'max-w-[623px] h-fit lg:pt-[30px] lg:pb-[50px] lg:px-[54px] border-0 lg:border lg:border-[#000] lg:rounded-md'}>
            <div className={'relative'}>
                <h2 className={'text-[50px] bold hidden lg:block'}>Մուտք</h2>

                {!formData.error && <p className={`${isPhone ? 'absolute bottom-full text-[12px] mb-1' : 'text-[16px]'} text-[#FB1313]`}>{error && 'Մուտքանունը կամ ծածկագիրը սխալ են'}</p>}

                {formData.error && isPhone && <p className={`absolute ${isPhone ? 'text-[12px] bottom-full mb-1' : 'text-[16px]'} text-[#FB1313]`}>{formData.error}</p>}
            </div>

            <div className={'lg:my-[15px]'}>
                <div
                    onClick={() => loginRef.current.focus()}
                    className={'flex items-center gap-5 h-[40px] lg:h-auto lg:gap-[30px] px-5 lg:px-[30px] py-[7.5px] lg:py-[9px] border border-[#000] rounded-[5px] lg:rounded-[10px] cursor-pointer'}>
                    <UserIcon width={isPhone ? 20 : 30} height={isPhone ? 20 : 30}/>
                    <input
                        ref={loginRef}
                        type={"text"}
                        name={'email'}
                        className={'w-full outline-none border-none focus:ring-0 text-[16px]'}
                        value={formData.email}
                        onChange={handleChange}
                        autoFocus={isPhone && false}
                        placeholder={'Մուտքանուն'}
                    />
                </div>

                {formData.error && !isPhone && <p className={`absolute ${isPhone ? 'text-[12px]' : 'text-[16px]'} text-[#FB1313]`}>{formData.error}</p>}

                <div
                    onClick={() => passwordRef.current.focus()}
                    className={'flex items-center gap-5 h-[40px] lg:h-auto lg:gap-[30px] mt-5 lg:mt-[30px] px-5 lg:px-[30px] py-[7.5px] lg:py-[9px] border border-[#000] rounded-[5px] lg:rounded-[10px] cursor-pointer'}>
                    <KeyIcon width={isPhone ? 20 : 30} height={isPhone ? 20 : 30}/>
                    <input
                        ref={passwordRef}
                        type={showPassword ? 'text' : 'password'}
                        name={'password'}
                        className={'w-full outline-none border-none focus:ring-0 text-[16px]'}
                        value={formData.password}
                        onChange={handleChange}
                        placeholder={'Ծածկագիր'}
                        onKeyDown={(e) => e.key === 'Enter' && handleSubmit()}
                    />
                    <button onClick={toggleShowPassword}>
                        {showPassword
                            ?
                            <OpenedEyeIcon width={isPhone ? 20 : 30} height={isPhone ? 20 : 30}/>
                            :
                            <ClosedEyeIcon width={isPhone ? 20 : 30} height={isPhone ? 20 : 30}/>}
                    </button>
                </div>
            </div>

            <div className={'flex justify-between mt-5 lg:mt-[30px]'}>
                <div className={'select-none cursor-pointer'}>
                    <label htmlFor="remember-me" className={'flex items-center gap-[14px] cursor-pointer'}
                           onClick={toggleRememberMe}>
                        <div
                            className={'flex items-center justify-center w-5 h-5 border border-black border-opacity-70 lg:border-opacity-100 rounded-[5px]'}>{formData.rememberMe &&
                            <CheckedIcon/>}</div>

                        <span className={'text-[12px] lg:text-[16px] text-black text-opacity-70 lg:text-opacity-100'}>Հիշել ինձ</span>
                    </label>
                </div>
                <p className={'text-[12px] lg:text-[16px] text-black text-opacity-70 lg:text-opacity-100 cursor-pointer'}>Մոռացե՞լ եք գաղտնաբառը</p>
            </div>
            <div className={'flex flex-col items-center gap-[25px]'}>
                <button
                    className={'flex items-center justify-center w-full h-[40px] lg:h-[60px] lg:text-[30px] text-white mt-5 lg:mt-[50px] bg-[#FCC10F] rounded-[5px] lg:rounded-[10px]'}
                    onClick={handleSubmit}
                >
                    <span>Մուտք</span>
                </button>
                <div className={'w-[100px] border border-black border-opacity-30'}></div>
                <button onClick={firebaseLogin}
                        className={'flex items-center justify-center h-[40px] lg:h-[60px] border border-black border-opacity-70 lg:border-opacity-100 w-full gap-5 lg:gap-[30px] rounded-[5px] lg:rounded-[10px]'}>
                    <GoogleIcon width={isPhone ? 20 : 30} height={isPhone ? 20 : 30}/>
                    <span className={'text-[16px] lg:text-[20px]'}>Մուտք Google համակարգով</span>
                </button>
            </div>
        </div>
    );
};

export default LoginForm;
