import React from 'react';
import head from '../../../assets/images/Head.png'
import heart from '../../../assets/images/Heart.png'
import hand from '../../../assets/images/Hand.png'
import {CompletedIcon, DroppedIcon, ParticipatedIcon, ProgramIcon} from "../../../assets/icons";
import {Link} from "react-router-dom";

const icons = {head, hand, heart}

const Programs = ({studentGroups}) => {
    const studentGroupsArr = Object.entries(studentGroups)

    const empty = !studentGroups.head.length && !studentGroups.hand.length && !studentGroups.heart.length
    return (
        <div className={'border-b px-[25px] pt-[15px] pb-[10px]'}>
            <h2 className={'flex items-center justify-center gap-[10px] text-[#FCC10F]'}><ProgramIcon/> Ծրագրեր</h2>
            {empty ?
                <h2 className={'text-center mt-5'}>Ավարտած ծրագրեր չկան</h2>
                :
                <div className={'grid grid-cols-3 mt-[34px] gap-[87px]'}>
                    {studentGroupsArr?.map(([key, groups]) => (
                        <div key={key}>
                            <div className={'flex justify-center pointer-events-none select-none'}>
                                <img src={icons[key]} alt="category"/>
                            </div>
                            <div className={'flex flex-col gap-5 mt-[30px] '}>
                                {groups.length > 0 && groups.map(item => {
                                    return (
                                        <div key={item._id}
                                             className={'w-full flex items-center justify-between gap-5'}>
                                            {item.statusType === 'current'
                                                ?
                                                item.userCanNavigateToGroup
                                                    ?
                                                    <Link to={`/groups/${item._id}/attendance`}
                                                          className={'hover:text-[#FCC10F] transition'}>{item?.name}</Link>
                                                    :
                                                    <p>{item?.name}</p>
                                                :
                                                item.userCanNavigateToGroup
                                                    ?
                                                    <Link to={`/groups/${item.group?._id}/attendance`}
                                                          className={'hover:text-[#FCC10F] transition'}>{item.group?.name}</Link>
                                                    :
                                                    <p>{item.group?.name}</p>
                                            }
                                            <div className={'flex justify-center items-center gap-5'}>

                                                {item.status === 'completed' && <CompletedIcon/>}
                                                {item.status === 'participated' && <ParticipatedIcon/>}
                                                {item.status === 'dropped' && <DroppedIcon/>}
                                                {/*<span className={'uppercase'}>{item.grade}</span>*/}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ))}
                </div>}
        </div>
    );
};


export default Programs;