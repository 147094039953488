import {
    CHANGE_COMMUNITY_STATUS,
    CREATE_COMMUNITY,
    DELETE_COMMUNITY,
    GET_COMMUNITIES,
    SET_LOADING
} from "../constants/actionTypes";
import * as communitiesApi from "../../api/communities";

export const createCommunity = (payload, sortOptions) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await communitiesApi.createCommunity(payload)

        if (data.success) {
            dispatch({type: CREATE_COMMUNITY, payload: data})

            dispatch(getCommunities(sortOptions))
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const changeCommunityStatus = (id, sortOption) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await communitiesApi.changeCommunityStatus(id)

        if (data.success) {
            dispatch({type: CHANGE_COMMUNITY_STATUS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const getCommunities = (sortOption) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await communitiesApi.getCommunities(sortOption)

        if (data.success) {
            dispatch({type: GET_COMMUNITIES, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const deleteCommunity = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await communitiesApi.deleteCommunity(id)

        if (data.success) {
            dispatch({type: DELETE_COMMUNITY, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}


