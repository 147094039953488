import {CHECK_IN, GET_CHECKINS_HISTORY, SET_LOADING} from "../constants/actionTypes";
import * as api from "../../api/checkIn";

export const checkIn = (idToken, setError) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.checkIn(idToken)

        dispatch({ type: CHECK_IN, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message)
        console.log(error.response.data)
        setError(error.response.data)
    }
}

export const getCheckinsHistory = (filters) => async (dispatch) => {
    try {
        dispatch({ type: SET_LOADING })

        const { data } = await api.getCheckinsHistory(filters)

        dispatch({ type: GET_CHECKINS_HISTORY, payload: data })
        dispatch({ type: SET_LOADING })
    } catch (error) {
        console.log(error.message)
        console.log(error.response.data)
    }
}