import {
    CREATE_STUDENT_FROM_REQUEST, DELETE_FOREVER,
    DELETE_REQUEST, EDIT_REQUEST,
    GET_REQUESTS, RECOVER_REQUEST, SELECT_REQUESTS,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    requests: null,
    selected: [],
    loading: false,
}

const requestsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REQUESTS:
            return { ...state, requests: action.payload, selected: [] }
        case EDIT_REQUEST:
            const editedRequest = action.payload.request
            return { ...state, requests: {...state.requests, data: state.requests.data.map(item => item._id === editedRequest._id ? editedRequest : item)}}
        case DELETE_REQUEST:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    data: state.requests.data.filter(item => item._id !== action.payload.id)
                }
            }
        case DELETE_FOREVER:
            return {
                ...state,
                selected: [],
                requests: {
                    ...state.requests,
                    data: state.requests.data.filter(item => {
                        if (action.payload.ids.includes(item._id)) {
                            return null
                        } else {
                            return item
                        }
                    }).filter(item => item)
                }
            }
        case SELECT_REQUESTS:
            const isAll = !action.payload.request
            let selected = state.selected

            if(isAll) {
                if(selected.length !== state.requests.data.length) {
                    selected = state.requests.data
                } else {
                    selected = []
                }
            } else {
                if(selected.find(item => item._id === action.payload.request._id)) {
                    selected = selected.filter(item => item._id !== action.payload.request._id)
                } else {
                    selected = [...selected, action.payload.request]
                }
            }

            return {...state, selected}
        case RECOVER_REQUEST:
            return { ...state, requests: {...state.requests, data: state.requests.data.filter(item => item._id !== action.payload.id)}}
        case CREATE_STUDENT_FROM_REQUEST:
            return { ...state}
        case SET_LOADING:
            return { ...state, loading: !state.loading }
        default:
            return state;
    }
}

export default requestsReducer