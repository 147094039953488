import React from 'react';
import {PlusIcon} from "../../../assets/icons";

const AddButton = ({onClick, text, className}) => {
    return (
        <div>
            <button
                onClick={onClick}
                className={`w-[208px] h-[50px] flex items-center justify-center gap-[30px] text-white rounded-[5px] bg-[#FCC10F] mt-[14px] ${className} ${className && 'fill-icon'}`}>
                <PlusIcon />
                <span className={'bold'}>{text}</span>
            </button>
        </div>
    );
};

export default AddButton;