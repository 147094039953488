import React from 'react';

const TotalAttendance = ({data, lineHovered, hoverLine, showDropped, activeStudents}) => {
    const dataArr = data && Object.entries(data)

    return (
        <div>
            {dataArr?.map(([id, data], index) => {
                const show = showDropped ? true : activeStudents.includes(id)

                return show && (
                    <div
                        key={id}
                        className={`relative grid grid-cols-2 place-items-center h-10 border-b`}
                        onMouseOver={() => hoverLine(index)}
                        onMouseLeave={() => hoverLine(null)}
                    >
                        {lineHovered === index && <div className={'absolute w-full h-full bg-black bg-opacity-[.03] z-[999]'}></div>}

                        <p className={'text-[#20BA07]'}>{data.present}</p>
                        <p className={'text-[#FF0707]'}>{data.absent}</p>
                    </div>
                )
            })}
        </div>
    );
};

export default TotalAttendance;