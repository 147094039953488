import {
    CHANGE_DIVISION_STATUS,
    CHANGE_MEMBER_STATUS,
    CREATE_DIVISION,
    CREATE_MEMBER,
    DELETE_MEMBER,
    EDIT_DIVISION,
    EDIT_PROFILE,
    GET_DIVISIONS,
    GET_MEMBERS,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    members: [],
    divisions: [],
    notifications: [],
    loading: false,
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_DIVISION:
            return {
                ...state,
                divisions: [action.payload.division, ...state.divisions],
            }
        case CREATE_MEMBER:
            return {
                ...state,
                members: {
                    ...state.members,
                    data: [action.payload.member, ...state.members.data]
                },
            }
        case GET_DIVISIONS:
            return {
                ...state,
                divisions: action.payload.divisions,
            }
        case GET_MEMBERS:
            return {
                ...state,
                members: action.payload.members,
            }
        case CHANGE_DIVISION_STATUS:
            const updatedDivision = action.payload.division
            const updatedDivisions = state.divisions.map(division => {
                if (division._id === updatedDivision._id) {
                    return {...division, status: updatedDivision.status}
                }
                return division
            })
            return {
                ...state,
                divisions: updatedDivisions,
            }
        case CHANGE_MEMBER_STATUS:
            const updatedMember = action.payload.member
            const updatedMembers = state.members.data.map(member => {
                if (member._id === updatedMember._id) {
                    return {...member, status: updatedMember.status}
                }
                return member
            })
            return {
                ...state,
                members: {
                    ...state.members,
                    data: updatedMembers,
                },
            }
        case EDIT_DIVISION:
            const editedDivision = action.payload.division
            const editedDivisions = state.divisions.map(division => {
                if (division._id === editedDivision._id) {
                    return editedDivision
                }
                return division
            });
            return {
                ...state,
                divisions: editedDivisions,
            }
        case EDIT_PROFILE:
            const editedMember = action.payload.member
            const editedMembers = state.members.data
                ? state.members.data.map(member => {
                    if (member._id === editedMember._id) {
                        return editedMember
                    }
                    return member
                })
                : []
            return {
                ...state,
                members: {
                    ...state.members,
                    data: editedMembers,
                },
            }

        case DELETE_MEMBER:
            return {
                ...state,
                members: {
                    ...state.members,
                    data: state.members.data.filter(member => member._id !== action.payload.deletedMemberId)
                }
            }
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}
export default profileReducer