import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {getCheckinsHistory} from "../../store/actions/checkIn";
import {useFilters} from "../../hooks/useFilters";
import {getGroups} from "../../store/actions/groups";
import CheckinsHistoryFilter from "./CheckinsHistoryFilter";
import CheckinHistoryItem from "./CheckinHistoryItem";
import {Container} from "../shared";
import Pagination from "../shared/Pagination";
import {ScrollToTopIcon} from "../../assets/icons";

const initialFilters = {
    name: '', page: 1, size: 9
}

const CheckinsHistory = () => {
    const {checkinsHistory} = useSelector(state => state.checkin)

    const {
        filters,
        setFilters,
        discardFilters,
        submitFilters,
        handleChange,
        changeSelectedFilters,
        clearAllFilters,
        changePage,
    } = useFilters(initialFilters, getCheckinsHistory, 'checkin-history')

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    const scrollToBottom = () => {
        window.scrollTo({top: document.documentElement.scrollHeight, behavior: 'smooth'})
    }

    return (
        <div>
            <h2 className={'mt-5 text-center text-[20px] lg:text-[28px] text-[#FCC10F] font-bold'}>Մուտք/ ելք</h2>

            <CheckinsHistoryFilter
                filters={filters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
                clearAllFilters={clearAllFilters}
                setFilters={setFilters}
            />

            <Container>
                <div className={'relative flex flex-col gap-2.5 mt-5'}>
                    {checkinsHistory?.checkins?.length
                        ?
                        checkinsHistory?.checkins?.map((history, i) => {
                            const isFirst = i === 0
                            const isLast = checkinsHistory?.checkins?.length === i + 1

                            return (
                               <>
                                   <CheckinHistoryItem {...history} />

                                   {isFirst &&
                                       <button
                                           className={'absolute left-full ml-2.5 transform rotate-180'}
                                           onClick={scrollToBottom}>
                                           <ScrollToTopIcon/>
                                       </button>
                                   }
                                   {isLast &&
                                       <button
                                           className={'absolute left-full ml-2.5 bottom-2'}
                                           onClick={scrollToTop}>
                                           <ScrollToTopIcon/>
                                       </button>
                                   }
                               </>
                            )
                        })
                        :
                        <p className={'text-center text-[20px]'}>Մուտք/ ելք չկա</p>
                    }
                </div>

                <Pagination count={checkinsHistory?.pagesCount} onChange={changePage}
                            page={parseInt(filters?.page)}/>
            </Container>
        </div>
    );
};

export default CheckinsHistory;