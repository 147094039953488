import React, {useEffect, useRef, useState} from 'react';
import {CheckedIcon, EditIcon, KeyIcon, QRIcon} from "../../assets/icons";
import {changeStudentStatus, resetPassword} from "../../store/actions/students";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {ConfirmationModal, DisplayPhoneNumber} from "../shared";
import {daysOfWeek, studentStatusData} from "../../common/dropdownOptions";
import {DropdownInputCheckbox} from "../shared/dropdowns";
import student_default_image from "../../assets/images/student_default_image.png";
import ButtonHoverText from "../shared/buttons/ButtonHoverText";
import {creator, genius, thinker, whizzKid, wizard} from "../../assets/images/SMART Degrees";
import moment from "moment";
import {routes} from "../../common/routes";
import {roles} from "../../common/roles";
const StudentItem = ({
                         fullName,
                         currentGroups,
                         email,
                         birthday,
                         profileImage,
                         phoneNumbers,
                         community,
                         currentSmartDegree,
                         selected,
                         globalStatus,
                         handleSelectStudent,
                         _id,
                         userHasPermissions,
                         handleDownloadQrCodesPdf,
                     }) => {
    const {authData} = useSelector(state => state.auth)
    const dispatch = useDispatch();
    const location = useLocation();

    const hasPermissions = roles[authData?.role]?.map(role => role.showEditStudentProfileButton)

    const age = moment().diff(moment(birthday, 'YYYY-MM-DD'), "years");

    const [passwordRecoverModal, setPasswordRecoverModal] = useState(null);
    const [showGroupsDropdown, setShowGroupsDropdown] = useState(false);
    const [dropdownPosition, setDropdownPosition] = useState('bottom');
    const dropdownRef = useRef(null);

    const checked = selected?.find(selected => selected?._id === _id);

    const schedule = currentGroups.scheduleCodes?.map((scheduleCode) => {
        const splitted = scheduleCode.split('_');
        const weekday = daysOfWeek[splitted[0]];

        return `${weekday}. ${splitted[1].split('-')[0]}`;
    });

    const handleRecoverPassword = () => {
        setPasswordRecoverModal(true);
    };

    const confirmRecoverPassword = () => {
        setPasswordRecoverModal(null)
        dispatch(resetPassword(_id))
    }

    const handleChangeStatus = (_, newStatus) => {
        dispatch(changeStudentStatus(_id, newStatus._id));
    };

    useEffect(() => {
        document.body.style.overflow = passwordRecoverModal ? 'hidden' : 'auto';
    }, [passwordRecoverModal]);

    useEffect(() => {
        const handleResize = () => {
            if (showGroupsDropdown && dropdownRef.current) {
                const rect = dropdownRef.current.getBoundingClientRect();
                const viewportHeight = window.innerHeight;

                if (rect.bottom > viewportHeight) {
                    setDropdownPosition('top');
                } else {
                    setDropdownPosition('bottom');
                }
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [showGroupsDropdown]);

    const handleMouseEnter = () => {
        setShowGroupsDropdown(true);
    };

    const handleMouseLeave = () => {
        setShowGroupsDropdown(false);
    }

    return (
        <>
            {passwordRecoverModal &&
                <ConfirmationModal
                    id={passwordRecoverModal}
                    close={() => setPasswordRecoverModal(false)}
                    warningText={'Վստա՞հ եք, որ ցանկանում եք վերաթողարկել ծածկագիրը։'}
                    confirm={confirmRecoverPassword}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'grid grid-cols-[30px,1fr] gap-[23px] py-5'}>
                <div className={'flex items-center'}>
                    {authData?.role !== 'instructor' &&
                        <div
                            onClick={() => handleSelectStudent(_id)}
                            className={'w-[30px] h-[30px] flex items-center justify-center cursor-pointer border border-black border-opacity-70 rounded-[5px]'}
                        >
                            {checked && <CheckedIcon/>}
                        </div>
                    }
                </div>

                <div
                    className={'grid grid-cols-[70px,1fr] place-items-center border border-black border-opacity-30 px-[30px] rounded-[10px] py-4'}>
                    <div className={'flex items-center rounded-full overflow-hidden'}>
                        <img src={profileImage || student_default_image} alt={'profileImage'}
                             className={'w-full h-full object-cover aspect-square pointer-events-none select-none'}/>
                    </div>
                    <div className={'w-full pl-[30px]'}>
                        <div className={'flex justify-between'}>
                            <div className={''}>
                                <div className={'flex gap-2.5'}>
                                    <Link
                                        to={`/students/${_id}?mode=view&subpage=profile`}
                                        state={{from: location.pathname + location.search}}
                                        className={'font-bold hover:text-[#FCC10F] transition'}
                                    >
                                        {fullName}
                                    </Link>
                                    {currentSmartDegree === "thinker" &&
                                        <img
                                            src={thinker}
                                            alt={'thinker'}
                                            className={`w-5 h-5 select-none`}
                                        />}
                                    {currentSmartDegree === "creator" &&
                                        <img
                                            src={creator}
                                            alt={'creator'}
                                            className={`w-5 h-5 select-none`}
                                        />}
                                    {currentSmartDegree === "whizzKid" &&
                                        <img
                                            src={whizzKid}
                                            alt={'whizzKid'}
                                            className={`w-5 h-5 select-none`}
                                        />}
                                    {currentSmartDegree === "wizard" &&
                                        <img
                                            src={wizard}
                                            alt={'wizard'}
                                            className={`w-5 h-5 select-none`}
                                        />}
                                    {currentSmartDegree === "genius" &&
                                        <img
                                            src={genius}
                                            alt={'genius'}
                                            className={`w-5 h-5 select-none`}
                                        />}
                                </div>
                                <div className={'grid grid-cols-[260px,1fr] gap-[50px] mt-[30px]'}>
                                    <div className={'w-[260px] flex flex-col gap-[25px]'}>
                                        <div className={'max-w-[132px] h-5 grid grid-cols-[100px,1fr] gap-4'}>
                                            <span className={'text-[#FCC10F]'}>Տարիք՝</span>
                                            <p className={''}>{age}</p>
                                        </div>
                                        <div className={'max-w-[250px] h-5 grid grid-cols-[100px,1fr] gap-4'}>
                                            <span className={'text-[#FCC10F]'}>Հեռախոս՝</span>
                                            <DisplayPhoneNumber phoneNumber={phoneNumbers[0]}/>
                                        </div>
                                        <div className={'max-w-[260px] h-5 grid grid-cols-[100px,1fr] gap-4'}>
                                            <span className={'text-[#FCC10F]'}>Խմբեր՝</span>
                                            {currentGroups.groups?.length > 0
                                                ?
                                                currentGroups.groups?.length > 1
                                                    ?
                                                    <div className="relative">
                                                        <span
                                                            className="cursor-pointer"
                                                            onMouseEnter={handleMouseEnter}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            Խմբեր
                                                        </span>
                                                        <div
                                                            ref={dropdownRef}
                                                            className={`absolute w-[238px] flex flex-col gap-5 z-10 bg-white border shadow-md rounded-[3px] p-2.5 transition-transform ${dropdownPosition === 'top' ? 'bottom-full' : 'top-full'} ${showGroupsDropdown ? 'block' : 'hidden'}`}
                                                            style={{left: '30%'}}
                                                        >
                                                            {currentGroups.groups?.map(group => (
                                                                <Link
                                                                    to={`/groups/${group._id}/attendance`}
                                                                    title={group.name}
                                                                    className="max-w-[120px] text-[12px] hover:text-[#FCC10F] transition line-clamp-3"
                                                                    key={group._id}
                                                                >
                                                                    {group.name}
                                                                </Link>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    :
                                                    currentGroups.groups?.map(group =>
                                                        <Link to={`/groups/${group._id}/attendance`}
                                                              key={group._id} className={'line-clamp-1'}
                                                              title={group?.name}>
                                                            {group?.name}
                                                        </Link>)
                                                :
                                                <p>Խումբ չկա</p>
                                            }
                                        </div>
                                    </div>
                                    <div className={'w-[400px] flex flex-col gap-[25px]'}>
                                        <div className={'h-5 grid grid-cols-[100px,1fr] gap-4'}>
                                            <span className={'text-[#FCC10F]'}>Համայնք՝</span>
                                            <p className={'w-full'}>{community?.name || 'Առկա չէ'}</p>
                                        </div>
                                        <div className={'h-5 grid grid-cols-[100px,1fr] gap-4'}>
                                            <span className={'text-[#FCC10F]'}>Էլ․ փոստ՝</span>
                                            <p className={'w-full'}>{email || 'Առկա չէ'}</p>
                                        </div>
                                        <div className={'h-5 grid grid-cols-[100px,1fr] gap-4'}>
                                            <span className={'text-[#FCC10F]'}>Գրաֆիկ՝</span>
                                            {schedule?.length > 0
                                                ?
                                                schedule.length > 2
                                                    ?
                                                    <div className={"relative group"}>
                                                        <span className={'cursor-pointer'}>Գրաֆիկ</span>
                                                        <div
                                                            className={"w-[228px] absolute z-10 hidden group-hover:grid grid-cols-3 gap-y-2.5 bg-white border shadow-md rounded-[3px] p-2 left-0 mt-2"}>
                                                            {schedule.map((item, index) => (
                                                                <p key={index} className={'text-[12px]'}>{item}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className={'grid grid-cols-2'}>
                                                        {schedule.map((item, index) =>
                                                            <p className={'w-full'} key={index}>{item}</p>
                                                        )}
                                                    </div>
                                                :
                                                <p>Առկա չէ</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'flex flex-col gap-10'}>
                                <div className={'flex justify-end gap-[30px] mt-[30px]'}>
                                    {roles[authData?.role]?.some(role => role.showStudentQRButton) &&
                                        <ButtonHoverText text={'Արտահանել QR կոդ'} onClick={() => handleDownloadQrCodesPdf(_id)} icon={<QRIcon/>}/>
                                    }
                                    {roles[authData?.role]?.some(role => role.showResetPasswordButton) &&
                                        <ButtonHoverText text={'Վերականգնել գաղտնաբառը'}
                                                         icon={<KeyIcon color={'#FCC10F'}/>}
                                                         onClick={handleRecoverPassword}/>
                                    }
                                    {(roles[authData?.role]?.some(role => role.showEditStudentProfileButton) && globalStatus !== 'terminated')
                                        &&
                                        <Link
                                            to={`/students/${_id}?mode=edit&subpage=profile`}
                                            state={{from: location.pathname + location.search}}
                                            className={'font-bold hover:text-[#FCC10F] transition'}
                                        >
                                            <ButtonHoverText text={'Խմբագրել'}
                                                             icon={<EditIcon width={30} height={30}/>}/>
                                        </Link>}
                                </div>
                                {roles[authData?.role]?.some(role => role.showEditStudentStatusButton)
                                    ?
                                    <div className={'relative w-[222px]'}>
                                        <DropdownInputCheckbox
                                            data={studentStatusData.filter(item => item._id !== globalStatus)}
                                            name={'globalStatus'}
                                            showCheckbox={false}
                                            placeholder={'Կարգավիճակ'}
                                            borderColor={'#FCC10F'}
                                            previewClassName={'rounded-[5px]'}
                                            onChange={handleChangeStatus}
                                            selected={[studentStatusData.find(item => item._id === globalStatus)]}
                                            hideCheckbox={true}
                                            disableInput
                                        />
                                    </div>
                                    :
                                    <div className={'border border-[#FCC10F] rounded-[5px] p-3'}>
                                        {globalStatus === 'current' && 'Ընթացիկ'}
                                        {globalStatus === 'graduated' && 'Շրջանավարտ'}
                                        {globalStatus === 'terminated' && 'Կրթակարգից դուրս մնացած'}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentItem;
