import {combineReducers} from "redux";
import authReducer from "./reducers/auth";
import formsReducer from "./reducers/forms";
import requestsReducer from "./reducers/requests";
import commonsReducer from "./reducers/common";
import studentsReducer from "./reducers/students";
import groupsReducer from "./reducers/groups";
import programsReducer from "./reducers/programs";
import profileReducer from "./reducers/profile";
import communitiesReducer from "./reducers/communities";
import transportReducer from "./reducers/transport";
import statementsReducer from "./reducers/statements";
import socketReducer from "./reducers/socket";
import chatsReducer from "./reducers/chats";
import notificationsReducer from "./reducers/notifications";
import checkInsReducer from "./reducers/checkin";

export default combineReducers({
    auth: authReducer,
    forms: formsReducer,
    requests: requestsReducer,
    common: commonsReducer,
    students: studentsReducer,
    groups: groupsReducer,
    programs: programsReducer,
    profile: profileReducer,
    communities: communitiesReducer,
    transport: transportReducer,
    statements: statementsReducer,
    socket: socketReducer,
    chats: chatsReducer,
    notifications: notificationsReducer,
    checkin: checkInsReducer,
})
