import { useEffect, useState } from 'react';
import { decryptMessage, encryptMessage } from "../../socket/encryption";
import { useDispatch } from "react-redux";
import { setNotifications, setNewNotificationsCount } from "../../store/actions/notifications";
import notifications from "../../store/reducers/notifications"; // Assuming you have these actions

export const useNotifications = (socket) => {
    const dispatch = useDispatch()

    const getNotifications = async (type) => {
        if (socket) {
            const obj = {
                type: 'get_notifications',
                data: { type }
            }
            const encryptedMessage = await encryptMessage(obj)
            socket.emit('msg', encryptedMessage, async (response) => {
                response = await decryptMessage(response)
                dispatch(setNotifications(response.notifications))
            })
        }
    }

    const markNotificationAsRead = async (notificationId) => {
        if (socket) {
            const obj = {
                type: 'mark_notification_as_read',
                data: { notificationId }
            };
            const encryptedMessage = await encryptMessage(obj);
            socket.emit('msg', encryptedMessage, async (response) => {
                response = await decryptMessage(response)
                console.log(response)

                // dispatch(setNotifications(response.notifications))
            });
        }
    }

    const markAllNotificationsAsRead = async () => {
        if (socket) {
            const obj = {
                type: 'mark_all_notifications_as_read',
            }
            const encryptedMessage = await encryptMessage(obj)
            socket.emit('msg', encryptedMessage, async (response) => {
                response = await decryptMessage(response)
                console.log(response)
                // dispatch(setNotifications(response.notifications))
            })
        }
    }

    useEffect(() => {
        if (socket) {
            socket.on('notification', async (response) => {
                response = await decryptMessage(response)
                dispatch(setNotifications(response.notifications))
            })

            socket.on('user_has_read_notification', async (response) => {
                response = await decryptMessage(response)
                dispatch(setNotifications(response.notifications))
            })
        }

        return () => {
            if (socket) {
                socket.off('notification')
                socket.off('user_has_read_notification')
            }
        }
    }, [socket]);

    return {
        getNotifications,
        markNotificationAsRead,
        markAllNotificationsAsRead,
    }
}