import React, { useRef, useState } from 'react';
import {
    CakeIcon,
    CompletedIcon,
    DroppedIcon,
    GeoIcon,
    MenuIcon,
    ParticipatedIcon,
    PhoneIcon
} from "../../../../../assets/icons";
import { useOutsideClick } from "../../../../../hooks/useOutsideClick";
import { Link } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import ButtonHoverText from "../../../../shared/buttons/ButtonHoverText";
import Portal from '../../../../shared/Portal';
import {roles} from "../../../../../common/roles";

const StudentItem = ({ student, status, index, openEditStudentModal, lineHovered, hoverLine, handleRemoveStudent, tempStatus }) => {
    const { authData } = useSelector((state) => state.auth)

    const [showMenu, setShowMenu] = useState(false)
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 })

    const [showDetails, setShowDetails] = useState(false)

    const ref = useRef(null)
    const menuButtonRef = useRef(null)

    useOutsideClick(ref, () => setShowMenu(false))

    const toggleShowDetails = () => {
        setShowDetails(!showDetails)
    }

    const handleMenuClick = () => {
        const rect = menuButtonRef.current.getBoundingClientRect()
        const scrollY = window.scrollY
        const scrollX = window.scrollX

        const menuHeight = 200
        const spaceBelow = window.innerHeight - rect.bottom
        const offset = 10

        setMenuPosition({
            top: spaceBelow < menuHeight ? rect.top + scrollY - menuHeight - offset : rect.bottom + scrollY,
            left: rect.right + scrollX,
        });
        setShowMenu(!showMenu);
    };

    const handleChangeStudentStatus = (type) => {
        openEditStudentModal({ student, type })
    }

    const StudentInfo = () => (
        <div
            className={`absolute mb-2 bottom-full w-full shadow-md custom-shadow transition z-50 rounded-[3px] bg-white px-5 py-[7px] group-hover:block hidden`}
        >
            <div className={'flex gap-5'}>
                <div className={'flex items-center'}>
                    <img src={student.profileImage} loading={'lazy'}
                         className={'w-[60px] aspect-square rounded-full'}
                         alt="profile-image"
                    />
                </div>
                <div className={'flex flex-col gap-[7px] text-sm'}>
                    <div className={'flex items-center gap-[15px]'}>
                        <GeoIcon />
                        <span>{student.community?.name}</span>
                    </div>
                    <div className={'flex items-center gap-[15px]'}>
                        <PhoneIcon />
                        <span>{student.phoneNumbers[0]}</span>
                    </div>
                    <div className={'flex items-center gap-[15px]'}>
                        <CakeIcon />
                        <span>{student.age}</span>
                    </div>
                </div>
            </div>

            <div className={'relative flex items-center justify-center'}>
                <div className={'w-2 h-2 bg-white absolute top-[calc(100%+1.1px)] transform rotate-[45deg]'}></div>
            </div>
        </div>
    );

    return (
        <>
            <div
                className={`relative place-items-center grid grid-cols-[1fr,20px] h-10 pl-5 pr-2.5 border-t ${lineHovered === index && 'bg-[#f2f2f2]'}`}
                // onMouseOver={() => hoverLine(index)}
                // onMouseLeave={() => hoverLine(null)}
            >

                <div className={`relative group w-full h-fit flex items-center justify-start gap-[10px]`}>
                    <StudentInfo {...student} />

                    <div
                        title={student.fullName}
                        className={`flex items-center gap-2.5 ${roles[authData?.role]?.some(role => role.allowChangeStudentStatus) ? 'max-w-[260px]' : 'max-w-[300px]'} line-clamp-1`}>
                        <span>{index + 1}</span>
                        <Link
                            to={`/students/${student._id}/?mode=view&subpage=profile`}
                            className={'truncate'}
                            onMouseOver={toggleShowDetails}
                            onMouseLeave={toggleShowDetails}
                        >{student.fullName}</Link>
                    </div>
                </div>

                <div className={'flex items-center gap-1'}>
                    <div>
                        {(status === 'completed' || tempStatus === 'completed') &&
                            <ButtonHoverText icon={<CompletedIcon width={10} height={10} />} text={'Ավարտած'} />}
                        {(status === 'participated' || tempStatus === 'participated') &&
                            <ButtonHoverText icon={<ParticipatedIcon width={10} height={10} />} text={'Մասնակից'} />}
                        {(status === 'dropped' || tempStatus === 'dropped') && <ButtonHoverText icon={<DroppedIcon width={10} height={10} />} text={'Չավարտած'} />}
                    </div>
                    {roles[authData?.role]?.some(role => role.allowChangeStudentStatus) && (
                        <div className={'flex items-center'}>
                            <button ref={menuButtonRef} onClick={handleMenuClick}
                                    className={`${showMenu ? 'pointer-events-none' : 'pointer-events-auto'} w-[20px] h-[20px] `}>
                                <MenuIcon />
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {showMenu && (
                <Portal top={menuPosition.top} left={window.innerWidth - menuPosition.left} alignment="right">
                    <div ref={ref}
                         className={`w-[185px] flex flex-col gap-4 bg-white py-5 border border-black rounded-[5px]`}>
                        <p onClick={() => handleRemoveStudent(student._id)}
                           className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Հեռացնել
                        </p>
                        <p onClick={() => handleChangeStudentStatus('participated')}
                           className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Մասնակից
                        </p>
                        <p onClick={() => handleChangeStudentStatus('completed')}
                           className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                            Ավարտած
                        </p>
                        {status !== 'dropped'
                            ?
                            <p onClick={() => handleChangeStudentStatus('dropped')}
                               className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Չավարտած
                            </p>
                            :
                            <p onClick={() => handleChangeStudentStatus('restored')}
                               className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                Վերականգնել
                            </p>
                        }
                    </div>
                </Portal>
            )}
        </>
    );
};

export default StudentItem;