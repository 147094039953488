import React, {useRef} from 'react';
import {useOutsideClick} from "../../../../../hooks/useOutsideClick";

const ImageModal = ({ close, imageSrc }) => {
    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div onClick={close} className={'fixed bg-black bg-opacity-5 w-full h-full top-0 left-0 z-[9990]'}></div>
            <div className={'absolute top-0 left-0 w-full h-full flex items-center justify-center'}>
                <img src={imageSrc} alt="full-screen-preview" className={'max-w-full max-h-full'}/>
            </div>
        </div>
    );
};

export default ImageModal;