import React, {useEffect, useState} from 'react';
import {ageGroupDataHybrid, groupStatusData} from "../../common/dropdownOptions";
import MobileDropdown from "../shared/dropdowns/MobileDropdown";
import {useDispatch, useSelector} from "react-redux";
import {Container} from "../shared";
import {CloseIcon, FilterIcon} from "../../assets/icons";
import {getScheduleCodes} from "../../store/actions/common";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";

const GroupsFilterMobile = ({
                                filters,
                                discardFilters,
                                submitFilters,
                                changeSelectedFilters,
                                clearAllFilters,
                                removeFilters,
                                handleChange
                            }) => {
    const {programs, scheduleCodes} = useSelector(state => state.common)

    const [showFilters, setShowFilters] = useState(false)

    const dispatch = useDispatch()

    const selectedFilters = (filters?.name || filters?.status || filters?.program || filters?.ageGroup || filters?.schedule)

    useEffect(() => {
        dispatch(getScheduleCodes())
    }, [])

    return (
        <Container>
            <div className={'fixed left-[70%] z-[99999] flex justify-end'}>
                <div className={'relative'}>
                    <div
                        onClick={() => setShowFilters(!showFilters)}
                        className={`relative flex items-center gap-2.5 bg-white`}
                    >
                        <span className={`${showFilters && 'text-[#FCC10F]'} text-[14px]`}>Ֆիլտր</span>
                        <FilterIcon color={showFilters && '#FCC10F'}/>
                    </div>

                    {showFilters &&
                        <div
                            className={'absolute top-full right-[5%] z-[9999] w-[281px] min-h-[424px] mt-2.5 p-[30px] bg-white border border-black rounded-[3px]'}>
                            <h2 className={'text-[14px] text-[#FCC10F] mb-5'}>Ֆիլտր</h2>

                            <div className={'max-h-[250px] overflow-y-auto'}>
                                <div className={'text-[14px] border-b border-b-black border-opacity-50 pb-[15px]'}>
                                    <input
                                        name={'name'}
                                        className={'outline-none custom-placeholder'}
                                        autoComplete={'off'}
                                        value={filters?.name || ''}
                                        onChange={handleChange}
                                        placeholder={"Խմբի անուն"}
                                        // onKeyDown={handleEnter}
                                    />
                                </div>

                                <MobileDropdown
                                    data={groupStatusData}
                                    name={'status'}
                                    placeholder={'Կարգավիճակ'}
                                    className={'border-b border-black border-opacity-50 py-[15px]'}
                                    onChange={changeSelectedFilters}
                                    selected={filters?.status}
                                    removeFilters={removeFilters}
                                />

                                <MobileDropdown
                                    data={programs}
                                    placeholder={'Ծրագրեր'}
                                    className={'border-b border-black border-opacity-50 py-[15px]'}
                                    name={'program'}
                                    onChange={changeSelectedFilters}
                                    selected={filters?.program}
                                    removeFilters={removeFilters}
                                />

                                <MobileDropdown
                                    data={ageGroupDataHybrid}
                                    placeholder={'Տարիքային խումբ'}
                                    className={'border-b border-black border-opacity-50 py-[15px]'}
                                    name={'ageGroup'}
                                    onChange={changeSelectedFilters}
                                    selected={filters?.ageGroup}
                                    removeFilters={removeFilters}
                                />

                                <MobileDropdown
                                    data={scheduleCodes}
                                    placeholder={'Գրաֆիկ'}
                                    className={'border-b border-black border-opacity-50 py-[15px]'}
                                    name={'schedule'}
                                    onChange={changeSelectedFilters}
                                    selected={filters?.schedule}
                                    removeFilters={removeFilters}
                                />
                            </div>

                            <div className={'flex items-center justify-center'}>
                                {selectedFilters?.length > 0 &&
                                    <div
                                        onClick={clearAllFilters}
                                        className={'w-fit flex items-center gap-3 px-1 bg-[#D9D9D940] rounded-[3px] text-[14px] mt-5'}>
                                        <span>Ջնջել բոլորը</span>
                                        <CloseIcon width={17} height={17}/>
                                    </div>
                                }
                            </div>

                            <div className={`flex gap-3 items-center ${selectedFilters?.length > 0 ? 'mt-5' : 'mt-[60px]'}`}>
                                <ButtonOutlined
                                    onClick={discardFilters}
                                    className={'w-[108px] h-[27px] text-[14px]'}
                                    text={'Չեղարկել'}
                                />
                                <ButtonFilled
                                    onClick={() => submitFilters()}
                                    className={'w-[108px] h-[27px] text-[14px]'}
                                    text={'Կիրառել'}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Container>
    )
}

export default GroupsFilterMobile