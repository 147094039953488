import React, {useEffect, useState} from 'react';
import {CloseIcon, FilterIcon} from "../../../../assets/icons";
import MobileDropdown from "../../../shared/dropdowns/MobileDropdown";
import {ageGroupDataHybrid, groupStatusData, sortProjectsData} from "../../../../common/dropdownOptions";
import {ButtonFilled, ButtonOutlined} from "../../../shared/buttons";
import {Container} from "../../../shared";
import {useDispatch, useSelector} from "react-redux";
import {getScheduleCodes} from "../../../../store/actions/common";
import {DropdownInputCheckbox} from "../../../shared/dropdowns";
import {useNavigate} from "react-router-dom";
import Calendar from "../../../shared/calendar/Calendar";

const ProjectsFilterMobile = ({
                                  filters,
                                  setFilters,
                                  discardFilters,
                                  submitFilters,
                                  changeSelectedFilters,
                                  clearAllFilters,
                                  removeFilters,
                                  handleChange
                              }) => {

    const {programs, scheduleCodes} = useSelector(state => state.common)

    const [showFilters, setShowFilters] = useState(false)
    const [sortOption, setSortOption] = useState(sortProjectsData[0])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const selectedFilters = (filters?.name || filters?.groupName || filters?.program || filters?.fromDate || filters?.toDate)

    const handleChangeSortOption = (name, option) => {
        navigate(`/projects?sort=${option._id}`)
        setSortOption(option)
    }

    const handleChangeFromDate = (date) => {
        setFilters({
            ...filters,
            fromDate: date
        })
    }

    const handleChangeToDate = (date) => {
        setFilters({
            ...filters,
            toDate: date
        })
    }

    useEffect(() => {
        dispatch(getScheduleCodes())
    }, [])

    return (
        <Container>
            <div className={'fixed left-[75%] z-[999] flex justify-end'}>
                <div className={'relative'}>
                    <div
                        onClick={() => setShowFilters(!showFilters)}
                        className={`relative flex items-center gap-2.5 bg-white`}
                    >
                        <span className={`${showFilters && 'text-[#FCC10F]'} text-[14px]`}>Ֆիլտր</span>
                        <FilterIcon color={showFilters && '#FCC10F'}/>
                    </div>

                    {showFilters &&
                        <div
                            className={'absolute top-full right-[5%] z-[9999] w-[345px] min-h-[424px] mt-2.5 p-[30px] bg-white border border-black rounded-[3px]'}>
                            <h2 className={'text-[14px] text-[#FCC10F] mb-5'}>Ֆիլտր</h2>

                            <div className={'max-h-[300px] overflow-y-auto'}>
                                <MobileDropdown
                                    data={sortProjectsData}
                                    name={'project'}
                                    placeholder={'Դասակարգել'}
                                    className={'border-b border-black border-opacity-50 pb-[15px]'}
                                    onChange={handleChangeSortOption}
                                    selected={[sortOption]}
                                    hideCheckbox
                                    removeFilters={removeFilters}
                                />

                                <div className={'text-[14px] border-b border-b-black border-opacity-50 py-[15px]'}>
                                    <input
                                        name={'name'}
                                        className={'outline-none custom-placeholder'}
                                        autoComplete={'off'}
                                        value={filters?.name || ''}
                                        onChange={handleChange}
                                        placeholder={"Նախագծի անուն"}
                                        // onKeyDown={handleEnter}
                                    />
                                </div>

                                <MobileDropdown
                                    data={programs}
                                    placeholder={'Ծրագրեր'}
                                    className={'border-b border-black border-opacity-50 py-[15px]'}
                                    name={'program'}
                                    onChange={changeSelectedFilters}
                                    selected={filters?.program}
                                    removeFilters={removeFilters}
                                />

                                <div className={'text-[14px] border-b border-b-black border-opacity-50 py-[15px]'}>
                                    <input
                                        name={'groupName'}
                                        className={'outline-none custom-placeholder'}
                                        autoComplete={'off'}
                                        value={filters?.groupName || ''}
                                        onChange={handleChange}
                                        placeholder={"Խմբի անուն"}
                                        // onKeyDown={handleEnter}
                                    />
                                </div>

                                <div className={'text-[14px] border-b border-b-black border-opacity-50 py-[15px]'}>
                                    <Calendar displayFormat={'DD.MM.YYYY'}
                                              returnFormat={'YYYY-MM-DD'}
                                              placeholder={'Սկիզբ'}
                                              className={'py-[1px]'}
                                              paddingClassName={'px-0'}
                                              optionClassName={'px-2'}
                                              previewClassName={'px-0'}
                                              gapClassName={'gap-1'}
                                              type={'mobile'}
                                              textSize={'text-[12px]'}
                                              showShortMonths={true}
                                              calendarIconWidth={17}
                                              calendarIconHeight={17}
                                              setDate={handleChangeFromDate}
                                              value={filters?.fromDate}
                                    />
                                </div>

                                <div className={'text-[14px] py-[15px]'}>
                                    <Calendar displayFormat={'DD.MM.YYYY'}
                                              returnFormat={'YYYY-MM-DD'}
                                              placeholder={'Ավարտ'}
                                              className={'py-[1px]'}
                                              paddingClassName={'px-0'}
                                              previewClassName={'px-0'}
                                              type={'mobile'}
                                              optionClassName={'px-2'}
                                              gapClassName={'gap-1'}
                                              textSize={'text-[12px]'}
                                              showShortMonths={true}
                                              calendarIconWidth={17}
                                              calendarIconHeight={17}
                                              setDate={handleChangeToDate}
                                              value={filters?.toDate}
                                    />
                                </div>
                            </div>

                            <div className={'flex items-center justify-center'}>
                                {selectedFilters?.length > 0 &&
                                    <div
                                        onClick={clearAllFilters}
                                        className={'w-fit flex items-center gap-3 px-1 bg-[#D9D9D940] rounded-[3px] text-[14px]'}>
                                        <span>Ջնջել բոլորը</span>
                                        <CloseIcon width={17} height={17}/>
                                    </div>
                                }
                            </div>

                            <div
                                className={`flex gap-3 items-center justify-end ${selectedFilters?.length > 0 ? 'mt-7' : 'mt-3'}`}>
                                <ButtonOutlined
                                    onClick={discardFilters}
                                    className={'w-[108px] h-[27px] text-[14px]'}
                                    text={'Չեղարկել'}
                                />
                                <ButtonFilled
                                    onClick={() => submitFilters()}
                                    className={'w-[108px] h-[27px] text-[14px]'}
                                    text={'Կիրառել'}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Container>
    );
};

export default ProjectsFilterMobile;