import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getProgramGroups} from "../../../../store/actions/programs";
import {useParams} from "react-router-dom";
import {Container} from "../../../../components/shared";
import GroupCard from "../../../../components/groups/GroupCard";
import {deleteGroup} from "../../../../store/actions/groups";
import Pagination from "../../../../components/shared/Pagination";
import EventCard from "../../../../components/smartEvents/EventCard";

const ProgramGroups = () => {
    const {groups, loading} = useSelector(state => state.programs);
    const [groupToDelete, setGroupToDelete] = useState(null)
    const [page, setPage] = useState(1)

    const {id} = useParams()
    const dispatch = useDispatch()

    const handleChangePage = (_, p) => {
        window.scrollTo({top: 0})
        setPage(p)
    }
    const handleDeleteGroup = () => {
        dispatch(deleteGroup(groupToDelete))
        setGroupToDelete(null)
    }

    useEffect(() => {
        dispatch(getProgramGroups(id, page))
    }, [page])

    return (
        <div>
            <Container>
                {loading
                    ?
                    <p className={'text-center'}>Բեռնվում է․․․</p>
                    :
                    groups?.data?.length > 0 ?
                        <>
                            <div
                                className={'grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items-stretch gap-[32px] mt-[30px]'}>
                                {groups?.data?.map((group, index) => (
                                    <div key={index} className={'w-full h-full flex justify-center items-center'}>
                                        {group.type === 'essential'
                                            ?
                                            <GroupCard
                                                {...group}
                                                handleDeleteGroup={handleDeleteGroup}
                                                groupToDelete={groupToDelete}
                                                setGroupToDelete={setGroupToDelete}
                                            />
                                            :
                                            <EventCard
                                                {...group}
                                                handleDeleteWorkshop={handleDeleteGroup}
                                                handleDeleteConnect={handleDeleteGroup}
                                                handleDeleteTrip={handleDeleteGroup}
                                                workshopToDelete={groupToDelete}
                                                connectToDelete={groupToDelete}
                                                tripToDelete={groupToDelete}
                                                setWorkshopToDelete={setGroupToDelete}
                                                setConnectToDelete={setGroupToDelete}
                                                setTripToDelete={setGroupToDelete}
                                            />
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className={'mt-[30px]'}>
                                <Pagination count={groups?.pagesCount} page={page} onChange={handleChangePage}/>
                            </div>
                        </>
                        :
                        <p>Խմբեր չկան</p>
                }

            </Container>
        </div>
    );
};

export default ProgramGroups;