import React, {useState} from 'react';
import {Container} from "../../../../components/shared";
import {Link, useNavigate} from "react-router-dom";
import {ReturnLeftIcon} from "../../../../assets/icons";
import StopsFilter from "../../../../components/transport/subpages/stops/StopsFilter";
import VehiclesFilter from "../../../../components/transport/vehicles/VehiclesFilter";
import {useFilters} from "../../../../hooks/useFilters";
import {deleteTransportStop, deleteVehicle, getTransportStops, getVehicles} from "../../../../store/actions/transport";
import {useDispatch, useSelector} from "react-redux";
import AddButton from "../../../../components/shared/buttons/AddButton";
import StopCard from "../../../../components/transport/subpages/stops/StopCard";
import Pagination from "../../../../components/shared/Pagination";
import VehicleCard from "../../../../components/transport/vehicles/VehicleCard";
import {roles} from "../../../../common/roles";

const initialFilters = {
    page: 1,
    size: 9
}

const Vehicles = () => {
    const {vehicles, loading} = useSelector(state => state.transport)
    const {authData} = useSelector(state => state.auth)

    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        changePage,
        submitFilters,
        setFilters
    } = useFilters(initialFilters, getVehicles, 'vehicles')

    const [vehicleToDelete, setVehicleToDelete] = useState(null)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleDeleteVehicle = () => {
        dispatch(deleteVehicle(vehicleToDelete))
        setVehicleToDelete(null)
    }

    return (
        <div>
            <Container>
                {/*<Link to={'/transport'} className={'flex items-center gap-[30px] group w-fit cursor-pointer fill-icon'}>*/}
                {/*    <ReturnLeftIcon/>*/}
                {/*    <p className={'transition group-hover:text-[#FCC10F]'}>Տրանսպորտ</p>*/}
                {/*</Link>*/}
                <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Մեքենաներ</h2>
            </Container>
            <VehiclesFilter
                filters={filters}
                setFilters={setFilters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
            />

            <Container>
                {roles[authData?.role]?.some(role => role.allowAllActionsInTransport) &&
                    <AddButton
                        text={'Նոր մեքենա'}
                        onClick={() => navigate('/vehicles/create')}
                        className={'hover:bg-white transition border border-[#FCC10F] hover:text-[#FCC10F]'}
                    />
                }
                {loading
                    ?
                    <p className={'text-center'}>Բեռնվում է․․․</p>
                    :
                    vehicles?.data?.length > 0
                        ?
                        <>
                            <div className={'grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-[30px] mt-[30px]'}>
                                {vehicles?.data?.map((vehicle, index) =>
                                    <VehicleCard
                                        {...vehicle}
                                        key={index}
                                        handleDeleteVehicle={handleDeleteVehicle}
                                        vehicleToDelete={vehicleToDelete}
                                        setVehicleToDelete={setVehicleToDelete}
                                    />)}
                            </div>
                            <Pagination count={vehicles?.pagesCount} page={filters?.page || 1} onChange={changePage}/>
                        </>
                        :
                        <p className={'text-center'}>Մեքենաներ չկան</p>
                }
            </Container>
        </div>
    );
};

export default Vehicles;