import { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import {PauseIcon, PlayIcon} from "../../../../assets/icons";
import {useMediaQuery} from "react-responsive";

const VoiceMessagePlayer = ({ voiceUrl, messageId }) => {
    const waveSurferRef = useRef(null); // Store the WaveSurfer instance
    const [isPlaying, setIsPlaying] = useState(false); // Track play/pause state

    const isPhone = useMediaQuery({maxWidth: 640})

    useEffect(() => {
        waveSurferRef.current = WaveSurfer.create({
            container: `#waveform-${messageId}`,
            waveColor: '#ddd',
            progressColor: '#000',
            height: 40,
            width: isPhone ? 278 : 500
        })

        waveSurferRef.current.load(voiceUrl)

        return () => {
            if (waveSurferRef.current) {
                waveSurferRef.current.destroy()
            }
        }
    }, [voiceUrl, messageId])

    const handlePlayPause = () => {
        if (waveSurferRef.current) {
            waveSurferRef.current.playPause()
            setIsPlaying(!isPlaying)
        }
    }

    return (
        <div className="audio-player-wrapper flex px-2.5">
            <button onClick={handlePlayPause} className="play-pause-button">
                {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </button>
            <div id={`waveform-${messageId}`} className="waveform"></div>
        </div>
    );
};

export default VoiceMessagePlayer