import React, {useEffect, useRef, useState} from 'react'
import {gsap} from 'gsap'
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {CheckedIcon, CloseIcon, DropdownArrow} from "../../../assets/icons";

const MobileDropdown = ({data, placeholder, className, onChange, name, selected, removeFilters, hideCheckbox}) => {
    const [openOptions, setOpenOptions] = useState(null)
    const optionsRef = useRef(null)
    const selectedRef = useRef(null)

    const handleSelectOption = (option) => {
        onChange(name, option)
    }

    useOutsideClick(optionsRef, () => setOpenOptions(null))

    useEffect(() => {
        const optionsElement = optionsRef.current;
        const selectedElement = selectedRef.current;

        if (openOptions) {
            gsap.fromTo(
                optionsElement,
                {opacity: 0, height: 0, display: 'block'},
                {opacity: 1, height: 'auto', duration: 0.3}
            )
            gsap.fromTo(
                selectedElement,
                {borderBottomColor: 'none', marginBottom: 0},
                {borderBottomColor: "black", marginBottom: 8, duration: 0.3}
            );
        } else {
            gsap.to(optionsElement, {opacity: 0, height: 0, duration: 0.2, display: 'none'})
            gsap.to(selectedElement, {borderBottomColor: 'none', marginBottom: 0, duration: 0.2})
        }
    }, [openOptions])

    return (
        <div className={className}>
            <div
                ref={selectedRef}
                onClick={() => setOpenOptions(!openOptions)}
                className={`flex items-center justify-between ${openOptions ? 'pointer-events-none' : 'pointer-events-auto'}`}
            >
                <p className={'text-[14px]'}>{placeholder}</p>
                <button className={`${openOptions && 'rotate-180'} transition`}><DropdownArrow/></button>
            </div>

            {openOptions &&
                <div ref={optionsRef}>
                    {selected?.length > 0 && !hideCheckbox &&
                        <div
                            onClick={() => removeFilters(name)}
                            className={'w-fit flex items-center gap-3 px-1 bg-[#D9D9D940] rounded-[3px] text-[14px] mt-5'}>
                            <span>Մաքրել ֆիլտրը</span>
                            <CloseIcon width={17} height={17}/>
                        </div>
                    }
                    <div className={'mt-5'}>
                        {data?.map(item => {
                                const checked = selected?.find(option => option?._id === item?._id)
                                return (
                                    <div
                                        onClick={(e) => handleSelectOption(item, e)}
                                        className={'flex items-center mt-5 gap-5'}>
                                        {!hideCheckbox &&
                                            <div
                                                className={'flex items-center justify-center w-[17px] h-[17px] border border-black rounded-[3px]'}>
                                                {checked && <CheckedIcon/>}
                                            </div>
                                        }
                                        <div key={item._id} className={'text-[14px]'}>{item.name}</div>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>
            }
        </div>
    );
};

export default MobileDropdown;