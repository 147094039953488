import React, {useEffect, useState} from 'react';
import {CalendarArrow} from "../../../assets/icons";
import moment from "moment";

const SelectYear = ({selectedYear, handleYearChange, className, textSize, arrowSize}) => {
    const startYear = 1970
    const lastYear = moment().year() + 1
    const nextIndex = selectedYear < lastYear ? selectedYear + 1 : null
    const prevIndex = selectedYear > startYear ? selectedYear - 1 : null

    const [years, setYears] = useState([])

    const handleNextYear = () => {
        handleYearChange(nextIndex)
    }

    const handlePrevYear = () => {
        handleYearChange(prevIndex)
    }

    const handleSelectYear = (e) => {
        handleYearChange(parseInt(e.target.value))
    }

    useEffect(() => {
        const yearsArr = []
        for (let i = startYear; i <= lastYear; i++) {
            yearsArr.push(i)
        }
        setYears(yearsArr)
    }, []);

    return (
        <div className={`flex items-center ${className || 'gap-5'}`}>
            <button
                className={`flex items-center justify-center transition p-1 rounded-full ${prevIndex !== null ? 'hover:bg-gray-200 opacity-100 cursor-pointer' : 'opacity-40'}`}
                disabled={prevIndex === null} onClick={handlePrevYear}>
                <CalendarArrow width={arrowSize} height={arrowSize}/>
            </button>
            <select value={selectedYear} className={`outline-none ${textSize && 'text-[13px]'}`} onChange={handleSelectYear}>
                {years?.map((year) => {
                    return <option key={year}>{year}</option>
                })}
            </select>
            <button
                className={`flex items-center justify-center transition rotate-180 p-1 rounded-full ${nextIndex !== null ? 'hover:bg-gray-200 opacity-100 cursor-pointer' : 'opacity-40'}`}
                disabled={nextIndex === null} onClick={handleNextYear}>
                <CalendarArrow width={arrowSize} height={arrowSize}/>
            </button>
        </div>
    );
};

export default SelectYear;