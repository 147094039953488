import React from 'react';
import {CalendarIcon, InstructorIcon, PeopleIcon, RecoverIcon} from "../../../assets/icons";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {daysOfWeek} from "../../../common/dropdownOptions";

const IntroInfo = ({instructors, studentsCount, scheduleCodes, startDate, endDate, activeStudentsCount}) => {
    const startDateMoment = moment(startDate, 'YYYY-MM-DD').format('DD.MM.YYYY');
    const endDateMoment = moment(endDate, 'YYYY-MM-DD').format('DD.MM.YYYY');

    const schedule = scheduleCodes?.map((scheduleCode) => {
        const [dayIndex, time] = scheduleCode.split('_');
        const weekday = daysOfWeek[dayIndex];
        return {weekday, time};
    });

    return (
        <div className="flex justify-between items-center mt-[30px] pb-5">
            <div className="flex items-center gap-2.5">
                <InstructorIcon/>
                {instructors?.length > 1 ?
                    <div className="relative group">
                        <span className="cursor-pointer">Ծրագրավարներ</span>
                        <div
                            className="absolute left-full w-[250px] z-10 hidden group-hover:grid grid-cols-2 gap-3 bg-white border shadow-md rounded-[3px] p-2 mt-2 transition">
                            {instructors?.map((instructor) => (
                                <Link
                                    to={`/instructors/${instructor._id}`}
                                    className="max-w-[120px] text-[12px] hover:text-[#FCC10F] transition"
                                    key={instructor._id}
                                >
                                    {instructor.fullName}
                                </Link>
                            ))}
                        </div>
                    </div>
                    :
                    instructors?.map((instructor) => (
                        <Link to={`/instructors/${instructor._id}`} className="hover:text-[#FCC10F] transition"
                              key={instructor._id}>
                            {instructor.fullName}
                        </Link>
                    ))
                }
            </div>

            <p className={'relative group flex items-center gap-[10px] select-none'}>
                <PeopleIcon color={'#FCC10F'}/>
                <span>{activeStudentsCount || (activeStudentsCount === 0 && studentsCount)}</span>
                <span
                    className={`absolute w-[220px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ակտիվ ուսանողների քանակ</span>
            </p>

            <div className="flex items-center gap-[10px]">
                <CalendarIcon color="#FCC10F"/>
                <div className="flex items-start flex-col gap-[30px]">
                    {schedule?.length > 2 ?
                        <div className="relative group">
                            <span className="cursor-pointer">Գրաֆիկ</span>
                            <div
                                className="w-[250px] absolute z-10 hidden group-hover:grid grid-cols-3 gap-y-2.5 bg-white border shadow-md rounded-[3px] p-2 left-0 mt-2">
                                {schedule?.map((item, index) => (
                                    <p key={index}
                                       className="text-[12px]">{`${item.weekday}. ${item.time.split('-')[0]}`}</p>
                                ))}
                            </div>
                        </div>
                        :
                        <div className={'flex'}>
                            {schedule?.map((item, index) => {
                                const isLast = index === schedule.length - 1
                                const isFirst = index === 0

                                return <div key={index}
                                            className={`${!isLast && 'h-5 border-r border-r-[#FCC10F]'} ${isFirst ? 'pr-5' : 'px-5 '} w-fit pr-5`}>{`${item.weekday}. ${item.time}`}</div>
                            })}
                        </div>
                    }
                </div>
            </div>

            <div className="flex items-center gap-[10px]">
                <RecoverIcon/>
                <span>{startDateMoment} - {endDateMoment}</span>
            </div>
        </div>
    );
};

export default IntroInfo;