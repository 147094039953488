import {
    CHANGE_EDIT_ELEMENT, CHANGE_FIELD, CHANGE_FOCUS, CHANGE_POSITION,
    CHANGE_REQUEST_FORM_ACCESS, CREATE_FIELD, CREATE_FORM, DELETE_FIELD, DELETE_REQUEST_FORM, DUPLICATE_FIELD,
    GET_FORM_NAMES, GET_FORM_REQUIREMENTS,
    GET_REQUEST_FORM, GET_REQUEST_FORMS, SAVE_REQUEST_FORM,
    SET_LOADING,
    SUBMIT_FORM
} from '../constants/actionTypes'
import {v4 as uuidv4} from "uuid"

const generateRequestFormInitialBody = () => {
    return [
        {
            id: uuidv4(),
            name: "Անուն",
            placeholder: "Գրել անունը",
            category: "firstName",
            type: "text",
            mandatory: true,
            info: null,
            options: null,
            answer: "",
            focused: true
        },
        {
            id: uuidv4(),
            name: "Ազգանուն",
            placeholder: "Գրել ազգանունը",
            category: "lastName",
            type: "text",
            mandatory: true,
            info: null,
            options: null,
            answer: "",
            focused: false
        },
        {
            id: uuidv4(),
            name: "Հայրանուն",
            placeholder: "Գրել հայրանունը",
            category: "middleName",
            type: "text",
            mandatory: true,
            info: null,
            options: null,
            answer: "",
            focused: false
        },
        {
            id: uuidv4(),
            name: "Սեռ",
            placeholder: "Ընտրել սեռը",
            category: "gender",
            type: "dropdown",
            mandatory: true,
            info: null,
            options: [
                {
                    id: uuidv4(),
                    value: "Արական"
                },
                {
                    id: uuidv4(),
                    value: "Իգական"
                }
            ],
            answer: "",
            focused: false
        },
        {
            id: uuidv4(),
            name: "Դիմողի ծննդյան տվյալներ",
            placeholder: "օր/ամիս/տարի",
            category: "birthday",
            type: "calendar",
            mandatory: true,
            info: null,
            options: null,
            answer: "",
            focused: false
        },
        {
            id: uuidv4(),
            name: "Դիմողի ծննդյան վկայականի կամ անձնագրի համար",
            placeholder: "AM000000",
            category: "birthCertificate",
            type: "text",
            mandatory: true,
            info: "օրինակ՝ ծննդյան վկայական ԱԱ 000000, անձնագիր AA 000000",
            options: null,
            answer: "",
            focused: false
        },
        {
            id: uuidv4(),
            name: "Համայնք",
            placeholder: "Ընտրել համայնքը",
            category: "community",
            type: "dropdown",
            mandatory: true,
            info: null,
            options: [
                {
                    id: uuidv4(),
                    value: "Տարբերակ 1"
                },
                {
                    id: uuidv4(),
                    value: "Տարբերակ 2"
                }
            ],
            answer: "",
            focused: false
        },
        {
            id: uuidv4(),
            name: "Հեռախոսահամար 1",
            placeholder: "Հուշող տեքստ",
            category: "phoneNumber",
            type: "phoneNumber",
            mandatory: true,
            info: null,
            options: null,
            answer: "",
            focused: false
        },
        {
            id: uuidv4(),
            name: "Հեռախոսահամար 2",
            placeholder: "Հուշող տեքստ",
            category: "phoneNumber",
            type: "phoneNumber",
            mandatory: true,
            info: null,
            options: null,
            answer: "",
            focused: false
        },
        {
            id: uuidv4(),
            name: "Շնորհակալություն",
            placeholder: "Ձեր հայտն ուղարկված է",
            category: null,
            type: "successMessage",
            mandatory: false,
            info: null,
            options: null,
            answer: ""
        }
    ]
}

const initialState = {
    forms: null,
    formNames: null,
    form: null,
    loading: false,
    requests: null,
    fields: null,
    submitted: false,
}

const formsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REQUEST_FORMS:
            return {...state, forms: action.payload, form: null}
        case SAVE_REQUEST_FORM:
            return {...state, form: action.payload.form}
        case CREATE_FORM:
            const {type} = action.payload

            const form = {
                name: 'Վերնագիր',
                description: 'Նկարագիր',
                type,
                body: type === 'request' ? generateRequestFormInitialBody() : []
            }
            return {...state, form}
        case CREATE_FIELD:
            const {fieldType} = action.payload

            const createField = (type) => {
                return {
                    id: uuidv4(),
                    name: 'Վերնագիր',
                    placeholder: 'Հուշող տեքստ',
                    category: null,
                    type,
                    mandatory: false,
                    info: null,
                    focused: true
                }
            }

            if (state.form && state.form.body.length > 0) {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        body: [
                            ...state.form.body.map(item => {
                                item.focused = false
                                return item
                            }),
                            createField(fieldType)
                        ]
                    },
                }
            } else {
                return {
                    ...state,
                    form: {
                        ...state.form,
                        body: [
                            createField(fieldType)
                        ]
                    },
                }
            }
        case DUPLICATE_FIELD:
            const {id, index} = action.payload

            let body = state.form.body
            const fieldToDuplicate = state.form.body.find(item => item.id === id);

            if (fieldToDuplicate) {
                const duplicated = {...fieldToDuplicate, id: uuidv4(), focused: true}
                body = body.map(item => {
                    item.focused = false
                    return item
                })

                body.splice(index + 1, 0, duplicated)

                return {...state, form: {...state.form, body}}
            }

            return {...state}
        case DELETE_FIELD:
            const {fieldId} = action.payload

            return {...state, form: {...state.form, body: state.form.body.filter(item => item.id !== fieldId)}}
        case CHANGE_EDIT_ELEMENT:
            const {name, value} = action.payload

            return {...state, form: {...state.form, [name]: value}}
        case CHANGE_FIELD:
            return {
                ...state,
                form: {
                    ...state.form,
                    body: state.form.body.map(item => {
                        if (item.id === action.payload.field.id) {
                            return action.payload.field
                        }
                        return item
                    })
                }
            }
        case CHANGE_FOCUS:
            return {
                ...state,
                form: {
                    ...state.form,
                    body: state.form.body.map(item => {
                        item.focused = item.id === action.payload?.id
                        return item
                    })
                }
            }
        case CHANGE_POSITION:
            return {
                ...state,
                form: {
                    ...state.form,
                    body: action.payload.body
                }
            }
        case GET_REQUEST_FORM:
            return {...state, form: action.payload.requestForm}
        case GET_FORM_REQUIREMENTS:
            return {...state, fields: action.payload.fields}
        case GET_FORM_NAMES:
            return {...state, formNames: action.payload.forms}
        case CHANGE_REQUEST_FORM_ACCESS:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    data: state.forms.data.map(f => f._id === action.payload.id ? action.payload.form : f)
                }
            }
        case DELETE_REQUEST_FORM:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    data: state.forms.data.filter(f => f._id !== action.payload.id)
                }
            }
        case SUBMIT_FORM:
            return {...state, submitted: true}
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}

export default formsReducer