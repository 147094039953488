import React from 'react';
import {
    ActiveStudentsIcon,
    AllStudentsCountIcon,
    GroupsCountIcon,
    InstructorIcon,
    SettingsIcon
} from "../../../assets/icons";
import {roles} from "../../../common/roles";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const ProgramHeader = ({
                           name,
                           image,
                           activeStudentsCount,
                           allStudentsCount,
                           groupsCount,
                           instructors,
                           programData,
                           handleEditProgram
                       }) => {
    const {authData} = useSelector(state => state.auth)

    return (
        <div className={'flex items-center justify-between'}>
            <img src={image} alt={'program_image'} className={'h-full max-h-[50px]'}/>

            <div className={'relative flex flex-col items-center'}>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold'}>{name}</h2>
                <div className={'absolute top-full flex justify-center gap-[30px]'}>
                    <p className={'relative group flex items-center gap-[10px]'}>
                        <ActiveStudentsIcon color={'#FCC10F'}/>
                        <span>{activeStudentsCount}</span>
                        <span
                            className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ակտիվ ուսանողներ</span>
                    </p>
                    <p className={'relative group flex items-center gap-[10px]'}>
                        <AllStudentsCountIcon color={'#FCC10F'}/>
                        <span>{allStudentsCount}</span>
                        <span
                            className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ընդհանուր ուսանողների թիվ</span>
                    </p>
                    <p className={'relative group flex items-center gap-[10px]'}>
                        <GroupsCountIcon color={'#FCC10F'}/>
                        <span>{groupsCount}</span>
                        <span
                            className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Խմբերի քանակ</span>
                    </p>
                    <div className={'relative group flex items-center gap-[10px]'}>
                        <InstructorIcon color={'#FCC10F'}/>
                        <div className={'w-full'}>
                            {instructors?.length > 1 ?
                                <div className="relative group">
                                    <span className="cursor-pointer">Ծրագրավարներ</span>
                                    <div
                                        className="absolute w-[250px] z-[9990] hidden group-hover:flex flex-wrap gap-3 bg-white border shadow-md rounded-[3px] p-2">
                                        {instructors?.map((instructor) => (
                                            <Link
                                                to={`/instructors/${instructor._id}`}
                                                className="max-w-[120px] text-[12px] hover:text-[#FCC10F] transition whitespace-nowrap"
                                                key={instructor._id}
                                            >
                                                {instructor.fullName}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                :
                                instructors?.map((instructor) => (
                                    <Link to={`/instructors/${instructor._id}`}
                                          className="hover:text-[#FCC10F] transition whitespace-nowrap"
                                          key={instructor._id}>
                                        {instructor.fullName}
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
            {roles[authData?.role]?.some(role => role.allowEditProgram)
                ?
                <div onClick={handleEditProgram} className={'cursor-pointer'}>
                    <SettingsIcon/>
                </div>
                :
                <div></div>
            }
        </div>
    );
};

export default ProgramHeader;