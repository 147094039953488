export const checkFormValidity = (form) => {
    let valid = true

    for (const field in form?.body) {
        if (form?.body[field].mandatory) {
            if(form?.body[field].type !== 'successMessage') {
                if (form?.body[field].answerType === 'phoneNumber') {
                    if (form?.body[field].answer?.length < 12) {
                        valid = false

                        return valid
                    }
                }

                if (!form.body[field].answer) {
                    valid = false

                    return valid
                }
            }
        }
    }

    return valid
}