import React, {useEffect, useRef, useState} from 'react';
import {ExternalFormIcon, InnerFormIcon, PlusIcon} from "../../assets/icons";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {gsap} from "gsap";
import {useDispatch} from "react-redux";
import {createForm} from "../../store/actions/forms";
import {useNavigate} from "react-router-dom";
import externalForm from "../../assets/images/ExternalForm.png"
import innerForm from "../../assets/images/InnerForm.png"

const CreateNewFormButton = () => {
    const [open, setOpen] = useState(false)
    const ref = useRef(null)
    const navigate = useNavigate()

    const dispatch = useDispatch()

    useOutsideClick(ref, () => setOpen(false))

    const handleSelect = (type) => {
        dispatch(createForm(type))
        navigate(`/forms/create?type=${type}`)
    }

    useEffect(() => {
        const optionsElement = ref.current;

        if (open) {
            gsap.fromTo(
                optionsElement,
                {opacity: 0, height: 0, display: 'block'},
                {opacity: 1, height: 'auto', duration: 0.3}
            )
        } else {
            gsap.to(optionsElement, {opacity: 0, height: 0, duration: 0.2, display: 'none'})
        }
    }, [open])

    return (
        <div className={`w-fit relative`}>
            <div
                onClick={() => setOpen(!open)}
                className={`w-fit h-[50px] flex items-center gap-[30px] border border-[#FCC10F] rounded-[5px] py-2.5 px-[29.6px] cursor-pointer ${open ? 'text-[#FCC10F] pointer-events-none rounded-b-none border-b-transparent' : 'bg-[#FCC10F] text-white pointer-events-auto rounded-[4px]'}`}
            >
                <PlusIcon color={open ? '#FCC10F' : '#fff'}/>
                <span className={'bold select-none'}>Նոր հայտ</span>
            </div>

            <div ref={ref} className={`${open ? 'h-auto' : 'h-0'} absolute w-full bg-white border border-[#FCC10F]`}>
                <div className={'flex flex-col py-5 gap-7 select-none'}>
                    <div className={'flex items-center gap-5 cursor-pointer hover:bg-gray-200 px-4 py-1'}
                         onClick={() => handleSelect('request')}>
                        <div className={'flex gap-5 items-center line-clamp-1'}>
                            <img src={externalForm} alt={'external_form'}/>
                            <span>Ընդունելություն</span></div>
                    </div>
                    <div className={'flex items-center gap-5 cursor-pointer hover:bg-gray-200 px-4 py-1'}
                         onClick={() => handleSelect('inner')}>
                        <p className={'flex items-center gap-5'}>
                            <img src={innerForm} alt={'inner_form'}/>
                            <span>Ներքին հայտ</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateNewFormButton;