import {
    ADD_MANY_STUDENTS,
    ADD_STUDENT_TO_GROUP,
    CANCEL_LESSON,
    CHANGE_GROUP_STATUS,
    CHANGE_GROUP_STUDENT_STATUS,
    CHANGE_LESSON_DATE,
    CHANGE_STUDENT_JOIN_DATE, CREATE_GROUP_STUDENT_NOTE, CREATE_NOTE,
    DELETE_GROUP,
    DELETE_GROUP_NOTE, GET_CONNECT, GET_CONNECTS,
    GET_GROUP,
    GET_GROUP_NOTES,
    GET_GROUPS, GET_INTRO, GET_INTROS, GET_TRIP, GET_TRIPS, GET_WORKSHOP, GET_WORKSHOPS,
    MARK_LESSON_ATTENDANCE,
    REMOVE_STUDENT_FROM_GROUP,
    RESTORE_CANCELED_LESSON,
    SET_LOADING
} from '../constants/actionTypes'

const initialState = {
    groups: null,
    group: null,
    notes: null,
    loading: false,
}

const groupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_GROUP_STUDENT_NOTE:
            const {note} = action.payload

            return {
                ...state,
                notes: [note, ...state.notes,
                    // data: [note, ...state.notes]
                ]
            }
        case ADD_MANY_STUDENTS:
            return {
                ...state,
                group: state.group ? {
                    ...state.group,
                    attendance: {
                        ...state.group.attendance,
                        levels: action.payload.levels,
                        total: action.payload.total,
                    },
                    students: action.payload.students,
                } : state.group
            }
        case GET_GROUPS:
            return {...state, groups: action.payload, group: null, notes: null}
        case GET_INTROS:
            return {...state, groups: action.payload.groups, group: null,}
        case GET_WORKSHOPS:
            return {...state, groups: action.payload.workshops, group: null}
        case GET_CONNECTS:
            return {...state, groups: action.payload.connects, group: null}
        case GET_TRIPS:
            return {...state, groups: action.payload.trips, group: null}
        case GET_GROUP:
            return {...state, group: action.payload.group}
        case GET_INTRO:
            return {...state, group: action.payload.group}
        case GET_WORKSHOP:
            return {...state, group: action.payload.group}
        case GET_CONNECT:
            return {...state, group: action.payload.group}
        case GET_TRIP:
            return {...state, group: action.payload.group}
        case SET_LOADING:
            return {...state, loading: !state.loading}
        case GET_GROUP_NOTES:
            return {...state, notes: action.payload.notes}
        case REMOVE_STUDENT_FROM_GROUP:
            return {
                ...state,
                group: {
                    ...state.group,
                    students: action.payload.students,
                    attendance: {
                        ...(state.group.attendance),
                        levels: action.payload.levels,
                        students: action.payload.students
                    }
                }
            }
        case CHANGE_GROUP_STUDENT_STATUS:
            return {
                ...state,
                group: {
                    ...state.group,
                    students: action.payload.students,
                    attendance: {
                        ...state.group.attendance,
                        levels: action.payload.levels
                    }
                }
            }
        case CHANGE_STUDENT_JOIN_DATE:
            return {
                ...state,
                group: {
                    ...state.group,
                    students: action.payload.students,
                    attendance: {
                        ...state.group.attendance,
                        levels: action.payload.levels
                    }
                }
            }
        case CHANGE_LESSON_DATE:
            return {
                ...state,
                group: {
                    ...state.group,
                    attendance: {
                        ...state.group.attendance,
                        levels: action.payload.levels,
                        dates: action.payload.dates
                    }
                }
            }
        case CANCEL_LESSON:
            return {
                ...state,
                group: {
                    ...state.group,
                    attendance: {
                        ...state.group.attendance,
                        levels: action.payload.levels
                    }
                }
            }
        case RESTORE_CANCELED_LESSON:
            return {
                ...state,
                group: {
                    ...state.group,
                    attendance: {
                        ...state.group.attendance,
                        levels: action.payload.levels,
                        total: action.payload.total
                    }
                }
            }
        case MARK_LESSON_ATTENDANCE:
            return {
                ...state,
                group: {
                    ...state.group,
                    attendance: {
                        ...state.group.attendance,
                        levels: action.payload.levels,
                        total: action.payload.total
                    }
                }
            }
        case CHANGE_GROUP_STATUS:
            const {id, status} = action.payload

            const updatedGroups = state.groups.data.map(group => {
                if (group._id === id) {
                    group.status = status
                }
                return group
            })

            return {
                ...state,
                groups: {...state.groups, data: updatedGroups},
            }
        case DELETE_GROUP:
            return {
                ...state,
                groups: {
                    ...state.groups,
                    data: state.groups.data.filter(item => item._id !== action.payload.id)
                }
            }
        case DELETE_GROUP_NOTE:
            console.log(state.notes.data)
            return {
                ...state,
                notes: {
                    ...state.notes,
                    data: state.notes.data.filter(item => item._id !== action.payload.id)
                }
            }
        default:
            return state;
    }
}
export default groupsReducer