import React, {useEffect, useRef, useState} from 'react';
import RequestsFilter from "../../components/requests/RequestsFilter";
import {useDispatch, useSelector} from "react-redux";
import {ConfirmationModal, Container} from "../../components/shared";
import {
    ArchiveIcon,
    ArrowCounterClockwise,
    CheckedIcon,
    CsvIcon,
    EditIcon,
    PdfIcon,
    ReturnLeftIcon,
    ScrollToTopIcon,
    XlsxIcon
} from "../../assets/icons";
import {archiveRequest, getRequests, recoverRequest, selectRequests} from "../../store/actions/requests";
import Pagination from "../../components/shared/Pagination";
import PageSizeDropdown from "../../components/shared/dropdowns/PageSizeDropdown";
import EditRequestModal from "../../components/requests/EditRequestModal";

import ButtonHoverText from "../../components/shared/buttons/ButtonHoverText";

import {CSVLink} from "react-csv";
import {downloadXlsx} from "../../utils/fileDownloads/xlsx/xlsx";
import generatePDF from "react-to-pdf";
import {useFilters} from "../../hooks/useFilters";
import {roles} from "../../common/roles";

const xlsxColumns = [
    {header: "Անուն", key: "firstName"},
    {header: "Ազգանուն", key: "lastName"},
    {header: "Հայրանուն", key: "middleName"},
    {header: "Տարիք", key: "age"},
    {header: "Համայնք", key: "community"},
    {header: "Կարգավիճակ", key: "status"},
    {header: "Հայտի համար", key: "form"}
]

const pdfCols = [
    'Անուն',
    'Ազգանուն',
    'Հայրանուն',
    'Տարիք',
    'Համայնք',
    'Կարգավիճակ',
    'Հայտի համար'
]

const initialFilters = {
    firstName: '', lastName: '', middleName: '', page: 1, size: 10, archive: false
}

const Requests = () => {
    const [selectedFilters, setSelectedFilters] = useState({});
    const [openDeleteModal, setOpenDeleteModal] = useState(null);
    const [openRecoverModal, setOpenRecoverModal] = useState(null);
    const [openDeleteForeverModal, setOpenDeleteForeverModal] = useState(null);
    const [editRequest, setEditRequest] = useState(null);
    const dispatch = useDispatch();
    const {requests, selected, loading} = useSelector(state => state.requests);

    const {authData} = useSelector(state => state.auth)

    const {
        filters,
        setFilters,
        handleChange,
        handleChangeSize,
        changePage,
        changeCustomPage,
        discardFilters,
        setCustomPage,
        changeSelectedFilters,
        submitFilters,
        toggleArchiveMode,
    } = useFilters(initialFilters, getRequests, 'requests', true)

    const allSelected = requests?.data.length === selected?.length

    const [csvData, setCsvData] = useState([])
    const pdfRef = useRef()

    const handleSelectRequests = (request) => {
        dispatch(selectRequests(request))
    }

    const confirmDelete = (id) => {
        setOpenDeleteModal(null)
        dispatch(archiveRequest(id))
    }

    const handleDeleteForever = () => {
        setOpenDeleteForeverModal(true)
    }

    const confirmRecover = (id) => {
        setOpenRecoverModal(null)
        dispatch(recoverRequest(id))
    }

    const handleDelete = (id) => {
        setOpenDeleteModal(id)
    }

    const handleRecover = (id) => {
        setOpenRecoverModal(id)
    }

    const closeRequestEditModal = () => {
        setEditRequest(null)
    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    const handleDownloadXlsx = () => {
        downloadXlsx(xlsxColumns, (selected.length ? selected : requests.data))
    };

    const downloadPdf = () => {
        generatePDF(pdfRef, {filename: 'page.pdf'})
    }

    useEffect(() => {
        document.body.style.overflow = openRecoverModal || openDeleteModal || editRequest ? 'hidden' : 'auto'
    }, [openRecoverModal, openDeleteModal, editRequest]);

    useEffect(() => {
        if (requests) {
            const csv = [
                ["Անուն", "Ազգանուն", "Հայրանուն", "Տարիք", "Համայնք", "Կարգավիճակ", "Հայտի համար"],
                ...(selected.length ? selected : requests?.data).map(({data, form, student}) => [
                    data?.firstName,
                    data?.lastName,
                    data?.middleName,
                    data?.age,
                    data?.community?.name || '',
                    student ? 'Ակտիվ' : 'Պասիվ',
                    form?.name || '',
                ]),
            ]

            setCsvData(csv)
        }
    }, [requests, selected]);

    return (
        <>
            {openDeleteModal &&
                <ConfirmationModal
                    id={openDeleteModal}
                    close={() => setOpenDeleteModal(false)}
                    warningText={'Վստա՞հ եք, որ ցանկանում եք արխիվացնել այս հայտը։'}
                    icon={<ArchiveIcon width={50} height={50} color={'#FCC10F'}/>}
                    confirm={() => confirmDelete(openDeleteModal)}
                    confirmText={'Արխիվացնել'}
                    cancelText={'Չեղարկել'}
                />
            }
            {openRecoverModal &&
                <ConfirmationModal
                    id={openRecoverModal}
                    close={() => setOpenRecoverModal(false)}
                    warningText={'Վստա՞հ եք, որ ցանկանում եք վերականգնել այս հայտը։'}
                    icon={<ArrowCounterClockwise width={50} height={50}/>}
                    confirm={() => confirmRecover(openRecoverModal)}
                    confirmText={'Վերականգնել'}
                    cancelText={'Չեղարկել'}
                />
            }
            {editRequest && <EditRequestModal request={editRequest} close={closeRequestEditModal}/>}

            <div>
                <h2 className={'text-center text-[50px] text-[#FCC10F] font-bold mt-[14px]'}>{filters?.archive ? 'Արխիվ' : 'Հայտադարան'}</h2>

                <RequestsFilter
                    show={!filters?.archive}
                    handleChange={handleChange}
                    discardFilters={discardFilters}
                    submitFilters={submitFilters}
                    filters={filters}
                    setFilters={setFilters}
                    selectedFilters={selectedFilters}
                    setSelectedFilters={setSelectedFilters}
                    changeSelectedFilters={changeSelectedFilters}/>

                <Container>
                    <div>
                        {filters?.archive &&
                            <>
                                <div className={'flex justify-between'}>
                                    <p onClick={toggleArchiveMode}
                                       className={'group w-fit flex items-center gap-[30px] cursor-pointer fill-icon'}>
                                        <ReturnLeftIcon className={'opacity-70'}/>
                                        <span className={'transition hover:text-[#FCC10F] opacity-70'}>Վերադառնալ հայտադարան</span>
                                    </p>
                                </div>
                            </>
                        }

                        {loading
                            ?
                            <p className={'text-center'}>Բեռնվում է․․․</p>
                            :
                            requests?.data.length ? (
                                    <>
                                        <div>
                                            <div className={'mt-[30px]'}>
                                                <div className={'grid grid-cols-8 place-items-center'}>
                                                    <div className={'line-clamp-1 cursor-default bold'}>Անուն</div>
                                                    <div className={'line-clamp-1 cursor-default bold'}>Ազգանուն</div>
                                                    <div className={'line-clamp-1 cursor-default bold'}>Հայրանուն</div>
                                                    <div className={'line-clamp-1 cursor-default bold'}>Տարիք</div>
                                                    <div className={'line-clamp-1 cursor-default bold'}>Համայնք</div>
                                                    <div className={'line-clamp-1 cursor-default bold'}>Կարգավիճակ</div>
                                                    <div className={'line-clamp-1 cursor-default bold'}>Հայտի համար
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {requests?.data.map((request, index) => {
                                                    const checked = selected?.find(selected => selected._id === request._id)
                                                    const isLast = requests?.data.length === index + 1

                                                    return (
                                                        <div key={request._id}>
                                                            <div
                                                                className={'w-full relative flex items-center gap-[23px] mt-[30px]'}>
                                                                <div
                                                                    className={'w-full grid grid-cols-8 place-items-center border border-black border-opacity-30 rounded-[5px] bg-none py-[7px]'}>
                                                                    <div
                                                                        className={'w-full overflow-hidden text-center line-clamp-1 cursor-default'}
                                                                        title={request.data?.firstName || ''}>
                                                                        <p className={'truncate'}>{request.data.firstName || ''}</p>
                                                                    </div>
                                                                    <div
                                                                        className={'w-full overflow-hidden text-center line-clamp-1 cursor-default'}
                                                                        title={request.data.lastName || ''}>
                                                                        <p className={'truncate'}>{request.data.lastName || ''}</p>
                                                                    </div>
                                                                    <div
                                                                        className={'w-full overflow-hidden text-center line-clamp-1 cursor-default'}
                                                                        title={request.data.middleName || ''}>
                                                                        <p className={'truncate'}>{request.data.middleName || ''}</p>
                                                                    </div>
                                                                    <div
                                                                        className={'w-full overflow-hidden text-center line-clamp-1 cursor-default'}
                                                                        title={request.data.age || ''}>
                                                                        <p className={'truncate'}>{request.data.age}</p>
                                                                    </div>
                                                                    <div
                                                                        className={'w-full overflow-hidden text-center line-clamp-1 cursor-default'}
                                                                        title={request.data.community?.name}>
                                                                        <p className={'truncate'}>{request.data.community?.name}</p>
                                                                    </div>
                                                                    <div
                                                                        className={'w-full overflow-hidden text-center line-clamp-1 cursor-default'}
                                                                        title={request.status === 'active' ? 'Ակտիվ' : 'Պասիվ'}>
                                                                        <p className={'truncate'}>{request.status === 'active' ? 'Ակտիվ' : 'Պասիվ'}</p>
                                                                    </div>
                                                                    <div
                                                                        className={'w-full overflow-hidden text-center line-clamp-1 cursor-default'}
                                                                        title={request.form?.name}>
                                                                        <p className={'truncate'}>{request.form?.name}</p>
                                                                    </div>
                                                                    <div
                                                                        className={'flex justify-end w-full px-[30px] gap-[30px]'}>

                                                                        {filters?.archive
                                                                            ?
                                                                            roles[authData?.role]?.some(role => role.showArchive) &&
                                                                            <ButtonHoverText text={'Վերականգնել'}
                                                                                             icon={
                                                                                                 <ArrowCounterClockwise/>}
                                                                                             onClick={() => handleRecover(request._id)}/>
                                                                            :
                                                                            roles[authData?.role]?.some(role => role.showEditRequestButton) &&
                                                                            <ButtonHoverText text={'Խմբագրել'}
                                                                                             icon={<EditIcon/>}
                                                                                             onClick={() => setEditRequest(request)}/>

                                                                        }
                                                                        {
                                                                            (request.status !== 'active' && !filters?.archive && roles[authData?.role]?.some(role => role.showArchive))
                                                                            &&
                                                                            <ButtonHoverText text={'Արխիվացնել'}
                                                                                             icon={<ArchiveIcon
                                                                                                 color={"#FCC10F"}/>}
                                                                                             onClick={() => handleDelete(request._id)}/>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {isLast && <button onClick={scrollToTop}
                                                                                   className={'absolute -right-14 -top-1'}>
                                                                    <ScrollToTopIcon/></button>}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <div
                                            className={'flex items-center justify-between mt-[50px] ml-[53px] mb-[100px]'}>
                                            {roles[authData?.role]?.some(role => role.showFormImportButtons)
                                                ?
                                                <div
                                                    className={`${filters?.archive && 'pointer-events-none opacity-0'} flex gap-[10px]`}>
                                                    <ButtonHoverText text={'Արտահանել որպես PDF'}
                                                                     className={'fill-icon'}
                                                                     icon={<PdfIcon/>} onClick={downloadPdf}/>
                                                    <ButtonHoverText text={'Արտահանել որպես XLSX'}
                                                                     className={'fill-icon'}
                                                                     icon={<XlsxIcon/>} onClick={handleDownloadXlsx}/>
                                                    {requests &&
                                                        <CSVLink className="download-csv-button" filename="my-file.csv"
                                                                 data={csvData}>
                                                            <ButtonHoverText text={'Արտահանել որպես CSV'}
                                                                             className={'fill-icon'}
                                                                             icon={<CsvIcon/>} onClick={a => a}/>
                                                        </CSVLink>}
                                                </div>
                                                :
                                                <div></div>
                                            }

                                            <div className={'flex items-center'}>
                                                {filters?.page &&
                                                    <Pagination count={requests?.pagesCount} onChange={changePage}
                                                                page={parseInt(filters?.page)}/>}
                                                <div className={'flex items-center justify-center gap-2.5'}>
                                                    <span>Էջ</span>
                                                    <input type="number" onKeyDown={changeCustomPage}
                                                           onChange={e => setCustomPage(e.target.value)}
                                                           className={'max-w-[53px] outline-none px-2 py-[3px] text-center border border-black rounded-[5px]'}/>
                                                </div>
                                                <div className={'ml-[30px]'}>
                                                    <PageSizeDropdown onChange={handleChangeSize}
                                                                      value={filters?.size}/>
                                                </div>
                                            </div>

                                            {roles[authData?.role]?.some(role => role.showArchive)
                                                ?
                                                <button
                                                    onClick={toggleArchiveMode}
                                                    className={`${filters?.archive && 'pointer-events-none opacity-0'} group fill-icon flex items-center gap-[30px] border border-black py-[5px] px-[10px] rounded-[5px] hover:text-[#FCC10F]`}>
                                                    Արխիվ
                                                    <ArchiveIcon color={'#000'}/>
                                                </button>
                                                :
                                                <div></div>
                                            }
                                        </div>
                                    </>
                                )
                                :
                                <>
                                    <p className={'text-center mt-10'}>Հայտեր չկան</p>
                                    {roles[authData?.role]?.some(role => role.showArchive) &&
                                        <div className={'flex justify-end'}>
                                            <button
                                                onClick={toggleArchiveMode}
                                                className={`${filters?.archive && 'pointer-events-none opacity-0'} group fill-icon flex items-center gap-[30px] border border-black py-[5px] px-[10px] rounded-[5px] hover:text-[#FCC10F]`}>
                                                Արխիվ
                                                <ArchiveIcon color={'#000'}/>
                                            </button>
                                        </div>
                                    }
                                </>
                        }
                    </div>
                </Container>

                <div ref={pdfRef} className={'w-full absolute -left-[5000px] pointer-events-none p-4'}>
                    <div style={{gridTemplateColumns: `repeat(${pdfCols.length}, 1fr)`}}
                         className={`grid bg-[#FCC10F] pb-4 px-4`}>
                        {pdfCols.map((item, i) => <div key={i}
                                                       className={`bold text-white ${i !== 0 && 'px-4'}`}>{item}</div>)}
                    </div>
                    <div>
                        {(selected.length ? selected : requests?.data)?.map(({data, form, student, _id}, i) => (
                            <div key={_id} style={{gridTemplateColumns: `repeat(${pdfCols.length}, 1fr)`}}
                                 className={`grid ${i % 2 === 0 ? 'bg-[#FAEED1]' : 'bg-[#FFF8E3]'} pb-2`}>
                                <p className={'px-4 pb-2'}>{data?.firstName}</p>
                                <p className={'px-4 pb-2'}>{data?.lastName}</p>
                                <p className={'px-4 pb-2'}>{data?.middleName}</p>
                                <p className={'px-4 pb-2'}>{data?.age}</p>
                                <p className={'px-4 pb-2'}>{data?.community?.name || ''}</p>
                                <p className={'px-4 pb-2'}>{student ? 'Ակտիվ' : 'Պասիվ'}</p>
                                <p className={'px-4 pb-2'}>{form?.name || ''}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Requests