import React from 'react';
import DriverPersonalData from "../../../../../../components/transport/subpages/drivers/driver/DriverPersonalData";
import DriverWorkData from "../../../../../../components/transport/subpages/drivers/driver/DriverWorkData";
import {Container} from "../../../../../../components/shared";

const DriverProfile = ({driver}) => {
    return (
        <Container>
            <div className={'flex justify-between mt-7'}>
                <DriverPersonalData {...driver}/>
                <DriverWorkData {...driver}/>
            </div>
        </Container>
    );
};

export default DriverProfile;