import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon, EmojiIcon, PdfColoredIcon, SliderControlArrow, UploadFileIcon } from "../../../assets/icons";
import { ButtonFilled, ButtonOutlined } from "../../shared/buttons";
import EmojiPicker from "emoji-picker-react";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { v4 as uuidv4 } from 'uuid';
import { decryptMessage, encryptMessage } from "../../../socket/encryption";
import { sendMessage } from "../../../socket/chats";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";
import {useMediaQuery} from "react-responsive";

const UploadFilesModal = ({ close, title, fileType, socket, chatId, chatRoom, replyingId }) => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState({});
    const [allFilesUploaded, setAllFilesUploaded] = useState(false);
    const [content, setContent] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [textareaHeight, setTextareaHeight] = useState("auto");

    const fileUploadRef = useRef(null);
    const pickerRef = useRef(null);
    const swiperRef = useRef(null);

    useOutsideClick(pickerRef, () => setShowPicker(false))

    const isPhone = useMediaQuery({maxWidth: 640})

    const cancelPreUploadingFiles = async () => {
        const obj = {
            type: 'cancel_pre_uploading_files_to_chat',
            data: {
                chatId,
            }
        }
        const encryptedMessage = await encryptMessage(obj);
        socket.emit('msg', encryptedMessage, async (response) => {
            response = await decryptMessage(response);
            console.log(response);
        });
    }

    const handleFileUpload = async (e) => {
        if (e.target.files) {
            const filesArr = Array.from(e.target.files);
            const newFilesWithIds = [];
            setUploadedFiles(prevFiles => [...prevFiles, ...filesArr.map(file => ({ file, id: null }))]);

            const newLoadingFiles = { ...loadingFiles };
            filesArr.forEach(file => {
                newLoadingFiles[file.name] = true;
            });
            setLoadingFiles(newLoadingFiles);

            let allUploaded = true;

            for (const file of filesArr) {
                const preUploadableFile = {
                    type: 'pre_upload_file_to_chat',
                    data: {
                        chatId,
                        fileData: {
                            name: file.name,
                            count: filesArr.length,
                            id: uuidv4(),
                        },
                    },
                }

                const encrypted = await encryptMessage(preUploadableFile);

                socket.emit('msg_file', { data: encrypted, file }, async (response) => {
                    response = await decryptMessage(response);

                    setLoadingFiles(prevLoadingFiles => {
                        const updatedFiles = { ...prevLoadingFiles };
                        updatedFiles[file.name] = false;
                        return updatedFiles;
                    });

                    setUploadedFiles(prevFiles => prevFiles.map(prevFile => {
                        if (prevFile.file.name === file.name) {
                            return { file: prevFile.file, id: response.fileId }
                        }
                        return prevFile;
                    }));

                    if (!response.allFilesUploaded) {
                        allUploaded = false;
                    }

                    if (response.allFilesUploaded) {
                        setAllFilesUploaded(true);
                    }
                });
            }

            setAllFilesUploaded(allUploaded);
        }
    }

    const onEmojiClick = (event) => {
        setContent(prevContent => prevContent + event.emoji);
        adjustTextareaHeight();
    }

    const handleRemoveFile = async (index) => {
        const fileToRemove = uploadedFiles[index];
        const fileId = fileToRemove.id;

        const deletePreUploadedFile = async () => {
            const preUploadableFile = {
                type: 'delete_pre_uploaded_file_to_chat',
                data: {
                    chatId,
                    fileId
                }
            }

            const encryptedMessage = await encryptMessage(preUploadableFile);
            socket.emit('msg', encryptedMessage, async (response) => {
                response = await decryptMessage(response);
                console.log(response);
            });
        }

        await deletePreUploadedFile();

        setUploadedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    }

    const openFileDialog = () => {
        fileUploadRef.current.click();
    }

    const handleClose = async (e) => {
        e.stopPropagation();
        await cancelPreUploadingFiles();
        close();
    }

    const handleSendMessage = () => {
        sendMessage(socket, content, chatRoom, replyingId);

        close();
    }

    const renderFilePreview = ({ file }, index) => {
        const fileURL = URL.createObjectURL(file);
        const isLoading = loadingFiles[file.name];

        if (file.type.startsWith('image/')) {
            return (
                <div key={index} className="relative">
                    {isLoading ?
                        <div className="w-[70px] h-[70px] lg:w-[100px] lg:h-[100px] flex items-center justify-center">
                            <span>Loading...</span>
                        </div>
                        :
                        <img
                            src={fileURL}
                            alt={`uploaded-${index}`}
                            className={`w-[70px] h-[70px] lg:w-[100px] lg:h-[100px] object-cover rounded-[3px] ${isPhone && 'mb-2'}`}
                        />
                    }
                    {!isLoading &&
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveFile(index);
                            }}
                            className="absolute z-[99999] bottom-[90%] left-[90%] w-4 h-4 flex items-center justify-center bg-white p-[2px] border border-[#FCC10F] rounded-full"
                        >
                            <CloseIcon width={isPhone ? 10 : 12} height={isPhone ? 10 : 12} color={'#FCC10F'} />
                        </button>
                    }
                </div>
            )
        } else if (file.type.startsWith('video/')) {
            return (
                <div key={index} className="relative">
                    {isLoading ?
                        <div className="w-[70px] h-[70px] lg:w-[100px] lg:h-[100px] flex items-center justify-center">
                            <span>Loading...</span>
                        </div>
                        :
                        <video
                            src={fileURL}
                            className="w-[70px] h-[70px] lg:w-[100px] lg:h-[100px] object-cover rounded-[3px]"
                        />
                    }
                    {!isLoading &&
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveFile(index);
                            }}
                            className="absolute bottom-[90%] left-[90%] w-4 h-4 flex items-center justify-center bg-white p-[2px] border border-[#FCC10F] rounded-full"
                        >
                            <CloseIcon width={isPhone ? 10 : 12} height={isPhone ? 10 : 12} color={'#FCC10F'} />
                        </button>
                    }
                </div>
            )
        } else if (file.type === 'application/pdf') {
            return (
                <div key={index} className="w-full rounded-[3px] lg:mx-[170px]">
                    {isLoading ?
                        <div className="w-[70px] h-[70px] lg:w-[100px] lg:h-[100px] flex items-center justify-center">
                            <span>Loading...</span>
                        </div>
                        :
                        <div className={'grid grid-cols-3 place-items-center lg:gap-[30px]'}>
                                <PdfColoredIcon />
                            <div className="flex items-center gap-5 lg:gap-[30px] text-sm">
                                <span className={'text-[14px] lg:text-[16px] line-clamp-2'}>{file.name}</span>
                            </div>
                            <button
                                onClick={async (e) => {
                                    e.stopPropagation();
                                    await handleRemoveFile(index);
                                }}
                                className=""
                            >
                                <CloseIcon width={isPhone ? 17 : 12} height={isPhone ? 17 : 12} />
                            </button>
                        </div>
                    }
                </div>
            )
        }
        return null;
    }

    useEffect(() => {
        if (swiperRef.current && uploadedFiles.length > 5) {
            const middleIndex = Math.floor(uploadedFiles.length / 2);
            swiperRef.current.slideToLoop(middleIndex, 0);
        }
    }, [uploadedFiles]);

    const adjustTextareaHeight = () => {
        const textarea = document.getElementById('contentTextarea');
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
    }

    return (
        <div className="fixed z-[99999] w-screen h-screen top-0 left-0 mt-[90px] flex justify-center">
            <div className="fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]"></div>

            <div
                className="absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[345px] min-h-[291px] lg:max-w-[690px] lg:min-h-[400px] z-[9999] border border-black rounded-[10px] bg-white">
                <div
                    className={`${isPhone && 'max-w-[340px]'} relative flex items-center justify-center pt-5 pb-[17px] lg:pt-[30px] lg:px-[30px] lg:pb-[23px]`}>
                    <h2 className="text-center text-[14px] lg:text-[16px]">{title}</h2>
                    <button className="absolute right-6 bg-black bg-opacity-5 rounded-full p-[2px] lg:p-1.5"
                            onClick={handleClose}>
                        <CloseIcon width={isPhone ? 13 : 20} height={isPhone ? 13 : 20}/>
                    </button>
                </div>

                <div
                    className={`flex justify-center ${isPhone ? 'max-w-[345px] overflow-x-auto' : 'w-full items-center'}`}>
                    <div
                        onClick={openFileDialog}
                        className={`${uploadedFiles.length === 0 && 'w-[50px] h-[50px] lg:w-[100px] lg:h-[100px]'} flex items-center justify-center cursor-pointer`}
                    >
                        <input
                            type="file"
                            accept={fileType === 'file' ? 'application/pdf' : 'image/*,video/*'}
                            className="hidden"
                            id="upload_file"
                            ref={fileUploadRef}
                            onChange={handleFileUpload}
                            multiple
                        />
                        <div
                            className={`w-full h-full flex items-center justify-center ${uploadedFiles.length === 0 && 'border lg:border-[7px] border-[#FCC10F] rounded-full'}`}
                        >
                            {uploadedFiles.length > 5 && fileType !== 'file'
                                ?
                                <div className={'flex flex-col'}>
                                    <div className="w-full relative flex justify-center lg:px-[60px]">
                                        <Swiper
                                            grabCursor={true}
                                            loop={true}
                                            centeredSlides={true}
                                            slidesPerView={'auto'}
                                            spaceBetween={isPhone ? 0 : 10}
                                            pagination={{
                                                el: '.swiper-pagination-slider',
                                                clickable: true,
                                            }}
                                            navigation={{
                                                nextEl: '.swiper-button-next',
                                                prevEl: '.swiper-button-prev',
                                                clickable: true,
                                            }}
                                            modules={[Pagination, Navigation]}
                                            style={{maxWidth: isPhone ? '300px' : 'auto'}}
                                        >
                                            {uploadedFiles.map((fileObj, index) => (
                                                <SwiperSlide key={index} style={{width: '100px', height: '120px'}}
                                                             className={'px-2 pt-5'}>
                                                    {renderFilePreview(fileObj, index)}
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                        <div className="slider-controller-images px-10">
                                            <div
                                                className="swiper-button-prev slider-arrow ml-8 rotate-180 transform -translate-y-1/2"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <SliderControlArrow color={'#FCC10F'}/>
                                            </div>
                                            <div
                                                className="swiper-button-next slider-arrow mr-8 transform -translate-y-1/2"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <SliderControlArrow color={'#FCC10F'}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`swiper-pagination-slider flex justify-center gap-5 lg:mt-5 ${isPhone ? 'pagination-container mb-5' : ''}`}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                    </div>
                                </div>
                                :
                                uploadedFiles.length > 0 ?
                                    <div
                                        className={`relative flex justify-center ${fileType === 'file' && 'flex-col'} gap-2.5`}
                                    >
                                        {uploadedFiles.map((fileObj, index) => renderFilePreview(fileObj, index))}
                                    </div>
                                    :
                                    <UploadFileIcon width={isPhone && 20} height={isPhone && 20}/>
                            }
                        </div>
                    </div>
                </div>

                <div className="flex-1 mx-2 flex items-end lg:px-[50px] lg:mt-[30px]">
                    <div
                        className="w-full min-h-[30px] lg:min-h-10 flex items-center bg-white border border-black border-opacity-50 rounded-[5px] px-5">
                    <textarea
                        id="contentTextarea"
                        value={content}
                        onChange={e => {
                            setContent(e.target.value);
                            adjustTextareaHeight();
                        }}
                        className="resize-none w-full outline-none text-[14px] lg:text-[16px]"
                        style={{height: textareaHeight, maxHeight: '302px', overflowY: 'auto'}}
                        placeholder={'Գրել նկարագրություն'}
                        rows={1}
                        onInput={adjustTextareaHeight}
                    />
                        <div className="relative flex items-center gap-5 pl-4">
                            <button onClick={() => setShowPicker(!showPicker)}>
                                <EmojiIcon width={isPhone && 17} height={isPhone && 17}/>
                            </button>
                            {showPicker && (
                                <div ref={pickerRef} className="absolute top-full right-[40%] z-10">
                                    <EmojiPicker onEmojiClick={onEmojiClick} searchDisabled={true}/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="flex justify-center pt-[30px] pb-[50px] lg:py-[50px] gap-5 lg:gap-[30px]">
                    <ButtonOutlined onClick={handleClose} text={'Չեղարկել'} className={'w-[120px] lg:w-[176px]'}
                                    isPhone={isPhone}/>
                    <ButtonFilled
                        onClick={handleSendMessage}
                        text={'Վերբեռնել'}
                        className={'w-[120px] lg:w-[176px]'}
                        isPhone={isPhone}
                        disabled={!allFilesUploaded || Object.values(loadingFiles).some(isLoading => isLoading)}
                    />
                </div>
            </div>
        </div>
    )
}

export default UploadFilesModal;