import React, {useEffect, useRef, useState} from 'react';
import {useFilters} from "../../../hooks/useFilters";
import StudentsFilters from "../../../components/students/StudentsFilters";
import {downloadStudentQrCodes, getStudents, selectStudent} from "../../../store/actions/students";
import {Container} from "../../../components/shared";
import {useDispatch, useSelector} from "react-redux";
import StudentItem from "../../../components/students/StudentItem";
import {CheckedIcon, CsvIcon, PdfIcon, PeopleIcon, ScrollToTopIcon, XlsxIcon} from "../../../assets/icons"
import ButtonHoverText from "../../../components/shared/buttons/ButtonHoverText";
import {CSVLink} from "react-csv";
import Pagination from "../../../components/shared/Pagination";
import PageSizeDropdown from "../../../components/shared/dropdowns/PageSizeDropdown";
import generatePDF from "react-to-pdf";
import {downloadXlsx} from "../../../utils/fileDownloads/xlsx/studentsXlsx";
import AddToGroupModal from "../../../components/students/modals/AddToGroupModal";
import {addManyStudents} from "../../../store/actions/groups";
import {roles} from "../../../common/roles";
import {downloadStudentsQrCodesFile} from "../../../utils/fileDownloads/downloadFile";
import {useNavigate} from "react-router-dom";

const xlsxColumns = [
    {header: "Անուն, ազգանուն, հայրանուն", key: "fullName"},
    {header: "Տարիք", key: "age"},
    {header: "Համայնք", key: "community"},
    {header: "Հեռախոս", key: "phone"},
    {header: "Էլ․ փոստ", key: "email"},
    {header: "Ծրագիր", key: "currentGroup"},
    {header: "Կարգավիճակ", key: "status"},
    {header: "Գրաֆիկ", key: ""},
]

const initialFilters = {
    firstName: '', lastName: '', middleName: '', page: 1, size: 10
}
const Students = () => {
    const {students, selected, loading} = useSelector(state => state.students)
    const [openAddStudentModal, setOpenAddStudentModal] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const allSelected = students?.data.length === selected?.length

    const pdfRef = useRef()
    const [csvData, setCsvData] = useState([])

    const {authData} = useSelector(state => state.auth)
    const userHasPermissions = authData.role === 'manager' || authData.role === 'volunteer' || authData.role === 'admin'

    const {
        filters,
        handleChange,
        changeSelectedFilters,
        changePage,
        changeCustomPage,
        handleChangeSize,
        setCustomPage,
        discardFilters,
        submitFilters
    } = useFilters(initialFilters, getStudents, 'students')

    const selectedStudentsId = selected.map(student => student._id)
    const joinedIds = selectedStudentsId.join(',')

    const handleSelectStudents = (id) => {
        dispatch(selectStudent(id))
    }

    const downloadPdf = () => {
        generatePDF(pdfRef, {filename: 'page.pdf'})
    }

    const handleDownloadXlsx = () => {
        downloadXlsx(xlsxColumns, (selected.length ? selected : students.data))
    };

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    const scrollToBottom = () => {
        window.scrollTo({top: document.documentElement.scrollHeight, behavior: 'smooth'})
    }

    const handleDownloadQrCodesPdf = (studentId) => {
        const ids = [studentId]

        if(selected.length > 0) {
            for (const selectedItem of selected) {
                if(!ids.includes(selectedItem._id)) {
                    ids.push(selectedItem._id)
                }
            }
        }

        dispatch(downloadStudentQrCodes(ids.join(',')))
    }

    useEffect(() => {
        if (students) {
            const csv = [
                ["Անուն, ազգանուն, հայրանուն", "Տարիք", "Համայնք", "Հեռախսո", "Էլ․ փոստ", "Ծրագիր", "Կարգավիճակ", "Գրաֆիկ"],
                ...(selected.length ? selected : students?.data).map(student => [
                    student.fullName,
                    student.age,
                    student.community?.name,
                    student.phoneNumbers[0],
                    student.email || '',
                    student.currentGroup?.name || '',
                    student.status === 'active' ? 'Ակտիվ' : 'Պասիվ',
                    'Custom schedule',
                ]),
            ]

            setCsvData(csv)
        }
    }, [students, selected])

    return (
        <>
            {openAddStudentModal &&
                <AddToGroupModal
                    close={() => setOpenAddStudentModal(false)}
                    joinedIds={joinedIds}
                />
            }

            <div>
                <h2 className={'mt-[30px] text-center text-[50px] text-[#FCC10F] font-bold'}>Ուսանողներ</h2>
            </div>

            <StudentsFilters
                filters={filters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
            />

            <Container>
                {loading
                    ?
                    <p className={'text-center'}>Բեռնվում է․․․</p>
                    :
                    students?.data.length ?
                        <>
                            {authData?.role !== 'instructor' &&
                                <div className={'flex items-center'}>
                                    <div
                                        onClick={() => handleSelectStudents()}
                                        className={'w-[30px] h-[30px] flex items-center justify-center mt-[30px] cursor-pointer border border-black rounded-[5px]'}>
                                        {allSelected && <CheckedIcon/>}
                                    </div>
                                </div>
                            }
                            <div>
                                {students?.data.map((student, index) => {
                                    const isFirst = index === 0
                                    const isLast = students?.data.length === index + 1
                                    return (
                                        <div className={'relative'} key={student._id}>
                                            <StudentItem
                                                {...student}
                                                selected={selected}
                                                handleSelectStudent={handleSelectStudents}
                                                userHasPermissions={userHasPermissions}
                                                handleDownloadQrCodesPdf={handleDownloadQrCodesPdf}
                                            />

                                            {isFirst &&
                                                <button
                                                    className={'absolute -right-16 top-5 transform rotate-180'}
                                                    onClick={scrollToBottom}>
                                                    <ScrollToTopIcon/>
                                                </button>}
                                            {isLast &&
                                                <button
                                                    className={'absolute -right-16 bottom-5'}
                                                    onClick={scrollToTop}>
                                                    <ScrollToTopIcon/>
                                                </button>}
                                        </div>
                                    )
                                })}
                            </div>


                            <div className={'flex items-center justify-between mt-[10px] mb-[100px] ml-[53px]'}>
                                {roles[authData?.role]?.some(role => role.showCreateFormButton)
                                    ?
                                    <div
                                        className={`flex gap-[10px]`}>
                                        <ButtonHoverText text={'Արտահանել որպես PDF'} className={'fill-icon'}
                                                         icon={<PdfIcon/>} onClick={downloadPdf}/>
                                        <ButtonHoverText text={'Արտահանել որպես XLSX'} className={'fill-icon'}
                                                         icon={<XlsxIcon/>} onClick={handleDownloadXlsx}/>
                                        {students &&
                                            <CSVLink className="download-csv-button" filename="my-file.csv"
                                                     data={csvData}>
                                                <ButtonHoverText text={'Արտահանել որպես CSV'} className={'fill-icon'}
                                                                 icon={<CsvIcon/>} onClick={a => a}/>
                                            </CSVLink>}
                                    </div>
                                    :
                                    <div></div>
                                }

                                <div className={'flex items-center'}>
                                    {filters?.page && <Pagination count={students?.pagesCount} onChange={changePage}
                                                                  page={parseInt(filters?.page)}/>}
                                    <div className={'flex items-center justify-center gap-2.5'}>
                                        <span>Էջ</span>
                                        <input type="number" onKeyDown={changeCustomPage}
                                               onChange={e => setCustomPage(e.target.value)}
                                               className={'max-w-[50px] outline-none px-2 py-0.5 text-center border border-black rounded-[5px]'}/>
                                    </div>
                                    <div className={'ml-[30px]'}>
                                        <PageSizeDropdown onChange={handleChangeSize} value={filters?.size}/>
                                    </div>
                                </div>
                                {roles[authData?.role]?.some(role => role.showAddToGroupButton)
                                    ?
                                    <button
                                        onClick={() => setOpenAddStudentModal(true)}
                                        className={`flex items-center gap-[10px] border border-black py-[2px] px-[10px] rounded-[5px]`}>
                                        Ավելացնել խմբում <PeopleIcon/>
                                    </button>
                                    :
                                    <div></div>
                                }

                            </div>
                        </>
                        :
                        <p className={'text-center mt-5'}>Ուսանողներ չկան</p>

                }
            </Container>
        </>
    )
}

export default Students
