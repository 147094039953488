import React, {useEffect, useState} from 'react';
import {Container} from "../../../components/shared";
import ProjectsFilter from "../../../components/programs/program/projects/ProjectsFilter";
import {useDispatch, useSelector} from "react-redux";
import {getAllProjects} from "../../../store/actions/programs";
import ProjectItem from "../../../components/programs/program/projects/ProjectItem";
import {useFilters} from "../../../hooks/useFilters";
import Pagination from "../../../components/shared/Pagination";
import CreateProjectModal from "../../../components/programs/program/projects/modals/CreateProjectModal";
import {DropdownInputCheckbox} from "../../../components/shared/dropdowns";
import {sortProjectsData} from "../../../common/dropdownOptions";
import {useLocation, useNavigate} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import ProjectItemMobile from "../../../components/programs/program/projects/ProjectItemMobile";
import {DropdownArrow} from "../../../assets/icons";
import ProjectsFilterMobile from "../../../components/programs/program/projects/ProjectsFilterMobile";

const initialFilters = {page: 1, size: 10}

const Projects = () => {
    const {projects, loading} = useSelector(state => state.programs)

    const [editProjectModal, setEditProjectModal] = useState(null)
    const [sortOption, setSortOption] = useState(sortProjectsData[0])
    const [showScrollToTop, setShowScrollToTop] = useState(false)

    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        clearAllFilters,
        changePage,
        submitFilters,
        setFilters
    } = useFilters(initialFilters, getAllProjects, 'projects')

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const isPhone = useMediaQuery({maxWidth: 640})

    const searchParams = new URLSearchParams(location.search)
    const page = searchParams.get('page') || 1
    const size = searchParams.get('size') || 10

    const handleEditProject = (project) => {
        setEditProjectModal(project)
    }

    const handleChangeSortOption = (name, option) => {
        navigate(`/projects?sort=${option._id}`)
        setSortOption(option)
    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 667) {
                setShowScrollToTop(true)
            } else {
                setShowScrollToTop(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    useEffect(() => {
        editProjectModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [editProjectModal])

    return (
        <div>
            {editProjectModal &&
                <CreateProjectModal close={() => setEditProjectModal(null)} projectData={editProjectModal}
                                    page={page}
                                    pageType={'allProjects'} size={size}
                />
            }

            <h2 className={'text-[20px] lg:text-[50px] text-[#FCC10F] font-bold text-center my-5 lg:my-[30px]'}>Նախագծեր</h2>
            {isPhone
                ?
                <ProjectsFilterMobile
                    filters={filters}
                    setFilters={setFilters}
                    discardFilters={discardFilters}
                    submitFilters={submitFilters}
                    handleChange={handleChange}
                    changeSelectedFilters={changeSelectedFilters}
                    clearAllFilters={clearAllFilters}
                />
                :
                <ProjectsFilter
                    filters={filters}
                    setFilters={setFilters}
                    discardFilters={discardFilters}
                    submitFilters={submitFilters}
                    handleChange={handleChange}
                    changeSelectedFilters={changeSelectedFilters}
                />
            }

            <Container>
                {!isPhone &&
                    <div className={'flex items-center justify-end gap-5'}>
                        <span className={'text-[12px]'}>Դասակարգել</span>
                        <div className={'w-[288px]'}>
                            <DropdownInputCheckbox
                                data={sortProjectsData}
                                name={'project'}
                                onChange={handleChangeSortOption}
                                selected={[sortOption]}
                                previewClassName={'py-2.5 rounded-[5px] px-[30px] text-[12px]'}
                                optionClassName={'px-[30px]'}
                                textSize={'text-[12px]'}
                                hideCheckbox
                                disableInput
                            />
                        </div>
                    </div>
                }
                {
                    loading
                        ?
                        <p className={'text-center mt-[30px]'}>Բեռնվում է...</p>
                        :
                        projects?.data?.length > 0
                            ?
                            <>
                                <div className={isPhone && 'grid grid-cols-1 place-items-center gap-5'}>
                                    {projects?.data?.map((project, index) =>
                                        isPhone
                                            ?
                                            <ProjectItemMobile
                                                key={index}
                                                {...project}
                                                pageType={'allProjects'}
                                                page={page}
                                                size={size}
                                            />
                                            :
                                            <ProjectItem
                                                key={index}
                                                {...project}
                                                page={page}
                                                size={size}
                                                type={'common'}
                                                pageType={'allProjects'}
                                                handleEditProject={() => handleEditProject(project)}
                                            />
                                    )}
                                </div>
                                <Pagination count={projects?.pagesCount} page={filters?.page || 1}
                                            onChange={changePage}/>
                            </>
                            :
                            <p className={'text-center mt-[30px]'}>Նախագծեր չկան</p>}
            </Container>

            {showScrollToTop && isPhone &&
                <button
                    className={'fixed z-[9999] bottom-4 left-[80%] bg-white border border-black border-opacity-70 rounded-full rotate-180 p-[7px]'}
                    onClick={scrollToTop}
                >
                    <DropdownArrow/>
                </button>
            }
        </div>
    );
};

export default Projects;