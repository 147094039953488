import React, {useEffect, useRef, useState} from 'react'
import {PdfColoredIcon, UploadIcon} from "../../../assets/icons";
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import {getMediaOfChat} from "../../../socket/chats";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {downloadFileFromSrc} from "../../../utils/fileDownloads/downloadFile";
import {useMediaQuery} from "react-responsive";

const ChatMediaViewer = ({setOpenChatMediaViewer, name, image, className}) => {
    const {socket} = useSelector(state => state.socket)
    const {chatRoom, media} = useSelector(state => state.chats)

    const [selectedType, setSelectedType] = useState('media')

    const dispatch = useDispatch()
    const ref = useRef(null)

    const isPhone = useMediaQuery({maxWidth: 640})

    useOutsideClick(ref, () => setOpenChatMediaViewer(null))

    const handleTypeClick = (type) => {
        setSelectedType(type)
    }

    useEffect(() => {
        getMediaOfChat(socket, chatRoom, selectedType, dispatch)
    }, [socket, chatRoom, selectedType, dispatch])

    return (
        <div ref={ref} className={`w-full h-full lg:w-[171px] ${isPhone && 'px-[15px]'}`}>
            {!isPhone &&
                <div className={'w-full flex items-center justify-center py-5 text-[12px]'}>
                    <div className={'flex flex-col gap-2.5'}>
                        <img src={image} alt={'chat_avatar'}
                             className={'w-[40px] h-[40px] object-cover mx-6 rounded-[3px]'}/>
                        <p className={'text-center'}>{name}</p>
                    </div>
                </div>
            }
            <div className={`flex ${isPhone && 'w-full justify-center'} border lg:border-b pt-[18px] pb-1 lg:py-5 px-2.5 text-[12px]`}>
                <div onClick={() => handleTypeClick('media')}
                     className={`cursor-pointer border-b-[3px] ${isPhone && 'pb-2.5'} ${selectedType === 'media' ? 'border-[#FCC10F]' : 'border-white'}`}>Նկարներ
                </div>
                <div className={'border border-black border-opacity-70 max-h-[15px] mx-[54px] lg:mx-5'}></div>
                <div onClick={() => handleTypeClick('file')}
                     className={`cursor-pointer border-b-[3px] ${selectedType === 'file' ? 'border-[#FCC10F]' : 'border-white'}`}>Ֆայլեր
                </div>
            </div>
            {selectedType === 'media'
                ?
                <div className={'flex flex-wrap gap-[15px] lg:gap-[2px] p-1'}>
                    {media?.length > 0
                        ?
                        media.map((image, i) => {
                            return (
                                <div key={i}>
                                    <img src={image.src} alt={'image'} className={'max-w-[100px] max-h-[112px] lg:w-[51px] lg:h-[57px] object-cover'}/>
                                </div>
                            )
                        })
                        :
                        <p className={'w-full text-[12px] text-center'}>Նկարներ չկան</p>
                    }
                </div>
                :
                <div className={''}>
                    {media.length > 0
                        ?
                        media.map(file => {
                            return (
                                <div className={'flex items-center justify-between px-2.5 pt-5 pb-2.5 border-b'}
                                     key={file._id}>
                                    <div className={'flex items-center gap-2.5'}>
                                        <PdfColoredIcon/>
                                        <div className={'flex flex-col gap-1'}>
                                            <p className={'w-full lg:max-w-[90px] break-all text-[12px]'}>{file.name}</p>
                                            <p className={'text-[10px] text-black text-opacity-70'}>{file.sizeFormatted} . {moment(file.createdAt).format('DD.MM.YYYY')}</p>
                                        </div>
                                    </div>
                                    <div onClick={() => downloadFileFromSrc(file.src, file.name)} className={'cursor-pointer'}>
                                        <UploadIcon width={isPhone ? 17 : 10} height={isPhone? 17 : 10}/>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <p className={'w-full text-[12px] text-center'}>Ֆայլեր չկան</p>
                    }
                </div>
            }
        </div>
    );
};

export default ChatMediaViewer;