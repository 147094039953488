import React from 'react';
import logo from "../../assets/images/logo.png";
import {Link} from "react-router-dom";
import {Container} from "../shared";
import {useMediaQuery} from "react-responsive";

const DefaultHeader = () => {
    const isPhone = useMediaQuery({maxWidth: 640})

    return (
        <div className={`fixed top-0 right-0 ${isPhone && 'max-h-[60px]'} z-[10000] left-0 bg-white py-1 border-0 lg:border-b lg:border-b-[#FCC10F]`}>
            <Container>
                <Link to={'/'} className={'block w-fit'}>
                    <img src={logo} className={'w-[30px] lg:w-10'} alt={'logo'}/>
                </Link>
            </Container>
        </div>
    );
};

export default DefaultHeader;