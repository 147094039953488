export const genderOptions = [
    {_id: 'male', name: 'Արական'},
    {_id: 'female', name: 'Իգական'},
]

export const ageData = [
    {
        _id: 1,
        name: 6
    },
    {
        _id: 2,
        name: 7
    },
    {
        _id: 3,
        name: 8
    },
    {
        _id: 4,
        name: 9
    }, {
        _id: 5,
        name: 10
    },
    {
        _id: 6,
        name: 11
    },
    {
        _id: 7,
        name: 12
    },
    {
        _id: 8,
        name: 13
    },
    {
        _id: 9,
        name: 14
    },
    {
        _id: 10,
        name: 15
    },
    {
        _id: 11,
        name: 16
    },
    {
        _id: 12,
        name: 17
    },
    {
        _id: 13,
        name: 18
    },
]
export const ageGroupData = [
    {
        _id: '8-11',
        name: '8-11 տարեկան'
    },
    {
        _id: '12-18',
        name: '12-18 տարեկան'
    },
]
export const ageGroupDataHybrid = [
    {
        _id: '8-11',
        name: '8-11 տարեկան'
    },
    {
        _id: '12-18',
        name: '12-18 տարեկան'
    },
    {
        _id: 'hybrid',
        name: 'Հիբրիդ'
    },
]
export const studentStatusData = [
    {
        _id: 'current',
        name: 'Ընթացիկ'
    },
    {
        _id: 'graduated',
        name: 'Շրջանավարտ'
    },
    {
        _id: 'terminated',
        name: 'Կրթակարգից դուրս մնացած'
    }
]
export const requestStatusData = [
    {
        _id: 'active',
        name: 'Ակտիվ'
    },
    {
        _id: 'passive',
        name: 'Պասիվ'
    }
]
export const groupStatusData = [
    {
        _id: 'active',
        name: 'Ակտիվ'
    },
    {
        _id: 'finished',
        name: 'Ավարտված'
    },
    {
        _id: 'closed',
        name: 'Փակ'
    }
]

export const groupStatusNames = {
    active: 'Ակտիվ',
    finished: 'Ավարտված',
    closed: 'Փակ'
}
export const gradeData = [
    {
        _id: 1,
        name: 1
    },
    {
        _id: 2,
        name: 2
    },
    {
        _id: 3,
        name: 3
    },
    {
        _id: 4,
        name: 4
    },
    {
        _id: 5,
        name: 5
    },
    {
        _id: 6,
        name: 6
    },
    {
        _id: 7,
        name: 7
    },
    {
        _id: 8,
        name: 8
    },
    {
        _id: 9,
        name: 9
    },
    {
        _id: 10,
        name: 10
    },
    {
        _id: 11,
        name: 11
    },
    {
        _id: 12,
        name: 12
    },
]

export const stopsCount = [
    {
        _id: 1,
        name: 1
    },
    {
        _id: 2,
        name: 2
    },
    {
        _id: 3,
        name: 3
    },
    {
        _id: 4,
        name: 4
    },
]

export const clothingSizeData = [
    {
        _id: 'xs',
        name: 'XS'
    },
    {
        _id: 's',
        name: 'S'
    },
    {
        _id: 'm',
        name: 'M'
    },
    {
        _id: 'l',
        name: 'L'
    },
    {
        _id: 'xl',
        name: 'XL'
    },
    {
        _id: 'xxl',
        name: 'XXL'
    },
]

export const daysOfWeek = ['Երկ', 'Երք', 'Չրք', 'Հնգ', 'Ուրբ', 'Շբթ', 'Կիր']
export const intervals = ['10:00-12:00', '12:00-14:00', '14:00-16:00', '16:00-18:00', '18:00-20:00']

export const generateScheduleDropdownData = () => {
    const data = []

    daysOfWeek.forEach((day, i) => {
        const weekdayHours = intervals.slice(3, 6)

        if (i > 4) {
            intervals.forEach(interval => {
                const dayData = {}
                dayData._id = `${i}_${interval}`
                dayData.name = `${daysOfWeek[i]}. ${interval}`
                data.push(dayData)
            })

        } else {
            weekdayHours.forEach(interval => {
                const dayData = {}
                dayData._id = `${i}_${interval}`
                dayData.name = `${daysOfWeek[i]} ${interval}`
                data.push(dayData)
            })

        }
    })

    return data
}

export const communitySortOptions = [
    {
        _id: 'alphabetical',
        name: 'ըստ այբբենական կարգի'
    },
    {
        _id: 'studentCountDescending',
        name: 'նվազման կարգով ըստ ուսանողների քանակի'
    },
    {
        _id: 'graduatedCountDescending',
        name: 'նվազման կարգով ըստ շրջանավարտների քանակի'
    },
    {
        _id: 'terminatedCountDescending',
        name: 'նվազման կարգով ըստ կրթակարգից դուրս մնացածների քանակի'
    },
    {
        _id: 'currentCountDescending',
        name: 'նվազման կարգով ըստ ակտիվ ուսանողների քանակի'
    },
]

export const smartDegreesData = [
    {
        _id: 'thinker',
        name: 'Մտածող'
    },
    {
        _id: 'creator',
        name: 'Ստեղծող'
    },
    {
        _id: 'whizzKid',
        name: 'Գիտակ'
    },
    {
        _id: 'wizard',
        name: 'Հրաշագործ'
    },
    {
        _id: 'genius',
        name: 'Տաղանդ'
    },
    {
        _id: 'mastermind',
        name: 'Հանճար'
    },
]

export const smartAwardsData = [
    {
        _id: 'pins',
        name: 'Փիներ'
    },
    {
        _id: 'hoodie',
        name: 'Հուդի'
    },
    {
        _id: 'smartVisits',
        name: 'ՍՄԱՐԹ այցեր'
    },
    {
        _id: 'notepad',
        name: 'Պլանշետ'
    },
    {
        _id: 'scholarship',
        name: 'Կրթաթոշակ'
    },
]

export const sortProjectsData = [
    {
        _id: 'newestFirst',
        name: 'ըստ ամսաթվի՝ սկզբից վերջ'
    },
    {
        _id: 'oldestFirst',
        name: 'ըստ ամսաթվի՝ վերջից սկիզբ'
    },
    {
        _id: 'mostLikedFirst',
        name: 'ըստ հավանումների քանակի նվազման'
    },
    {
        _id: 'lessLikedFirst',
        name: 'ըստ հավանումների քանակի աճման'
    },
]

export const daysOfWeekLong = [
    {
        _id: '0',
        name: 'Երկուշաբթի'
    },
    {
        _id: '1',
        name: 'Երեքշաբթի'
    },
    {
        _id: '2',
        name: 'Չորեքշաբթի'
    },
    {
        _id: '3',
        name: 'Հինգշաբթի'
    },
    {
        _id: '4',
        name: 'Ուրբաթ'
    },
    {
        _id: '5',
        name: 'Շաբաթ'
    },
    {
        _id: '6',
        name: 'Կիրակի'
    },
]

export const driverLicenseData = [
    {
        _id: 'a',
        name: 'A'
    },
    {
        _id: 'b',
        name: 'B'
    },
    {
        _id: 'c',
        name: 'C'
    },
]
