import React from 'react';
import {CloseIcon, EditIcon, ReplyIcon} from "../../../../assets/icons";

const EditMessage = ({message, setEditMessage, setIsEditingToMessage, setContent}) => {
    const handleCancelReplying = () => {
        setIsEditingToMessage(null)
        setEditMessage(null)
        setContent('')
    }

    return (
        <div id="edit-message" className="relative w-full flex justify-between pt-[30px] px-5">
            <div className={'w-[100px]'}></div>
            <div className="flex-1 mx-2 flex items-end">
                <div
                    className={"w-full min-h-[67px] bg-gray-100 border border-black border-opacity-50 border-b-0 rounded-[5px] rounded-b-[0px] px-5 py-2.5"}
                >
                    <div className={'relative flex justify-between'}>
                        <div className={'flex flex-col gap-2.5'}>
                            <div className={'flex items-center gap-2.5'}>
                                <EditIcon width={15} height={15} color={'#000'} />
                                <span
                                    className={'text-[14px] text-black text-opacity-80 bold'}>Խմբագրել նամակը</span>
                            </div>
                            <div className={'text-[14px] text-justify'}>{message}</div>
                        </div>
                        <button
                            className={'absolute right-0'}
                            onClick={handleCancelReplying}
                        >
                            <CloseIcon width={16} height={16} />
                        </button>
                    </div>
                </div>
            </div>
            <div className={'w-[50px]'}></div>
        </div>
    )
};

export default EditMessage;