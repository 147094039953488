import React, {useEffect, useState} from 'react';
import {Container} from "../../../../../components/shared";
import {AsteriskIcon} from "../../../../../assets/icons";
import Calendar from "../../../../../components/shared/calendar/Calendar";
import SelectSchedule from "../../../../../components/groups/SelectSchedule";
import DropdownInputCheckbox from "../../../../../components/shared/dropdowns/DropdownInputCheckbox";
import {ButtonFilled, ButtonOutlined} from "../../../../../components/shared/buttons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {checkGroupDetails} from "../../../../../utils/groups/checkGroupDetails";
import {changeGroupSettings, createWorkshop, getIntro, getWorkshop} from "../../../../../store/actions/groups";
import {ageGroupDataHybrid} from "../../../../../common/dropdownOptions";

const WorkshopSettings = () => {
    const {programs} = useSelector(state => state.common)

    const {group} = useSelector(state => state.groups)

    const [data, setData] = useState(null)

    const [validationErrors, setValidationErrors] = useState({})
    const [nameBeforeEdit, setNameBeforeEdit] = useState('')

    const {id} = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleStartChangeName = () => {
        setNameBeforeEdit(data.name)
    }

    const handleChangeName = (e) => {
        setData({...data, name: e.target.value})
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (data.name) {
                setNameBeforeEdit('')
            } else {
                setData({...data, name: nameBeforeEdit})
            }
        }
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleAddToSchedule = (scheduleDay) => {
        const exists = data.schedule.some(item => item.dayIndex === scheduleDay.dayIndex)

        if (exists) {
            setData({
                ...data,
                schedule: data.schedule.map(item => item.dayIndex === scheduleDay.dayIndex ? scheduleDay : item),
            })
        } else {
            setData({
                ...data,
                schedule: [...data.schedule, scheduleDay]
            })
        }
    }

    const handleSelectProgram = (_, program) => {
        setData({...data, program})
    }

    const handleRemoveScheduleDay = (dayIndex) => {
        setData({
            ...data,
            schedule: data.schedule.filter(item => item.dayIndex !== dayIndex),
        })
    }

    const handleChangeGuestName = (e) => {
        setData({...data, workshopGuest: e.target.value})
    }

    const changeStartDate = (e) => {
        setData({...data, startDate: e})
    }

    const handleDiscard = () => {
        navigate('/workshops?page=1&size=10&')
    }

    const handleSubmit = () => {
        const payload = {
            name: data.name,
            lessonDuration: data.lessonDuration,
            schedule: data.schedule
        }

        const errors = checkGroupDetails(payload, data)

        if (Object.keys(errors).length === 0) {
            dispatch(changeGroupSettings(id, payload, navigate(`/workshops/${group._id}`)))
        } else {
            setValidationErrors(errors)
        }
    }

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ""
        }))
    }

    useEffect(() => {
        if (group) {
            setData({
                ...data,
                name: group.name,
                workshopGuest: group.workshopGuest,
                program: group.program,
                startDate: group.startDate,
                endDate: group.endDate,
                lessonDuration: group.lessonDuration,
                lessonsCount: group.lessonsCount,
                schedule: group.schedule,
            })
        }
    }, [group])

    useEffect(() => {
        if (!group) {
            dispatch(getWorkshop(id))
        }
    }, [id])

    return (
        <div>
            <Container>
                <div className={'flex justify-center mb-[50px]'}>
                    {nameBeforeEdit ?
                        <input
                            name="name"
                            className={'w-full text-center text-[50px] text-[#FCC10F] font-bold outline-none'}
                            autoComplete={'off'}
                            autoFocus
                            onFocus={e => e.target.select()}
                            value={data.name}
                            onChange={handleChangeName}
                            onKeyDown={handleEnter}
                        />
                        :
                        <h2 onClick={handleStartChangeName}
                            className={'text-center text-[50px] text-[#FCC10F] font-bold break-all'}>{data?.name}</h2>
                    }
                </div>
                <div className={'grid lg:grid-cols-2 grid-cols-1 gap-[30px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Դասընթացավարի անունը</h2>
                            <div>
                                <input
                                    type={"text"}
                                    name={'workshopGuest'}
                                    className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                                    onChange={handleChangeGuestName}
                                    value={data?.workshopGuest}
                                />
                            </div>
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Դասերի քանակը <AsteriskIcon/></h2>
                            <input
                                type={"number"}
                                value={data?.lessonsCount}
                                name={'lessonsCount'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('lessonsCount')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px] pointer-events-none'}
                            />
                            {validationErrors.lessonsCount &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.lessonsCount}</p>}
                        </div>

                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>Աշխատարանի մեկնակի օր <AsteriskIcon/></h2>
                            <div className={'relative'}>
                                <div
                                    className={`absolute w-full bg-white border border-black border-opacity-70 py-[6px] rounded-[5px]`}>
                                    <Calendar displayFormat={'DD.MM.YYYY'}
                                              returnFormat={'YYYY-MM-DD'}
                                              value={data?.startDate}
                                              setDate={changeStartDate}
                                              placeholder={'օր/ամիս/տարի'}
                                              className={'py-[1px]'}
                                              optionClassName={'px-[30px]'}
                                              onFocus={() => handleFocus('startDate')}
                                              disableClick={true}
                                    />
                                    {validationErrors.startDate &&
                                        <p className={"absolute top-full text-red-500 mt-2"}>{validationErrors.startDate}</p>}
                                </div>
                            </div>
                        </div>

                        <div className={`flex flex-col gap-[30px] ${validationErrors ? 'mt-12' : 'mt-10'}`}>
                            <h2 className={'flex gap-[6px] text-[#FCC10F]'}>Գրաֆիկ <AsteriskIcon/></h2>
                            <SelectSchedule
                                schedule={data?.schedule}
                                removeScheduleDay={handleRemoveScheduleDay}
                                addToSchedule={handleAddToSchedule}
                                onFocus={() => handleFocus('schedule')}
                                lessonDuration={data?.lessonDuration}
                            />
                            {validationErrors.schedule &&
                                <p className={"text-red-500"}>{validationErrors.schedule}</p>}
                        </div>
                    </div>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կցել ծրագիր </h2>
                            <div>
                                <DropdownInputCheckbox
                                    data={programs}
                                    previewClassName={'px-[30px] rounded-[5px] py-[7px]'}
                                    optionClassName={'px-6'}
                                    name={'program'}
                                    placeholder={'Ընտրել ծրագիր'}
                                    onChange={handleSelectProgram}
                                    selected={[data?.program]}
                                    hideCheckbox
                                    onFocus={() => handleFocus('program')}
                                />
                            </div>
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Դասերի տևողությունը (րոպե)</h2>
                            <input
                                type={"number"}
                                value={data?.lessonDuration}
                                name={'lessonDuration'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('lessonDuration')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'flex items-center justify-end'}>
                        <div>
                            <div className={'w-fit flex gap-[30px] items-center mt-[100px]'}>
                                <ButtonOutlined
                                    onClick={handleDiscard}
                                    text={'Չեղարկել'}
                                    className={'w-[176px]'}
                                />
                                <ButtonFilled
                                    onClick={handleSubmit}
                                    text={'Պահպանել'}
                                    className={'w-[176px]'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default WorkshopSettings;