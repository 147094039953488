import React, {useRef} from 'react';
import {useOutsideClick} from "../../../../hooks/useOutsideClick";
import {useSelector} from "react-redux";
import ReactDOM from 'react-dom';
import {roles} from "../../../../common/roles";
import moment from "moment";

const LessonActionsMenu = ({
                               isFutureLesson,
                               markAttendance,
                               openReplaceLessonModal,
                               openCancelLessonModal,
                               lessonCanceled,
                               openRestoreCanceledLessonModal,
                               close,
                               date,
                               top,
                               left,
                               alignment,
                               type
                           }) => {
    const {authData} = useSelector((state) => state.auth);
    const userHasPermissions = roles[authData?.role]?.some(role => role.allowReplaceLesson || role.allowCancelLesson || role.allowRecoverLesson);
    const isSpecialType = (type === 'connect' || type === 'workshop' || type === 'trip') && (authData.role === 'projectsCoordinator');
    const isMdt = type === 'intro' && authData.role === 'mdt'
    const isInstructor = authData.role === 'instructor'

    const userCanMarkAttendance = (authData.role === 'instructor' && moment().isSame(moment(date, 'YYYY-MM-DD'), 'day')) || userHasPermissions

    const ref = useRef(null)
    useOutsideClick(ref, close);

    if ((lessonCanceled && !userHasPermissions) || (isFutureLesson && !userHasPermissions)) return null;

    return ReactDOM.createPortal(
        <div ref={ref} style={{top, left, transform: `translateY(-50%)`, position: 'absolute'}}
             className={(userHasPermissions || isSpecialType || isMdt || userCanMarkAttendance) && `w-[175px] py-[5px] shadow-md z-[9980] pointer-events-auto bg-white rounded-[3px] text-base ${alignment === 'right' ? 'right-0' : 'left-0'}`}>
            <ul>
                {lessonCanceled && userHasPermissions && (
                    <li className={'pl-2.5 py-[5px] cursor-pointer hover:bg-black hover:bg-opacity-5'}
                        onClick={openRestoreCanceledLessonModal}>Վերականգնել
                    </li>
                )}
                {(userCanMarkAttendance && !lessonCanceled) &&
                    <li className={'pl-2.5 py-[5px] cursor-pointer hover:bg-black hover:bg-opacity-5'}
                        onClick={markAttendance}>Ներկայություն
                    </li>
                }
                {!lessonCanceled && (userHasPermissions || isSpecialType || isMdt) && (
                    <>
                        {userHasPermissions && (
                            <>
                                <li className={'pl-2.5 py-[5px] cursor-pointer hover:bg-black hover:bg-opacity-5'}
                                    onClick={openReplaceLessonModal}>Տեղափոխել դասը
                                </li>
                                <li className={'pl-2.5 py-[5px] cursor-pointer hover:bg-black hover:bg-opacity-5'}
                                    onClick={openCancelLessonModal}>Չեղարկել դասը
                                </li>
                            </>
                        )}
                    </>
                )}
            </ul>
        </div>,
        document.body
    )
}

export default LessonActionsMenu;
