import {Dashboard, Form, Forms, Requests} from "../pages/main";
import {Student, Students} from "../pages/main/students";
import {CreateGroup, Group, Groups} from "../pages/main/groups";
import Programs from "../pages/main/programs/Programs";
import Program from "../pages/main/programs/Program";
import GroupSettings from "../pages/main/groups/group/settings/GroupSettings";
import Settings from "../pages/main/settings/Settings";
import Profile from "../pages/main/profile/Profile";
import Projects from "../pages/main/projects/Projects";
import Communities from "../pages/main/communities/Communities";
import Intros from "../pages/main/intros/Intros";
import CreateIntro from "../pages/main/intros/CreateIntro";
import Intro from "../pages/main/intros/intro/Intro";
import IntroSettings from "../pages/main/intros/intro/IntroSettings";
import Workshops from "../pages/main/smartEvents/workshops/Workshops";
import CreateWorkshop from "../pages/main/smartEvents/workshops/CreateWorkshop";
import Workshop from "../pages/main/smartEvents/workshops/workshop/Workshop";
import Connects from "../pages/main/smartEvents/connects/Connects";
import WorkshopSettings from "../pages/main/smartEvents/workshops/workshop/WorkshopSettings";
import CreateConnect from "../pages/main/smartEvents/connects/CreateConnect";
import Connect from "../pages/main/smartEvents/connects/connect/Connect";
import ConnectSettings from "../pages/main/smartEvents/connects/connect/ConnectSettings";
import Trips from "../pages/main/smartEvents/trips/Trips";
import Trip from "../pages/main/smartEvents/trips/trip/Trip";
import CreateTrip from "../pages/main/smartEvents/trips/CreateTrip";
import TripSettings from "../pages/main/smartEvents/trips/trip/TripSettings";
import Transport from "../pages/main/transport/Transport";
import Stops from "../pages/main/transport/subpages/stops/Stops";
import CreateStop from "../pages/main/transport/subpages/stops/CreateStop";
import EditStop from "../pages/main/transport/subpages/stops/EditStop";
import Routes from "../pages/main/transport/subpages/routes/Routes";
import CreateRoute from "../pages/main/transport/subpages/routes/CreateRoute";
import EditRoute from "../pages/main/transport/subpages/routes/EditRoute";
import Vehicles from "../pages/main/transport/vehicles/Vehicles";
import CreateVehicle from "../pages/main/transport/vehicles/CreateVehicle";
import Drivers from "../pages/main/transport/subpages/drivers/Drivers";
import Driver from "../pages/main/transport/subpages/drivers/driver/Driver";
import TransportTrips from "../pages/main/transport/subpages/trips/TransportTrips";
import TripAnalytics from "../pages/main/transport/subpages/trips/TripAnalytics";
import Statements from "../pages/main/statements/Statements";
import CreateStatement from "../pages/main/statements/CreateStatement";
import Statement from "../pages/main/statements/statement/Statement";
import EditStatement from "../pages/main/statements/statement/EditStatement";
import Chats from "../pages/main/chats/Chats";
import StudentProfileMobile from "../pages/main/students/mobile/StudentProfileMobile";
import CheckInOut from "../pages/main/check_in_out/CheckInOut";
import CheckinsHistory from "../components/checkins/CheckinsHistory";

export const routes = {
    'manager': [
        {path: '/', element: <Dashboard />},
        {path: '/forms', element: <Forms/>},
        {path: '/forms/:id', element: <Form/>},
        {path: '/requests', element: <Requests/>},
        {path: '/students', element: <Students/>},
        {path: '/students/:id/', element: <Student/>},
        {path: '/groups/create', element: <CreateGroup/>},
        {path: '/groups/:id/settings', element: <GroupSettings/>},
        {path: '/intros/:id/settings', element: <IntroSettings/>},
        {path: '/workshops/:id/settings', element: <WorkshopSettings/>},
        {path: '/connects/:id/settings', element: <ConnectSettings/>},
        {path: '/trips/:id/settings', element: <TripSettings/>},
        {path: '/groups/:id/*', element: <Group/>},
        {path: '/groups', element: <Groups/>},
        {path: '/programs', element: <Programs/>},
        {path: '/programs/:id/*', element: <Program/>},
        {path: '/settings/*', element: <Settings/>},
        {path: '/profile', element: <Profile/>},
        {path: '/projects', element: <Projects/>},
        {path: '/communities', element: <Communities/>},
        {path: '/intros/:id/*', element: <Intro/>},
        {path: '/intros', element: <Intros/>},
        {path: '/intros/create', element: <CreateIntro/>},
        {path: '/workshops', element: <Workshops/>},
        {path: '/workshops/create', element: <CreateWorkshop/>},
        {path: '/workshops/:id/*', element: <Workshop/>},
        {path: '/connects', element: <Connects/>},
        {path: '/connects/:id/*', element: <Connect/>},
        {path: '/connects/create', element: <CreateConnect/>},
        {path: '/trips', element: <Trips/>},
        {path: '/trips/:id/*', element: <Trip/>},
        {path: '/trips/create', element: <CreateTrip/>},
        {path: '/transport', element: <Transport/>},
        {path: '/transport/stops', element: <Stops/>},
        {path: '/transport/stops/create', element: <CreateStop/>},
        {path: '/transport/stops/edit', element: <EditStop/>},
        {path: '/transport/routes', element: <Routes/>},
        {path: '/transport/routes/create', element: <CreateRoute/>},
        {path: '/transport/routes/edit', element: <EditRoute/>},
        {path: '/vehicles', element: <Vehicles/>},
        {path: '/vehicles/create', element: <CreateVehicle/>},
        {path: '/transport/drivers', element: <Drivers/>},
        {path: '/transport/drivers/:id/*', element: <Driver/>},
        {path: '/transport/trips', element: <TransportTrips/>},
        {path: '/transport/trips/analytics', element: <TripAnalytics/>},
        {path: '/statements', element: <Statements/>},
        {path: '/statements/create', element: <CreateStatement/>},
        {path: '/statements/:id', element: <Statement/>},
        {path: '/statements/:id/edit', element: <EditStatement/>},
        {path: '/chats/:id?', element: <Chats/>},
        {path: '/checkin-history', element: <CheckinsHistory />},
    ],
    'admin': [
        {path: '/', element: <Dashboard/>},
        {path: '/forms', element: <Forms/>},
        {path: '/forms/:id', element: <Form/>},
        {path: '/requests', element: <Requests/>},
        {path: '/students', element: <Students/>},
        {path: '/students/:id/', element: <Student/>},
        {path: '/programs', element: <Programs/>},
        {path: '/programs/:id/*', element: <Program/>},
        {path: '/groups', element: <Groups/>},
        {path: '/groups/create', element: <CreateGroup/>},
        {path: '/groups/:id/*', element: <Group/>},
        {path: '/groups/:id/settings', element: <GroupSettings/>},
        {path: '/intros/:id/settings', element: <IntroSettings/>},
        {path: '/settings/*', element: <Settings/>},
        {path: '/profile', element: <Profile/>},
        {path: '/projects', element: <Projects/>},
        {path: '/communities', element: <Communities/>},
        {path: '/intros/:id/*', element: <Intro/>},
        {path: '/intros', element: <Intros/>},
        {path: '/intros/create', element: <CreateIntro/>},
        {path: '/chats/:id?', element: <Chats/>},
        {path: '/statements', element: <Statements/>},
        {path: '/statements/create', element: <CreateStatement/>},
        {path: '/statements/:id', element: <Statement/>},
        {path: '/workshops', element: <Workshops/>},
        {path: '/workshops/:id/*', element: <Workshop/>},
        {path: '/statements/:id/edit', element: <EditStatement/>},
        {path: '/connects', element: <Connects/>},
        {path: '/connects/:id/*', element: <Connect/>},
        {path: '/trips', element: <Trips/>},
        {path: '/trips/:id/*', element: <Trip/>},
        {path: '/transport', element: <Transport/>},
        {path: '/transport/stops', element: <Stops/>},
        {path: '/transport/routes', element: <Routes/>},
        {path: '/vehicles', element: <Vehicles/>},
        {path: '/transport/drivers', element: <Drivers/>},
        {path: '/transport/drivers/:id/*', element: <Driver/>},
        {path: '/transport/trips', element: <TransportTrips/>},
        {path: '/transport/trips/analytics', element: <TripAnalytics/>},
        {path: '/checkin-history', element: <CheckinsHistory />},
    ],
    'divisionManager': [
        {path: '/', element: <Dashboard/>},
        {path: '/forms', element: <Forms/>},
        {path: '/forms/:id', element: <Form/>},
        {path: '/requests', element: <Requests/>},
        {path: '/students', element: <Students/>},
        {path: '/students/:id/', element: <Student/>},
        {path: '/programs', element: <Programs/>},
        {path: '/programs/:id/*', element: <Program/>},
        {path: '/groups', element: <Groups/>},
        {path: '/groups/create', element: <CreateGroup/>},
        {path: '/groups/:id/*', element: <Group/>},
        {path: '/groups/:id/settings', element: <GroupSettings/>},
        {path: '/intros/:id/settings', element: <IntroSettings/>},
        {path: '/settings/*', element: <Settings/>},
        {path: '/profile', element: <Profile/>},
        {path: '/projects', element: <Projects/>},
        {path: '/communities', element: <Communities/>},
        {path: '/intros/:id/*', element: <Intro/>},
        {path: '/intros', element: <Intros/>},
        {path: '/intros/create', element: <CreateIntro/>},
        {path: '/chats/:id?', element: <Chats/>},
        {path: '/statements', element: <Statements/>},
        {path: '/statements/:id', element: <Statement/>},
        {path: '/workshops', element: <Workshops/>},
        {path: '/workshops/:id/*', element: <Workshop/>},
        {path: '/connects', element: <Connects/>},
        {path: '/connects/:id/*', element: <Connect/>},
        {path: '/trips', element: <Trips/>},
        {path: '/trips/:id/*', element: <Trip/>},
        {path: '/transport', element: <Transport/>},
        {path: '/transport/stops', element: <Stops/>},
        {path: '/transport/routes', element: <Routes/>},
        {path: '/vehicles', element: <Vehicles/>},
        {path: '/transport/drivers', element: <Drivers/>},
        {path: '/transport/drivers/:id/*', element: <Driver/>},
        {path: '/transport/trips', element: <TransportTrips/>},
        {path: '/transport/trips/analytics', element: <TripAnalytics/>},
        {path: '/checkin-history', element: <CheckinsHistory />},
    ],
    'volunteer': [
        {path: '/', element: <Dashboard/>},
        {path: '/forms', element: <Forms/>},
        {path: '/forms/:id', element: <Form/>},
        {path: '/requests', element: <Requests/>},
        {path: '/students', element: <Students/>},
        {path: '/students/:id/', element: <Student/>},
        {path: '/chats', element: <Chats/>},
    ],
    'instructor': [
        {path: '/', element: <Dashboard/>},
        {path: '/groups', element: <Groups/>},
        {path: '/groups/:id/*', element: <Group/>},
        {path: '/students/:id/', element: <Student/>},
        {path: '/programs', element: <Programs/>},
        {path: '/programs/:id/*', element: <Program/>},
        {path: '/projects', element: <Projects/>},
        {path: '/students', element: <Students/>},
        {path: '/chats/:id?', element: <Chats/>},
        {path: '/communities', element: <Communities/>},
        {path: '/statements', element: <Statements/>},
        {path: '/statements/:id', element: <Statement/>},
        {path: '/workshops', element: <Workshops/>},
        {path: '/workshops/:id/*', element: <Workshop/>},
        {path: '/connects', element: <Connects/>},
        {path: '/connects/:id/*', element: <Connect/>},
        {path: '/trips', element: <Trips/>},
        {path: '/trips/:id/*', element: <Trip/>},
        {path: '/intros/:id/*', element: <Intro/>},
        {path: '/intros', element: <Intros/>},
        {path: '/transport', element: <Transport/>},
        {path: '/transport/stops', element: <Stops/>},
        {path: '/transport/routes', element: <Routes/>},
        {path: '/vehicles', element: <Vehicles/>},
        {path: '/transport/drivers', element: <Drivers/>},
        {path: '/transport/drivers/:id/*', element: <Driver/>},
        {path: '/transport/trips', element: <TransportTrips/>},
        {path: '/profile', element: <Profile/>},
    ],
    'mdt': [
        {path: '/', element: <Dashboard/>},
        {path: '/forms', element: <Forms/>},
        {path: '/forms/:id', element: <Form/>},
        {path: '/requests', element: <Requests/>},
        {path: '/students', element: <Students/>},
        {path: '/students/:id/', element: <Student/>},
        {path: '/programs', element: <Programs/>},
        {path: '/programs/:id/*', element: <Program/>},
        {path: '/groups', element: <Groups/>},
        {path: '/groups/create', element: <CreateGroup/>},
        {path: '/groups/:id/*', element: <Group/>},
        {path: '/groups/:id/settings', element: <GroupSettings/>},
        {path: '/intros/:id/settings', element: <IntroSettings/>},
        {path: '/settings/*', element: <Settings/>},
        {path: '/profile', element: <Profile/>},
        {path: '/projects', element: <Projects/>},
        {path: '/communities', element: <Communities/>},
        {path: '/intros/:id/*', element: <Intro/>},
        {path: '/intros', element: <Intros/>},
        {path: '/intros/create', element: <CreateIntro/>},
        {path: '/chats/:id?', element: <Chats/>},
        {path: '/statements', element: <Statements/>},
        {path: '/statements/:id', element: <Statement/>},
        {path: '/workshops', element: <Workshops/>},
        {path: '/workshops/:id/*', element: <Workshop/>},
        {path: '/connects', element: <Connects/>},
        {path: '/connects/:id/*', element: <Connect/>},
        {path: '/trips', element: <Trips/>},
        {path: '/trips/:id/*', element: <Trip/>},
        {path: '/intros/:id/*', element: <Intro/>},
        {path: '/intros', element: <Intros/>},
        {path: '/transport', element: <Transport/>},
        {path: '/transport/stops', element: <Stops/>},
        {path: '/transport/routes', element: <Routes/>},
        {path: '/vehicles', element: <Vehicles/>},
        {path: '/transport/drivers', element: <Drivers/>},
        {path: '/transport/drivers/:id/*', element: <Driver/>},
        {path: '/transport/trips', element: <TransportTrips/>},
        {path: '/transport/trips/analytics', element: <TripAnalytics/>},
    ],
    'driver': [
        {path: '/', element: <Dashboard/>},
    ],
    'transportCoordinator': [
        {path: '/', element: <Dashboard/>},
        {path: '/students', element: <Students/>},
        {path: '/students/:id/', element: <Student/>},
        {path: '/programs', element: <Programs/>},
        {path: '/programs/:id/*', element: <Program/>},
        {path: '/projects', element: <Projects/>},
        {path: '/communities', element: <Communities/>},
        {path: '/statements', element: <Statements/>},
        {path: '/statements/:id', element: <Statement/>},
        {path: '/workshops', element: <Workshops/>},
        {path: '/workshops/:id/*', element: <Workshop/>},
        {path: '/connects', element: <Connects/>},
        {path: '/connects/:id/*', element: <Connect/>},
        {path: '/trips', element: <Trips/>},
        {path: '/trips/:id/*', element: <Trip/>},
        {path: '/intros/:id/*', element: <Intro/>},
        {path: '/intros', element: <Intros/>},
        {path: '/transport', element: <Transport/>},
        {path: '/transport/stops', element: <Stops/>},
        {path: '/transport/routes', element: <Routes/>},
        {path: '/vehicles', element: <Vehicles/>},
        {path: '/transport/drivers', element: <Drivers/>},
        {path: '/transport/drivers/:id/*', element: <Driver/>},
        {path: '/transport/trips', element: <TransportTrips/>},
        {path: '/profile', element: <Profile/>},
        {path: '/chats/:id?', element: <Chats/>},
        {path: '/transport/trips/analytics', element: <TripAnalytics/>},
    ],
    'projectsCoordinator': [
        {path: '/', element: <Dashboard/>},
        {path: '/students', element: <Students/>},
        {path: '/students/:id/', element: <Student/>},
        {path: '/programs', element: <Programs/>},
        {path: '/programs/:id/*', element: <Program/>},
        {path: '/projects', element: <Projects/>},
        {path: '/groups', element: <Groups/>},
        {path: '/groups/:id/*', element: <Group/>},
        {path: '/communities', element: <Communities/>},
        {path: '/statements', element: <Statements/>},
        {path: '/statements/:id', element: <Statement/>},
        {path: '/workshops', element: <Workshops/>},
        {path: '/workshops/:id/*', element: <Workshop/>},
        {path: '/connects', element: <Connects/>},
        {path: '/connects/:id/*', element: <Connect/>},
        {path: '/trips', element: <Trips/>},
        {path: '/trips/:id/*', element: <Trip/>},
        {path: '/intros/:id/*', element: <Intro/>},
        {path: '/intros', element: <Intros/>},
        {path: '/transport', element: <Transport/>},
        {path: '/transport/stops', element: <Stops/>},
        {path: '/transport/routes', element: <Routes/>},
        {path: '/vehicles', element: <Vehicles/>},
        {path: '/transport/drivers', element: <Drivers/>},
        {path: '/transport/drivers/:id/*', element: <Driver/>},
        {path: '/transport/trips', element: <TransportTrips/>},
        {path: '/profile', element: <Profile/>},
        {path: '/chats/:id?', element: <Chats/>},
    ],
    'dispatcher': [
        {path: '/', element: <Dashboard/>},
        {path: '/students', element: <Students/>},
        {path: '/programs', element: <Programs/>},
        {path: '/programs/:id/*', element: <Program/>},
        {path: '/projects', element: <Projects/>},
        {path: '/groups', element: <Groups/>},
        {path: '/groups/:id/*', element: <Group/>},
        {path: '/communities', element: <Communities/>},
        {path: '/statements', element: <Statements/>},
        {path: '/statements/:id', element: <Statement/>},
        {path: '/workshops', element: <Workshops/>},
        {path: '/workshops/:id/*', element: <Workshop/>},
        {path: '/connects', element: <Connects/>},
        {path: '/connects/:id/*', element: <Connect/>},
        {path: '/trips', element: <Trips/>},
        {path: '/trips/:id/*', element: <Trip/>},
        {path: '/intros/:id/*', element: <Intro/>},
        {path: '/intros', element: <Intros/>},
        {path: '/transport', element: <Transport/>},
        {path: '/transport/stops', element: <Stops/>},
        {path: '/transport/routes', element: <Routes/>},
        {path: '/vehicles', element: <Vehicles/>},
        {path: '/transport/drivers', element: <Drivers/>},
        {path: '/transport/drivers/:id/*', element: <Driver/>},
        {path: '/transport/trips', element: <TransportTrips/>},
        {path: '/profile', element: <Profile/>},
        {path: '/chats/:id?', element: <Chats/>},
        {path: '/transport/trips/analytics', element: <TripAnalytics/>},
    ],
    'student': [
        {path: '/', element: <Dashboard />},
        {path: '/groups', element: <Groups/>},
        {path: '/statements', element: <Statements/>},
        {path: '/statements/:id', element: <Statement/>},
        {path: '/projects', element: <Projects/>},
        {path: '/chats/:id?', element: <Chats/>},
        {path: '/profile', element: <StudentProfileMobile />},
    ],
    'checkin': [
        {path: '/', element: <CheckInOut />},
    ],
}

export const routeNames = {
    'manager': [
        {path: "/students?page=1&size=10&", name: 'Գլխավոր'},
        {path: "/forms?page=1&size=10&", name: 'Ստեղծել հայտ'},
        {path: "/requests?page=1&size=10&trash=false", name: 'Հայտադարան'},
    ],
    'volunteer': [
        {path: "/students?page=1&size=10&", name: 'Գլխավոր'},
        {path: "/forms?page=1&size=10&", name: 'Ստեղծել հայտ'},
        {path: "/requests?page=1&size=10&trash=false", name: 'Հայտադարան'},
    ],
}