import React, { useState, useEffect } from 'react';
import Calendar from "../shared/calendar/Calendar";
import { CloseIcon } from "../../assets/icons";
import moment from "moment";

const SelectVacation = ({ startDate, endDate, onChangeStartDate, onChangeEndDate, deleteVacation }) => {
    const [isPastEndDate, setIsPastEndDate] = useState(false);

    useEffect(() => {
        const now = moment();
        const end = moment(endDate, 'YYYY-MM-DD');
        setIsPastEndDate(now.isAfter(end));
    }, [endDate]);

    return (
        <div className={'relative h-[90px] flex justify-between flex-wrap gap-[30px]'}>
            {
                <button
                    disabled={isPastEndDate && true}
                    className={'absolute right-0 top-0 flex items-center justify-center bg-black bg-opacity-5 rounded-full p-1 w-5 h-5'}
                    onClick={deleteVacation}
                >
                    <CloseIcon width={20} height={20} />
                </button>
            }

            <div>
                <h2>Արձակուրդի սկիզբ</h2>
                <div className={'w-[297px] border border-black border-opacity-70 py-[6px] rounded-[5px] mt-[30px]'}>
                    <Calendar
                        displayFormat={'DD.MM.YYYY'}
                        returnFormat={'YYYY-MM-DD'}
                        value={startDate}
                        setDate={startDate => onChangeStartDate(startDate)}
                        placeholder={'օր/ամիս/տարի'}
                        className={'py-[1px]'}
                        gapClassName={'gap-1'}
                        showShortMonths={true}
                    />
                </div>
            </div>
            <div>
                <h2>Արձակուրդի ավարտ</h2>
                <div className={'w-[297px] border border-black border-opacity-70 py-[6px] rounded-[5px] mt-[30px]'}>
                    <Calendar
                        displayFormat={'DD.MM.YYYY'}
                        returnFormat={'YYYY-MM-DD'}
                        value={endDate}
                        setDate={endDate => onChangeEndDate(endDate)}
                        placeholder={'օր/ամիս/տարի'}
                        className={'py-[1px]'}
                        paddingClassName={'px-2 pb-[2px]'}
                        gapClassName={'gap-1'}
                        marginClassName={'mt-1 max-w-[297px]'}
                        showShortMonths={true}
                    />
                </div>
            </div>
        </div>
    );
};

export default SelectVacation;
