const SwitchButton = ({checked, handleChange}) => {
    const handleCheckboxChange = () => {
        handleChange()
    };

    return (
        <label
            className={`flex cursor-pointer select-none items-center ${checked ? 'border-yellow-400' : 'border-black border-opacity-70'} border-[1.5px] rounded-full`}>
            <input
                type='checkbox'
                checked={checked}
                onChange={handleCheckboxChange}
                className='sr-only'
            />
            <div className='relative'>
                <div className={`h-[30px] w-[50px] rounded-full bg-${checked ? 'green' : 'gray'} transition`}></div>
                {checked ?
                    <div className="absolute top-1 rounded-full bg-yellow-400 w-5 h-5 translate-x-full my-[1px]"></div>
                    :
                    <div
                        className="absolute top-1 rounded-full bg-[#000] bg-opacity-70 w-[15px] h-[15px] my-[3px] ml-[8px]"></div>
                }
            </div>
        </label>
    )
}

export default SwitchButton;
