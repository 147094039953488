import {
    CHAT_MESSAGE,
    SET_CHAT,
    SET_CHAT_ROOM,
    SET_CHATS, SET_NEW_NOTIFICATION_COUNT, SET_NOTIFICATIONS, SET_REPLYING_MESSAGE,
    USER_DELETED_MESSAGE,
    USER_PINNED_UNPINNED_MESSAGE
} from "../constants/actionTypes";

export const setNewNotificationsCount = (count) => async (dispatch) => {
    try {
        dispatch({ type: SET_NEW_NOTIFICATION_COUNT, payload: count })
    } catch (error) {
        console.log(error.message)
    }
}

export const setNotifications = (nots) => async (dispatch) => {
    try {
        dispatch({ type: SET_NOTIFICATIONS, payload: {nots} })
    } catch (error) {
        console.log(error.message)
    }
}