import React, {useEffect, useRef, useState} from 'react';
import {CalendarIcon, FileIcon, OpenedEyeIcon, ReturnLeftIcon} from '../../../../assets/icons';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {Link, useParams} from "react-router-dom";
import {Container} from "../../../../components/shared";
import StatementImages from "../../../../components/statements/StatementImages";
import {useDispatch, useSelector} from "react-redux";
import {getStatement} from "../../../../store/actions/statements";
import moment from "moment";
import {useMediaQuery} from "react-responsive";

const Statement = () => {
    const {statement} = useSelector(state => state.statements)

    const [showAudienceList, setShowAudienceList] = useState(false);
    const [listPosition, setListPosition] = useState('5px');
    const audienceListRef = useRef(null)

    const modifiedDate = moment(statement?.createdAt).format('DD.MM.YYYY')

    const {id} = useParams()
    const dispatch = useDispatch()

    const isPhone = useMediaQuery({maxWidth: 640})

    const convertAudienceToArray = (audience) => {
        const audienceArray = []

        audience?.ageGroup && audienceArray.push({label: 'Տարիքային խումբ', value: audience.ageGroup})

        audience?.community?.length && audience.community.forEach(community => {
            audienceArray.push({label: 'Համայնք', value: community.name})
        })

        audience?.group?.length && audience.group.forEach(group => {
            audienceArray.push({label: 'Խումբ', value: group.name})
        })

        audience?.program?.length && audience.program.forEach(program => {
            audienceArray.push({label: 'Ծրագիր', value: program.name})
        })

        audience?.schedule?.length && audience.schedule.forEach(schedule => {
            audienceArray.push({label: 'Գրաֆիկ', value: schedule})
        })

        audience?.gender && audienceArray.push({label: 'Սեռ', value: audience.gender})

        audience?.statusInGroup?.length && audience.statusInGroup.forEach(status => {
            audienceArray.push({label: 'Կարգավիճակ (խմբում)', value: status})
        })

        audience?.globalStatus && audienceArray.push({label: 'Կարգավիճակ (գլոբալ)', value: audience.globalStatus})

        return audienceArray
    }

    const audienceArray = convertAudienceToArray(statement?.audience)

    const formatValue = (value) => {
        if (typeof value === 'object') {
            return JSON.stringify(value, null, 2)
        }
        return value;
    }

    const handleMouseEnter = () => {
        setListPosition('5px');
        setShowAudienceList(true);
    };

    const handleMouseLeave = () => {
        setShowAudienceList(false);
    }

    const leftSide = (
        <>
            <div className={'flex items-center gap-2.5'}>
                <img alt={'profile-image'} src={statement?.author?.profileImage}
                     className={'w-[15px] h-[15px] object-cover select-none rounded-full'}/>
                <span className={'text-black text-opacity-70 text-[12px]'}>{statement?.author?.fullName}</span>
            </div>
            <div className={'group relative flex items-center gap-2.5 cursor-pointer'}
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}
            >
                <FileIcon/>
                <p className={'text-[12px] text-black text-opacity-70'}>Թիրախային խումբ</p>
                <div
                    ref={audienceListRef}
                    className={`absolute z-[99999] top-0 left-full mt-5 w-[148px] ${showAudienceList ? 'flex' : 'hidden'} flex-col gap-5 p-2.5 custom-shadow bg-white rounded-[3px]`}
                    style={{top: listPosition}}
                >
                    {audienceArray.map((item, index) => {
                        return (
                            <p key={index} className={'text-[12px]'}>{formatValue(item.value?.name)}</p>
                        )
                    })}
                </div>
            </div>
        </>
    )

    const rightSide = (
        <>
            <div className={'flex items-center gap-2.5'}>
                <CalendarIcon width={15} height={15}/>
                <span className={'text-black text-opacity-70 text-[12px]'}>{modifiedDate}</span>
            </div>
            <div className={'flex items-center gap-2.5'}>
                <OpenedEyeIcon width={15} height={15}/>
                <span className={'text-black text-opacity-70 text-[12px]'}>{statement?.viewedBy}</span>
            </div>
        </>
    )

    useEffect(() => {
        if (showAudienceList && audienceListRef.current) {
            const rect = audienceListRef.current.getBoundingClientRect();
            if (rect.bottom > window.innerHeight) {
                setListPosition(`-${rect.height}px`);
            } else {
                setListPosition('5px');
            }
        }
    }, [showAudienceList])

    useEffect(() => {
        dispatch(getStatement(id))
    }, [])

    return (
        <>
            <Container>
                <div>
                    <Link to={'/statements'}
                          className={'flex items-center gap-2.5 lg:gap-[30px] group w-fit cursor-pointer fill-icon'}>
                        <ReturnLeftIcon width={isPhone && 17} height={isPhone && 17}/>
                        <p className={'text-[14px] lg:text-[16px] transition group-hover:text-[#FCC10F] text-black text-opacity-70'}>Վերադառնալ
                            հայտարարություններ</p>
                    </Link>
                </div>
                <h1 className={'text-center text-[20px] lg:text-[28px] bold text-[#FCC10F] mt-2.5 lg:mt-5'}>{statement?.title}</h1>
            </Container>

            <StatementImages images={statement?.media}/>

            <Container>
                <div>
                    {statement?.body.split(': ').map((text, index) => (
                        <p key={index} className="mb-4 text-justify text-[14px] lg:text-[16px]">
                            {text + (index < statement?.body.split(': ').length - 1 ? ':' : '')}
                        </p>
                    ))}
                </div>
                {statement?.link && <Link to={statement?.link.href} target={'_blank'}
                                          className={'text-[#447EB9] text-[14px] lg:text-[16px]'}>{statement?.link.name}</Link>}

                {isPhone
                    ?
                    <div className={'flex items-center justify-between mt-5'}>
                        <div className={'flex flex-col gap-2.5'}>
                            {leftSide}
                        </div>
                        <div className={'flex flex-col gap-2.5'}>
                            {rightSide}
                        </div>
                    </div>
                    :
                    <div className={'flex items-center justify-between w-full mt-[30px]'}>
                        {leftSide}
                        {rightSide}
                    </div>
                }
            </Container>
        </>
    );
};

export default Statement