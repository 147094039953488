import React from 'react';
import { useMediaQuery } from "react-responsive";

const CircularProgress = ({ percentage }) => {
    const isPhone = useMediaQuery({ maxWidth: 640 })

    const radius = isPhone ? 35 : 60
    const stroke = isPhone ? 10 : 20
    const normalizedRadius = radius - stroke * 0.5
    const circumference = normalizedRadius * 2 * Math.PI
    const strokeDashoffset = circumference - (percentage / 100) * circumference

    const size = isPhone ? 80 : 120

    return (
        <svg height={size} width={size}>
            <circle
                stroke="#e0e0e0"
                fill="transparent"
                strokeWidth={stroke}
                r={normalizedRadius}
                cx={size / 2}
                cy={size / 2}
            />
            <circle
                stroke="#fcc10f"
                fill="transparent"
                strokeWidth={stroke}
                strokeDasharray={circumference + ' ' + circumference}
                style={{ strokeDashoffset }}
                r={normalizedRadius}
                cx={size / 2}
                cy={size / 2}
            />
            <text
                x="50%"
                y="50%"
                dy=".3em"
                textAnchor="middle"
                fontSize={isPhone ? "14px" : "16px"}
                fill="#000"
            >
                {`${percentage}%`}
            </text>
        </svg>
    );
};

export default CircularProgress;
