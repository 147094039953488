import React, {useEffect, useState} from 'react';
import AddButton from "../../../../components/shared/buttons/AddButton";
import DivisionItem from "../../../../components/settings/divisions/DivisionItem";
import CreateDivisionModal from "../../../../components/settings/divisions/CreateDivisionModal";
import SettingsNavbar from "../../../../components/settings/SettingsNavbar";
import {useDispatch, useSelector} from "react-redux";
import {getDivisions} from "../../../../store/actions/profile";

const Divisions = () => {
    const {divisions, loading} = useSelector(state => state.profile)

    const [divisionToCreate, setDivisionToCreate] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        divisionToCreate ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [divisionToCreate]);

    useEffect(() => {
        dispatch(getDivisions())
    }, [])

    return (
        <div>
            {divisionToCreate &&
                <CreateDivisionModal close={() => setDivisionToCreate(null)}/>
            }

            <div className={'flex items-center justify-between'}>
                <AddButton text={'Դիվիժն'} onClick={() => setDivisionToCreate(true)}/>
                <SettingsNavbar/>
            </div>

            <div>
                {
                    loading
                        ?
                        <p className={'text-center mt-[30px]'}>Բեռնվում է...</p>
                        :
                        divisions?.length > 0
                            ?
                            <div>
                                {divisions?.map((division, index) => <DivisionItem {...division} key={index}/>)}
                            </div>
                            :
                            <p className={'text-center mt-[30px]'}>Դիվիժններ չկան</p>}
            </div>
        </div>
    )
}

export default Divisions;