import React, {useEffect, useRef, useState} from 'react';
import {DropdownArrow} from '../../../assets/icons';
import {gsap} from 'gsap'
import {roles} from "../../../common/roles";
import {useSelector} from "react-redux";

const FormStatusDropdown = ({data, name, selectedKey, value, onChange, placeholder, onTouch}) => {
    const {authData} = useSelector(state => state.auth)

    const [open, setOpen] = useState(false);
    const optionsRef = useRef(null);
    const selectedRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (
                !optionsRef.current?.contains(e.target) &&
                !selectedRef.current?.contains(e.target)
            ) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    if (!data || !onChange) {
        throw new Error('Missing props in DropdownInputCheckbox component (data, value, onChange)');
    }

    const handleSelect = (newValue) => {
        onChange({target: {value: newValue, name}});
        setOpen(false);
    };

    useEffect(() => {
        const optionsElement = optionsRef.current;

        if (open) {
            gsap.fromTo(
                optionsElement,
                {opacity: 0, height: 0, display: 'block'},
                {opacity: 1, height: 'auto', duration: 0.3}
            )
        } else {
            gsap.to(optionsElement, {opacity: 0, height: 0, duration: 0.2, display: 'none'})
        }
    }, [open]);

    return (
        roles[authData?.role]?.some(role => role.showChangeFormStatusButton)
            ?
            <div
                className={`relative h-full w-[220px] bg-white transition select-none`}>
                <div
                    onClick={() => setOpen(!open)}
                    ref={selectedRef}
                    className={`h-full flex justify-between items-center gap-2 px-7 cursor-pointer border-x border-1 ${open ? 'border-black border-opacity-30' : 'border-transparent'}`}
                >
                    <span>{value}</span>
                    <button>
                        <DropdownArrow/>
                    </button>
                </div>
                <div
                    ref={optionsRef}
                    className="absolute top-full z-50 w-full max-h-[171px] bg-white overflow-hidden border border-black border-opacity-30 rounded-b-[5px] shadow-md"
                    style={{display: 'none', opacity: 0, height: 0}}
                >
                    {data.map((option, i) => (
                        option.value !== selectedKey && <div
                            key={option.id || i}
                            onClick={() => handleSelect(option.value)}
                            className={`px-7 py-2 my-2.5 cursor-pointer hover:bg-gray-100 ${
                                option.name === value && 'bg-gray-200'
                            }`}
                        >
                            {option.name}
                        </div>
                    ))}
                </div>
            </div>
            :
            <div className={'py-1.5'}>{value}</div>
    );
};

export default FormStatusDropdown;
