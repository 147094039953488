import React, {useEffect, useRef, useState} from 'react';
import {ButtonFilled, ButtonOutlined} from "../../shared/buttons";
import {AsteriskIcon, CheckedIcon, CloseIcon, SearchIcon} from "../../../assets/icons";
import {searchChats} from "../../../socket/chats";
import {forwardMessage} from "../../../socket/chats";
import {useDispatch, useSelector} from "react-redux";
import {useMediaQuery} from "react-responsive";

const ShareFilesModal = ({close, closeMediaModal, messageId, mediaId}) => {
    const {socket} = useSelector(state => state.socket)
    const {authData} = useSelector((state) => state.auth)
    const {searchedChats = []} = useSelector(state => state.chats)

    const [value, setValue] = useState('')
    const [selectedChats, setSelectedChats] = useState([])
    const [isInputFocused, setIsInputFocused] = useState(false)

    const dispatch = useDispatch()
    const wrapperRef = useRef(null)
    const searchOptionsRef = useRef(null)

    const isPhone = useMediaQuery({maxWidth: 640})

    useEffect(() => {
        if (value.trim() && isInputFocused) {
            searchChats({socket, searchValue: value, includeExistingChats: true, includeGroups: true, includeNewUsers: true, dispatch})
        }
    }, [value, socket, dispatch, isInputFocused])

    const handleChatSelect = (chat) => {
        setSelectedChats(prevSelectedChats => {
            if (prevSelectedChats.find(selected => selected._id === chat._id)) {
                return prevSelectedChats.filter(selected => selected._id !== chat._id)
            } else {
                return [...prevSelectedChats, chat]
            }
        })
    }

    const removeSelectedChat = (chatId) => {
        setSelectedChats(prevSelectedChats => prevSelectedChats.filter(chat => chat._id !== chatId))
    }

    const handleBlur = (e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsInputFocused(false);
            if (selectedChats.length > 0) {
                const firstChat = selectedChats[0]
                setValue(firstChat.name)
            }
        }
    }

    const handleFocus = () => {
        setIsInputFocused(true)
        setValue('')
    }

    const combinedChats = [
        ...selectedChats,
        ...(searchedChats || []).filter(chat => !selectedChats.find(selected => selected._id === chat._id))
    ]

    const handleForwardMessage = () => {
        if (selectedChats.length > 0) {
            forwardMessage(socket, messageId, mediaId, selectedChats)
                .then(() => {
                    close()
                    closeMediaModal()
                })
                .catch((e) => {
                    console.log(e)
                })
        }
    }

    const renderSelectedChatsInfo = () => {
        if (selectedChats.length > 0) {
            const firstChat = selectedChats[0]
            const remainingChats = selectedChats.slice(1)
            const remainingCount = selectedChats.length - 1
            return (
                <div
                    className={'flex items-center gap-[30px] cursor-pointer'}
                    onClick={handleFocus}
                >
                    <img
                        alt={'chat_image'}
                        src={firstChat?.image}
                        className={`w-5 h-5 ${firstChat?.type !== 'group' && 'rounded-full'} object-cover`}
                    />
                    <div className={'flex items-center'}>
                        <span className={`text-[14px] lg:text-[16px]`}>{firstChat.name}</span>
                        <div className={'relative group'}>
                            {remainingCount > 0 && (
                                <span
                                    className={'text-[14px] lg:text-[16px] ml-2 lg:ml-[15px]'}>+{remainingCount}</span>
                            )}
                            <div
                                className={'w-[262px] absolute left-full bg-white shadow-md rounded-[3px] p-2.5 hidden group-hover:flex flex-col'}>
                                {remainingChats.map(chat => (
                                    <div key={chat._id}>{chat.name}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }

    return (
        <div
            className={`fixed z-[999999] w-screen h-screen top-0 left-0 flex justify-center ${authData.role === 'student' ? 'mt-[145px]' : 'mt-[100px]'}`}>
            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[345px] max-h-[207px] lg:max-w-[690px] lg:max-h-[400px] z-[99999999] border border-black rounded-[10px] bg-white'}>
                <div
                    className={'max-w-[345px] lg:max-w-full relative flex items-center justify-center pt-5 pb-2 lg:p-[28px]'}
                    ref={wrapperRef}>
                    <h2 className={'text-center text-[14px] lg:text-[16px]'}>Կիսվել նկարով</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-[2px] lg:p-1.5'}
                            onClick={close}>
                        <CloseIcon width={isPhone ? 13 : 20} height={isPhone ? 13 : 20}/>
                    </button>
                </div>

                <div className={'relative flex flex-col px-2.5 lg:px-[70px]'} onBlur={handleBlur} tabIndex={-1}>
                    <div className={`relative flex flex-col gap-[10px]`} ref={searchOptionsRef}>
                        <h2 className={'flex gap-[5px] text-[14px] lg:text-[16px]'}>Կիսվել <AsteriskIcon/></h2>
                        <div
                            className={`relative flex justify-between bg-white ${isPhone ? 'max-w-[325px]' : 'w-full'} border border-black border-opacity-70 ${value && 'rounded-b-[0px]'} rounded-[3px] py-[2px] px-2.5 lg:px-[30px]`}>
                            {!isInputFocused && selectedChats.length > 0 ?
                                renderSelectedChatsInfo()
                                :
                                <input
                                    type={'text'}
                                    placeholder={'որոնել'}
                                    value={value}
                                    onChange={e => setValue(e.target.value)}
                                    onFocus={handleFocus}
                                    className={`${isPhone ? 'max-w-[325px]' : 'w-full'} pr-[30px] outline-none text-[14px] lg:text-[16px]`}
                                />
                            }
                            <div className={'flex items-center gap-5'}>
                                <button>
                                    <SearchIcon width={isPhone && 17} height={isPhone && 17}/>
                                </button>
                            </div>
                        </div>
                    </div>

                    {isInputFocused && value &&
                        <div
                            className={`${isPhone ? 'w-[94.2%]' : 'w-[79.6%]'} max-h-[340px] overflow-y-auto flex flex-col ${combinedChats.length > 0 && 'gap-[15px]'} absolute bg-white top-full border border-black border-t-0 border-opacity-70 rounded-b-[3px] ${selectedChats.length > 0 ? 'py-5' : combinedChats.length > 0 ? 'lg:py-[30px]' : 'py-2'}`}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            <div className={'px-2.5 lg:px-[30px]'}>
                                {selectedChats.length > 0 &&
                                    <div className={'flex flex-wrap gap-3 border-b pb-5'}>
                                        {selectedChats.map(chat => (
                                            <div
                                                key={chat._id}
                                                onClick={() => removeSelectedChat(chat._id)}
                                                className={'flex items-center gap-3 bg-gray-100 rounded-[3px] cursor-pointer'}>
                                                <span className={'text-[14px] lg:text-[16px]'}>{chat.name}</span>
                                                <button>
                                                    <CloseIcon width={10} height={10}/>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                }
                            </div>

                            {combinedChats.length > 0 ?
                                combinedChats.map(chat => (
                                    <div
                                        key={chat._id}
                                        onClick={() => handleChatSelect(chat)}
                                        className={`flex items-center justify-between hover:bg-gray-100 transition cursor-pointer px-2.5 lg:px-[30px]`}>
                                        <div className={'flex items-center gap-5'}>
                                            <img
                                                alt={'chat_image'}
                                                src={chat?.image}
                                                className={`w-[17px] h-[17px] lg:w-[30px] lg:h-[30px] ${chat?.type !== 'group' && 'rounded-full'} object-cover`}
                                            />
                                            <p className={`${isPhone && 'text-[14px]'}`}>{chat.name}</p>
                                        </div>

                                        <div
                                            className={`w-[17px] h-[17px] lg:w-5 lg:h-5 flex items-center justify-center border border-black rounded-[3px]`}>
                                            {selectedChats.find(selected => selected._id === chat._id) &&
                                                <CheckedIcon/>}
                                        </div>
                                    </div>
                                ))
                                :
                                <div className={'flex justify-center items-center text-gray-500'}>
                                    Ոչինչ չի գտնվել
                                </div>
                            }
                        </div>
                    }
                </div>
                <div
                    className={'flex justify-end lg:justify-center gap-5 lg:gap-[30px] pt-5 pb-[50px] lg:pb-[100px] lg:pt-[50px] px-2.5 lg:px-0'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[120px] lg:w-[176px]'}
                                    isPhone={isPhone}/>
                    <ButtonFilled onClick={handleForwardMessage} text={'Կիսվել'} className={'w-[120px] lg:w-[176px]'}
                                  isPhone={isPhone}/>
                </div>
            </div>
        </div>
    )
}

export default ShareFilesModal