import React, {useEffect, useRef, useState} from 'react';
import {
    ActiveStudentsIcon,
    AllStudentsCountIcon,
    CommunityStudentsIcon,
    DroppedStudentsIcon,
    GeoIcon,
    MenuIcon, StatusIcon
} from "../../assets/icons";
import ButtonHoverText from "../shared/buttons/ButtonHoverText";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {ConfirmationModal} from "../shared";
import {useDispatch, useSelector} from "react-redux";
import {roles} from "../../common/roles";
import {changeProgramStatus, deleteProgram} from "../../store/actions/programs";
import {changeCommunityStatus, deleteCommunity} from "../../store/actions/communities";

const CommunityItem = ({
                           _id,
                           name,
                           active: initialActive,
                           activeStudentsCount,
                           allStudentsCount,
                           graduatedStudentsCount,
                           terminatedStudentsCount,
                           communityToDelete,
                           setCommunityToDelete,
                           handleDeleteCommunity
                       }) => {
    const {authData} = useSelector(state => state.auth)

    const [showMenu, setShowMenu] = useState(false)
    const [statusToChange, setStatusToChange] = useState(false)
    const [active, setActive] = useState(initialActive)

    const dispatch = useDispatch()
    const ref = useRef(null)

    useOutsideClick(ref, () => setShowMenu(false))

    const handleChangeStatus = () => {
        const newStatus = !active
        setActive(newStatus);
        dispatch(changeCommunityStatus(_id))
            .finally(() => {
                setShowMenu(false)
            })
        setStatusToChange(false)
    }

    const deleteCommunity = () => {
        setCommunityToDelete(_id)
    }

    useEffect(() => {
        document.body.style.overflow = (statusToChange || communityToDelete) ? 'hidden' : 'auto'
    }, [statusToChange, communityToDelete])

    return (
        <>
            {communityToDelete &&
                <ConfirmationModal
                    close={() => setCommunityToDelete(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ջնջել այս համայնքը։`}
                    confirm={handleDeleteCommunity}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {statusToChange &&
                <ConfirmationModal
                    close={() => setStatusToChange(false)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ${active ? 'ապաակտիվացնել' : 'ակտիվացնել'} այս համայնքը։`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div className={'relative max-w-[405px] h-[130px] mt-[30px] py-[30px] border shadow-md rounded-[5px]'}>
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px]`}></div>

                <div className={'relative flex items-center justify-center'}>
                    <p className={'flex items-center gap-2.5'}>
                        <GeoIcon color={!active && '#666666'}/>
                        <span
                            className={`${active === false ? 'text-black text-opacity-60' : 'text-[#FCC10F]'}`}>{name}</span>
                        {active === true
                            ?
                            <ButtonHoverText icon={<StatusIcon color={'#20BA07'}/>} text={'Ակտիվ'}/>
                            :
                            <ButtonHoverText icon={<StatusIcon color={'#00000099'}/>} text={'Ապաակտիվ'}/>
                        }
                    </p>
                    {(roles[authData?.role]?.some(role => role.allowChangeCommunityStatus) || roles[authData?.role]?.some(role => role.allowDeleteCommunity)) &&
                        <button
                            onClick={() => setShowMenu(!showMenu)}
                            className={'absolute right-0 mr-5'}
                        >
                            <MenuIcon/>
                        </button>
                    }
                    {showMenu && (
                        <div
                            ref={ref}
                            className="absolute z-[999] w-[199px] h-[130px] top-[30px] flex flex-col gap-4 bg-white right-5 pt-[30px] border border-black rounded-[5px]"
                        >
                            <p
                                onClick={() => setStatusToChange(true)}
                                className="px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer">
                                {active ? 'Ապաակտիվացնել' : 'Ակտիվացնել'}
                            </p>
                            <p
                                onClick={deleteCommunity}
                                className="px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer">
                                Ջնջել
                            </p>
                        </div>
                    )}
                </div>
                <div className={'flex items-center justify-between px-[30px] pt-[30px]'}>
                    <p className={'relative group flex items-center gap-2.5'}>
                        <CommunityStudentsIcon/>
                        <span>{allStudentsCount}</span>
                        <span
                            className={`absolute z-[9999] w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Համայնքից ընդհանուր ուսանողների թիվ</span>
                    </p>
                    <p className={'relative group flex items-center gap-2.5'}>
                        <ActiveStudentsIcon color={'#20BA07'}/>
                        <span>{activeStudentsCount}</span>
                        <span
                            className={`absolute z-[9999] w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ակտիվ ուսանողներ</span>
                    </p>
                    <p className={'relative group flex items-center gap-2.5'}>
                        <AllStudentsCountIcon color={'#FCC10F'}/>
                        <span>{graduatedStudentsCount}</span>
                        <span
                            className={`absolute z-[9999] w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Շրջանավարտներ</span>
                    </p>
                    <p className={'relative group flex items-center gap-2.5'}>
                        <DroppedStudentsIcon/>
                        <span>{terminatedStudentsCount}</span>
                        <span
                            className={`absolute z-[9999] w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Կրթակարգից դուրս մնացած մասնակիցներ</span>
                    </p>
                </div>
            </div>
        </>
    );
};

export default CommunityItem;
