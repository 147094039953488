import {useEffect, useState} from 'react';
import {decryptMessage, encryptMessage} from "../../socket/encryption";
import {useDispatch} from "react-redux";
import {openChat, setChatRoom, setChats} from "../../store/actions/chats";

export const useGetChats = (socket) => {
    const dispatch = useDispatch();

    const cb = async (response) => {
        response = await decryptMessage(response)

        dispatch(setChats(response.chats))
    }

    useEffect(() => {
        (async () => {
            if (socket) {
                const obj = {
                    type: 'get_chats',
                }
                const encryptedMessage = await encryptMessage(obj)
                socket.emit('msg', encryptedMessage, cb)
            }
        })()
    }, [socket])
}

export const usePinUnpinChat = (socket) => {
    const dispatch = useDispatch();

    const pinUnpinChat = async (chatId) => {
        const obj = {
            type: 'pin_unpin_chat',
            data: {
                chatId,
            }
        }

        const encryptedMessage = await encryptMessage(obj)

        socket.emit('msg', encryptedMessage, async (response) => {
            response = await decryptMessage(response)

            dispatch(setChats(response.chats))
        })
    }

    return {pinUnpinChat}
}

export const useGetChat = (socket) => {
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const cb = async (response) => {
        response = await decryptMessage(response)

        const {chats, chat} = response.data

        dispatch(openChat(chats, chat))
    }

    const getChat = async (chatId) => {
        setLoading(true)
        if (socket) {
            const obj = {
                type: 'get_chat',
                data: {chatId}
            }
            const encryptedMessage = await encryptMessage(obj)
            socket.emit('msg', encryptedMessage, cb)
            setLoading(false)
        }
    }

    return {getChat}
}

export const useConnectToChat = (socket) => {
    const dispatch = useDispatch()

    const cb = async (response) => {
        response = await decryptMessage(response)
    }

    const connectChatRoom = async (newChatRoom, prevChatRoom) => {
        if (socket) {
            const obj = {
                type: 'change_room',
                data: {
                    newRoom: `chat_${newChatRoom}`,
                    prevRoom: prevChatRoom && `chat_${prevChatRoom}`
                }
            }

            if(newChatRoom !== prevChatRoom) {
                const encryptedMessage = await encryptMessage(obj)
                socket.emit('msg', encryptedMessage, cb)

                dispatch(setChatRoom(newChatRoom))
            }
        }
    }
    return {connectChatRoom}
}