import React, {useEffect, useState} from 'react';
import {Container} from "../../../components/shared";
import {useDispatch, useSelector} from "react-redux";
import {deleteProgram, getPrograms} from "../../../store/actions/programs";
import ProgramCard from "../../../components/programs/ProgramCard";
import CreateProgramModal from "../../../components/programs/CreateProgramModal";
import AddButton from "../../../components/shared/buttons/AddButton";
import {roles} from "../../../common/roles";

const Programs = () => {
    const {programs, loading} = useSelector((state) => state.programs)
    const {authData} = useSelector(state => state.auth)
    const [openCreateProgramModal, setOpenCreateProgramModal] = useState(false)
    const [programToDelete, setProgramToDelete] = useState(null)

    const dispatch = useDispatch()

    const handleDeleteProgram = () => {
        dispatch(deleteProgram(programToDelete))
        setProgramToDelete(null)
    }

    useEffect(() => {
        document.body.style.overflow = (programToDelete) ? 'hidden' : 'auto'
    }, [programToDelete])

    useEffect(() => {
        dispatch(getPrograms())
    }, [dispatch])

    return (
        <div>
            {openCreateProgramModal &&
                <CreateProgramModal
                    close={() => setOpenCreateProgramModal(false)}
                />}
            <Container>
                <h1 className={'text-[50px] text-[#FCC10F] text-center font-bold'}>Ծրագրեր</h1>
                {roles[authData?.role]?.some(role => role.allowCreateNewProgram)
                    &&
                    <AddButton onClick={() => setOpenCreateProgramModal(!openCreateProgramModal)} text={'Նոր ծրագիր'}/>
                }

                <div className={'grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 place-items-stretch gap-[30px] mt-[30px]'}>
                    {programs?.map((program, index) => {
                        return (
                            <ProgramCard
                                key={index}
                                {...program}
                                programToDelete={programToDelete}
                                setProgramToDelete={setProgramToDelete}
                                handleDeleteProgram={handleDeleteProgram}
                            />
                        )
                    })}
                </div>
            </Container>
        </div>
    );
};

export default Programs;