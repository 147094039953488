import {
    CHANGE_DIVISION_STATUS, CHANGE_MEMBER_STATUS,
    CREATE_DIVISION, CREATE_MEMBER, DELETE_MEMBER,
    EDIT_DIVISION, EDIT_PROFILE,
    GET_DIVISIONS, GET_MEMBERS,
    SET_LOADING
} from "../constants/actionTypes";
import * as profileApi from "../../api/profile";

export const createDivision = (payload) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await profileApi.createDivision(payload)

        if (data.success) {
            dispatch({type: CREATE_DIVISION, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const createMember = (payload) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await profileApi.createMember(payload)

        if (data.success) {
            dispatch({type: CREATE_MEMBER, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        dispatch({type: SET_LOADING})
        alert(error.response?.data.message)
    }
}

export const getDivisions = () => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await profileApi.getDivisions()

        if (data.success) {
            dispatch({type: GET_DIVISIONS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const getMembers = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await profileApi.getMembers(queryParams)

        if (data.success) {
            dispatch({type: GET_MEMBERS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const changeDivisionStatus = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await profileApi.changeDivisionStatus(id)

        if (data.success) {
            dispatch({type: CHANGE_DIVISION_STATUS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const changeMemberStatus = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await profileApi.changeMemberStatus(id)

        if (data.success) {
            dispatch({type: CHANGE_MEMBER_STATUS, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const editDivision = (id, formData) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await profileApi.editDivision(id, formData)

        if (data.success) {
            dispatch({type: EDIT_DIVISION, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const editProfile = (id, formData) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await profileApi.editProfile(id, formData)

        if (data.success) {
            dispatch({type: EDIT_PROFILE, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

export const deleteMember = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await profileApi.deleteMember(id)

        if (data.success) {
            dispatch({type: DELETE_MEMBER, payload: data})
        }

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error);
        alert(error.response?.data.message)
    }
}

