import API from "./index";

export const getStudents = (filters) => API.get(`/students${filters}`)
export const changeStudentStatus = (id, status) => API.patch(`/students/${id}/change-status?status=${status}`)
export const getStudent = (id) => API.get(`/students/${id}`)
export const changeStudentProfileImage = (id, imageData) => API.patch(`students/${id}/image`, imageData)
export const editStudent = (id, data) => API.patch(`students/${id}`, data)

export const getStudentsNameSuggestions = (searchPattern) => API.get(`/students/search?p=${searchPattern}`)

export const getNotes = (studentId, page) => API.get(`/notes/${studentId}?page=${page}`)
export const createNote = (studentId, data) => API.post(`/notes/${studentId}`, data)
export const editNote = (noteId, data) => API.patch(`/notes/${noteId}`, data)
export const deleteNote = (noteId) => API.delete(`/notes/${noteId}`)

export const getStudentFiles = (id) => API.get(`/students/${id}/files`)
export const createStudentFile = (id, data) => API.post(`/students/${id}/files`, data)
export const deleteStudentFile = (id) => API.delete(`/students/files/${id}`)

export const getStudentsFilters = () => API.get('/students/filters')

export const resetPassword = (id) => API.patch(`/students/${id}/reset-password`)

export const downloadQrCodes = (students) => API.get(`/students/qr?students=${students}`)