import React from 'react';
import routeHistoryImage from '../../../../../../assets/images/routeHistoryImage.png'
import Calendar from "../../../../../shared/calendar/Calendar";
import {AlarmIcon, CalendarIcon} from "../../../../../../assets/icons";

const RouteHistoryCard = () => {
    return (
        <div className={'w-[405px] h-[220px] custom-shadow rounded-[5px]'}>
            <div className={'text-center py-5'}>Մարգահովիտ- Վանաձոր- SMART</div>
            <div className={'w-full max-h-[100px] flex justify-center'}>
                <img alt={'image'} src={routeHistoryImage} className={'max-w-[100px] h-full'}/>
            </div>
            <div className={'flex justify-between px-[30px] py-5'}>
                <div className={'flex items-center gap-5'}>
                    <CalendarIcon />
                    <span>02.06.2024</span>
                </div>
                <div className={'flex items-center gap-5'}>
                    <AlarmIcon color={'#000'}/>
                    <span>16:00</span>
                </div>
            </div>
        </div>
    );
};

export default RouteHistoryCard;