import React from 'react';
import StatementsFilter from "../../../components/statements/StatementsFilter";
import {useFilters} from "../../../hooks/useFilters";
import AddButton from "../../../components/shared/buttons/AddButton";
import {useNavigate} from "react-router-dom";
import {Container} from "../../../components/shared";
import StatementCard from "../../../components/statements/StatementCard";
import Pagination from "../../../components/shared/Pagination";
import {useDispatch, useSelector} from "react-redux";
import {getStatements} from "../../../store/actions/statements";
import {roles} from "../../../common/roles";
import {useMediaQuery} from "react-responsive";
import StatementsMobileFilter from "../../../components/statements/StatementsMobileFilter";

const initialFilters = {
    page: 1,
    size: 9
}

const Statements = () => {
    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        changePage,
        submitFilters,
        setFilters
    } = useFilters(initialFilters, getStatements, 'statements')

    const {statements, loading} = useSelector(state => state.statements)
    const {authData} = useSelector(state => state.auth)

    const isPhone = useMediaQuery({maxWidth: 640})

    const navigate = useNavigate()

    const content = (
        <div>
            {roles[authData?.role]?.some(role => role.allowCreateNewStatement) &&
                <AddButton
                    text={'Նոր'}
                    onClick={() => navigate('/statements/create')}
                    className={'hover:bg-white transition border border-[#FCC10F] hover:text-[#FCC10F]'}
                />
            }
            {loading
                ?
                <p className={'text-center'}>Բեռնվում է․․․</p>
                :
                statements?.data?.length > 0
                    ?
                    <>
                        <div className={'grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-5 lg:gap-8 lg:mt-[30px] px-[15px] lg:px-0'}>
                            {statements?.data?.map((statement, index) => (
                                <div key={statement._id}>
                                    <StatementCard
                                        index={index}
                                        {...statement}
                                    />
                                </div>
                            ))}
                        </div>
                        <Pagination count={statements.pagesCount} page={filters?.page || 1} onChange={changePage}/>
                    </>
                    :
                    statements?.data?.length === 0 &&
                    <p className={'w-full flex items-center justify-center'}>Հայտարարություններ չկան</p>
            }
        </div>
    )

    return (
        <div>
            <h2 className={'text-center text-[#FCC10F] text-[20px] lg:text-[50px] font-bold py-5 lg:mt-[30px] lg:mb-[25px]'}>Հայտարարություններ</h2>

            {isPhone
                ?
                <StatementsMobileFilter
                    filters={filters}
                    setFilters={setFilters}
                    discardFilters={discardFilters}
                    submitFilters={submitFilters}
                    handleChange={handleChange}
                    changeSelectedFilters={changeSelectedFilters}
                />
                :
                <StatementsFilter
                    filters={filters}
                    setFilters={setFilters}
                    discardFilters={discardFilters}
                    submitFilters={submitFilters}
                    handleChange={handleChange}
                    changeSelectedFilters={changeSelectedFilters}
                />
            }

            {isPhone
                ?
                content
                :
                <Container>
                    {content}
                </Container>
            }
        </div>
    );
};

export default Statements;
