import React from 'react';
import {
    AlarmIcon,
    CalendarIcon,
    GeoIcon,
    GroupIcon,
    InstructorIcon,
    PeopleIcon,
    RecoverIcon
} from "../../../assets/icons";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import {daysOfWeek} from "../../../common/dropdownOptions";

const EventInfo = ({program, workshopGuest, studentsCount, scheduleCodes, schedule, startDate, endDate, type, place, date, activeStudentsCount}) => {
    const modifiedStartDate = moment(startDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
    const modifiedEndDate = moment(endDate, 'YYYY-MM-DD').format('DD.MM.YYYY')
    const modifiedDate = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')

    const modifiedSchedule = scheduleCodes?.map((scheduleCode) => {
        const [dayIndex, time] = scheduleCode.split('_');
        const weekday = daysOfWeek[dayIndex];
        return {weekday, time};
    })

    return (
        <div className="flex justify-between items-center mt-[30px] pb-3 px-1">
            <div className="flex gap-[10px]">
                <GroupIcon/>
                <Link to={`/programs/${program?._id}/groups`} className="hover:text-[#FCC10F] transition">
                    {program?.name}
                </Link>
            </div>

            {type === 'workshop' &&
                <div className="flex items-center gap-[10px]">
                    <InstructorIcon/>
                    <span>{workshopGuest}</span>
                </div>
            }

            {type === 'trip' &&
                <div className="flex items-center gap-[10px]">
                    <GeoIcon/>
                    <span>{place}</span>
                </div>
            }

            <p className={'relative group flex items-center gap-[10px] select-none'}>
                <PeopleIcon color={'#FCC10F'}/>
                <span>{activeStudentsCount || (activeStudentsCount === 0 && studentsCount)}</span>
                <span
                    className={`absolute w-[220px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ակտիվ ուսանողների քանակ</span>
            </p>

            <div className="flex items-center gap-[10px]">
                <AlarmIcon/>
                {type === 'workshop' &&
                    <div className="flex items-start flex-col gap-[30px]">
                        {modifiedSchedule?.length > 2 ?
                            <div className="relative group">
                                <span className="cursor-pointer">Գրաֆիկ</span>
                                <div
                                    className="w-[250px] absolute z-10 hidden group-hover:grid grid-cols-3 gap-y-2.5 bg-white border shadow-md rounded-[3px] p-2 left-0 mt-2">
                                    {modifiedSchedule?.map((item, index) => (
                                        <p key={index}
                                           className="text-[12px]">{`${item.weekday}. ${item.time.split('-')[0]}`}</p>
                                    ))}
                                </div>
                            </div>
                            :
                            <div className={'flex'}>
                                {modifiedSchedule?.map((item, index) => {
                                    const isLast = index === modifiedSchedule.length - 1
                                    const isFirst = index === 0

                                    return <div key={index}
                                                className={`${!isLast && 'h-5 border-r border-r-[#FCC10F]'} ${isFirst ? 'pr-5' : 'px-5 '} w-fit pr-5`}>{`${item.weekday}. ${item.time}`}</div>
                                })}
                            </div>
                        }
                    </div>
                }
                {(type === 'connect' || type === 'trip') &&
                    <div>
                        {schedule?.map(scheduleTimes =>
                            <p>{scheduleTimes.startTime} - {scheduleTimes.endTime}</p>
                        )}
                    </div>
                }
            </div>

            <div className="flex items-center gap-2.5">
                <RecoverIcon/>
                {type === 'workshop' ? <span>{modifiedStartDate} - {modifiedEndDate}</span> :
                    <span>{modifiedDate}</span>}
            </div>
        </div>
    );
};

export default EventInfo;