import React from 'react';
import {InfoIcon} from "../../assets/icons";

const HoverInfo = ({info}) => {
    return (
        <div className={`relative ml-5 group`}>
            <InfoIcon/>
            <div className={'absolute md:left-full md:right-0 right-full w-[80vw] max-w-[450px] opacity-0 group-hover:opacity-100 pointer-events-none bottom-full px-[10px] rounded-[3px] bg-white shadow-md text-[12px] transition'}>
                <span>
                    {info}
                </span>
            </div>
        </div>
    );
};

export default HoverInfo;