import {
    ADD_ROUTE_TO_TRIP, ADD_VEHICLE_TO_TRIP,
    CHANGE_DRIVER_STATUS,
    CHANGE_ROUTE_STATUS,
    CHANGE_TRANSPORT_STOP_STATUS, CHANGE_TRIP_STATUS,
    CHANGE_VEHICLE_STATUS,
    CREATE_DRIVER,
    CREATE_DRIVER_FILE,
    CREATE_ROUTE,
    CREATE_TRANSPORT_STOP,
    CREATE_VEHICLE,
    DELETE_DRIVER_FILE,
    DELETE_ROUTE,
    DELETE_TRANSPORT_STOP,
    DELETE_VEHICLE, DIVIDE_PASSENGERS,
    EDIT_DRIVER,
    EDIT_ROUTE,
    EDIT_TRANSPORT_STOP, EDIT_TRIP,
    GET_DRIVER,
    GET_DRIVER_FILES,
    GET_DRIVERS,
    GET_ROUTES,
    GET_TRANSPORT_STOPS, GET_TRANSPORT_TRIP, GET_TRIP_WEEKDAY, GET_TRIP_WEEKS, GET_TRIPS_AT_TIME,
    GET_VEHICLES, MERGE_TRIPS, REMOVE_TRIP_ROUTE, REMOVE_TRIP_VEHICLE,
    SELECT_ROUTE, SELECT_STUDENTS,
    SELECT_TRANSPORT_STOP, SELECT_TRIPS,
    SET_LOADING
} from "../constants/actionTypes";
import * as transportApi from "../../api/transport";

export const createTransportStop = (payload, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.createTransportStop(payload)

        navigate(`/transport/stops?page=1&size=9&`)

        dispatch({type: CREATE_TRANSPORT_STOP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const createRoute = (payload, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.createRoute(payload)

        navigate(`/transport/routes?page=1&size=9&`)

        dispatch({type: CREATE_ROUTE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const createDriver = (payload) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.createDriver(payload)

        dispatch({type: CREATE_DRIVER, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const createVehicle = (payload, navigate) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.createVehicle(payload)

        navigate(`/vehicles?page=1&size=9&`)

        dispatch({type: CREATE_VEHICLE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const createDriverFile = (id, payload) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.createDriverFile(id, payload)

        dispatch({type: CREATE_DRIVER_FILE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getTransportStops = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getTransportStops(queryParams)

        dispatch({type: GET_TRANSPORT_STOPS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getRoutes = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getRoutes(queryParams)

        dispatch({type: GET_ROUTES, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getDrivers = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getDrivers(queryParams)

        dispatch({type: GET_DRIVERS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getDriver = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getDriver(id)

        dispatch({type: GET_DRIVER, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getVehicles = (queryParams) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getVehicles(queryParams)

        dispatch({type: GET_VEHICLES, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getDriverFiles = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getDriverFiles(id)

        dispatch({type: GET_DRIVER_FILES, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getTripWeeks = () => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getTripWeeks()

        dispatch({type: GET_TRIP_WEEKS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getTripWeekday = (id, weekday) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getTripWeekday(id, weekday)

        dispatch({type: GET_TRIP_WEEKDAY, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const getTripsAtTime = (id, weekday, type, time) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getTripsAtTime(id, weekday, type, time)

        dispatch({type: GET_TRIPS_AT_TIME, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const checkRoutesMatch = (queryParams, setAddRoute, setError) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.checkRouteMatch(queryParams)

        setAddRoute(true)

        dispatch({type: GET_TRANSPORT_TRIP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        setError(error.message)
        throw error
    }
}

export const getTrip = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.getTrip(id)

        dispatch({type: GET_TRANSPORT_TRIP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const addRouteToTrip = (routeId, tripId, setError) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.addRouteToTrip(tripId, routeId)

        dispatch({type: ADD_ROUTE_TO_TRIP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        setError(error.message)
        throw error
    }
}

export const addVehicleToTrip = (vehicleId, tripId, setError) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.addVehicleToTrip(tripId, vehicleId)

        dispatch({type: ADD_VEHICLE_TO_TRIP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        setError(error.message)
        throw error
    }
}

export const mergeTrips = (queryParams, setError) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.mergeTrips(queryParams)

        dispatch({type: MERGE_TRIPS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        setError(error.message)
        throw error
    }
}

export const editTransportStop = (id, formData) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.editTransportStop(id, formData)

        dispatch({type: EDIT_TRANSPORT_STOP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const editRoute = (id, formData) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.editRoute(id, formData)

        dispatch({type: EDIT_ROUTE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const editDriver = (id, formData) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.editDriver(id, formData)

        dispatch({type: EDIT_DRIVER, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const editTrip = (id, formData) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.editTrip(id, formData)

        dispatch({type: EDIT_TRIP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const dividePassengers = (id, queryParams, setError) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.dividePassengers(id, queryParams)

        dispatch({type: DIVIDE_PASSENGERS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message)
        setError(error.message)
        throw error
    }
}

export const selectTransportStop = (transportStop) => async (dispatch) => {
    try {
        dispatch({type: SELECT_TRANSPORT_STOP, payload: {transportStop}})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const selectRoute = (route) => async (dispatch) => {
    try {
        dispatch({type: SELECT_ROUTE, payload: {route}})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const selectTrips = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        dispatch({type: SELECT_TRIPS, payload: {id}})
        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error)
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeTransportStopStatus = (id, status) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.changeTransportStopStatus(id, status)

        dispatch({type: CHANGE_TRANSPORT_STOP_STATUS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeRouteStatus = (id, status) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.changeRouteStatus(id, status)

        dispatch({type: CHANGE_ROUTE_STATUS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeDriverStatus = (id, status) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.changeDriverStatus(id, status)

        dispatch({type: CHANGE_DRIVER_STATUS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeVehicleStatus = (id, status) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.changeVehicleStatus(id, status)

        dispatch({type: CHANGE_VEHICLE_STATUS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const changeTripStatus = (id, status) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.changeTripStatus(id, status)

        dispatch({type: CHANGE_TRIP_STATUS, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const deleteTransportStop = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.deleteTransportStop(id)

        dispatch({type: DELETE_TRANSPORT_STOP, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const deleteRoute = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.deleteRoute(id)

        dispatch({type: DELETE_ROUTE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const deleteVehicle = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.deleteVehicle(id)

        dispatch({type: DELETE_VEHICLE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const deleteDriverFile = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.deleteDriverFile(id)

        dispatch({type: DELETE_DRIVER_FILE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const removeTripRoute = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.removeTripRoute(id)

        dispatch({type: REMOVE_TRIP_ROUTE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}

export const removeTripVehicle = (id) => async (dispatch) => {
    try {
        dispatch({type: SET_LOADING})

        const {data} = await transportApi.removeTripVehicle(id)

        dispatch({type: REMOVE_TRIP_VEHICLE, payload: data})

        dispatch({type: SET_LOADING})
    } catch (error) {
        console.log(error.message);
        error.response.data.message && alert(error.response.data.message)
    }
}


