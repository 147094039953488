import React from 'react'
import login_bg from '../assets/images/login/login-bg.png'
import { Container } from "../components/shared"
import LoginForm from "../components/login/LoginForm.jsx"

const Login = () => {
    return (
        <div>
            <Container className={'lg:pt-[70px] lg:pb-[86px]'}>
                <div className={'grid grid-cols-1 lg:grid-cols-2'}>
                    <h2 className={'text-[20px] bold text-center block lg:hidden'}>
                        Մուտք
                    </h2>
                    <div className={'flex justify-center'}>
                        <img
                            src={login_bg}
                            alt={''}
                            className={'w-[200px] h-[181px] mt-5 mb-[30px] object-cover lg:w-auto lg:h-[594px] lg:m-0'}
                        />
                    </div>

                    <LoginForm/>
                </div>
            </Container>
        </div>
    );
};

export default Login;
