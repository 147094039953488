import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Container} from "../../../shared";
import DropdownInputCheckbox from "../../../shared/dropdowns/DropdownInputCheckbox";
import SelectedFilters from "../../../shared/SelectedFilters";
import {ButtonFilled, ButtonOutlined} from "../../../shared/buttons";
import {stopsCount} from "../../../../common/dropdownOptions";
import {getRegions, getTransportRouteFilters, getTransportStopsNames} from "../../../../store/actions/common";

const RoutesFilter = ({handleChange, changeSelectedFilters, filters, discardFilters, submitFilters}) => {
    const {stopNames, regions, routeFilters} = useSelector(state => state.common)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getTransportStopsNames())
        dispatch(getRegions())
        dispatch(getTransportRouteFilters())
    }, [])

    return (
        <div className={'custom-shadow mt-5'}>
            <Container>
                <div className={'flex flex-wrap gap-[7px] mt-[17px]'}>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={regions} name={'region'}
                                               placeholder={'Տարածաշրջան'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px] px-[15px]'}
                                               optionClassName={'px-[15px]'}
                                               selected={filters?.region}
                        />
                    </div>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={stopsCount} name={'stopsCount'} placeholder={'Կանգառների քանակ '}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px] px-[15px]'}
                                               optionClassName={'px-[15px]'}
                                               selected={filters?.stopsCount}/>
                    </div>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={stopNames} name={'routeStart'} placeholder={'Երթուղու սկիզբ '}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px] px-[15px]'}
                                               optionClassName={'px-[15px]'}
                                               selected={filters?.routeStart}/>
                    </div>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={routeFilters?.routeDurations} name={'routeDuration'} placeholder={'Երթուղու տևողություն'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px] px-[15px]'}
                                               optionClassName={'px-[15px]'}
                                               selected={filters?.routeDuration}/>
                    </div>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={routeFilters?.routeDistances} name={'distance'} placeholder={'Երթուղու երկարություն'}
                                               onChange={changeSelectedFilters}
                                               previewClassName={'rounded-[5px] py-[7px] px-[15px]'}
                                               optionClassName={'px-[15px]'}
                                               selected={filters?.distance}/>
                    </div>
                </div>

                <div className={'flex justify-between gap-3 pt-[30px] pb-[17px] pr-1'}>
                    <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters}/>

                    <div className={'flex gap-3 items-center'}>
                        <ButtonOutlined
                            onClick={discardFilters}
                            text={'Չեղարկել'}
                        />
                        <ButtonFilled
                            onClick={() => submitFilters()}
                            text={'Կիրառել'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default RoutesFilter;