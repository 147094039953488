import React, {useEffect, useState} from 'react';
import {PlusButton} from '../../../../components/shared/buttons';
import CreateProjectModal from '../../../../components/programs/program/projects/modals/CreateProjectModal';
import {useDispatch, useSelector} from 'react-redux';
import {getProgramProjects} from '../../../../store/actions/programs';
import {useParams} from 'react-router-dom';
import ProjectItem from '../../../../components/programs/program/projects/ProjectItem';
import Pagination from "../../../../components/shared/Pagination";
import {roles} from "../../../../common/roles";
import {DropdownInputCheckbox} from "../../../../components/shared/dropdowns";

const Projects = () => {
    const {authData} = useSelector(state => state.auth)
    const {projects, loading} = useSelector(state => state.programs)
    const [openCreateProjectModal, setOpenCreateProjectModal] = useState(false)
    const [editProjectModal, setEditProjectModal] = useState(null)
    const [page, setPage] = useState(1)

    const {id} = useParams();
    const dispatch = useDispatch();

    const handleChangePage = (_, p) => {
        window.scrollTo({top: 0})
        setPage(p)
    }

    const handleEditProject = (project) => {
        setEditProjectModal(project)
    }

    useEffect(() => {
        dispatch(getProgramProjects(id, page))
    }, [page])

    useEffect(() => {
        openCreateProjectModal || editProjectModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [openCreateProjectModal, editProjectModal])

    return (
        <>
            {editProjectModal &&
                <CreateProjectModal close={() => setEditProjectModal(null)} projectData={editProjectModal} page={page} programId={id} pageType={'programProjects'}/>
            }

            {openCreateProjectModal && (
                <CreateProjectModal close={() => setOpenCreateProjectModal(false)} page={page}/>
            )}

            <div className={'flex justify-between items-center mt-4'}>
                {roles[authData?.role]?.some(role => role.allowCreateNewProject) &&
                    <div className={'mt-[30px]'}>
                        <PlusButton
                            onClick={() => setOpenCreateProjectModal(!openCreateProjectModal)}
                            className={`p-2.5`}
                            width={10}
                            height={10}
                            styleClassName={`hover:bg-white transition border border-[#FCC10F]`}
                            hoverPlusColor={'#FCC10F'}
                        />
                    </div>
                }

                <div className={'flex items-center gap-5'}>
                    <span className={'text-[12px]'}>Դասակարգել</span>
                    <div className={'w-[288px]'}>
                        <DropdownInputCheckbox
                            data={''}
                            name={'project'}
                            onChange={() => {
                            }}
                            selected={''}
                            previewClassName={'py-2.5 rounded-[5px] px-[30px] text-[12px]'}
                            optionClassName={'px-[30px]'}
                            textSize={'text-[12px]'}
                            hideCheckbox
                            disableInput
                        />
                    </div>
                </div>
            </div>

            <div>
                {loading ?
                    <p className={'text-center'}>Բեռնվում է․․․</p>
                    : projects?.data?.length > 0 ?
                        <>
                            {projects?.data?.map(project => (
                                <ProjectItem
                                    key={project._id}
                                    projectId={id}
                                    {...project}
                                    page={page}
                                    handleEditProject={() => handleEditProject(project)}
                                    programId={id}
                                    type={'program'}
                                    pageType={'programProjects'}
                                />
                            ))}
                            <Pagination count={projects?.pagesCount} page={page} onChange={handleChangePage}/>
                        </>
                        :
                        <p className={'text-center mt-5'}>Նախագծեր չկան</p>
                }
            </div>
        </>
    );
};

export default Projects;
