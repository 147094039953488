import React, {useEffect, useRef, useState} from 'react';
import {CloseIcon, DropdownArrow} from '../../../assets/icons';
import {gsap} from 'gsap'

const FormStatusDropdown = ({data, name, selectedKey, value, onChange, placeholder, onTouch, isCategory}) => {
    const [open, setOpen] = useState(false);
    const [connectedValue, setConnectedValue] = useState(value)
    const optionsRef = useRef(null);
    const selectedRef = useRef(null);

    const handleClearValue = () => {
        setConnectedValue('Կապել')
    }
    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (
                !optionsRef.current.contains(e.target) &&
                !selectedRef.current.contains(e.target)
            ) {
                setOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    if (!data || !onChange) {
        throw new Error('Missing props in DropdownInputCheckbox component (data, value, onChange)');
    }

    const handleSelect = (newValue) => {
        onChange({target: {value: newValue, name}});
        setOpen(false);
    };

    useEffect(() => {
        const optionsElement = optionsRef.current;

        if (open) {
            gsap.fromTo(
                optionsElement,
                {opacity: 0, height: 0, display: 'block'},
                {opacity: 1, height: 'auto', duration: 0.3}
            )
        } else {
            gsap.to(optionsElement, {opacity: 0, height: 0, duration: 0.2, display: 'none'})
        }
    }, [open]);

    useEffect(() => {
        setConnectedValue(value)
    }, [value]);

    return (
        <div
            className={`relative h-full w-[270px] bg-white transition select-none rounded-[5px]`}>
            <div
                onClick={() => setOpen(!open)}
                ref={selectedRef}
                className={`h-full flex justify-between items-center gap-2 px-7 py-0.5 cursor-pointer border border-1 border-black border-opacity-30 rounded-[5px] ${open && 'rounded-b-none'}`}
            >
                <p className={'line-clamp-1 truncate'}>{connectedValue}</p>
                {connectedValue !== 'Կապել' && isCategory
                    ?
                    <button onClick={handleClearValue}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                    :
                    <button className={`transition ${open && 'rotate-180'}`}>
                        <DropdownArrow/>
                    </button>
                }

            </div>
            <div
                ref={optionsRef}
                className="absolute top-full z-50 w-full max-h-[171px] overflow-auto bg-white border border-black border-opacity-30 border-t-transparent rounded-b-[5px] shadow-md"
                style={{display: 'none', opacity: 0, height: 0}}
            >
                {data.map((option, i) => (
                    <div
                        key={option.id || i}
                        onClick={() => handleSelect(option.value)}
                        className={`px-7 py-2 cursor-pointer hover:bg-gray-100 ${
                            option.name === value && 'bg-gray-200'
                        }`}
                    >
                        {option.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FormStatusDropdown;
