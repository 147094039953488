import React, { useRef, useState, useEffect } from 'react';
import { MenuIcon, TrashIcon, PinnedIcon, ReturnLeftIcon } from "../../../assets/icons";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { ConfirmationModal } from "../../shared";
import { usePinUnpinChat } from "../../../hooks/socket/useChats";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import defaultGroupImage from '../../../assets/images/group-chat.png';
import ReactDOM from 'react-dom';

const Tooltip = ({ text, targetRef, show }) => {
    const tooltipRef = useRef(null);

    useEffect(() => {
        if (tooltipRef.current && targetRef.current) {
            const targetRect = targetRef.current.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();

            const top = targetRect.bottom + window.scrollY;
            const left = targetRect.left + window.scrollX;

            tooltipRef.current.style.top = `${top}px`;
            tooltipRef.current.style.left = `${left}px`;
        }
    }, [targetRef, show]);

    return show ? ReactDOM.createPortal(
        <div ref={tooltipRef} className="absolute text-[12px] border rounded-[3px] p-2 bg-white custom-shadow">
            {text}
        </div>,
        document.body
    ) : null;
};

const ChatHeader = ({
                        _id,
                        image,
                        name,
                        pinned,
                        type,
                        setOpenChatMediaViewer,
                        isMessagePinned,
                        pinnedMessages,
                        setShowPinnedMessages,
                        openChatMediaViewer
                    }) => {
    const { socket } = useSelector(state => state.socket)
    const {authData} = useSelector((state) => state.auth)
    const { messages } = useSelector(state => state.chats)

    const [showMenu, setShowMenu] = useState(null);
    const [chatToDelete, setChatToDelete] = useState(null);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const { id } = useParams();
    const { pinUnpinChat } = usePinUnpinChat(socket);
    const ref = useRef(null);
    const iconRef = useRef(null);

    const isPhone = useMediaQuery({ maxWidth: 640 });

    useOutsideClick(ref, () => setShowMenu(null));

    const handlePinUnpinClick = () => {
        pinUnpinChat(id);
        setShowMenu(null);
    };

    return (
        <div className="flex flex-col">
            <div className={`relative ${authData.role === 'student' ? 'min-h-[88px]' : isPhone && 'min-h-[120px]'} lg:h-[95px] flex justify-between items-center px-5 lg:px-[30px] ${isPhone && ''}`}>
                {chatToDelete &&
                    <ConfirmationModal
                        close={() => setChatToDelete(null)}
                        warningText="Վստա՞հ եք, որ ցանկանում եք ջնջել այս նամակագրությունը։"
                        icon={<TrashIcon width={50} height={50} />}
                        confirm={() => {}}
                        confirmText="Այո"
                        cancelText="Ոչ"
                    />
                }

                <div className="flex justify-between w-full">
                    {isPhone &&
                        <div className="flex justify-between w-full" onClick={() => setOpenChatMediaViewer(true)}>
                            <div className="flex gap-5">
                                <Link to="/chats">
                                    <ReturnLeftIcon width={17} height={15}/>
                                </Link>
                                <div className={`w-[30px] h-[30px] lg:w-10 lg:h-10 overflow-hidden ${type === 'dm' && 'rounded-full'}`}>
                                    <img
                                        src={image || defaultGroupImage}
                                        alt={'image'}
                                        className={`w-full h-full ${type === 'group' ? 'object-contain' : 'object-cover'}`}
                                    />
                                </div>
                            </div>
                            <h1 className="max-w-[250px] text-[14px] lg:text-[16px]">{name}</h1>
                            <div></div>
                        </div>
                    }

                    {type === 'group' && !isPhone
                        ?
                        <>
                            <div className="w-full flex items-center justify-between">
                                <div className="w-[30px] h-[30px] lg:w-10 lg:h-10 overflow-hidden">
                                    <img
                                        src={image || defaultGroupImage}
                                        alt={'image'}
                                        className={`w-full h-full object-contain`}
                                    />
                                </div>
                                <h1 onClick={() => setOpenChatMediaViewer(true)}
                                    className={`text-[14px] lg:text-[16px] cursor-pointer ${openChatMediaViewer ? 'pointer-events-none' : 'pointer-events-auto'}`}>{name}</h1>
                                <button
                                    onClick={() => setShowMenu(!showMenu)}
                                    className={`cursor-pointer ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                                >
                                    <MenuIcon/>
                                </button>
                            </div>
                            <div className={`absolute ${isPhone && 'right-8'} bottom-3 ${type === 'group' && 'right-0 mr-[30px]'}`}>
                                {pinnedMessages?.length > 0 &&
                                    <div className="relative group flex items-center gap-[10px] select-none mt-3">
                                        <div
                                            onMouseEnter={() => setIsTooltipVisible(true)}
                                            onMouseLeave={() => setIsTooltipVisible(false)}
                                            onClick={() => setShowPinnedMessages(true)}
                                            ref={iconRef}
                                            className="relative z-50 fill-icon cursor-pointer"
                                        >
                                            <PinnedIcon />
                                            <Tooltip text="Ցուցադրել ամրագրված նամակները" targetRef={iconRef} show={isTooltipVisible} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </>
                        :
                        !isPhone &&
                        <div
                            onClick={() => setOpenChatMediaViewer(true)}
                            className="flex items-center gap-[30px] cursor-pointer"
                        >
                            <img src={image} alt="image" className="w-[30px] h-[30px] lg:w-10 lg:h-10 object-cover rounded-full" />
                            <h1 className="text-[14px] lg:text-[16px]">{name}</h1>
                        </div>
                    }

                    <div className="relative">
                        {!isPhone && type !== 'group' &&
                            <button
                                onClick={() => setShowMenu(!showMenu)}
                                className={`cursor-pointer ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                            >
                                <MenuIcon />
                            </button>
                        }

                        {pinnedMessages?.length > 0 && type !== 'group' &&
                            <div className="relative group flex items-center gap-[10px] select-none mt-3">
                                <div
                                    onMouseEnter={() => setIsTooltipVisible(true)}
                                    onMouseLeave={() => setIsTooltipVisible(false)}
                                    ref={iconRef}
                                    onClick={() => setShowPinnedMessages(true)}
                                    className="absolute z-50 left-0 fill-icon cursor-pointer"
                                >
                                    <PinnedIcon />
                                </div>
                                <Tooltip text="Ցուցադրել ամրագրված նամակները" targetRef={iconRef} show={isTooltipVisible} />
                            </div>
                        }
                    </div>

                    {showMenu &&
                        <div ref={ref} className="absolute z-50 w-[229px] top-[60px] flex flex-col gap-4 bg-white right-[4%] py-6 border border-black rounded-[5px]">
                            <>
                                <p
                                    onClick={handlePinUnpinClick}
                                    className="px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer"
                                >
                                    {pinned ? 'Ապաամրացնել' : 'Ամրացնել'}
                                </p>
                                <p
                                    onClick={() => setChatToDelete(true)}
                                    className="px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer"
                                >
                                    Ջնջել
                                </p>
                            </>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default ChatHeader