import React from 'react';
import {CloseIcon} from "../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "./buttons";
import {useMediaQuery} from "react-responsive";

const ConfirmationModal = ({close, confirm, warningText, infoText, icon, cancelText, confirmText}) => {
    const isPhone = useMediaQuery({maxWidth: 640})

    return (
        <div className={'fixed z-[99999] w-screen h-screen left-0 top-0 flex justify-center'}>
            <div onClick={close} className={'fixed bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div className={`flex flex-col items-center ${isPhone && 'w-[345px] max-h-[225px]'} bg-white absolute top-[180px] z-[9999] border border-black border-opacity-70 lg:px-[142px] pt-5 lg:py-[50px] rounded-[10px]`}>
                <button className={'absolute top-5 right-5 bg-black bg-opacity-5 rounded-full p-[2px] lg:p-1.5'} onClick={close}>
                    <CloseIcon width={isPhone ? 13 : 20} height={isPhone ? 13 : 20}/>
                </button>
                <div>
                    {icon}
                </div>
                <div className={`${isPhone && 'max-w-[285px] text-[14px] text-center'} flex flex-col items-center gap-[30px] mt-[30px] lg:mt-[50px]`}>
                    <p>{warningText}</p>
                    <p>{infoText}</p>
                </div>
                <div className={'flex gap-[30px] lg:mt-[60px] mb-[50px]'}>
                    <ButtonOutlined onClick={confirm} text={confirmText} className={'w-[120px] lg:w-[176px]'} isPhone={isPhone}/>
                    <ButtonFilled onClick={close} text={cancelText} className={'w-[120px] lg:w-[176px]'} isPhone={isPhone}/>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;