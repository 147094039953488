import {CHECK_IN, GET_CHECKINS_HISTORY, SET_LOADING} from '../constants/actionTypes'

const initialState = {
    checkins: [],
    checkinsHistory: [],
    loading: false,
}

const checkInsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_IN:
            console.log(action.payload)
            return {...state, checkins: [action.payload.checkinData, ...state.checkins]}
        case GET_CHECKINS_HISTORY:
            return {...state, checkinsHistory: action.payload.data}
        case SET_LOADING:
            return {...state, loading: !state.loading}
        default:
            return state;
    }
}
export default checkInsReducer