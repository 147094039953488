import API from "./index";

export const createTransportStop = (data) => API.post(`/transport/stops`, data)
export const getTransportStops = (queryParams) => API.get(`/transport/stops${queryParams}`)
export const editTransportStop = (id, data) => API.patch(`/transport/stops/${id}`, data)
export const changeTransportStopStatus = (id, status) => API.patch(`/transport/stops/${id}/status`, {status})
export const deleteTransportStop = (id) => API.delete(`/transport/stops/${id}`)

export const createRoute = (data) => API.post('/transport/routes', data)
export const getRoutes = (queryParams) => API.get(`/transport/routes${queryParams}`)
export const changeRouteStatus = (id, status) => API.patch(`/transport/routes/${id}/status`, {status})
export const editRoute = (id, data) => API.patch(`transport/routes/${id}`, data)
export const deleteRoute = (id) => API.delete(`/transport/routes/${id}`)

export const createVehicle = (data) => API.post('/transport/vehicles', data)
export const getVehicles = (queryParams) => API.get(`/transport/vehicles${queryParams}`)
export const changeVehicleStatus = (id, status) => API.patch(`/transport/vehicles/${id}/status`, status)
export const deleteVehicle = (id) => API.delete(`/transport/vehicles/${id}`)

export const createDriver = (data) => API.post('/transport/drivers', data)
export const createDriverFile = (id, data) => API.post(`/transport/drivers/${id}/files`, data)
export const getDrivers = (queryParams) => API.get(`/transport/drivers/${queryParams}`)
export const getDriver = (id) => API.get(`transport/drivers/${id}`)
export const getDriverFiles = (id) => API.get(`transport/drivers/${id}/files`)
export const changeDriverStatus = (id) => API.patch(`/transport/drivers/${id}/status`)
export const editDriver = (id, data) => API.patch(`/transport/drivers/${id}`, data)
export const deleteDriverFile = (fileId) => API.delete(`/transport/drivers/files/${fileId}`)

export const getTripWeeks = () => API.get('/transport/trips/weeks')
export const getTripWeekday = (weekId, weekdayIndex) => API.get(`/transport/trips/weekday?week=${weekId}&weekday=${weekdayIndex}`, )
export const getTripsAtTime = (weekId, weekdayIndex, type, time) => API.get(`/transport/trips/trips-at-time?week=${weekId}&weekday=${weekdayIndex}&type=${type}&time=${time}`)
export const getTrip = (id) => API.get(`/transport/trips/${id}`)
export const checkRouteMatch = (queryParams) => API.get(`/transport/trips/check-route-match?trips=${queryParams}`)
export const addRouteToTrip = (tripId, routeId) => API.patch(`transport/trips/attach-route?trips=${tripId}&route=${routeId}`)
export const addVehicleToTrip = (tripId, vehicleId) => API.patch(`transport/trips/attach-vehicle?trip=${tripId}&vehicle=${vehicleId}`)
export const changeTripStatus = (id, status) => API.patch(`/transport/trips/${id}/status`, status)
export const editTrip = (id, data) => API.patch(`/transport/trips/${id}`, data)
export const dividePassengers = (id, queryParams) => API.patch(`/transport/trips/${id}/divide-passengers?sizes=${queryParams}`)
export const mergeTrips = (queryParams) => API.patch(`/transport/trips/merge?trips=${queryParams}`)
export const removeTripRoute = (id) => API.delete(`/transport/trips/${id}/remove-route`)
export const removeTripVehicle = (id) => API.delete(`/transport/trips/${id}/remove-vehicle`)