import React, {useEffect} from 'react';
import {Container} from "../shared";
import {DropdownInputCheckbox} from "../shared/dropdowns";
import SelectedFilters from "../shared/SelectedFilters";
import {ButtonFilled, ButtonOutlined} from "../shared/buttons";
import {daysOfWeekLong} from "../../common/dropdownOptions";
import Calendar from "../shared/calendar/Calendar";
import {useDispatch, useSelector} from "react-redux";
import {getScheduleCodes} from "../../store/actions/common";

const CheckinsHistoryFilter = ({
                                   handleChange,
                                   changeSelectedFilters,
                                   filters,
                                   discardFilters,
                                   submitFilters,
                                   setFilters
                               }) => {
    const { scheduleCodes } = useSelector(state => state.common)

    const dispatch = useDispatch()

    const handleEnter = (e) => {
        e.key === 'Enter' && submitFilters()
    }

    const handleChangeFromDate = (date) => {
        setFilters({
            ...filters,
            fromDate: date
        })
    }

    useEffect(() => {
        dispatch(getScheduleCodes())
    }, [])

    return (
        <div className={'custom-shadow mt-5'}>
            <Container>
                <div className={'grid grid-cols-5 gap-[11px] mt-[17px]'}>
                    <div className={'relative'}>
                        <div>
                            <input type={"text"}
                                   className={'w-[250px] border border-black outline-none rounded-[5px] py-[7px] pl-[10px]'}
                                   name={'name'}
                                   value={filters?.name || ''}
                                   onChange={handleChange}
                                   placeholder={"ԱԱՀ"}
                                   onKeyDown={handleEnter}
                            />
                        </div>
                    </div>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={daysOfWeekLong} name={'weekday'} showCheckbox={true}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               placeholder={'Շաբաթվա օր'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.weekday}
                        />
                    </div>

                    <div className={'relative w-[250px] h-10'}>
                        <div
                            className={'absolute w-full border border-black border-opacity-70 py-[6px] rounded-[5px] bg-white'}>
                            <Calendar displayFormat={'DD.MM.YYYY'}
                                      returnFormat={'YYYY-MM-DD'}
                                      placeholder={'Սկիզբ'}
                                      previewClassName={'px-2.5'}
                                      className={'py-[1px]'}
                                      paddingClassName={'px-0'}
                                      optionClassName={'px-2'}
                                      gapClassName={'gap-1'}
                                      textSize={'text-[12px]'}
                                      showShortMonths={true}
                                      setDate={handleChangeFromDate}
                                      value={filters?.fromDate}
                            />
                        </div>
                    </div>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={scheduleCodes} name={'schedule'} showCheckbox={true}
                                               placeholder={'Գրաֆիկ'}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.schedule}
                        />
                    </div>

                    <div className={'relative w-[250px]'}>
                        <DropdownInputCheckbox data={''} name={'group'} showCheckbox={false}
                                               placeholder={'Խումբ'}
                                               previewClassName={'rounded-[5px] py-[7px]'}
                                               onChange={changeSelectedFilters}
                                               selected={filters?.group}
                        />
                    </div>
                </div>

                <div className={'flex justify-between gap-3 pt-[30px] pb-[17px]'}>
                    <SelectedFilters selectedFilters={filters} removeItem={changeSelectedFilters}/>

                    <div className={'flex gap-3 items-center'}>
                        <ButtonOutlined
                            onClick={discardFilters}
                            text={'Չեղարկել'}
                        />
                        <ButtonFilled
                            onClick={() => submitFilters()}
                            text={'Կիրառել'}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CheckinsHistoryFilter;