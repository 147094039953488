import React, {useEffect, useRef, useState} from 'react';
import {CloseIcon} from "../../../../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {editTrip, getTrip} from "../../../../../store/actions/transport";
import {ButtonFilled, ButtonOutlined} from "../../../../shared/buttons";
import {getStudentsNameSuggestions} from "../../../../../api/students";
import Search from "../edit/Search";
import PassengerItem from "../edit/PassengerItem";

const EditPassengersModal = ({id, close, refreshData}) => {
    const {trip} = useSelector(state => state.transport)

    const [searchStates, setSearchStates] = useState([])
    const [selectedStudents, setSelectedStudents] = useState([])
    const [selectedPassengers, setSelectedPassengers] = useState([])
    const [editNoteIndex, setEditNoteIndex] = useState(-1)
    const [updatedNote, setUpdatedNote] = useState('')
    const [warningMessage, setWarningMessage] = useState(null)

    const totalPassengersCount = trip?.stops.reduce((total, stop) => total + stop.passengers.length, 0)
    const seatingCapacity = trip?.vehicle?.seatingCapacity

    const searchOptionsRef = useRef(null)
    const dispatch = useDispatch()

    const fetchStudentSuggestions = async (value, index) => {
        const {data} = await getStudentsNameSuggestions(value)
        const newSearchStates = [...searchStates]
        newSearchStates[index].students = data.students
        setSearchStates(newSearchStates)
    }

    const handleSelect = (student, stopIndex) => {
        const isAlreadyInTrip = trip.stops.some(stop =>
            stop.passengers.some(p => p.passenger._id === student._id)
        )

        if (isAlreadyInTrip) {
            setWarningMessage('Տվալ ուսանողն արդեն ներառված է ցանկում')
            return
        }

        if (totalPassengersCount === seatingCapacity) {
            return
        }

        setWarningMessage(null)

        const newTrip = {...trip}
        const stop = newTrip.stops[stopIndex]

        const group = student.group ? student.group : null

        const newPassenger = {
            _id: student._id,
            passenger: student,
            active: true,
            type: 'guest',
            group: group,
            note: null,
        }

        stop.passengers = [...stop.passengers, newPassenger]

        dispatch({type: 'UPDATE_TRIP', payload: newTrip})

        const newSearchStates = [...searchStates]

        setSelectedStudents([...selectedStudents, student])
        setSearchStates(newSearchStates)
    }

    const handleChange = (value, index, event) => {
        const newSearchStates = [...searchStates]
        newSearchStates[index].value = value
        setSearchStates(newSearchStates)

        if (value) {
            fetchStudentSuggestions(value, index)
        } else {
            newSearchStates[index].students = null
            setSearchStates(newSearchStates)
        }

        if (event.key === 'Enter') {
            const newTrip = {...trip}
            const stop = newTrip.stops[index]

            const newPassenger = {
                _id: `${Math.random()}`,
                passenger: {
                    _id: `${Math.random()}`,
                    fullName: value,
                    phoneNumbers: [],
                    profileImage: null,
                    community: null,
                },
                active: true,
                type: 'guest',
                group: null,
                note: null,
            }

            stop.passengers = [...stop.passengers, newPassenger]

            dispatch({type: 'UPDATE_TRIP', payload: newTrip})

            newSearchStates[index].value = ''
            setSearchStates(newSearchStates)
        }
    }

    const handleToggleActive = (passengerId, stopIndex) => {
        const newSelectedPassengers = [...selectedPassengers]
        const newTrip = {...trip}
        const passenger = newTrip.stops[stopIndex].passengers.find(p => p._id === passengerId)
        const isSelected = selectedPassengers.includes(passengerId)

        if (isSelected) {
            setSelectedPassengers(newSelectedPassengers.filter(id => id !== passengerId))
            passenger.active = false
        } else {
            setSelectedPassengers([...newSelectedPassengers, passengerId])
            passenger.active = true
        }

        dispatch({type: 'UPDATE_TRIP', payload: newTrip})
    }

    const handleEditNote = (passengerId, stopIndex) => {
        setEditNoteIndex(passengerId)
        const passenger = trip.stops[stopIndex].passengers.find(p => p._id === passengerId)
        setUpdatedNote(passenger.note || '')
    };

    const handleUpdateNote = (passengerId, stopIndex) => {
        const newTrip = {...trip}
        const passenger = newTrip.stops[stopIndex].passengers.find(p => p._id === passengerId)
        passenger.note = updatedNote

        dispatch({type: 'UPDATE_TRIP', payload: newTrip})

        setEditNoteIndex(-1)
    };

    const handleRemovePassenger = (passengerId, stopIndex) => {
        const newTrip = {...trip}
        const stop = newTrip.stops[stopIndex]
        const updatedPassengers = stop.passengers.filter(p => p._id !== passengerId)
        stop.passengers = updatedPassengers

        dispatch({type: 'UPDATE_TRIP', payload: newTrip})

        const newSearchStates = [...searchStates]
        newSearchStates[stopIndex].students = null
        setSearchStates(newSearchStates)
    }

    const handleSubmit = () => {
        const updatedTripData = {
            id: trip._id,
            stops: trip.stops.map(stop => ({
                _id: stop._id,
                transportStop: {
                    _id: stop.transportStop?._id,
                    name: stop.transportStop?.name,
                    division: stop.transportStop.division,
                },
                passengers: stop.passengers.map(passenger => ({
                    _id: passenger._id,
                    passenger: {
                        _id: passenger.passenger?._id,
                        fullName: passenger.passenger.fullName,
                        phoneNumbers: passenger.passenger.phoneNumbers,
                        profileImage: passenger.passenger.profileImage,
                        community: {
                            _id: passenger.passenger.community?._id,
                            name: passenger.passenger.community?.name,
                        },
                    },
                    type: passenger.type,
                    active: passenger.active,
                    group: passenger.group !== null ? passenger.group : undefined,
                    note: passenger.note,
                })),
            })),
        };

        dispatch(editTrip(trip._id, updatedTripData))
            .then(() => {
                refreshData()
                close()
            })
    }

    useEffect(() => {
        if (trip?.stops) {
            const initialSearchStates = trip.stops.map(() => ({value: '', students: null}))
            setSearchStates(initialSearchStates);

            const initiallySelectedPassengers = trip.stops.flatMap(stop =>
                stop.passengers.filter(passenger => passenger.active).map(passenger => passenger._id)
            );
            setSelectedPassengers(initiallySelectedPassengers)
        }
    }, [trip])

    useEffect(() => {
        dispatch(getTrip(id))
    }, [dispatch, id])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchOptionsRef.current && !searchOptionsRef.current.contains(event.target)) {
                const newSearchStates = searchStates.map(state => ({...state, students: null}))
                setSearchStates(newSearchStates)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [searchStates])

    return (
        <div className={'fixed z-[999999] top-[5%] w-screen h-screen left-0 bottom-0 flex justify-center'}>
            <div className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute w-full max-w-[1062px] max-h-[calc(100vh-7rem)] overflow-hidden z-[9999] border border-black rounded-[10px] bg-white overflow-y-auto'}>
                <div className={'relative flex items-center justify-center p-[30px]'}>
                    <h2 className={'text-center'}>Խմբագրել ուղևորներին</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>

                <Search
                    trip={trip}
                    searchOptionsRef={searchOptionsRef}
                    searchStates={searchStates}
                    handleChange={handleChange}
                    seatingCapacity={seatingCapacity}
                    totalPassengersCount={totalPassengersCount}
                    handleSelect={handleSelect}
                    selectedStudents={selectedStudents}
                    warningMessage={warningMessage}
                />

                <PassengerItem
                    trip={trip}
                    handleToggleActive={handleToggleActive}
                    handleRemovePassenger={handleRemovePassenger}
                    handleEditNote={handleEditNote}
                    editNoteIndex={editNoteIndex}
                    setEditNoteIndex={setEditNoteIndex}
                    updatedNote={updatedNote}
                    setUpdatedNote={setUpdatedNote}
                    handleUpdateNote={handleUpdateNote}
                />

                <div className={'flex justify-end py-[50px] gap-[30px] pr-[163px]'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                    <ButtonFilled onClick={handleSubmit} text={'Պահպանել'} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    );
};

export default EditPassengersModal
