import React, { useEffect, useRef, useState } from 'react';
import moment from "moment";
import { AbsentIcon, CanceledLessonIcon, CheckedGreenIcon, CommentIcon } from "../../../../assets/icons";
import Portal from "../../../shared/Portal";

const AttendanceNoteIcon = ({ note, type, isParentScrolling }) => {
    const [hovered, setHovered] = useState(false);
    const iconRef = useRef(null);
    const tooltipRef = useRef(null);
    const [coords, setCoords] = useState({ top: 0, left: 0 });

    const bgColor = type === 'absent' ? 'bg-[#FF0707]' : 'bg-[#20BA07]';

    const showNote = () => {
        if (iconRef.current && tooltipRef.current) {
            const iconRect = iconRef.current.getBoundingClientRect();
            const tooltipRect = tooltipRef.current.getBoundingClientRect();

            const top = window.scrollY + iconRect.top - tooltipRect.height - 10

            const left = window.scrollX + iconRect.left + (iconRect.width - tooltipRect.width) / 2;

            setCoords({ top, left });
        }
        setHovered(true);
    };

    const hideNote = () => {
        setHovered(false);
    };

    useEffect(() => {
        if (isParentScrolling && hovered) {
            setHovered(false);
        }
    }, [isParentScrolling, hovered]);

    return (
        <div
            ref={iconRef}
            className={`relative w-5 h-5 flex justify-center items-center rounded-[3px] ${bgColor}`}
            onMouseOver={showNote}
            onMouseLeave={hideNote}
        >
            <CommentIcon />

            {hovered && (
                <Portal top={coords.top} left={coords.left} alignment="left">
                    <div
                        ref={tooltipRef}
                        className={`${note.length > 20 && 'w-[300px]'} p-2.5 z-[9999] opacity-100 pointer-events-auto transition bg-white rounded-[3px] shadow-md`}
                        style={{ position: 'absolute' }}
                    >
                        <span className="text-center max-w-[300px]">{note}</span>
                        <div className="relative flex items-center justify-center">
                            <div className="w-2 h-2 bg-white absolute -bottom-3 transform rotate-[45deg]"></div>
                        </div>
                    </div>
                </Portal>
            )}
        </div>
    );
};

const StudentAttendanceIcon = ({ lessonDate, data, canceled, lineHovered, hoverLine, index, isParentScrolling }) => {
    const isBeforeToday = lessonDate.isSameOrBefore(moment());

    const Icon = () => (
        data?.present === true
            ? data?.note
                ? <AttendanceNoteIcon type={'present'} note={data.note} isParentScrolling={isParentScrolling} />
                : <CheckedGreenIcon />
            : data?.present === false
                ? data?.note
                    ? <AttendanceNoteIcon type={'absent'} note={data.note} isParentScrolling={isParentScrolling} />
                    : <AbsentIcon />
                : ''
    );

    return (
        <div
            className={`w-full h-10 flex items-center justify-center border-b ${lineHovered && 'bg-black bg-opacity-5'}`}
            // onMouseOver={() => hoverLine(index)}
            // onMouseLeave={() => hoverLine(null)}
        >
            {canceled
                ? <CanceledLessonIcon />
                : isBeforeToday
                    ? data
                        ? <Icon />
                        : <div className='w-5 h-5 rounded-[3px] bg-[#00000019]'></div>
                    : data ? '' : <div className='w-5 h-5 rounded-[3px] bg-[#00000019]'></div>}
        </div>
    );
};

export default StudentAttendanceIcon;
