import * as ExcelJS from "exceljs";

export const downloadXlsx = (columns, data) => {
    const workbook = new ExcelJS.Workbook()
    const sheet = workbook.addWorksheet("Test Worksheet")

    sheet.getRow(1).border = {
        top: {style: "thin", color: {argb: "black"}},
        left: {style: "thin", color: {argb: "black"}},
        bottom: {style: "thin", color: {argb: "black"}},
        right: {style: "thin", color: {argb: "black"}},
    };

    sheet.getRow(1).fill = {
        fgColor: {argb: "FFFF00"},
    };

    sheet.getRow(1).font = {
        name: "Arial",
        bold: true,
    }

    sheet.columns = columns;

    data.map(({data, form, student}) => {
        sheet.addRow({
            firstName: data?.firstName,
            lastName: data?.lastName,
            middleName: data?.middleName,
            age: data?.age,
            community: data?.community?.name || '',
            status: student ? 'Ակտիվ' : 'Պասիվ',
            form: form?.name,
        });
    })

    workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = "download.xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
    })
}
