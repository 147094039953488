import React, {useEffect, useState} from 'react';
import {CloseIcon} from "../../../../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../../../../shared/buttons";
import {DropdownInputCheckbox} from "../../../../shared/dropdowns";
import {useDispatch, useSelector} from "react-redux";
import {getRouteNames} from "../../../../../store/actions/common";
import {addRouteToTrip} from "../../../../../store/actions/transport";

const AddRoute = ({close, joinedIds, refreshData, error, setError}) => {
    const {routeNames} = useSelector(state => state.common)
    const [selectedRoute, setSelectedRoute] = useState()

    const dispatch = useDispatch()

    const handleSelectRoute = (_, selected) => {
        setSelectedRoute(selected)
    }

    const handleSubmit = () => {
        if (!selectedRoute) {
            alert('Նշեք երթուղին');
            return;
        }

        dispatch(addRouteToTrip(selectedRoute._id, joinedIds, setError))
            .then(() => {
                refreshData();
                close();
            })
            .catch(error => {
                setError(error.message)
            })
    }


    useEffect(() => {
        if (error) {
            setError(error)
        }
    }, [error])

    useEffect(() => {
        dispatch(getRouteNames())
    }, [])

    return (
        <div className={'fixed z-[9990] mt-[30px] w-screen h-screen left-0 flex justify-center'}>
            <div className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute w-full max-w-[1062px] max-h-[356px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-[30px]'}>
                    <h2 className={'text-center mt-5'}>Կցել երթուղի</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>

                <div className={'px-[30px]'}>
                    <DropdownInputCheckbox
                        data={routeNames}
                        previewClassName={'rounded-[3px] px-[30px] py-[7px]'}
                        optionClassName={'px-[30px]'}
                        onChange={handleSelectRoute}
                        selected={[selectedRoute]}
                        hideCheckbox
                    />
                </div>

                {/*{error && <p className={'text-red-500 pl-[30px] mt-2 text-[12px]'}>Տվյալ երթուղին գոյություն չունի</p>}*/}

                <div className={'flex justify-center gap-[30px] mt-[70px] pb-[100px]'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                    <ButtonFilled onClick={handleSubmit} text={'Հաստատել'} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    );
};

export default AddRoute;