import React, {useEffect, useState} from 'react';
import {PlusIcon, TrashIcon} from "../../../assets/icons";
import CreateNewFileModal from "../../../components/students/profile/files/modals/CreateNewFileModal";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {createStudentFile, deleteStudentFile, getStudentFiles} from "../../../store/actions/students";
import FileItem from "../../../components/students/profile/files/FileItem";
import {ConfirmationModal} from "../../../components/shared"
import {createDriverFile, deleteDriverFile, getDriverFiles} from "../../../store/actions/transport";
import driver from "../transport/subpages/drivers/driver/Driver";

const Files = ({type}) => {
    const {files, loading} = useSelector(state => state.students)
    const {driverFiles} = useSelector(state => state.transport)
    const [createFileModal, setCreateFileModal] = useState(false)

    const [fileToDelete, setFileToDelete] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const handleSubmit = (file, fileName) => {
        if (file && fileName) {
            const formData = new FormData()
            formData.append("file", file)
            formData.append("name", fileName)

            type === 'driver' ? dispatch(createDriverFile(id, formData)) : dispatch(createStudentFile(id, formData))
            setCreateFileModal(false)

        } else {
            alert("Բոլոր դաշտերը լրացված չեն")
        }
    }

    const handleDeleteFile = () => {
        type === 'driver' ? dispatch(deleteDriverFile(fileToDelete)) : dispatch(deleteStudentFile(fileToDelete))
        setFileToDelete(null)
    }

    useEffect(() => {
        createFileModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [createFileModal])

    useEffect(() => {
        type === 'driver' ? dispatch(getDriverFiles(id)) : dispatch(getStudentFiles(id))
    }, [id])

    return (
        <>
            {fileToDelete && <ConfirmationModal
                close={() => setFileToDelete(null)}
                warningText={'Վստա՞հ եք, որ ցանկանում եք ջնջել այս փաստաթուղթը։'}
                confirm={handleDeleteFile}
                cancelText={'Ոչ'}
                confirmText={'Այո'}
                icon={<TrashIcon width={50} height={50} color={'#FCC10F'} />}
            />}

            <div>
                {createFileModal &&
                    <CreateNewFileModal
                        close={() => setCreateFileModal(false)}
                        submit={handleSubmit}
                    />}
                <div>
                    <button
                        onClick={() => setCreateFileModal(!createFileModal)}
                        className={'w-[208px] h-[50px] flex items-center justify-center gap-[30px] text-white rounded-[5px] bg-[#FCC10F] mt-7'}>
                        <PlusIcon/>
                        <span className={'bold'}>Նոր ֆայլ</span>
                    </button>
                </div>
                <div className={'grid gap-5 mt-[30px]'}>
                    {loading
                        ?
                        <p className={'text-center'}>Բեռնվում է․․․</p>
                        :
                        type !== 'driver' ?
                            files?.length > 0
                            ?
                            files.map(file => <FileItem deleteFile={setFileToDelete} key={file?._id} {...file}  />)
                            :
                            <p className={'text-center'}>Ֆայլեր չկան</p>
                            :
                            driverFiles?.length > 0
                                ?
                                driverFiles?.map(file => <FileItem deleteFile={setFileToDelete} key={file?._id} {...file}  />)
                                :
                                <p className={'text-center'}>Ֆայլեր չկան</p>
                    }
                </div>
            </div>
        </>
    );
};

export default Files;