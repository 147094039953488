import React, {useEffect} from 'react';
import {Container} from "../../../components/shared";
import ChatsList from "../../../components/chats/chatsList/ChatsList";
import Chat from "../../../components/chats/chat/Chat";
import {useGetChat, useGetChats} from "../../../hooks/socket/useChats";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {useMediaQuery} from "react-responsive";
import {getNewMessagesCount} from "../../../socket/chats";

const Chats = () => {
    const {socket} = useSelector(state => state.socket);
    const {chat} = useSelector(state => state.chats);
    const {id} = useParams();

    useGetChats(socket);

    const {getChat} = useGetChat(socket);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSelectChat = (chatId) => {
        navigate(`/chats/${chatId}`)
    }

    useEffect(() => {
        if (id && socket) {
            getChat(id);
        }
    }, [id, socket]);

    const isPhone = useMediaQuery({maxWidth: 640})

    const content = isPhone ?
        id
            ?
            <div className="h-full">
                <Chat/>
            </div>
            :
            <div className="h-full">
                <ChatsList
                    onChatSelect={handleSelectChat}
                    loading={false}
                />
            </div>
        :
        <div className={`h-full flex`}>
            <div className={`w-full sm:w-[34%] h-full`}>
                <ChatsList
                    onChatSelect={handleSelectChat}
                    loading={false}
                />
            </div>
            <div className={`w-full sm:w-[66%] h-full flex items-center justify-center`}>
                {id
                    ? <Chat/>
                    : 'Ընտրեք նամակագրություն'
                }
            </div>
        </div>

    return (
        <Container className={`py-0 ${id && 'px-0'}`}>
            <div className="overflow-hidden">
                {content}
            </div>
        </Container>
    )
}

export default Chats