import React, {useEffect} from 'react';
import {CloseIcon} from "../../../assets/icons";
import QRCode from "react-qr-code";
import {useDispatch, useSelector} from "react-redux";
import {getStudent} from "../../../store/actions/students";

const QrCode = ({close}) => {
    const {authData} = useSelector(state => state.auth)
    const {student} = useSelector(state => state.students)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getStudent(authData.id))
    }, [])

    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex justify-center mt-[70px]'}>
            <div onClick={close} className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>
            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[345px] max-h-[320px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-between pt-5 pb-[30px] px-5'}>
                    <div></div>
                    <h2 className={'text-center'}>QR</h2>
                    <div
                        onClick={close}
                        className={'w-[15px] h-[15px] flex items-center justify-center p-[1px] bg-white border border-black border-opacity-70 rounded-full'}>
                        <CloseIcon width={13} height={13}/>
                    </div>
                </div>

                <div className={'flex items-center justify-center pb-[50px]'}><QRCode value={authData.idToken} size={200}/></div>
            </div>
        </div>
    );
};

export default QrCode;