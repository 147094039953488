import React, {useEffect, useState} from 'react';
import {
    CheckedIcon,
    ClothsSizeIcon,
    GPSIcon,
    HoodieIcon,
    LocationIcon,
    MailIcon,
    PhoneIcon,
    PinIcon,
    TabletIcon,
    TransportIcon,
    TuitionIcon,
    UsersIcon
} from "../../../assets/icons";
import {creator, genius, thinker, whizzKid, wizard} from "../../../assets/images/SMART Degrees/index";
import {DisplayPhoneNumber} from "../../shared";
import moment from "moment/moment";
import {DropdownInputCheckbox} from "../../shared/dropdowns";
import {clothingSizeData} from "../../../common/dropdownOptions";
import Calendar from "../../shared/calendar/Calendar";
import {useDispatch, useSelector} from "react-redux";
import {getTransportStopsNames} from "../../../store/actions/common";
import {roles} from "../../../common/roles";

const rewardIcons = [
    {icon: <PinIcon/>, id: 'pins', text: 'Փիներ'},
    {icon: <HoodieIcon/>, id: 'hoodie', text: 'Հուդի'},
    {icon: <LocationIcon/>, id: 'smartVisits', text: 'Սմարթ այցեր'},
    {icon: <TabletIcon/>, id: 'notepad', text: 'Պլանշետ'},
    {icon: <TuitionIcon/>, id: 'scholarship', text: 'Կրթաթոշակ'}
];

const PersonalInfo = ({
                          changeCommunityField,
                          changePhoneNumber,
                          changeTransportStopName,
                          changeClothingSize,
                          community,
                          email,
                          transportStop,
                          gender,
                          phoneNumbers,
                          editMode,
                          birthday,
                          changeField,
                          clothingSize,
                          smartDegrees,
                          changeBirthdayDate,
                          ageGroup,
                          addAward,
                          awards,
                      }) => {
    const {communities, stopNames} = useSelector(state => state.common)

    const { authData } = useSelector(state => state.auth);

    const birthdayFormatted = moment(birthday).format('DD.MM.YYYY')
    const [selectedSection, setSelectedSection] = useState([])

    const [grayscale, setGrayscale] = useState({
        thinker: true,
        creator: true,
        whizzKid: true,
        wizard: true,
        genius: true
    })

    const dispatch = useDispatch()

    const handleSectionSelect = (index) => {
        if (editMode) {
            const isSelected = selectedSection.includes(index)
            let newSelectedSection

            if (isSelected) {
                newSelectedSection = selectedSection.filter(i => i !== index)
            } else {
                newSelectedSection = [...selectedSection, index]
            }

            setSelectedSection(newSelectedSection)

            const selectedAwards = newSelectedSection.map(i => rewardIcons[i].id)

            addAward(selectedAwards)
        }
    }

    useEffect(() => {
        const initialSelected = rewardIcons
            .map((icon, index) => awards.includes(icon.id) ? index : null)
            .filter(index => index !== null)
        setSelectedSection(initialSelected)
    }, [awards])

    useEffect(() => {
        dispatch(getTransportStopsNames())
    }, [])

    return (
        <div
            className={`flex justify-between h-[265px] px-[30px] pt-4 ${editMode && roles[authData?.role]?.some(role => role.allowEditAll) ? 'pb-[29px]' : 'pb-[31px]'} border-b`}>
            <div className={`flex flex-col max-w-[356px] ${editMode && roles[authData?.role]?.some(role => role.allowEditAll) ? 'gap-[10px]' : 'gap-[14.6px]'}`}>
                <p className={'text-[#FCC10F]'}>Անձնական տվյալներ</p>
                <div className={'flex gap-[30px]'}>
                    <div className={'flex items-center gap-[10px]'}>
                        <GPSIcon/>
                        {editMode && roles[authData?.role]?.some(role => role.allowEditAll)
                            ?
                            <div className={'w-[180px]'}>
                                <DropdownInputCheckbox
                                    data={communities}
                                    name={'community'}
                                    placeholder={'Համայնք'}
                                    onChange={changeCommunityField}
                                    previewClassName={'rounded-[3px] h-[30px]'}
                                    hideCheckbox
                                    selected={[community]}
                                />
                            </div>
                            :
                            <span>{community?.name}</span>
                        }
                    </div>
                    <div>
                        <div className={'flex items-center gap-[10px]'}>
                            <TransportIcon/>
                            {editMode && (roles[authData?.role]?.some(role => role.allowEditAll) || roles[authData?.role]?.some(role => role.allowEditOnlyStop))
                                ?
                                <div className={'w-[180px]'}>
                                    <DropdownInputCheckbox
                                        data={stopNames}
                                        name={'stops'}
                                        placeholder={'Կանգառ'}
                                        onChange={changeTransportStopName}
                                        previewClassName={'rounded-[3px] h-[30px]'}
                                        hideCheckbox
                                        selected={[transportStop]}
                                    />
                                </div>
                                :
                                <span>{transportStop?.name}</span>
                            }
                        </div>
                    </div>
                </div>
                <div className={'flex items-center gap-[10px]'}>
                    {editMode && roles[authData?.role]?.some(role => role.allowEditAll)
                        ?
                        <div className={'flex gap-[30px]'}>
                            {phoneNumbers?.map((phoneNumber, i) => (
                                <div key={i} className={'flex items-center gap-[10px]'}>
                                    <PhoneIcon/>
                                    <input
                                        type="text"
                                        className={'w-[180px] h-[30px] border border-black border-opacity-70 rounded-[3px] py-2 px-[10px] outline-none'}
                                        value={phoneNumber || ''}
                                        onChange={e => changePhoneNumber(i, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                        :
                        <>
                            <PhoneIcon/>
                            <div>
                                {phoneNumbers?.map((n, i) => <DisplayPhoneNumber key={i} className={'mr-2.5'}
                                                                                 phoneNumber={n}/>)}
                            </div>
                        </>
                    }

                </div>
                <div className={'w-[420px] flex items-center gap-[10px]'}>
                    <MailIcon/>
                    {editMode && roles[authData?.role]?.some(role => role.allowEditAll)
                        ?
                        <input
                            type="text"
                            className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] px-[10px] outline-none'}
                            name={'email'}
                            value={email || ''}
                            onChange={changeField}
                        />
                        :
                        <span>{email}</span>
                    }
                </div>
                <div className={` ${editMode && roles[authData?.role]?.some(role => role.allowEditAll) && 'w-[450px]'} flex items-center gap-[10px]`}>
                    <div className={'flex gap-[10px]'}>
                        <span className={'text-[#FCC10F]'}>Սեռ՝</span>
                        <span>{gender === 'female' ? 'Իգական' : 'Արական'}</span>
                    </div>
                    <div className={'relative flex items-center'}>
                        <span className={'w-[120px] text-[#FCC10F]'}>Ծննդյան օր՝</span>
                        {editMode && roles[authData?.role]?.some(role => role.allowEditAll)
                            ?
                            <div className={'absolute left-full top-0 w-[240px] border border-black rounded-[3px]'}>
                                <Calendar
                                    displayFormat={'DD.MM.YYYY'}
                                    returnFormat={'YYYY-MM-DD'}
                                    className={'py-[1px]'}
                                    paddingClassName={'px-0'}
                                    optionClassName={'px-2'}
                                    gapClassName={'gap-1'}
                                    textSize={'text-[12px]'}
                                    setDate={changeBirthdayDate}
                                    showShortMonths={true}
                                    value={birthday}
                                />
                            </div>
                            :
                            <span>{birthdayFormatted}</span>
                        }
                    </div>
                </div>

                <div className={`${editMode && roles[authData?.role]?.some(role => role.allowEditAll) ? 'gap-[14px]' : 'gap-[30px]'} flex items-center`}>
                    {editMode && roles[authData?.role]?.some(role => role.allowEditAll) ?
                        <div className={'flex items-center w-[101px] gap-[10px] rounded-[3px]'}>
                            <DropdownInputCheckbox
                                selected={[clothingSizeData.find(item => item._id === clothingSize)]}
                                data={clothingSizeData}
                                onChange={changeClothingSize}
                                name={'clothingSize'}
                                icon={<ClothsSizeIcon/>}
                                hideCheckbox
                                optionClassName={'uppercase px-4'}
                                previewClassName={'uppercase py-[2px] rounded-[3px]'}
                                disableInput
                            />
                        </div>
                        :
                        <div className={'flex items-center gap-2.5 '}>
                            <ClothsSizeIcon/>
                            <p className={'uppercase'}>{clothingSize || 'Առկա չէ'}</p>
                        </div>
                    }

                    <div className={'flex items-center gap-[10px]'}>
                        <UsersIcon color={'#FCC10F'}/>
                        <span>{ageGroup}</span>
                    </div>
                </div>
            </div>

            <div className={`flex flex-col ${(!editMode || roles[authData?.role]?.some(role => role.allowEditOnlyStop)) && 'gap-10'}`}>
                <div
                    className={`flex items-center justify-between w-[310px] ${editMode && (roles[authData?.role]?.some(role => role.allowEditAll) || roles[authData?.role]?.some(role => role.allowEditOnlyPins)) ? 'h-[110px]' : 'h-[70px]'} mt-[24px] px-5 rounded-[5px] shadow-md`}>
                    {rewardIcons.map((iconData, index) =>
                        <div key={index}
                             className={'relative group flex flex-col items-center gap-5 cursor-pointer select-none'}
                             onClick={() => handleSectionSelect(index)}
                        >
                            {React.cloneElement(iconData.icon, {
                                color: selectedSection.includes(index) ? '#FCC10F' : undefined
                            })}
                            <span
                                className={`absolute z-[9999] text-center w-[115px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>{iconData.text}</span>
                            {editMode && (roles[authData?.role]?.some(role => role.allowEditAll) || roles[authData?.role]?.some(role => role.allowEditOnlyPins)) &&
                                <div
                                    className={`w-5 h-5 flex items-center justify-center mx-[10px] border rounded-[3px] ${selectedSection.includes(index) ? 'border-[#FCC10F]' : 'border-black'}`}>
                                    {selectedSection.includes(index) &&
                                        <CheckedIcon color={'#FCC10F'}/>
                                    }
                                </div>
                            }
                        </div>
                    )}
                </div>

                <div
                    className={'flex items-center justify-between w-[310px] h-[70px] px-5 rounded-[5px] shadow-md select-none'}>
                    <div className={'relative group'}>
                        <img
                            src={thinker}
                            alt={'thinker'}
                            className={`w-[30px] h-[30px]  select-none ${smartDegrees.find(degree => degree === "thinker") ? '' : 'grayscale'}`}
                        />
                        <span
                            className={`absolute z-[9999] w-fit left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Մտածող</span>
                    </div>

                    <div className={'relative group'}>
                        <img
                            src={creator}
                            alt={'creator'}
                            className={`w-[30px] h-[30px]  select-none ${smartDegrees.find(degree => degree === "creator") ? '' : 'grayscale'}`}
                        />
                        <span
                            className={`absolute z-[9999] w-fit left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ստեղծող</span>
                    </div>

                    <div className={'relative group'}>
                        <img
                            src={whizzKid}
                            alt={'whizzKid'}
                            className={`w-[30px] h-[30px]  select-none ${smartDegrees.find(degree => degree === "whizzKid") ? '' : 'grayscale'}`}
                        />
                        <span
                            className={`absolute z-[9999] w-fit left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Գիտակ</span>
                    </div>

                    <div className={'relative group'}>
                        <img
                            src={wizard}
                            alt={'wizard'}
                            className={`w-[30px] h-[30px]  select-none ${smartDegrees.find(degree => degree === "wizard") ? '' : 'grayscale'}`}
                        />
                        <span
                            className={`absolute z-[9999] w-fit left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Հրաշագործ</span>
                    </div>

                    <div className={'relative group'}>
                        <img
                            src={genius}
                            alt={'genius'}
                            className={`w-[30px] h-[30px]  select-none ${smartDegrees.find(degree => degree === "genius") ? '' : 'grayscale'}`}
                        />
                        <span
                            className={`absolute z-[9999] w-fit left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Տաղանդ</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PersonalInfo;
