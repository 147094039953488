import React, {useEffect, useState} from 'react';
import DropdownInputCheckbox from "../../../components/shared/dropdowns/DropdownInputCheckbox";
import {Container} from "../../../components/shared";
import {AsteriskIcon} from "../../../assets/icons";
import Calendar from "../../../components/shared/calendar/Calendar";
import {ButtonFilled, ButtonOutlined, PlusButton} from "../../../components/shared/buttons"
import {useDispatch, useSelector} from "react-redux"
import {useNavigate} from "react-router-dom"
import SelectSchedule from "../../../components/groups/SelectSchedule";
import {createGroup} from "../../../store/actions/groups";
import moment from "moment";
import SelectVacation from "../../../components/groups/SelectVacation";
import {ageGroupDataHybrid} from "../../../common/dropdownOptions";
import {checkGroupDetails} from "../../../utils/groups/checkGroupDetails";
import {getMembersNamesList} from "../../../store/actions/common";

const initialState = {
    name: 'Խմբի անունը',
    program: '',
    lessonsCount: 30,
    levelsCount: 3,
    ageGroup: null,
    instructors: [],
    lessonDuration: 120,
    startDate: '',
    vacations: [],
    schedule: []
}

const CreateGroup = () => {
    const [data, setData] = useState(initialState)
    const [validationErrors, setValidationErrors] = useState({})
    const [nameBeforeEdit, setNameBeforeEdit] = useState('')
    const [value, setValue] = useState('');
    const [selectedInstructors, setSelectedInstructors] = useState([]);

    const {programs, members} = useSelector(state => state.common)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleStartChangeName = () => {
        setNameBeforeEdit(data.name)
    }

    const handleChangeName = (e) => {
        setData({...data, name: e.target.value})
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (data.name) {
                setNameBeforeEdit('')
            } else {
                setData({...data, name: nameBeforeEdit})
            }
        }
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleAddToSchedule = (scheduleDay) => {
        const exists = data.schedule.some(item => item.dayIndex === scheduleDay.dayIndex)

        if (exists) {
            setData({
                ...data,
                schedule: data.schedule.map(item => item.dayIndex === scheduleDay.dayIndex ? scheduleDay : item),
            })
        } else {
            setData({
                ...data,
                schedule: [...data.schedule, scheduleDay]
            })
        }
    }

    const handleRemoveScheduleDay = (dayIndex) => {
        setData({
            ...data,
            schedule: data.schedule.filter(item => item.dayIndex !== dayIndex),
        })
    }

    const handleDeleteVacation = (index) => {
        setData({
            ...data,
            vacations: data.vacations.filter((_, i) => i !== index)
        })
    }

    const handleSelectProgram = (_, program) => {
        setData({...data, program})
    }

    const handleSelectAgeGroup = (_, ageGroup) => {
        setData({...data, ageGroup})
    }

    const handleAddInstructor = (_, value) => {
        const isSelected = data.instructors.some(item => item._id === value._id)

        if (isSelected) {
            const updatedInstructors = data.instructors.filter(instructor => instructor._id !== value._id)
            setData({
                ...data,
                instructors: updatedInstructors,
            })
        } else {
            setData({
                ...data,
                instructors: [...data.instructors, value],
            })
        }
    }

    const handleRemoveSelectedInstructor = (instructorId) => {
        const updatedInstructors = data.instructors.filter(instructor => instructor._id !== instructorId);
        setData({
            ...data,
            instructors: updatedInstructors,
        });
    }

    const changeStartDate = (e) => {
        setData({...data, startDate: e})
    }

    const handleAddVacation = () => {
        setData({
            ...data,
            vacations: [...data.vacations, {start: '', end: ''}]
        })
    }

    const changeVacationStartDate = (startDate, index) => {
        setData({
            ...data,
            vacations: data.vacations.map((vacation, i) =>
                i === index ? {...vacation, start: startDate} : vacation
            )
        })
    }

    const changeVacationEndDate = (endDate, index) => {
        setData({
            ...data,
            vacations: data.vacations.map((vacation, i) =>
                i === index ? {...vacation, end: endDate} : vacation
            )
        })
    }

    const handleDiscard = () => {
        navigate('/groups?page=1&size=9&')
    }

    const handleSubmit = () => {
        const payload = {
            ...data,
            vacations: data.vacations.map(vacation => ({
                start: moment(vacation.start).format('YYYY-MM-DD'),
                end: moment(vacation.end).format('YYYY-MM-DD'),
            })),
            ageGroup: data.ageGroup?._id
        }

        const errors = checkGroupDetails(payload, data)

        if (Object.keys(errors).length === 0) {
            dispatch(createGroup(payload, navigate))
        } else {
            setValidationErrors(errors)
        }
    }

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ""
        }))
    }

    useEffect(() => {
        dispatch(getMembersNamesList(['instructor', 'admin', 'divisionManager', 'projectsCoordinator', 'dispatcher', 'mdt', 'manager']))
    }, [])

    return (
        <div>
            <Container>
                <div className={'flex justify-center mb-[50px]'}>
                    {nameBeforeEdit ?
                        <input
                            name="name"
                            className={'w-full text-center text-[50px] text-[#FCC10F] font-bold outline-none'}
                            autoComplete={'off'}
                            autoFocus
                            onFocus={e => e.target.select()}
                            value={data.name}
                            onChange={handleChangeName}
                            onKeyDown={handleEnter}
                        />
                        :
                        <h2 onClick={handleStartChangeName}
                            className={'text-center text-[50px] text-[#FCC10F] font-bold break-all'}>{data.name}</h2>
                    }
                </div>
                <div className={'grid lg:grid-cols-2 grid-cols-1 gap-[30px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կցել ծրագիր <AsteriskIcon/></h2>
                            <div>
                                <DropdownInputCheckbox
                                    data={programs}
                                    previewClassName={'px-[30px] rounded-[5px] py-[7px]'}
                                    optionClassName={'px-6'}
                                    name={'program'}
                                    placeholder={'Ընտրել ծրագիր'}
                                    onChange={handleSelectProgram}
                                    selected={[data?.program]}
                                    hideCheckbox
                                    onFocus={() => handleFocus('program')}
                                />
                                {validationErrors.program &&
                                    <p className={"text-red-500 mt-2"}>{validationErrors.program}</p>}
                            </div>
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Դասերի քանակը <AsteriskIcon/></h2>
                            <input
                                type={"number"}
                                value={data?.lessonsCount}
                                name={'lessonsCount'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('lessonsCount')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.lessonsCount &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.lessonsCount}</p>}
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Մակարդակների քանակը<AsteriskIcon/></h2>
                            <input
                                type={"number"}
                                value={data?.levelsCount}
                                name={'levelsCount'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('levelsCount')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.levelsCount &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.levelsCount}</p>}
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Տարիքային խումբ <AsteriskIcon/></h2>
                            <div>
                                <DropdownInputCheckbox
                                    data={ageGroupDataHybrid}
                                    previewClassName={'px-[30px] rounded-[5px] py-[7px]'}
                                    optionClassName={'px-6'}
                                    name={'ageGroup'}
                                    placeholder={'Ընտրել տարիքային խումբ'}
                                    onChange={handleSelectAgeGroup}
                                    selected={[data?.ageGroup]}
                                    hideCheckbox
                                    onFocus={() => handleFocus('ageGroup')}
                                />
                                {validationErrors.ageGroup &&
                                    <p className={"text-red-500 mt-2"}>{validationErrors.ageGroup}</p>}
                            </div>
                        </div>

                        <div className={'flex flex-col gap-[30px]'}>
                            <h2 className={'flex gap-[6px] text-[#FCC10F]'}>Գրաֆիկ <AsteriskIcon/></h2>
                            <SelectSchedule
                                schedule={data.schedule}
                                removeScheduleDay={handleRemoveScheduleDay}
                                addToSchedule={handleAddToSchedule}
                                onFocus={() => handleFocus('schedule')}
                                lessonDuration={data.lessonDuration}
                            />
                            {validationErrors.schedule &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.schedule}</p>}
                        </div>
                    </div>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կցել մասնագետ <AsteriskIcon/></h2>
                            <div>
                                <DropdownInputCheckbox
                                    data={members}
                                    previewClassName={'px-[30px] rounded-[5px] py-[7px]'}
                                    optionClassName={'px-6'}
                                    name={'instructor'}
                                    className={'rounded-[3px]'}
                                    placeholder={'Ընտրել մասնագետ(ներ)'}
                                    onChange={handleAddInstructor}
                                    selected={data?.instructors}
                                    type={'instructors'}
                                    removeSelectedInstructor={handleRemoveSelectedInstructor}
                                    onFocus={() => handleFocus('instructors')}
                                />
                                {validationErrors.instructors &&
                                    <p className={"text-red-500 mt-2"}>{validationErrors.instructors}</p>}
                            </div>
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Դասերի տևողությունը (րոպե) <AsteriskIcon/></h2>
                            <input
                                type={"number"}
                                value={data.lessonDuration}
                                name={'lessonDuration'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('lessonDuration')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.lessonDuration &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.lessonDuration}</p>}
                        </div>

                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>Դասի մեկնարկի օր <AsteriskIcon/></h2>
                            <div className={'relative'}>
                                <div
                                    className={`absolute w-full bg-white border border-black border-opacity-70 py-[6px] rounded-[5px]`}>
                                    <Calendar displayFormat={'DD.MM.YYYY'}
                                              returnFormat={'YYYY-MM-DD'}
                                              value={data.startDate}
                                              setDate={changeStartDate}
                                              placeholder={'օր/ամիս/տարի'}
                                              className={'py-[1px]'}
                                              optionClassName={'px-[30px]'}
                                              onFocus={() => handleFocus('startDate')}
                                    />
                                    {validationErrors.startDate &&
                                        <p className={"absolute top-full text-red-500 mt-2"}>{validationErrors.startDate}</p>}
                                </div>
                            </div>
                        </div>
                        <div className={'mt-10'}>
                            <h2 className={`flex items-center mb-[30px] text-[#FCC10F] ${validationErrors.startDate && 'mt-2'}`}>Արձակուրդ</h2>

                            <div className={`flex flex-col gap-[10px]`}>
                                {data.vacations.map((_, index) => (
                                    <div key={index} className={'mb-5'}>
                                        <SelectVacation
                                            key={index}
                                            startDate={data.vacations[index]?.start || ''}
                                            endDate={data.vacations[index]?.end || ''}
                                            onChangeStartDate={startDate => changeVacationStartDate(startDate, index)}
                                            onChangeEndDate={endDate => changeVacationEndDate(endDate, index)}
                                            deleteVacation={() => handleDeleteVacation(index)}
                                        />
                                    </div>
                                ))}
                            </div>

                            <div className={`flex items-center justify-center`} onClick={handleAddVacation}>
                                <PlusButton className={'p-2.5'} width={10} height={10}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'flex items-center justify-end'}>
                        <div>
                            <div className={'w-fit flex gap-[30px] items-center mt-[100px]'}>
                                <ButtonOutlined
                                    onClick={handleDiscard}
                                    text={'Չեղարկել'}
                                    className={'w-[176px]'}
                                />
                                <ButtonFilled
                                    onClick={handleSubmit}
                                    text={'Պահպանել'}
                                    className={'w-[176px]'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default CreateGroup;
