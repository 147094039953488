import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {checkGroupDetails} from "../../../../../utils/groups/checkGroupDetails";
import {createTransportStop, editTransportStop} from "../../../../../store/actions/transport";
import {Container} from "../../../../../components/shared";
import {AsteriskIcon} from "../../../../../assets/icons";
import DropdownInputCheckbox from "../../../../../components/shared/dropdowns/DropdownInputCheckbox";
import {ButtonFilled, ButtonOutlined} from "../../../../../components/shared/buttons";

const EditStop = () => {
    const {communities} = useSelector(state => state.common)
    const {stop} = useSelector(state => state.transport)

    const [data, setData] = useState(stop)
    const [validationErrors, setValidationErrors] = useState({})
    const [nameBeforeEdit, setNameBeforeEdit] = useState('')

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleStartChangeName = () => {
        setNameBeforeEdit(data.name)
    }

    const handleChangeName = (e) => {
        setData({...data, name: e.target.value})
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            if (data.name) {
                setNameBeforeEdit('')
            } else {
                setData({...data, name: nameBeforeEdit})
            }
        }
    }

    const handleChange = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectCommunity = (_, community) => {
        setData({...data, community})
    }

    const handleDiscard = () => {
        navigate('/transport/stops?page=1')
    }

    const handleSubmit = () => {
        const payload = {
            name: data.name,
            community: data.community,
            duration: data.duration,
            distance: data.distance,
            locationOfStop: data.locationOfStop,
            coordinates: data.coordinates
        }

        const errors = checkGroupDetails(payload, data)

        if (Object.keys(errors).length === 0) {
            dispatch(editTransportStop(stop._id, payload))
            navigate('/transport/stops?page=1')
        } else {
            setValidationErrors(errors)
        }
    }

    const handleFocus = (field) => {
        setValidationErrors(prevErrors => ({
            ...prevErrors,
            [field]: ""
        }))
    }

    useEffect(() => {
        stop
            &&
            setData({
                ...stop,
                coordinates: `${stop.coordinates.lat}, ${stop.coordinates.long}`,
            })
    }, [stop])

    return (
        <div>
            <Container>
                <div className={'flex justify-center mb-[50px]'}>
                    {nameBeforeEdit ?
                        <input
                            name="name"
                            className={'w-full text-center text-[50px] text-[#FCC10F] font-bold outline-none'}
                            autoComplete={'off'}
                            autoFocus
                            onFocus={e => e.target.select()}
                            value={data.name}
                            onChange={handleChangeName}
                            onKeyDown={handleEnter}
                        />
                        :
                        <h2 onClick={handleStartChangeName}
                            className={'text-center text-[50px] text-[#FCC10F] font-bold break-all'}>{data?.name}</h2>
                    }
                </div>
                <div className={'grid lg:grid-cols-2 grid-cols-1 gap-[30px]'}>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Համայնք <AsteriskIcon/></h2>
                            <DropdownInputCheckbox data={communities} name={'community'} placeholder={'Համայնք'}
                                                   onChange={handleSelectCommunity}
                                                   previewClassName={'rounded-[5px] py-[7px] px-[30px]'}
                                                   optionClassName={'px-[30px]'}
                                                   hideCheckbox
                                                   selected={[data?.community]}
                                                   onFocus={() => handleFocus('community')}
                                                   disableInput={true}
                            />
                            {validationErrors.community &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.community}</p>}
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կանգառի տեղանք <AsteriskIcon/></h2>
                            <input
                                type={"text"}
                                value={data?.locationOfStop}
                                name={'locationOfStop'}
                                placeholder={'Օրինակ՝ Կանաչ դարպասի մոտ '}
                                onChange={handleChange}
                                onFocus={() => handleFocus('locationOfStop')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.locationOfStop &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.locationOfStop}</p>}
                        </div>

                        <div>
                            <h2 className={'flex gap-1 mb-[10px]'}>Կոորդինատներ <AsteriskIcon/></h2>
                            <input
                                type={"text"}
                                value={`${data?.coordinates}`}
                                name={'coordinates'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('coordinates')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                                placeholder={'Օրինակ՝ 20.2, 23'}
                            />
                            {validationErrors.coordinates &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.coordinates}</p>}
                        </div>
                    </div>
                    <div className={'flex flex-col gap-[30px]'}>
                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>Հեռավորությունը SMART կենրոնից (կմ) <AsteriskIcon/>
                            </h2>
                            <input
                                type={"number"}
                                value={data?.distance}
                                name={'distance'}
                                placeholder={'Օրինակ՝ 1000'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('distance')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.distance &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.distance}</p>}
                        </div>

                        <div className={'relative'}>
                            <h2 className={'flex gap-1 mb-[10px]'}>Ժամանակահատվածը մինչև SMART կենտրոն
                                (րոպե) <AsteriskIcon/></h2>
                            <input
                                type={"number"}
                                value={data?.duration}
                                name={'duration'}
                                placeholder={'Օրինակ՝ 120'}
                                onChange={handleChange}
                                onFocus={() => handleFocus('duration')}
                                className={'w-full border border-black border-opacity-70 outline-none rounded-[5px] py-[7px] px-[30px]'}
                            />
                            {validationErrors.duration &&
                                <p className={"text-red-500 mt-2"}>{validationErrors.duration}</p>}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={'flex items-center justify-end'}>
                        <div>
                            <div className={'w-fit flex gap-[30px] items-center mt-[100px]'}>
                                <ButtonOutlined
                                    onClick={handleDiscard}
                                    text={'Չեղարկել'}
                                    className={'w-[176px]'}
                                />
                                <ButtonFilled
                                    onClick={handleSubmit}
                                    text={'Պահպանել'}
                                    className={'w-[176px]'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default EditStop;
