import React from 'react';

const VideoPreviewModal = ({close, video}) => {
    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div onClick={close}
                 className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>
            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[879px] z-[9999] pt-[30px] pb-[50px] rounded-[10px]'}>
                <video src={video} controls className={'rounded-[5px]'} autoPlay></video>
            </div>
        </div>
    )
};

export default VideoPreviewModal;