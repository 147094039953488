import React, { useEffect, useState } from 'react';
import Container from "../components/shared/Container";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRequestForm } from "../store/actions/forms";
import FormView from "../components/forms/form/FormView";
import { AsteriskIcon } from "../assets/icons";
import FormSubmitted from "../components/forms/form/FormSubmitted";
import ClosedForm from "./main/forms/ClosedForm";

const FillFormRequest = () => {
    const [isLoading, setIsLoading] = useState(true)
    const { form, submitted, loading } = useSelector(state => state.forms)
    const { id } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        if (id) {
            dispatch(getRequestForm(id))
                .finally(() => setIsLoading(false))
        }
    }, [id, dispatch])

    if (isLoading) {
        return null
    }

    return (
        <div>
            <Container className={`lg:pt-[47px] lg:pb-24`}>
                {
                    submitted
                        ?
                        <FormSubmitted successMessage={form?.successMessage} />
                        :
                        form
                            ?
                            <div
                                className={'max-w-[1254px] flex justify-center border-0 lg:border lg:rounded-[10px] lg:shadow-md lg:p-4'}>
                                <div className={'max-w-[1054px] w-full'}>
                                    <h2 className={'text-[20px] lg:text-[28px] font-bold text-center text-[#FCC10F] mt-[14px]'}>{form?.name}</h2>
                                    <p className={'text-[14px] lg:text-[28px] my-5 lg:mt-[50px] lg:mb-[30px] break-all'}>{form?.description}</p>
                                    <div className={'flex items-center gap-4 text-[#FF0707] mb-5'}>
                                        <AsteriskIcon width={12} height={12} /><span className={'text-[12px] lg:text-[16px]'}>դաշտերը պարտադիր լրացման են</span>
                                    </div>
                                    <FormView form={form} disableSubmit={loading} />
                                </div>
                            </div>
                            :
                            <ClosedForm />
                }
            </Container>
        </div>
    )
}

export default FillFormRequest
