import React from 'react';
import dashboardImg from "../../assets/images/dashboardimg.png";

const Dashboard = () => {
    return (
        <div>
            <div className={'flex justify-center py-[50px]'}><img src={dashboardImg} className={'w-[952px]'} alt={'df'}/></div>
        </div>
    );
};

export default Dashboard;