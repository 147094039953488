import React, {useEffect, useRef, useState} from 'react';
import {AsteriskIcon, CameraIcon, CloseIcon} from "../../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../../shared/buttons";
import {useDispatch} from "react-redux";
import {createDivision, editDivision} from "../../../store/actions/profile";

const CreateDivisionModal = ({close, divisionData}) => {
    const [uploadedImage, setUploadedImage] = useState(null)
    const [name, setName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [address, setAddress] = useState('')

    const editMode = !!divisionData

    const [errors, setErrors] = useState({})

    const dispatch = useDispatch()

    const imageUploadRef = useRef(null)

    const handleImageUpload = (e) => {
        const selectedImage = e.target.files[0]

        if (selectedImage) {
            setUploadedImage(selectedImage)
        }
    }

    const handleCreateDivision = () => {
        const newErrors = {}
        if (!name) newErrors.name = "Այս դաշտը պարտադիր է"
        if (!phoneNumber) newErrors.phoneNumber = "Այս դաշտը պարտադիր է"
        if (!email) newErrors.email = "Այս դաշտը պարտադիր է"
        if (!address) newErrors.address = "Այս դաշտը պարտադիր է"
        if (!uploadedImage && !editMode) newErrors.uploadedImage = "Այս դաշտը պարտադիր է"

        if (Object.keys(newErrors).length === 0) {
            const formData = new FormData()
            formData.append('name', name)
            formData.append('phoneNumber', phoneNumber)
            formData.append('email', email)
            formData.append('address', address)
            if (uploadedImage) {
                formData.append('image', uploadedImage)
            }

            if (editMode) {
                dispatch(editDivision(divisionData._id, formData))
            } else {
                dispatch(createDivision(formData))
            }

            setName('')
            setPhoneNumber('')
            setEmail('')
            setAddress('')
            setUploadedImage(null)
            close()
        } else {
            setErrors(newErrors)
        }
    }

    useEffect(() => {
        setErrors(prevErrors => ({
            ...prevErrors,
            uploadedImage: uploadedImage ? "" : prevErrors?.uploadedImage,
            name: name ? "" : prevErrors?.name,
            phoneNumber: phoneNumber ? "" : prevErrors?.phoneNumber,
            email: email ? "" : prevErrors?.email,
            address: address ? "" : prevErrors?.address,
        }))
    }, [uploadedImage, name, phoneNumber, email, address])

    useEffect(() => {
        if (editMode && divisionData) {
            setName(divisionData.name || '')
            setPhoneNumber(divisionData.phoneNumber || '')
            setEmail(divisionData.email || '')
            setAddress(divisionData.address || '')
        }
    }, [editMode, divisionData])

    return (
        <div>
            <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
                <div onClick={close}
                     className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>
                <div
                    className={'grid grid-rows-[60px,auto] w-full max-w-[879px] max-h-[510px] overflow-hidden z-[9999] border border-black rounded-[10px] bg-white'}>
                    <div className={'relative flex items-center justify-center'}>
                        <h2 className={'text-center'}>Նոր դիվիժն</h2>

                        <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'}
                                onClick={close}>
                            <CloseIcon width={20} height={20}/>
                        </button>
                    </div>

                    <div className={'flex gap-[30px] mx-[30px] h-[calc(100vh-405px)] overflow-scroll'}>
                        <div className={'flex flex-col gap-3'}>
                            <div
                                onClick={() => imageUploadRef.current.click()}
                                className={`w-[200px] h-[200px] flex items-center justify-center ${uploadedImage || divisionData ? 'border-0' : 'border-[7px] border-[#FCC10F]'} rounded-full cursor-pointer`}>
                                <input type={"file"} accept={'image/*'} className={'hidden'} id={"upload_image"}
                                       ref={imageUploadRef} onChange={handleImageUpload}/>
                                {uploadedImage ?
                                    <img
                                        src={URL.createObjectURL(uploadedImage)} alt="uploaded"
                                        className="max-w-[200px] h-full object-cover"/>
                                    :
                                    divisionData && divisionData.image
                                        ?
                                        <img
                                            src={divisionData.image} alt="uploaded"
                                            className="max-w-[200px] h-full object-cover"/>
                                        :
                                        <CameraIcon color={'#FCC10F'} height={100} width={100}/>
                                }
                            </div>
                            {errors?.uploadedImage && <p className={'text-red-500'}>{errors.uploadedImage}</p>}
                        </div>
                        <div className={`flex flex-col w-[548px] gap-5`}>
                            <p className={'flex items-center gap-[15px] text-[#FF0707]'}>
                                <AsteriskIcon/>
                                <span>դաշտերը պարտադիր լրացման են</span>
                            </p>

                            <div className={'flex flex-col gap-2.5'}>
                                <p className={'flex gap-1'}>
                                    <span>Անուն</span>
                                    <AsteriskIcon/>
                                </p>
                                <input
                                    type={"text"}
                                    className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                />
                                {errors?.name && <p className={'text-red-500'}>{errors.name}</p>}
                            </div>

                            <div className={'flex flex-col gap-2.5'}>
                                <p className={'flex gap-1'}>
                                    <span>Հեռախոսահամար </span>
                                    <AsteriskIcon/>
                                </p>
                                <input
                                    type={"tel"}
                                    className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                                    value={phoneNumber}
                                    onChange={e => {
                                        const formattedValue = e.target.value.replace(/\D/g, '')
                                        setPhoneNumber('+' + formattedValue)
                                    }}
                                />
                                {errors?.phoneNumber && <p className={'text-red-500'}>{errors.phoneNumber}</p>}
                            </div>
                            <div className={'flex flex-col gap-2.5'}>
                                <p className={'flex gap-1'}>
                                    <span>Էլետրոնային փոստ </span>
                                    <AsteriskIcon/>
                                </p>
                                <input
                                    type={"email"}
                                    className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />
                                {errors?.email && <p className={'text-red-500'}>{errors.email}</p>}
                            </div>

                            <div className={'flex flex-col gap-2.5'}>
                                <p className={'flex gap-1'}>
                                    <span>Հասցե </span>
                                    <AsteriskIcon/>
                                </p>
                                <input
                                    type={"text"}
                                    className={'w-full h-[30px] border border-black border-opacity-70 rounded-[3px] outline-none px-[30px]'}
                                    value={address}
                                    onChange={e => setAddress(e.target.value)}
                                />
                                {errors?.address && <p className={'text-red-500'}>{errors.address}</p>}
                            </div>

                            <div
                                className={`flex items-center justify-end gap-[30px] ${!errors && 'mt-[10px]'} pb-[50px]`}>
                                <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                                <ButtonFilled onClick={handleCreateDivision} text={editMode ? 'Պահպանել' : 'Ստեղծել'}
                                              className={'w-[176px] shadow-md'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateDivisionModal;