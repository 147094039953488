import React, {useState} from 'react';
import EventsFilter from "../../../../components/smartEvents/EventsFilter";
import {useFilters} from "../../../../hooks/useFilters";
import {deleteGroup, getWorkshops} from "../../../../store/actions/groups";
import AddButton from "../../../../components/shared/buttons/AddButton";
import {useNavigate} from "react-router-dom";
import {Container} from "../../../../components/shared";
import {useDispatch, useSelector} from "react-redux";
import {ScrollToTopIcon} from "../../../../assets/icons";
import Pagination from "../../../../components/shared/Pagination";
import PageSizeDropdown from "../../../../components/shared/dropdowns/PageSizeDropdown";
import EventCard from "../../../../components/smartEvents/EventCard";
import {roles} from "../../../../common/roles";

const initialFilters = {
    name: '', page: 1, size: 9
}

const Workshops = () => {
    const {
        filters,
        handleChange,
        changeSelectedFilters,
        discardFilters,
        changePage,
        submitFilters,
        changeCustomPage,
        setCustomPage,
        handleChangeSize,
        setFilters
    } = useFilters(initialFilters, getWorkshops, 'workshops')

    const {groups, loading} = useSelector(state => state.groups)
    const {authData} = useSelector(state => state.auth)

    const [workshopToDelete, setWorkshopToDelete] = useState(null)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleDeleteWorkshop = () => {
        dispatch(deleteGroup(workshopToDelete))
        setWorkshopToDelete(null)
    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    return (
        <div>
            <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center my-[30px]'}>ՍՄԱՐԹ աշխատարան</h2>
            <EventsFilter
                filters={filters}
                setFilters={setFilters}
                discardFilters={discardFilters}
                submitFilters={submitFilters}
                handleChange={handleChange}
                changeSelectedFilters={changeSelectedFilters}
                placeholder={'ՍՄԱՐԹ աշխատարան'}
            />

            <Container>
                {roles[authData?.role]?.some(role => role.allowCreateNewEvent) &&
                    <AddButton text={'Նոր'} onClick={() => navigate('/workshops/create')}/>
                }
                {
                    loading
                        ?
                        <p className={'text-center mt-[30px]'}>Բեռնվում է...</p>
                        :
                        groups?.data?.length > 0
                            ?
                            <>
                                <div
                                    className={'relative grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 place-items-stretch gap-[32px] mt-[30px]'}>
                                    {groups?.data?.map((workshop, index) => {
                                        const isLast = groups?.data.length === index + 1

                                        return (
                                            <div key={index}>
                                                <EventCard
                                                    {...workshop}
                                                    workshopToDelete={workshopToDelete}
                                                    setWorkshopToDelete={setWorkshopToDelete}
                                                    handleDeleteWorkshop={handleDeleteWorkshop}
                                                    type={'workshop'}
                                                />
                                                {isLast &&
                                                    <button
                                                        className={'absolute -right-16'}
                                                        onClick={scrollToTop}>
                                                        <ScrollToTopIcon/>
                                                    </button>}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className={'flex items-center justify-center mt-[40px]'}>
                                    {filters?.page && <Pagination count={groups?.pagesCount} onChange={changePage}
                                                                  page={parseInt(filters?.page)}/>}
                                    <div className={'flex items-center justify-center gap-2.5'}>
                                        <span>Էջ</span>
                                        <input type="number" onKeyDown={changeCustomPage}
                                               onChange={e => setCustomPage(e.target.value)}
                                               className={'max-w-[50px] outline-none px-2 py-0.5 text-center border border-black rounded-[5px]'}/>
                                    </div>
                                    <div className={'ml-[30px]'}>
                                        <PageSizeDropdown onChange={handleChangeSize} value={filters?.size}/>
                                    </div>
                                </div>
                            </>
                            :
                            <p className={'text-center mt-[30px]'}>Աշխատարաններ չկան</p>}
            </Container>
        </div>
    );
};

export default Workshops;