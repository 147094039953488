import React, {useEffect, useState} from 'react';
import { Container } from "../../../components/shared";
import AddButton from "../../../components/shared/buttons/AddButton";
import { DropdownInputCheckbox } from "../../../components/shared/dropdowns";
import { communitySortOptions } from "../../../common/dropdownOptions";
import CommunityItem from "../../../components/communities/CommunityItem";
import {useDispatch, useSelector} from "react-redux";
import {deleteCommunity, getCommunities} from "../../../store/actions/communities";
import {useLocation, useNavigate} from "react-router-dom";
import {parseQueryParams} from "../../../utils/convertQueryFilters";
import CreateCommunityModal from "../../../components/communities/CreateCommunityModal";
import {roles} from "../../../common/roles";

const Communities = () => {
    const {communities} = useSelector(state => state.communities)
    const {authData} = useSelector(state => state.auth)

    const [sortOption, setSortOption] = useState(communitySortOptions[0])
    const [createCommunityModal, setCreateCommunityModal] = useState(null)
    const [communityToDelete, setCommunityToDelete] = useState(null)

    const dispatch = useDispatch()

    const navigate = useNavigate()
    const location = useLocation()

    const handleChangeSortOption = (name, option) => {
        navigate(`/communities?sortOption=${option._id}`)
    }

    const handleDeleteCommunity = () => {
        dispatch(deleteCommunity(communityToDelete))
        setCommunityToDelete(null)
    }

    useEffect(() => {
        const {sortOption: sortParam} = parseQueryParams(window.location.href)

        setSortOption(communitySortOptions.find(item => item._id === sortParam))

        dispatch(getCommunities(location.search))
    }, [location])

    return (
        <div>
            {createCommunityModal &&
                <CreateCommunityModal
                    close={() => setCreateCommunityModal(null)}
                    sortOptions={location.search}
                />
            }
            <Container>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold text-center'}>Համայնքներ</h2>

                <div className={'flex items-center justify-between'}>
                    {roles[authData?.role]?.some(role => role.allowCancelLesson)
                        ?
                        <AddButton text={'Նոր համայնք'} onClick={() => setCreateCommunityModal(true)}/>
                        :
                        <div></div>
                    }
                    <div className={'flex items-center gap-5 mt-[14px]'}>
                        <p className={'text-[12px]'}>Դասակարգել</p>
                        <div className={'w-[518px]'}>
                            <DropdownInputCheckbox
                                data={communitySortOptions}
                                name={'community'}
                                onChange={handleChangeSortOption}
                                selected={[sortOption]}
                                previewClassName={'py-2.5 rounded-[5px] px-[30px] text-[12px]'}
                                optionClassName={'px-[30px]'}
                                textSize={'text-[12px]'}
                                hideCheckbox
                                disableInput
                            />
                        </div>
                    </div>
                </div>

                <div className={'grid xl:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-x-[33px]'}>
                    {communities.map((community, index) =>
                        <CommunityItem
                            key={index}
                            {...community}
                            sortOption={location.search}
                            communityToDelete={communityToDelete}
                            setCommunityToDelete={setCommunityToDelete}
                            handleDeleteCommunity={handleDeleteCommunity}
                        />)}
                </div>
            </Container>
        </div>
    );
};

export default Communities
