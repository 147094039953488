export const formatDate = (createdAt) => {
    const date = new Date(createdAt)
    const now = new Date()

    const isToday = date.toDateString() === now.toDateString()

    if (isToday) {
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
    } else {
        return date.toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' })
    }
}