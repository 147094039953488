import React, {useRef, useState} from 'react';
import {AsteriskIcon, CloseIcon, FileUploadIcon} from "../../../../../assets/icons";
import {ButtonFilled, ButtonOutlined} from "../../../../shared/buttons";
import pdfIcon from "../../../../../assets/images/pdf.png";
import {createStudentFile} from "../../../../../store/actions/students";

const CreateNewFileModal = ({close, submit}) => {
    const [file, setFile] = useState(null)
    const [name, setName] = useState('')

    const fileInputRef = useRef(null)

    const handleFileUploadClick = () => {
        fileInputRef.current.click()
    }

    const handleSubmit = () => {
        submit(file, name)
    }

    const handleFileChange = (event) => {
        const uploadedFile = event.target.files[0];
        if (uploadedFile && uploadedFile.type === "application/pdf") {
            setFile(uploadedFile)
        } else {
            alert("Please upload a PDF file.")
        }
    }

    const handleFileRemove = () => {
        setFile(null)
        fileInputRef.current.value = null
    }

    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 flex items-center justify-center'}>
            <div className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>
            <div
                className={'absolute grid grid-rows-[auto,1fr,auto] w-full max-w-[879px] max-h-[520px] z-[9999] pt-[30px] pb-[50px] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center'}>
                    <h2 className={'text-center'}>Նոր ֆայլ</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>

                <div className={'flex gap-[30px] px-[30px] mt-5'}>
                    <div className={'flex flex-col gap-2.5'}>
                        <div className={'w-[200px] h-[200px] cursor-pointer'} onClick={handleFileUploadClick}>
                            {file
                                ?
                                <img src={pdfIcon} alt="PDF Icon" className="w-full h-full object-cover"/>
                                :
                                <FileUploadIcon/>
                            }
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{display: 'none'}}
                            accept="application/pdf"
                            onChange={handleFileChange}
                        />
                        <p className={'text-black text-opacity-70'}>Ընդունելի ձևաչափ՝ PDF</p>
                        {file &&
                            <p className={"flex items-center gap-5 text-black text-opacity-70 max-w-[208px] break-all"}>
                                <span>{file.name}</span>
                                <span onClick={handleFileRemove} className="cursor-pointer">
                                    <CloseIcon width={15} height={15}/>
                                </span>
                            </p>}
                    </div>
                    <div className={'flex flex-col gap-5 w-[548px]'}>
                        <p className={'flex items-center gap-[15px] text-[#FF0707]'}><AsteriskIcon/> դաշտերը պարտադիր
                            լրացման են</p>
                        <div className={'flex flex-col gap-[10px]'}>
                            <p className={'flex gap-1'}>Փաստաթղթի անունը <AsteriskIcon/></p>
                            <input
                                type="text"
                                className={'w-full h-[30px] border border-black border-opacity-70 py-1 px-[30px] rounded-[3px] outline-none'}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className={'flex items-center justify-end gap-[30px] mt-[110px]'}>
                            <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                            <ButtonFilled onClick={handleSubmit} text={'Պահպանել'} className={'w-[176px] shadow-md'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateNewFileModal;
