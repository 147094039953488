import moment from "moment";

export const countStudentAttendanceInLevel = (level) => {
    const students = {}

    level?.forEach((lesson) => {
        for (const studentId in lesson[1].students) {
            const lessonDate = moment(lesson[0], 'YYYY-MM-DD')

            if (!students[studentId]) { // If there is no key of student id specified yet in data to return
                students[studentId] = {present: 0, absent: 0}
            }

            if(lessonDate.isBefore(moment()) && !lesson[1].canceled) {
                if (lesson[1].students[studentId]) { // Meaning that the value is not null
                    const {present} = lesson[1].students[studentId]

                    if (present === true) {
                        students[studentId] = {...students[studentId], present: students[studentId].present + 1}
                    } else if(present === false) {
                        students[studentId] = {...students[studentId], absent: students[studentId].absent + 1}
                    }
                }
            }
        }
    })

    return students
}