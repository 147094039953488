import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getIntro} from "../../../../store/actions/groups";
import {useParams} from "react-router-dom";
import {Container} from "../../../../components/shared";
import IntroHeader from "../../../../components/intros/intro/IntroHeader";
import IntroInfo from "../../../../components/intros/intro/IntroInfo";
import {PlusButton} from "../../../../components/shared/buttons";
import SwitchButton from "../../../../components/shared/SwitchButton";
import ButtonHoverText from "../../../../components/shared/buttons/ButtonHoverText";
import AddStudentModal from "../../../../components/groups/group/modals/AddStudent";
import Attendance from "../../groups/group/attendance/Attendance";
import {roles} from "../../../../common/roles";

const Intro = () => {
    const {group, loading} = useSelector(state => state.groups)
    const {authData} = useSelector(state => state.auth)

    const [openAddStudentModal, setOpenAddStudentModal] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const [showDroppedStudents, setShowDroppedStudents] = useState(() => {
        const savedState = localStorage.getItem('showDroppedStudents')
        return savedState !== null ? JSON.parse(savedState) : false
    })

    const changeShowDroppedStudents = () => {
        const newState = !showDroppedStudents
        setShowDroppedStudents(newState)
        localStorage.setItem('showDroppedStudents', JSON.stringify(newState))
    }

    useEffect(() => {
        dispatch(getIntro(id))
    }, [id])

    useEffect(() => {
        openAddStudentModal ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
    }, [openAddStudentModal])

    return (
        <div>
            {openAddStudentModal &&
                <AddStudentModal
                    close={() => setOpenAddStudentModal(false)}
                    type={'intro'}
                />
            }

            <Container className={'p-0'}>
                <div className={'fixed top-[7%] max-w-[1280px] w-full z-[9990] bg-white pt-[30px]'}>
                    <IntroHeader {...group} />
                    <IntroInfo {...group} />
                </div>

                <div className={'flex items-center justify-between mt-[180px]'}>
                    {roles[authData?.role]?.some(role => role.allowAddStudentInGroup)
                        ?
                        <PlusButton
                            onClick={() => setOpenAddStudentModal(true)}
                            className={'p-2.5'}
                            width={10}
                            height={10}
                            styleClassName={'hover:bg-white transition border border-[#FCC10F]'}
                            hoverPlusColor={'#FCC10F'}
                        />
                        :
                        <div></div>
                    }

                    <ButtonHoverText
                        icon={<SwitchButton checked={showDroppedStudents} handleChange={changeShowDroppedStudents}/>}
                        text={'Ցույց տալ դուրս մնացած մասնակիցներին'}
                    />
                </div>

                <Attendance {...group} showDroppedStudents={showDroppedStudents}/>
            </Container>
        </div>
    );
};

export default Intro;