import React, {useEffect, useState} from 'react';
import {ProfileData, ProfileInfo} from "../../../components/students/profile";
import {DownloadIcon} from "../../../assets/icons";
import {useDispatch, useSelector} from "react-redux";
import {ButtonFilled, ButtonOutlined} from "../../../components/shared/buttons";
import {editStudent} from "../../../store/actions/students";
import {useLocation, useNavigate, useParams} from "react-router-dom";

const StudentProfile = () => {
    const { id } = useParams();
    const {student} = useSelector(state => state.students);
    const [studentData, setStudentData] = useState(student || {});
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryParams = new URLSearchParams(location.search)
    const editMode = queryParams.get('mode') === 'edit'

    const discardEdit = () => {
        setStudentData(student)
        navigate(`/students?page=1&size=10&`)
    }

    const changeMultipleChoiceField = (name, selected) => {
        const checked = studentData[name]?.some(item => item._id === selected._id);

        setStudentData({
            ...studentData,
            [name]: checked ? studentData[name].filter(item => item._id !== selected._id) : [...studentData[name], selected]
        })
    }

    const handleChangeField = (e) => {
        setStudentData({ ...studentData, [e.target.name]: e.target.value });
    }

    const handleChangeCommunityField = (_, selectedCommunity) => {
        setStudentData({
            ...studentData,
            community: selectedCommunity,
        })
    }

    const handelChangeStopName = (_, selectedStop) => {
        setStudentData({
            ...studentData,
            transportStop: selectedStop,
        })
    }

    const handleChangeBirthday = (newBirthday) => {
        setStudentData({ ...studentData, birthday: newBirthday })
    }

    const changeClothingSize = (_, newClothingSize) => {
        if (newClothingSize && newClothingSize._id) {
            setStudentData({ ...studentData, clothingSize: newClothingSize._id })
        }
    }

    const handleAddAward = (awards) => {
        setStudentData(prevStudentData => ({
            ...prevStudentData,
            smartAwards: awards
        }));
    };

    const handleChangePhoneNumber = (index, value) => {
        const allowedCharset = '+1234567890';

        if (value.length > 12) {
            value = value.slice(0, 12);
        }
        const lastSymbol = value.charAt(value.length - 1);

        if (allowedCharset.includes(lastSymbol)) {
            setStudentData({
                ...studentData,
                phoneNumbers: studentData.phoneNumbers.map((item, i) => i === index ? value : item)
            });
        }
    }

    const handleChangeParentName = (index, value) => {
        setStudentData(prevStudentData => {
            const updatedParentsFullNames = [...prevStudentData.parentsFullNames]
            updatedParentsFullNames[index] = value
            return { ...prevStudentData, parentsFullNames: updatedParentsFullNames }
        })
    }

    const handleSubmitChanges = () => {
        let payload = {};

        for (const key in studentData) {
            switch (key) {
                case 'community':
                case 'formWeek':
                    payload[key] = studentData[key]?._id
                    break
                case 'matriculationYear':
                    payload[key] = parseInt(studentData[key])
                    break
                default:
                    payload[key] = studentData[key]
                    break
            }
        }

        dispatch(editStudent(studentData._id, payload, navigate))
    }

    useEffect(() => {
        if (student) {
            setStudentData(student)
        }
    }, [student])

    if (!student) {
        return <div>Loading...</div>
    }

    return (
        <>
            <div className={'grid grid-cols-[356px,926px] mt-[27px] pb-[30px]'}>
                <ProfileInfo
                    {...studentData}
                    editMode={editMode}
                    enterEditMode={() => navigate(`?mode=edit&subpage=profile`)}
                    changeField={handleChangeField}
                    changePhoneNumber={handleChangePhoneNumber}
                    changeParentName={handleChangeParentName}
                />

                <ProfileData
                    {...studentData}
                    editMode={editMode}
                    enterEditMode={() => navigate(`?mode=edit&subpage=profile`)}
                    changeField={handleChangeField}
                    changeMultipleChoiceField={changeMultipleChoiceField}
                    changePhoneNumber={handleChangePhoneNumber}
                    changeClothingSize={changeClothingSize}
                    changeBirthdayDate={handleChangeBirthday}
                    addAward={handleAddAward}
                    changeCommunityField={handleChangeCommunityField}
                    changeTransportStopName={handelChangeStopName}
                    awards={student.smartAwards}
                />
            </div>

            <div className={'mb-[84px]'}>
                {editMode ?
                    <div className={'flex items-center justify-end gap-[30px]'}>
                        <ButtonOutlined onClick={discardEdit} text={'Չեղարկել'} className={'w-[176px]'} />
                        <ButtonFilled onClick={handleSubmitChanges} text={'Պահպանել'} className={'w-[176px]'} />
                    </div>
                    :
                    <button
                        className={'flex items-center gap-5 border border-[#FCC10F] text-[#FCC10F] py-[5px] px-[10px] rounded-[3px]'}
                        onClick={() => navigate(`?mode=edit&subpage=profile`)}
                    >
                        <span>Արտահանել PDF</span>
                        <DownloadIcon />
                    </button>
                }
            </div>
        </>
    );
};

export default StudentProfile;