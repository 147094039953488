export const checkGroupDetails = (payload, data) => {
    const errors = {}

    for (const key in data) {
        switch (key) {
            case 'program':
                if (data[key]?._id) {
                    payload[key] = data[key]._id
                } else {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'instructors':
                const instructors = data[key].map(item => item._id)
                if (instructors.length > 0) {
                    payload[key] = instructors
                } else {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'lessonsCount':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'levelsCount':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'lessonDuration':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'startDate':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'schedule':
                if (data[key].length === 0) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'ageGroup':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'place':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'startTime':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'community':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'distance':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'coordinates':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'duration':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'region':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'routeStart':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'routeEnd':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'durationToEndPoint':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'routeDuration':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'mark':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'color':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'seatingCapacity':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'plate':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'fullName':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'birthday':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'registrationAddress':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'address':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'email':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'passport':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'phoneModel':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'driverLicense':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'phoneNumber1':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            case 'vehicle1':
                if (!data[key]) {
                    errors[key] = "Այս դաշտը պարտադիր է"
                }
                break
            default:
                payload[key] = data[key]
        }
    }

    return errors
}