import React, {useEffect, useRef, useState} from 'react';
import EmojiPicker from 'emoji-picker-react';
import {EmojiIcon, MediaIcon, SendMessageIcon, TrashIcon, VoiceMessageIcon} from "../../../assets/icons";
import {useOutsideClick} from "../../../hooks/useOutsideClick";
import UploadFilesModal from "../modals/UploadFilesModal";
import {editSelectedMessage, sendFileAsVoiceMessage, sendMessage} from "../../../socket/chats";
import {useDispatch, useSelector} from "react-redux";
import ReplyToMessage from "./items/ReplyToMessage";
import {useVoiceVisualizer, VoiceVisualizer} from "react-voice-visualizer";
import {useMediaQuery} from "react-responsive";
import EditMessage from "./items/EditMessage";
import message from "./message/Message";

const MessageTypingArea = ({
                               id,
                               replyToMessage,
                               replyingTo,
                               setReplyToMessage,
                               setReplyingTo,
                               onHeightChange,
                               typingAreaHeight,
                               isEditingMessage,
                               userCanSendMessage,
                               setEditMessage,
                               setIsEditingMessage,
                               openChatMediaViewer,
                               editMessage,
                               isMediaOpen,
                           }) => {
    const {socket} = useSelector(state => state.socket);
    const {chatRoom} = useSelector(state => state.chats);

    const [content, setContent] = useState('');
    const [showPicker, setShowPicker] = useState(false);
    const [showMediaPick, setShowMediaPick] = useState(null);
    const [openFileUploadModal, setOpenFileUploadModal] = useState(null);
    const [fileType, setFileType] = useState('');
    const [isRecording, setIsRecording] = useState(false);

    const ref = useRef(null);
    const pickerRef = useRef(null);
    const containerRef = useRef(null);
    const textareaRef = useRef(null);

    useOutsideClick(pickerRef, () => setShowPicker(false));
    useOutsideClick(ref, () => setShowMediaPick(false));

    const isPhone = useMediaQuery({maxWidth: 640})

    // Voice message logic
    const recorderControls = useVoiceVisualizer();
    const {
        startRecording, // New function to start recording
        stopRecording, // New function to stop recording
        recordedBlob,
        error,
        audioRef,
    } = recorderControls;

    const onEmojiClick = (event, emojiObject) => {
        setContent(prevContent => prevContent + event.emoji);
    }

    const dispatch = useDispatch()

    useEffect(() => {
        if (isEditingMessage && editMessage?.message) {
            setContent(editMessage.message)
        }
    }, [isEditingMessage, editMessage])

    useEffect(() => {
        if (!recordedBlob) return
        console.log(recordedBlob)
    }, [recordedBlob, error])

    useEffect(() => {
        if (!error) return
        console.log(error)
    }, [error])

    const handleOpenFileUploadModal = (type) => {
        setOpenFileUploadModal(true)
        setFileType(type)
    }

    const handleSendMessage = () => {
        if (content.trim()) {
            if (isEditingMessage) {
                editSelectedMessage(socket, editMessage._id, content, dispatch)
                setIsEditingMessage(false)
            } else {
                sendMessage(socket, content, chatRoom, replyingTo?._id)
            }

            setReplyToMessage(null)
            setReplyingTo(null)
            setContent('')
            if (isPhone) {
                textareaRef.current.style.height = '30px'
            } else {
                textareaRef.current.style.height = '60px'
            }
            onHeightChange(40)
        } else {
            sendFileAsVoiceMessage(socket, chatRoom, replyingTo, 'a', recordedBlob)
            setIsRecording(false)
        }
    }

    const handleInputChange = (e) => {
        setContent(e.target.value);
        textareaRef.current.style.height = 'auto';

        const newHeight = Math.min(textareaRef.current.scrollHeight, 320);
        textareaRef.current.style.height = `${newHeight}px`;

        onHeightChange(newHeight);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage();
        }
    }

    useEffect(() => {
        const initialHeight = Math.min(textareaRef.current.scrollHeight, 320);
        textareaRef.current.style.height = `${initialHeight}px`;
        onHeightChange(initialHeight);
    }, []);

    const handleVoiceMessageClick = () => {
        if (isRecording) {
            stopRecording();
        } else {
            startRecording();
        }
        setIsRecording(!isRecording);
    }

    return (
        <>
            {(openFileUploadModal && userCanSendMessage) &&
                <UploadFilesModal
                    title={fileType === 'media' ? 'Վերբեռնել նկար/ հոլովակ' : 'Վերբեռնել ֆայլ'}
                    fileType={fileType}
                    socket={socket}
                    chatId={id}
                    chatRoom={chatRoom}
                    replyingId={replyingTo?._id}
                    close={() => setOpenFileUploadModal(null)}
                />
            }
            {replyingTo &&
                <ReplyToMessage {...replyingTo} setReplyToMessage={setReplyToMessage} setReplyingTo={setReplyingTo}/>
            }

            {isEditingMessage &&
                <EditMessage {...editMessage} setEditMessage={setEditMessage}
                             setIsEditingToMessage={setIsEditingMessage} setContent={setContent}/>
            }
            <div
                className={`${!replyToMessage && !isEditingMessage && 'min-h-[60px] lg:min-h-[90px] pt-2 lg:pt-8 pb-5'} relative flex ${typingAreaHeight > 70 ? 'items-end' : 'items-center'} justify-between px-5 ${openChatMediaViewer && isPhone && 'hidden'} ${isPhone && isMediaOpen && 'hidden'}`}
            >
                <div className="relative flex items-center gap-2.5 lg:gap-5 lg:pr-5">
                    {!isRecording &&
                        <button
                            onClick={() => setShowMediaPick(true)}
                            className={userCanSendMessage && 'fill-icon'}
                            disabled={!userCanSendMessage}
                        >
                            <MediaIcon color={!userCanSendMessage && '#D0CFCF'} width={isPhone && 17}
                                       height={isPhone && 17}/>
                        </button>
                    }
                    {(showMediaPick && userCanSendMessage) &&
                        <div
                            ref={ref}
                            className={`${!userCanSendMessage && 'pointer-events-none'} absolute z-50 w-[239px] lg:w-[272px] bottom-full mb-2.5 flex flex-col gap-4 bg-white py-5 lg:py-6 border border-black border-opacity-50 rounded-[5px]`}
                        >
                            <>
                                <p
                                    onClick={() => handleOpenFileUploadModal('media')}
                                    className={`px-2.5 lg:px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer text-[14px] lg:text-[16px]`}
                                >
                                    Վերբեռնել նկար/ հոլովակ
                                </p>
                                <p
                                    onClick={() => handleOpenFileUploadModal('file')}
                                    className={`px-2.5 lg:px-[15px] transition hover:bg-gray-200 py-1 cursor-pointer text-[14px] lg:text-[16px]`}
                                >
                                    Վերբեռնել ֆայլ
                                </p>
                            </>
                        </div>
                    }
                    {!isRecording &&
                        <div className={'relative flex'}>
                            <button disabled={!userCanSendMessage} onClick={handleVoiceMessageClick}>
                                <VoiceMessageIcon color={!userCanSendMessage && '#D0CFCF'} width={isPhone && 17}
                                                  height={isPhone && 17}/>
                            </button>
                        </div>
                    }
                </div>

                {isRecording &&
                    <button onClick={() => setIsRecording(false)} className={'cursor-pointer'}><TrashIcon color={'#000'}
                                                                                                          width={isPhone ? 17 : 30}
                                                                                                          height={isPhone ? 17 : 30}/>
                    </button>}

                <div
                    className={`relative flex-1 mx-2 flex items-end ${isRecording && 'max-w-[646px] h-[30px] lg:h-[60px] bg-[#FCC10F] rounded-[5px]'}`}
                    style={{maxHeight: '320px'}}
                >
                    {isRecording
                        ?
                        <VoiceVisualizer
                            controls={recorderControls}
                            mainBarColor={'#fff'}
                            secondaryBarColor={'#fff'}
                            isProgressIndicatorOnHoverShown={false}
                            isProgressIndicatorShown={false}
                            height={isPhone ? 40 : 80}
                        />
                        :
                        <div
                            ref={containerRef}
                            className={`w-full flex items-center bg-white border ${!userCanSendMessage ? 'border-[#D0CFCF]' : 'border-black border-opacity-50'} rounded-[5px] px-5 ${(replyToMessage || isEditingMessage) && 'rounded-t-[0px]'}`}
                        >
                            <textarea
                                ref={textareaRef}
                                value={content}
                                autoFocus={!isPhone && true}
                                disabled={!userCanSendMessage}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                className="h-[30px] lg:h-[60px] text-[14px] lg:text-[16px] resize-none w-full outline-none break-all pt-1 lg:pt-4"
                            />
                            <div className="relative gap-5 pl-4">
                                <button onClick={() => setShowPicker(!showPicker)} disabled={!userCanSendMessage}>
                                    <EmojiIcon color={!userCanSendMessage && '#D0CFCF'} width={isPhone && 17}
                                               height={isPhone && 17}/>
                                </button>
                                {showPicker && (
                                    <div ref={pickerRef}
                                         className={`absolute bottom-full -right-[100%] lg:right-[40%] z-[99]`}>
                                        <EmojiPicker onEmojiClick={onEmojiClick} searchDisabled={true}/>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>

                <button disabled={!userCanSendMessage} className="lg:pl-5" onClick={handleSendMessage}>
                    <SendMessageIcon disabled={!userCanSendMessage} width={isPhone && 17} height={isPhone && 17}/>
                </button>
            </div>
        </>
    );
};

export default MessageTypingArea