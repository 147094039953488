import API from "./index"

export const saveRequestForm = (data) => API.post(`/forms`, {data})
export const getRequestForm = (id) => API.get(`/forms/${id}`)
export const getFormNames = () => API.get(`/forms/names`)
export const editRequestForm = (id, data) => API.patch(`/forms/${id}`, {data})
export const changeRequestFormStatus = (id, status) => API.patch(`/forms/status/${id}/${status}`)
export const submitForm = (formId, data) => API.post(`/forms/submit`, {formId, data})
export const getForms = (filters) => API.get(`/forms${filters}`)
export const deleteRequestForm = (id) => API.delete(`/forms/${id}`)
export const searchForms = (searchValue, page) => API.get(`forms/search?search=${searchValue}&page=${page}`)
export const getFormRequirements = (id) => API.get(`forms/${id}/requirements`)