import React from 'react';
import {SettingsIcon} from "../../../assets/icons";
import {Link} from "react-router-dom";
import {roles} from "../../../common/roles";
import {useSelector} from "react-redux";

const GroupHeader = ({image, name}) => {
    const {authData} = useSelector(state => state.auth)

    return (
        <div className={'flex items-center justify-between'}>
            <div><img src={image} alt={'groupImage'} className={'max-w-[50px] max-h-[50px]'} /></div>
            <h2 className={'text-[50px] text-[#FCC10F] font-bold'}>{name}</h2>
            {roles[authData?.role]?.some(role => role.allowChangeStudentStatus)
                ?
                <Link to={'settings'}><SettingsIcon /></Link>
                :
                <div></div>
            }
        </div>
    );
};

export default GroupHeader;