import React from 'react';
import {CloudDownloadIcon, PdfFilledIcon, TrashIcon} from "../../../../assets/icons";
import moment from "moment";
import {useSelector} from "react-redux";
import {downloadFileFromSrc} from "../../../../utils/fileDownloads/downloadFile";
import {Link} from "react-router-dom";
import {roles} from "../../../../common/roles";

const FileItem = ({name, filePath, createdAt, _id, deleteFile}) => {
    const {authData} = useSelector(state => state.auth);
    const dateMoment = moment(createdAt).format('DD.MM.YYYY');

    const handleDelete = () => {
        deleteFile(_id);
    }

    return (
        <div>
            <div className="grid grid-cols-6 px-[30px] pb-2.5">
                <p className="bold">Ֆայլ</p>
                <p className="bold">Անուն</p>
                <p className="bold"></p>
                <p className="bold"></p>
                <p className="bold">Ավելացման ամսաթիվ</p>
            </div>
            <div className="relative grid grid-cols-6 border border-black rounded-[3px] px-[30px] py-2.5">
                <div className="flex items-center">
                    <PdfFilledIcon/>
                </div>
                <div className="col-span-3 flex items-center">
                    {authData?.role === 'instructor'
                        ?
                        <p
                            className="max-w-[500px] block"
                        >
                            {name}
                        </p>
                        :
                        <Link
                            to={filePath}
                            download={filePath}
                            className="max-w-[500px] cursor-pointer block"
                        >
                            {name}
                        </Link>
                    }
                </div>
                <div className="flex items-center">
                    {dateMoment}
                </div>
                <div className="flex items-center gap-[30px] justify-end">
                    {roles[authData?.role]?.some(role => role.allowDownloadStudentFile) &&
                        <button onClick={() => downloadFileFromSrc(filePath, name)}
                                className="flex items-center justify-center">
                            <CloudDownloadIcon/>
                        </button>
                    }
                    {roles[authData?.role]?.some(role => role.allowDeleteStudentFile) &&
                        <button className="flex items-center justify-center" onClick={handleDelete}>
                            <TrashIcon/>
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};

export default FileItem;
