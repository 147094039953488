export const roles = {
    'manager': [
        {
            showEditRequestButton: true,
            showFormImportButtons: true,
            showArchive: true,
            showCreateFormButton: true,
            showChangeFormStatusButton: true,
            showStudentsImportButtons: true,
            showAddToGroupButton: true,
            showEditStudentStatusButton: true,
            showResetPasswordButton: true,
            showStudentQRButton: true,
            showEditStudentProfileButton: true,
            allowOpenCheckinsHistoryPage: true,
            allowEditAll: true,
            statementActionsMenu: true,
            allowEditPublishedNote: true,
            allowDeletePublishedNote: true,
            allowDeleteDraftNote: true,
            allowImportPdfInStudentProfile: true,
            allowDownloadStudentFile: true,
            allowOpenStudentFile: true,
            allowDeleteStudentFile: true,
            allowCreateNewProgram: true,
            allowChangeProgramStatus: true,
            allowDeleteProgram: true,
            allowEditProgram: true,
            allowCreateNewProject: true,
            allowDeleteProject: true,
            allowEditProject: true,
            allowCreateNewGroup: true,
            allowDeleteGroup: true,
            allowChangeGroupStatus: true,
            allowEditGroup: true,
            allowAddStudentInGroup: true,
            allowChangeStudentStatus: true,
            allowMarkAttendance: true,
            allowWriteNoteInGroup: true,
            allowReplaceLesson: true,
            allowCancelLesson: true,
            allowRecoverLesson: true,
            allowAddNoteInGroup: true,
            allowCreateCommunity: true,
            allowChangeCommunityStatus: true,
            allowDeleteCommunity: true,
            allowCreateNewStatement: true,
            allowEditPublishedStatement: true,
            allowDeletePublishedStatement: true,
            allowCreateNewEvent: true,
            allowChangeEventStatus: true,
            allowDeleteEvent: true,
            allowEditEvent: true,
            allowCreateIntro: true,
            allowChangeIntroStatus: true,
            allowDeleteIntro: true,
            allowViewTransportPage: true,
            allowAllActionsInTransport: true,
            allowViewDriverProfile: true,
        },
    ],
    'divisionManager': [
        {
            showEditRequestButton: true,
            showFormImportButtons: true,
            showArchive: true,
            showCreateFormButton: true,
            showChangeFormStatusButton: true,
            allowCreateNewStatement: true,
            showStudentsImportButtons: true,
            showAddToGroupButton: true,
            showEditStudentStatusButton: true,
            showResetPasswordButton: true,
            showStudentQRButton: true,
            showEditStudentProfileButton: true,
            allowEditPublishedNote: true,
            allowDeletePublishedNote: true,
            allowDeleteDraftNote: true,
            allowEditAll: true,
            allowImportPdfInStudentProfile: true,
            allowDownloadStudentFile: true,
            allowOpenStudentFile: true,
            allowOpenCheckinsHistoryPage: true,
            allowDeleteStudentFile: true,
            allowCreateNewProgram: true,
            allowChangeProgramStatus: true,
            allowDeleteProgram: true,
            statementActionsMenu: true,
            allowEditProgram: true,
            allowCreateNewProject: true,
            allowDeleteProject: true,
            allowEditProject: true,
            allowCreateNewGroup: true,
            allowDeleteGroup: true,
            allowChangeGroupStatus: true,
            allowEditGroup: true,
            allowAddStudentInGroup: true,
            allowChangeStudentStatus: true,
            allowMarkAttendance: true,
            allowWriteNoteInGroup: true,
            allowReplaceLesson: true,
            allowCancelLesson: true,
            allowRecoverLesson: true,
            allowAddNoteInGroup: true,
            allowCreateCommunity: true,
            allowChangeCommunityStatus: true,
            allowDeleteCommunity: true,
            allowEditPublishedStatement: true,
            allowDeletePublishedStatement: true,
            allowCreateNewEvent: true,
            allowChangeEventStatus: true,
            allowDeleteEvent: true,
            allowEditEvent: true,
            allowCreateIntro: true,
            allowChangeIntroStatus: true,
            allowDeleteIntro: true,
            allowViewTransportPage: true,
            allowAllActionsInTransport: true,
            allowViewDriverProfile: true,
        },
    ],
    'admin': [
        {
            showEditRequestButton: true,
            showFormImportButtons: true,
            showArchive: true,
            allowCreateNewStatement: true,
            showCreateFormButton: false,
            showChangeFormStatusButton: false,
            showStudentsImportButtons: true,
            showAddToGroupButton: true,
            showEditStudentStatusButton: true,
            showResetPasswordButton: true,
            showStudentQRButton: true,
            statementActionsMenu: true,
            allowOpenCheckinsHistoryPage: true,
            showEditStudentProfileButton: true,
            allowEditAll: true,
            allowEditPublishedNote: false,
            allowDeletePublishedNote: false,
            allowDeleteDraftNote: true,
            allowImportPdfInStudentProfile: true,
            allowDownloadStudentFile: true,
            allowOpenStudentFile: true,
            allowDeleteStudentFile: true,
            allowCreateNewProgram: false,
            allowChangeProgramStatus: false,
            allowDeleteProgram: false,
            allowCreateNewProject: true,
            allowDeleteProject: true,
            allowEditProject: true,
            allowCreateNewGroup: true,
            allowDeleteGroup: true,
            allowChangeGroupStatus: true,
            allowEditGroup: true,
            allowAddStudentInGroup: true,
            allowChangeStudentStatus: true,
            allowMarkAttendance: true,
            allowWriteNoteInGroup: true,
            allowReplaceLesson: true,
            allowCancelLesson: true,
            allowRecoverLesson: true,
            allowAddNoteInGroup: true,
            allowEditPublishedStatement: true,
            allowDeletePublishedStatement: true,
            allowCreateNewEvent: true,
            allowChangeEventStatus: true,
            allowDeleteEvent: true,
            allowEditEvent: true,
            allowCreateIntro: true,
            allowChangeIntroStatus: true,
            allowDeleteIntro: true,
            allowViewTransportPage: true,
            allowAllActionsInTransport: false,
            allowViewDriverProfile: true,
        },
    ],
    'mdt': [
        {
            showEditRequestButton: false,
            showFormImportButtons: false,
            showArchive: false,
            showStudentsImportButtons: false,
            showAddToGroupButton: false,
            showEditStudentStatusButton: false,
            showResetPasswordButton: false,
            allowDeletePublishedNote: false,
            allowDeleteDraftNote: true,
            showStudentQRButton: false,
            showEditStudentProfileButton: false,
            allowCreateNewProgram: false,
            allowChangeProgramStatus: false,
            allowDeleteProgram: false,
            allowCreateNewProject: false,
            allowDeleteProject: false,
            allowEditProject: false,
            allowMarkAttendanceInIntro: true,
            allowViewTransportPage: true,
            allowAllActionsInTransport: false,
            allowViewDriverProfile: true,
        },
    ],
    'instructor': [
        {
            showStudentsImportButtons: false,
            showAddToGroupButton: false,
            showEditStudentStatusButton: false,
            showResetPasswordButton: false,
            showStudentQRButton: false,
            allowDeletePublishedNote: false,
            allowDeleteDraftNote: true,
            showEditStudentProfileButton: false,
            allowCreateNewProgram: false,
            allowChangeProgramStatus: false,
            allowDeleteProgram: false,
            allowCreateNewProject: false,
            allowViewTransportPage: false,
            allowDeleteProject: false,
            allowEditProject: false,
            allowMarkAttendance: true,
            allowAddNoteInGroup: true,
            allowOpenCheckinsHistoryPage: false,
            allowWriteNoteInGroup: true,
        },
    ],
    'driver': [
        {},
    ],
    'transportCoordinator': [
        {
            showStudentsImportButtons: false,
            showAddToGroupButton: false,
            showEditStudentStatusButton: false,
            showResetPasswordButton: false,
            showStudentQRButton: false,
            showEditStudentProfileButton: true,
            allowEditPublishedNote: false,
            allowDeletePublishedNote: false,
            allowDeleteDraftNote: true,
            allowEditOnlyStop: true,
            allowEditAll: false,
            allowImportPdfInStudentProfile: false,
            allowDownloadStudentFile: false,
            allowOpenStudentFile: false,
            allowDeleteStudentFile: false,
            allowCreateNewProgram: false,
            allowChangeProgramStatus: false,
            allowDeleteProgram: false,
            allowCreateNewProject: false,
            allowDeleteProject: false,
            allowEditProject: false,
            allowViewTransportPage: true,
            allowAllActionsInTransport: true,
            allowViewDriverProfile: true,
        },
    ],
    'projectsCoordinator': [
        {
            showStudentsImportButtons: false,
            showAddToGroupButton: false,
            showEditStudentStatusButton: false,
            showResetPasswordButton: false,
            showStudentQRButton: false,
            showEditStudentProfileButton: true,
            allowEditPublishedNote: false,
            allowDeletePublishedNote: false,
            allowDeleteDraftNote: true,
            allowEditOnlyPins: true,
            allowEditAll: false,
            allowImportPdfInStudentProfile: false,
            allowDownloadStudentFile: false,
            allowOpenStudentFile: false,
            allowDeleteStudentFile: false,
            allowCreateNewProgram: false,
            allowChangeProgramStatus: false,
            allowDeleteProgram: false,
            allowCreateNewProject: true,
            allowDeleteProject: true,
            allowEditProject: true,
            allowCreateNewEvent: true,
            allowChangeEventStatus: true,
            allowDeleteEvent: true,
            allowEditEvent: true,
            allowMarkSmartEventAttendance: true
        },
    ],
    'dispatcher': [
        {
            showStudentsImportButtons: false,
            showAddToGroupButton: false,
            showEditStudentStatusButton: false,
            showResetPasswordButton: false,
            showStudentQRButton: false,
            showEditStudentProfileButton: false,
            allowDeletePublishedNote: false,
            allowDeleteDraftNote: true,
            allowCreateNewProgram: false,
            allowCreateNewStatement: true,
            allowChangeProgramStatus: false,
            allowDeleteProgram: false,
            allowCreateNewProject: false,
            allowDeleteProject: false,
            allowEditProject: false,
            statementActionsMenu: true,
            allowViewTransportPage: true,
            allowAllActionsInTransport: true,
            allowViewDriverProfile: true,
        },
    ],
    'student': [
        {},
        // no student page
    ],
}
