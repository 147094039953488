import React, { useEffect, useRef, useState } from 'react';
import { CloseIcon, DropdownArrow } from "../../../assets/icons";
import { gsap } from "gsap";
import { Checkbox } from "../buttons";
import { useOutsideClick } from "../../../hooks/useOutsideClick";

const DropdownInputCheckbox = ({
                                   data,
                                   name,
                                   placeholder,
                                   onChange,
                                   selected,
                                   hideCheckbox,
                                   showSelected,
                                   removeSelectedInstructor,
                                   borderColor,
                                   previewClassName,
                                   optionsClassName,
                                   optionClassName,
                                   previewUppercase,
                                   disableInput,
                                   type,
                                   onFocus,
                                   textSize,
                                   icon
                               }) => {
    const [innerData, setInnerData] = useState(null);
    const customPlaceholder = selected?.length === 1 && selected[0]?.name;
    const [searchValue, setSearchValue] = useState(customPlaceholder || "");

    const [open, setOpen] = useState(false);

    const selectedRef = useRef(null);
    const optionsRef = useRef(null);

    useOutsideClick(optionsRef, () => setOpen(false));

    const toggleOpen = (e) => {
        e.stopPropagation();
        setSearchValue('');
        setOpen(!open);
    };

    const handleClick = (option, e) => {
        e.stopPropagation();
        if (hideCheckbox) setOpen(false);
        if (name === 'status') toggleOpen(e);

        onChange(name, option)
        setSearchValue(option.name)
        setSearchValue('')
    };

    const handleChangeSearch = (e) => {
        e.stopPropagation();
        setSearchValue(e.target.value);

        if (!open) setOpen(true);
    };

    useEffect(() => {
        const optionsElement = optionsRef.current;

        if (open) {
            gsap.fromTo(
                optionsElement,
                { opacity: 0, height: 0, display: 'block' },
                { opacity: 1, height: 'auto', duration: 0.3 }
            );
        } else {
            gsap.to(optionsElement, { opacity: 0, height: 0, duration: 0.2, display: 'none' });
        }
    }, [open]);

    useEffect(() => {
        if (!disableInput) {
            searchValue.length > 0
                ? setInnerData(data?.filter(item => String(item.name || item.value).toLowerCase().slice(0, searchValue.toLowerCase().length) === searchValue.toLowerCase()))
                : setInnerData(data);
        }
    }, [searchValue]);

    useEffect(() => {
        data && setInnerData(data);
    }, [data])

    return (
        <>
            {open && <div onClick={toggleOpen} className={`fixed z-40 h-full top-0 left-0`}></div>}

            <div onFocus={onFocus} className={`relative ${open && 'z-[999]'} w-full`}>
                <div
                    className={`flex justify-between items-center px-2 border border-[${borderColor || '#000'}] ${open ? 'rounded-b-none pointer-events-none' : 'pointer-events-auto'} ${previewClassName}`}
                    style={previewUppercase && { textTransform: 'uppercase' }}
                    ref={selectedRef}
                    onClick={toggleOpen}
                >
                    {icon && <div className={'pr-[10px]'}>{icon}</div>}

                    {showSelected && selected?.length > 0 ?
                        <div className={"flex flex-wrap items-center gap-5"}>
                            {selected.map((item, index) => (
                                item?.name && <span key={index} className={"w-fit line-clamp-1 truncate"}>{item?.name}</span>
                            ))}
                        </div>
                        :
                        name === 'globalStatus'
                            ?
                            <p className={`py-[7px] line-clamp-1 truncate ${!customPlaceholder && 'text-gray-400'}`}>{customPlaceholder || 'Կարգավիճակ'}</p>
                            :
                            disableInput
                                ?
                                <p className={'line-clamp-1'}>{customPlaceholder}</p>
                                :
                                <input value={searchValue}
                                       onChange={handleChangeSearch}
                                       autoComplete={'off'}
                                       placeholder={customPlaceholder || placeholder}
                                       className={`w-full transition ${customPlaceholder && 'placeholder:text-black focus:placeholder:text-gray-400'} outline-none`}
                                />
                    }
                    <button className={`${open && `rotate-180`} flex items-center justify-center transition`}>
                        <DropdownArrow /></button>
                </div>

                <div
                    style={{ background: '#fff' }}
                    ref={optionsRef}
                    className={`${open ? 'h-auto mr-2' : 'h-0'}
                    ${innerData?.length > 4 ? 'overflow-auto ' : 'overflow-hidden'}
                    ${optionsClassName}
                    w-full absolute border border-[${borderColor || '#000'}]
                    rounded-b-[5px] border-t-0`}
                >
                    <div className={`w-full max-h-[310px] transition`}>
                        <div>
                            {type === 'instructors' && (
                                <div className={`flex flex-wrap ${selected?.length > 0 && 'py-4 px-2.5'}`}>
                                    <div className="flex flex-wrap items-center gap-2.5">
                                        {selected?.length > 0
                                            &&
                                            selected.map(item => (
                                                item?.name && <div
                                                    onClick={() => removeSelectedInstructor(item._id)}
                                                    key={item._id}
                                                    className={'flex items-center gap-3 bg-gray-100 px-1 rounded-[3px] cursor-pointer'}>
                                                    <span>{item.name}</span>
                                                    <button>
                                                        <CloseIcon width={10} height={10} />
                                                    </button>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            )}
                            <div className={`${type === 'instructors' && selected?.length && 'border-t'}`}>
                                {innerData?.length > 0
                                    ?
                                    innerData?.map((option, i) => {
                                        let checked = null;

                                        try {
                                            checked = selected?.find(item => item?._id === option?._id);
                                        } catch (e) {

                                        }

                                        return (
                                            option?.name && <div key={i} title={option.name}
                                                                 onClick={(e) => handleClick(option, e)}
                                                                 className={`grid grid-cols-[1fr,20px] gap-2 px-1.5 py-2.5 cursor-pointer hover:bg-gray-100 ${optionClassName}`}>
                                                <div
                                                    className={'flex items-center justify-between line-clamp-1 overflow-hidden'}>
                                                    <p className={`truncate ${textSize}`}>{option.name}</p>
                                                </div>
                                                {!hideCheckbox &&
                                                    <div className={''}><Checkbox checked={checked} /></div>}
                                            </div>
                                        );
                                    })
                                    :
                                    <div
                                        className={'flex justify-between items-center px-1.5 py-2.5 rounded-md cursor-default'}>
                                        <p>Ոչինչ չի գտնվել</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DropdownInputCheckbox;
