import {SET_NEW_NOTIFICATION_COUNT, SET_NOTIFICATIONS} from '../constants/actionTypes'

const initialState = {
    notifications: null,
    newNotificationsCount: null,
    loading: false,
}

const notificationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_NOTIFICATIONS:
            return {...state, notifications: action.payload.nots}
        case SET_NEW_NOTIFICATION_COUNT:
            return {...state, newNotificationsCount: action.payload}
        case 'UPDATE_NEW_NOTIFICATIONS_COUNT':
            return {
                ...state,
                newNotificationsCount: action.payload,
            }
        default:
            return state;
    }
}
export default notificationsReducer