import React from 'react';
import {GeoIcon, MailIcon, PhoneIcon} from "../../../../../assets/icons";
import {DisplayPhoneNumber} from "../../../../shared";
import moment from "moment";

const DriverPersonalData = ({profileImage, registrationAddress, address, phoneNumbers, email, birthday}) => {
    const formattedBirthday = moment(birthday).format("DD.MM.YYYY")

    return (
        <div className={'w-[445px] h-[560px] custom-shadow rounded-[10px]'}>
            <div className={'w-full flex justify-center pt-5'}>
                <img alt={'profileImage'} src={profileImage}
                     className={'max-w-[200px] w-full max-h-[200px] object-cover rounded-full pointer-events-none select-none'}/>
            </div>
            <p className={'py-[30px] text-center'}>Վարորդ</p>

            <div className={'flex flex-col px-[30px] gap-[30px]'}>
                <div className={'flex items-center gap-2.5'}>
                    <div className={'flex items-center gap-2.5'}>
                        <GeoIcon/>
                        <span className={'text-[#FCC10F]'}>Գրանցման</span>
                    </div>
                    <span>{registrationAddress}</span>
                </div>
                <div className={'flex items-center gap-2.5'}>
                    <div className={'flex items-center gap-2.5'}>
                        <GeoIcon/>
                        <span className={'text-[#FCC10F]'}>Փաստացի</span>
                    </div>
                    <span>{address}</span>
                </div>
                <div className={'flex items-center gap-5'}>
                    <div className={'flex items-center gap-2.5'}>
                        <PhoneIcon/>
                        <div className={'flex gap-5'}>
                            {phoneNumbers?.map((phoneNumber, index) =>
                                <span key={index}>
                                    <DisplayPhoneNumber phoneNumber={phoneNumber}/>
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                <div className={'flex items-center gap-5'}>
                    <div className={'flex items-center gap-2.5'}>
                        <MailIcon/>
                        <span>{email}</span>
                    </div>
                </div>
                <div className={'flex items-center gap-5'}>
                    <div className={'flex items-center gap-2.5'}>
                        <span className={'text-[#FCC10F]'}>Ծննդյան օր՝</span>
                        <span>{formattedBirthday}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DriverPersonalData;