import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getGroupNotes} from "../../../../../store/actions/groups";
import {useParams} from "react-router-dom";
import NoteItem from "../../../../../components/students/NoteItem";
import CreateNoteModal from "../../../../../components/students/modals/CreateNoteModal";
import {deleteGroupStudentNote} from "../../../../../store/actions/students";
import {ConfirmationModal} from "../../../../../components/shared";
import {TrashIcon} from "../../../../../assets/icons";
import Pagination from "../../../../../components/shared/Pagination";

const GroupNotes = () => {
    const {notes} = useSelector(state => state.groups)
    const [page, setPage] = useState(1)
    const [editNote, setEditNote] = useState(null)
    const [deleteNoteModal, setDeleteNoteModal] = useState(null)

    const {id} = useParams()
    const dispatch = useDispatch()

    const handleEditNote = (note) => {
        setEditNote(note)
    }

    const handleDeleteNote = () => {
        dispatch(deleteGroupStudentNote(deleteNoteModal))
        setDeleteNoteModal(null)
    }

    const handleChangePage = (_, p) => {
        window.scrollTo({top: 0})
        setPage(p)
    }

    useEffect(() => {
        dispatch(getGroupNotes(id, page))
    }, [dispatch, id, page])

    useEffect(() => {
        if (editNote || deleteNoteModal) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [editNote, deleteNoteModal])

    return (
        <>
            {editNote && <CreateNoteModal
                editNote={editNote}
                close={() => setEditNote(null)}
                groupId={id}
                page={page}
                open={editNote}
                type={'group'}
            />}

            {deleteNoteModal && <ConfirmationModal
                close={() => setDeleteNoteModal(null)}
                cancelText={'Չեղարկել'}
                confirmText={'Ջնջել'}
                confirm={handleDeleteNote}
                icon={<TrashIcon />}
                warningText={'Վստա՞հ եք, որ ցանկանում եք ջնջել այս նոթագրությունը։'}
            />}

            <div>
                {notes?.data?.map(note => (
                    <NoteItem
                        key={note._id}
                        note={note}
                        showStudent
                        handleEditNote={handleEditNote}
                        setDeleteNoteModal={setDeleteNoteModal}
                        handleDeleteNote={(id) => setDeleteNoteModal(id)}
                    />
                ))}

                <Pagination count={notes?.pagesCount} page={page} onChange={handleChangePage}/>
            </div>
        </>
    )
}

export default GroupNotes
