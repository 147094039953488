import React, {useEffect, useRef, useState} from 'react';
import {ActionMenuIcon, AgeGroupIcon, PeopleIcon, StatusIcon} from "../../assets/icons";
import {daysOfWeek, groupStatusNames} from "../../common/dropdownOptions";
import {useOutsideClick} from "../../hooks/useOutsideClick";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeGroupStatus} from "../../store/actions/groups";
import {ConfirmationModal} from "../shared";
import {roles} from "../../common/roles";
import {useMediaQuery} from "react-responsive";

const statusColors = {
    active: '#20BA07',
    finished: '#FF0707',
    closed: 'rgba(0, 0, 0, 0.6)',
}

const GroupCard = ({
                       name,
                       scheduleCodes,
                       program,
                       instructors,
                       studentsCount,
                       ageGroup,
                       status,
                       type,
                       image,
                       handleDeleteGroup,
                       _id,
                       groupToDelete,
                       setGroupToDelete,
                   }) => {
    const {authData} = useSelector(state => state.auth)

    const userHasPermissions = authData.role === 'manager' || authData.role === 'volunteer' || authData.role === 'admin'

    const [showMenu, setShowMenu] = useState(false)

    const [openStatusModal, setOpenStatusModal] = useState(null)

    const ref = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const isPhone = useMediaQuery({maxWidth: 640})

    useOutsideClick(ref, () => setShowMenu(false))

    const textColorStyle = statusColors.hasOwnProperty(status) ? {color: statusColors[status]} : {}

    const deleteGroup = () => {
        setGroupToDelete(_id)
    }

    const changeStatus = () => {
        setOpenStatusModal(true)
    }

    const handleChangeStatus = () => {
        let newStatus
        if (status === 'active' || status === 'finished') {
            newStatus = 'closed'
        } else if (status === 'closed') {
            newStatus = 'active'
        }

        dispatch(changeGroupStatus(_id, newStatus))
        setShowMenu(false)
        setOpenStatusModal(false)
    }

    const schedule = scheduleCodes?.map((scheduleCode) => {
        const splitted = scheduleCode.split('_')
        const weekday = daysOfWeek[splitted[0]]

        return `${weekday}. ${splitted[1].split('-')[0]}`
    })

    useEffect(() => {
        document.body.style.overflow = groupToDelete || openStatusModal ? 'hidden' : 'auto'
    }, [groupToDelete, openStatusModal])

    return (
        <>
            {groupToDelete &&
                <ConfirmationModal
                    close={() => setGroupToDelete(null)}
                    warningText={`Վստա՞հ եք, որ ցանկանում եք ջնջել այս խումբը։`}
                    confirm={handleDeleteGroup}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            {openStatusModal &&
                <ConfirmationModal
                    close={() => setOpenStatusModal(false)}
                    warningText={`${status === 'active' || status === 'finished' ? 'Վստա՞հ եք, որ ցանկանում եք փակել այս խումբը։' : 'Վստա՞հ եք, որ ցանկանում եք ակտիվացնել այս խումբը։'}`}
                    confirm={handleChangeStatus}
                    confirmText={'Այո'}
                    cancelText={'Ոչ'}
                />
            }

            <div
                className={`relative w-[345px] h-[298px] lg:w-[405px] lg:h-[392px] p-5 lg:py-[25px] lg:px-[29px] rounded-[10px] custom-shadow`}>
                <div
                    className={`${showMenu ? 'block' : 'hidden'} absolute w-full h-full z-40 backdrop-blur-[1px]`}></div>

                <div className={'w-full'}>
                    <div className={'flex justify-center'}>
                        {authData.role === 'student'
                            ?
                            <div className={'flex justify-center'}>
                                <p className={'absolute max-w-[300px] text-[14px] lg:text-[16px] text-[#FCC10F] text-center line-clamp-2'}>{name}</p>
                            </div>
                            :
                            <Link to={`/groups/${_id}/attendance`}
                                  className={'absolute max-w-[300px] text-[14px] lg:text-[16px] text-[#FCC10F] text-center line-clamp-2'}>{name}</Link>
                        }

                        {(roles[authData?.role]?.some(role => role.allowDeleteGroup) || roles[authData?.role]?.some(role => role.allowChangeGroupStatus)) &&
                            <button
                                className={`absolute z-50 right-5 ${showMenu ? 'pointer-events-none' : 'pointer-events-auto'}`}
                                onClick={() => setShowMenu(!showMenu)}
                            >
                                <ActionMenuIcon/>
                            </button>}
                    </div>

                    {showMenu && (
                        <div ref={ref}
                             className={`absolute z-50 w-[163px] top-[60px] flex flex-col gap-4 bg-white right-5 py-6 border border-black rounded-[5px]`}>
                            {status === 'active'
                                &&
                                <>
                                    <p
                                        onClick={deleteGroup}
                                        className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                        Ջնջել
                                    </p>
                                    <p
                                        onClick={changeStatus}
                                        className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                        {status === 'active' ? 'Փակել' : 'Ակտիվացնել'}
                                    </p>
                                </>
                            }
                            {status === 'finished'
                                &&
                                <>
                                    <p
                                        onClick={changeStatus}
                                        className={'px-[30px] py-[3px] transition hover:bg-gray-200 cursor-pointer'}>
                                        Փակել
                                    </p>
                                    {userHasPermissions &&
                                        <p
                                            onClick={deleteGroup}
                                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                            Ջնջել
                                        </p>
                                    }
                                </>
                            }
                            {status === 'closed'
                                &&
                                <>
                                    <p
                                        onClick={changeStatus}
                                        className={'px-[30px] py-[3px] transition hover:bg-gray-200 cursor-pointer'}>
                                        Ակտիվացնել
                                    </p>
                                    {userHasPermissions &&
                                        <p
                                            onClick={deleteGroup}
                                            className={'px-[30px] transition hover:bg-gray-200 py-1 cursor-pointer'}>
                                            Ջնջել
                                        </p>
                                    }
                                </>
                            }
                        </div>
                    )}
                </div>
                <div className={'h-10'}></div>
                <div
                    className={`w-full h-full max-h-[100px] lg:max-h-[152px] flex justify-center ${name.length > 40 ? 'my-5' : 'my-4'} px-10`}>
                    {authData.role === 'student' && isPhone && (
                        <div className={'w-[100px] h-[100px]'}>
                            <img
                                src={image}
                                alt={'program_image'}
                                className={`w-full h-full pointer-events-none object-contain`}
                            />
                        </div>
                    )}
                    {!isPhone && authData.role !== 'student'
                        ?
                        <Link to={`/groups/${_id}/attendance`}>
                            <img src={image} alt={'program_image'}
                                 className={'max-h-[149px] w-full pointer-events-none object-cover'}/>
                        </Link>
                        :
                        authData.role !== 'student' &&
                        <Link to={`/groups/${_id}/attendance`}>
                            <img src={image} alt={'program_image'}
                                 className={`${type === 'connect' ? 'max-w-[130px]' : 'max-w-[110px]'} lg:w-full max-h-[110px] lg:max-h-[149px] w-full pointer-events-none object-cover`}/>
                        </Link>
                    }
                </div>
                <div
                    className={(type === 'intro' || type === 'connect' || type === 'trip' || type === 'workshop') && authData.role === 'student' && `mt-10 lg:mt-14`}>
                    <div className={'grid grid-cols-[1fr,80px] gap-4'}>
                        <div
                            className={authData.role === 'student' ? `${type === 'intro' ? 'flex-none' : 'flex flex-col gap-5 lg:gap-[30px]'} w-full` : 'w-full flex flex-col gap-5 lg:gap-[30px]'}>
                            {authData.role === 'student'
                                ?
                                <p className={'text-[14px] lg:text-[16px]'}>{program?.name}</p>
                                :
                                <Link to={`/programs/${program?._id}/groups`}
                                      className={'text-[14px] lg:text-[16px] transition hover:text-[#FCC10F]'}>{program?.name}</Link>
                            }
                            {instructors?.length > 1
                                ?
                                <div className="relative group">
                                    <span className="cursor-pointer text-[14px] lg:text-[16px]">Ծրագրավարներ</span>
                                    <div
                                        className="absolute w-[250px] z-10 hidden group-hover:grid grid-cols-2 gap-3 bg-white border shadow-md rounded-[3px] p-2 mt-2">
                                        {instructors?.map((instructor) => (
                                            authData.role === 'student'
                                                ?
                                                <p
                                                    className="max-w-[120px] text-[12px] hover:text-[#FCC10F] transition"
                                                    key={instructor._id}
                                                >
                                                    {instructor.fullName}
                                                </p>
                                                :
                                                <Link
                                                    to={`/instructors/${instructor._id}`}
                                                    className="max-w-[120px] text-[12px] hover:text-[#FCC10F] transition"
                                                    key={instructor._id}
                                                >
                                                    {instructor.fullName}
                                                </Link>
                                        ))}
                                    </div>
                                </div>
                                : instructors?.length && instructors?.length === 1 ?
                                    authData.role === 'student'
                                        ?
                                        <p
                                            title={instructors[0]?.fullName}
                                            className={'line-clamp-1 text-[14px] lg:text-[16px]'}>{instructors[0]?.fullName}</p>
                                        :
                                        <Link
                                            to={`/instructors/${instructors[0]?._id}`}
                                            title={instructors[0]?.fullName}
                                            className={'transition hover:text-[#FCC10F] line-clamp-1 text-[14px] lg:text-[16px]'}>{instructors[0]?.fullName}</Link>
                                    : null
                            }
                        </div>
                        <div className={'w-[90px] max-h-[70px] flex items-start flex-col gap-5 lg:gap-[30px]'}>
                            {schedule?.length > 2 ?
                                <div className={"relative group"}>
                                    <span className={'cursor-pointer text-[14px] lg:text-[16px]'}>Գրաֆիկ</span>
                                    <div
                                        className={"w-[228px] absolute z-10 hidden group-hover:grid grid-cols-3 gap-y-2.5 bg-white border shadow-md rounded-[3px] p-2 right-[1%] lg:right-0 mt-2"}>
                                        {schedule?.map((item, index) => (
                                            <p key={index} className={'text-[12px]'}>{item}</p>
                                        ))}
                                    </div>
                                </div>
                                : schedule?.map((item, index) => <p key={index}
                                                                    className={'text-[14px] lg:text-[16px]'}>{item}</p>)
                            }
                        </div>
                    </div>
                    <div className={'flex justify-between mt-5'}>
                        <div className={'flex items-center gap-5 lg:gap-[30px]'}>
                            <p className={'relative group flex items-center gap-[10px] select-none'}>
                                <PeopleIcon width={isPhone ? 17 : 20} height={isPhone ? 17 : 20}/>
                                <span className={'text-[14px] lg:text-[16px]'}>{studentsCount}</span>
                                <span
                                    className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Ուսանողների քանակ</span>
                            </p>
                            {ageGroup &&
                                <p className={'relative group flex items-center gap-[10px] select-none'}>
                                    <AgeGroupIcon width={isPhone ? 17 : 20} height={isPhone ? 17 : 20}/>
                                    <span
                                        className={'text-[14px] lg:text-[16px]'}>{ageGroup === 'hybrid' ? 'Հիբրիդ' : ageGroup}</span>
                                    <span
                                        className={`absolute w-[180px] left-full top-full text-[12px] border rounded-[3px] p-2.5 opacity-0 bg-white custom-shadow pointer-events-none group-hover:opacity-100 transition`}>Տարիքային խումբ</span>
                                </p>
                            }
                        </div>
                        <p className={`flex items-center capitalize gap-[10px] select-none`}>
                            <span style={textColorStyle}
                                  className={'text-[14px] lg:text-[16px]'}>{groupStatusNames[status]}</span>
                            <StatusIcon color={statusColors[status]} width={isPhone ? 17 : 20}
                                        height={isPhone ? 17 : 20}/>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
        ;
};

export default GroupCard;
