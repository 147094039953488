import React from 'react';
import {
    ClothsSizeIcon, CompletedIcon, DroppedIcon,
    GeoIcon, IntroductoryWeekIcon,
    MailIcon, ParticipatedIcon,
    PhoneIcon,
    StopIcon,
    TransportIcon,
    UsersIcon
} from "../../../../assets/icons";
import moment from "moment";

const StudentPersonalInfo = ({
                                 community,
                                 transportStop,
                                 phoneNumbers,
                                 email,
                                 gender,
                                 birthday,
                                 clothingSize,
                                 ageGroup,
                                 educationalEstablishment,
                                 matriculationYear,
                                 birthCertificate,
                                 parentsFullNames,
                                 formWeek,
                                 introGroups
                             }) => {
    const formattedBirthday = moment(birthday).format("DD.MM.YYYY")

    return (
        <div className={'mt-5 mb-[50px]'}>
            <h2 className={'text-[14px] text-[#FCC10F]'}>Անձնական տվյալներ</h2>

            <div className={'flex flex-col gap-2.5 mt-5'}>
                <div className={'flex items-center gap-[30px]'}>
                    <div className={'flex items-center gap-2.5'}>
                        <GeoIcon width={18} height={18}/>
                        <p className={'text-[14px]'}>{community.name ? community.name : 'Առկա չէ'}</p>
                    </div>
                    <div className={'flex items-center gap-2.5'}>
                        <TransportIcon width={18} height={18}/>
                        <p className={'text-[14px]'}>{transportStop ? transportStop : 'Առկա չէ'}</p>
                    </div>
                </div>
                <div className={'flex items-center gap-[30px]'}>
                    <div className={'flex items-center gap-2.5'}>
                        <PhoneIcon width={18} height={18}/>
                        <p className={'text-[14px]'}>{phoneNumbers[0] ? phoneNumbers[0] : 'Առկա չէ'}</p>
                    </div>
                    <p className={'text-[14px]'}>{phoneNumbers[1] ? phoneNumbers[1] : 'Առկա չէ'}</p>
                </div>
                <div className={'flex items-center gap-[30px]'}>
                    <div className={'flex items-center gap-2.5'}>
                        <MailIcon width={18} height={18}/>
                        <p className={'text-[14px]'}>{email ? email : 'Առկա չէ'}</p>
                    </div>
                </div>
                <div className={'flex items-center gap-[23px]'}>
                    <div className={'flex items-center gap-2.5'}>
                        <p className={'text-[14px] text-[#FCC10F]'}>Սեռ՝ </p>
                        <p className={'text-[14px]'}>{gender === 'female' ? 'Իգական' : 'Արական'}</p>
                    </div>
                    <div className={'flex items-center gap-5'}>
                        <p className={'text-[14px] text-[#FCC10F]'}>Ծննդյան օր՝ </p>
                        <p className={'text-[14px]'}>{birthday ? formattedBirthday : 'Առկա չէ'}</p>
                    </div>
                </div>
                <div className={'flex items-center gap-[34px]'}>
                    <div className={'flex items-center gap-2.5'}>
                        <ClothsSizeIcon width={18} height={18}/>
                        <p className={'text-[14px]'}>{clothingSize ? clothingSize : 'Առկա չէ'}</p>
                    </div>
                    <div className={'flex items-center gap-2.5'}>
                        <UsersIcon width={18} height={18}/>
                        <p className={'text-[14px]'}>{ageGroup ? ageGroup : 'Առկա չէ'}</p>
                    </div>
                </div>
            </div>
            <h2 className={'text-[14px] text-[#FCC10F] mt-5'}>Ուսումնական հաստատություն</h2>
            <p className={'text-[14px] mt-2.5'}>{educationalEstablishment ? educationalEstablishment : 'Առկա չէ'}</p>

            <h2 className={'text-[14px] text-[#FCC10F] mt-5'}>Ընդունվելու տարեթիվ</h2>
            <p className={'text-[14px] mt-2.5'}>{matriculationYear ? matriculationYear : 'Առկա չէ'}</p>

            <h2 className={'text-[14px] text-[#FCC10F] mt-5'}>Ծննդյան վկայական/ անձնագիր</h2>
            <p className={'text-[14px] mt-2.5'}>{birthCertificate ? birthCertificate : 'Առկա չէ'}</p>

            <h2 className={'text-[14px] text-[#FCC10F] mt-5'}>Ծնող կամ օրինական խնամակալ 1</h2>
            <p className={'text-[14px] mt-2.5'}>{parentsFullNames[0] ? parentsFullNames[0] : 'Առկա չէ'}</p>

            <h2 className={'text-[14px] text-[#FCC10F] mt-5'}>Ծնող կամ օրինական խնամակալ 2</h2>
            <p className={'text-[14px] mt-2.5'}>{parentsFullNames[1] ? parentsFullNames[1] : 'Առկա չէ'}</p>

            <h2 className={'text-[14px] text-[#FCC10F] mt-5'}>Հայտի համար</h2>
            <p className={'text-[14px] mt-2.5'}>{formWeek ? formWeek.name : 'Առկա չէ'}</p>

            <h2 className={'flex items-center gap-2.5 text-[14px] text-[#FCC10F] mt-5'}>
                <IntroductoryWeekIcon width={18} height={18}/>
                Ներածական շաբաթ
            </h2>
            <div className={'text-[14px] mt-2.5'}>
                {introGroups.length
                    ?
                    introGroups.map((item, index) =>
                        <div key={index} className={'flex items-center gap-[30px]'}>
                            <p>{item.statusType === 'current' ? item?.name : item?.group?.name}</p>
                            {item.status === 'completed' && <CompletedIcon/>}
                            {item.status === 'participated' && <ParticipatedIcon/>}
                            {item.status === 'dropped' && <DroppedIcon/>}
                        </div>)
                    :
                    <p className={'flex items-center gap-[30px]'}>Առկա չէ</p>
                }
            </div>
        </div>
    );
};

export default StudentPersonalInfo;