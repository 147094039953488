import React, {useEffect, useState} from 'react';
import {CloseIcon} from '../../../../../assets/icons';
import {DropdownInputCheckbox} from '../../../../shared/dropdowns';
import {ButtonFilled, ButtonOutlined, PlusButton} from '../../../../shared/buttons';
import {useDispatch} from "react-redux";
import {dividePassengers} from "../../../../../store/actions/transport"

const testingArr = Array.from({length: 100}, (_, index) => ({_id: index, name: index + 1}));

const DividePassengersModal = ({id, close, refreshData}) => {
    const [dropdownSelections, setDropdownSelections] = useState([{_id: 14, name: 15}, null]);
    const [error, setError] = useState()

    const dispatch = useDispatch()

    const handleSelect = (dropdownIndex, selectedOption) => {
        setDropdownSelections(prevSelections => {
            const newSelections = [...prevSelections]
            newSelections[dropdownIndex] = selectedOption
            return newSelections
        })
    }

    const removeDropdown = (indexToRemove) => {
        setDropdownSelections(prevSelections => {
            const updatedSelections = [...prevSelections]
            updatedSelections.splice(indexToRemove, 1)
            return updatedSelections
        })
    }

    const renderDropdowns = () => {
        return dropdownSelections.map((selectedOption, index) => (
            <div className={'flex'} key={index}>
                <div className={'w-[112px]'}>
                    <DropdownInputCheckbox
                        data={testingArr}
                        previewClassName={`border-opacity-70 rounded-[3px] py-[7px] px-5 ${index >= 2 && 'rounded-e-none'}`}
                        optionClassName={'px-5'}
                        onChange={(event, selectedOption) => handleSelect(index, selectedOption)}
                        selected={selectedOption ? [selectedOption] : []}
                        hideCheckbox
                    />
                </div>
                {index >= 2 && (
                    <div
                        onClick={() => removeDropdown(index)}
                        className={'w-10 h-10 flex items-center justify-center border border-black border-opacity-70 border-l-0 rounded-e-[3px] cursor-pointer'}>
                        <CloseIcon width={20} height={20}/>
                    </div>
                )}
            </div>
        ))
    }

    const addDropdown = () => {
        setDropdownSelections([...dropdownSelections, null])
    }

    const handleSubmit = () => {
        const selectedValues = dropdownSelections
            .filter(value => value !== null)
            .map(value => value._id + 1)
            .join(', ')

        dispatch(dividePassengers(id, selectedValues, setError))
            .then(() => {
                refreshData()
                close()
            })
            .catch(error => {
                setError(error.message)
            })
    }

    useEffect(() => {
        if (error) {
            setError(error)
        }
    }, [error])

    return (
        <div className={'fixed z-[9990] w-screen h-screen top-0 left-0 mt-28 flex justify-center'}>
            <div className={'fixed bg-black bg-opacity-5 backdrop-blur w-full h-full top-0 left-0 z-[9990]'}></div>

            <div
                className={'absolute w-full max-w-[690px] min-h-[356px] z-[9999] border border-black rounded-[10px] bg-white'}>
                <div className={'relative flex items-center justify-center p-[30px]'}>
                    <h2 className={'text-center'}>Բաժանել մասնակիցներին</h2>
                    <button className={'absolute right-6 bg-black bg-opacity-5 rounded-full p-1.5'} onClick={close}>
                        <CloseIcon width={20} height={20}/>
                    </button>
                </div>

                <div
                    className={`max-w-[500px] flex flex-wrap justify-center items-center gap-x-[47px] gap-y-5 ${error ? 'pb-2.5' : 'pb-[30px]'} mx-auto`}>
                    {renderDropdowns()}
                </div>

                {error && <p className={'text-red-500 pb-5 text-[12px] text-center'}>Ուսանողների քանակը չի կարող գերազանցել ցանկի նախնական քանակը։</p>}

                <div className={'flex justify-center pb-[50px]'}>
                    <PlusButton
                        onClick={addDropdown}
                        className={`p-2.5`}
                        width={10}
                        height={10}
                        styleClassName={`hover:bg-white transition border border-[#FCC10F]`}
                        hoverPlusColor={'#FCC10F'}
                    />
                </div>
                <div className={'flex justify-center gap-[30px] pb-[80px]'}>
                    <ButtonOutlined onClick={close} text={'Չեղարկել'} className={'w-[176px]'}/>
                    <ButtonFilled onClick={handleSubmit} text={'Հաստատել'} className={'w-[176px]'}/>
                </div>
            </div>
        </div>
    );
};

export default DividePassengersModal;
