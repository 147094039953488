import React from 'react';
import formSubmitted from '../../../assets/images/forms/form-submitted.png'

const FormSubmitted = ({successMessage}) => {
    return (
        <div>
            <div className={'flex flex-col items-center'}>
                <h2 className={'text-[50px] text-[#FCC10F] font-bold'}>{successMessage.title}</h2>
                <p className={'text-[28px] mt-[60px]'}>{successMessage.text}</p>
                <img src={formSubmitted} alt={'forms.json-submitted'} className={'mt-[45px]'}/>
            </div>
        </div>
    );
};

export default FormSubmitted