import React, {useEffect, useState} from 'react';
import {getStudent} from "../../../../store/actions/students";
import {useDispatch, useSelector} from "react-redux";
import {creator, genius, thinker, whizzKid, wizard} from "../../../../assets/images/SMART Degrees";
import {DropdownArrow, HoodieIcon, LocationIcon, PinIcon, TabletIcon, TuitionIcon} from "../../../../assets/icons";
import ProfileHeader from "../../../../components/students/profile/mobile/ProfileHeader";
import StudentPersonalInfo from "../../../../components/students/profile/mobile/StudentPersonalInfo";
import StudentProgramsInfo from "../../../../components/students/profile/mobile/StudentProgramsInfo";
import {useMediaQuery} from "react-responsive";
import {Container} from "../../../../components/shared";

const StudentProfileMobile = () => {
    const {authData} = useSelector(state => state.auth)
    const {student} = useSelector(state => state.students)

    const [showScrollToTop, setShowScrollToTop] = useState(false)

    const [subpage, setSubpage] = useState('programs')

    const dispatch = useDispatch()

    const isPhone = useMediaQuery({maxWidth: 640})

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 370) {
                setShowScrollToTop(true)
            } else {
                setShowScrollToTop(false)
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    useEffect(() => {
        dispatch(getStudent(authData.id))
    }, [dispatch, authData.id])

    const content = (
        <div className={'max-w-[390px] lg:max-w-full'}>
            <ProfileHeader student={student} setSubpage={setSubpage} subpage={subpage}/>

            <div className={'px-5 mt-[200px] lg:mt-0'}>
                {subpage === 'info'
                    ?
                    <StudentPersonalInfo {...student} />
                    :
                    <StudentProgramsInfo {...student}/>
                }

                {showScrollToTop && isPhone &&
                    <button
                        className={'fixed z-[9999] bottom-4 left-[75%] bg-white border border-black border-opacity-70 rounded-full rotate-180 p-1'}
                        onClick={scrollToTop}
                    >
                        <DropdownArrow/>
                    </button>
                }
            </div>
        </div>
    )

    return (
        isPhone
            ?
            content
            :
            <Container>
                {content}
            </Container>
    );
};

export default StudentProfileMobile;