import React from 'react';
import { CloseIcon, ReplyIcon } from "../../../../assets/icons";
import {useMediaQuery} from "react-responsive";

const ReplyToMessage = ({ author, message, setReplyToMessage, setReplyingTo }) => {
    const isPhone = useMediaQuery({maxWidth: 640})

    const handleCancelReplying = () => {
        setReplyToMessage(null)
        setReplyingTo(null)
    }

    return (
        <div id="reply-to-message" className="relative w-full flex justify-between pt-2.5 lg:pt-[30px] px-5">
            <div className={isPhone ? 'w-[44px]' : 'w-[100px]'}></div>
            <div className="flex-1 mx-2 flex items-end">
                <div
                    className={"w-full min-h-[67px] bg-gray-100 border border-black border-opacity-50 border-b-0 rounded-[5px] rounded-b-[0px] px-5 py-2.5"}
                >
                    <div className={'relative flex justify-between'}>
                        <div className={'flex flex-col gap-2.5'}>
                            <div className={'flex items-center gap-2.5'}>
                                <ReplyIcon />
                                <span
                                    className={`text-[14px] text-black text-opacity-80 bold ${isPhone && 'line-clamp-1'}`}>{author?.fullName}</span>
                            </div>
                            <div className={'text-[14px] text-justify'}>{message}</div>
                        </div>
                        <button
                            className={'absolute right-0'}
                            onClick={handleCancelReplying}
                        >
                            <CloseIcon width={16} height={16} />
                        </button>
                    </div>
                </div>
            </div>
            <div className={isPhone ? 'w-[17px]' : 'w-[50px]'}></div>
        </div>
    );
};

export default ReplyToMessage;
