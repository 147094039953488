import { useEffect } from 'react';

export const useClick = (ref, callback, ignoreRefs = []) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                ref.current && !ref.current.contains(event.target) &&
                !ignoreRefs.some(ignoreRef => ignoreRef.current && ignoreRef.current.contains(event.target))
            ) {
                callback();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback, ignoreRefs]);
};
